import {
  Avatar,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PictureViewer from "../PictureViewer";
import { Carousel } from "react-responsive-carousel";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import mapMarkerBlue from "../../assets/images/map-marker-blue.png";
import BuildidInfo from "../../assets/images/buildid-info-bg.png";
import moment from "moment";
import {
  PushPin as PushPinIcon,
  MoreHoriz as MoreHorizIcon,
  BusinessCenter as BusinessCenterIcon,
  Room as RoomIcon,
  Build as BuildIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { BIBlue, BIRed } from "../../assets/buildidColors";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PostComments from "./PostComments";
import PostCommentInput from "./PostCommentInput";
import Loader from "../Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPosts, postSettings } from "../../api/PostsApi";

const PostCommentModal = (props) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const history = useHistory();

  const getMyPostDate = (date) => {
    if (!moment.isMoment(date)) date = moment(date);
    if (date.isSame(moment(), "day")) {
      return moment.parseZone(date).local().format("h:mma");
    } else if (date.isSame(moment().subtract(1, "d"), "day")) {
      return "Yesterday";
    } else if (date.isSame(moment(), "week")) {
      return moment.parseZone(date).local().format("dddd");
    } else {
      return moment.parseZone(date).local().format("MMMM DD, YYYY");
    }
  };

  const postDate = props?.post?.age
    ? props?.post?.age?.includes("min") ||
      props?.post?.age?.includes("hours") ||
      props?.post?.age?.includes("days") ||
      props?.post?.age?.includes("hour") ||
      props?.post?.age?.includes("day")
      ? props?.post?.age
      : getMyPostDate(moment(props?.post?.age))
    : "";

  function getPostIcon(postIcon) {
    switch (postIcon) {
      case "Briefcase":
        return <BusinessCenterIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      case "Map Pin":
        return <RoomIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      case "Wrench":
        return <BuildIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      case "Pencil":
        return <EditIcon sx={{ fontSize: "50px", color: BIBlue }} />;
      default:
        return;
    }
  }

  const handleReport = (id) => {
    setMoreMenuAnchorEl(false);
    props?.setPostId(id);
    props?.setReportModalOpen(true);
    props?.onClose();
  };

  const handleMutePost = async (post) => {
    setMoreMenuAnchorEl(false);
    try {
      const mutePostObj = JSON.stringify({
        UserId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            PostId: Number(post?.id),
            UserId: localStorage?.userId,
            IsHidden: false,
            IsMute: !post?.isMute,
          },
        ],
      });
      const mutePostRes = await postSettings(mutePostObj);
      if (mutePostRes.message === "OK") {
        props?.updatePosts();
        props?.onClose();
      }
    } catch (err) {
      console.log("MUTE POST ERROR ", err);
    }
  };

  const messageParts =
    props?.post?.message != undefined ? props?.post?.message.split(/\n/) : null;

  return (
    <Modal open={props?.isOpen} onClose={props?.onClose}>
      <div className="modal-center" style={{ left: "50%", outline: "none" }}>
        <div
          className="modal-card experience-form postComment-modal"
          style={{
            maxHeight:
              props?.post?.pictureList?.length !== 0 ? "600px" : "auto",
            overflowY: "auto",
            margin: "0",
            paddingTop: "0",
          }}
        >
          {props?.loading ? (
            <Loader />
          ) : (
            <>
              {props?.source === "info" ? (
                <div
                  style={{
                    borderBottom: "1px solid black",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "0",
                      background: "white",
                      zIndex: 1000,
                      paddingBlock: "10px",
                    }}
                  >
                    <h4 style={{ margin: "0px 0px 0px 20px" }}>BuildID Info</h4>
                  </div>

                  <div
                    style={{
                      height: "200px",
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "auto",
                    }}
                  >
                    <img
                      style={{
                        height: "200px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={BuildidInfo}
                    />
                    <img
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        pointerEvents: "none",
                        height: "50px",
                      }}
                      src={mapMarkerBlue}
                    />
                  </div>

                  <h4 style={{ marginLeft: "20px", marginTop: "10px" }}>
                    BuildID Info
                  </h4>
                  <Typography
                    style={{
                      marginLeft: "20px",
                      marginBottom: "10px",
                      fontFamily: "G-Roboto-n4, Arial",
                      fontSize: "14px",
                      color: "#4f4f4f",
                      lineHeight: "1.4",
                      letterSpacing: 0,
                    }}
                  >
                    Official announcements from the My BuilderID team.
                  </Typography>
                </div>
              ) : (
                <div
                  className="modal-card-body"
                  style={{
                    maxHeight:
                      props?.post?.pictureList?.length !== 0 ? "475px" : "auto",
                    overflowY: "auto",
                    padding: "10px 30px 0px 30px",
                  }}
                >
                  <div
                    style={{
                      maxHeight:
                        props?.post?.pictureList?.length !== 0
                          ? "450px"
                          : "auto",
                      overflowY: "auto",
                    }}
                    className="experience-form"
                  >
                    <CardHeader
                      style={{
                        padding: props.post?.appRecordName === "Ads" && 0,
                      }}
                      avatar={
                        props?.post?.appRecordName !== "Ads" && (
                          <Avatar
                            alt="Profile Picture"
                            src={
                              props?.post?.appId === 19
                                ? mapMarkerYellow
                                : props?.post?.postedByPictureUrl
                            }
                          />
                        )
                      }
                      title={
                        props?.post?.appRecordName !== "Ads" && (
                          <>
                            <span
                              className={
                                props?.post?.postedByUserId !==
                                  Number(localStorage.userId) && "link"
                              }
                              onClick={() => {
                                props?.post?.postedByUserId !==
                                  Number(localStorage.userId) &&
                                  history.push(
                                    `/user-dashboard-profile/${props?.post?.postedByUserId}`
                                  );
                              }}
                              style={{ color: "#4f4f4f" }}
                            >
                              <b>
                                {" "}
                                {props?.fromPinPost
                                  ? "BuildID Info"
                                  : `${props?.post?.postedByFirstName} ${props?.post?.postedByLastName}`}
                              </b>
                            </span>

                            {props.pinned ? (
                              <PushPinIcon
                                sx={{
                                  fontSize: "14px",
                                  color: "gray",
                                  marginLeft: "4px",
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </>
                        )
                      }
                      subheader={
                        props.post?.appRecordName !== "Ads" && (
                          <div>
                            {
                              <span>
                                {props?.post?.tradeName
                                  ? `${props?.post?.tradeName}`
                                  : ""}
                              </span>
                            }
                            {props?.post?.postedByUserId !==
                              Number(localStorage.userId) &&
                            props?.post?.addToProfile == true ? (
                              <span> | worked On</span>
                            ) : (
                              <></>
                            )}
                            <div
                              style={{ color: "#a4a4a4", fontSize: "12.8px" }}
                            >
                              <span> {postDate} </span>
                            </div>
                          </div>
                        )
                      }
                      action={
                        props?.post?.appId !== 19 ? (
                          <>
                            <IconButton
                              aria-label="settings"
                              onClick={(event) =>
                                setMoreMenuAnchorEl(event.currentTarget)
                              }
                            >
                              <MoreHorizIcon />
                            </IconButton>
                            <Menu
                              anchorEl={moreMenuAnchorEl}
                              open={Boolean(moreMenuAnchorEl)}
                              onClose={() => setMoreMenuAnchorEl(null)}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                            >
                              <MenuItem
                                onClick={() => handleMutePost(props?.post)}
                                style={{ color: BIBlue }}
                                dense
                              >
                                {props?.post?.isMute
                                  ? "Turn on notifications for this Post"
                                  : "Turn off notifications for this Post"}
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleReport(props.post.id)}
                                style={{ color: BIRed }}
                                dense
                              >
                                Report
                              </MenuItem>
                            </Menu>
                          </>
                        ) : (
                          <></>
                        )
                      }
                    />
                    <CardContent
                      className="cardBody"
                      style={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        paddingInline: "0px",
                      }}
                    >
                      {props.post?.icon !== "" ? (
                        <Typography align="center">
                          {" "}
                          {getPostIcon(props.post?.icon)}{" "}
                        </Typography>
                      ) : (
                        <></>
                      )}
                      <Typography
                        align={
                          props.post?.icon === "" || props.post?.icon === null
                            ? "left"
                            : "center"
                        }
                        style={{
                          paddingLeft: "24px",
                          fontFamily: "G-Roboto-n4, Arial",
                          fontSize: "14px",
                          color: "#4f4f4f",
                          lineHeight: "1.4",
                          letterSpacing: 0,
                        }}
                      >
                        {/* {
                                                  props?.post?.message !== '<<<picture>>>'
                                                      ? props?.post?.message
                                                      : ""
                                              } */}
                        {messageParts?.length > 2 ? (
                          <>
                            {props.post.message !== "<<<picture>>>"
                              ? messageParts.map((part, index) => (
                                  <span key={index}>
                                    {part}
                                    {index < messageParts.length - 1 && <br />}
                                  </span>
                                ))
                              : ""}
                          </>
                        ) : (
                          <>{props.post.message}</>
                        )}
                      </Typography>
                      {props.post?.appRecordName === "Ads" && (
                        <>
                          {props.post?.fileUrl !== "" && (
                            <Typography align="center">
                              <img
                                src={props.post?.fileUrl}
                                alt="AdPicture"
                                style={{ width: "50%", objectFit: "contain" }}
                              />
                            </Typography>
                          )}
                        </>
                      )}
                      {props?.post?.pictureList ? (
                        <PictureViewer
                          isOpen={pictureViewerOpen}
                          onClose={() => setPictureViewerOpen(false)}
                          pictures={props?.post?.pictureList.map((pic) => ({
                            src: pic.pictureUrl,
                            alt: "",
                          }))}
                          viewerIndex={pictureViewIndex}
                        />
                      ) : (
                        ""
                      )}

                      {props?.post?.pictureList ? (
                        <div style={{ marginTop: "8px", marginBottom: "8px" }}>
                          <Carousel
                            showThumbs={false}
                            showIndicators={
                              props?.post?.pictureList.length > 1 ? true : false
                            }
                          >
                            {props?.post?.pictureList.map((picture, index) => (
                              <div
                                key={picture.id}
                                style={{
                                  cursor: "pointer",
                                  height: "330px",
                                  display: "block",
                                }}
                                onClick={() => {
                                  setPictureViewerOpen(true);
                                  setPictureViewIndex(index);
                                }}
                              >
                                <img
                                  src={picture.pictureUrl}
                                  alt=""
                                  style={{ objectFit: "contain" }}
                                />
                              </div>
                            ))}
                          </Carousel>
                        </div>
                      ) : (
                        <></>
                      )}
                    </CardContent>
                  </div>
                </div>
              )}
              <div
                id="scrollablediv_comments"
                style={{
                  maxHeight: "350px",
                  overflow: "auto",
                  padding: "0px 30px",
                  marginBottom: "20px",
                }}
              >
                {props?.comments?.length > 0 ? (
                  <>
                    <InfiniteScroll
                      dataLength={props?.comments?.length}
                      next={props?.fetchMoreComments}
                      hasMore={true}
                      scrollableTarget="scrollablediv_comments"
                    >
                      <Stack spacing={2}>
                        {props?.comments.map((comment, index) => (
                          <React.Fragment key={index}>
                            <PostComments
                              comment={comment}
                              closeModal={props?.onClose}
                              commentSource={
                                props?.source === "info" ? true : false
                              }
                            />
                          </React.Fragment>
                        ))}
                      </Stack>
                    </InfiniteScroll>{" "}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
          {props?.source === "info" ? (
            <></>
          ) : (
            <div className="modal-card-body">
              <PostCommentInput
                post={props?.post}
                updateComments={props?.updateComments}
                from={props?.from}
                getNoOfComments={props?.getNoOfComments}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PostCommentModal;
