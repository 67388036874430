import { Modal, Button } from "@material-ui/core";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import Loader from "../Loader";
import CircularProgress from "@mui/material/CircularProgress";
import { useState } from "react";

function MemberListModal(props) {
  const history = useHistory();
  const [indexNo, setIndexNo] = useState(null);

  const loaderBtn = () => {
    return (
      <>
        <Button
          disabled={true}
          color="primary"
          variant="contained"
          style={{ width: "100px" }}
        >
          <CircularProgress size={24} />
        </Button>
      </>
    );
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={() => {
        setIndexNo(null);
        props.onClose();
      }}
    >
      <div
        className="modal-center"
        style={{ width: "62%", marginLeft: "50px", outline: "none" }}
      >
        <div className="modal-card">
          <div className="modal-card-body">
            <div
              style={{
                paddingBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <h3>Members</h3>
              </div>
              <div>
                <IconButton
                  onClick={() => {
                    setIndexNo(null);
                    props.onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <Stack spacing={0} style={{ maxHeight: "450px", overflow: "auto" }}>
              {props.isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: 200,
                  }}
                >
                  <h3>
                    <Loader />
                  </h3>
                </div>
              ) : (
                <>
                  {props.members.length != 0 ? (
                    <>
                      {props.members.map((member, index) => {
                        return (
                          <div key={index.toString()}>
                            <ListItem
                              key={index.toString()}
                              secondaryAction={
                                <div style={{ display: "flex" }}>
                                  <div style={{ marginRight: "20px" }}>
                                    {member.contactStatus == "No" ? (
                                      props?.showBtnLoader &&
                                      index === indexNo ? (
                                        loaderBtn()
                                      ) : (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          onClick={() => {
                                            setIndexNo(index);
                                            props.handleConnectClick(member);
                                          }}
                                        >
                                          connect
                                        </Button>
                                      )
                                    ) : member.contactStatus == "me" ||
                                      member.contactStatus == "Yes" ? (
                                      props?.showBtnLoader &&
                                      index === indexNo ? (
                                        loaderBtn()
                                      ) : (
                                        <Button
                                          color="primary"
                                          variant="outlined"
                                          onClick={() => {
                                            setIndexNo(index);
                                            props.handleMessageClick(member);
                                          }}
                                        >
                                          Message
                                        </Button>
                                      )
                                    ) : member.contactStatus ==
                                        "Request Sent" ||
                                      member.contactStatus ===
                                        "Awaiting response" ? (
                                      <Button
                                        disabled
                                        color="primary"
                                        variant="text"
                                      >
                                        Request Sent
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              }
                              disablePadding
                            >
                              <ListItemButton
                                sx={{ display: "flex", flexDirection: "row" }}
                                onClick={() => {
                                  setIndexNo(null);
                                  props?.onClose(1);
                                  props.profileMemberList
                                    ? history.push(
                                        `/user-dashboard-profile/${member.contactId}`
                                      )
                                    : Number(member.userId) ===
                                      Number(localStorage?.userId)
                                    ? history.push(`/profile`)
                                    : history.push(
                                        `/user-dashboard-profile/${member.userId}`
                                      );
                                }}
                              >
                                {
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <Stack
                                      sx={{ marginRight: "10px" }}
                                      direction="row"
                                      spacing={1}
                                      alignItems="center"
                                    >
                                      <Avatar
                                        src={
                                          props.profileMemberList
                                            ? member.contactPictureUrl
                                            : member.pictureUrl
                                        }
                                      />
                                    </Stack>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    ></div>
                                  </div>
                                }

                                <ListItemText
                                  primary={
                                    <div style={{ display: "flex" }}>
                                      {props.profileMemberList ? (
                                        <>
                                          <div>
                                            <b>{member.contactName}</b>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: "10px",
                                              color: "GrayText",
                                            }}
                                          >
                                            {member.workOnSameSite
                                              ? " | Worked On"
                                              : null}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <div>
                                            <b>
                                              {member.firstName +
                                                " " +
                                                member.lastName}
                                            </b>
                                          </div>
                                          <div
                                            style={{
                                              marginLeft: "10px",
                                              color: "GrayText",
                                            }}
                                          >
                                            {member.contactStatus == "me"
                                              ? " (me)"
                                              : member.contactStatus != "me" &&
                                                member?.addToProfile
                                              ? " | Worked On"
                                              : null}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  }
                                  secondary={member.tradeName}
                                />
                              </ListItemButton>
                            </ListItem>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 200,
                      }}
                    >
                      <h3>!!! NO DATA FOUND !!!</h3>
                    </div>
                  )}
                </>
              )}
            </Stack>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MemberListModal;
