// Full coded by vaibhav On 25-09-2024

import React, { useEffect, useRef, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Close, Close as CloseIcon } from "@material-ui/icons";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import HelpIcon from "@mui/icons-material/Help";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import moment from "moment";
import { LoadingButton } from "@mui/lab";

import "../../../assets/css/Addexpenses.css";
import {
  deleteCLientExpenseReceipt,
  fetchClientCurrencies,
  fetchClientexpenses,
  fetchClientexpensescodes,
  fetchClientjobs,
  saveClientexpenses,
  saveExpensesReceipt,
} from "../../../api/ClientApi";

export default function Addexpenses(props) {
  const { onClose, open, form, id, reloadData } = props;

  const imgRef = useRef(null);
  /// Expense Code
  const hint = useRef("");
  const [expenseinputValue, setExpenseCodeInputValue] = useState("");
  const [expenseCodeList, setExpenseCodeList] = useState([]);
  const [selectedCodeValue, setSelectedCodeValue] = useState({});
  // autocomplete Job
  const jobHint = useRef("");
  const [jobInputValue, setJobInputValue] = useState("");
  const [jobList, setJobList] = useState([]);
  const [selectedJobValue, setSelectedJobValue] = useState({});

  const [showSubtotal, setShowSubtotal] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);

  const [images, setImages] = useState([]);
  const [deleteImageList, setDeleteImageList] = useState([]);

  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [addExpense, setAddExpense] = useState({
    dateSubmitted: moment().format("YYYY-MM-DD"),
    title: "",
    milage: "",
    total: "",
    tax: "",
    tip: "",
    paidWith: 1,
    description: "",
    subTotal: "",
    CurrencyId: "1",
    milageUnit: "km",
  });

  useEffect(() => {
    fetchJobData();
    fetchCurrency();

    if (form == "edit" || id) {
      fetchExpensesdata();
    }
  }, []);

  useEffect(() => {
    fetchExpenseCode();
  }, [expenseinputValue]);

  useEffect(() => {
    let total =
      Number(addExpense?.subTotal) +
      Number(addExpense?.tax) +
      Number(addExpense?.tip);
    setAddExpense({
      ...addExpense,
      total: total,
    });
  }, [addExpense?.subTotal, addExpense?.tax, addExpense?.tip]);

  useEffect(() => {
    if (
      addExpense.title == "" ||
      addExpense.subTotal == "" ||
      addExpense.description == "" ||
      selectedCodeValue == ""
    ) {
      setButtonDisable(true);
    } else if (selectedCodeValue?.requireJob && selectedJobValue == "") {
      setButtonDisable(true);
    } else if (selectedCodeValue?.requireMileage && addExpense.milage == "") {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [addExpense]);

  const fetchExpensesdata = async () => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [{ id: id }],
        SortList: [
          {
            FieldName: "Id",
            Direction: "DESC",
          },
        ],
      };
      let getExpenses = await fetchClientexpenses(obj);

      let data = getExpenses?.data[0];
      setAddExpense({
        dateSubmitted: moment(data?.dateSubmitted).format("YYYY-MM-DD"),
        title: data?.title,
        milage: data?.mileage,
        total: data?.total,
        tax: data?.tax,
        tip: data?.tip,
        paidWith: data?.paidWith,
        description: data?.description,
        subTotal: data?.subTotal,
        CurrencyId: data?.currencyId,
        milageUnit: data?.mileageUnit,
        clientId: data?.clientId,
        clientExpenseCodeId: data?.clientExpenseCodeId,
        clientExpenseCode: data?.clientExpenseCode,
        clientExpenseName: data?.clientExpenseName,
        clientJobId: data?.clientJobId,
        clientJobCode: data?.clientJobCode,
        clientJobName: data?.clientJobName,
      });

      fetchExpenseCode(data?.clientExpenseCodeId);
      if (data?.clientJobId != 0) fetchJobData(data?.clientJobId);

      let temp = [];
      data?.expenseReceiptList?.map((item) => {
        temp.push({
          id: item?.id,
          fileUrl: item?.fileUrl,
          fileType: "SERVER",
        });
      });

      setImages(temp);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchExpenseCode = async (CodeId) => {
    try {
      let obj = {};
      if (CodeId) {
        obj = {
          PageNr: 1,
          NrOfRecPerPage: 20,
          FullSearch: expenseinputValue,
          UserId: Number(localStorage?.userId),
          IncludeRecordNr: true,
          TypeOfObjectReturned: "",
          FetchAllowedRecordsOnly: false,
          SearchList: [{ id: CodeId }],
          SortList: [
            {
              FieldName: "Id",
              Direction: "DESC",
            },
          ],
        };

        let response = await fetchClientexpensescodes(obj);
        console.log("expense code list", JSON.stringify(response, null, 2));
        setSelectedCodeValue(response?.data[0]);
        setExpenseCodeInputValue(response?.data[0]?.name);
      } else {
        let obj = {
          PageNr: 1,
          NrOfRecPerPage: 20,
          FullSearch: expenseinputValue,
          UserId: Number(localStorage?.userId),
          IncludeRecordNr: true,
          TypeOfObjectReturned: "",
          FetchAllowedRecordsOnly: false,
          // SearchList: [{ id: id }],
          SortList: [
            {
              FieldName: "Id",
              Direction: "DESC",
            },
          ],
        };

        let response = await fetchClientexpensescodes(obj);
        setExpenseCodeList(response?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchCurrency = async () => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,

        FetchAllowedRecordsOnly: false,
      };
      let response = await fetchClientCurrencies(obj);
      setCurrencyList(response?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchJobData = async (jobId) => {
    try {
      let obj = {};
      if (jobId) {
        obj = {
          PageNr: 1,
          NrOfRecPerPage: 20,
          FullSearch: "",
          UserId: Number(localStorage?.userId),
          IncludeRecordNr: true,
          FetchAllowedRecordsOnly: false,
          SearchList: [
            {
              Id: jobId,
            },
          ],
        };
        let getResponse = await fetchClientjobs(obj);
        console.log("getResponse", JSON.stringify(getResponse, null, 2));
        setSelectedJobValue(getResponse?.data[0]);
        setJobInputValue(getResponse?.data[0]?.name);
      } else {
        obj = {
          PageNr: 1,
          NrOfRecPerPage: 20,
          FullSearch: "",
          UserId: Number(localStorage?.userId),
          IncludeRecordNr: true,
          FetchAllowedRecordsOnly: false,
        };
        let getResponse = await fetchClientjobs(obj);
        setJobList(getResponse?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    let deleteTemp = [];
    if (deleteImageList.length > 0) {
      deleteImageList?.map((item) => {
        deleteTemp.push({
          id: item.id,
        });
      });

      try {
        let obj = {
          userId: localStorage?.userId,
          returnRecordError: true,
          softDelete: false,
          deleteList: deleteTemp,
        };
        console.log("obj", obj);
        const res = await deleteCLientExpenseReceipt(obj);
      } catch (error) {
        console.log("error", error);
      }
    }

    let obj = {
      UserId: localStorage?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: id || 0,
          ClientEmployeeId: localStorage?.clientEmployeeId,
          DateSubmitted: moment(addExpense?.dateSubmitted).format("YYYY/MM/DD"),
          Title: addExpense?.title,
          ClientExpenseCodeId: selectedCodeValue?.id,
          ClientJobId: selectedJobValue?.id || 0,
          Mileage: addExpense?.milage,
          MileageUnit: addExpense?.milageUnit,
          SubTotal: addExpense?.subTotal || 0,
          Tax: addExpense?.tax || 0,
          Tip: addExpense?.tip || 0,
          Total: addExpense?.total || 0,
          PaidWith: addExpense?.paidWith,
          Description: addExpense?.description,
          Status: 1,
          CurrencyId: addExpense?.CurrencyId,
          ModifiedBy: localStorage?.userId,
        },
      ],
    };
    try {
      const res = await saveClientexpenses(obj);
      console.log("res", res);
    } catch (error) {
      console.log("error", error);
    }

    const res = await saveClientexpenses(obj);
    console.log("res", images);

    let saveImages = [];
    if (images.length > 0) {
      images.map((item) => {
        if (item?.fileType === "LOCAL") {
          saveImages.push(item?.fileUrl);
        }
      });
    }

    if (res?.data?.length > 0) {
      if (saveImages.length > 0) {
        let formDataArray = saveImages.map((item) => {
          const formData = new FormData();
          formData.append("Id", 0);
          formData.append("ClientExpenseId", res?.data[0]?.Id);
          formData.append("PictureUrl", item);
          formData.append(
            "PictureDateTime",
            moment(new Date()).format("YYYY-MM-DD")
          );
          formData.append("ModifiedBy", Number(localStorage?.userId));
          return formData;
        });
        try {
          let promises = formDataArray.map((formData) =>
            saveExpensesReceipt(formData)
          );
          let a = await Promise.all(promises);
        } catch (error) {
          console.log("error", error);
        }
      }
    }
    setLoading(false);
    resetState();
    reloadData();
    onClose();
  };

  const imgUrl = (blob) => {
    try {
      return URL.createObjectURL(blob);
    } catch (e) {
      return blob;
    }
  };

  const onImagechange = async (e) => {
    let temp = [...images];
    let file = e.target.files;
    for (let i = 0; i < file.length; i++) {
      temp.push({
        id: null,
        fileUrl: file[i],
        fileType: "LOCAL",
      });
    }
    setImages(temp);
    e.target.value = null;
  };

  const onSubtotal = () => {
    setShowSubtotal(!showSubtotal);
  };

  const clearImage = (item, index) => {
    let delet = [...deleteImageList];
    if (item?.fileType === "SERVER") {
      delet.push({ id: item?.id });
      setDeleteImageList(delet);
    }
    let temp = [...images];
    temp.splice(index, 1);
    setImages(temp);
  };

  // const handleCodeMenuClick = (item) => {
  //   console.log("item", item);
  //   if (item !== null) {
  //     setSelectedCodeValue(item);
  //   }
  //   if (item?.requireJobMileage) {
  //     setShowJob(true);
  //     // setIsShowMileAge(true);
  //   }
  //   // else if (item?.requireMileage) {
  //   //   setIsShowMileAge(true);
  //   //   // setIsJobData([])
  //   //   setIsShowJob(false);
  //   // } else if (item?.requireJob) {
  //   //   setIsShowJob(true);
  //   //   // setDistance('');
  //   //   // setDistanceUnit({});
  //   //   setIsShowMileAge(false);
  //   // } else {
  //   //   setIsShowJob(false);
  //   //   // setJobData([])
  //   //   setIsShowMileAge(false);
  //   //   // setDistance('');
  //   // }
  //   // setShowCode(false);
  // };

  const handleSelectCode = (item) => {
    if (!item?.requireJob) {
      setSelectedJobValue({});
      setJobInputValue("");
    }
    if (!item?.requireMileage) {
      setAddExpense({
        ...addExpense,
        milage: "",
      });
    }
  };

  const resetState = () => {
    setAddExpense({
      dateSubmitted: moment().format("YYYY-MM-DD"),
      title: "",
      milage: "",
      total: "",
      tax: "",
      tip: "",
      paidWith: 1,
      description: "",
      subTotal: "",
      CurrencyId: "1",
      milageUnit: "km",
    });
    setExpenseCodeInputValue("");
    setSelectedCodeValue({});
    setJobInputValue("");
    setSelectedJobValue({});
    setImages([]);
    setDeleteImageList([]);
  };

  const handleAmountChange = (e) => {
    const regex = /^\d{0,4}(\.\d{0,2})?$/;
 
    if (regex.test(e) || e === "") {
      setAddExpense({
        ...addExpense,
        subTotal: e,
      });
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div
          className="modal-center add-expenses"
          style={{ maxHeight: "760px" }}
        >
          <div className="addexpenses-form">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                color="primary"
                onClick={onClose}
                style={{ padding: "0px" }}
              >
                <Close />
              </IconButton>
            </div>

            <div className="form-content">
              <div>
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Date
                </Typography>
                <KeyboardDatePicker
                  className="form-field"
                  variant="inline"
                  inputVariant={"outlined"}
                  placeholder="MM/DD/YYYY"
                  format="MMMM D, YYYY"
                  maxDate={moment()}
                  onChange={(e) => {
                    setAddExpense({
                      ...addExpense,
                      dateSubmitted: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                  value={addExpense?.dateSubmitted}
                  fullWidth
                  size="small"
                  autoOk={true}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Title
                </Typography>
                <TextField
                  placeholder="Enter a title"
                  className="form-field"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={addExpense?.title}
                  onChange={(e) => {
                    setAddExpense({
                      ...addExpense,
                      title: e.target.value,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Code
                </Typography>
                <Autocomplete
                  sx={{
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  size="small"
                  style={{
                    backgroundColor: "#f2f2f2",
                  }}
                  fullWidth
                  onKeyDown={(event) => {
                    if (event.key === "Tab") {
                      if (hint.current) {
                        setExpenseCodeInputValue(hint.current);
                        event.preventDefault();
                      }
                    }
                  }}
                  onBlur={() => {
                    hint.current = "";
                  }}
                  disablePortal
                  inputValue={expenseinputValue}
                  filterOptions={(options, state) => {
                    const displayOptions = options.filter((option) =>
                      option?.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase().trim())
                    );

                    return displayOptions;
                  }}
                  id="combo-box-hint-demo"
                  options={expenseCodeList}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <Typography>{`${option?.name} `}</Typography>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    handleSelectCode(newValue);
                    setSelectedCodeValue({
                      ...newValue,
                    });
                    // handleCodeMenuClick(newValue);
                    if (newValue) {
                      setExpenseCodeInputValue(`${newValue?.name}`); // Update the input value to the selected label
                    } else {
                      setExpenseCodeInputValue("");
                    }
                  }}
                  renderInput={(params) => (
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          position: "absolute",
                          opacity: 0.5,
                          left: 14,
                          top: 16,
                        }}
                      >
                        {hint.current}
                      </Typography>
                      <TextField
                        {...params}
                        onFocus={() => setExpenseCodeInputValue("")}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setExpenseCodeInputValue(newValue);
                          const matchingOption = expenseCodeList.find(
                            (option) => option?.name.startsWith(newValue)
                          );

                          if (newValue && matchingOption) {
                            hint.current = matchingOption.name;
                          } else {
                            hint.current = "";
                          }
                        }}
                      />
                    </Box>
                  )}
                />
              </div>

              {selectedCodeValue?.requireJob && (
                <div
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="label"
                    className="form-label"
                    htmlFor="my-input"
                  >
                    Job
                  </Typography>
                  <Autocomplete
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    size="small"
                    style={{
                      backgroundColor: "#f2f2f2",
                    }}
                    fullWidth
                    onKeyDown={(event) => {
                      if (event.key === "Tab") {
                        if (jobHint.current) {
                          setJobInputValue(jobHint.current);
                          event.preventDefault();
                        }
                      }
                    }}
                    onBlur={() => {
                      jobHint.current = "";
                    }}
                    disablePortal
                    inputValue={jobInputValue}
                    filterOptions={(options, state) => {
                      const displayOptions = options.filter((option) =>
                        option?.name
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase().trim())
                      );

                      return displayOptions;
                    }}
                    id="combo-box-hint-demo"
                    options={jobList}
                    renderOption={(props, option) => (
                      <Box {...props}>
                        <Typography>{`${option?.name} `}</Typography>
                      </Box>
                    )}
                    onChange={(event, newValue) => {
                      setSelectedJobValue({
                        ...newValue,
                      });
                      if (newValue) {
                        setJobInputValue(`${newValue?.name}`); // Update the input value to the selected label
                      } else {
                        setJobInputValue("");
                      }
                    }}
                    renderInput={(params) => (
                      <Box sx={{ position: "relative" }}>
                        <Typography
                          sx={{
                            position: "absolute",
                            opacity: 0.5,
                            left: 14,
                            top: 16,
                          }}
                        >
                          {jobHint.current}
                        </Typography>
                        <TextField
                          {...params}
                          onFocus={() => setJobInputValue("")}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            setJobInputValue(newValue);
                            const matchingOption = expenseCodeList.find(
                              (option) => option?.name.startsWith(newValue)
                            );

                            if (newValue && matchingOption) {
                              jobHint.current = matchingOption.name;
                            } else {
                              jobHint.current = "";
                            }
                          }}
                        />
                      </Box>
                    )}
                  />
                </div>
              )}
              {selectedCodeValue?.requireMileage && (
                <div style={{ marginTop: "13px" }}>
                  <Typography
                    variant="h6"
                    component="label"
                    className="form-label"
                    htmlFor="my-input"
                  >
                    Mileage
                  </Typography>
                  <div style={{ display: "flex", gap: "12px" }}>
                    <TextField
                      type="number"
                      variant="outlined"
                      placeholder="Enter mileage"
                      fullWidth
                      onChange={(e) => {
                        setAddExpense({
                          ...addExpense,
                          milage: e.target.value,
                        });
                      }}
                      value={addExpense?.milage}
                      name="milage"
                      className="form-field"
                      size="small"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                    />
                    <Select
                      placeholder="Select a Code"
                      value={"km"}
                      onChange={(e) => {
                        setAddExpense({
                          ...addExpense,
                          milageUnit: e.target.value,
                        });
                      }}
                      size="small"
                      className="form-field"
                    >
                      <MenuItem value={"km"}>{"km"}</MenuItem>
                      <MenuItem value={"mi"}>{"mi"}</MenuItem>
                    </Select>
                  </div>
                </div>
              )}

              <div
                style={{
                  marginTop: "20px",
                }}
              >
                <Divider />
              </div>

              <div style={{ marginTop: "13px" }}>
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Amount
                </Typography>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Select
                    style={{
                      width: "100px",
                    }}
                    placeholder="Select a Code"
                    value={addExpense?.CurrencyId}
                    onChange={(e) => {
                      setAddExpense({
                        ...addExpense,
                        CurrencyId: e.target.value,
                      });
                    }}
                    size="small"
                    className="form-field"
                  >
                    {currencyList.map((item) => (
                      <MenuItem value={item?.id}>
                        <Typography
                          style={{
                            color: localStorage?.clientAccentColor,
                          }}
                        >{`${item?.currencyCode} ${item?.currencySign}`}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    placeholder={"0.00"}
                    className="form-field"
                    value={addExpense?.subTotal}
                    onChange={(e) => {
                        handleAmountChange(e.target.value);
                      }}
                    name="total"
                    size="small"
                    type="number"
                    color="primary"
                    fullWidth
                  />
                  {!showSubtotal ? (
                    <ArrowDropDownIcon
                      style={{
                        marginTop: "10px",
                        color: localStorage?.clientAccentColor,
                        fontSize: "35px",
                        cursor: "pointer",
                      }}
                      onClick={onSubtotal}
                    />
                  ) : (
                    <ArrowDropUpIcon
                      style={{
                        marginTop: "10px",
                        color: localStorage?.clientAccentColor,
                        fontSize: "35px",
                        cursor: "pointer",
                      }}
                      onClick={onSubtotal}
                    />
                  )}
                </div>
                {showSubtotal === true && (
                  <>
                    <div style={{ marginTop: "13px", display: "flex" }}>
                      <Grid container alignItems={"center"}>
                        <Grid
                          item
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          style={{ paddingRight: "14px" }}
                        >
                          <Typography
                            variant="h6"
                            component="label"
                            className="form-label"
                            htmlFor="my-input"
                          >
                            Tax (optional)
                          </Typography>
                          <TextField
                            placeholder={"0"}
                            className="form-field"
                            size="small"
                            color="primary"
                            onChange={(e) => {
                              setAddExpense({
                                ...addExpense,
                                tax: e.target.value,
                              });
                            }}
                            value={addExpense?.tax}
                            type="number"
                            name="tax"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {addExpense.CurrencyId === 1 && (
                                    <AttachMoneyIcon />
                                  )}
                                  {addExpense.CurrencyId === 2 && (
                                    <AttachMoneyIcon />
                                  )}
                                  {addExpense.CurrencyId === 3 && (
                                    <CurrencyPoundIcon />
                                  )}
                                  {addExpense.CurrencyId === 4 && (
                                    <CurrencyRupeeIcon />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <Typography
                            variant="h6"
                            component="label"
                            className="form-label"
                            htmlFor="my-input"
                          >
                            Tip (optional)
                          </Typography>
                          <TextField
                            placeholder={"0"}
                            className="form-field"
                            onChange={(e) => {
                              setAddExpense({
                                ...addExpense,
                                tip: e.target.value,
                              });
                            }}
                            value={addExpense?.tip}
                            type="number"
                            name="tip"
                            size="small"
                            color="primary"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {addExpense.CurrencyId === 1 && (
                                    <AttachMoneyIcon />
                                  )}
                                  {addExpense.CurrencyId === 2 && (
                                    <AttachMoneyIcon />
                                  )}
                                  {addExpense.CurrencyId === 3 && (
                                    <CurrencyPoundIcon />
                                  )}
                                  {addExpense.CurrencyId === 4 && (
                                    <CurrencyRupeeIcon />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <div style={{ marginTop: "13px" }}>
                      <Typography
                        variant="h6"
                        component="label"
                        className="form-label"
                        htmlFor="my-input"
                      >
                        Total
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{
                            fontSize: "17px",
                            justifyContent: "center",
                            display: "flex",
                          }}
                          component="label"
                          className="form-label"
                          htmlFor="my-input"
                        >
                          {addExpense.CurrencyId === 1 && <AttachMoneyIcon />}
                          {addExpense.CurrencyId === 2 && <AttachMoneyIcon />}
                          {addExpense.CurrencyId === 3 && <CurrencyPoundIcon />}
                          {addExpense.CurrencyId === 4 && <CurrencyRupeeIcon />}
                          {addExpense?.total}
                        </Typography>
                        <HelpIcon
                          style={{
                            fontSize: "25px",
                            color: localStorage?.clientAccentColor,
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div style={{ marginTop: "13px" }}>
                <FormControl>
                  <FormLabel className="form-label">Select 1</FormLabel>
                  <RadioGroup
                    defaultValue="paid"
                    name="controlled-radio-buttons-group"
                    value={addExpense?.paidWith}
                    onChange={(e) =>
                      setAddExpense({
                        ...addExpense,
                        paidWith: e.target.value,
                      })
                    }
                    sx={{ my: 1 }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio
                          onClick={() => {
                            setAddExpense({
                              ...addExpense,
                              paidWith: 1,
                            });
                          }}
                          style={{ color: localStorage?.clientAccentColor }}
                        />
                      }
                      label="Paid personally"
                    />
                    <FormControlLabel
                      value={2}
                      onClick={() => {
                        setAddExpense({
                          ...addExpense,
                          paidWith: 2,
                        });
                      }}
                      control={
                        <Radio
                          onClick={() => {
                            setAddExpense({
                              ...addExpense,
                              paidWith: 2,
                            });
                          }}
                          style={{ color: localStorage?.clientAccentColor }}
                        />
                      }
                      label="Paid with company card"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div style={{ marginTop: "13px" }}>
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                >
                  Description
                </Typography>
                <TextField
                  multiline
                  value={addExpense?.description}
                  onChange={(e) => {
                    setAddExpense({
                      ...addExpense,
                      description: e.target.value,
                    });
                  }}
                  placeholder="Include additional deatils ,etc. (optional)"
                  name="description"
                  fullWidth
                  rows={4}
                  variant="outlined"
                  className="form-field"
                />
              </div>
              <div
                style={{
                  marginTop: "13px",
                  textAlign: "center",
                  alignItems: "center",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="receipt-img"
                  multiple
                  ref={imgRef}
                  onChange={(e) => onImagechange(e)}
                />
                <Button
                  style={{
                    color: localStorage?.clientAccentColor,
                    borderColor: localStorage?.clientAccentColor,
                    height: "40px",
                    width: "100px%",
                  }}
                  className="upload-btn"
                  onClick={() => imgRef.current?.click()}
                >
                  Upload receipt
                </Button>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    maxWidth: "100%",
                    overflowX: "auto",
                  }}
                >
                  {images?.map((item, index) => (
                    <span style={{ position: "relative" }}>
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                        className="recipt-img"
                        src={imgUrl(item?.fileUrl)}
                      />
                      <IconButton
                        style={{
                          top: "10px",
                          right: "8px",
                          backgroundColor: "white",
                          color: "red",
                          height: "15px",
                          width: "15px",
                          display: "flex",
                          position: "absolute",
                          padding: "10px",
                          justifyContent: "center",
                          alignitems: "center",
                        }}
                        onClick={() => clearImage(item, index)}
                        size="small"
                      >
                        <CloseIcon fontSize="small" style={{ fontSize: 16 }} />
                      </IconButton>
                    </span>
                  ))}
                </div>
              </div>
              <div style={{ marginTop: "13px" }}>
                <LoadingButton
                  loading={loading}
                  disabled={buttonDisable}
                  fullWidth
                  className="submit-btn"
                  loadingPosition="start"
                  style={{
                    backgroundColor: !buttonDisable
                      ? localStorage?.clientAccentColor
                      : "lightgrey",
                  }}
                  onClick={() => handleSave()}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
