import { Tab, Tabs, Box, Typography, Stack } from "@mui/material";
import React, { useCallback, useState } from "react";
import { searchTrade } from "../../api/TradesApi";
import SearchField from "../../components/SearchField";
import PropTypes from "prop-types";
import SearchListSection from "../search/SearchListSection";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import { Modal } from "@material-ui/core";
import RequestTradeModal from "../../components/RequestTradeModal";

const myTradesArray = ["On My Profile", "Following"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Tradelist() {
  const [tradeTabValue, setTradeTabValue] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [getUpdatedData, setGetUpdatedData] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [hasData, setHasData] = useState(true);
  const [activeMyTrades, setActiveMyTrades] = useState("On My Profile");
  const [showAddTradeModal, setShowAddTradeModal] = useState(false);

  const getTradelist = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage?.userId),
        SearchString: searchText,
        PageNumber: pageNumber,
        NrRowsPerPage: 100,
        // MyTradeCategory: "ALL",
        MyTradeCategory: "",
      };
      const res = await searchTrade(obj);
      setTotalRecord(res?.nrOfRecords);
      setHasData(res?.data?.length != 0 ? true : false);
      return res?.data;
    },
    [page]
  );

  const getMytradelist = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage?.userId),
        SearchString: searchText,
        PageNumber: pageNumber,
        NrRowsPerPage: 20,
        MyTradeCategory: "ON MY PROFILE",
      };
      const res = await searchTrade(obj);
      setHasData(res?.data?.length != 0 ? true : false);
      setTotalRecord(res?.nrOfRecords);
      return res?.data;
    },
    [page]
  );

  const getFollowingData = useCallback(
    async (pageNumber, searchText) => {
      const obj = {
        UserId: Number(localStorage?.userId),
        SearchString: searchText,
        PageNumber: pageNumber,
        NrRowsPerPage: 20,
        MyTradeCategory: "FOLLOWING",
      };
      const res = await searchTrade(obj);
      setHasData(res?.data?.length != 0 ? true : false);
      setTotalRecord(res?.nrOfRecords);
      return res?.data;
    },
    [page]
  );

  const increaseOffset = (callback) => {
    setPage((prevPageNr) => prevPageNr + 1);
    callback(page + 1);
  };

  const handleChangeTradeTab = (event, newValue) => {
    setTradeTabValue(newValue);
    setPage(1);
  };

  const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  const clearSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setGetUpdatedData(true);
    }
  };

  return (
    <div className="dashboard-page">
      <RequestTradeModal
        open={showAddTradeModal}
        onClose={() => {
          setShowAddTradeModal(false);
        }}
      />
      <div style={{ padding: "0% 4%" }}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginBottom: "10px",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              width: "35vw",
            }}
          >
            <SearchField
              value={searchText}
              onChange={(value) => clearSearch(value)}
              onSubmit={() => {
                setPage(1);
                setGetUpdatedData(true);
              }}
              placeholder={"Search Trades"}
            />
          </div>
          <p
            style={{
              backgroundColor: "white",
              padding: "12px",
              color: BIGrey,
              borderRadius: "5px",
            }}
          >
            {totalRecord} : Result
          </p>
        </Stack>
        <div style={{ display: "flex", position: "fixed", width: "81%" }}>
          <Tabs
            orientation="vertical"
            textColor={BIBlue}
            indicatorColor="primary"
            aria-label="primary tabs example"
            style={{ marginTop: "10px", width: "250px" }}
            value={tradeTabValue}
            onChange={handleChangeTradeTab}
          >
            <Tab
              textColor={BIBlue}
              className={"tab-btn-radius"}
              label="All Trades"
              {...a11yProps(0)}
            />
            <Tab
              textColor={BIBlue}
              className={"tab-btn-radius"}
              style={{ marginTop: "10px" }}
              label="My Trades"
              {...a11yProps(1)}
            />
          </Tabs>

          <TabPanel value={tradeTabValue} index={0} style={{ width: "100%" }}>
            <div
              id="scrollList"
              className="scrollablediv_manufacturer experience-form"
              style={{ marginTop: "10px", height: "585px" }}
            >
              <SearchListSection
                getData={getTradelist}
                increaseOffset={increaseOffset}
                page={page}
                universalsearch={false}
                searchText={searchText}
                getUpdatedData={getUpdatedData}
                setGetUpdatedData={(val) => setGetUpdatedData(val)}
                from="trade"
                hasMore={hasData}
              />

              <div className="footer-btnbox" style={{ padding: "20px 0px" }}>
                <Typography
                  style={{
                    fontSize: "14px",
                    color: BIGrey,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Can't find your Trade?
                  <span
                    style={{
                      color: BIBlue,
                      fontWeight: "600",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setShowAddTradeModal(true);
                    }}
                  >
                    Request one
                  </span>
                </Typography>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={tradeTabValue} index={1} style={{ width: "100%" }}>
            <Tabs
              textColor={BIBlue}
              indicatorColor="primary"
              value={activeMyTrades}
              onChange={async (event, newTab) => {
                setPage(1);
                await setActiveMyTrades(newTab);
              }}
            >
              {myTradesArray.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab}
                  label={tab}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginTop: "10px",
                    marginRight: "20px",
                  }}
                />
              ))}
            </Tabs>
            {activeMyTrades === "On My Profile" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getMytradelist}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="trade"
                  hasMore={hasData}
                />
              </div>
            )}
            {activeMyTrades === "Following" && (
              <div
                id="scrollList"
                className="scrollablediv_manufacturer experience-form"
                style={{ marginTop: "10px", height: "525px" }}
              >
                <SearchListSection
                  getData={getFollowingData}
                  increaseOffset={increaseOffset}
                  page={page}
                  universalsearch={false}
                  searchText={searchText}
                  getUpdatedData={getUpdatedData}
                  setGetUpdatedData={(val) => setGetUpdatedData(val)}
                  from="trade"
                  hasMore={hasData}
                />
              </div>
            )}
          </TabPanel>
        </div>
      </div>
    </div>
  );
}
