import React, { useCallback, useEffect, useState } from "react";
import {
    Button, Chip,
    Container, IconButton, Stack, ListItem, ListItemButton, ListItemText, Avatar,
    Menu, MenuItem, List, TextField, InputAdornment
} from "@mui/material";
import { BIBlue, BIRed, BIWhite } from "../../assets/buildidColors";
import RoomIcon from '@mui/icons-material/Room';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchUserListingByDistances, fetchUserSavedListings } from "../../api/BuySellApi";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg"
import {
    MoreVert as MoreIcon, Search as SearchIcon,
    Close as CloseIcon
} from "@mui/icons-material";
import _debounce from 'lodash/debounce';
import Loader from "../../components/Loader";
import MyListings from "./MyListings";
import ItemDetailsModal from './ItemDetailsModal';
import FilterOptions from "./FilterOptions";
import ChooseLocation from "./ChooseLocation";
import ReportModal from "../../components/ReportModal";
import UserUsages from "../../components/userUsages/UserUsages";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash/debounce";


const BuySellList = () => {

    const [mainData, setMainData] = useState([]);
    const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [showMyListings, setShowMyListings] = useState(false);
    const [showItemDetailsModal, setShowItemDetailsModal] = useState(false);
    const [selectItemDetails, setSelectedItemDetails] = useState(null);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [forTradeCheckBox, setForTradeCheckBox] = useState(false);
    const [forSaleCheckBox, setForSaleCheckBox] = useState(false);
    const [checkBoxValue, setCheckBoxValue] = useState(null);
    const [isDataFiltered, setIsDataFiltered] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [currentLocationLatLong, setCurrentLocationLatLong] = useState(null);
    const [location, setLocation] = useState({ lat: '51.044270', lng: '-114.062019' });
    const [isLocationChanged, setIsLocationChanged] = useState(false);
    const [locationButtonText, setLocationButtonText] = useState(
        "Using Current Location"
    );
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const finalData = isDataFiltered ? filterData : mainData;
    const [pageNumber, setPageNumber] = useState(1);
    const [nrOfRecords, setNrOfRecords] = useState(0);

    useEffect(async () => {
        await UserUsages('BuySell');
        await getInitialData()
        try {
            const currentLocationValuesAtFirstTime = await getCurrentLocation();
            await getData("", 1, currentLocationValuesAtFirstTime);
        } catch (error) {
            setLoading(false);
            console.error("Error getting current location:", error);
        }
    }, [])



    const getInitialData = () => {
        setLocationButtonText(localStorage?.postalCodeForBuySell ?? "Using Current Location");
        setLocation({
            lat: localStorage?.latForBuySell ? localStorage?.latForBuySell : 51.044270,
            lng: localStorage?.lngForBuySell ? localStorage?.lngForBuySell : -114.062019,
        })
        setIsLocationChanged(localStorage?.isLocationChangedForBuySell ? true : false);
    }

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                alert("Please allow location from your browser");
                getCurrentLocation();
                break;
            case error.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                alert("An unknown error occurred.");
                break;
            default:
                break;
        }
    };

    const currentLocation = async (position) => {
        var lat = position?.coords?.latitude;
        var lng = position?.coords?.longitude;
        setCurrentLocationLatLong({ lat, lng });
        return { lat, lng }
        // localStorage.setItem("latForBuySell", lat);
        // localStorage.setItem("lngForBuySell", lng);
    };

    const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const lat = position.coords.latitude;
                        const lng = position.coords.longitude;
                        setCurrentLocationLatLong({ lat, lng });
                        resolve({ lat, lng });
                    },
                    error => {
                        showError(error);
                        reject(error);
                    }
                );
            } else {
                alert("Geolocation is not supported by this browser.");
                reject(new Error("Geolocation is not supported by this browser."));
            }
        });
    };




    const getData = async (value, pageNumber, currentLocationValuesAtFirstTime) => {
        try {
            if (pageNumber === 1) {
                setLoading(true);
            }
            const obj = {
                UserId: Number(localStorage.userId),
                UserLatitude: isLocationChanged ? location.lat : currentLocationValuesAtFirstTime?.lat ?? currentLocationLatLong?.lat,
                UserLongitude: isLocationChanged ? location.lng : currentLocationValuesAtFirstTime?.lng ?? currentLocationLatLong?.lng,
                // UserLatitude: "18.535834",
                // UserLongitude: "73.829778",
                PageNumber: 1,
                NrRowsPerPage: 20
            }
            const fetchItemDistance = await fetchUserListingByDistances(obj);
            const obj2 = {
                PageNr: pageNumber,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: Number(localStorage.userId),
                IncludeRecordNr: true,
                TypeOfObjectReturned: "",
                FetchAllowedRecordsOnly: false,
                SearchList: [value?.trim()?.length > 0 ? { title: value } : {}],
                SortList: [{ FieldName: "Id", Direction: "DESC" }]
            }
            const fetchItem = await fetchUserSavedListings(obj2);
            setNrOfRecords(fetchItem?.nrOfRecords ?? 0);
            let a = [];
            const newListingArray = await fetchItem?.data?.map((item) => {
                a = item;
                fetchItemDistance.map((i) => {
                    if (i.id == item.id) {
                        a['distance'] = i.distance;
                    }
                })
                return a;
            });
            const finalArray = await newListingArray.filter(item => item.createdBy != localStorage.userId);
            if (pageNumber > 1) {
                setMainData((prevData) => prevData.concat(finalArray.sort((a, b) => {
                    let dateA = a.distance, dateB = b.distance
                    return dateA - dateB
                })
                    ?? []));
            } else {
                setMainData(
                    finalArray.sort((a, b) => {
                        let dateA = a.distance, dateB = b.distance
                        return dateA - dateB
                    })
                    ?? []);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('ErrorFetchUsersJobPosts:', error?.message ?? error)
        }
    }

    const onSearchTermChange = _debounce((value) => {
        getData(value, 1);
    }, 200);

    // console.log('forsale checkbox-----------',forSaleCheckBox)
    // console.log('forTradeCheckBox checkbox-----------',forTradeCheckBox)
    const onFilterModalClose = () => {

        if(forSaleCheckBox && forTradeCheckBox){
            setIsDataFiltered(false);
        }
        else if(forSaleCheckBox){
            const forSaleData = mainData.filter((i => !i.forTrade));
            setIsDataFiltered(true);
            setFilterData(forSaleData);
        }else if(forTradeCheckBox){
            const forTradeData = mainData.filter((i => i.forTrade));
            setIsDataFiltered(true);
            setFilterData(forTradeData);
        }else {
            setIsDataFiltered(false);
        }
        return setShowFilterOptions(false);

        // if (checkBoxValue === "For Sale") {
        //     const forSaleData = mainData.filter((i => !i.forTrade));
        //     setIsDataFiltered(true);
        //     setFilterData(forSaleData);
        // } else if (checkBoxValue === "For Trade") {
        //     const forTradeData = mainData.filter((i => i.forTrade));
        //     setIsDataFiltered(true);
        //     setFilterData(forTradeData);
        // } else {
        //     setIsDataFiltered(false);
        // }
        // return setShowFilterOptions(false);
    }

    const onLocationModalClose = () => {
        setShowLocationModal(false);
    }



    const increaseOffset = (callback) => {
        setPageNumber((prevPageNr) => prevPageNr + 1);
        callback(pageNumber + 1);
    }

    const debouncedSearch = useCallback(
        debounce((term) => {
            getData(term, 1);
        }, 500),
        []
    );

    const handleSearch = (event) => {
        const term = event.target.value;
        setSearchTerm(term);
        setPageNumber(1)
        debouncedSearch(term);
    };

    return (
        <>
            <div className="page rewards-page">
                <div className="header">
                    <div className="page-content">
                        <h1>Buy/Sell/Trade</h1>
                    </div>
                </div>
            </div>
            <div className="page">
                <div className="page-content">
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ marginTop: '20px' }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                        >
                            <TextField
                                variant="outlined"
                                placeholder="Search Listings"
                                size="small"
                                value={searchTerm}
                                onChange={handleSearch}
                                // onChange={async (e) => {
                                //     setPageNumber(1);
                                //     await setSearchTerm(e.target.value)
                                //     getData(e.target.value, 1);
                                //     // onSearchTermChange(e.target.value)
                                // }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    endAdornment: (
                                        searchTerm
                                        && (
                                            <IconButton
                                                onClick={async () => {
                                                    setPageNumber(1);
                                                    await setSearchTerm("");
                                                    await getData("", 1);
                                                }}>
                                                <CloseIcon />
                                            </IconButton>
                                        )
                                    )
                                }}
                            />
                            <Button
                                variant="outlined"
                                style={{
                                    color: BIBlue,
                                }}
                                onClick={() => { setShowMyListings(true); }}>
                                My Listings
                            </Button>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            spacing={2}
                        >
                            <Button startIcon={<RoomIcon />}
                                style={{
                                    color: BIBlue,
                                }}
                                onClick={() => {
                                    setShowLocationModal(true);
                                }}
                            >
                                {locationButtonText}
                            </Button>
                            <IconButton onClick={() => { setShowFilterOptions(true) }}
                                style={{ color: BIBlue }}>
                                <FilterListIcon />
                            </IconButton>
                        </Stack>
                    </Stack>
                    <div>
                        {
                            loading ? <Loader /> :
                                finalData?.length > 0 ?
                                    <>

                                        <InfiniteScroll
                                            dataLength={finalData.length}
                                            next={async () => {
                                                increaseOffset((pageNr) => { getData(searchTerm, pageNr) });
                                            }}
                                            hasMore={true}>
                                            <List sx={{ width: '100%', marginTop: '10px' }}>
                                                {
                                                    finalData.map((item, index) => (
                                                        <ListItem
                                                            key={index}
                                                            sx={{ width: '100%', bgcolor: BIWhite, borderRadius: '5px', margin: '15px 0', height: '84px' }}
                                                            secondaryAction={
                                                                <>
                                                                    <IconButton
                                                                        onClick={(event) => {
                                                                            setSelectedItemDetails(item)
                                                                            setMoreMenuAnchorEl(event.currentTarget)
                                                                        }}>
                                                                        <MoreIcon />
                                                                    </IconButton>
                                                                </>
                                                            }
                                                            disablePadding>
                                                            <ListItemButton
                                                                onClick={async () => {
                                                                    await setSelectedItemDetails(item)
                                                                    await setShowItemDetailsModal(true)
                                                                }}>
                                                                <Avatar
                                                                    variant="rounded"
                                                                    style={{ marginRight: "10px" }}
                                                                    src={item?.pictureList?.length != 0 ? item?.pictureList?.[0].name : defaultProjectImage}>
                                                                </Avatar>
                                                                <ListItemText
                                                                    primary={item?.title}
                                                                    secondary={
                                                                        item.forTrade ?
                                                                            (
                                                                                <>
                                                                                    <Chip label="For Trade" />
                                                                                </>
                                                                            )
                                                                            : `$${item.price}`
                                                                    }
                                                                />
                                                            </ListItemButton>
                                                        </ListItem>

                                                    ))
                                                }
                                            </List>
                                        </InfiniteScroll>

                                    </>
                                    :
                                    <>
                                    </>
                        }
                    </div>
                </div>
            </div>
            {
                showMyListings &&
                <MyListings
                    open={showMyListings}
                    onClose={() => {
                        setShowMyListings(false);
                    }}
                    onMyListingItemClick={(item) => {
                        setSelectedItemDetails(item)
                        setShowItemDetailsModal(true);
                    }}
                />
            }
            {
                showItemDetailsModal &&
                <ItemDetailsModal
                    open={showItemDetailsModal}
                    onClose={() => {
                        setSelectedItemDetails(null)
                        setShowItemDetailsModal(false);
                    }}
                    item={selectItemDetails}
                />
            }

            {
                showFilterOptions &&
                <FilterOptions
                    open={showFilterOptions}
                    onClose={() => { onFilterModalClose() }}
                    forSaleCheckBox={forSaleCheckBox}
                    setForSaleCheckBox={setForSaleCheckBox}
                    forTradeCheckBox={forTradeCheckBox}
                    setForTradeCheckBox={setForTradeCheckBox}
                    // checkBoxValue={checkBoxValue}
                    // handleCheckBoxClick={(value) => { setCheckBoxValue(value) }}
                    // handleForSaleClick={() => {
                    //     setForSaleCheckBox(!forSaleCheckBox)
                    // }}
                    // handleForTradeClick={() => {
                    //     setForTradeCheckBox(!forTradeCheckBox)
                    // }}
                />
            }
            {
                showLocationModal &&
                <ChooseLocation
                    open={showLocationModal}
                    setOpenChooseLocationModal={onLocationModalClose}
                    setLocationButtonText={(value, callApi) => {
                        setLocationButtonText(value ?? locationButtonText);
                        if (callApi) {
                            setPageNumber(1);
                            getData("", 1)
                        }
                    }}
                    coordinates={{
                        lat: isLocationChanged ? Number(location?.lat) : Number(currentLocationLatLong?.lat),
                        lng: isLocationChanged ? Number(location?.lng) : Number(currentLocationLatLong?.lng)
                    }}
                    setCurrentLocationLatLong={setCurrentLocationLatLong}
                    setLocation={setLocation}
                    location={location}
                    setIsLocationChanged={setIsLocationChanged}
                    isLocationChanged={isLocationChanged}
                />
            }


            {
                reportModalOpen &&
                <ReportModal
                    isOpen={reportModalOpen}
                    onClose={() => {
                        setReportModalOpen(false)
                        setSelectedItemDetails(null)
                    }}
                    name={"BuySellStore"}
                    recordId={selectItemDetails?.id ?? 0}
                />
            }

            {
                Boolean(moreMenuAnchorEl) &&
                <Menu
                    anchorEl={moreMenuAnchorEl}
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={() => setMoreMenuAnchorEl(null)}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}>
                    <MenuItem
                        onClick={async () => {
                            await setMoreMenuAnchorEl(null)
                            await setReportModalOpen(true)
                        }}
                        style={{ color: BIRed }}
                        dense>
                        Report
                    </MenuItem>
                </Menu>
            }
        </>
    )
}

export default BuySellList;