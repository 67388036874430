import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { Button, TextField } from "@material-ui/core";

const AddAnotherNameModal = (props) => {
  const [anotherName, setAnotherName] = useState("");

  const handleClose = () => {
    setAnotherName("");
    props.onClose();
  };
  return (
    <>
      <Dialog onClose={handleClose} open={props.open} fullWidth maxWidth="sm">
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Another Name
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div className="input-container">
            <TextField
              autoFocus
              variant="outlined"
              label={props?.label ?? ""}
              fullWidth
              defaultValue={anotherName}
              onChange={(e) => {
                setAnotherName(e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter" && anotherName.trim()) {
                  props.onAotherNameSave(anotherName);
                }
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.onAotherNameSave(anotherName);
            }}
            disabled={!anotherName.trim()}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddAnotherNameModal;
