import { restGet, restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }

// Gets dashboard content (ie. Connect > BuildID Info)
// URL is under "users", but makes more sense here under "Dashboard"
export async function getDashboardNotifications(userId) {
  const res = await restGet(
    "users/GetDashboardNotifications/" + userId,
    headers
  );
  return res;
}
export async function getDashboardNotificationsCount(userId) {
  const res = await restGet(
    "Users/GetDashboardNotificationsCount/" + userId,
    headers
  );
  return res;
}

export async function dismissUserNotification(data) {
  const res = await restPost("users/DismissUserNotification/", data, headers);
  return res;
}

export async function getSurveyQuizz(data) {
  const res = await restPost("Users/FetchBIQuiz/", data, headers);
  return res;
}
export async function saveSurveyAnswers(data) {
  const res = await restPost("users/SaveSurveyQuestions/", data, headers);
  return res;
}
export async function getSurveyDate(userId) {
  const res = await restGet(
    `Users/GetDashboardNotificationsCount/${userId}`,
    headers
  );
  return res;
}
export async function getSurveyDateNever(userId, key) {
  const res = await restGet(
    `Users/GetUserSurveyListDate/${userId}/${key}`,
    headers
  );
  return res;
}
