import React from "react";
import {
  Modal,
  Card,
  CardContent,
  Box,
  TextField,
} from "@mui/material";
import { ArrowDownward } from "@material-ui/icons";
import "../../assets/css/ResumeBuilder.css";
import { Button } from "@material-ui/core";

function AfterGenTutorial(props) {
  return (
    <Modal
      open={props?.showTutorial ? true : false}
      onClose={props.onPress}
      style={{
        backgroundColor: "rbga(0,0,0,0.1)",
      }}
    >
      <div
        style={{ backgroundColor: "rgba(0, 0, 0, 0.7)", height: "100%" }}
        onClick={props.onPress}
      >
        {props.showTutorial && (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              height: "100%",
            }}
          >
            <div className="resumebuilder-mini-tutorial-body">
              <Box className="resumebuilder-mini-tutorial-box">
                <div
                  style={{
                    marginLeft: "20%",
                    color: "white",
                    marginTop: "10px",
                  }}
                >
                  <div>After you are complete, enter a title and share! </div>
                  <ArrowDownward style={{ fontSize: "40px" }} />
                </div>
                <Card>
                  <CardContent >
                    <div className="resumebuilder-mini-tutorial-cardcontent">
                      <Box>
                        <div style={{ marginTop: "15px", textAlign: "start" }}>
                          <p>Enter Title of Resume</p>
                        </div>
                        <div
                          className="flex-container-horizontal"
                          style={{ alignItems: "stretch" }}
                        >
                          <TextField
                            variant="outlined"
                            label="Resume Title"
                            fullWidth
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "15px" }}
                            type="button"
                          >
                            Save
                          </Button>
                        </div>
                        <div style={{ marginTop: "15px", textAlign: "start" }}>
                          <p>Recipients</p>
                        </div>
                        <div
                          className="flex-container-horizontal"
                          style={{ alignItems: "stretch" }}
                        >
                          <TextField
                            defaultValue={""}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Add Contacts or emails"
                          />
                        </div>
                      </Box>
                      <Box>
                      </Box>
                    </div>
                  </CardContent>
                </Card>
              </Box>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default AfterGenTutorial;
