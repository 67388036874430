import {
  Button,
  FormControlLabel,
  Modal,
  Radio,
  TextField,
  Typography,
} from "@material-ui/core";
import { CloseFullscreen, UTurnLeftTwoTone } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  getSurveyDateNever,
  getSurveyQuizz,
  saveSurveyAnswers,
} from "../../api/DashboardApi";
import { set } from "lodash";
import { validate } from "@material-ui/pickers";
import Loader from "../../components/Loader";

export default function SurveyModalQuiz({ open, onClose }) {
  const [quiz, setQuiz] = useState([]);
  const [validation, setValidation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  useEffect(() => {
    fetchQuiz();
  }, []);

  const fetchQuiz = async () => {
    const fetchQuestionObj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{}],
      isLoading: true,
    };
    const questions = await getSurveyQuizz(fetchQuestionObj);
    let data = questions.data.map((item) => {
      if (item.questionType == "Select") {
        let array = item.answerList.map((answer) => {
          return {
            ...answer,
            isChecked: false,
          };
        });
        return {
          ...item,
          answerList: array,
        };
      }
      if (item.questionType == "Multiline") {
        console.log("Asdfasdfasdf");
        return { ...item, givenAnswer: "" };
      }
    });
    setQuiz(data);
    // }
  };

  const handleCheckBoxChange = (e, index, index1) => {
    let temp = [...quiz];
    temp[index].answerList.map((item, a) => {
      if (a == index1) {
        item.isChecked = true;
      } else item.isChecked = false;
    });
    console.log(temp);
    setQuiz(temp);
  };

  const handelTextChange = (e, index) => {
    let temp = [...quiz];
    temp[index] = {
      ...temp[index],
      givenAnswer: e.target.value,
    };
    setQuiz(temp);
    console.log(temp);
  };

  const saveTheData = async () => {
    setDisableButton(true);
    let temp = [...quiz];
    let data = temp.map((item) => {
      if (item.questionType == "Select") {
        return {
          Id: 0,
          BIQuizQuestionId: item.id,
          BIQuizAnswer: item.answerList.find((x) => x.isChecked)
            ?.biQuizQuestionId,
          RecordStatusId: 1,
          ModifiedBy: Number(localStorage.userId),
        };
      }
      console.log(item);
      if (item.questionType == "Multiline") {
        return {
          Id: 0,
          BIQuizQuestionId: item.id,
          BIQuizAnswer: item.givenAnswer,
          RecordStatusId: 1,
          ModifiedBy: Number(localStorage.userId),
        };
      }
    });

    try {
      const saveListObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: data,
      };
      await saveSurveyAnswers(saveListObj);
      await getSurveyDateNever(Number(localStorage.userId), 5);
      onClose();
    } catch (error) {
      console.log("SAVE_SURVEY_ERROR" + error);
    }
  };

  const handelSave = () => {
    // validation
    let temp = [...quiz];
    let sample = [];
    let isValidcount = 0;
    temp.map((item, index) => {
      if (item?.questionType == "Select") {
        let temp = item?.answerList.map((item) => item?.isChecked == true);

        let check = temp.some((item) => item == true);
        if (!check) {
          sample.push({
            ...quiz[index],
            checkValidation: true,
          });
          isValidcount += 1;
        } else {
          sample.push({
            ...quiz[index],
            checkValidation: false,
          });
        }
      }
      if (item?.questionType == "Multiline") {
        if (item?.givenAnswer == "") {
          sample.push({
            ...quiz[index],
            checkValidation: true,
          });
          isValidcount += 1;
        } else {
          sample.push({
            ...quiz[index],
            checkValidation: false,
          });
        }
      }
    });

    setQuiz(sample);
    setValidation(isValidcount == 0 ? true : false);

    if (isValidcount == 0) {
      saveTheData();
    } else {
      console.log("not able to save the data");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-center">
        <div
          style={{
            padding: "20px",
            width: "500px",
          }}
          className="modal-card"
        >
          <div
            style={{
              padding: "20px",
              maxHeight: "500px",
              overflowY: "auto",
            }}
          >
            {quiz.map((item, index) => {
              return (
                <div style={{}}>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.question}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {item.questionType == "Select" && (
                      <>
                        {item.answerList.map((answer, index1) => {
                          return (
                            <div style={{ display: "flex" }}>
                              <FormControlLabel
                                checked={answer.isChecked}
                                control={
                                  <Radio
                                    color="primary"
                                    onChange={(e) => {
                                      handleCheckBoxChange(e, index, index1);
                                    }}
                                  />
                                }
                                label={answer.answer}
                              />
                            </div>
                          );
                        })}
                        {item.checkValidation && (
                          <Typography
                            style={{
                              color: "red",
                            }}
                          >
                            Please select the option
                          </Typography>
                        )}
                      </>
                    )}

                    {item.questionType == "Multiline" && (
                      <>
                        <TextField
                          error={item?.checkValidation}
                          style={{ margin: "10px 0px" }}
                          id="outlined-multiline-static"
                          helperText={
                            item?.checkValidation == true
                              ? "this is required"
                              : ""
                          }
                          label="Answer"
                          variant="outlined"
                          onChange={(e) => handelTextChange(e, index)}
                        />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {disableButton ? (
            <div
              style={{
                width: "100%",
              }}
            >
              <Loader />
            </div>
          ) : (
            <Button
              disabled={disableButton}
              variant="contained"
              color="primary"
              onClick={handelSave}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
