import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Paper,
  Link,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Divider,
  Button,
} from "@mui/material";
import "../../assets/css/Chat.css";
import SendIcon from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import {
  getChatGroupMessages,
  getUnreadMessagecount,
  leaveChatGroup,
  muteChatGroup,
  saveChatGroupUserMessages,
  saveChatGroupUsers,
  saveMessagePictures,
  sendChatGroupFirebaseNotify,
  sendChatGroupNotifications,
} from "../../api/ChatApi";
import { useEffect } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useRef } from "react";
import Loader from "../../components/Loader";
import defaultTradeProfile from "../../assets/images/default-trade-profile.jpg";
import { useLocation, useHistory } from "react-router-dom";
import { Close as CloseIcon } from "@material-ui/icons";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg";
import moment from "moment";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import { MoreVert } from "@material-ui/icons";
import { BIRed } from "../../assets/buildidColors";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import MemberListModal from "../../components/profile/MemberListModal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// 1198
function ConversationScreen(props) {
  const location = useLocation();
  const history = useHistory();
  const messageContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const chatList = useRef([]);
  const page = useRef(1);
  const [sendMessage, setSendmessage] = useState("");
  const [messagesList, setMessageslist] = useState([]);
  const [hubConnection, setHubConnection] = useState(null);
  const [showPagelink, setShowpagelink] = useState(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [showBtnLoader, setShowBtnLoader] = useState(false);
  const [memberList, setMemberlist] = useState([]);
  const [selectedImageUrls, setSelectedImageUrls] = useState([]);
  const [showPreviewlink, setShowPreviewLink] = useState(false);
  const [sendButton, setSendbutton] = useState(true);
  const [isMuted, setIsMuted] = useState();
  const [isFabMenuOpen, setIsFabMenuOpen] = useState(null);
  const isMenuButtonOpen = Boolean(isFabMenuOpen);
  // const [expanded, setExpanded] = useState(false);

  let globalConnection;

  useEffect(() => {
    if (props?.chatGroupUsers?.length > 0) {
      let currentUser = {
        id: 1,
        firstName: localStorage.firstName,
        lastName: localStorage.lastName,
        userEmail: localStorage?.primaryEmail,
        tradeName: localStorage?.tradeName,
        pictureUrl: localStorage.pictureUrl,
        userId: Number(localStorage.userId),
        chatGroupId: props.chatGroupUsers[0]?.chatGroupId,
        contactStatus: "me",
      };
      setMemberlist([currentUser, ...props.chatGroupUsers]);
      createConnection();
    }
    return () => {
      page.current = 1;
      chatList.current = [];
      setMessageslist([]);
      globalConnection?.stop();
    };
  }, [props.chatGroupUsers]);

  useEffect(() => {
    if (!props?.expanded) {
      if (messageContainerRef.current) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    }
  }, [props?.expanded]);

  useEffect(() => {
    if (hubConnection?.connectionState === "Connected") {
      getChatmessages(page);
    }
  }, [hubConnection]);

  useEffect(() => {
    if (location?.state?.from === "Detail") {
      setShowpagelink(true);
      setSendbutton(false);
    } else {
      setShowpagelink(false);
    }
  }, [location.state]);

  // Create connection
  const createConnection = async () => {
    let connectionUrl = "";
    if (process.env.REACT_APP_BASE_URL.includes("test")) {
      // development
      connectionUrl = `${process.env.REACT_APP_CHAT}/chat?username=${Number(
        localStorage.getItem("userId")
      )}&chatgroupid=${props?.chatGroupUsers[0]?.chatGroupId}`;
    } else if (process.env.REACT_APP_BASE_URL.includes("staging")) {
      // staging
      connectionUrl = `${process.env.REACT_APP_CHAT}/chat?username=${Number(
        localStorage.getItem("userId")
      )}&chatgroupid=${props?.chatGroupUsers[0]?.chatGroupId}`;
    } else {
      // production
      connectionUrl = `${process.env.REACT_APP_CHAT}/chat?username=${Number(
        localStorage.getItem("userId")
      )}&chatgroupid=${props?.chatGroupUsers[0]?.chatGroupId}`;
    }
    try {
      let newConnection = new HubConnectionBuilder()
        .withUrl(connectionUrl)
        .build();
      newConnection.on(
        `ChatGroupId${props?.chatGroupUsers[0]?.chatGroupId}`,
        (obj, message) => {
          let getMessage = {
            fromFirstName: obj?.fromFirstName,
            messageText: obj?.messageText,
            fromUserId: obj?.fromUserId,
            messageTypeId: obj?.messageTypeId,
            id: obj?.id,
          };
          let getMessagelist = chatList.current;
          let lastElement = getMessagelist[getMessagelist?.length - 1];
          if (
            obj?.toChatGroupId === Number(localStorage.getItem("chatGroupId"))
          ) {
            if (lastElement?.id !== obj?.id) {
              let updatedList = [...getMessagelist, getMessage];
              chatList.current = updatedList;
              setMessageslist(updatedList);
              updatePrevMessage(obj);
              // Scroll to the bottom of the message container
              if (messageContainerRef.current) {
                messageContainerRef.current.scrollTop =
                  messageContainerRef.current.scrollHeight;
              }
            } else if (typeof lastElement === "undefined") {
              // when create group first time the lastelement is undefined
              let updatedList = [...getMessagelist, getMessage];
              chatList.current = updatedList;
              setMessageslist(updatedList);
            }
          } else {
            getUnreadmsgcount(obj);
          }
        }
      );
      newConnection.on(`BroadcastMessage`, (obj) => {});
      newConnection
        .start()
        .then(() => {
          setHubConnection(newConnection);
        })
        .catch((err) => {
          console.log("Error while establishing SignalR connection!", err);
          throw err;
        });
      globalConnection = newConnection;
    } catch {
      console.log("Hub connection faild");
    }
  };

  const updatePrevMessage = async (getMessage) => {
    if (window.location.pathname === "/chat") {
      const obj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            ChatGroupId: getMessage?.toChatGroupId,
            ToUserId: Number(localStorage.getItem("userId")),
            CGMessageId: getMessage?.id,
          },
        ],
      };
      await saveChatGroupUserMessages(obj);
      let getUserlist = JSON.parse(localStorage.getItem("userList"));
      let getUpdatedlist = getUserlist.map((item) => {
        if (item?.chatGroupId === getMessage?.toChatGroupId) {
          return {
            ...item,
            dateSent: getMessage?.dateSent,
            messageText: getMessage?.messageText,
            fromUserFirstName: getMessage?.fromFirstName,
            fromUserLastName: getMessage?.fromLastName,
          };
        } else {
          return item;
        }
      });
      localStorage.setItem("userList", JSON.stringify(getUpdatedlist));
      props.setUserlist(getUpdatedlist);
    }
  };

  const getUnreadmsgcount = async (getMessage) => {
    let getCount = await getUnreadMessagecount(
      Number(localStorage.getItem("userId")),
      getMessage?.toChatGroupId
    );
    if (getCount?.length > 0) {
      let getUserlist = JSON.parse(localStorage.getItem("userList"));
      let getUpdatedlist = getUserlist.map((item) => {
        if (item?.chatGroupId === getMessage?.toChatGroupId) {
          return {
            ...item,
            unreadMessagesNr: getCount[0]?.unreadMessagesNr,
            dateSent: getMessage?.dateSent,
            messageText: getMessage?.messageText,
            active: "",
          };
        } else if (
          item.chatGroupId === Number(localStorage.getItem("chatGroupId"))
        ) {
          return {
            ...item,
            active: "active",
          };
        } else {
          return item;
        }
      });
      localStorage.setItem("userList", JSON.stringify(getUpdatedlist));
      props.setUserlist(getUpdatedlist);
    }
  };

  // Get communication message between sender and receiver
  const getChatmessages = async () => {
    let chatGroupData = await getChatGroupMessages(
      localStorage.getItem("userId"),
      props?.chatGroupUsers[0]?.chatGroupId,
      page.current,
      50
    );
    if (chatGroupData.length > 0) {
      let getMessagelist = chatList.current;
      const resultArray = chatGroupData.concat(getMessagelist);
      setMessageslist(resultArray);
      chatList.current = resultArray;
      props.setIsloader(false);
      setSendmessage("");
      if (messageContainerRef.current && page.current === 1) {
        messageContainerRef.current.addEventListener("scroll", handleScroll);
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    } else {
      props.setIsloader(false);
      page.current = page.current - 1;
    }
  };

  const onMessagechange = (e) => {
    if (e.target.value === "") {
      setSendbutton(true);
      setSendmessage("");
    } else {
      setSendbutton(false);
      setSendmessage(e.target.value);
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      onMessagesend();
    }
  };

  const onMessagesend = async () => {
    if (sendButton === false) {
      setSendbutton(true);
      let messageType;
      if (selectedImageUrls?.length > 0) {
        messageType = 2;
      } else if (sendMessage !== "" && sendMessage.trim() !== "") {
        messageType = 1;
      }
      if (showPagelink === true && location?.state?.from === "Detail") {
        messageType = 3;
      }
      await sendMessages(messageType);
    }
  };

  const sendMessages = async (messageType) => {
    switch (messageType) {
      case 1:
        let msgObj1 = {
          messageType: 1,
          message: sendMessage,
        };
        await messageSend(msgObj1);
        break;
      case 2:
        await Promise.all(
          selectedImageUrls.map(async (item) => {
            const formData = new FormData();
            formData.append("UserId", Number(localStorage.getItem("userId")));
            formData.append("Pictures", item?.file);
            const picUrl = await saveMessagePictures(formData);
            let msgObj2 = {
              messageType: 2,
              message: picUrl[0],
            };
            await messageSend(msgObj2);
          })
        );
        setSelectedImageUrls([]);
        setShowPreviewLink(false);
        if (sendMessage !== "") {
          let msgObj3 = {
            messageType: 1,
            message: sendMessage,
          };
          await messageSend(msgObj3);
        }
        break;
      case 3:
        let msgObj4 = {
          messageType: 3,
          message: location?.state?.messageText,
        };
        await messageSend(msgObj4);
        break;
      default:
        break;
    }
  };

  const messageSend = async (msgObj) => {
    await hubConnection.invoke(
      "BroadcastMessage",
      msgObj?.messageType === 1 ? sendMessage : "",
      "OK"
    );
    const chatGroupName =
      props?.chatGroupUsers.length != 0
        ? props?.chatGroupUsers.length > 2
          ? `${props?.chatGroupUsers[0]?.firstName}, ${
              props?.chatGroupUsers[1]?.firstName
            } and ${props?.chatGroupUsers.length - 2} others`
          : props?.chatGroupUsers.length == 2
          ? `${props?.chatGroupUsers[0]?.firstName} and ${props?.chatGroupUsers[1]?.firstName}`
          : props?.chatGroupUsers[0]?.firstName
        : "";
    await hubConnection
      .invoke(
        "SendMessageToChatGroup",
        Number(localStorage.userId),
        localStorage.firstName,
        localStorage.lastName,
        localStorage.pictureUrl,
        props?.chatGroupUsers[0]?.chatGroupId,
        msgObj?.message,
        msgObj?.messageType
      )
      .then(async () => {
        // await sendChatGroupFirebaseNotify(props?.chatGroupUsers[0]?.chatGroupId, chatGroupName, Number(localStorage.getItem('userId')));
        const obj = {
          chatGroupId: props?.chatGroupUsers[0]?.chatGroupId,
          UserId: Number(localStorage.userId),
          Notification: {
            ActionUrl: `${process.env.REACT_APP_CONVERSATIONSCREEN}${props?.chatGroupUsers[0]?.chatGroupId}`,
            title: chatGroupName,
            Body:
              msgObj?.messageType === 1
                ? sendMessage
                : "You have a new message",
            Tags: {
              chatGroupId: props?.chatGroupUsers[0]?.chatGroupId,
              chatGroupName: chatGroupName,
              UserId: Number(localStorage.userId),
            },
          },
        };
        await sendChatGroupFirebaseNotify(obj);
      })
      .catch((err) => {
        console.log("ErrorSendingMessage1:", err);
      });
    setSendmessage("");
    setShowpagelink(false);
  };

  const handleFileInputChange = async (e) => {
    let getImages = [...selectedImageUrls];
    if (e.target.files.length > 0) {
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i]?.type.startsWith("image/")) {
          const imageUrl = URL.createObjectURL(e.target.files[i]);
          let msgObj = {
            messageType: 2,
            message: "",
            blobUrl: imageUrl,
            file: e.target.files[i],
          };
          getImages.push(msgObj);
        }
      }
      setSelectedImageUrls(getImages);
      setSendbutton(false);
      setShowPreviewLink(true);
    }
  };

  const handleImageIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleScroll = async () => {
    if (messageContainerRef.current.scrollTop === 0) {
      page.current = page.current + 1;
      await getChatmessages();
    }
  };

  const navigateTosite = (params) => {
    if (params.type == "site") {
      history.push(`/sites/site/${params?.id}`);
    } else if (params.type == "product") {
      history.push(`/product/${params?.id}`);
    } else if (params.type == "trade") {
      history.push(`/trade/${params?.id}`);
    } else if (params.type == "company") {
      history.push(`/company/${params?.id}`);
    } else if (params.type == "manufacturer") {
      history.push(`/manufacturer/${params?.id}`);
    } else if (params.type == "project") {
      history.push(`/projects/project/${params?.id}`);
    } else {
      console.log("ANOTHER PAGE");
    }
  };

  const getPageLinkparams = (messageText) => {
    const splitUrl = messageText?.split("&");
    const params = {};
    splitUrl?.forEach((part) => {
      const [key, value] = part?.split("=");
      if (typeof value !== "undefined") {
        params[key] = decodeURIComponent(value.replace(/\+/g, " "));
      }
    });
    return params;
  };

  const closePagelink = () => {
    setShowpagelink(false);
    setSendbutton(true);
  };

  const closePreviewlink = (index) => {
    let imageUrls = selectedImageUrls.slice();
    imageUrls.splice(index, 1);
    setSelectedImageUrls(imageUrls);
    if (imageUrls.length === 0) {
      setShowPreviewLink(false);
      setSendbutton(true);
    }
  };

  const MessageLink = (props) => {
    let params = getPageLinkparams(props.message.messageText);
    return (
      <div className="messagelink" onClick={() => navigateTosite(params)}>
        <img
          onError={(e) => {
            e.target.src = defaultProjectImage;
          }}
          src={
            params?.picture === ""
              ? defaultProjectImage
              : params?.type === "trade"
              ? defaultTradeProfile
              : params?.picture
          }
          style={{ height: "60px", width: "60px" }}
        />
        <div className="site-detail">
          <h5 className="site-name">{params?.name}</h5>
          <span className="site-type">{params?.type}</span>
        </div>
      </div>
    );
  };

  const PageMessagelink = () => {
    let params = getPageLinkparams(location?.state?.messageText);
    return (
      <div className="message-link">
        <img
          onError={(e) => {
            e.target.src = defaultProjectImage;
          }}
          src={
            params?.type === "trade"
              ? defaultTradeProfile
              : params?.picture === ""
              ? defaultProjectImage
              : params?.picture
          }
          className="link-img"
        />
        <div>
          <h5 className="site-name">{params?.name}</h5>
          <span className="site-type">{params?.type}</span>
        </div>
        <div className="close-link" onClick={closePagelink}>
          <CloseIcon className="close-icon" />
        </div>
      </div>
    );
  };

  const SelectedImagePreview = () => {
    return (
      <div className="preview-message-link">
        {selectedImageUrls.map((imageUrl, index) => (
          <div key={index} className="image-preview-container">
            <img
              onError={(e) => {
                e.target.src = defaultProjectImage;
              }}
              src={imageUrl?.blobUrl}
              className="link-img"
            />
            <div className="close-link" onClick={() => closePreviewlink(index)}>
              <CloseIcon className="close-icon" />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const Getmydate = (props) => {
    const localStr = moment
      .parseZone(props.message.dateSent)
      .local()
      .format("h:mma");
    return props.message?.fromUserId !== Number(localStorage.getItem("userId"))
      ? !props.message?.isAdjacentMessage && (
          <span className="message-time">
            {props?.message?.fromFirstName?.trim() + " " + localStr}
          </span>
        )
      : !props.message?.isAdjacentMessage && (
          <span
            className="message-time"
            style={{ display: "flex", justifyContent: "end" }}
          >
            {localStr}
          </span>
        );
  };

  const deleteChat = async () => {
    const obj = {
      UserId: Number(localStorage.getItem("userId")),
      ChatGroupId: props?.chatGroupUsers[0]?.chatGroupId,
      ModifiedBy: Number(localStorage.getItem("userId")),
    };
    await leaveChatGroup(obj);
    history.push({
      pathname: "/chat",
      state: {
        from: "",
      },
    });
    setMoreMenuAnchorEl(null);
  };

  const showMembers = () => {
    if (props.chatGroupUsers.length === 1) {
      if (Number(localStorage.getItem("nrOfUsersInGroup")) == 1) {
        history.push(`/profile`);
      } else {
        history.push(
          `/user-dashboard-profile/${props.chatGroupUsers[0]?.userId}`
        );
      }
    } else {
      setMembersModalOpen(true);
    }
    setMoreMenuAnchorEl(null);
  };

  const handleAddContact = async (data) => {
    try {
      setShowBtnLoader(true);
      let obj = {
        UserId: localStorage.userId,
        FriendsEmails: data.userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(
            localStorage.userId
          )}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${contact?.userId}`
        },
      };
      await inviteFriendsByEmail(obj);
      let getUpdatedlist = memberList.map((item) => {
        if (data?.userId === item.userId) {
          return {
            ...item,
            contactStatus: "Request Sent",
          };
        }
        return item;
      });
      setMemberlist(getUpdatedlist);
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
    }
  };

  const navigateTochat = async (data) => {
    try {
      setShowBtnLoader(true);
      const saveListArray = [
        {
          Id: 0,
          ChatGroupId: 0,
          UserId: data?.userId,
          ModifiedBy: Number(localStorage.getItem("userId")),
          Muted: false,
        },
      ];
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };
      let result = await saveChatGroupUsers(chatGroupObj);
      if (result?.data?.length > 0) {
        let chatGroupId = result.data[0]?.objData.chatGroupId;
        history.push({
          pathname: "/chat",
          state: {
            from: "New Chat",
            chatGroupId: chatGroupId,
          },
        });
      }
      setShowBtnLoader(false);
      setMembersModalOpen(false);
    } catch (error) {
      setShowBtnLoader(false);
      setMembersModalOpen(false);
    }
  };

  const getMyDate = (date) => {
    if (!moment.isMoment(date)) date = moment(date);
    if (date.isSame(moment(), "day")) {
      return "Today";
    } else if (date.isSame(moment().subtract(1, "d"), "day")) {
      return "Yesterday";
    } else {
      return moment.parseZone(date).local().format("MMM DD");
    }
  };

  const renderDayText = (message, index) => {
    // const prevDate = index !== messagesList.length - 1 ? messagesList[index + 1]?.dateSent : null;
    const prevDate = index !== 0 ? messagesList[index - 1]?.dateSent : null;
    return (
      <>
        {moment(prevDate).format("YYYY-MM-DD") !=
        moment(message?.dateSent).format("YYYY-MM-DD") ? (
          <>
            <Stack
              alignItems={"center"}
              justifyContent={"space-between"}
              direction={"row"}
            >
              <Divider
                style={{
                  width: props?.fabButton ? "38%" : "42%",
                  borderColor: BIGrey,
                }}
              />
              <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                {getMyDate(moment(message?.dateSent))}
              </Typography>
              <Divider
                style={{
                  width: props?.fabButton ? "38%" : "42%",
                  borderColor: BIGrey,
                }}
              />
            </Stack>
          </>
        ) : null}
      </>
    );
  };

  const backUserlist = () => {
    props.setShowchat(false);
  };

  const muteNotification = async () => {
    try {
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ChatGroupId: props?.chatGroupUsers[0]?.chatGroupId,
        ModifiedBy: Number(localStorage.getItem("userId")),
        Muted: !isMutedValue() ? true : false,
      };
      let result = await muteChatGroup(chatGroupObj);
      setMoreMenuAnchorEl(null);
      await props?.getUserlist();
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const isMutedValue = () => {
    let userIsMutedData = props?.userList?.filter(
      (item) => item?.chatGroupId === props?.chatGroupUsers?.[0]?.chatGroupId
    );
    if (userIsMutedData?.[0]?.muted) {
      return true;
    } else {
      return false;
    }
  };

  return props?.isLoder ? (
    <div className={props?.fabButton ? "fab-loader" : "loader-style"}>
      <Loader />
    </div>
  ) : (
    <>
      {!props?.fabButton ? (
        <div className="chat-conversation">
          <div
            className={"builder-bucks-bar-header-div"}
            style={{
              justifyContent: "space-between",
              backgroundColor: "#ffeebf",
              fontSize: "12px",
              padding: "13px",
              color: BIBlue,
              alignItems: "center",
              borderBottom: "1px solid #CCCCCC",
            }}
          >
            <div className={"conversation-header"}>
              {window.innerWidth < 992 && (
                <ArrowBackIosIcon
                  onClick={backUserlist}
                  style={{
                    display: props.showChat ? "block" : "none",
                    fontSize: "17px",
                  }}
                />
              )}
              <p className="accordionSummary-font">
                <b>
                  {props?.chatGroupUsers?.length != 0
                    ? props?.chatGroupUsers?.length > 2
                      ? `${props?.chatGroupUsers[0]?.firstName?.trim()}, ${props?.chatGroupUsers[1]?.firstName?.trim()} and ${
                          props?.chatGroupUsers?.length - 2
                        } others`
                      : props?.chatGroupUsers?.length == 2
                      ? `${props?.chatGroupUsers[0]?.firstName?.trim()} and ${props?.chatGroupUsers[1]?.firstName?.trim()}`
                      : props?.chatGroupUsers[0]?.firstName?.trim() +
                        " " +
                        `${props?.chatGroupUsers[0]?.lastName?.trim()} ${
                          Number(localStorage.getItem("nrOfUsersInGroup")) == 1
                            ? "(me) 1"
                            : ""
                        }`
                    : ""}
                </b>
              </p>
            </div>
            <div>
              <IconButton
                onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={moreMenuAnchorEl}
                open={Boolean(moreMenuAnchorEl)}
                onClose={() => setMoreMenuAnchorEl(null)}
                className="hello"
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <MenuItem
                  style={{ color: "#083DB8" }}
                  dense
                  onClick={() => showMembers()}
                >
                  {props.chatGroupUsers.length === 1
                    ? "View Profile"
                    : "Show Members"}
                </MenuItem>
                {props?.chatGroupUsers?.length > 1 && (
                  <MenuItem
                    style={{ color: "#083DB8" }}
                    dense
                    onClick={() => muteNotification()}
                  >
                    {isMutedValue()
                      ? "Unmute Notification"
                      : "Mute Notification"}
                  </MenuItem>
                )}
                {Number(localStorage.getItem("nrOfUsersInGroup")) ==
                1 ? null : (
                  <MenuItem
                    style={{ color: BIRed }}
                    dense
                    onClick={() => deleteChat()}
                  >
                    {props.chatGroupUsers.length === 1
                      ? "Delete Chat"
                      : "Leave Chat"}{" "}
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setMoreMenuAnchorEl(false);
                    props.setReportModalOpenSearch(true);
                  }}
                  style={{ color: BIRed }}
                  dense
                >
                  Report
                </MenuItem>
              </Menu>
            </div>
          </div>
          <Box
            ref={messageContainerRef}
            sx={{
              height: showPagelink || showPreviewlink ? "45vh" : "67vh",
              flexGrow: 1,
              overflow: "auto",
              p: 1,
            }}
          >
            {messagesList?.map((message, index) => (
              <>
                {renderDayText(message, index)}
                <div>
                  <Getmydate message={message} />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent:
                        message?.fromUserId ===
                        Number(localStorage.getItem("userId"))
                          ? "flex-end"
                          : "flex-start",
                      mb: 2,
                    }}
                  >
                    <Paper
                      variant="outlined"
                      className="message-paper"
                      sx={{
                        p: 2,
                        backgroundColor:
                          message?.fromUserId ===
                          Number(localStorage.getItem("userId"))
                            ? "#FFBC0A"
                            : "#CCCCCC",
                        borderRadius:
                          message?.fromUserId ===
                          Number(localStorage.getItem("userId"))
                            ? "8px 1px 8px 8px"
                            : "1px 8px 8px 8px",
                      }}
                    >
                      {message?.messageTypeId === 1 ? (
                        <>
                          <Typography variant="body1" className="messages">
                            {message?.messageText}
                          </Typography>
                        </>
                      ) : message?.messageTypeId === 2 ? (
                        <Link
                          href={message?.messageText}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={message?.messageText}
                            className="conversation-img"
                          ></img>
                        </Link>
                      ) : (
                        <MessageLink message={message} />
                      )}
                    </Paper>
                  </Box>
                </div>
              </>
            ))}
          </Box>
          {showPagelink && (
            <>
              <hr style={{ color: "#CCCCCC" }} />
              <PageMessagelink />
            </>
          )}
          {showPreviewlink && (
            <>
              <hr style={{ color: "#CCCCCC" }} />
              <SelectedImagePreview />
            </>
          )}
          <Box sx={{ p: 2, backgroundColor: "background.default" }}>
            <div className="send-box">
              <TextField
                fullWidth
                size="small"
                placeholder="Send a message"
                autoFocus
                variant="outlined"
                value={sendMessage}
                onChange={onMessagechange}
                onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ImageIcon
                        style={{ cursor: "pointer" }}
                        onClick={handleImageIconClick}
                      />{" "}
                      {/* Add the image icon here */}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileInputChange}
                        multiple
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <SendIcon
                className={`send-icon ${sendButton ? "disabled-color" : ""}`}
                onClick={onMessagesend}
              />
            </div>
          </Box>
        </div>
      ) : (
        <div>
          <div className="fab-conversation-screen">
            <div
              className="fab-screen-header"
              onClick={() => {
                props?.setExpanded(!props?.expanded);
              }}
            >
              <Typography variant="body2" fontWeight={"bold"} color={BIBlue}>
                {props?.chatGroupUsers?.length != 0
                  ? props?.chatGroupUsers?.length > 2
                    ? `${props?.chatGroupUsers[0]?.firstName?.trim()}, ${props?.chatGroupUsers[1]?.firstName?.trim()} and ${
                        props?.chatGroupUsers?.length - 2
                      } others`
                    : props?.chatGroupUsers?.length == 2
                    ? `${props?.chatGroupUsers[0]?.firstName?.trim()} and ${props?.chatGroupUsers[1]?.firstName?.trim()}`
                    : props?.chatGroupUsers[0]?.firstName?.trim() +
                      " " +
                      `${props?.chatGroupUsers[0]?.lastName?.trim()} ${
                        Number(localStorage.getItem("nrOfUsersInGroup")) == 1
                          ? "(me)"
                          : ""
                      }`
                  : ""}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  props?.setExpanded(!props?.expanded);
                }}
              >
                {props?.expanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </div>
            <IconButton size="small" onClick={props?.handleFabMenuClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          {!props?.expanded && (
            <div className="chat-conversation fab-chat-conversation">
              <Box
                ref={messageContainerRef}
                sx={{
                  height: showPagelink || showPreviewlink ? "45vh" : "71vh",
                  flexGrow: 1,
                  overflow: "auto",
                  p: 1,
                }}
              >
                {messagesList?.map((message, index) => (
                  <>
                    {renderDayText(message, index)}
                    <div>
                      <div style={{ width: "295px" }}>
                        <Getmydate message={message} />
                      </div>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent:
                            message?.fromUserId ===
                            Number(localStorage.getItem("userId"))
                              ? "flex-end"
                              : "flex-start",
                          mb: 0.5,
                        }}
                      >
                        <Paper
                          variant="outlined"
                          className="message-paper"
                          sx={{
                            p: 2,
                            backgroundColor:
                              message?.fromUserId ===
                              Number(localStorage.getItem("userId"))
                                ? "#FFBC0A"
                                : "#CCCCCC",
                            borderRadius:
                              message?.fromUserId ===
                              Number(localStorage.getItem("userId"))
                                ? "8px 1px 8px 8px"
                                : "1px 8px 8px 8px",
                          }}
                        >
                          {message?.messageTypeId === 1 ? (
                            <>
                              <Typography
                                variant="body1"
                                className={
                                  props?.fabButton ? "fab-messages" : "messages"
                                }
                              >
                                {message?.messageText}
                              </Typography>
                            </>
                          ) : message?.messageTypeId === 2 ? (
                            <Link
                              href={message?.messageText}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={message?.messageText}
                                className="conversation-img"
                              ></img>
                            </Link>
                          ) : (
                            <MessageLink message={message} />
                          )}
                        </Paper>
                      </Box>
                    </div>
                  </>
                ))}
              </Box>
              {showPagelink && (
                <>
                  <hr style={{ color: "#CCCCCC" }} />
                  <PageMessagelink />
                </>
              )}
              {showPreviewlink && (
                <>
                  <hr style={{ color: "#CCCCCC" }} />
                  <SelectedImagePreview />
                </>
              )}
              <Box sx={{ p: 0.5, backgroundColor: "background.default" }}>
                <div className="fab-senBox">
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Send a message"
                    autoFocus
                    variant="outlined"
                    value={sendMessage}
                    onChange={onMessagechange}
                    onKeyPress={handleKeyPress}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <ImageIcon
                            fontSize="medium"
                            style={{ cursor: "pointer" }}
                            onClick={handleImageIconClick}
                          />{" "}
                          {/* Add the image icon here */}
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileInputChange}
                            multiple
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <IconButton size="medium" onClick={onMessagesend}>
                    <SendIcon
                      fontSize="small"
                      className={` ${sendButton ? "disabled-color" : ""}`}
                    />
                  </IconButton>
                </div>
              </Box>
            </div>
          )}
        </div>
      )}
      {membersModalOpen && (
        <MemberListModal
          isOpen={membersModalOpen}
          onClose={() => {
            setMembersModalOpen(false);
          }}
          members={memberList}
          profileMemberList={false}
          handleConnectClick={handleAddContact}
          handleMessageClick={navigateTochat}
          showBtnLoader={showBtnLoader}
        />
      )}
    </>
  );
}

export default ConversationScreen;
