import React, { useState, useEffect } from "react";
import {
  Modal,
  IconButton,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Publish as UploadIcon,
} from "@material-ui/icons";
import moment from "moment/moment";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { BIBlue, BIGrey, BIRed } from "../../assets/buildidColors";
// import Autocomplete from '@mui/material/Autocomplete';
import { Autocomplete } from "@material-ui/lab";
import {
  deleteSafetyObservations,
  deleteSafetyObservationsPictures,
  fetchSites,
  saveSafetyObservations,
  saveSafetyObservationsPictures,
  searchUsersMembers,
} from "../../api/SafetyObservationApi";
import PromptModal from "../../components/PromptModal";
import AlertModal from "../../components/AlertModal";

export default function SafetyObservationModal(props) {
  const today = moment();
  const [selectedDate, setSelectedDate] = useState(today.format("YYYY-MM-DD"));
  const [visitedBy, setVisitedBy] = useState(0);
  const [site, setSite] = useState("");
  const [observation, setObservation] = useState("");
  const [pictureList, setPictureList] = useState([]);
  const [safetyObservationsId, setSafetyObservationsId] = useState(0);
  const [imminentDangerChecked, setImminentDangerChecked] = useState(false);
  const [positiveObservationChecked, setPositiveObservationChecked] =
    useState(false);
  const [deletedImages, setDeletedImages] = useState([]);
  const [actionTaken, setActionTaken] = useState("");
  const [userMemberList, setUserMemberList] = useState([]);
  const [visitedByInputValue, setVisitedByInputValue] = useState("");
  const [flag, setFlag] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [siteFlag, setSiteFlag] = useState(false);
  const [siteInputValue, setSiteInputValue] = useState("");
  const [deleteSafetyObservationAlert, setDeleteSafetyObservationAlert] =
    useState(false);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);

  let form_headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  function handleChooseFile(event) {
    const files = event.target.files;
    const pictures = [];
    Array.from(files).forEach((file) => pictures.push(file));
    setPictureList([...pictureList, ...pictures]);
  }

  const handleDateChange = (date) => {
    const newDate = moment(date).format("YYYY-MM-DD");
    setSelectedDate(newDate);
  };

  const handleVisitedBy = (event, value) => {
    setVisitedBy(value.id);
  };

  const handleSite = (event) => {
    setSite(event.target.value);
  };

  const handleObservation = (event) => {
    setObservation(event.target.value);
  };

  const handleIsImminentDangerChecked = (e) => {
    setImminentDangerChecked(e.target.checked);
  };

  const handleIsPositiveObservationChecked = (e) => {
    setPositiveObservationChecked(e.target.checked);
  };

  const handleActionTaken = (e) => {
    setActionTaken(e.target.value);
  };

  const onDeletePicturePressed = (item, index) => {
    if (item.pictureUrl) deletedImages.push(item);
    const pictures = pictureList.filter((pic, i) => i !== index);
    setDeletedImages([...deletedImages]);
    setPictureList([...pictures]);
  };

  const handleSaveSafetyObservations = async () => {
    try {
      if (deletedImages.length !== 0) {
        const deleteArray = deletedImages.map((deletePic) => {
          return { Id: deletePic.id, ModifiedBy: localStorage.userId };
        });

        const deleteObj = {
          UserId: Number(localStorage.getItem("userId")),
          ReturnRecordId: false,
          ReturnRecordError: true,
          DeleteList: deleteArray,
        };
        await deleteSafetyObservationsPictures(deleteObj);
      }

      const saveSafetyObservationsObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: props.editSafetyObservationsData?.id || 0,
            VisitedDate: selectedDate,
            VisitedById:
              props.editSafetyObservationsData?.visitedById || visitedBy,
            SiteId: props.editSafetyObservationsData?.siteId || site,
            Observation: observation,
            IsImminentDanger: imminentDangerChecked,
            IsPositiveObservation: positiveObservationChecked,
            ActionTaken: actionTaken,
            RecordStatusId: 1,
            ModifiedBy: Number(localStorage.getItem("userId")),
          },
        ],
      };

      const saveSafetyObservationsRes = await saveSafetyObservations(
        saveSafetyObservationsObj
      );

      const newPictureList = pictureList?.filter((pic) => !pic.pictureUrl);
      if (newPictureList && newPictureList.length > 0) {
        newPictureList.map(async (img, index) => {
          const formData1 = new FormData();
          formData1.append("Id", 0);
          formData1.append(
            "SafetyObservationId",
            props.editSafetyObservationsData?.id ||
              saveSafetyObservationsRes.data[index].objData.id
          );
          formData1.append("PictureUrl", img);
          formData1.append("Comment", "Test");
          formData1.append("RecordStatusId", 1);
          formData1.append(
            "ModifiedBy",
            Number(localStorage.getItem("userId"))
          );
          await saveSafetyObservationsPictures(formData1, form_headers).catch(
            (err) => {
              if (err === "Content Inappropriate") {
                setInappropriateImageAlert(true);
              }
            }
          );
        });
      }
      // });
      props.onClose();
      props.dataAddedSuccessfully();
      if (saveSafetyObservationsRes.message === "OK") {
        // setSaveSafetyObservationsAlert(true);
        props.onClose();
        await clearEditData();
        // await props.setForceUpdateComponent();
      } else {
        await clearEditData();
        // await props.setForceUpdateComponent();
      }
    } catch (error) {
      console.log("SaveSafetyObservationsError:", error);
    }
  };

  const handleDeleteSafetyObservations = async (safetyObservationId) => {
    try {
      const deleteSafetyObservationObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: safetyObservationId,
            ModifiedBy: Number(localStorage.getItem("userId")),
          },
        ],
      };
      const deleteSafetyObservationRes = await deleteSafetyObservations(
        deleteSafetyObservationObj
      );
      props.onClose();
      props.dataAddedSuccessfully();
      if (deleteSafetyObservationRes.message === "OK") {
        setDeleteSafetyObservationAlert(false);
        await clearEditData();
        // await props.setForceUpdateComponent();
      } else {
        await clearEditData();
        // await props.setForceUpdateComponent();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearEditData = () => {
    setSelectedDate(moment(today).format("YYYY-MM-DD"));
    setVisitedBy(0);
    setSite("");
    setObservation("");
    setPictureList([]);
    setPositiveObservationChecked(false);
    setImminentDangerChecked(false);
    setVisitedByInputValue("");
    setSiteInputValue("");
    setActionTaken("");
    setSafetyObservationsId(0);
  };

  useEffect(() => {
    const fetchEditSafetyObservations = () => {
      if (!props.editSafetyObservationsData.id) {
        props.setSafetyObservationsModalOpen();
      } else {
        props.setPageNr(1);
        setSafetyObservationsId(props.editSafetyObservationsData?.id);
        // setVisitedBy(props.editSafetyObservationsData?.visitedBy);
        setObservation(props.editSafetyObservationsData?.observation);
        setSelectedDate(
          moment(props.editSafetyObservationsData?.dateCreated).format(
            "YYYY-MM-DD"
          )
        );
        setPictureList(props.editSafetyObservationsData?.pictureList);
        setImminentDangerChecked(
          props.editSafetyObservationsData?.isImminentDanger
        );
        setPositiveObservationChecked(
          props.editSafetyObservationsData?.isPositiveObservation
        );
        setActionTaken(props.editSafetyObservationsData?.actionTaken);

        Promise.resolve(props.editSafetyObservationsData?.siteId).then(() =>
          setSite(props.editSafetyObservationsData?.siteId)
        );
        Promise.resolve(props.editSafetyObservationsData?.visitedById).then(
          () => setVisitedBy(props.editSafetyObservationsData?.visitedById)
        );
        Promise.resolve(props.editSafetyObservationsData?.siteName).then(() =>
          setSiteInputValue(props.editSafetyObservationsData?.siteName)
        );
        Promise.resolve(props.editSafetyObservationsData?.visitedBy).then(() =>
          setVisitedByInputValue(props.editSafetyObservationsData?.visitedBy)
        );
      }
    };
    fetchEditSafetyObservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editSafetyObservationsData]);

  useEffect(() => {
    async function fetchUserMembers() {
      const searchMemeberRes = await searchUsersMembers();
      setUserMemberList(searchMemeberRes);
    }

    async function fetchSiteList() {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{}],
        SortList: [{}],
      };
      const fetchSiteRes = await fetchSites(obj);
      setSiteList(fetchSiteRes.data);
    }

    try {
      fetchUserMembers();
      fetchSiteList();
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div>
      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />

      <PromptModal
        isOpen={deleteSafetyObservationAlert}
        onClose={() => {
          setDeleteSafetyObservationAlert(false);
        }}
        title="Are you sure you want to Delete?"
        redButtonText="Delete"
        onRedButtonClick={() =>
          handleDeleteSafetyObservations(props.editSafetyObservationsData?.id)
        }
      />

      <Modal
        open={props.isOpen}
        onClose={async () => {
          props.onClose();
          await clearEditData();
          // await props.setForceUpdateComponent();
        }}>
        <div className="modal-center timesheet-modal" style={{ top: "45%" }}>
          <div className="modal-card">
            <div
              className="modal-card-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <div className="modal-card-header-buttons">
                <IconButton
                  color="primary"
                  onClick={async () => {
                    props.onClose();
                    await clearEditData();
                    // await props.setForceUpdateComponent();
                  }}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div>
                <h2>
                  {safetyObservationsId !== 0 ? "Edit" : "Add"} Safety
                  Observations
                </h2>
              </div>
            </div>
            <div
              className="modal-card-body"
              style={{
                padding: "10px 15px 0px 0px",
                overflowY: "auto",
                maxHeight: "80vh",
              }}>
              <div className="input-container">
                {pictureList?.length > 0 ? (
                  pictureList?.map((item, picIndex) => {
                    return (
                      <div key={picIndex} style={{ position: "relative" }}>
                        <IconButton
                          aria-label="Close"
                          onClick={() => onDeletePicturePressed(item, picIndex)}
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "190px",
                          }}>
                          <CloseIcon />
                        </IconButton>
                        <img
                          src={
                            item.pictureUrl
                              ? item.pictureUrl
                              : URL.createObjectURL(item)
                          }
                          alt="Safety Observations"
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <img style={{ display: "none" }} alt="" />
                )}
              </div>

              <div className="input-container">
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="label"
                  startIcon={<UploadIcon />}>
                  Upload Image
                  <input
                    id="safetyObservationsPicture-upload"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleChooseFile}
                    hidden
                  />
                </Button>
              </div>

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}>
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Safety Observations Date"
                    format="YYYY-MM-DD"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => handleDateChange(date)}
                  />
                </div>
              </div>

              <div className="input-container">
                <Autocomplete
                  freeSolo
                  inputValue={visitedByInputValue}
                  onInputChange={(event, value) => {
                    setVisitedByInputValue(value);
                    setFlag(true);
                  }}
                  label="Visited By"
                  id="visited-by"
                  options={userMemberList}
                  // defaultValue={props.editSafetyObservationsData?.VisitedById || ""}
                  onChange={(event, value) => {
                    setVisitedBy(value?.userId);
                    setFlag(false);
                  }}
                  getOptionLabel={(option) =>
                    `${option?.firstName}  ${option?.lastName}`
                  }
                  style={{ flex: 1 }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          {...params}
                          label="Visited By"
                          variant="outlined"
                        />
                      </>
                    );
                  }}
                  open={visitedByInputValue?.length > 0 && flag}
                />
              </div>

              <div className="input-container">
                <Autocomplete
                  freeSolo
                  inputValue={siteInputValue}
                  onInputChange={(event, value) => {
                    setSiteInputValue(value);
                    setSiteFlag(true);
                  }}
                  label="Visited By"
                  id="visited-by"
                  options={siteList}
                  onChange={(event, value) => {
                    setSite(value?.id);
                    setSiteFlag(false);
                  }}
                  getOptionLabel={(option) => `${option?.name}`}
                  style={{ flex: 1 }}
                  renderInput={(params) => {
                    return (
                      <>
                        <TextField
                          {...params}
                          label="Site"
                          variant="outlined"
                        />
                      </>
                    );
                  }}
                  open={siteInputValue?.length > 0 && siteFlag}
                />
              </div>

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}>
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Observation"
                    value={observation}
                    onChange={handleObservation}
                  />
                </div>
              </div>

              <div className="input-container">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: BIBlue }}
                        checked={imminentDangerChecked}
                        onChange={handleIsImminentDangerChecked}
                      />
                    }
                    label={
                      <Typography style={{ color: BIGrey }}>
                        Imminent Danger
                      </Typography>
                    }
                  />{" "}
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: BIBlue }}
                        checked={positiveObservationChecked}
                        onChange={handleIsPositiveObservationChecked}
                      />
                    }
                    label={
                      <Typography style={{ color: BIGrey }}>
                        Positive Observation
                      </Typography>
                    }
                  />{" "}
                </div>
              </div>

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}>
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Action Taken/Required"
                    value={actionTaken}
                    onChange={handleActionTaken}
                  />
                </div>
              </div>

              <div
                className="input-container"
                style={{ padding: "20px 0px", display: "flex" }}>
                {safetyObservationsId !== 0 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    size="medium"
                    component="label"
                    disabled={false}
                    startIcon={<DeleteIcon />}
                    onClick={() => setDeleteSafetyObservationAlert(true)}
                    style={{
                      marginRight: "10px",
                      color: BIRed,
                      borderColor: BIRed,
                    }}>
                    Delete
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  component="label"
                  disabled={false}
                  startIcon={<SaveIcon />}
                  onClick={handleSaveSafetyObservations}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
