import { Box, Button, Card, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Select, Stack, TextField, Typography, useScrollTrigger } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./ClientTimesheets.css"
import AddIcon from '@mui/icons-material/Add';
import "./ClientTimesheets.css";
import { getClientUDFieldsbyClientId } from "../../../api/ClientApi";
import { Close as CloseIcon } from "@material-ui/icons";
import ActivityHours from "./ActivityHours.jsx";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function ActivitiesModal(props) {

    const fileInputRef = useRef(null);
    const picIndex = useRef(null);
    const [showActivityhours, setShowactivityhours] = useState(false);
    const [parentIndex, setParentindex] = useState(null);

    useEffect(() => {
       if (props.showJobActivities === false) {
            getUDfields();
        }
    }, [props.showJobActivities]);

    const getUDfields = async () => {
        const getResponse = await Promise.all(props.selectedJobActivities.map(async (activity) => {
            const obj = {
                clientId: localStorage?.clientId,
                appId: localStorage?.ClientAppId,
                entityRecordId: activity?.id,
                EntityType: "Activity",
            };
            const res = await getClientUDFieldsbyClientId(obj);
            // If Activity alredy selected Udfield 
            if (activity?.udField?.length > 0) {
                let getSelected = res.map((item, index) => {
                    let getSeleted = activity?.udField.filter((field) => field?.id === item.id);
                    if (getSeleted?.length > 0) {
                        return {
                            ...res[index], checked: getSeleted[0]?.checked
                        }
                    } else {
                        return { ...item, checked: false };
                    }
                });
                return {
                    ...activity,
                    udField: getSelected,
                    selectedEmp: activity?.hoursChange === true ? activity?.selectedEmp : props.selectedEmp,
                }
            } else {
                return {
                    ...activity,
                    udField: res?.map((item) => { return { ...item, checked: false } }),
                    selectedEmp: activity?.hoursChange === true ? activity?.selectedEmp : props.selectedEmp,
                }
            }
        }));
        props.setSelectJobActivities(getResponse);
    }

    const openJobactivities = () => {
        props.SetShowjobactivities(true);
    }

    const onSelectimage = (index) => {
        console.log("index--->",index);
        picIndex.current = index;
        fileInputRef.current.click();
    }

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            let getPictures = props.selectedJobActivities[picIndex.current]?.pictures;
            console.log("getPictures--->",getPictures);
            let getList = props.selectedJobActivities?.map((item, index) => {
                if (picIndex.current === index) {
                    return {
                        ...item,
                        pictures: [...getPictures, { id: 0, isDeleted: false, imgUrl: imageUrl, imgFile: file }]
                    }
                } else {
                    return item;
                }
            });
            console.log("getList--->",getList)
            props.setSelectJobActivities(getList);
        }
        event.target.value = "";
    };

    const removeJobActivities = (act) => {
        if (act?.id !== 0) {
            let getUpdatedactlist = props?.selectedJobActivities?.map((item) => {
                if (item?.id === act?.id) {
                    return {
                        ...item,
                        Deleted: true,
                        isSelected: false,
                    }
                } else {
                    return item
                }
            })
            props.setSelectJobActivities(getUpdatedactlist);
        } else {
            let getList = props?.selectedJobActivities?.filter((item) => act?.id !== item?.id);
            props.setSelectJobActivities(getList);
        }
    }

    const onNoteschange = (e, getIndex) => {
        let getList = props.selectedJobActivities.map((item, index) => {
            if (getIndex === index) {
                return {
                    ...item,
                    notes: e.target.value === "" ? "" : e.target.value
                }
            } else {
                return item
            }
        })
        props.setSelectJobActivities(getList);
    }

    const onHoursClick = (getIndex) => {
        setParentindex(getIndex)
        setShowactivityhours(true);
    }

    const onQuantitychange = (e, getIndex) => {
        let getList = props.selectedJobActivities.map((item, index) => {
            if (getIndex === index) {
                return {
                    ...item,
                    quantity: e.target.value === "" ? "" : Number(e.target.value)
                }
            } else {
                return item
            }
        })
        props.setSelectJobActivities(getList);
    }


    const onUdfieldchange = (e, getIndex, udFieldindex) => {
        let getList = props.selectedJobActivities?.map((item, index) => {
            if (index === getIndex) {
                let getUdfield = item?.udField?.map((item, udIndex) => {
                    if (udIndex === udFieldindex) {
                        return {
                            ...item,
                            checked: e.target.checked
                        }
                    } else {
                        return item;
                    }
                })
                return {
                    ...item,
                    udField: getUdfield
                }
            } else {
                return item
            }
        });
        props.setSelectJobActivities(getList);
    }

    const closeTimesheetpicture = (item, activityIndex, picIndex) => {
        if (item?.id !== 0) {
            // If Image have id then we set isDeleted true
            let getUpdatelist = props?.selectedJobActivities?.map((item, index) => {
                if (index === activityIndex) {
                    let getPics = props?.selectedJobActivities[activityIndex]?.pictures.map((item, imgIndex) => {
                        if (picIndex === imgIndex) {
                            return {
                                ...item,
                                isDeleted: true
                            }
                        } else {
                            return item;
                        }
                    })
                    return {
                        ...item,
                        pictures: getPics
                    }
                } else {
                    return item
                }
            })
            props.setSelectJobActivities(getUpdatelist);
        } else {
            let getUpdatedpictures = props?.selectedJobActivities[activityIndex]?.pictures.filter((item, index) => index !== picIndex);
            let getUpdatedActivities = props?.selectedJobActivities?.map((item, index) => {
                if (index === activityIndex) {
                    return {
                        ...item,
                        pictures: getUpdatedpictures
                    }
                } else {
                    return item
                }
            })
            props.setSelectJobActivities(getUpdatedActivities);
        }
    }

    const renderHours = (item) => {
        let hours = 0;
        item?.selectedEmp?.map((item) => {
            if (item.Deleted === false) {
                hours = hours + item?.hours;
                return hours;
            }
        });
        return hours;
    }

    return (
        <>
            <Stack gap={2}>
                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                    <Typography fontSize={19} fontWeight={600}>Activities</Typography>
                    {props.selectedJobActivities?.length !== 0 && (
                            <Typography fontSize={16} color={"#1976d2"} fontWeight={500} style={{ cursor: "pointer" }} onClick={() => openJobactivities()}>Select activities</Typography>
                    )}
                </Stack>
                {props.selectedJobActivities?.length === 0 ? (
                    <Button color="primary" onClick={() => openJobactivities()} fullWidth variant="contained">Select Activities</Button>
                ) : (
                    <Box className="jobActivities-list">
                        {console.log("props.selectedJobActivities",props.selectedJobActivities)}
                        {props.selectedJobActivities.map((item, index) => (
                            item?.Deleted === false && (
                                <Card className="jobActivities-card">
                                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography fontSize={15} fontWeight={600}>{item?.activityName}</Typography>
                                        <IconButton
                                            color="primary"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => removeJobActivities(item)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Stack>
                                    <Stack flexDirection={"row"} gap={1} alignItems={"end"}>
                                        <Stack gap={1}>
                                            <Typography fontSize={15}>Hours</Typography>
                                            <div onClick={() => onHoursClick(index)} style={{ cursor: "pointer" }}>
                                                <TextField
                                                    disabled
                                                    style={{ pointerEvents: 'none' }}
                                                    value={renderHours(item)}
                                                    placeholder={"00.00"}
                                                    color="primary"
                                                    size={"small"}
                                                />
                                            </div>
                                        </Stack>
                                        <Stack gap={1}>
                                            <Typography fontSize={15}>Quantity</Typography>
                                            <TextField
                                                value={item?.quantity}
                                                onChange={(e) => onQuantitychange(e, index)}
                                                placeholder={"00.00"}
                                                color="primary"
                                                size={"small"}
                                            />
                                        </Stack>
                                        <Stack>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={10}
                                                    size="small"
                                                >
                                                    <MenuItem value={10}>sqrt</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Stack>
                                        {/* <ArrowDropDownIcon /> */}
                                    </Stack>
                                    <Stack>
                                        <FormGroup>
                                            {item?.udField?.map((item, udFieldindex) => (
                                                <FormControlLabel control={<Checkbox checked={item?.checked} onChange={(e) => onUdfieldchange(e, index, udFieldindex)} />} label={item?.fieldLabel} />
                                            ))}
                                        </FormGroup>
                                    </Stack>
                                    <Stack gap={1} marginBottom={1}>
                                        <Typography fontSize={15}>Notes</Typography>
                                        <TextField
                                            value={item?.notes}
                                            onChange={(e) => onNoteschange(e, index)}
                                            multiline
                                            placeholder={"Enter additional details(optional)"}
                                            color="primary"
                                            rows={2}
                                            className="notes-field"
                                        />
                                    </Stack>
                                    <Stack>
                                        <Typography fontSize={15}>Pictures</Typography>
                                        <Grid container gap={1} overflow={"scroll"} padding={"8px 0px"} flexWrap={"nowrap"} display={"-webkit-box"}>
                                            <input
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={(e) => handleImageSelect(e)}
                                            />
                                            <Grid sm={2} md={3} lg={3} xl={3}>
                                                <Box className="add-pic" onClick={() => onSelectimage(index)}>
                                                    <AddIcon color="#cccccc" />
                                                </Box>
                                            </Grid>
                                            {item?.pictures?.map((item, picIndex) => (
                                                item?.isDeleted === false && (
                                                    <Grid sm={2} md={3} lg={3} xl={3} position={"relative"} >
                                                        <IconButton color="primary" className="close-btn" onClick={() => closeTimesheetpicture(item, index, picIndex)}>
                                                            <CloseIcon style={{ fontSize: "1.1rem" }} />
                                                        </IconButton>
                                                        <Box>
                                                            <img className="add-pic" src={item?.imgUrl}></img>
                                                        </Box>
                                                    </Grid>
                                                )))}
                                        </Grid>
                                    </Stack>
                                </Card>
                            )))
                        }
                    </Box>)}
            </Stack>
            <ActivityHours
                parentIndex={parentIndex}
                selectedJobActivities={props.selectedJobActivities}
                setSelectJobActivities={props.setSelectJobActivities}
                showActivityhours={showActivityhours}
                onClose={() => setShowactivityhours(false)}
            />
        </>
    )

}