import React, { useEffect, useState } from 'react'
import { ClientHeader } from '../ClientHeader'
import { DeleteClientTimesheets, FetchClientTimesheetJobs, FetchClientTimesheets2 } from '../../../api/ClientApi';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Chip, IconButton, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import PromptModal from '../../../components/PromptModal';
import Loader from '../../../components/Loader';
import { BIGrey } from '../../../assets/buildidColors';
import AddClientTimesheetIndividualModal from './AddClientTimesheetIndividualModal';

const ViewClientIndividualTimesheet = () => {

    const { id } = useParams();
    let history = useHistory();

    let userId = localStorage?.userId ?? 0;
    let clientAccentColor = localStorage.getItem("clientAccentColor");

    const [initialData, setInitialData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [isLoading, setIsLoading] = useState(true)
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isShowFullScreenImage, setIsShowFullScreenImage] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [rootIndex, setRootIndex] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [showAddClientIndividualTimesheetModal, setShowAddClientIndividualTimesheetModal] = useState(false);


    useEffect(() => {
        if (id) {
            getClientTimeSheetDetails();
        }
    }, [])


    const getClientTimeSheetDetails = async () => {
        try {
            const clientTimesheetObject = {
                PageNr: 1,
                FullSearch: '',
                UserId: userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{
                    Id: id
                }]
            }
            await FetchClientTimesheets2(clientTimesheetObject).then(async (res1) => {
                setHeaderData(res1?.data?.[0]);
                const timesheetJobObject = {
                    PageNr: 1,
                    FullSearch: '',
                    UserId: userId,
                    IncludeRecordNr: true,
                    FetchAllowedRecordsOnly: false,
                    SearchList: [{
                        ClientTimesheetId: res1?.data[0]?.id
                    }]
                };
                const res = await FetchClientTimesheetJobs(timesheetJobObject);
                setInitialData(res?.data);
                setIsBannerVisible(true);
                setIsLoading(false);
            });
        } catch (error) {
            setIsLoading(false);
            console.log("Error while getting time sheet data :: ", error);
        }
    }

    const onEditClick = () => {
        // history.push(`/client-timesheet/individual/${id}`, {
        //     data: initialData,
        //     headerData: headerData,
        //     from: "viewindividualtimesheet",
        // });
        setShowAddClientIndividualTimesheetModal(true);
        return;
    }

    const onDeleteClick = () => {
        setShowDeleteModal(true);
    }

    const getColorStyle = (val) => {
        if (val === '0.00' || val == 0.00) {
            return "#8d8d8d";
        } else {
            return "#000000";
        }
    };

    const deleteTimesheet = async () => {
        setIsLoading(true);
        try {
            const deleteObject = {
                userId: Number(userId),
                returnRecordError: true,
                softDelete: false,
                deleteList: [{ id: Number(id) }]
            };
            const deleteResponse = await DeleteClientTimesheets(deleteObject);
            if (deleteResponse.status) {
                setIsLoading(false);
                history.replace('/client-timesheets');
            } else {
                setIsLoading(false);
                alert("Something went wrong. Please try again later.")
            }
        } catch (e) {
            setIsLoading(false);
            console.log("CLIENT : [onDeletePressed] :ERROR > ", e);
        }
    };


    return (
        <>
            <ClientHeader screenName={"Timesheets"} />
            <div style={{ paddingTop: '20px' }}>
                {
                    !isLoading ?
                        (
                            <>
                                <div style={{ width: '90%', margin: '10px auto' }}>
                                    <div>
                                        {(headerData?.status === 'Approved' || headerData?.status === 'Rejected') &&
                                            (
                                                <>
                                                    <div style={{
                                                        backgroundColor: headerData?.status === 'Rejected' ? '#fabebc' : '#c6d8ba',
                                                        color: headerData?.status === 'Rejected' ? '#812322' : '#275418,'
                                                    }}>
                                                        <Typography style={{ fontWeight: '500' }}>
                                                            {headerData?.status === 'Rejected' ? 'This Timesheet has been rejected.' : 'This Timesheet has been approved and cannot be edited.'}
                                                        </Typography>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>

                                    <Stack direction={'row'} justifyContent={"space-between"}>
                                        <Stack direction={'row'} justifyContent={"space-between"} style={{ width: '60%' }}>
                                            <div>
                                                <Typography style={{ color: BIGrey, fontSize: '14px' }}>Date</Typography>
                                                <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 'bold' }}>{moment(headerData?.date).format("MMMM D, YYYY")}</Typography>
                                            </div>
                                            <div>
                                                <Typography style={{ color: BIGrey, fontSize: '14px' }}>Submitted on</Typography>
                                                <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 'bold' }}>{moment(headerData?.dateSubmitted).format("MMMM D, YYYY")}</Typography>
                                            </div>
                                        </Stack>

                                        <Stack direction={'row'} justifyContent={"flex-end"} style={{ width: '40%' }}>
                                            <div>
                                                {headerData?.status != 'Approved' &&
                                                    <IconButton
                                                        onClick={onEditClick}
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                color: clientAccentColor ?? "",
                                                            }}
                                                        />
                                                    </IconButton>
                                                }
                                            </div>
                                            <div>
                                                <IconButton
                                                    onClick={onDeleteClick}
                                                >
                                                    <DeleteIcon
                                                        style={{
                                                            color: "red"
                                                        }}
                                                    />
                                                </IconButton>
                                            </div>
                                        </Stack>
                                    </Stack>
                                    <Stack style={{ marginTop: '20px' }}>
                                        <Typography variant='h6' style={{ fontSize: '16px', fontWeight: 'bold' }}>Jobs</Typography>
                                        {
                                            initialData?.map((item, index) => (
                                                <div key={index} style={{ margin: '10px 0px', border: '1px solid #e4e4e4', borderRadius: '12px', padding: '10px 10px 15px' }}>
                                                    <Stack>
                                                        <Typography>{item?.timesheetHoursList[0]?.clientJobCode}{' - '}{item?.timesheetHoursList[0]?.clientJobName}</Typography>
                                                    </Stack>


                                                    {
                                                        item?.timesheetHoursList[0]?.clientJobActivityName != '' ?
                                                            <div>
                                                                <Typography style={{ fontWeight: "500", color: "grey", fontSize: 14 }}>{item?.timesheetHoursList[0]?.clientJobActivityCode}{' - '}{item?.timesheetHoursList[0]?.clientJobActivityName}</Typography>
                                                            </div>
                                                            : null
                                                    }

                                                    <Stack direction={'row'} gap={2} style={{ marginTop: '10px', width: '50%', justifyContent: 'space-between' }}>
                                                        <div>
                                                            <Typography style={{ color: BIGrey, fontSize: '14px' }}>
                                                                Hours
                                                            </Typography>
                                                            <Typography style={{ color: getColorStyle(item?.hours) }}>
                                                                {parseFloat(item?.timesheetHoursList[0]?.hours).toFixed(2)}
                                                            </Typography>
                                                        </div>
                                                        <div>
                                                            <Typography style={{ color: BIGrey, fontSize: '14px' }}>
                                                                Mileage
                                                            </Typography>
                                                            <Typography>
                                                                {item?.timesheetEmployeesList?.length > 0
                                                                    ? parseFloat(item?.timesheetEmployeesList[0]?.mileage).toFixed(1)
                                                                    : 0}{' '}
                                                                {item?.timesheetEmployeesList?.length > 0
                                                                    ? item?.timesheetEmployeesList[0]?.mileageUnit
                                                                    : 'km'}
                                                            </Typography>
                                                        </div>
                                                    </Stack>

                                                    {
                                                        item?.udFieldsList?.length > 0 &&
                                                        (
                                                            <div style={{ marginTop: '10px' }}>
                                                                <Stack key={index} direction={'row'} gap={2}>
                                                                    {
                                                                        item?.udFieldsList?.map((udValues, index) => (
                                                                            (udValues?.value == 'true' || udValues?.value == true) ?
                                                                                <Chip label={udValues?.label} />
                                                                                : null
                                                                        ))
                                                                    }
                                                                </Stack>
                                                            </div>
                                                        )
                                                    }

                                                    {
                                                        item?.notes?.trim() !== "" &&
                                                        (
                                                            <div style={{ marginTop: '5px' }}>
                                                                <Typography> {item?.notes}</Typography>
                                                            </div>
                                                        )
                                                    }


                                                    {
                                                        item?.pictureList?.length > 0 &&
                                                        (
                                                            <div style={{ marginTop: '20px' }}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        gap: "10px",
                                                                        overflowX: "scroll",
                                                                        width: '80%',
                                                                        height: '125px'
                                                                    }}
                                                                >
                                                                    {
                                                                        item?.pictureList.map((img, index) => (
                                                                            <div style={{ width: '110px', height: '110px' }} key={index}>
                                                                                <img src={img?.fileUrl} style={{ height: '100%', width: '100%', borderRadius: '20px' }} />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                            ))
                                        }
                                    </Stack>
                                </div>
                            </>
                        )
                        :
                        (
                            <div style={{ height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Loader />
                            </div>
                        )
                }
                <div>
                    {
                        showDeleteModal &&
                        <PromptModal
                            isOpen={showDeleteModal}
                            onClose={() => {
                                setShowDeleteModal(false);
                            }}
                            title="Are you sure you want to delete this Timesheet? "
                            desc="This action cannot be undone."
                            clientActionButtonText="Delete"
                            clientAccentColor={clientAccentColor ?? ""}
                            clientActionButtonClick={() => {
                                deleteTimesheet();
                                setShowDeleteModal(false);
                            }}
                        />
                    }

                    {/* {
                        true &&
                        <JobActivitiesHoursModal
                            show={true}
                            handleClose={() => { }}
                        />
                    } */}
                </div>
            </div>
            {
                showAddClientIndividualTimesheetModal && (
                    <AddClientTimesheetIndividualModal
                        show={showAddClientIndividualTimesheetModal}
                        onClose={() => {
                            setShowAddClientIndividualTimesheetModal(false);
                        }}
                        onTimesheetAdded={() => {
                            setIsLoading(true);
                            getClientTimeSheetDetails();
                            setShowAddClientIndividualTimesheetModal(false);
                        }}
                        individualTimesheetData={initialData ?? null}
                        headerData={headerData ?? null}
                    />
                )
            }
        </>
    )
}

export default ViewClientIndividualTimesheet;