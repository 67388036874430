import { Button, Container, IconButton, LinearProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { BIBlue } from "../../assets/buildidColors";
import TradesCard from "./sections/TradesCard";
import ResumeBuilderModal from "./ResumeBuilderModal";
import EducationCard from "./sections/EducationCard";
import ExperienceCard from "./sections/ExperienceCard";
import TicketCard from "./sections/TicketCard";
import YourInfoCard from "./sections/YourInfoCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  fetchUserCompanies,
  fetchUserEmails2,
  fetchUserPhones2,
  fetchUserProducts,
  fetchUserTradeCertifications2,
  fetchUserTrades,
  fetchUserWorkHistory,
  generateResume,
} from "../../api/UserApi";
import { fetchTickets } from "../../api/TicketsApi";
import Loader from "../../components/Loader";
import SaveResumes from "./SaveReumes";
import moment from "moment";
import ProductsCard from "./sections/ProductsCard";
function removeDuplicates(originalArray, prop) {
  let newArray = [];
  let lookupObject = {};
  for (let i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }
  for (let i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}
function ResumeBuilder() {
  const [openResumeBuilderModal, setOpenResumeBuilderModal] = useState(false);
  const [workHistoryCompanies, setWorkHistoryCompanies] = useState([]); // List of user companies
  const [workHistorySites, setWorkHistorySites] = useState([]);
  const [userTrades, setUserTrades] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [product, setProduct] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [genResumeLink, setGenResumeLink] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSaveResumes, setShowSaveResumes] = useState(false);

  const [workHistory, setWorkHistory] = useState([]);
  const [unknownCompany, setUnknownCompany] = useState([]);

  const getExperienceDetails = async () => {
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 0,
        SearchList: [{ Id: 0, CreatedBy: Number(localStorage.userId) }],
      };
      const userCompanyObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 0,
        SearchList: [{ UserId: Number(localStorage.userId) }],
      };

      const workHistory = await fetchUserWorkHistory(workHistoryObj);

      const userCompanies = await fetchUserCompanies(userCompanyObj);

      const noCompSites = workHistory.filter((comp) => comp.companyId == 0);
      let a = [];
      const expData = userCompanies.map((unique) => {
        a = unique;
        a["experience"] = [];
        a["isSelected"] = true;
        workHistory.map((tempData) => {
          if (tempData.companyId === unique.companyId) {
            let obj = {
              id: tempData.id,
              companyId: tempData.companyId,
              companyName: tempData.companyName,
              endDate: tempData.endDate,
              includeInResume: tempData.includeInResume,
              present: tempData.present,
              mainPictureUrl: tempData.mainPictureUrl,
              siteId: tempData.siteId,
              userCompanyId: tempData.userCompanyId,
              siteName: tempData.siteName,
              roleName: tempData.roleName,
              roleId: tempData.roleId,
              startDate: tempData.startDate,
              tradeId: tempData.tradeId,
              tradeName: tempData.tradeName,
              address: tempData.address,
              city: tempData.city,
              province: tempData.province,
              postalCode: tempData.postalCode,
              country: tempData.country,
              description: tempData.description,
              projectName: tempData.projectName,
              isSelected: true,
            };
            a["experience"].push(obj);
          }
        });
        return a;
      });
      let b = [];
      const noSiteExpData = noCompSites.map((unique) => {
        b = unique;
        b["experience"] = [];
        b["isSelected"] = true;
        workHistory.map((tempData) => {
          if (tempData.userCompanyId === 0) {
            let obj = {
              id: tempData.id,
              companyId: tempData.companyId,
              companyName: tempData.companyName,
              endDate: tempData.endDate,
              includeInResume: tempData.includeInResume,
              present: tempData.present,
              userCompanyId: tempData.userCompanyId,
              mainPictureUrl: tempData.mainPictureUrl,
              siteId: tempData.siteId,
              siteName: tempData.siteName,
              roleName: tempData.roleName,
              roleId: tempData.roleId,
              startDate: tempData.startDate,
              tradeId: tempData.tradeId,
              tradeName: tempData.tradeName,
              address: tempData.address,
              city: tempData.city,
              province: tempData.province,
              postalCode: tempData.postalCode,
              country: tempData.country,
              description: tempData.description,
              projectName: tempData.projectName,
              isSelected: true,
            };
            b["experience"].push(obj);
          }
        });
        return b;
      });
      const totalExpData = [...expData, ...noSiteExpData];
      const sortedExpData = totalExpData
        .filter((op) => op.startDate && op.companyName != "")
        .sort((a, b) => {
          let dateA = new Date(a.startDate),
            dateB = new Date(b.startDate);
          return dateB - dateA;
        });
      const noDateExp = totalExpData.filter(
        (exp) =>
          !exp.startDate &&
          !exp.endDate &&
          exp.companyId != 0 &&
          exp.companyName !== ""
      );
      const unknownExpData = totalExpData
        .filter((exp) => exp.companyName == "")
        .sort((a, b) => {
          let dateA = new Date(a.startDate),
            dateB = new Date(b.startDate);
          return dateB - dateA;
        });
      const finalExpData = [...sortedExpData, ...noDateExp];

      setWorkHistory(finalExpData);
      setUnknownCompany(unknownExpData);
      const newUnknownExpData = removeDuplicates(unknownExpData, "companyId");
    } catch (err) {
      console.log("Error-----------", err);
    }
  };

  async function fetchUserTrade() {
    const res = await fetchUserTrades({
      SearchList: [{ userId: Number(localStorage.userId) }],
    });
    let a = [];
    const newResponse = res
      ?.sort(
        (a, b) =>
          a.tradeType.toLowerCase().localeCompare(b.tradeType.toLowerCase()) ||
          a.tradeName
            .trim()
            .toLowerCase()
            .localeCompare(b.tradeName.trim().toLowerCase())
      )
      .map((value) => {
        a = value;
        a["isSelected"] = true;
        return a;
      });
    setUserTrades(newResponse);
  }

  async function fetchEducation() {
    fetchUserTradeCertifications2({
      SearchList: [{ UserId: localStorage.userId }],
    }).then((res) => {
      if (res) {
        let certificationsData = [];
        res.forEach((cert) => {
          certificationsData.push({
            id: cert.id || 0,
            userId: cert.userId || 0,
            userName: cert.userName || "",
            school: cert.school || "",
            fieldOfStudy: cert.fieldOfStudy || "",
            years: cert.years || 0,
            tradeReceived: cert.tradeReceived || "",
            startDate: cert.startDate || "",
            endDate: cert.endDate || "",
            dateCreated: cert.dateCreated || "",
            dateModified: cert.dateModified || "",
            createdBy: cert.createdBy || "",
            modifiedBy: cert.modifiedBy || "",
            createdByUserName: cert.createdByUserName || "",
            modifiedByUserName: cert.modifiedByUserName || "",
            isSelected: true,
          });
        });

        certificationsData = certificationsData.sort((a, b) => {
          if (a.startDate == "" || a.startDate == null) a.startDate = moment();
          if (b.startDate == "" || b.startDate == null) b.startDate = moment();
          let dateA = new Date(a.startDate),
            dateB = new Date(b.startDate);
          return dateB - dateA;
        });
        setCertifications(certificationsData);
      }
    });
  }
  async function UserProducts() {
    fetchUserProducts({
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: Number(localStorage.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ UserId: Number(localStorage.userId) }],
    }).then((res) => {
      if (res) {
        let productData = [];
        res.forEach((prod) => {
          productData.push({
            id: prod.productId || 0,
            userId: prod.userId || 0,
            productName: prod.productName || "",
            productType: prod.productType || "",
            manufacturerName: prod.manufacturerName || "",
            isSelected: true,
          });
        });

        setProduct(productData);
      }
    });
  }

  async function fetchUserTickets() {
    try {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        SearchList: [{ Id: 0, CreatedBy: localStorage?.userId }],
        IncludeRecordNr: false,
      };
      const tickets = await fetchTickets(obj);
      let b = [];
      const userTickets = tickets.map((tick) => {
        b = tick;
        b["isSelected"] = true;
        return b;
      });
      const validTicket = userTickets.filter((value) => {
        const current = moment().format("YYYY-MM-DDThh:mm:ss");
        if (value.expiry >= current || value.expiry == null) return true;
        return false;
      });
      const expiredTicket = userTickets.filter((value) => {
        const current = moment().format("YYYY-MM-DDThh:mm:ss");
        if (value.expiry <= current && value.expiry != null) return value;
      });
      setTickets([
        ...validTicket.sort((a, b) => {
          let dateA = new Date(a.issuedOn),
            dateB = new Date(b.issuedOn);
          return dateB - dateA;
        }),
        ...expiredTicket.sort((a, b) => {
          let dateA = new Date(a.issuedOn),
            dateB = new Date(b.issuedOn);
        }),
      ]);
    } catch (err) {
      console.log("error while getting user ticket list :: ", err);
    }
  }

  function fetchYourInfo() {
    try {
      Promise.all([
        fetchUserEmails2({ SearchList: [{ UserId: localStorage.userId }] }),
        fetchUserPhones2({ SearchList: [{ UserId: localStorage.userId }] }),
      ]).then(([emailRes, phoneRes]) => {
        let emailData = [];
        if (emailRes) {
          emailRes.forEach((email) => {
            emailData.push({
              id: email.id || 0,
              userId: email.userId || localStorage.userId,
              emailType: email.emailType || "",
              email: email.email || "",
              verified: email.verified || "",
              modifiedBy: email.modifiedBy || localStorage.userId,
              isSelected: false,
            });
          });
        }

        const primEmail = {
          email: localStorage.primaryEmail,
          emailType: "Primary",
          verified: true,
          id: 0,
          isSelected: true,
        };

        setEmails([primEmail, ...emailData]);

        let phoneData = [];
        let hasSelectedHPhone = false; // To track whether an "H" phone has been selected
        if (phoneRes) {
          phoneRes.forEach((phone) => {
            const isSelected = phone.phoneType === "M" && !hasSelectedHPhone;
            if (isSelected) {
              hasSelectedHPhone = true; // Marking that an "H" phone has been selected
            }
            phoneData.push({
              id: phone.id || 0,
              userId: phone.userId || localStorage.userId,
              phoneType: phone.phoneType || "",
              phoneNo: phone.phoneNo || "",
              modifiedBy: phone.modifiedBy || localStorage.userId,
              isSelected: isSelected,
            });
          });
        }

        setPhones(phoneData);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const companyItemHandlePressed = (item, index) => {
    const updatedWorkHistory = workHistory.map((itemCompany, i) => {
      if (i === index) {
        if (!itemCompany.isSelected) {
          itemCompany.experience.map((itemSite) => {
            return (itemSite.isSelected = true);
          });
        } else {
          itemCompany.experience.map((itemSite) => {
            return (itemSite.isSelected = false);
          });
        }
        itemCompany.isSelected = itemCompany.isSelected ? false : true;
        return itemCompany;
      }
      return itemCompany;
    });
    setWorkHistory(updatedWorkHistory ?? []);
  };

  const noCompanyprojectItemHandlePressed = ({ item, index, projectIndex }) => {
    const cloneExperiences = [...unknownCompany];
    const newData = cloneExperiences.map((j, jIndex) => {
      if (jIndex === projectIndex) {
        j.isSelected = !j.isSelected;
      }
    });
    setUnknownCompany(cloneExperiences);
    return;
  };

  const projectItemHandlePressed = ({ item, index, projectIndex }) => {
    const cloneExperiences = workHistory;

    const newData = cloneExperiences.map((company, companyIndex) => {
      if (companyIndex === index) {
        const updatedExperience = company.experience.map((project, pIndex) => {
          if (pIndex === projectIndex) {
            return {
              ...project,
              isSelected: !project.isSelected
            };
          } else {
            return project;
          }
        });
        return {
          ...company,
          experience: updatedExperience
        };
      } else {
        return company;
      }
    });
    setWorkHistory(newData);

    return;
  };

  const handleToggle = (index, itemType) => {
    if (itemType === "KnownCompanies") {
      workHistory.map((itemCompany) => {
        if (itemCompany.companyId === index) {
          if (!itemCompany.isSelected) {
            itemCompany.experience.map((itemSite) => {
              return (itemSite.isSelected = true);
            });
          } else {
            itemCompany.experience.map((itemSite) => {
              return (itemSite.isSelected = false);
            });
          }
          itemCompany.isSelected = itemCompany.isSelected ? false : true;
          return itemCompany;
        }
        return itemCompany;
      });
      setWorkHistory([...workHistory]);
    }

    if (itemType === "UnknownCompanies") {
      unknownCompany.map((itemCompany) => {
        if (itemCompany.companyId === index) {
          if (!itemCompany.isSelected) {
            itemCompany.experience.map((itemSite) => {
              return (itemSite.isSelected = true);
            });
          } else {
            itemCompany.experience.map((itemSite) => {
              return (itemSite.isSelected = false);
            });
          }
          itemCompany.isSelected = itemCompany.isSelected ? false : true;
          return itemCompany;
        }
        return itemCompany;
      });

      setUnknownCompany([...unknownCompany]);
    }

    if (itemType === "ProjectCard") {
      let count = 0;

      workHistory.map((itemSite) => {
        if (itemSite.id === index) {
          itemSite.isSelected = itemSite.isSelected ? false : true;

          workHistory.map((itemCompany) => {
            if (itemCompany.companyId === itemSite.companyId) {
              itemCompany.experience.map((op) => {
                if (op.isSelected) {
                  count++;
                }
                return op;
              });
              if (count !== 0) {
                return (itemCompany.isSelected = true);
              } else {
                return (itemCompany.isSelected = false);
              }
            }
            return itemCompany;
          });
        }
        return itemSite;
      });
      setWorkHistory([...workHistory]);
      setWorkHistoryCompanies([...workHistoryCompanies]);
    }

    if (itemType === "UnKnownCompanyProjectCard") {
      let count = 0;
      workHistorySites.map((itemSite) => {
        if (itemSite.id === index) {
          itemSite.isSelected = itemSite.isSelected ? false : true;

          unknownCompany.map((itemCompany) => {
            if (itemCompany.companyId === itemSite.companyId) {
              itemCompany.experience.map((op) => {
                if (op.isSelected) {
                  count++;
                }
                return op;
              });
              if (count !== 0) {
                return (itemCompany.isSelected = true);
              } else {
                return (itemCompany.isSelected = false);
              }
            }
            return itemCompany;
          });
        }
        return itemSite;
      });
      setWorkHistorySites([...workHistorySites]);
      setUnknownCompany([...unknownCompany]);
    }
    if (itemType === "EducationCard") {
      certifications.map((itemCert) => {
        if (itemCert.id === index) {
          itemCert.isSelected = itemCert.isSelected ? false : true;
        }
        return itemCert;
      });
      setCertifications([...certifications]);
    }
    if (itemType === "ProductsCard") {
      product.map((itemCert) => {
        if (itemCert.id === index) {
          itemCert.isSelected = itemCert.isSelected ? false : true;
        }
        return itemCert;
      });
      setProduct([...product]);
    }

    if (itemType === "TradesCard") {
      userTrades.map((itemTrade) => {
        if (itemTrade.id === index) {
          itemTrade.isSelected = itemTrade.isSelected ? false : true;
        }
        return itemTrade;
      });
      setUserTrades([...userTrades]);
    }

    if (itemType === "TicketCard") {
      tickets.map((itemTicket) => {
        if (itemTicket.id === index) {
          itemTicket.isSelected = itemTicket.isSelected ? false : true;
        }
        return itemTicket;
      });
      setTickets([...tickets]);
    }

    if (itemType === "EmailCard") {
      emails.map((itemEmail) => {
        if (itemEmail.id === index) {
          itemEmail.isSelected = true;
        } else {
          itemEmail.isSelected = false;
        }
        return itemEmail;
      });
      setEmails([...emails]);
    }

    if (itemType === "PhoneCard") {
      phones.map((itemPhone) => {
        if (itemPhone.id === index) {
          itemPhone.isSelected = true;
        } else {
          itemPhone.isSelected = false;
        }
        return itemPhone;
      });
      setPhones([...phones]);
    }
  };
  const toggleComponents = () => {
    setShowSaveResumes(!showSaveResumes);
  };
  async function handleGenerateResume() {
    setLoading(true)
    const selectedEmail = emails
      .filter((itemEmail) => itemEmail.isSelected)
      .map(({ email }) => email);
    const selectedphone = phones
      .filter((itemPhone) => itemPhone.isSelected)
      .map(({ phoneNo }) => phoneNo);
    const updatePhoneNo = selectedphone
      .toString()
      .replace(/[`~!@#$%^*()_|+\-=?;:'"' ',.<>]/gi, "");
    const selectedTrades = userTrades
      .filter((trade) => trade.isSelected)
      .map(({ tradeName }) => tradeName);

    // const selectedCompanies = workHistory.filter(
    //   ({ isSelected }) => isSelected
    // );

    // const selectedUnknownCompanies = unknownCompany.filter(
    //   ({ isSelected }) => isSelected
    // );
    
    // Filtering Both Companies
    let selectedCompanies = [];

    const workHistoryCopy = JSON.parse(JSON.stringify(workHistory));
    const companies = workHistoryCopy
    .map((comp) => {
      if (comp?.isSelected) {
        delete comp.experience;
        return comp;
      } else {
        return null;
      }
    })
    .filter(Boolean);
    const unknownCompanyCopy = JSON.parse(JSON.stringify(unknownCompany));
    const companies1 = unknownCompanyCopy
    .map((comp) => {
      if (comp?.isSelected) {
        delete comp.experience;
        return comp;
      } else {
        return null;
      }
    })
    .filter(Boolean);
    selectedCompanies.push(...companies,...companies1)

    // Filtering Users Experience
    const experiences =[]
    workHistory.map((work)=>{
      if(work.experience.length !=0){
        work.experience.map((exp)=>{
          experiences.push(exp)
        })
      }
    })

    const selectedTickets = tickets
      .filter((ex) => ex.isSelected)
      .map((shared) => {
        delete shared.sharedTicketsList;
        return shared;
      });
    const selectedEducation = certifications.filter(
      ({ isSelected }) => isSelected
    );
    const selectedProduct = product.filter(({ isSelected }) => isSelected);
    const obj = {
      Companies: selectedCompanies,
      Educations: selectedEducation,
      ProductList: selectedProduct,
      Email: selectedEmail.toString(),
      Experiences: experiences,
      PhoneNo: updatePhoneNo,
      Tickets: selectedTickets,
      UserTrades: selectedTrades,
      firstName: String(localStorage.firstName),
      lastName: String(localStorage.lastName),
      userId: Number(localStorage.userId),
    };

    const res = await generateResume(obj);

    if (res.message === "OK") {
      try {
        setGenResumeLink(res.data);
        setOpenResumeBuilderModal(true);
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log("Error: ", error);
      }
    }
  }

  useEffect(() => {
    try {
      getExperienceDetails();
      fetchUserTrade();
      fetchEducation();
      fetchUserTickets();
      fetchYourInfo();
      UserProducts();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);
  const [step, setStep] = useState(1);
  const totalSteps = 3;
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };
  return (
    <div>
      <ResumeBuilderModal
        genResumeLink={genResumeLink}
        isOpen={openResumeBuilderModal}
        onClose={() => setOpenResumeBuilderModal(false)}
      />
      {showSaveResumes ? (
        <SaveResumes />
      ) : (
        <div>
          <div className="page rewards-page">
            <div className="header">
              <div className="page-content">
                <div style={{ display: "flex" }}>
                  <div>
                    <h1>Resume Builder</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page">
            <div className="page-content padding-lg">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div>
                    <div
                      className="resp-container-horizontal"
                      style={{ marginTop: "-45px" }}>
                      {step === 1 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          component="label"
                          startIcon={<CancelOutlinedIcon />}
                          style={{
                            background: BIBlue,
                            color: "white",
                            marginTop: "10px",
                          }}
                          onClick={toggleComponents}>
                          Back
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          component="label"
                          startIcon={<ArrowBackIcon />}
                          style={{
                            background: BIBlue,
                            color: "white",
                            marginTop: "10px",
                          }}
                          onClick={handlePreviousStep}
                        />
                      )}
                    </div>
                    <Container style={{ marginTop: "10px" }}>
                      {" "}
                      <LinearProgress
                        variant="determinate"
                        value={step * 33.34}
                        sx={{ height: "8px" }}
                      />
                    </Container>
                    <div
                      className="flex-container-vertical"
                      style={{ flex: 2 }}>
                      <div className="card" style={{ maxHeight: "100%" }}>
                        <div className="flex-container-horizontal">
                          <div
                            className="flex-container-horizontal"
                            style={{ flex: 1 }}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}>
                              <p>
                                Choose information from your Profile to build a
                                Resume.
                              </p>
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "5px",
                                  color: BIBlue,
                                }}>
                                <h2>
                                  {" "}
                                  {step}/{totalSteps}
                                </h2>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {step === 1 && (
                      <div className="resp-container-horizontal">
                        <div
                          className="flex-container-vertical"
                          style={{ flex: 2 }}>
                          <YourInfoCard
                            handleToggle={handleToggle}
                            emails={emails}
                            phones={phones}
                          />
                        </div>
                        <div
                          className="flex-container-vertical"
                          style={{ flex: 2 }}>
                          <TradesCard
                            handleToggle={handleToggle}
                            userTrades={userTrades}
                          />
                        </div>
                      </div>
                    )}
                    <div className="resp-container-horizontal">
                      {step === 2 && (
                        <div
                          className="flex-container-vertical"
                          style={{ flex: 2 }}>
                          <ExperienceCard
                            companyItemHandlePressed={companyItemHandlePressed}
                            projectItemHandlePressed={projectItemHandlePressed}
                            noCompanyprojectItemHandlePressed={
                              noCompanyprojectItemHandlePressed
                            }
                            workHistory={workHistory}
                            unknownCompany={unknownCompany}
                            itemType="ExperienceCard"
                          />
                        </div>
                      )}
                      {step === 3 && (
                        <div
                          className="flex-container-vertical"
                          style={{ flex: 2 }}>
                          <EducationCard
                            handleToggle={handleToggle}
                            certifications={certifications}
                          />
                        </div>
                      )}
                    </div>
                    {step === 3 && (
                      <div className="resp-container-horizontal">
                        <div
                          className="flex-container-vertical"
                          style={{ flex: 2 }}>
                          <TicketCard
                            handleToggle={handleToggle}
                            tickets={tickets}
                          />
                        </div>
                      </div>
                    )}
                    {step === 3 && (
                      <div className="resp-container-horizontal">
                        <div
                          className="flex-container-vertical"
                          style={{ flex: 2 }}>
                          <ProductsCard
                            handleToggle={handleToggle}
                            product={product}
                          />
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        textAlign: "right",
                        marginTop: "20px",
                        paddingRight: "20px",
                      }}>
                      {step !== 3 ? (
                        <Button
                          variant="contained"
                          style={{
                            background: BIBlue,
                            color: "white",
                          }}
                          onClick={handleNextStep}>
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={handleGenerateResume}
                          style={{
                            background: BIBlue,
                            color: "white",
                          }}>
                          Generate Resume
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResumeBuilder;
