import React, { useRef, useState, useEffect } from "react";
import { Close } from "@material-ui/icons";
import {
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../assets/css/ClientHome.css";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteClientSafetyObservationsPictures,
  fetchClientJobs,
  getClientUDFieldsbyClientId,
  saveClientSafetyObservationPictures,
  saveClientSafetyObservations,
} from "../../../api/ClientApi";
import moment from "moment";
import PictureViewer from "../../../components/PictureViewer";
import { Checkbox } from "@material-ui/core";
import { BILightGrey } from "../../../assets/buildidColors";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { LoadingButton } from "@mui/lab";

export const AddSafetyObservationModal = (props) => {
  console.log("props?.", props);

  const {
    isOpen,
    onClose,
    reLoadData,
    from,
    data,
    clientJobsData,
    individualSafetyObservation,
    getSafetyIncidentsList,
    closeViewObservationModal,
  } = props;
  const hint = useRef("");
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [uploadedImageList, setUploadedImageList] = useState([]);
  const [clientJobList, setClientJobList] = useState([]);
  const [description, setDescription] = useState("");
  const [safetyObservationDate, setSafetyObservationDate] = useState(
    new Date()
  );
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [deleteImageList, setDeleteImageList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const [clientUDFields, setClientUDFields] = useState([]);
  const imgRef = useRef(null);

  useEffect(() => {
    if (from == "SAR") {
      setInputValue(clientJobsData?.jobName);
      setSelectedValue({
        clientEmployeeId: clientJobsData?.clientEmployeeId,
        clientJobCodeId: clientJobsData?.clientJobId,
      });
      getUDfields(data?.id);
    }
    console.table({
      clientEmployeeId: data?.id,
      clientJobCodeId: data?.clientJobId,
    });
  }, []);

  const getClientJobs = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: inputValue,
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            clientEmployeeId: localStorage.getItem("clientEmployeeId"),
            ClientId: localStorage.getItem("clientId"),
          },
        ],
        SortList: [{ FieldName: "dateModified", Direction: "DESC" }],
      };
      const clientJobRes = await fetchClientJobs(obj);
      if (Array.isArray(clientJobRes?.data) && clientJobRes.data.length > 0) {
        clientJobRes.data.forEach((obj) => {
          if (obj.jobCode) {
            obj.label = obj.jobCode;
          }
        });
        setClientJobList(clientJobRes?.data);
      }
    } catch (error) {
      console.error("getClientJobs Error ", error);
    }
  };

  useEffect(() => {
    getClientJobs();
  }, [inputValue]);

  useEffect(() => {
    if (individualSafetyObservation) {
      let udfields = [];
      const {
        description,
        pictureList,
        safetyObservationDate,
        clientJobId,
        clientJobCode,
        udFieldsList,
      } = individualSafetyObservation;
      setSelectedValue({
        clientEmployeeId: localStorage.clientEmployeeId,
        clientJobCodeId: clientJobId,
      });
      setInputValue(clientJobCode);
      setDescription(description);
      setUploadedImageList(pictureList);
      setSafetyObservationDate(safetyObservationDate);
      // getUDfields(clientJobId)
      udFieldsList.map((item) => {
        udfields.push({
          fieldDefaultValue: item?.value,
          fieldLabel: item?.label,
          id: item.id,
        });
      });
      setClientUDFields(udfields);
    }
  }, [individualSafetyObservation]);

  const handleSafetyObservationSubmit = async () => {
    try {
      setLoading(true);

      // Delete images if needed
      if (deleteImageList.length > 0) {
        const deletePromises = deleteImageList.map(async (item) => {
          const deletePicObj = {
            UserId: localStorage.userId,
            ReturnRecordId: true,
            ReturnRecordError: true,
            SoftDelete: true,
            DeleteList: [{ Id: item.id }],
          };
          await deleteClientSafetyObservationsPictures(deletePicObj);
        });
        await Promise.all(deletePromises);
      }

      const { clientEmployeeId, clientJobCodeId } = selectedValue;
      let ud = {};
      clientUDFields.forEach((field) => {
        ud[field.id] = field.fieldDefaultValue === "true" ? 1 : 0;
      });
      const convertedJson = JSON.stringify(ud);

      let obj = {
        UserId: localStorage.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: individualSafetyObservation?.id || 0,
            ClientEmployeeId: Number(clientEmployeeId),
            SafetyObservationDate: safetyObservationDate,
            ClientJobId: Number(clientJobCodeId),
            Description: description,
            SARId: from === "SAR" ? data?.id : 0,
            udFields: convertedJson,
            ModifiedBy: localStorage.userId,
          },
        ],
      };

      const res = await saveClientSafetyObservations(obj);
      if (res.message === "OK") {
        if (uploadedImageList.length > 0) {
          const uploadPromises = uploadedImageList.map(async (picture) => {
            const formData = new FormData();
            formData.append("Id", picture.id || 0);
            formData.append("SafetyObservationId", res.data[0]?.objData?.id);
            formData.append("ModifiedBy", localStorage.userId);
            formData.append("FileName", picture);
            await saveClientSafetyObservationPictures(formData);
          });
          await Promise.all(uploadPromises);
        }

        // Close modal and reset states
        onClose();
        closeViewObservationModal && closeViewObservationModal();
        if (from === "SAR") {
          reLoadData();
        }
        getSafetyIncidentsList();

        // Call resetData after successful submission
        resetData();
        setDescription("");
      }
      setLoading(false);
    } catch (error) {
      console.error("saveClientSafetyObservations Error ", error);
      setLoading(false);
    }
  };

  const getUDfields = async (clientJOBid) => {
    try {
      const obj = {
        clientId: localStorage?.clientId,
        appId: localStorage.ClientAppId,
        entityRecordId: selectedValue?.clientJobCodeId || clientJOBid,
      };
      const res = await getClientUDFieldsbyClientId(obj);
      setClientUDFields(res);

      res.reduce((acc, ele) => {
        if (ele.udFieldTypeId == 1) {
          acc[ele.id] = ele.fieldDefaultValue === "true";
        }
        return acc;
      }, {});
    } catch (err) {}
  };

  const handelCheckboxChange = (e, id) => {
    const { checked } = e.target;
    setClientUDFields((prev) => {
      return prev.map((field) => {
        if (field.id === id) {
          field.fieldDefaultValue = checked ? "true" : "false";
        }
        return field;
      });
    });
  };

  const resetData = () => {
    setInputValue("");
    setSelectedValue(null);
    setUploadedImageList([]);
    setDeleteImageList([]);
    setDescription("");
    setSafetyObservationDate(null);
    setClientUDFields([]);
  };

  const handleAddImage = () => {
    imgRef.current.click();
  };

  const ImgUrl = (file) => {
    try {
      let url = URL.createObjectURL(file);
      return url;
    } catch (error) {
      return file;
    }
  };

  const handleImageChange = (event) => {
    let temp = [...uploadedImageList];
    let files = event.target.files;
    for (let i = 0; i < files.length; i++) {
      temp.push(files[i]);
    }
    setUploadedImageList(temp);
    event.target.value = "";
  };

  const handleDate = (date) => {
    if (date) setSafetyObservationDate(date.format().substring(0, 10));
    else setSafetyObservationDate(null);
  };

  const handleRemoveImage = (picture, index) => {
    const deleteList = [...deleteImageList];
    if (picture.id) {
      deleteList.push({ id: picture?.id });
    }
    setDeleteImageList(deleteList);
    let temp = [...uploadedImageList];
    temp.splice(index, 1);
    setUploadedImageList(temp);
  };

  const handleImagePreviewClick = (index) => {
    setPictureViewIndex(index);
    setPictureViewerOpen(true);
  };
  console.log("hit", hint);

  return (
    <>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={imgRef}
        style={{ display: "none" }}
        multiple
      />

      <Modal
        open={isOpen}
        onClose={() => {
          resetData();
          onClose();
        }}>
        <div className="modal-center" style={{ outline: "none" }}>
          <div
            className="modal-card"
            style={{
              padding: "20px",
              minHeight: "500px",
              width: "600px",
              outline: "none",
              overflowY: "auto",
            }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}>
              <IconButton
                color="primary"
                onClick={() => {
                  resetData();
                  onClose();
                }}
                style={{ padding: "0px" }}>
                <Close />
              </IconButton>
            </div>
            <div style={{ padding: "10px" }}>
              <Grid>
                <Typography margin={0} variant="subtitle1" gutterBottom>
                  Date
                </Typography>

                <KeyboardDatePicker
                  onClick={() => {
                    setDatePickerOpen(true);
                  }}
                  onClose={() => {
                    setDatePickerOpen(false);
                  }}
                  open={datePickerOpen}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true, // Prevent manual editing
                    style: {
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      height: "40px",
                      padding: "0 10px",
                    },
                  }}
                  inputVariant={"standard"}
                  size="small"
                  onChange={handleDate}
                  value={
                    safetyObservationDate
                      ? moment(safetyObservationDate).format("YYYY-MM-DD")
                      : null
                  }
                  className="date-picker"
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  fullWidth
                  autoOk={true}
                  KeyboardButtonProps={{
                    style: { color: localStorage.clientAccentColor }, // Change icon color here
                  }}
                />
              </Grid>

              <Grid>
                <Typography margin={0} variant="subtitle1" gutterBottom>
                  Job
                </Typography>
                <Autocomplete
                  disabled={props?.from === "VIEWOBS" ? true : false}
                  sx={{
                    // border: "1px solid blue",
                    "& .MuiOutlinedInput-root": {
                      // border: "1px solid yellow",
                      borderRadius: "0",
                      padding: "0",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                  }}
                  size="small"
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                  }}
                  fullWidth
                  onKeyDown={(event) => {
                    if (event.key === "Tab") {
                      if (hint.current) {
                        setInputValue(hint.current);
                        event.preventDefault();
                      }
                    }
                  }}
                  onBlur={() => {
                    hint.current = "";
                  }}
                  disablePortal
                  inputValue={inputValue}
                  filterOptions={(options, state) => {
                    const displayOptions = options.filter(
                      (option) =>
                        option.label
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase().trim()) ||
                        (option.name &&
                          option.name
                            .toLowerCase()
                            .includes(state.inputValue.toLowerCase().trim()))
                    );

                    return displayOptions;
                  }}
                  id="combo-box-hint-demo"
                  options={clientJobList}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <Typography>{`${option.label} - ${option.name}`}</Typography>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    console.log(newValue);
                    getUDfields(newValue?.clientId);
                    setSelectedValue({
                      clientEmployeeId: localStorage.clientEmployeeId,
                      clientJobCodeId: newValue?.id,
                    });
                    if (newValue) {
                      setInputValue(`${newValue.label} - ${newValue.name}`); // Update the input value to the selected label
                    } else {
                      setInputValue("");
                    }
                  }}
                  renderInput={(params) => (
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        sx={{
                          position: "absolute",
                          opacity: 0.5,
                          left: 14,
                          top: 16,
                        }}>
                        {hint.current}
                      </Typography>
                      <TextField
                        disabled={from == "SAR" ? true : false}
                        {...params}
                        onFocus={() => setInputValue("")}
                        onBlur={() => {
                          if (individualSafetyObservation) {
                            if (!inputValue)
                              setInputValue(
                                individualSafetyObservation?.clientJobCode
                              );
                          }
                        }}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setInputValue(newValue);
                          const matchingOption = clientJobList.find((option) =>
                            option.label.startsWith(newValue)
                          );

                          if (newValue && matchingOption) {
                            hint.current = matchingOption.label;
                          } else {
                            hint.current = "";
                          }
                        }}
                      />
                    </Box>
                  )}
                />
              </Grid>
              <Grid>
                <Typography margin={0} variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <TextField
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                    padding: "0 10px",
                  }}
                  fullWidth
                  multiline
                  rows={5}
                  placeholder="Description the problem and what needs to be done."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid display={"flex"} gap={2} marginTop={2}>
                <div
                  onClick={handleAddImage}
                  style={{
                    cursor: "pointer",
                    height: "100px",
                    minWidth: "100px",
                    border: "2px solid lightgrey",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <AddIcon />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "80%",
                    height: "100%",
                    overflow: "scroll",
                  }}>
                  {uploadedImageList?.map((picture, index) => (
                    <div style={{ position: "relative", height: "100px" }}>
                      <img
                        src={picture?.fileUrl || ImgUrl(picture)}
                        alt={"NO data"}
                        style={{
                          width: "100px",
                          height: "93px",
                          borderRadius: "10px",
                        }}
                        onClick={() => handleImagePreviewClick(index)}
                      />
                      <IconButton
                        onClick={() => handleRemoveImage(picture, index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "5px",
                          backgroundColor: BILightGrey,
                          color: "white",
                        }}>
                        <CloseIcon style={{ fontSize: "16px" }} />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid>
                {clientUDFields.length != 0 &&
                  clientUDFields.map((field, index) => {
                    return (
                      <>
                        <Checkbox
                          color="primary"
                          checked={
                            field?.fieldDefaultValue == "true" ? true : false
                          }
                          onChange={(e) => handelCheckboxChange(e, field?.id)}
                        />
                        <span style={{ color: "grey" }}>
                          {field?.fieldLabel}
                        </span>
                      </>
                    );
                  })}
              </Grid>
              <Grid>
                <LoadingButton
                  onClick={handleSafetyObservationSubmit}
                  fullWidth
                  loadingPosition="start"
                  disabled={
                    !safetyObservationDate ||
                    !description ||
                    !selectedValue?.clientJobCodeId
                  }
                  style={{
                    marginTop: "10px",
                    background:
                      !safetyObservationDate ||
                      !description ||
                      !selectedValue?.clientJobCodeId
                        ? BILightGrey
                        : localStorage?.clientAccentColor,
                    color: "white",
                  }}
                  loading={loading}>
                  Submit
                </LoadingButton>
              </Grid>
            </div>
          </div>
        </div>
      </Modal>
      {pictureViewerOpen && (
        <PictureViewer
          isOpen={pictureViewerOpen}
          onClose={() => setPictureViewerOpen(false)}
          pictures={uploadedImageList?.map((pic) => ({
            src: pic?.fileUrl || ImgUrl(pic),
            alt: "Description",
          }))}
          viewerIndex={pictureViewIndex}
        />
      )}
    </>
  );
};
