import {
  Autocomplete,
  Avatar,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "../../assets/css/Chat.css";
import { useState } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { saveChatGroupUsers, searchUsersMembers } from "../../api/ChatApi";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import QRCode from "react-qr-code";
import { getDbValue } from "../../api/RestClient";
import { functions } from "lodash";
import { Button } from "@material-ui/core";

function NewChatModal(props) {
  const [userList, setUserlist] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const history = useHistory();
  const [qrCodeValue, setQrCodeValue] = useState(null);
  const [flag, setFlag] = useState(false);
  const [fieldData, setFieldData] = useState("");

  let getUrl = window.location.pathname.split("/").pop();

  useEffect(() => {
    const getQrCodeValue = async () => {
      await getDbValue();
      setQrCodeValueBasedOnDb();
    };
    getQrCodeValue();
    getUserList();
  }, []);

  const setQrCodeValueBasedOnDb = () => {
    let dbValue = localStorage?.db ?? null;
    if (dbValue) {
      let value = `${
        dbValue == 1
          ? process.env.REACT_APP_WEB_USER
          : dbValue == 2
          ? process.env.REACT_APP_WEB_USER
          : process.env.REACT_APP_WEB_USER
      }/refer/${localStorage.getItem("userId")}`;
      setQrCodeValue(value);
    }
  };

  const closeNewchat = () => {
    props.setShownewchat(false);
  };

  const createGroup = async () => {
    if (selectedItems?.length > 0) {
      // Filter out items where cont?.userId is not present
      const saveListArray = selectedItems
        .filter((cont) => cont?.userId) // Keep only items with a valid userId
        .map((cont) => {
          return {
            Id: 0,
            ChatGroupId: 0,
            UserId: Number(cont.userId),
            ModifiedBy: Number(localStorage.getItem("userId")),
            Muted: false,
          };
        });

      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };

      let result = await saveChatGroupUsers(chatGroupObj);

      if (result.data.length > 0) {
        let chatGroupId = result.data[0]?.objData.chatGroupId;
        props.setShownewchat(false);

        let messageText;
        switch (props?.from) {
          case "Company":
            messageText = `${process.env.REACT_APP_BASE_URL}company/${getUrl}&name=${props.title}&id=${getUrl}&type=company&picture=`;
            break;
          case "Manufacturer":
            messageText = `${process.env.REACT_APP_BASE_URL}manufacturer/${getUrl}&name=${props.title}&id=${getUrl}&type=manufacturer&picture=`;
            break;
          case "Product":
            messageText = `${process.env.REACT_APP_BASE_URL}product/${getUrl}&name=${props.title}&id=${getUrl}&type=product&picture=`;
            break;
          case "Trade":
            messageText = `${process.env.REACT_APP_BASE_URL}trade/${getUrl}&name=${props.title}&id=${getUrl}&type=trade&picture=`;
            break;
          default:
            history.push({
              pathname: "/chat",
              state: {
                from: "New Chat",
                chatGroupId: chatGroupId,
                createGroup: true,
              },
            });
            return; // Exit early if no valid 'from' prop is matched
        }

        history.push({
          pathname: "/chat",
          state: {
            from: "Detail",
            chatGroupId: chatGroupId,
            showInList: true,
            messageText: messageText,
          },
        });
      }
    }
  };

  const handleShareClick = () => {
    try {
      const message = `Check out BuildID today and become a part of a construction community that empowers and rewards you! \n user.mybuilderid.com/refer/${localStorage?.userId}`;
      const subject = encodeURIComponent("");
      const body = encodeURIComponent(message);
      const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;
    } catch (error) {
      console.log("Error while sharing :: ", error);
    }
  };

  const handleSelect = (event, value) => {
    if (value?.length === 0) {
      getUserList();
      setSelectedItems([]);
    } else {
      setFieldData("");
      setSelectedItems(value);
      setFlag(false);
    }
  };

  const getFilterUserList = async (params) => {
    if (params !== "") {
      const getValue = await searchUsersMembers(
        Number(localStorage.getItem("userId")),
        0,
        params
      );
      const contactUser = getValue?.filter(
        (item) => item?.isMyContact === true
      );
      const otherUser = getValue?.filter((item) => item?.isMyContact !== true);
      const finalData = [...contactUser, ...otherUser];
      const filteredUserList = finalData.filter(
        (user) => !selectedItems.some((item) => item.userId === user.userId)
      );
      setUserlist(filteredUserList);
      setFlag(true);
    }
  };

  const getUserList = async () => {
    const getValue = await searchUsersMembers(
      Number(localStorage.getItem("userId")),
      0,
      ""
    );
    setFlag(true);
    const filterUser = getValue?.filter((item) => item?.isMyContact === true);
    setUserlist(filterUser);
  };

  const onSearchChange = (e) => {
    setFieldData(e.target.value);
    getFilterUserList(e.target.value);
  };

  const options = userList.map((option) => {
    const userType = option?.isMyContact === true;
    return {
      userType: userType ? "Contacts" : "Others",
      ...option,
    };
  });

  return (
    <>
      {!showInviteModal ? (
        <Modal
          open={props.showNewchat}
          className="add-new-chat"
          onClose={closeNewchat}
        >
          <div className="modal-center" style={{ border: "0px" }}>
            <div className="modal-card newchat-card">
              <div className="header-section">
                <Typography
                  className="addsite-title"
                  variant="h6"
                  component="h2"
                >
                  New Chat
                </Typography>
                <IconButton color="primary" onClick={closeNewchat}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="newchat-list">
                <div className="Autocomplete-height">
                  <Autocomplete
                    multiple
                    // freeSolo
                    fullWidth
                    size="small"
                    className="newuser-list"
                    // disableCloseOnSelect
                    open={flag}
                    options={options}
                    groupBy={(option) => option.userType}
                    onChange={handleSelect}
                    onclear
                    filterSelectedOptions={true}
                    getOptionLabel={(option) =>
                      `${option?.firstName} ${option?.lastName} ${option?.tradeName}`
                    }
                    renderOption={(props, option, index) => (
                      <List {...props} key={option.userId}>
                        <ListItem style={{ padding: "0px" }} disablePadding>
                          <IconButton color="primary" disabled>
                            <Avatar src={option?.pictureUrl} />
                          </IconButton>
                          <ListItemText
                            style={{ paddingLeft: "10px" }}
                            primary={
                              <span>
                                {option?.firstName + " " + option?.lastName}
                              </span>
                            }
                            secondary={option?.tradeName}
                          />
                        </ListItem>
                      </List>
                    )}
                    renderTags={
                      (value, getTagProps) =>
                        value
                          .map((option, index) => {
                            if (typeof option === "object" && option !== null) {
                              return (
                                <Chip
                                  label={`${option?.firstName} ${option?.lastName}`}
                                  {...getTagProps({ index })}
                                  key={option.userId} // Ensure unique key if option is an object
                                />
                              );
                            }
                            // Return null if option is a string or not an object
                            return null;
                          })
                          .filter((tag) => tag !== null) // Filter out nulls from the array
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search"
                        onChange={(e) => onSearchChange(e)}
                      />
                    )}
                  />
                </div>
                <div className="footer-btnbox">
                  <Typography style={{ fontSize: "14px", color: BIGrey }}>
                    Can't find someone?{" "}
                    <span
                      style={{
                        color: BIBlue,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowInviteModal(true);
                      }}
                    >
                      Invite them
                    </span>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={createGroup}
                    disabled={
                      selectedItems?.length === 0 || fieldData !== ""
                        ? true
                        : false
                    }
                    style={{ marginTop: "10px" }}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          open={showInviteModal}
          className="add-new-chat"
          onClose={() => {
            setShowInviteModal(false);
          }}
        >
          <div className="modal-center" style={{ border: "0px" }}>
            <div className="modal-card newchat-card">
              <div className="header-section">
                <Typography
                  className="addsite-title"
                  variant="h6"
                  component="h2"
                ></Typography>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setShowInviteModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Stack style={{ padding: "15px" }}>
                <Typography style={{ textAlign: "center", fontSize: "15px" }}>
                  Invite people to join BuildID and earn bonus BuilderBucks for
                  each person that sign up!
                </Typography>
                {qrCodeValue && (
                  <div
                    style={{
                      height: "auto",
                      margin: "10px auto",
                      maxWidth: 200,
                      width: "100%",
                    }}
                  >
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrCodeValue}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                )}
                <Stack direction={"row"} style={{ margin: "10px" }} gap={3}>
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          `user.mybuilderid.com/refer/${localStorage?.userId}`
                        )
                        .then((res) => {
                          alert("Link copied");
                        })
                        .catch((error) => {
                          console.error("Failed to copy: ", error);
                        });
                    }}
                  >
                    Copy Link
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: "100%" }}
                    onClick={handleShareClick}
                  >
                    Share
                  </Button>
                </Stack>
              </Stack>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
export default NewChatModal;
