import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

/*
Material UI Date Picker wrapper
Props (* required):
	*label		- string; text that appears above Material UI TextField
	*value		- string; controlled variable
	*setValue	- function; useState function that changes var value
	disabled	- boolean; disables DateSelector interaction (defaults to false)
	variant		- string; material-ui variant styling (defaults to "outlined")

Usage:
	<DateSelector
		label=""
		value={}
		setValue={}/>
*/
function DateSelector(props) {
	return (
		<KeyboardDatePicker
			maxDate={props.maxDate}
			label={props.label}
			variant="inline"			// Inline popup instead of fullscreen
			inputVariant={props.variant || "outlined"}
			disabled={props.disabled || false}
			placeholder="MM/DD/YYYY"
			format={props?.dateFormat || "MM/DD/YYYY"}
			value={props.value ?? null}
			onChange={(date, value) => props.setValue(date)}
			fullWidth
			autoOk={true}	// Selecting a date closes popup
			InputProps={{	// Only show "X" button if theres a date to clear
				startAdornment: (props.value)
					? (
						<IconButton style={{ order: 1 }}
							onClick={() => props.setValue(null)}>
							<CloseIcon />
						</IconButton>
					)
					: null
			}}
			InputAdornmentProps={{
				position: "end",
				style: { order: 2, marginLeft: 0 }
			}} />
	)
}

export default DateSelector;