import React, { useEffect, useState } from "react";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { BIBlue, BIGrey, BIRed } from "../../assets/buildidColors";
import "../../assets/css/Search.css";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Button,
  Card,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import PromptModal from "../../components/PromptModal";
import ReportModal from "../../components/ReportModal";
import Loader from "../../components/Loader";

export default function SearchListSection(props) {
  const [list, setList] = useState([]);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [mobileAppRedirect, setMobileAppRedirect] = useState(false);
  const [reportModalOpenSearch, setReportModalOpenSearch] = useState(false);
  const [itemRecordId, setItemRecordId] = useState(0);
  const [itemName, setItemname] = useState("");
  const [isEmptyList, setIsEmptyList] = useState(false);

  const history = useHistory();

  var isAndroid = /Android/i.test(navigator.userAgent);
  var isIphone = /iPhone/i.test(navigator.userAgent);

  useEffect(() => {
    updateList();
  }, []);

  useEffect(() => {
    if (props?.getUpdatedData) {
      updateList();
    }
  }, [props?.getUpdatedData]);

  const updateList = async () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (props.universalsearch === true) {
      setList(props.getData);
    } else {
      await props?.getData(props?.page, props?.searchText).then(async (res) => {
        setList(res);
        if (res?.length == 0) {
          setIsEmptyList(true);
        }
      });
      props?.setGetUpdatedData(false);
    }
  };

  const openMenu = (event, item) => {
    event.stopPropagation();
    event.preventDefault();
    setMoreMenuAnchorEl(event.currentTarget);
    setItemRecordId(item?.id);
    setItemname(item?.name);
  };

  const handleReport = () => {
    setMoreMenuAnchorEl(false);
    setReportModalOpenSearch(true);
  };

  const showSeemore = () => {
    if (props.from === "product") history.push(`/products`);
    if (props.from === "company") history.push(`/companies`);
    if (props.from === "manufacturer") history.push(`/manufactures`);
    if (props.from === "trade") history.push(`/trades`);
  };

  const handleMobileAppClick = () => {
    if (isAndroid) {
      const url = `intent://?id=${props.data[props.itemIdKey]}&type=${
        props.itemRecordType
      }#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
      window.location.replace(url);
    } else if (isIphone) {
      window.location.replace(
        `mybuilderid://?id=${props.data[props.itemIdKey]}&type=${
          props.itemRecordType
        }`
      );
    }
  };

  const handleWebAppClick = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.from === "product")
      if (isAndroid || isIphone) setMobileAppRedirect(true);
      else {
        history.push(`/product/${item?.id}`);
      }
    if (props.from === "company")
      if (isAndroid || isIphone) setMobileAppRedirect(true);
      else history.push(`/company/${item?.id}`);
    if (props.from === "manufacturer")
      if (isAndroid || isIphone) setMobileAppRedirect(true);
      else history.push(`/manufacturer/${item?.id}`);
    if (props.from === "trade")
      if (isAndroid || isIphone) setMobileAppRedirect(true);
      else history.push(`/trade/${item?.id}`);
  };

  const concat = (item) => {
    let fullAddress = "";
    if (props?.from === "product") {
      let product = "";
      if (item?.productType && item?.manufacturerName) {
        product += item?.productType;
        product += " | ";
        product += item?.manufacturerName;
      } else if (item?.productType) {
        product += item?.productType;
      } else if (item?.manufacturerName) {
        product += item?.manufacturerName;
      } else {
        product += "Unknown";
      }
      return product;
    } else {
      if (item?.address !== "" && item?.address !== undefined) {
        fullAddress += item?.address;
        fullAddress += ", ";
      }
      if (item?.city !== "" && item?.city !== undefined) {
        fullAddress += item?.city;
        fullAddress += ", ";
      }
      if (item?.province !== "" && item?.province !== undefined) {
        fullAddress += item?.province;
        fullAddress += ", ";
      }
      if (item?.postalCode !== "" && item?.postalCode !== undefined) {
        fullAddress += item?.postalCode;
        fullAddress += ", ";
      }
      if (item?.country !== "" && item?.country !== undefined) {
        fullAddress += item?.country;
      }
    }
    return fullAddress;
  };
  const filterNewItems = list.filter(
    (value) => value != null || value != undefined
  );
  const uniqueItems = filterNewItems.filter(
    (item, index, self) => index === self.findIndex((i) => i.type === item.type)
  );

  return (
    <>
      <PromptModal
        isOpen={mobileAppRedirect}
        onClose={() => setMobileAppRedirect(false)}
        title="Open link in App"
        desc="Please choose your option."
        redButtonText="Web"
        blueButtonText="Open App"
        blueButtonTextFirst="Go Back"
        onBlueButtonClick={() => {
          handleMobileAppClick();
        }}
        onRedButtonClick={() => handleWebAppClick()}
      />

      <ReportModal
        isOpen={reportModalOpenSearch}
        onClose={() => {
          setReportModalOpenSearch(false);
        }}
        name={itemName}
        recordId={itemRecordId}
      />
      {list?.length > 0 ? (
        <InfiniteScroll
          loader={<Loader />}
          dataLength={list?.length}
          next={
            props?.universalsearch === false
              ? async () => {
                  await props.increaseOffset((pageNr) => {
                    props.getData(pageNr, props.searchText).then((res) => {
                      setList(list.concat(res));
                    });
                  });
                }
              : () => {}
          }
          hasMore={props?.hasMore}
          scrollableTarget="scrollList"
        >
          <Menu
            anchorEl={moreMenuAnchorEl}
            open={Boolean(moreMenuAnchorEl)}
            onClose={(event) => {
              setMoreMenuAnchorEl(false);
            }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
          >
            <MenuItem
              onClick={() => handleReport()}
              style={{ color: BIRed }}
              dense
            >
              Report
            </MenuItem>
          </Menu>
          <div>
            {uniqueItems.map((item, index) =>
              item ? (
                <div key={index}>
                  <p>{item.type}</p>
                </div>
              ) : (
                ""
              )
            )}
          </div>
          {list.map((item, index) =>
            item === undefined ? (
              ""
            ) : (
              <div key={index}>
                <Card
                  // style={{
                  //   marginTop: props?.from !== "trade" ? "7px" : "0px",
                  //   marginBottom: props?.from === "trade" ? "7px" : "",
                  //   borderRadius: props?.universalsearch === true ? "0px" : "4px",
                  // }}
                  style={{ marginBottom: "7px" }}
                >
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton onClick={(event) => openMenu(event, item)}>
                        <MoreIcon />
                      </IconButton>
                    }
                    disablePadding
                    onClick={(e) => handleWebAppClick(e, item)}
                  >
                    <ListItemButton>
                      <ListItemText
                        style={{ fontSize: "15px" }}
                        primary={
                          props?.universalsearch === true
                            ? item?.description1
                            : item?.name
                        }
                        secondary={
                          item?.type === "Trades" && item?.description2
                            ? `${item?.description2}`
                            : concat(item)
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </Card>
              </div>
            )
          )}
        </InfiniteScroll>
      ) : (
        <>
          {isEmptyList ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
                marginTop: "15%",
                color: "GrayText",
              }}
            >
              No Data Present
            </div>
          ) : (
            <div style={{ marginTop: "20%" }}>
              <Loader />
            </div>
          )}
        </>
      )}
      {props?.universalsearch === true && (
        <ListSubheader>
          <Button
            color="primary"
            style={{ marginBottom: 15, marginTop: 15 }}
            variant="outlined"
            fullWidth
            onClick={() => showSeemore()}
          >
            Show More
          </Button>
        </ListSubheader>
      )}
    </>
  );
}
