import React, { useEffect } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import moment from "moment";
import Close from "@material-ui/icons/Close";
import "../../../assets/css/ClientHome.css";
import { Edit } from "@material-ui/icons";
import { BIBlue } from "../../../assets/buildidColors";
import { useState } from "react";
import { AddSafetyObservationModal } from "./AddSafetyObservationModal";
import EditIcon from "@mui/icons-material/Edit";
import PictureViewer from "../../../components/PictureViewer";

export const ViewObservationModal = ({
  isOpen,
  onClose,
  individualSafetyObservation,
  getSafetyIncidentsList,
  activeTab,
}) => {
  const [viewerOpen, setViewerOpen] = useState(false);
  const [viewerIndex, setViewerIndex] = useState(0);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const {
    profilePictureUrl,
    userName,
    safetyObservationDate,
    clientJobCode,
    description,
    pictureList,
    from,
  } = individualSafetyObservation;

  const handleEditClick = () => {
    setEditModalOpen(true);
  };

  useEffect(() => {
    console.log("in", JSON.stringify(individualSafetyObservation, null, 2));
  });

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div
          className="modal-center"
          style={{ border: "none", outline: "none" }}>
          <div className="modal-card" style={{ height: "auto", width: "100%" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                width: "100%",
                justifyContent: "space-between",
              }}>
              <IconButton onClick={onClose} style={{ padding: "0px" }}>
                <Close
                  style={{
                    color: localStorage.clientAccentColor,
                  }}
                />
              </IconButton>

              {individualSafetyObservation?.clientEmployeeId ==
                localStorage.clientEmployeeId && (
                <IconButton
                  onClick={() => {
                    setEditModalOpen(true);
                  }}
                  style={{ padding: "0px" }}>
                  <EditIcon
                    style={{
                      color: localStorage.clientAccentColor,
                    }}
                  />
                </IconButton>
              )}
            </div>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Grid
                  gap={1}
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                  padding={2}>
                  <Grid item>
                    <Avatar
                      alt={individualSafetyObservation?.profilePictureUrl}
                      src={individualSafetyObservation?.profilePictureUrl}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="p">{userName}</Typography>
                  </Grid>
                  <Grid item sx={{ marginLeft: "auto" }}>
                    <Typography
                      style={{
                        color: "grey",
                      }}
                      variant="p">
                      {moment(safetyObservationDate).format("MMMM D, YYYY")}
                      {individualSafetyObservation?.isEdited ? " *" : ""}
                    </Typography>
                  </Grid>
                  {/* {activeTab === "My Observations" && (
                    <IconButton
                      onClick={handleEditClick}
                      style={{ color: BIBlue }}
                    >
                      <Edit />
                    </IconButton>
                  )} */}
                </Grid>
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography style={{ color: "grey" }} variant="body2">
                        Job
                      </Typography>
                      <Typography variant="body2">
                        {`${individualSafetyObservation?.clientJobCode} - ${individualSafetyObservation?.clientJobName}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      maxHeight: "150px", // Restrict the height of the description
                      overflowY: "auto", // Enable vertical scrolling when content overflows
                      marginTop: "10px",
                      paddingRight: "10px", // To account for scrollbar width
                      marginBottom: "10px",
                    }}>
                    <Typography variant="body1" style={{ marginTop: "10px" }}>
                      {description}
                    </Typography>
                  </div>

                  <Grid
                    container
                    style={{
                      overflowX: "scroll",
                      width: "600px",
                      flexWrap: "nowrap",
                    }}>
                    <PictureViewer
                      isOpen={viewerOpen}
                      onClose={() => setViewerOpen(false)}
                      pictures={pictureList?.map((picture) => ({
                        src: picture?.fileUrl,
                        alt: "",
                      }))}
                      viewerIndex={viewerIndex}
                    />

                    {pictureList?.length > 0 &&
                      pictureList?.map((picture, index) => {
                        return (
                          <Grid marginRight={1} key={`${index}`}>
                            <img
                              onClick={() => {
                                setViewerIndex(index);
                                setViewerOpen(true);
                              }}
                              src={picture?.fileUrl}
                              alt={` ${index}`}
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "10px",
                              }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      gap: "10px",
                      marginTop: "10px",
                    }}>
                    {individualSafetyObservation?.udFieldsList?.map(
                      (item, index) => {
                        if (item?.value == "true") {
                          return (
                            <Typography
                              style={{
                                backgroundColor: "lightgrey",
                                padding: "5px",
                                borderRadius: "5px",
                                color: "black",
                                fontWeight: "bold",
                              }}
                              variant="body2"
                              color="textSecondary">
                              {item?.label}
                            </Typography>
                          );
                        }
                      }
                    )}
                  </span>
                </CardContent>
              </Card>
            </Grid>
          </div>
        </div>
      </Modal>
      {editModalOpen && (
        <AddSafetyObservationModal
          from={"VIEWOBS"}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          individualSafetyObservation={individualSafetyObservation}
          getSafetyIncidentsList={getSafetyIncidentsList}
          closeViewObservationModal={onClose}
        />
      )}
    </>
  );
};
