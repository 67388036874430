import React, { useEffect, useRef, useState } from "react";
import { Grid, Menu, MenuItem, Stack } from "@mui/material";
import { Typography } from "@material-ui/core";
import { BIBlack, BIGrey } from "../../../assets/buildidColors";
import "../../../assets/css/ClientHome.css";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader";
import { ClientHeader } from "../ClientHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FileUpload } from "@mui/icons-material";
import { FetchClientSiteFilings2 } from "../../../api/ClientApi";

const greyPlaceholder = {
  imgSrc: "https://www.tlbx.app/200.svg",
};

const ClientSiteFilingDetail = (props) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [siteFilingsFolderList, setSiteFilingFolderList] = useState([]);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
   console.log("handle clode.................")
    setAnchorEl(null);
  };

  const handleSortOptionClick = (option) => {
    console.log(`Selected sort option: ${option}`);
    handleClose();
  };

  const getSiteFilingFolder = async () => {
    setIsLoading(true);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 30,
      FullSearch: clientSearchTerm || "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [{ clientEmployeeJobId: id }],
    };
    await FetchClientSiteFilings2(obj)
      .then((res) => {
        const modifiedRes = res?.data;
        setSiteFilingFolderList(modifiedRes)  
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getting error while getting site filing list", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSiteFilingFolder();
  }, [clientSearchTerm]);

  const handleFileUploadClick = () => {
    // Trigger the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      console.log("Files selected:", files);
      // Handle file upload logic here
    }
  };

  if (isLoading) {
    return (
      <>
        <ClientHeader
          screenName={"Expenses"}
          clientSearchTerm={clientSearchTerm}
          setClientSearchTerm={setClientSearchTerm}
        />
        <Loader />
      </>
    );
  }

  const data = [
    {
      id: "1",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image5",
        },
      ],
      title: "folder 1",
      itemCount: "895",
    },
    {
      id: "2",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
      ],
      title: "folder 2",
      itemCount: "895",
    },
    {
      id: "3",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
      ],
      title: "folder 3",
      itemCount: "895",
    },
    {
      id: "4",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
      ],
      title: "folder 4",
      itemCount: "895",
    },
    {
      id: "5",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
      ],
      title: "folder 5",
      itemCount: "895",
    },
    {
      id: "6",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image5",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image6",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image7",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image8",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image9",
        },
      ],
      title: "folder 6",
      itemCount: "895",
    },
    {
      id: "7",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
      ],
      title: "folder 7",
      itemCount: "895",
    },
    {
      id: "8",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
        {
          imgSrc:
            "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
          imageTitle: "image3",
        },
        {
          imgSrc:
            "https://codeskulptor-demos.commondatastorage.googleapis.com/GalaxyInvaders/back07.jpg",
          imageTitle: "image4",
        },
      ],
      title: "folder 8",
      itemCount: "895",
    },
    {
      id: "9",
      images: [
        {
          imgSrc: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
          imageTitle: "image1",
        },
        {
          imgSrc: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
          imageTitle: "image2",
        },
      ],
      title: "folder 9",
      itemCount: "895",
    },
  ];

  return (
    <>
      <ClientHeader
        screenName={"Site Filing"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content">
          <div className="expense-detail">
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography style={{ fontWeight: "600", marginTop: "10px" }}>
                C25-00601-TC - S&I Silver Springs
              </Typography>
            </Stack>

            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ margin: "15px 0px" }}
            >
              <Stack>
                <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                  9 items
                </Typography>
              </Stack>

              <Stack alignItems={"center"}>
                <Typography style={{ color: BIBlack, fontSize: "14px" }} >
                  Sort by{" "}
                  <span
                    style={{
                      color: localStorage.getItem("clientAccentColor"),
                      fontWeight: "600",
                      cursor: "pointer",
                      position: "relative",
                      zIndex: "999",
                    }}
                    onClick={handleClick}
                     onMouseEnter={handleClick}
                     
                  >
                    Upload Date
                  </span>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onMouseLeave={handleClose}
                    slotProps={{
                      paper: {
                        sx: {
                          marginTop: "10px",
                          marginLeft: "-40px",
                        },
                      },
                    }}
                  >
                    {["Type", "Name", "Upload Date"].map((option) => (
                      <MenuItem
                        key={option}
                        onClick={() => handleSortOptionClick(option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Typography>
              </Stack>
            </Stack>
            <Grid container spacing={4}>
              {data.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={1}
                    onClick={() => {
                      history.push(`/client-site-filing-detail/${item?.id}/${item?.id}`, {
                        folderData: item,
                      });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {[
                      ...item.images.slice(0, 4),
                      ...Array(Math.max(0, 4 - item.images.length)).fill(
                        greyPlaceholder
                      ),
                    ].map((img, imgIndex) => (
                      <Grid item xs={6} key={imgIndex}>
                        <img
                          src={img.imgSrc}
                          alt={img.imageTitle}
                          style={{
                            height: "auto",
                            width: "100%",
                            aspectRatio: "1",
                            objectFit: "cover",
                          }}
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Typography variant="h6">{item?.title}</Typography>
                      <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                        {item.itemCount} items
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept="*/*" // Allow all file types
      />
      <div className="fixed-button-container">
        <button className="fixed-button" style={{ background: localStorage?.clientAccentColor }} onClick={handleFileUploadClick}>
          <FileUpload />
        </button>
      </div>
    </>
  );
};

export default ClientSiteFilingDetail;
