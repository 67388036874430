import React, { useRef, useState } from "react";
import { Modal, IconButton, Typography, Box, Slider } from "@material-ui/core";
import { Close, Refresh } from "@material-ui/icons";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import SaveIcon from "@mui/icons-material/Save";
import { BIBlue } from "../../assets/buildidColors";

function FreeFromCropModal(props) {
  console.log("props", props);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [combinedImageUrl, setCombinedImageUrl] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [showRotationSlider, setShowRotationSlider] = useState(false); // New state to control slider visibility
  const imageRef = useRef(null);

  const onImageLoaded = (image) => {
    imageRef.current = image;
    applyRotationAndPreview(rotation);
  };

  const onCropComplete = (crop) => {
    applyCropAndRotation(crop, rotation);
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  // Function to apply crop and rotation and update the preview
  const applyCropAndRotation = (crop, rotation) => {
    if (imageRef.current && crop.width && crop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
      const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

      // Adjust canvas size for rotation (90 or 270 degrees need swapped dimensions)
      if (rotation % 180 !== 0) {
        canvas.width = crop.height;
        canvas.height = crop.width;
      } else {
        canvas.width = crop.width;
        canvas.height = crop.height;
      }

      const ctx = canvas.getContext("2d");

      // Apply rotation and cropping
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(
        imageRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        -crop.width / 2,
        -crop.height / 2,
        crop.width,
        crop.height
      );

      const combinedUrl = canvas.toDataURL("image/jpeg");
      setCombinedImageUrl(combinedUrl);
    }
  };

  // Function to apply rotation alone (before cropping)
  const applyRotationAndPreview = (rotation) => {
    if (imageRef.current) {
      const canvas = document.createElement("canvas");
      const width = imageRef.current.naturalWidth;
      const height = imageRef.current.naturalHeight;

      // Adjust canvas size for rotation (90 or 270 degrees need swapped dimensions)
      if (rotation % 180 !== 0) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      const ctx = canvas.getContext("2d");

      // Apply rotation
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((rotation * Math.PI) / 180);
      ctx.drawImage(imageRef.current, -width / 2, -height / 2, width, height);

      const rotatedUrl = canvas.toDataURL("image/jpeg");
      setCombinedImageUrl(rotatedUrl);
    }
  };

  const handleRotate = (event, newValue) => {
    setRotation(newValue);
    applyRotationAndPreview(newValue);
  };
  console.log("combinedImageUrl", combinedImageUrl);

  const handleSave = () => {
    if (combinedImageUrl) {
      const blob = dataURLtoBlob(combinedImageUrl);
      const file = new File([blob], "rotated-cropped-image.jpg", {
        type: "image/jpeg",
      });

      props.setPictureList((prevList) => {
        // Remove the old image and insert the updated one
        const updatedList = prevList.filter(
          (picture) => picture.id !== props.selectedImageUrl.id
        );
        const updatedImage = {
          ...props.selectedImageUrl,
          fileUrl: combinedImageUrl,
          galleryUploadUrl: file,
          imageFrom: "Gallery",
          deleteCropPicID: props?.selectedImageUrl?.id,
        };
        console.log("updatedImage :-", updatedImage);
        updatedList.push(updatedImage);

        return updatedList;
      });

      props.onClose();
    }
  };
  console.log("props.selectedImageUrl", props.selectedImageUrl);

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <Box
          bgcolor="white"
          borderRadius="8px"
          boxShadow={3}
          width="500px"
          height="auto"
          p={2}
          position="relative">
          <IconButton
            onClick={props.onClose}
            style={{ position: "absolute", top: 10, left: 10 }}>
            <Close />
          </IconButton>

          <IconButton
            onClick={() => setShowRotationSlider(!showRotationSlider)} // Toggle slider visibility
            style={{ position: "absolute", top: 10, right: 50, color: BIBlue }}>
            <Refresh />
          </IconButton>

          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            style={{ position: "absolute", top: "25px", left: "36%" }}>
            Adjust the crop area
          </Typography>

          <IconButton
            onClick={handleSave}
            style={{ position: "absolute", top: 10, right: 10, color: BIBlue }}>
            <SaveIcon />
          </IconButton>

          {props.selectedImageUrl?.fileUrl && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={5}
              style={{ height: "350px", overflow: "hidden" }}>
              <ReactCrop
                crop={crop}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}>
                <img
                  src={props.selectedImageUrl.fileUrl}
                  alt="Crop Me"
                  ref={imageRef}
                  crossOrigin="anonymous"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "350px",
                    objectFit: "contain",
                  }}
                />
              </ReactCrop>
            </Box>
          )}

          {/* Combined Preview (crop and rotate) */}
          {combinedImageUrl && (
            <Box mt={2} textAlign="center">
              <Typography variant="body1">Preview :</Typography>
              <img
                src={combinedImageUrl}
                alt="Cropped and Rotated Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: "200px",
                  borderRadius: "8px",
                }}
              />
            </Box>
          )}

          {/* Rotation Slider */}
          {showRotationSlider && (
            <Box mt={2} textAlign="center">
              <Typography variant="body1">Rotate Image:</Typography>
              <Slider
                value={rotation}
                onChange={handleRotate}
                aria-labelledby="rotation-slider"
                min={0}
                max={360}
                step={1}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default FreeFromCropModal;