import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchUserProducts } from "../../../api/ProductsApi";
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

export default function UserProductCard(props) {
  const [productData, setProductData] = useState([]);
  const history = useHistory();

  async function handleFetchProducts() {
    const projectDataObj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: Number(props.contactId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ UserId: Number(props.contactId) }],
    };
    const res = await fetchUserProducts(projectDataObj);
    setProductData(res.data);
    if (res.data.length == 0) {
      props?.setIsUserProductEmpty(true);
    }
  }

  useEffect(async () => {
    try {
      await handleFetchProducts();
    } catch (error) {
      console.log("Error while getting details :: ", error);
    }
  }, [props.contactId]);

  if (props?.showLoader) {
    return null;
  }

  return (
    <>
      {productData.length != 0 && productData != "" ? (
        <div className="exp-card product-card">
          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <h3>Products</h3>
              </div>
            </div>
          </div>

          <List sx={{ padding: 0 }}>
            {productData.map((item, index) => (
              <Card sx={{ marginBottom: "10px" }}>
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    onClick={() => history.push(`/product/${item?.productId}`)}
                  >
                    <ListItemText
                      primary={item.productName}
                      secondary={
                        <>
                          {item.productType}
                          {item.productType && item.manufacturerName && " | "}
                          {item.manufacturerName}
                        </>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              </Card>
            ))}
          </List>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
