import React, { useState } from "react";
import { Avatar, IconButton, Modal, Typography } from "@mui/material";
import moment from "moment";
import { Close } from "@material-ui/icons";
import PictureViewer from "../../../components/PictureViewer";

export default function ClientChangeTrackerViewModel(props) {
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const [descriptionSeeMore, setDescriptionSeeMore] = useState(true);

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props?.open}
        onClose={() => {
          setDescriptionSeeMore(true);
          props?.close();
        }}
        closeAfterTransition
      >
        <div
          className="modal-center add-expenses height-auto  "
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="addexpenses-form  height-auto">
            <div
              className="modal-card-header-buttons"
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
               style={{color:localStorage.clientAccentColor}}
                onClick={() => {
                  props?.close();
                  setDescriptionSeeMore(true);
                }}
              >
                <Close />
              </IconButton>
            </div>
            <div style={{ marginBottom: "13px" }}>
              <Typography
                variant="h6"
                component="label"
                className="form-label"
                htmlFor="my-input"
                style={{
                  color: "#BDBCBD",
                }}
              >
                Job
              </Typography>
              <Typography variant="h6" component="div" className="form-label">
                {props?.data?.clientEmployeeJobCode +
                  "-" +
                  props?.data?.clientEmployeeJobName}
              </Typography>
            </div>
            <div className="form-content">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={props?.data?.userProfilePicture}
                  />
                  <Typography
                    variant="h6"
                    component="label"
                    className="form-label"
                    htmlFor="my-input"
                    style={{
                      fontWeight: "bold",
                      color: "#838382",
                    }}
                  >
                    {props?.data?.clientEmployeeName}
                    {props?.data?.clientEmployeeId ==
                    localStorage.getItem("clientEmployeeId")
                      ? " (me)"
                      : ""}
                  </Typography>
                </div>
                <div>
                  <Typography
                    variant="h6"
                    component="label"
                    className="form-label"
                    htmlFor="my-input"
                    style={{
                      color: "#747474",
                    }}
                  >
                    {moment(props?.data?.date).format("MMMM DD, YYYY")}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  marginTop: "13px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                <Typography
                  variant="h6"
                  component="label"
                  className="form-label"
                  htmlFor="my-input"
                  style={{
                    overflowWrap: "break-word",
                  }}
                >
                  {props?.data?.description?.length > 230 && descriptionSeeMore
                    ? props.data.description?.slice(0, 230) + "..."
                    : props.data.description}
                </Typography>
              </div>
              {props?.data?.description?.length > 230 && descriptionSeeMore && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <span
                    onClick={() => {
                      setDescriptionSeeMore(false);
                    }}
                    style={{
                      fontSize: "13px",
                      color: localStorage.clientAccentColor,
                      cursor: "pointer",
                    }}
                  >
                    see more
                  </span>
                </div>
              )}
              <div style={{ marginTop: "13px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    overflow: "auto",
                  }}
                >
                  {props.data.pictureList != null &&
                    props?.data?.pictureList.length > 0 &&
                    props?.data?.pictureList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <img
                            onClick={() => {
                              setPictureViewerOpen(true);
                              setPictureViewIndex(index);
                            }}
                            src={item.fileUrl}
                            alt="img"
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "px",
                            }}
                          />
                        </div>
                      );
                    })}
                  {props.data.pictureList != null &&
                    props?.data?.pictureList.length > 0 && (
                      <PictureViewer
                        isOpen={pictureViewerOpen}
                        onClose={() => setPictureViewerOpen(false)}
                        pictures={props?.data?.pictureList.map((pic) => ({
                          src: pic.fileUrl,
                          alt: "",
                        }))}
                        viewerIndex={pictureViewIndex}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
            }}
          ></div>
        </div>
      </Modal>
    </>
  );
}
