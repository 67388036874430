import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Checkbox, FormControlLabel, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import { FetchClientJobActivities2 } from "../../../api/ClientApi";
import Loader from "../../../components/Loader";
import { BIGrey } from "../../../assets/buildidColors";


export default function TimeplateJobActivities({ show, onClose, selectedJobId, selectedJobActivities, setSelectJobActivities }) {

    const debounceTimeout = useRef(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [jobActivities, setJobActivities] = useState([]);

    useEffect(() => {
        if (selectedJobId) {
            getClientjobActivities(searchTerm)
        }
    }, [selectedJobId])

    const getClientjobActivities = async (searchTerm) => {
        try {
            const obj = {
                PageNr: 1,
                FullSearch: searchTerm,
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [
                    {
                        clientJobId: selectedJobId
                    }
                ]
            }
            const res = await FetchClientJobActivities2(obj);
            let resData = res?.data ?? [];
            console.log("resData ====>", resData)
            let updatedData = resData?.map((item) => {
                return {
                    ...item,
                    label: `${item.labourActivityCode} - ${item.labourActivityName}`,
                    activityId: item?.id,
                    isSelected: selectedJobActivities.find(obj => obj.activityId === item?.id) ? true : false
                }
            })
            setJobActivities(updatedData ?? []);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error while getting job activities list :: ", error);
        }
    }

    const selectClientjob = (item) => {
        let getList = jobActivities?.map((act) => {
            if (act?.activityId === item?.activityId) {
                return {
                    ...act,
                    isSelected: item?.isSelected === true ? false : true
                }
            } else {
                return {
                    ...act
                }
            }
        })
        setJobActivities(getList);
    }

    const onSelectjobActivities = () => {
        let getSelected = jobActivities.filter((item) => item.isSelected === true);

        let updatedSelectedActivities = [...selectedJobActivities];

        getSelected.forEach(secondItem => {
            const exists = updatedSelectedActivities.some(firstItem => firstItem.activityId === secondItem.activityId);
            if (!exists) {
                updatedSelectedActivities.push(secondItem);
            }
        });

        setSelectJobActivities(updatedSelectedActivities);
        onClose();
    }

    return (
        <>
            <Modal
                open={show}
                onClose={onClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <div></div>
                            <IconButton
                                onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Select Job Activities</Typography>
                            <TextField
                                value={searchTerm}
                                onChange={async (event) => {
                                    setIsLoading(true);
                                    const searchValue = event.target.value;
                                    setSearchTerm(searchValue)
                                    if (debounceTimeout.current) {
                                        clearTimeout(debounceTimeout.current);
                                    }
                                    debounceTimeout.current = setTimeout(() => {
                                        getClientjobActivities(searchValue);
                                    }, 1000);
                                }}
                                placeholder={"Search for a job activity"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>

                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px", maxHeight: "450px", overflow: "scroll" }}>
                            {
                                !isLoading
                                    ?
                                    (
                                        <>
                                            {
                                                jobActivities?.length > 0 ?
                                                    (
                                                        jobActivities.map((item) => (
                                                            <Stack flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => selectClientjob(item)} justifyContent={'start'} gap={0}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={item?.isSelected}
                                                                            onChange={() => selectClientjob(item)}
                                                                        />
                                                                    }
                                                                    style={{ margin: '0px' }}
                                                                />
                                                                <Typography>{item?.label}</Typography>
                                                            </Stack>
                                                        ))
                                                    )
                                                    :
                                                    (
                                                        <div style={{ padding: '10px', textAlign: 'center', color: BIGrey }}>
                                                            No job activity found.
                                                        </div>
                                                    )
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div style={{ textAlign: 'center' }}>
                                                <Loader />
                                            </div>
                                        </>
                                    )
                            }
                        </Card>

                        <Button onClick={onSelectjobActivities} color="primary" fullWidth variant="contained" sx={{ marginTop: 2 }} disabled={jobActivities?.filter((item) => item?.isSelected === true)?.length !== 0 ? false : true}>Select {jobActivities?.filter((item) => item?.isSelected === true)?.length !== 0 ? jobActivities?.filter((item) => item?.isSelected === true)?.length : ""}</Button>
                    </div>
                </div>
            </Modal>

        </>
    )

}