import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ActionsProvider } from "./components/ActionsContext";
import { updateNotificationStatus } from "./api/UserApi";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
    .then(function (registration) {
      console.log(
        "Service Worker registered successfully:",
        registration.scope
      );
    })
    .catch(function (error) {
      console.error("Service Worker registration failed:", error);
    });
}

const constructUrl = (baseUrl, path, params) => {
  const url = new URL(baseUrl);
  url.pathname = path;
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
};

const handleUpdateNotificationStatus = async (msgId) => {
  try {
    let deviceId = localStorage.getItem("deviceId");
    const obj = {
      NotificationMessageId: msgId,
      ReceiverUserDevice: deviceId,
    };
    await updateNotificationStatus(obj);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const handleNotificationClick = (item, itemUrl) => {
  if (item !== undefined) {
    const url = item['gcm.n.link']; // No need to parse this as JSON, it's already a string.
    let screenName = "";
    let matchId = "";

    function parseURL(urlString) {
      const urlWithoutProtocol = urlString.replace("mybuilderid://", ""); // Remove protocol

      const [path] = urlWithoutProtocol.split("&"); // Split by "&" as query parameters start immediately after path

      const screenNameMatch = path.match(/screenName=([^&]+)/);
      const screenName = screenNameMatch ? screenNameMatch[1] : path;

      // If queryString is undefined, matchId won't be found
      const idMatch = urlWithoutProtocol.match(/id=([^&]+)/); // Use a regex to get the ID directly from the string
      const matchId = idMatch ? idMatch[1] : null;

      return { screenName, matchId };
    }

    const { screenName: parsedScreenName, matchId: parsedId } = parseURL(url);

    screenName = parsedScreenName || ""; // Fallback to empty string if not found
    matchId = parsedId || ""; // Fallback to empty string if not found

    let urlWithState;
    switch (screenName) {
      case "CompanyInfo":
        urlWithState = `${itemUrl}company/${matchId}`;
        break;
      case "ManufacturerInfo":
        urlWithState = `${itemUrl}manufacturer/${matchId}`;
        break;
      case "ProductInfo":
        urlWithState = `${itemUrl}product/${matchId}`;
        break;
      case "TradeDetail":
        urlWithState = `${itemUrl}trade/${matchId}`;
        break;
      case "ProjectPage":  
        urlWithState = `${itemUrl}projects/project/${matchId}`;
        break;
      
      case "ConversationScreen": 
        urlWithState = `${itemUrl}chat`;
        break;
      case "ContactProfile":    
        urlWithState = `${itemUrl}user-dashboard-profile/${matchId}`;
        break;
      case "DetailPost":  
          urlWithState = constructUrl(itemUrl, "/dashboard", {
            notificationPostId: Number(matchId),
          });
          break;
      case "MapForSingleSite":    
        urlWithState = constructUrl(itemUrl, "new-map", {
          appRecordId: Number(matchId),
          });
        break;
        case "Tickets":
          urlWithState = `${itemUrl}tickets`;
          break;
        case "ProfileBoard":
          urlWithState = `${itemUrl}profile`;
          break;
        case "MyExperience":
          urlWithState = constructUrl(itemUrl, "profile", {
            from: "NotificationsForProfile",
          });
          break;
        case "DashboardNotifications":
          urlWithState = constructUrl(itemUrl, "dashboard", {
            from: "NotificationsForNotify",
          });
          break;
        case "Quiz":          
            urlWithState = constructUrl(itemUrl, "rewards/earn-rewards", {
              QuestionId: Number(matchId),
            });
            break;
      default:
        urlWithState = `${itemUrl}dashboard`;
        break;
        // return;
    }
    handleUpdateNotificationStatus(item?.NotificationMessageId);
    window.location.href = urlWithState;
  } else {
    console.error("No screen name or ID found in the input string");
  }
};

// Handle messages from the service worker
navigator.serviceWorker.addEventListener("message", (event) => {
  const message = event.data;
  if (message && message.type === "navigate" && message.notificationData) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
      .then(function (registration) {
        handleNotificationClick(message.notificationData, registration.scope);
      
      })
      .catch(function (error) {
        console.error("Service Worker registration failed:", error);
      });
  }
});

ReactDOM.render(
  <ActionsProvider>
    <App />
  </ActionsProvider>,
  document.getElementById("root")
);

reportWebVitals();
