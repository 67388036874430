import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import { ClientCoachingCard } from './ClientCoachingCard';
import { AddClientCoachingModal } from './AddClientCoachingModal';
import { fetchClientCoachings } from '../../../api/ClientApi';
import { Typography } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import Loader from '../../../components/Loader';
import "../../../assets/css/ClientHome.css"
import { ClientHeader } from '../../client/ClientHeader';

export const ClientCoaching = () => {
    const tabsArray = ["Received", "Submitted"];
    const [activeTab, setActiveTab] = useState("Received");
    const [coachingList, setClientCoachingList] = useState([]);
    const [addClientModalOpen, setAddClientModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [clientSearchTerm,setClientSearchTerm] = useState("");

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    const getCoachingList = async () => {
        setIsLoading(true);
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: clientSearchTerm || "",
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: true,
                SearchList: [activeTab === "Submitted" ? { createdBy: localStorage.userId } : { supervisorUserId: localStorage.clientEmployeeId }],
                SortList : [{
                    FieldName: "Id", Direction: "DESC"
                }]
            }
            const coachingListRes = await fetchClientCoachings(obj)
            setClientCoachingList(coachingListRes?.data);
            setIsLoading(false);
        }
        catch (err) {
            console.log("Error while fetch coaching list ", err);
            setIsLoading(false);
        }
    }

    const handleAddCoachingClick = () => {
        setAddClientModalOpen(true);
    }

    useEffect(() => {
        getCoachingList();
    }, [activeTab, clientSearchTerm])

    return (
        <>
            <ClientHeader 
                screenName={"Coaching"} 
                clientSearchTerm={clientSearchTerm}
                setClientSearchTerm={setClientSearchTerm}
                />

            <div className="page">
                <div className="page-content">
                    <div className="flex-container-vertical" style={{ flex: 2 }}>
                        <div style={{ margin: "15px" }}>
                            <div
                                className="flex-container-horizontal"
                                style={{ justifyContent: "space-between" }}>
                                <Tabs
                                    sx={{
                                        ".Mui-selected": {
                                            color: localStorage?.clientAccentColor,
                                        }
                                    }}
                                    TabIndicatorProps={{ style: { backgroundColor: localStorage?.clientAccentColor } }}
                                    value={activeTab}
                                    onChange={handleTabChange}>
                                    {tabsArray.map((tab, index) => (
                                        <Tab
                                            key={index}
                                            value={tab}
                                            label={tab}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                                marginRight: "20px",
                                            }}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        </div>
                    </div>

                    {isLoading ? <Loader /> :
                        coachingList?.length > 0 ?
                            coachingList?.map((coachingListItem) => {
                                return <ClientCoachingCard coachingListItem={coachingListItem} />
                            }) : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                                <Typography variant='body'>
                                    {`You do not have any ${activeTab.toLowerCase()} Coaching Requests.`}
                                </Typography>
                            </div>}
                    <div className="fixed-button-container">
                        <button className="fixed-button" style={{background:localStorage?.clientAccentColor}} onClick={handleAddCoachingClick}>
                            <AddIcon />
                        </button>
                    </div>
                    {
                        addClientModalOpen &&
                        <AddClientCoachingModal
                            isOpen={addClientModalOpen}
                            onClose={() => setAddClientModalOpen(false)}
                            getCoachingList={getCoachingList}
                        />
                    }
                </div>
            </div>
        </>
    )
}
