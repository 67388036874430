import { restPost } from "./RestClient";
import { headers } from "./UserApi";

// let headers = {
// 	"Content-Type": "application/json",
// 	"Accept": "application/json",
// 	"Authorization": ('Bearer ' + localStorage.getItem('token')),
// }

// let form_headers = {
// 	"Accept": "application/json",
// 	"Content-Type": "multipart/form-data",
// 	"Authorization" : ('Bearer ' + localStorage.getItem('token')),
// }


export async function fetchExpenses(data) {
	const res = await restPost("users/FetchExpenses", data, headers);
	return res;
}

export async function saveExpenses(data) {
	// try {
		const res = await restPost("users/SaveExpenses", data, headers);
		console.log("==========",res)
		return res;	
	// } catch (error) {
	// 	console.log("Error ----",error.response.status)
	// 	return error;
	// }
}



export async function deleteExpenses(data) {
	const res = await restPost("users/DeleteExpenses", data, headers);
	return res;
}

export async function sendExpenses(data) {
	const res = await restPost("users/SendExpenses", data, headers);
	return res;
}

export async function deleteExpensesPictures(data) {
	const res = await restPost("users/DeleteExpensesPictures", data, headers);
	return res;
}

export async function saveExpensesPictures(data, form_headers) {
	const res = await restPost("users/SaveExpensesPictures", data, form_headers);
	return res;
}