import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  IconButton,
  Modal,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Card,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  fetchClientJobs,
  saveClientSiteEstimations,
  saveClientSiteEstimationsEntries,
} from "../../../api/ClientApi";
import { LoadingButton } from "@mui/lab";

function ClientAddSiteEstimation({ open, close, getSiteEstimation }) {
  const [jobOptions, setJobOptions] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [data, setData] = useState({
    jobCodeId: "",
    items: [{ description: "", days: "", employees: "" }],
  });
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);

  const getJobList = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: localStorage.getItem("userId"),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            clientEmployeeId: localStorage.getItem("clientEmployeeId"),
          },
        ],
      };

      const res = await fetchClientJobs(obj);
      setJobOptions(res.data);
    } catch (err) {
      console.log("Error while fetching jobs list", err);
    }
  };

  const handleAddItem = () => {
    setData({
      ...data,
      items: [...data.items, { description: "", days: "", employees: "" }],
    });
  };

  const handleRemoveItem = (index) => {
    const items = data.items.filter((_, i) => i !== index);
    setData({ ...data, items });
  };

  const handleChangeItem = (index, field, value) => {
    const items = data.items.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setData({ ...data, items });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const userId = localStorage.getItem("userId");
    const clientEmployeeId = localStorage.getItem("clientEmployeeId");
    const payload = {
      UserId: Number(userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          ClientEmployeeId: clientEmployeeId,
          ClientJobId: selectedJob?.id,
          EstimationDate: "",
          ModifiedBy: Number(userId),
        },
      ],
    };

    try {
      const response = await saveClientSiteEstimations(payload);
      const objData = response.data[0].objData;
      const modifiedSaveList = data.items.map((item) => ({
        Id: 0,
        ClientSiteEstimationId: objData.id,
        Description: item.description,
        Days: Number(item.days).toFixed(2),
        Employees: Number(item.employees),
        SubTotal: Number(
          (Number(item.employees) * Number(item.days)).toFixed(2)
        ),
        ModifiedBy: Number(userId),
      }));

      const entriesPayload = {
        UserId: localStorage.getItem("userId"),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: modifiedSaveList,
      };

      await saveClientSiteEstimationsEntries(entriesPayload);
      setIsLoading(false);
      close();
      // window.location.href = "/client-site-estimation";
      getSiteEstimation();
    } catch (err) {
      setIsLoading(false);
      console.log("Error while saving estimate", err);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  // Focus on the latest added item when the item list changes
  useEffect(() => {
    if (data.items.length > 0) {
      inputRefs.current[data.items.length - 1]?.focus();
    }
  }, [data.items]);

  const isSubmitDisabled =
    !data.jobCodeId ||
    data.items.some(
      (item) => !item.description || !item.days || !item.employees
    );

  const handleClose = () => {
    close();
    setData({
      jobCodeId: "",
      items: [{ description: "", days: "", employees: "" }],
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal-center ">
        <div
          className="modal-card"
          style={{
            width: "500px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
              }}
              variant="p"
            >
              Create Estimate
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
          <FormControl fullWidth margin="normal">
            <Autocomplete
              sx={{
                // border: "1px solid blue",
                "& .MuiOutlinedInput-root": {
                  // border: "1px solid yellow",
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              size="small"
              style={{
                backgroundColor: "#f2f2f2",
                borderRadius: "10px",
              }}
              options={jobOptions}
              getOptionLabel={(option) =>
                `${option.jobCode + " - " + option.name}`
              }
              onChange={(event, newValue) => {
                setData({ ...data, jobCodeId: newValue?.id || "" });
                setSelectedJob(newValue);
              }}
              renderInput={(params) => {
                console.log(params);
                return (
                  <TextField
                    placeholder="Search for a Job"
                    {...params}
                    variant="outlined"
                  />
                );
              }}
            />
          </FormControl>

          {data.items.length === 0 ? (
            <Button
              onClick={handleAddItem}
              fullWidth
              size="small"
              style={{
                textTransform: "none",
                backgroundColor: localStorage.clientAccentColor,
                color: "#fff",
              }}
            >
              Add Item
            </Button>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h4>Area/Item</h4>
                <Button
                  style={{
                    textTransform: "none",
                  }}
                  onClick={handleAddItem}
                >
                  Add Item
                </Button>
              </div>
              <>
                {/* Container for scrollable items */}
                <div
                  style={{
                    maxHeight: "300px", // Adjust the height as needed
                    overflowY: "auto",
                  }}
                >
                  {data.items.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        padding: "10px",
                        borderRadius: "10px",
                        border: "1px solid #f2f2f2",
                        marginBottom: "10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <TextField
                          size={"small"}
                          style={{
                            backgroundColor: "#f2f2f2",
                            borderRadius: "10px",
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "0",
                              padding: "0",
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "none",
                              },
                          }}
                          placeholder="Description"
                          value={item.description}
                          onChange={(e) =>
                            handleChangeItem(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                          fullWidth
                          // inputRef={(el) => (inputRefs.current[index] = el)}
                        />
                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <Close />
                        </IconButton>
                      </div>
                      <div
                        key={index}
                        style={{
                          marginTop: 5,
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <Typography>Days</Typography>
                          <TextField
                            placeholder="0.00"
                            size={"small"}
                            style={{
                              backgroundColor: "#f2f2f2",
                              borderRadius: "10px",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                            }}
                            type="number"
                            value={item.days}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Regex to allow up to two decimal places
                              const regex = /^\d*\.?\d{0,2}$/;

                              // Check if the input matches the regex
                              if (regex.test(value)) {
                                handleChangeItem(index, "days", value);
                              }
                            }}
                            inputProps={{ step: 0.01 }}
                          />
                        </div>

                        <div>
                          <Typography>Employees</Typography>
                          <TextField
                            placeholder="0"
                            size={"small"}
                            style={{
                              backgroundColor: "#f2f2f2",
                              borderRadius: "10px",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                            }}
                            type="number"
                            value={item.employees}
                            onChange={(e) =>
                              handleChangeItem(
                                index,
                                "employees",
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </div>
                        <div>
                          <Typography>Subtotal</Typography>
                          <Typography
                            align="center"
                            style={{
                              padding: "6px",
                              fontWeight: "bold",
                              color: localStorage.clientAccentColor,
                            }}
                          >
                            {(item.days * item.employees).toFixed(2) || 0}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            </>
          )}

          <LoadingButton
            onClick={handleSubmit}
            disabled={isSubmitDisabled || data.items.length === 0}
            loading={isLoading}
            loadingPosition="start"
            fullWidth
            style={{
              marginTop: "10px",
              textTransform: "none",
              backgroundColor:
                isSubmitDisabled || data.items.length === 0
                  ? "lightgrey"
                  : localStorage?.clientAccentColor,
              color: "white",
              borderRadius: "10px",
            }}
          >
            Submit
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
}

export default ClientAddSiteEstimation;
