import React, { useState, useEffect, useRef } from 'react'
import Modal from '@mui/material/Modal';
import { IconButton, TextField, InputAdornment, Stack, Card, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { fetchClientJobs } from '../../../api/ClientApi';
import { Close as CloseIcon, Delete } from "@material-ui/icons";
import Loader from '../../../components/Loader';
import { BIGrey } from '../../../assets/buildidColors';

const ClientJobListModal = (props) => {

    const clientId = localStorage.getItem("clientId");
    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? 'black';
    const debounceTimeout = useRef(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [jobList, setJobList] = useState([]);

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (props.show === true) {
            getJobs(searchTerm);
        }
    }, [props.show]);

    const getJobs = async (searchTerm) => {
        setLoader(true);
        try {
            const clientJobObj = {
                PageNr: 1,
                FullSearch: searchTerm,
                UserId: Number(localStorage?.userId),
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [
                    {
                        ClientId: Number(clientId),
                    },
                ],
                SortList: [{ FieldName: "dateCreated", Direction: "DESC" }],
            };
            const res = await fetchClientJobs(clientJobObj);
            let resData = res?.data ?? [];
            resData = resData.map((item) => {
                console.log("props?.selectedJob", props?.selectedJob)
                let isSelected = props?.selectedJob?.filter((job) => job?.isSelected === true);
                console.log("isSelected", isSelected);
                let jobObj = {
                    id: 0,
                    clientJobId: item?.id,
                    clientJobName: item?.name,
                    clientJobCode: item?.jobCode,
                    name: item?.jobCode + " - " + item?.name,
                    Deleted: false,
                    isSelected: false
                }
                if (isSelected?.length > 0) {
                    if (isSelected[0]?.clientJobId === item?.id) {
                        return {
                            ...isSelected[0],
                        }
                    } else {
                        return jobObj
                    }
                } else {
                    return jobObj
                }
            })
            setJobList(resData);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log("ERROR WHILE FETCHING JOB IN SAFETY OBSERVATION ", err);
        }
        setLoader(false);
    };


    const getJobListItemBorderColor = (item) => {
        console.log("item--->", item);
        return `1px ${item?.isSelected === true ? clientAccentColor : "grey"} solid`
    }

    const onJobItemClick = (getItem) => {
        if (props?.timesheet === "individual") {
            props.onJobSelect(getItem);
        } else {
            let getList = jobList.map((item) => {
                if (item?.clientJobId === getItem?.clientJobId) {
                    return {
                        ...item,
                        Deleted: false,
                        isSelected: true
                    }
                } else {
                    return {
                        ...item,
                        Deleted: item?.id === 0 ? false : true,
                        isSelected: false
                    }
                }
            })
            console.log("getList", getList)
            setJobList(getList);
            let getSelected = getList.filter((item) => item.isSelected === true);
            let getDeletedjob = getList.filter((item) => item.Deleted === true);
            getSelected = [...getSelected, ...getDeletedjob];
            if (getSelected?.length > 0) {
                console.log("getSelected--->", getSelected)
                props.setSelectedjob(getSelected);
            }
            if (getDeletedjob?.length > 0) {
                deletePreviousdata();
            }
        }

        props.handleClose();
    }

    // If any privious job deleted then delete the selected employee and activity 
    const deletePreviousdata = () => {
        // Selected emp deleted 
        let getUpdatedemp = props.selectedEmp.map((item) => {
            return {
                ...item,
                Deleted: true,
                isSelected: false
            }
        })
        props.setSelectedemp(getUpdatedemp);
        // Selected activity deleted 
        let getUpdatedact = props.selectedJobActivities.map((item) => {
            return {
                ...item,
                Deleted: true,
                isSelected: false
            }
        })
        props.setSelectJobActivities(getUpdatedact);
    }


    if (!props.show) {
        return null;
    }

    if (loader) {
        return (
            <Loader />
        )
    }

    return (
        <>
            <Modal
                open={props.show}
                onClose={props.handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <div></div>
                            <IconButton
                                onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Jobs</Typography>
                            <TextField
                                value={searchTerm}
                                onChange={async (event) => {
                                    setIsLoading(true);
                                    const searchValue = event.target.value;
                                    setSearchTerm(searchValue)
                                    if (debounceTimeout.current) {
                                        clearTimeout(debounceTimeout.current);
                                    }
                                    debounceTimeout.current = setTimeout(() => {
                                        getJobs(searchValue);
                                    }, 1000);
                                }}
                                placeholder={"Search for a Job"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px", maxHeight: "450px", overflow: "scroll" }}>
                            {
                                !isLoading
                                    ?
                                    (
                                        <>
                                            {
                                                jobList?.length > 0 ?
                                                    (jobList?.map((item, index) => (
                                                        <div key={index}
                                                            style={{ border: getJobListItemBorderColor(item), margin: '10px', padding: '10px', cursor: "pointer" }}
                                                            onClick={() => { onJobItemClick(item) }}
                                                        >
                                                            <Typography>
                                                                {`${item?.clientJobCode} - ${item?.clientJobName}`}
                                                            </Typography>
                                                        </div>
                                                    )))
                                                    :
                                                    (
                                                        <div style={{ padding: '10px', textAlign: 'center', color: BIGrey }}>
                                                            No Job Found
                                                        </div>
                                                    )
                                            }
                                        </>
                                    )
                                    : (
                                        <>
                                            <div style={{ textAlign: 'center' }}>
                                                <Loader />
                                            </div>
                                        </>
                                    )
                            }
                        </Card>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ClientJobListModal