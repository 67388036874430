import { restGet, restPost } from "./RestClient";
import { headers, form_headers } from "./UserApi";

export async function fetchClientPicturesBoard2(args) {
  const res = await restPost(
    "Client/FetchClientPicturesBoard2/",
    args,
    headers
  );
  return res;
}

export async function fetchClientPicturesBoard(args) {
  const res = await restPost("Client/FetchClientPicturesBoard/", args, headers);
  return res;
}

export async function fetchClientBoardAlbums(args) {
  const res = await restPost("Client/FetchClientBoardAlbums2/", args, headers);
  return res;
}

export async function saveClientPicturesBoard(args) {
  const res = await restPost("Client/SaveClientPicturesBoard/", args, headers);
  return res;
}

export async function deleteClientPicturesBoard(args) {
  const res = await restPost(
    "Client/DeleteClientPicturesBoard/",
    args,
    headers
  );
  return res;
}

export async function fetchClientEmployeeLicenses(args) {
  const res = await restPost(
    "Client/FetchClientEmployeeLicenses2",
    args,
    headers
  );
  return res;
}

export async function getClientDashboardNotifications(userId,isViewed) {
  const res = await restGet(
    `Client/GetClientDashboardNotifications/${userId}/${isViewed}`,
    headers
  );
  return res;
}

export async function updateEmployeeLicensesIsPinFlag(args) {
  const res = await restPost(
    "Client/UpdateEmployeeLicensesIsPinFlag",
    args,
    headers
  );
  return res;
}

export async function getClientApprovedPictureBoard(clientId) {
  const res = await restGet(
    "Client/GetClientApprovedPictureBoard/" + clientId,
    headers
  );
  return res;
}

export async function fetchClientexpenses(args) {
  try {
    const res = await restPost("Client/FetchClientExpenses/", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientexpensescodes(args) {
  try {
    const res = await restPost("Client/FetchClientExpenseCodes", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function saveClientexpenses(args) {
  try {
    const res = await restPost("Client/SaveClientExpenses", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientjobs(args) {
  try {
    const res = await restPost("Client/FetchClientJobs", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientCurrencies(args) {
  try {
    const res = await restPost("Client/FetchClientCurrencies2", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

export async function saveExpensesReceipt(args) {
  try {
    const res = await restPost(
      "Client/SaveClientExpenseReceipt",
      args,
      form_headers
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function fetchClientexpensesReceipts(args) {
  try {
    const res = await restPost(
      "Client/FetchClientExpensesReceipts",
      args,
      headers
    );
    return res;
  } catch (error) {
    throw error;
  }
}

export async function deleteClientExpenses(args) {
  try {
    const res = await restPost("Client/DeleteClientExpenses", args, headers);
    return res;
  } catch (error) {
    throw error;
  }
}

//Client Safety Observation's
export async function fetchClientSafetyObservations(args) {
  const res = await restPost(
    "Client/FetchClientSafetyObservations2/",
    args,
    headers
  );
  return res;
}

export async function fetchClientJobs(args) {
  const res = await restPost("Client/FetchClientJobs/", args, headers);
  return res;
}

export async function saveClientSafetyObservations(args) {
  const res = await restPost(
    "Client/SaveClientSafetyObservations/",
    args,
    headers
  );
  return res;
}

export async function saveClientSafetyObservationPictures(args) {
  const res = await restPost(
    "Client/SaveClientSafetyObservationsPictures/",
    args,
    form_headers
  );
  return res;
}

export async function deleteClientSafetyObservationsPictures(args) {
  const res = await restPost(
    "Client/DeleteClientSafetyObservationsPictures",
    args,
    headers
  );
  return res;
}

export async function fetchClientEmployees(args) {
  const res = await restPost("Client/FetchClientEmployees", args, headers);
  return res;
}

export async function getClientUDFieldsbyClientId({
  clientId,
  appId,
  entityRecordId,
}) {
  const res = await restGet(
    `Client/GetClientUDFieldsbyClientId/${clientId}/${appId}/${entityRecordId}`,
    headers
  );
  return res;
}

// Client Coaching

export async function fetchClientCoachings(args) {
  const res = await restPost("Client/FetchClientCoachings", args, headers);
  return res;
}

export async function fetchClientEmployee(args) {
  const res = await restPost("Client/FetchClientEmployees2", args, headers);
  return res;
}

export async function updateClientEmployeeInfo(args) {
  const res = await restPost("Client/UpdateClientEmployeesInfo", args, headers);
  return res;
}

export async function saveClientCoachings(args) {
  const res = await restPost("Client/SaveClientCoachings", args, headers);
  return res;
}

// Client Safety Incidents

export async function fetchClientSafetyIncidents(args) {
  const res = await restPost(
    "Client/FetchClientSafetyIncidents",
    args,
    headers
  );
  return res;
}

export async function saveClientSafetyIncidents(args) {
  const res = await restPost("Client/SaveClientSafetyIncidents", args, headers);
  return res;
}

// client Tracker

export async function fetchClientTracker2(args) {
  const res = await restPost(
    "Client/FetchClientChangeTrackers2",
    args,
    headers
  );
  return res;
}

export async function fetchClientEmployeesJobs(args) {
  const res = await restPost("Client/FetchClientEmployeesJobs2", args, headers);
  return res;
}

export async function saveClientTracker(args) {
  const res = await restPost("Client/SaveClientChangeTrackers", args, headers);
  return res;
}

export async function saveClientTrackerPicture(args) {
  const res = await restPost(
    "Client/SaveClientChangeTrackersPictures",
    args,
    headers
  );
  return res;
}

// Client Site Estimation

export async function saveClientSiteEstimationsEntries(args) {
  const res = await restPost(
    "Client/SaveClientSiteEstimationsEntries",
    args,
    headers
  );
  return res;
}
export async function saveClientSiteEstimations(args) {
  const res = await restPost("Client/SaveClientSiteEstimations", args, headers);
  return res;
}
export async function fetchClientSiteEstimations(args) {
  const res = await restPost(
    "Client/FetchClientSiteEstimations",
    args,
    headers
  );
  return res;
}

// client site activity report
export async function fetchClientSAR2(args) {
  const res = await restPost("Client/FetchClientSAR2", args, headers);
  return res;
}
export async function saveClientSAR(args) {
  const res = await restPost("Client/SaveClientSAR", args, headers);
  return res;
}
export async function saveClientSARActions(args) {
  const res = await restPost("Client/SaveClientSARActions", args, headers);
  return res;
}
export async function saveClientSARActionsPictures(args) {
  const res = await restPost(
    "Client/SaveClientSARActionsPictures",
    args,
    form_headers
  );
  return res;
}
export async function fetchClientSiteActivityNotes(args) {
  const res = await restPost("Client/FetchClientSARNotes2", args, headers);
  return res;
}
export async function fetchClientSiteActivityNotesTypes(args) {
  const res = await restPost("Client/FetchClientSARNotesTypes2", args, headers);
  return res;
}
export async function fetchClientSiteActivityActions(args) {
  const res = await restPost("Client/FetchClientSARActions", args, headers);
  return res;
}
export async function fetchClientSARNotesTypes(args) {
  const res = await restPost("Client/FetchClientSARNotesTypes", args, headers);
  return res;
}
export async function saveClientSARNotes(args) {
  const res = await restPost("Client/SaveClientSARNotes", args, headers);
  return res;
}
// export async function fetchClientCommunicator(args) {
//   const res = await restPost("Client/FetchClientCommunicator2", args, headers);
//   return res;
// }

export async function getClientCommunicatorDetails(
  clientId,
  acknowledged,
  searchString
) {
  const res = await restGet(
    `Client/GetClientCommunicatorDetails/${clientId}/${acknowledged}/${searchString}`,
    headers
  );
  return res;
}
export async function saveClientCommunicatorAcknowledgements(args) {
  const res = await restPost(
    "Client/SaveClientCommunicatorAcknowledgements",
    args,
    headers
  );
  return res;
}
export async function FetchClientCommunicator2(args) {
  const res = await restPost("Client/FetchClientCommunicator2", args, headers);
  return res;
}

export async function saveClientSARnotesPictures(args) {
  const res = await restPost(
    "Client/SaveClientSARNotesPictures",
    args,
    form_headers
  );
  return res;
}

export async function fetchClientActions(args) {
  const res = await restPost("Client/FetchClientSARActions2", args, headers);
  return res;
}
export async function FetchClientSARActionsHistory2(args) {
  const res = await restPost(
    "Client/FetchClientSARActionsHistory2",
    args,
    headers
  );
  return res;
}
export async function saveSARActions(args) {
  const res = await restPost(
    "Client/SaveClientSARActionsHistory",
    args,
    headers
  );
  return res;
}
export async function saverOverDueCLientSARActionHistory(
  clientSARActionId,
  userId
) {
  const res = await restGet(
    `Client/SaveOverDueClientSARActionHistory/${clientSARActionId}/${userId}`,
    headers
  );
  return res;
}

export async function FetchClientSupport(args) {
  const res = await restPost("Client/FetchClientSupports", args, headers);
  return res;
}
export async function FetchClientSupportCategories(args) {
  const res = await restPost(
    "Client/FetchClientSupportsHistory2",
    args,
    headers
  );
  return res;
}
export async function saveClientHistory(args) {
  const res = await restPost("Client/SaveClientSupportsHistory", args, headers);
  return res;
}

export async function fetchClientSupportHistory(args) {
  const res = await restPost("Client/FetchClientSupportsHistory", args, headers);
  return res;
}
export async function fetchSupportUrgencyList(args) {
  const res = await restPost(
    "Client/FetchClientUrgencySupportList",
    args,
    headers
  );
  return res;
}
export async function fetchSupportCategoryList(args) {
  const res = await restPost(
    "Client/FetchClientSupportCategories",
    args,
    headers
  );
  return res;
}
export async function saveClientSupport(args) {
  const res = await restPost("Client/SaveClientSupports", args, headers);
  return res;
}
export async function saveClientSupportPictures(args) {
  const res = await restPost(
    "Client/SaveClientSupportsPictures",
    args,
    form_headers
  );
  return res;
}
export async function fetchClientExpensesHistory(args) {
  const res = await restPost(
    "Client/FetchClientExpensesHistory2",
    args,
    headers
  );
  return res;
}
export async function deleteCLientExpenseReceipt(args) {
  const res = await restPost(
    "Client/DeleteClientExpensesReceipts",
    args,
    headers
  );
  return res;
}

export async function FetchClientJobs(args) {
  const res = await restPost("Client/FetchClientJobs", args, headers);
  return res;
}

export async function FetchClientJobActivities2(args) {
  const res = await restPost("Client/FetchClientJobActivities2", args, headers);
  return res;
}

export async function SaveClientTimesheets(args) {
  const res = await restPost("Client/SaveClientTimesheets", args, headers);
  return res;
}

export async function DeleteClientTimesheetJobs(args) {
  const res = await restPost("Client/DeleteClientTimesheetJobs", args, headers);
  return res;
}

export async function DeleteClientTimesheetJobImages(args) {
  const res = await restPost(
    "Client/DeleteClientTimesheetJobImages",
    args,
    headers
  );
  return res;
}

export async function FetchClientTimesheetJobs(args) {
  const res = await restPost("Client/FetchClientTimesheetJobs2", args, headers);
  return res;
}

export async function SaveClientTimesheetJobImages(args) {
  const res = await restPost(
    "Client/SaveClientTimesheetJobImages",
    args,
    headers
  );
  return res;
}

export async function FetchClientTimesheets2(args) {
  const res = await restPost("Client/FetchClientTimesheets2", args, headers);
  return res;
}

export async function DeleteClientTimesheets(args) {
  const res = await restPost("Client/DeleteClientTimesheets", args, headers);
  return res;
}

export async function saveSupportReviews(args) {
  try {
    const res = await restPost(
      "Client/SaveClientSupportsReviews",
      args,
      headers
    );
    return res;
  } catch (error) {}
}

export async function fetchClientTimesheetTemplates(args) {
  try {
    const res = await restPost(
      "Client/FetchClientTimesheetTemplates2",
      args,
      headers
    );
    return res;
  } catch (error) {}
}

export async function SaveClientTimesheetTemplates(args) {
  try {
    const res = await restPost(
      "Client/SaveClientTimesheetTemplates",
      args,
      headers
    );
    return res;
  } catch (error) {}
}

export async function DeleteClientTimesheetTemplates(args) {
  try {
    const res = await restPost(
      "Client/DeleteClientTimesheetTemplates",
      args,
      headers
    );
    return res;
  } catch (error) {}
}

export async function DeleteClientTimesheetTemplateActivities(args) {
  try {
    const res = await restPost(
      "Client/DeleteClientTimesheetTemplateActivities",
      args,
      headers
    );
    return res;
  } catch (error) {}
}

export async function DeleteClientTimesheetTemplateEmployees(args) {
  try {
    const res = await restPost(
      "Client/DeleteClientTimesheetTemplateEmployees",
      args,
      headers
    );
    return res;
  } catch (error) {}
}

export async function GetClientTimesheetDashboard(userId) {
  const res = await restGet(
    `Client/GetClientTimesheetDashboard/${userId}`,
    headers
  );
  return res;
}
export async function fetchClientTake5Recordings(args) {
  const res = await restPost(
    "Client/FetchClientTake5Recordings",
    args,
    headers
  );
  return res;
}
export async function saveClientTake5Recordings(args) {
  const res = await restPost(
    "Client/SaveClientTake5Recordings",
    args,
    form_headers
  );
  return res;
}
export async function saveClientTake5(args) {
  const res = await restPost("Client/SaveClientTake5", args, headers);
  return res;
}
export async function fetchClientTake5(args) {
  const res = await restPost("Client/FetchClientTake5", args, headers);
  return res;
}
export async function deleteClientTake5Recordings(args) {
  const res = await restPost(
    "Client/DeleteClientTake5Recordings",
    args,
    headers
  );
  return res;
}

// client site filing
export async function FetchClientSiteFilings2(args) {
  const res = await restPost("Client/FetchClientSiteFilings2", args, headers);
  return res;
}

// client site filing
export async function ClientSafetyTicketsApi(args) {
  const res = await restPost("Client/ClientSafetyTicketsApi", args, headers);
  return res;
}

export async function SaveInvitationResponse(args) {
  const res = await restPost("Client/Client_SaveInvitations", args, headers);
  return res;
}

export async function GetClientSafetyTickets(clientId) {
  const res = await restGet(
    `Client/GetClientSafetyTickets/${clientId}`,
    headers
  );
  return res;
}
