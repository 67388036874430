import { Button, Card, Checkbox, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import { FetchClientJobActivities2 } from "../../../api/ClientApi";


export default function ClientJobActivities(props) {

    const [jobActivities, setJobactivities] = useState([]);
    const [searchInputText, setSearchInputText] = useState("");

    useEffect(() => {
        if (props.showJobActivities === true) {
            getClientjobActivities()
        }
    }, [props.showJobActivities])

    const getClientjobActivities = async () => {
        try {
            const obj = {
                PageNr: 1,
                FullSearch: searchInputText,
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{clientJobId: props.selectedJob[0]?.clientJobId}]
            }
            const res = await FetchClientJobActivities2(obj);
            const options = res.data.map((item) => {
                const isSelected = props.selectedJobActivities.filter((act) => act.clientActivityId === item.id);
                console.log("isSelected --->", isSelected);
                if (isSelected?.length > 0) {
                    if (isSelected[0]?.Deleted === true) {
                        return {
                            ...isSelected[0],
                            udField: []
                        }
                    } else {
                        return {
                            ...isSelected[0],
                            value: `${item.labourActivityCode} - ${item.labourActivityName}`,
                            label: `${item.labourActivityCode} - ${item.labourActivityName}`,
                            activityName: `${item.labourActivityCode} - ${item.labourActivityName}`,
                        }
                    }
                } else {
                    return {
                        value: `${item.labourActivityCode} - ${item.labourActivityName}`,
                        label: `${item.labourActivityCode} - ${item.labourActivityName}`,
                        id: 0,
                        clientActivityId: item.id,
                        isSelected: false,
                        activityName: `${item.labourActivityCode} - ${item.labourActivityName}`,
                        hours: 0,
                        quantity: 0,
                        notes: "",
                        pictures: [],
                        unit: "",
                        Deleted: false,
                        hoursChange: false,
                        selectedEmp: props.selectedEmp
                    }
                }
            })
            setJobactivities(options)
        } catch (error) {

        }
    }

    const selectClientjob = (item) => {
        console.log("item--->",item)
        let getList = jobActivities?.map((act) => {
            if (act?.clientActivityId === item?.clientActivityId) {
                return {
                    ...act,
                    isSelected: item?.isSelected === true ? false : true,
                }
            } else {
                return {
                    ...act
                }
            }
        })
        setJobactivities(getList);
    }

    const onSelectjobActivities = () => {
        let getSelected = jobActivities.filter((item) => item.isSelected === true);
        let getDeletedact = props.selectedJobActivities.filter((item) => item.Deleted === true);
        getSelected = [...getSelected, ...getDeletedact];
        if (getSelected?.length > 0) {
            props.setSelectJobActivities(getSelected);
        } else {
            props.setSelectJobActivities([]);
        }
        props.handleClose();
    }

    return (
        <>
            <Modal
                open={props.showJobActivities}
                onClose={props.handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Select Job Activities</Typography>
                            <TextField
                                value={""}
                                placeholder={"Search for a job activity"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{maxHeight:"450px",overflow:"scroll", boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px" }}>
                            {jobActivities.map((item) => (
                                <Stack flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => selectClientjob(item)}>
                                    <Checkbox checked={item?.isSelected}></Checkbox>
                                    <Typography>{item?.value}</Typography>
                                </Stack>
                            ))}
                        </Card>
                        <Button onClick={onSelectjobActivities} color="primary" fullWidth variant="contained" sx={{ marginTop: 2 }} disabled={jobActivities?.filter((item) => item?.isSelected === true)?.length !== 0 ? false : true}>Select {jobActivities?.filter((item) => item?.isSelected === true)?.length !== 0 ? jobActivities?.filter((item) => item?.isSelected === true)?.length : ""}</Button>
                    </div>
                </div>
            </Modal>

        </>
    )

}