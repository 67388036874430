import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
} from "@mui/material";
import { ListItemButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Search as SearchIcon } from "@mui/icons-material";
import ProductSearchField from "../../components/ProductSearchField";
import { fetchCompanies, saveCompanies2 } from "../../api/CompanyApi";
import CircularProgress from "@mui/material/CircularProgress";
import { saveProducts } from "../../api/ProductsApi";
import AddItemModelProducts from "../../components/profile/AddItemModelProducts";
import { BIBlue } from "../../assets/buildidColors";
import { Button } from "@material-ui/core";

function AddItemModel(props) {
  const [inputValue, setInputValue] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [submit, setSubmit] = useState(false);

  const history = useHistory();
  let type = props?.type ?? "";

  // Reset fields on close
  function onCloseClick() {
    setSubmit(false);
    setInputValue("");
    setFilterData([]);
    props.onClose();
  }

  function onTextChange(text) {
    setInputValue(text != "" ? text : "");
    getFilterData(text);
  }

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getFilterData = async (text) => {
    try {
      if (text.length <= 1) {
        return setFilterData([]);
      }
      if (type === "companies" || type === "manufacturers") {
        const res = await fetchCompanies({
          IncludeRecordNr: true,
          NrOfRecPerPage: 0,
          SearchList: [
            {
              name: text,
              manufacturer: type === "manufacturers" ? true : false,
            },
          ],
          SortList: [{ FieldName: "Name", Direction: "ASC" }],
        });
        let a;
        res.data.map((x) => {
          a = x;
          a["title"] = x.name;
          return a;
        });
        const response = await removeDuplicates(res.data, "name");
        return setFilterData(response);
      } else {
        return;
      }
    } catch (error) {
      console.log("Error while getting data::", error);
    }
  };

  async function handleAddItem() {
    try {
      setSubmit(true);
      if (type === "companies" || type === "manufacturers") {
        const saveObject = {
          userId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              Name: inputValue,
              ModifiedBy: Number(localStorage.userId),
              RecordStatusId: 1,
              Manufacturer: type === "manufacturers" ? true : false,
            },
          ],
        };
        const res = await saveCompanies2(saveObject);
        if (res?.[0]?.objData?.id) {
          onCloseClick();
          return history.push(
            `/${type === "manufacturers" ? "manufacturer" : "company"}/${
              res?.[0]?.objData?.id
            }`
          );
        }
        onCloseClick();
      } else {
        return null;
      }
    } catch (err) {
      onCloseClick();
      console.log("Error while saving the new item::", err);
    }
  }

  const getTitleName = () => {
    if (type === "companies") {
      return "Add Company";
    } else if (type === "manufacturers") {
      return "Add Manufacturer";
    } else {
      return "";
    }
  };

  const getPlaceHolderText = () => {
    if (type === "companies") {
      return "Company Name";
    } else if (type === "manufacturers") {
      return "Manufacturer Name";
    } else {
      return "";
    }
  };

  const getExistingText = () => {
    if (type === "companies") {
      return "Existing Companies";
    } else if (type === "manufacturers") {
      return "Existing Manufacturers";
    } else {
      return "";
    }
  };

  const showAddBtn = (value) => {
    const searchData = filterData.filter(
      (i) => i.name.toUpperCase() === value.toUpperCase()
    );
    return searchData?.length === 0 ? true : false;
  };

  const onViewClick = (item) => {
    if (type === "companies") {
      return history.push(`/company/${item?.id}`);
    } else if (type === "manufacturers") {
      return history.push(`/manufacturer/${item?.id}`);
    } else {
      return "";
    }
  };

  const onProductSave = (productId) => {
    if (!productId) return;
    history.push(`/product/${productId}`);
  };

  return (
    <>
      {type === "products" ? (
        <AddItemModelProducts
          open={props.open}
          onClose={() => {
            props.onClose();
          }}
          onProductSave={onProductSave}
          modalTitle={"Add Product"}
          listSectionType={"manufacturer"}
          showManufactureName={true}
          showManufactureNameInNewAddProduct={true}
          updateData={[]}
        />
      ) : (
        <Dialog
          open={props.open}
          onClose={onCloseClick}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>{getTitleName()}</DialogTitle>
          <Stack spacing={2} padding={"0px 40px"}>
            <ProductSearchField
              value={inputValue}
              onChange={(e) => onTextChange(e)}
              isFocused={() => {}}
              placeholder={getPlaceHolderText()}
            />
          </Stack>
          <DialogContent style={{ paddingTop: 0 }}>
            <Stack spacing={0}>
              <>
                {inputValue?.trim().length > 1 ? (
                  showAddBtn(inputValue) ? (
                    <div>
                      <ListItem
                        secondaryAction={
                          <div style={{ display: "flex" }}>
                            {!submit ? (
                              <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={() => handleAddItem()}
                              >
                                Add
                              </Button>
                            ) : (
                              <Button variant="contained" size="small">
                                <CircularProgress
                                  size={24}
                                  style={{ color: "white" }}
                                />
                              </Button>
                            )}
                          </div>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          sx={{ display: "flex", flexDirection: "row" }}
                        >
                          <ListItemText primary={inputValue} />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                {inputValue != "" ? (
                  <div>
                    {filterData.length != 0 ? (
                      <ListSubheader>{getExistingText()}</ListSubheader>
                    ) : (
                      <></>
                    )}
                    {filterData.length != 0 ? (
                      <>
                        {filterData.map((item, index) => {
                          return (
                            <div key={index.toString()}>
                              <ListItem
                                key={index.toString()}
                                secondaryAction={
                                  <div style={{ display: "flex" }}>
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      size="small"
                                      onClick={() => {
                                        onViewClick(item);
                                      }}
                                    >
                                      View
                                    </Button>
                                  </div>
                                }
                                disablePadding
                              >
                                <ListItemButton
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <ListItemText primary={item?.name} />
                                </ListItemButton>
                              </ListItem>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default AddItemModel;
