import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDbSSyyPW8MEb2R8jT5dSb5ntliwi3SbZU",
  authDomain: "mybuilderid-e7f6c.firebaseapp.com",
  databaseURL: "https://mybuilderid-e7f6c.firebaseio.com",
  projectId: "mybuilderid-e7f6c",
  storageBucket: "mybuilderid-e7f6c.appspot.com",
  messagingSenderId: "552465314432",
  appId: "1:552465314432:web:17db212118245b7f8cac65",
  measurementId: "G-BKN3R2MQ4V"
};

export const firebase_app = firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging()

export const getToken = async () => {
  let currentToken = "";
  try {
    currentToken = await messaging.getToken({
      // vapidKey: "BCqP195jNssapgeeygO8drnDTD9ScxKI-hwvN-zb67fNjRhmyWoJlQ8AuFoReTanbTw0I21vkLqVB3Ae63ioCto",
      // vapidKey: "BMJ661C-UrC8E2odc99PC-QrTkLTZPzkTomBrToYxElmIsmozUYP34lUJHVTuBLMvE1MnwyMVt0n-H232UDleZg",
      // vapidKey: "BD9mg7R5aFWb4WOG9UFPc1vWfpkkmD6_7Fs-06Y8DqIFexQTN398M0a95fwyNsoxiRfMGRdfTJQyFmN2BWr-wjA",
      // vapidKey: "BLqS6UxNajiFwuBlN9CHBxRxUSBlXZQuiqwgmzeJ7_LvxaW5eH-xHBJq1ULAumTGi_bX1D3aeL4LYIXI3S6sQsk",
      vapidKey: "BLqS6UxNajiFwuBlN9CHBxRxUSBlXZQuiqwgmzeJ7_LvxaW5eH-xHBJq1ULAumTGi_bX1D3aeL4LYIXI3S6sQsk",
    });
    console.log("current token", currentToken)
  } catch (error) {
    console.error("An error occurred while retrieving token.", error);
  }
  return currentToken;
};

export { messaging };

