import { Button, IconButton, LinearProgress, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import Employeeslist from "./Employeeslist";
import ClientJobListModal from "./ClientJobListModal";
import GeneralModal from "./GeneralModal";
import ActivitiesModal from "./ActivitiesModal";
import ClientJobActivities from "./ClientJobActivities";
import Reviews from "./Reviews.jsx";
import { DeleteClientTimesheetJobImages, FetchClientTimesheetJobs, SaveClientTimesheetJobImages, SaveClientTimesheets } from "../../../api/ClientApi.js";
import Loader from "../../../components/Loader.jsx";

export default function CrewModal(props) {

    const [showJobListModal, setShowJobListModal] = useState(false);
    const [showEmployeelist, setShowemployeelist] = useState(false);
    const [showJobActivities, SetShowjobactivities] = useState(false);

    const [selectedJob, setSelectedjob] = useState([]);

    const [emplist, setEmplist] = useState([]);
    const [selectedEmp, setSelectedemp] = useState([]);

    const [selectedJobActivities, setSelectJobActivities] = useState([]);

    const [currentStep, setCurrentstep] = useState(1);
    const [notes, setNotes] = useState("");
    const [pictures, setPictures] = useState([]);
    const [timesheetDate, setTimesheetdate] = useState(new Date());

    const [loader,setLoader] = useState(false);


    useEffect(() => {
        if (props.timesheetId !== null) {
            // Crew Timesheet Edit Flow
            getTimesheetdataforEdit();
        }
    }, [props.timesheetId]);

    const getTimesheetdataforEdit = async () => {
        const timesheetJobObject = {
            PageNr: 1,
            FullSearch: '',
            UserId: Number(localStorage?.userId),
            IncludeRecordNr: true,
            FetchAllowedRecordsOnly: false,
            SearchList: [{
                ClientTimesheetId: props.timesheetId
            }]
        };
        let getSelectedemp;
        let getResponse = await FetchClientTimesheetJobs(timesheetJobObject);
        if (getResponse?.data?.length > 0) {
            getResponse = getResponse?.data[0];
            // Set Client Job ID and Client Job Name and Notes
            let getJob = {
                id: getResponse?.id,
                clientJobId: getResponse?.clientJobId,
                clientJobName: getResponse?.clientJobName,
                clientJobCode: getResponse?.clientJobCode,
                Deleted: false,
                isSelected: true,
                name: getResponse?.clientJobCode + " - " + getResponse?.clientJobName
            }
            setSelectedjob([getJob]);
            setNotes(getResponse?.notes);
            // Set Selected Job Images
            let getImages = getResponse?.pictureList?.map((item) => {
                return {
                    id: item?.id,
                    isDeleted: false,
                    imgUrl: item?.thumbFileUrl,
                    imgFile: item?.thumbFileUrl
                }
            })
            setPictures(getImages);
            // Set Selected Employee
            if (getResponse?.timesheetEmployeesList?.length > 0) {
                getSelectedemp = getResponse?.timesheetEmployeesList?.map((emp) => {
                    // Set Selected Udfields
                    let getUdfield = emp?.udFieldsList.map((item) => {
                        return {
                            ...item,
                            checked: JSON.parse(item?.value),
                            fieldLabel: item?.label
                        }
                    })
                    return {
                        ...emp,
                        isSelected: true,
                        mileage: emp?.mileage,
                        mileageUnit: emp?.mileageUnit,
                        hours: emp?.employeeHours,
                        udField: getUdfield ?? [],
                        clientEmployeeName: emp?.clientEmployeeName,
                        Deleted: false
                    }
                })
                console.log("selected emp", getSelectedemp)
                setSelectedemp(getSelectedemp);
            }
            // Set Selected Activity 
            if (getResponse?.timesheetActivitiesList?.length > 0) {
                let getActivity = getResponse?.timesheetActivitiesList?.map((act) => {
                    // Set Selected Activity images
                    let getPictures = act?.pictureList.map((pic) => {
                        return {
                            id: pic?.id,
                            isDeleted: false,
                            imgUrl: pic?.fileUrl,
                            imgFile: pic?.thumbFileUrl
                        }
                    })
                    // Set Selected Activity udfields
                    let getUdfield = act?.udFieldsList.map((field) => {
                        return {
                            ...field,
                            checked: JSON.parse(field?.value),
                            fieldLabel: field?.label
                        }
                    })
                    return {
                        value: `${act.clientJobActivityCode} - ${act.clientJobActivityName}`,
                        label: `${act.clientJobActivityCode} - ${act.clientJobActivityName}`,
                        id: act.id,
                        clientActivityId: act?.clientActivityId,
                        isSelected: true,
                        activityName: `${act.clientJobActivityCode} - ${act.clientJobActivityName}`,
                        hours: act?.activityHours,
                        quantity: act?.qty,
                        notes: act?.notes,
                        pictures: getPictures ?? [],
                        unit: act?.qtyUnit,
                        udField: getUdfield,
                        Deleted: false,
                        hoursChange: false,
                        selectedEmp: getSelectedemp
                    }
                })
                setSelectJobActivities(getActivity);
            }
        }
    }

    const onNextflow = async () => {
        if (currentStep !== 3) {
            setCurrentstep(currentStep + 1);
        }
        if (currentStep === 3) {
            await onSaveCrewtimesheet();
        }
    }

    const onSaveCrewtimesheet = async () => {
        setLoader(true)
        let getClientjobinfo = selectedJob.find((item) => item?.isSelected === true);
        try {
            let employeeList = selectedEmp?.map((item) => {
                return {
                    Id: item?.id ?? 0,
                    ClientJobId: getClientjobinfo?.clientJobId,
                    ClientEmployeeId: item?.clientEmployeeId,
                    Mileage: item?.mileage,
                    MileageUnit: item?.mileageUnit,
                    UDFields: item?.udField?.length > 0 ? JSON.stringify(item?.udField?.reduce((acc, curr) => {
                        acc[curr.id] = curr?.checked === true ? 1 : 0
                        return acc;
                    }, {})) : "",
                    Deleted: item?.Deleted
                }
            });
            let timeSheethourslist = selectedJobActivities?.map((act) => {
                // Only deleted false employee take for hours
                let getEmployess = act?.selectedEmp?.filter((item) => item?.Deleted === false);
                let getEmphours = getEmployess.map((emp) => {
                    return {
                        Id: emp?.id ?? 0,
                        ClientJobId: getClientjobinfo?.clientJobId,
                        ClientEmployeeId: emp?.clientEmployeeId,
                        ClientJobActivityId: act?.clientActivityId,
                        Hours: emp?.hours,
                        Deleted: act?.Deleted
                    }
                });
                return getEmphours
            }).flat();   // This flattens the array of arrays into a single array
            let activityList = selectedJobActivities?.map((act) => {
                return {
                    Id: act?.id ?? 0,
                    ClientJobId: getClientjobinfo?.clientJobId,
                    ClientActivityId: act?.clientActivityId,
                    Qty: act?.quantity,
                    QtyUnit: act?.unit,
                    Notes: act?.notes,
                    UDFields: act?.udField?.length > 0 ? JSON.stringify(act?.udField?.reduce((acc, curr) => {
                        acc[curr.id] = 0;
                        return acc;
                    }, {})) : "",
                    Deleted: act?.Deleted
                }
            });
            let timesheetJoblist = selectedJob.map((item) => {
                return {
                    Id: item?.id,
                    ClientJobId: item?.clientJobId,
                    Notes: notes,
                    UDFields: "",
                    Deleted: item?.Deleted
                }
            })
            let getObj = {
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: props?.timesheetId ?? 0,
                        Date: timesheetDate,
                        DateSubmitted: new Date(),
                        StatusId: 1,
                        TypeId: 2,
                        ClientId: Number(localStorage?.clientId),
                        Deleted: false,
                        TimeSheetJobList: timesheetJoblist ?? [],
                        EmployeeList: employeeList,
                        TimesheetHoursList: timeSheethourslist,
                        ActivityList: activityList
                    }
                ]
            }
            console.log("getObj", getObj)
            const getResponse = await SaveClientTimesheets(getObj);
            let clientTimesheetId = getResponse?.data?.[0]?.objData?.ClientTimesheetId ?? null
            const timesheetJobObject = {
                PageNr: 1,
                FullSearch: '',
                UserId: Number(localStorage?.userId),
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{
                    ClientTimesheetId: clientTimesheetId
                }]
            };
            let getClientTimesheetjobId = await FetchClientTimesheetJobs(timesheetJobObject);
            getClientTimesheetjobId = getClientTimesheetjobId?.data[0]?.timesheetEmployeesList[0]?.clientTimesheetJobId;
            await onSaveClientjobImages(clientTimesheetId, getClientTimesheetjobId, getClientjobinfo?.clientJobId);
            await onSaveactivitiesImages(clientTimesheetId, getClientTimesheetjobId, getClientjobinfo?.clientJobId);
        } catch (error) {
            console.log("error", error);
        }
    }

    const onSaveClientjobImages = async (clientTimesheetId, clientTimesheetjobId, clientJobId) => {
        // At the time of Edit if you remove the job images then call DeleteClientTimesheetJobImages API
        let isDeletedjobImages = pictures?.filter((item) => item.isDeleted === true).map((item) => { return { id: item?.id, ModifiedBy: Number(localStorage?.userId) } });
        if (isDeletedjobImages?.length > 0) {
            const obj = {
                UserId: 1,
                ReturnRecordId: false,
                ReturnRecordError: true,
                SoftDelete: true,
                DeleteList: isDeletedjobImages
            }
            await DeleteClientTimesheetJobImages(obj);
        }
        // For new job image call SaveClientTimesheetJobImages API 
        if (pictures.length > 0) {
            let getNewImages = pictures?.filter((item) => item?.id === 0);
            for (let index = 0; index < getNewImages?.length; index++) {
                try {
                    const formData = new FormData();
                    formData.append("Id", 0);
                    formData.append("ClientTimesheetJobId", clientTimesheetjobId);
                    formData.append("ClientJobId", clientJobId);
                    formData.append("ClientActivityId", 0);
                    formData.append("ModifiedBy", Number(localStorage?.userId));
                    formData.append("ClientTimesheetId", clientTimesheetId);
                    formData.append("FileName", getNewImages[index]?.imgFile);
                    await SaveClientTimesheetJobImages(formData);
                } catch (error) {
                    console.log("error");
                }
            }
        }
    }

    const onSaveactivitiesImages = async (clientTimesheetId, clientTimesheetjobId, clientJobId) => {
        for (let i = 0; i < selectedJobActivities.length; i++) {
            if (selectedJobActivities[i].pictures.length > 0) {
                // At the time of Edit if you remove the Activity images then call DeleteClientTimesheetJobImages API
                let isDeletedjobImages = selectedJobActivities[i]?.pictures?.filter((item) => item.isDeleted === true).map((item) => { return { id: item?.id, ModifiedBy: Number(localStorage?.userId) } });
                if (isDeletedjobImages?.length > 0) {
                    try {
                        const obj = {
                            UserId: 1,
                            ReturnRecordId: false,
                            ReturnRecordError: true,
                            SoftDelete: true,
                            DeleteList: isDeletedjobImages
                        }
                        await DeleteClientTimesheetJobImages(obj);
                    } catch (error) {

                    }
                }
                // For new Activity images call SaveClientTimesheetJobImages API 
                let getNewImages = selectedJobActivities[i]?.pictures?.filter((item) => item.id === 0);
                if (getNewImages?.length > 0) {
                    for (let index = 0; index < getNewImages.length; index++) {
                        try {
                            const formData = new FormData();
                            formData.append("Id", 0);
                            formData.append("ClientTimesheetJobId", clientTimesheetjobId);
                            formData.append("ClientJobId", clientJobId);
                            formData.append("ClientActivityId", selectedJobActivities[i]?.clientActivityId);
                            formData.append("ModifiedBy", Number(localStorage?.userId));
                            formData.append("ClientTimesheetId", clientTimesheetId);
                            formData.append("FileName", getNewImages[index]?.imgFile);
                            await SaveClientTimesheetJobImages(formData);
                        } catch (error) {
                            console.error(`Error uploading image ${index}:`, error);
                        }
                    }
                }

            }
        }
        closeCrew(true);
    }

    const onBackflow = () => {
        setCurrentstep(currentStep - 1)
    }

    const closeCrew = async (refresh) => {
        setSelectJobActivities([]);
        setSelectedemp([]);
        setCurrentstep(1);
        setNotes("");
        setPictures([]);
        setSelectedjob([]);
        props.setTimesheetid(null);
        props.setOpencrew(false);
        // Refresh the client timesheet liist
        if(refresh) {
            await props.fetchClienttimesheet();
        }
        setLoader(false)
    }

    if(loader) {
        return (
            <div class="loader-overlay">
                <div class="spinner-border text-primary" role="status">
                    <Loader />
                </div>
            </div>
        )
    }
    
    return (
       <>
        <Modal
            open={props.openCrew}
            onClose={()=>closeCrew(false)}>
            <div className="modal-center">
                <div className="modal-card crew-modal" style={{ width: "600px", height: currentStep === 2 || currentStep === 3 && "670px" }}>
                    <div className="modal-card-header-buttons">
                        <IconButton
                            color="primary"
                            onClick={()=>closeCrew(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Stack textAlign={"center"} gap={1} marginBottom={2}>
                        <Typography fontSize={"17px"} fontWeight={600}>Step {currentStep} of 3</Typography>
                        <LinearProgress variant="determinate" value={currentStep === 1 ? 33.33 : currentStep === 2 ? 66.66 : currentStep === 3 ? 100 : 0} />
                    </Stack>
                    {currentStep === 1 && (
                        <GeneralModal
                            timesheetDate={timesheetDate}
                            setTimesheetdate={setTimesheetdate}
                            showEmployeelist={showEmployeelist}
                            setSelectedemp={setSelectedemp}
                            selectedEmp={selectedEmp}
                            setShowJobListModal={setShowJobListModal}
                            setShowemployeelist={setShowemployeelist}
                            selectedJob={selectedJob} />
                    )}
                    {currentStep === 2 && (
                        <ActivitiesModal
                            showJobActivities={showJobActivities}
                            setSelectJobActivities={setSelectJobActivities}
                            selectedJobActivities={selectedJobActivities}
                            SetShowjobactivities={SetShowjobactivities}
                            selectedEmp={selectedEmp}

                        />
                    )}
                    {currentStep === 3 && (
                        <Reviews
                            timesheetDate={timesheetDate}
                            notes={notes}
                            setNotes={setNotes}
                            selectedEmp={selectedEmp}
                            selectedJobActivities={selectedJobActivities}
                            pictures={pictures}
                            setPictures={setPictures}
                            setCurrentstep={setCurrentstep}
                        />
                    )}
                    {currentStep === 1 ? (
                        <Button fullWidth style={{ marginTop: "10px" }} color="primary" disabled={selectedEmp?.length === 0 || selectedEmp?.filter((item) => item.Deleted === true).length === selectedEmp?.length ? true : false} variant="contained" onClick={onNextflow}>Next</Button>
                    ) : (
                        <Stack marginTop={2} flexDirection={"row"} justifyContent={"space-between"}>
                            <Button style={{ marginTop: "10px", background: "#cccccc", color: "#000000" }} color="primary" disabled={false} variant="contained" onClick={onBackflow}>Back</Button>
                            <Button style={{ marginTop: "10px" }} color="primary" disabled={currentStep === 2 ? selectedJobActivities?.length === 0 || selectedJobActivities?.filter((item) => item.Deleted === true).length === selectedJobActivities?.length ? true : false : false} variant="contained" onClick={onNextflow}>{currentStep === 3 ? "Submit" : "Next"}</Button>
                        </Stack>
                    )}
                </div>
            </div>
        </Modal>
        <ClientJobListModal
            show={showJobListModal}
            handleClose={() => { setShowJobListModal(false) }}
            selectedJob={selectedJob}
            setSelectedjob={setSelectedjob}
            setSelectedemp={setSelectedemp}
            selectedEmp={selectedEmp}
            selectedJobActivities={selectedJobActivities}
            setSelectJobActivities={setSelectJobActivities}
        />
        {/* Select Employee  */}
        <Employeeslist
            setEmplist={setEmplist}
            setSelectedemp={setSelectedemp}
            selectedEmp={selectedEmp}
            emplist={emplist}
            showEmployeelist={showEmployeelist}
            handleClose={() => { setShowemployeelist(false) }} />

        <ClientJobActivities
            setSelectJobActivities={setSelectJobActivities}
            selectedJobActivities={selectedJobActivities}
            showJobActivities={showJobActivities}
            handleClose={() => { SetShowjobactivities(false) }}
            selectedEmp={selectedEmp}
            selectedJob={selectedJob}
        />
    </>
    )

}