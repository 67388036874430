import React, { useEffect, useState } from "react";
import { Modal, IconButton, TextField, Button } from "@material-ui/core";
import { Close, Close as CloseIcon, Search } from "@material-ui/icons";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import {
  Avatar,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { saveChatGroupUsers, searchUsersMembers } from "../../api/ChatApi";
import PromptModal from "../../components/PromptModal";
import { useHistory } from "react-router-dom";
import { saveContactEvent } from "../../api/UserApi";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import QRCode from "react-qr-code";
import { getDbValue } from "../../api/RestClient";

function AddContactModal(props) {
  const [newEmail, setNewEmail] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [cancelItem, setCancelitem] = useState({});
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState(null);
  const history = useHistory();
  // let getUrl = window.location.pathname.split("/").pop();

  useEffect(() => {
    const getQrCodeValue = async () => {
      getDbValue();
      setQrCodeValueBasedOnDb();
    };
    getQrCodeValue();
  }, []);

  const setQrCodeValueBasedOnDb = () => {
    let dbValue = localStorage?.db ?? null;
    if (dbValue) {
      let value = `${
        dbValue == 1
          ? process.env.REACT_APP_WEB_USER
          : dbValue == 2
          ? process.env.REACT_APP_WEB_USER
          : process.env.REACT_APP_WEB_USER
      }/refer/${localStorage.getItem("userId")}`;
      setQrCodeValue(value);
    }
  };

  const handleInputChange = async (e) => {
    let val=e.target.value;
    if (val.length === 1) {
      if (val === " ") return;
      setNewEmail(val);
    } else {
      if (/\s{2}/.test(val)) return; // Check for consecutive spaces using regex
      setNewEmail(val);
    }
    let getData = await searchUsersMembers(
      Number(localStorage.userId),
      0,
      e.target.value.trim()
    );
    if (getData?.length > 0) {
      setSearchData(getData);
    }
  };

  const onAddContactPressed = async (contact) => {
    try {
      let obj = {
        UserId: localStorage.userId,
        FriendsEmails: contact.userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(
            localStorage.userId
          )}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${contact?.userId}`
        },
      };
      let getOutput = await inviteFriendsByEmail(obj);
      if (getOutput === "Connect Request Send") {
        let getUpdatedlist = searchData.map((item) => {
          if (item?.userId === contact?.userId) {
            return {
              ...item,
              contactStatus: "Request Sent",
            };
          } else {
            return item;
          }
        });
        setSearchData(getUpdatedlist);
      }
    } catch (error) {
      console.log(error);
      alert("Error adding contact(s)");
    }
  };

  const onRequestSentBtnPressed = (item) => {
    setAlertModalOpen(true);
    setCancelitem(item);
  };

  const cancelRequest = async () => {
    try {
      const obj = {
        Id: 0,
        UserId: Number(localStorage.getItem("userId")),
        ContactEmail: cancelItem?.userEmail,
        EventStatusId: 3,
        ModifiedBy: Number(localStorage.getItem("userId")),
      };
      await saveContactEvent(obj);
      let getUpdatedlist = searchData.map((item) => {
        if (item?.userId === cancelItem?.userId) {
          return {
            ...item,
            contactStatus: "No",
          };
        } else {
          return item;
        }
      });
      setSearchData(getUpdatedlist);
      setCancelitem({});
      setAlertModalOpen(false);
    } catch (err) {}
  };

  const onMessagePressed = async (item) => {
    const saveListArray = [
      {
        Id: 0,
        ChatGroupId: 0,
        UserId: item?.userId,
        ModifiedBy: Number(localStorage.getItem("userId")),
        Muted: false,
      },
    ];
    const chatGroupObj = {
      UserId: Number(localStorage.getItem("userId")),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: saveListArray,
    };
    let result = await saveChatGroupUsers(chatGroupObj);
    if (result?.data?.length > 0) {
      let chatGroupId = result.data[0]?.objData.chatGroupId;
      history.push({
        pathname: "/chat",
        state: {
          from: "New Chat",
          chatGroupId: chatGroupId,
          showInList: true,
        },
      });
    }
  };

  const onAcceptPressed = async () => {
    try {
      const obj = {
        Id: 0,
        UserId: Number(localStorage.getItem("userId")),
        ContactEmail: cancelItem?.userEmail,
        EventStatusId: 2,
        ModifiedBy: Number(localStorage.getItem("userId")),
      };
      await saveContactEvent(obj);
      let getUpdatedlist = searchData.map((item) => {
        if (item?.userId === cancelItem?.userId) {
          return {
            ...item,
            contactStatus: "Yes",
          };
        } else {
          return item;
        }
      });
      setSearchData(getUpdatedlist);
    } catch (err) {}
  };

  const clearValue = () => {
    setNewEmail("");
    setSearchData([]);
  };

  const handleShareClick = () => {
    try {
      const message = `Check out BuildID today and become a part of a construction community that empowers and rewards you! \n user.mybuilderid.com/refer/${localStorage?.userId}`;
      const subject = encodeURIComponent("");
      const body = encodeURIComponent(message);
      const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
      window.location.href = mailtoLink;
    } catch (error) {
      console.log("Error while sharing :: ", error);
    }
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={() => {
          props.onClose();
          clearValue();
        }}
      >
        <div className="modal-center" style={{ width: "35%" }}>
          <div className="modal-card">
            <div className="modal-card-header-buttons">
              <IconButton
                color="primary"
                onClick={() => {
                  props.onClose();
                  clearValue();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="modal-card-body">
              <h2 style={{ fontSize: "19px" }}>Add Contact</h2>
              <TextField
                fullWidth
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      onClick={clearValue}
                      position="end"
                      style={{ cursor: "pointer" }}
                    >
                      {
                        // Only show "X" button when field not empty
                        newEmail !== "" ? (
                          <IconButton onClick={clearValue}>
                            <Close />
                          </IconButton>
                        ) : (
                          <></>
                        )
                      }
                    </InputAdornment>
                  ),
                }}
                value={newEmail}
                variant="outlined"
                onChange={handleInputChange}
                placeholder="Search people"
              />
              <List sx={{ width: "100%" }} className="contact-list">
                {searchData.map((item) => (
                  <ListItem
                    secondaryAction={
                      item?.contactStatus === "No" ? (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onAddContactPressed(item)}
                        >
                          connect
                        </Button>
                      ) : item?.contactStatus === "Request Sent" ? (
                        <Button
                          // style={{ background: "#083db8" }}
                          color="primary"
                          variant="outlined"
                          onClick={() => onRequestSentBtnPressed(item)}
                          className="request-sent"
                        >
                          Request Sent
                        </Button>
                      ) : item?.contactStatus === "Awaiting response" ? (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onAcceptPressed(item)}
                        >
                          Accept
                        </Button>
                      ) : (
                        <Button
                          style={{ background: "#083db8" }}
                          color="primary"
                          variant="contained"
                          onClick={() => onMessagePressed(item)}
                        >
                          Messages
                        </Button>
                      )
                    }
                  >
                    <ListItemAvatar>
                      <Avatar src={item?.pictureUrl} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item?.firstName + " " + item?.lastName}
                      secondary={item?.tradeName}
                    />
                  </ListItem>
                ))}
              </List>
              <div className="footer-btnbox">
                <Typography
                  style={{
                    fontSize: "14px",
                    color: BIGrey,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Can't find someone?
                  <span
                    style={{
                      color: BIBlue,
                      fontWeight: "600",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      setShowInviteModal(true);
                      props.onClose();
                    }}
                  >
                    Invite them
                  </span>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={showInviteModal}
        className="add-new-chat"
        onClose={() => {
          setShowInviteModal(false);
          props.onClose();
        }}
      >
        <div className="modal-center" style={{ border: "0px" }}>
          <div className="modal-card newchat-card">
            <div className="header-section">
              <Typography
                className="addsite-title"
                variant="h6"
                component="h2"
              ></Typography>
              <IconButton
                color="primary"
                onClick={() => {
                  setShowInviteModal(false);
                  props.onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Stack style={{ padding: "15px" }}>
              <Typography style={{ textAlign: "center", fontSize: "15px" }}>
                Invite people to join BuildID and earn bonus BuilderBucks for
                each person that sign up!
              </Typography>
              {qrCodeValue && (
                <div
                  style={{
                    height: "auto",
                    margin: "25px auto",
                    maxWidth: 200,
                    width: "100%",
                  }}
                >
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={qrCodeValue}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              )}
              <Stack direction={"row"} style={{ margin: "10px" }} gap={3}>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigator.clipboard
                      .writeText(
                        `user.mybuilderid.com/refer/${localStorage?.userId}`
                      )
                      .then((res) => {
                        alert("Link copied");
                      })
                      .catch((error) => {
                        console.error("Failed to copy: ", error);
                      });
                  }}
                >
                  Copy Link
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={handleShareClick}
                >
                  Share
                </Button>
              </Stack>
            </Stack>
          </div>
        </div>
      </Modal>

      <PromptModal
        isOpen={alertModalOpen}
        onClose={() => {
          setAlertModalOpen(false);
          setCancelitem({});
        }}
        title="Connect Request"
        desc="Are you sure want to cancel request ?"
        redButtonText="Cancel"
        blueButtonText="Ok"
        onRedButtonClick={() => {
          setAlertModalOpen(false);
          setCancelitem({});
        }}
        onBlueButtonClick={cancelRequest}
      />
    </>
  );
}

export default AddContactModal;
