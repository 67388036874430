import { Avatar, Card, CardContent, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { ClientHeader } from '../ClientHeader'
import Loader from '../../../components/Loader'
import { useHistory } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AvatarImage from "../../../assets/images/AvatarImage.jpeg"
import moment from 'moment';
import { getClientDashboardNotifications } from '../../../api/ClientApi';

export const ClientNotification = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const history = useHistory();

    const formatDescription = (description) => {
        return description.replace(/<([^>]+)>/g, (_, text) => `<strong>${text}</strong>`);
    };

    const getNotificationListIcon = (item) => {
        let icon;
        switch (item.title) {
            case 'Actions':
            case 'Timesheets Review':
            case 'Coaching':
            case 'Change Tracker':
            case 'Communicator':
                icon = (
                    <Avatar
                        sx={{
                            width: 40,
                            height: 40,
                            marginRight: 1,
                            marginLeft: 2
                        }}
                        src={item.custom4}
                    />
                );
                break;

            case 'Support':
                icon = (
                    <LiveHelpIcon sx={{
                        width: 40,
                        height: 40,
                        marginRight: 1,
                        marginLeft: 2,
                        borderRadius: 1,
                        color: localStorage.clientAccentColor
                    }} />
                );
                break;

            case 'Timesheets Approved':
            case 'Timesheets Rejected':
                icon = (
                    <CalendarMonthIcon sx={{
                        width: 40,
                        height: 40,
                        marginRight: 1,
                        marginLeft: 2,
                        borderRadius: 1,
                        color: localStorage.clientAccentColor
                    }} />
                );
                break;

            case 'Picture Board':
                icon = (
                    <Avatar
                        sx={{
                            width: 40,
                            height: 40,
                            marginRight: 1,
                            marginLeft: 2,
                            borderRadius: 1
                        }}
                        src={item.custom4}
                    />
                );
                break;

            case 'Expenses Approved':
                icon = (
                    <InsertDriveFileIcon sx={{
                        width: 40,
                        height: 40,
                        marginRight: 1,
                        marginLeft: 2,
                        borderRadius: 1,
                        color: localStorage.clientAccentColor
                    }} />
                );
                break;

            default:
                icon = (
                    <Avatar
                        sx={{
                            width: 40,
                            height: 40,
                            marginRight: 1,
                            marginLeft: 2
                        }}
                        src={AvatarImage}
                    />
                );
                break;
        }
        return icon;
    }

    function handleNotificationClick(item) {
        switch (item.title) {
            case 'Actions':
            case 'Actions Status':
                history.push(`/client-actions-details/${item.recordId}`);
                break;

            case 'Support':
                history.push('/client-support', { item });
                break;

            // case 'Timesheets Review':
            //   history.push('/timesheets-review', { item });
            //   break;

            // case 'Timesheets Approved':
            //   history.push('/timesheets-approved', { item });
            //   break;

            // case 'Timesheets Rejected':
            //   history.push('/timesheets-rejected', { item });
            //   break;

            case 'Coaching':
                history.push('/client-coaching', { item });
                break;

            case 'Change Tracker':
                history.push('/client-change-Tracker', { item });
                break;

            case 'Picture Board':
                history.push('/client-picture-board', { item });
                break;

            case 'Expenses Approved':
            case 'Expenses Rejected':
                history.push(`/client-expenses-details/${item.recordId}`);
                break;

            case 'Communicator':
                history.push('/client-communicator', { item });
                break;
        }
    }

    useEffect(() => {
        async function getNotificationList() {
            try {
                setIsLoading(true);
                const res = await getClientDashboardNotifications(Number(localStorage.clientEmployeeId), true);
                setNotificationList(res);
                setIsLoading(false);
            } catch (err) {
                console.log("getClientDashboardNotifications [ERROR] ", err);
            }
        }
        getNotificationList();
    }, [])

    return (
        <>
            <ClientHeader screenName={"Notification"} />
            <div className="page">
                <div style={{ padding: "20px" }}>
                    <div className="flex-container-vertical" style={{ flex: 2 }}>
                        {isLoading ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "80vh",
                                }}
                            >
                                <Loader />
                            </div>
                        ) : (
                            notificationList.length > 0 ? (
                                notificationList.map((item) => (
                                    <Card
                                        key={item.id} // add a unique key prop
                                        variant="outlined"

                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '5px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleNotificationClick(item)}
                                    >
                                        {getNotificationListIcon(item)}
                                        <CardContent sx={{
                                            display: 'flex', 
                                            flexDirection: 'column',
                                            '&:last-child': {
                                                paddingBottom: '16px',
                                            },
                                        }}>
                                            <Typography
                                                variant="body1"
                                                color="text.primary"
                                                dangerouslySetInnerHTML={{ __html: formatDescription(item?.description) }}
                                            />
                                            <Typography variant="body2" color="text.secondary">
                                                {moment(item.notificationDate).format("MMMM DD, YYYY")}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ))
                            ) : (
                                <Typography variant="body2" color="text.secondary" align="center">
                                    No notifications available
                                </Typography>
                            )
                        )}

                    </div>
                </div>
            </div>
        </>
    )
}
