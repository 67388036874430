import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { Publish as UploadIcon, Close as CloseIcon } from "@material-ui/icons";
import { TextField, Select, MenuItem } from "@material-ui/core";
import {
  Button,
  FormControl,
  Stack,
  Typography,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import defaultProjectImage from "../assets/images/project-profile-placeholder.jpg";
import { BIBlue, BIGrey, BILightGrey } from "../assets/buildidColors";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { TimePicker } from "@material-ui/pickers";
import ErrorIcon from "@mui/icons-material/Error";
import { formatNumericToTime } from "../assets/helpers";

export default function TimesheetCard(props) {
  let { item } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [timeType, setTimeType] = useState(null);
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const [goalTimeIndex, setGoalTimeIndex] = useState(null);
  const [timePickerValue, setTimePickerValue] = useState(null);

  function handleChooseFile(event) {
    props.setEdited();
    const files = event.target.files;
    const pictures = [];

    let allFilesValid = true;
    if (files?.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (!files[i].type.startsWith("image/")) {
          allFilesValid = false;
          break;
        }
      }
    }
    if (!allFilesValid)
      return alert("Please select valid image files (JPEG, PNG, GIF, etc.).");

    Array.from(files).forEach((file) => pictures.push(file));
    props?.onPictureSelect(pictures, props?.index);
    event.target.value = null;
  }

  const handleTimeChange = (timeValue) => {
    if (!timeType) return null;
    const formattedTime = timeValue.format("HH:mm");
    if (timeType === "job") {
      props?.handleTimeChangeForJob(formattedTime, props?.index);
    } else if (timeType === "goal") {
      props?.handleTimeChangeForGoal(
        formattedTime,
        props?.index,
        goalTimeIndex
      );
    } else {
      return null;
    }
  };

  const onTimePickerClick = async (time, type, goalIndex) => {
    const [hours, minutes] = time.split(":");
    const formattedTime = new Date();
    formattedTime.setHours(parseInt(hours, 10));
    formattedTime.setMinutes(parseInt(minutes, 10));
    setTimePickerValue(formattedTime);
    await setTimeType(type);
    if (type === "goal") {
      await setGoalTimeIndex(goalIndex);
    }
    setOpenTimeModal(true);
  };

  return (
    <>
      <div key={props.index} style={{ margin: "10px 0" }}>
        {console.log("item--->", item)}
        <Card style={{ padding: "20px" }}>
          <Typography>
            {item?.companyId == 0 || item?.companyName == ""
              ? "No Company"
              : item.companyName}
          </Typography>
          <CardHeader
            action={
              <IconButton
                aria-label="settings"
                onClick={() => {
                  props.onDeleteEntryPressed(props?.item, props?.index);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
            // title={item?.siteName}

            title={
              <>
                <Stack direction={"row"}>
                  <Avatar
                    variant="rounded"
                    style={{
                      marginRight: "10px",
                      width: "52px",
                      height: "52px",
                    }}
                    src={
                      item?.mainPictureUrl && item?.mainPictureUrl !== ""
                        ? item?.mainPictureUrl
                        : defaultProjectImage
                    }
                  ></Avatar>
                  <Stack>
                    <p>{item?.projectName}</p>
                    <p style={{ fontSize: "16px", color: BIGrey }}>
                      {item?.siteAddress.trim() + ", "}
                      {item?.siteCity.trim() + ", "}
                      {item?.siteProvince.trim() + ", "}
                    </p>
                    <p style={{ fontSize: "16px", color: BIGrey }}>
                      {item?.sitePostalCode.trim() + ", "}
                      {item?.siteCountry.trim()}
                    </p>
                  </Stack>
                </Stack>
              </>
            }
          />
          <Stack>
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <TextField
                style={{ width: "100px" }}
                type="text"
                variant="outlined"
                label="Hour"
                value={
                  item?.hours === 0 ? "00:00" : formatNumericToTime(item?.hours)
                }
                onClick={async () => {
                  props.setEdited();
                  onTimePickerClick(formatNumericToTime(item?.hours), "job");
                }}
                InputProps={{
                  readOnly: true,
                }}
              />

              <Typography>(Required)</Typography>
              <TextField
                style={{ width: "100px", marginLeft: "30px" }}
                variant="outlined"
                label="Distance"
                value={item?.distance?.toString() ?? ""}
                type="number"
                onChange={(e) => {
                  props.setEdited();
                  const value = e.target.value;
                  if (value >= 0) {
                    const cleanedText = value.replace(
                      /[^\d.]/g,
                      (match, index, original) => {
                        return match === "." &&
                          original.indexOf(match, index + 1) !== -1
                          ? ""
                          : match;
                      }
                    );
                    const decimalCount = cleanedText.split(".").length - 1;
                    if (decimalCount <= 1) {
                      props?.updateDistance(cleanedText, props?.index);
                    }
                  }
                }}
              />
              <Typography>{item?.unitOfDistance ?? "km"}</Typography>
              <div style={{ marginLeft: "auto" }}>
                <IconButton
                  onClick={() => {
                    props?.onExandClick(props?.index);
                  }}
                >
                  {item?.isJobItemExpanded ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </div>
            </Stack>
            {item.isError && (
              <Stack
                direction={"row"}
                gap={1}
                style={{ color: "red", margin: "10px 0px 5px" }}
                alignItems={"center"}
              >
                <ErrorIcon style={{ fontSize: "18px" }} />
                <Typography>
                  The total of all Goals/Categories' time cannot exceed the
                  Job's time.
                </Typography>
              </Stack>
            )}

            {item?.isJobItemExpanded && (
              <>
                <Stack style={{ marginTop: "15px" }}>
                  {item?.timesheetGoalsArray?.map(
                    (goalItem, goalIndex) => (
                      console.log("goalItem--->", goalItem),
                      (
                        <Stack
                          key={goalIndex}
                          direction={"row"}
                          gap={2}
                          alignItems={"center"}
                          style={{ margin: "5px 0" }}
                        >
                          <DoNotDisturbOnIcon
                            style={{ color: "red", cursor: "pointer" }}
                            onClick={() => {
                              props?.deleteTimeSheetGoal(
                                props?.index,
                                goalIndex
                              );
                            }}
                          />
                          <Stack
                            direction={"row"}
                            style={{
                              border: "1px solid",
                              borderColor: BILightGrey,
                              width: "200px",
                              height: "40px",
                              cursor: "pointer",
                            }}
                            gap={5}
                            alignItems={"center"}
                            onClick={() =>
                              props.handleRowClick({
                                goalItem: goalItem,
                                goalIndex: goalIndex,
                                itemIndex: props?.index,
                                item: item,
                              })
                            }
                          >
                            <Typography style={{ padding: "5px" }}>
                              {goalItem?.goalName
                                ? goalItem?.goalName
                                : goalItem?.categoryName}
                            </Typography>
                            <ArrowDropDownIcon style={{ marginLeft: "auto" }} />
                          </Stack>
                          testing
                          <TextField
                            style={{ width: "100px", marginLeft: "30px" }}
                            variant="outlined"
                            value={goalItem?.hours || goalItem?.time || "00:00"}
                            size="small"
                            onClick={async () => {
                              props.setEdited();
                              onTimePickerClick(
                                formatNumericToTime(
                                  goalItem?.time === "00:00"
                                    ? item?.hours
                                    : goalItem?.time
                                ),
                                "goal",
                                goalIndex
                              );
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Stack>
                      )
                    )
                  )}
                </Stack>
                {props?.goalList?.length > 0 && (
                  <Stack
                    direction={"row"}
                    gap={"10px"}
                    alignItems={"center"}
                    style={{ margin: "5px 0" }}
                  >
                    <Button
                      style={{ color: BIBlue }}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        props?.onAddGoalClick(item, props?.index);
                        props.setEdited();
                      }}
                    >
                      Add Goal
                    </Button>
                    <HelpIcon
                      style={{
                        color: BIGrey,
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        setOpenDialog(true);
                      }}
                    />
                  </Stack>
                )}

                <Stack>
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Comments (Optional)"
                    multiline
                    rows={2}
                    value={item?.comments ?? ""}
                    onChange={(e) => {
                      props.setEdited();
                      props?.updateComment(e.target.value, props?.index);
                    }}
                  />
                </Stack>
                <Stack>
                  <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    <Stack direction={"row"}>
                      {item?.pictureList?.length > 0 &&
                        item.pictureList?.map((item, picIndex) => {
                          return (
                            <div key={picIndex}>
                              <IconButton
                                aria-label="Close"
                                onClick={() => {
                                  props.setEdited();
                                  props.onDeletePicturePressed(
                                    picIndex,
                                    item,
                                    props?.index
                                  );
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                              <img
                                src={
                                  item?.pictureUrl
                                    ? item.pictureUrl
                                    : URL.createObjectURL(item)
                                }
                                alt="Timesheet"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  objectFit: "contain",
                                }}
                                accept="image/*"
                              />
                            </div>
                          );
                        })}
                    </Stack>
                  </div>
                  <Button
                    style={{ width: "100%", margin: "10px 0px" }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    component="label"
                    startIcon={<UploadIcon />}
                  >
                    Upload Image
                    <input
                      id="timeSheetPicture-upload"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={handleChooseFile}
                      hidden
                    />
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Card>
        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
        >
          <div style={{ width: "450px", height: "190px" }}>
            <DialogContent>
              You can add a Goal or Category to this Job to count hour credits
              towards it. The total of all Goals/Categories’ time cannot exceed
              the Job’s time.
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpenDialog(false);
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
      <TimePicker
        format="hh:mm a"
        ampm={false}
        value={timePickerValue ? timePickerValue : new Date()}
        open={openTimeModal}
        onClose={() => {
          setTimePickerValue(null);
          setTimeType(null);
          setOpenTimeModal(false);
        }}
        onChange={handleTimeChange}
        TextFieldComponent={() => null}
      />
    </>
  );
}
