import React, { useState, useEffect } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import {
  Edit as EditIcon,
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon,
} from "@material-ui/icons";
import { BIBlue } from "../../assets/buildidColors";
import { fetchExpenses } from "../../api/ExpensesApi";
import {
  Button,
  Card,
  CardContent,
  ImageList,
  ImageListItem,
} from "@material-ui/core";
import PictureViewer from "../../components/PictureViewer";
import Loader from "../../components/Loader";

export default function ExpensesListSection(props) {
  const [response, setResponse] = useState([]);
  const [nrOfRecords, setNrOfRecords] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    updateList();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const resetCheck = response.map((item) => (item.isExpenseChecked = false));
    setResponse(resetCheck);
    props.setSendButtonVisible(response.filter((res) => res.isExpenseChecked));
  }, [props.activeTab]);

  const updateList = async () => {
    setShowLoader(true);
    props.getData().then((res) => {
      setNrOfRecords(res.nrOfRecords);
      const newResponse = res.map((value) => ({
        ...value,
        isExpenseChecked: false,
        isExpand: false,
      }));
      setResponse(newResponse);
      setShowLoader(false); // Hide loader after response is set
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleToggle = (index) => {
    response[index].isExpenseChecked = !response[index].isExpenseChecked;
    setResponse([...response]);
    props.setSendButtonVisible(response.filter((res) => res.isExpenseChecked));
  };

  const callNextList = async () => {
    if (props?.infiniteScroll === true) {
      await props.increaseOffset();
      props.getData().then((res) => {
        const newResponse = res.map((item) => ({
          ...item,
          isExpenseChecked: false,
          isExpand: false,
        }));
        setResponse(newResponse);
      });
    }
  };

  return (
    <>
      {showLoader ? (
        <Loader />
      ) :
        <div className="all-tab">
          {response.length > 0 ? (
            <InfiniteScroll
              dataLength={response.length}
              next={callNextList}
              hasMore={true}
            >
              <Stack spacing={0}>
                {response.map((item, itemIndex) => (
                  <div key={itemIndex}>
                    <ListSectionItem
                      data={item}
                      handleToggle={() => handleToggle(itemIndex)}
                      itemExpenseDate={item.expenseDate}
                      itemDescription={item.description}
                      itemTotalAmount={item.totalAmount}
                      itemId={item.id}
                      setClickMode={props.setClickMode}
                      itemActiveTab={props.activeTab}
                      itemResponse={response}
                      itemSetResponse={setResponse}
                      itemSetSendButtonVisible={props.setSendButtonVisible}
                      itemGetEditExpensesData={props.getEditExpensesData}
                    />
                  </div>
                ))}
              </Stack>
            </InfiniteScroll>
          ) : (
            nrOfRecords !== 0 && (
              <CircularProgress
                style={{
                  textAlign: "center",
                  marginTop: "50%",
                  marginLeft: "50%",
                }}
              />
            )
          )}
        </div>}
    </>
  );
}


function ListSectionItem(props) {
  const [expenseInfo, setExpenseInfo] = useState({});
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const [expenseAmount, setExpenseAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);

  const expandCurrentExpense = async (e, getId) => {
    e.preventDefault();
    let getUpdatedresponse = props.itemResponse.map((item) => {
      if (item.id === getId) {
        return {
          ...item,
          isExpand: item.isExpand === true ? false : true,
        };
      } else {
        return {
          ...item,
          isExpand: false,
        };
      }
    });
    props.itemSetResponse(getUpdatedresponse);
    try {
      setIsLoading(true);
      const expenseEntry = await fetchExpenses({ SearchList: [{ id: getId }] });
      setExpenseAmount(expenseEntry.data[0]?.totalAmount.toFixed(2));
      setExpenseInfo(expenseEntry?.data[0]);

      setIsLoading(false);
    } catch (err) {
      console.log("Error : ", err);
    }
  };

  const openCurrentImg = () => {
    setPictureViewerOpen(true);
  };

  return (
    <>
      <ListItem
        key={props.itemId}
        secondaryAction={
          <div style={{ display: "flex" }}>
            <ListItemText
              style={{ marginRight: "1rem" }}
              primaryTypographyProps={{ fontSize: "20px", color: BIBlue }}
              primary={`$${props?.itemTotalAmount.toFixed(2)}`}
            />
            {/* <IconButton
                            style={{ color: BIBlue }}
                            onClick={() => { props.itemGetEditExpensesData(props?.itemId); props.setClickMode("EditExpense") }}>
                            <EditIcon />
                        </IconButton> */}
            {props?.data?.isExpand === true ? (
              <IconButton
                style={{ color: BIBlue }}
                onClick={(e) => expandCurrentExpense(e, props.itemId)}
              >
                <CollapseIcon />
              </IconButton>
            ) : (
              <IconButton
                style={{ color: BIBlue }}
                onClick={(e) => expandCurrentExpense(e, props.itemId)}
              >
                <ExpandIcon />
              </IconButton>
            )}
          </div>
        }
        disablePadding
      >
        <ListItemButton onClick={props.handleToggle}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <>
              <ListItemIcon>
                <Checkbox
                  style={{ color: BIBlue }}
                  value={JSON.stringify(props.itemId)}
                  checked={props.data?.isExpenseChecked}
                />
              </ListItemIcon>
            </>
            <ListItemText
              style={{ maxWidth: "85%" }}
              primary={props?.itemDescription}
              secondary={moment(props?.itemExpenseDate).format("MMMM DD, YYYY")}
            />
          </div>
        </ListItemButton>
      </ListItem>

      {props.data.isExpand === true && (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Card className="timesheetinfo-card">
                <CardContent className="sheet-conent">
                  {/* <h4 className="project-name">{expenseInfo?.siteName}</h4> */}
                  <p>
                    {" "}
                    {expenseInfo?.pictureList?.length > 0 && (
                      <>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <ImageList
                            className="project-img"
                            style={{ width: "500px", height: "auto" }}
                            cols={3}
                            rowHeight={164}
                          >
                            {expenseInfo?.pictureList?.map((item) => (
                              <ImageListItem>
                                <img
                                  onClick={openCurrentImg}
                                  srcSet={item?.pictureUrl}
                                  src={item?.pictureUrl}
                                  loading="lazy"
                                />
                              </ImageListItem>
                            ))}
                          </ImageList>
                        )}
                        <hr></hr>
                      </>
                    )}
                  </p>
                  <p>
                    Date :{" "}
                    <strong>
                      {moment(expenseInfo?.expenseDate).format("MMM D, YYYY")}
                    </strong>{" "}
                  </p>
                  <span>
                    <p>
                      Total Amount : <strong>${expenseAmount}</strong>{" "}
                    </p>
                  </span>
                  <p>
                    Description : <strong>{expenseInfo?.description}</strong>{" "}
                  </p>
                  <p>
                    Project : <strong>{expenseInfo?.siteAddress}</strong>{" "}
                  </p>
                  {expenseInfo?.comments && (
                    <p>
                      Comments : <strong>{expenseInfo?.comments}</strong>{" "}
                    </p>
                  )}

                  {props.itemActiveTab === "sent" ? (
                    <p
                      style={{
                        backgroundColor: "lightyellow",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <InfoIcon fontSize="small" /> This Expense has been sent.
                      Remember to resend if you edit!
                    </p>
                  ) : (
                    <></>
                  )}
                  <div style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      style={{
                        background: BIBlue,
                        color: "#fff",
                        width: "15%",
                      }}
                      onClick={() => {
                        props.itemGetEditExpensesData(props?.itemId);
                        props.setClickMode("EditExpense");
                      }}
                    >
                      EDIT
                    </Button>
                  </div>

                  {expenseInfo?.dateModified && (
                    <p
                      style={{
                        textAlign: "right",
                        fontSize: "small",
                        marginTop: "5px",
                      }}
                    >
                      Last Modified:{" "}
                      {moment
                        .parseZone(expenseInfo?.dateModified)
                        .local()
                        .format("YYYY-DD-MM h:ssa")}
                    </p>
                  )}
                </CardContent>
              </Card>
              {expenseInfo?.pictureList?.length > 0 && (
                <PictureViewer
                  isOpen={pictureViewerOpen}
                  onClose={() => setPictureViewerOpen(false)}
                  pictures={expenseInfo?.pictureList?.map((pic) => ({
                    src: pic.pictureUrl,
                    alt: "",
                  }))}
                  viewerIndex={pictureViewIndex}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
