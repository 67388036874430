import React, { useCallback, useEffect, useState } from "react";

import {
  Typography,
  ListItemButton,
  Grid,
  Avatar,
  Chip,
  Tab,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { TabContext } from "@material-ui/lab";
import { Tabs } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import debounce from "lodash/debounce";

import Loader from "../../../components/Loader";
import { FetchClientSupport } from "../../../api/ClientApi";
import { ClientHeader } from "../ClientHeader";
import "../../../assets/css/ClientHome.css";
import ClientSupportViewModal from "./ClientSupportViewModal";
import ClientSupportAddModal from "./ClientSupportAddModal";
import {
  BIBlack,
  BIGreen,
  BILightGreen,
  BILightGrey,
  BILightYellow,
  BIYellow,
} from "../../../assets/buildidColors";
import { useLocation } from "react-router-dom";

function ClientSupport() {
  const [value, setValue] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [addmodel, setaddmodel] = useState(false);
  const [filterData, setFilterData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [supportList, setSupportList] = useState([]);
  const [hasMoreFlag, setHasMoreFlag] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [supportRecordId,setSupportRecordId] = useState(0);
  const location = useLocation();

  const closeModel = () => setaddmodel(false);

  const tabArray = [
    {
      value: "",
      label: "All",
    },
    {
      value: "Open",
      label: "Open",
    },
    {
      value: "Completed",
      label: "Completed",
    },
    {
      value: "Closed",
      label: "Closed",
    },
  ];

  useEffect(() => {
    getSupportList();
  }, [value]);

  useEffect(() => {
    getData(clientSearchTerm);
  }, [clientSearchTerm]);

  const getData = useCallback(
    debounce(async (clientSearchTerm) => {
      setSupportList([]);
      await getSupportList(1, clientSearchTerm);
    }, 500),
    []
  );

  const removeDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  const getSupportList = async (page, a) => {
    if (pageNo == 1) {
      setSupportList([]);
      setPageNo(1);
    }
    setIsLoading(true);
    const obj = {
      PageNr: page || pageNo,
      NrOfRecPerPage: 10,
      FullSearch: a || "",
      UserId: localStorage?.userId,
      IncludeRecordNr: false,
      FetchAllowedRecordsOnly: true,
      SearchList: [
        {
          clientId: localStorage.getItem("clientId"),
          status: value,
        },
      ],
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };
    try {
      const res = await FetchClientSupport(obj);
      setPageNo(pageNo + 1);
      if (res?.data.length > 0) {
        setHasMoreFlag(true);
      } else {
        setHasMoreFlag(false);
      }
      setSupportList((prevList) => {
        let dub = removeDuplicates([...prevList, ...res?.data], "id");
        dub.sort((a, b) => {
          return new Date(b.id) - new Date(a.id);
        });
        return dub;
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setSupportList([]);
    setValue(newValue);
    if (newValue == "") {
      setPageNo(1);
      setFilterData("");
    } else if (newValue == "Open") {
      setPageNo(1);
      setFilterData("Open");
    } else if (newValue == "Completed") {
      setPageNo(1);
      setFilterData("Completed");
    } else if (newValue == "Closed") {
      setPageNo(1);
      setFilterData("Closed");
    }
  };

  useEffect(()=>{
    if(location.state){
      setSupportRecordId(location.state?.item?.recordId)
    }
  },[location.state])

  return (
    <>
      <ClientHeader
        screenName={"Support"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div style={{ padding: "20px" }}>
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <Loader />
              </div>
            ) : (
              <TabContext value={"1"}>
                <Tabs
                  sx={{
                    ".Mui-selected": {
                      color: localStorage?.clientAccentColor,
                    },
                  }}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: localStorage?.clientAccentColor,
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                >
                  {tabArray.map((tab, index) => (
                    <Tab
                      key={index}
                      value={tab.value}
                      label={tab.label}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        marginRight: "20px",
                      }}
                    />
                  ))}
                </Tabs>

                <InfiniteScroll
                  dataLength={supportList?.length}
                  next={() => getSupportList(pageNo, filterData)}
                  hasMore={hasMoreFlag}
                  loader={isLoading && <Loader />}
                  scrollableTarget="scrollableDiv_role"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  {isLoading ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                        }}
                      >
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      {supportList?.length == 0 ? (
                        <>
                          <Typography
                            style={{
                              color: "grey",
                              textAlign: "center",
                              marginTop: "20px",
                              height: "100vh",
                            }}
                          >
                            No Support Requests.
                          </Typography>
                        </>
                      ) : (
                        <></>
                      )}
                      {supportList?.map((item, index) => {
                        if (item?.status == value || value == "") {
                          return (
                            <ListComponent
                              key={index}
                              data={item}
                              reloadData={() => getSupportList(1, value)}
                            />
                          );
                        }
                      })}
                    </>
                  )}
                </InfiniteScroll>
              </TabContext>
            )}
          </div>
        </div>
      </div>
      <div className="fixed-button-container">
        <Fab
          className="fixed-button"
          style={{ background: localStorage?.clientAccentColor }}
          onClick={() => setaddmodel(true)}
        >
          <AddIcon
            style={{
              color: "white",
            }}
          />
        </Fab>
      </div>
      {addmodel && <ClientSupportAddModal
        open={addmodel}
        onClose={closeModel}
        reloadData={() => {
          getSupportList(1, value);
        }}
      />}
      {
        supportRecordId > 0 &&
        <ClientSupportViewModal
          open={supportRecordId > 0}
          onClose={() => {
            setSupportRecordId(0)
          }}
          id={supportRecordId} />
      }
    </>
  );
}

export default ClientSupport;

const ListComponent = ({ data, reloadData }) => {
  const [viewOpen, setViewOpen] = useState(false);

  return (
    <>
      <ListItemButton
        onClick={() => setViewOpen(true)}
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid container>
          <Grid
            item
            display={"flex"}
            sx={12}
            md={12}
            xl={12}
            lg={12}
            sm={12}
            justifyContent={"space-between"}
          >
            <Grid item display={"flex"} alignItems={"center"} gap={1}>
              <Avatar src={data?.userProfilePicture} />
              <Typography>{data?.clientEmployeeName}</Typography>
            </Grid>
            <Typography
              style={{
                color: "grey",
              }}
            >
              {moment(data?.dateCreated).format("MMMM DD, YYYY")}
            </Typography>
          </Grid>
          <Grid
            marginTop={1}
            item
            display={"flex"}
            sx={12}
            md={12}
            xl={12}
            lg={12}
            sm={12}
            justifyContent={"space-between"}
          >
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
              }}
              variant="body1"
            >
              {data?.description}
            </Typography>
          </Grid>
          <Grid
            item
            marginTop={1}
            display={"flex"}
            sx={12}
            lg={12}
            md={12}
            xl={12}
            sm={12}
            justifyContent={"space-between"}
          >
            <div>
              {data?.supportCategoryName && (
                <Chip
                  style={{
                    backgroundColor: "lightgray",
                    color: "black",
                    borderRadius: "5px",
                  }}
                  label={data?.supportCategoryName}
                />
              )}
            </div>
            <Chip
              style={
                data?.status == "Open"
                  ? {
                    backgroundColor: BILightGrey,
                    color: BIBlack,
                    minWidth: "100px",
                  }
                  : data?.status == "Close" || data?.status == "Closed"
                    ? {
                      backgroundColor: BILightGreen,
                      color: BIGreen,
                      minWidth: "100px",
                    }
                    : data?.status == "Completed"
                      ? {
                        backgroundColor: BILightYellow,
                        color: BIYellow,
                        minWidth: "100px",
                      }
                      : {}
              }
              label={data?.status}
            />
          </Grid>
        </Grid>
      </ListItemButton>
      <ClientSupportViewModal
        open={viewOpen}
        onClose={() => {
          setViewOpen(false);
          reloadData();
        }}
        id={data.id}
      />
    </>
  );
};
