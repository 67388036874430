import axios from "axios";
import { restGet, restGetResetCode, restPost, restPostLogin } from "./RestClient";

export const token = localStorage.getItem("token");

export let headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

export async function fetchUserProducts(args) {
  const res = await restPost("users/fetchUserProducts/", args, headers);
  return res?.data;
}
export let form_headers = {
  Accept: "application/json",
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + localStorage.getItem("token"),
};


let headers_without_authorization = {
  "Content-Type": "application/json",
  "Accept": "application/json",
};

// Account
export async function signIn(data) {
  const res = await restPost("users/SignIn2", data, headers);
  return res;
}

export async function userLogin(data) {
  const res = await restPostLogin(
    `USERS/V4/login`,
    data,
    headers_without_authorization
  );
  headers.Authorization = "Bearer " + res?.data?.data?.accessToken ?? null;
  return res;
}

export async function createAccount(data) {
  const res = await restPost(
    `users/v4/CreateAccount/`,
    data,
    headers_without_authorization
  );
  return res.data;
}

export async function updateVerifiedDetails(data) {
  const res = await restPost(
    `Users/V1/UpdateVerifiedDetails`,
    data,
    headers
  );
  return res;
}

export async function generateResetCode(userEmail) {
  try {
    const res = await restGetResetCode(
      "users/ForgotPassword/" + userEmail,
      headers_without_authorization
    );
    return res.data;
  } catch (error) {
    return error?.data;
  }
  
}

export async function isValidResetCode(params) {
  const res = await restPost(
    "users/IsValidResetCode",
    params,
    headers_without_authorization
  );
  headers.Authorization = "Bearer " + res?.data?.accessToken ?? null;
  return res;
}

export async function saveNewPassword(data,token) {
  const res = await restPost("users/ResetPassword", data,{
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  });
  return res;
}

export async function saveUserSupport(data) {
  const res = await restPost("users/SaveUserSupport/", data, headers);
  return res.data;
}

// General User Inf
export async function getUserInfo(userId) {
  const res = await restGet("users/GetUserInfo/" + userId, headers);
  return res;
}

export async function savePersonalInfo(data) {
  if (form_headers?.Authorization === "Bearer null") {
    const storedToken = localStorage.getItem("token");
    form_headers.Authorization = `Bearer ${storedToken}`;
  }
  const res = await restPost(`users/SavePersonalInfo/`, data, form_headers);
  return res.data;
}

export async function deactivateUserAccount() {
  const res = await restGet("users/DeactivateUserAccount/", headers);
  return res.data;
}

// Resume
export async function shareResumeWith(args) {
  const res = await restPost("users/ShareResumeWith/", args, headers);
  return res.data;
}

//Resume Builder
export async function sendResume(args) {
  // const res = await sendResumeWithFetch("users/SendResume/", args);
  const res = await restPost("users/SendResume/", args, headers);
  return res;
}

// BuilderBucks
export async function getUserRewardAmount(userId) {
  const res = await restGet("users/GetUserRewardAmount/" + userId, headers);
  return res;
}

// Employment Status
// TODO fetch replacement?
export async function getEmploymentStatuses() {
  const res = await restGet("users/GetEmployementStatuses/0/0/0/", headers);
  return res;
}

// User Certifications
export async function fetchUserTradeCertifications2(args) {
  const res = await restPost(
    "users/FetchUserTradeCertifications2/",
    args,
    headers
  );
  return res.data;
}

export async function saveUserTradeCertifications(data) {
  const res = await restPost(
    "users/SaveUserTradeCertification/",
    data,
    headers
  );
  return res.data;
}

export async function deleteUserTradeCertifications(data) {
  const res = await restPost(
    "users/DeleteUserTradeCertifications/",
    data,
    headers
  );
  return res.data;
}

// Work History Companies
export async function fetchUserCompanies(args) {
  const res = await restPost("users/fetchUserCompanies/", args, headers);
  return res.data;
}

export async function saveUserCompanies(args) {
  const res = await restPost("users/saveUserCompanies/", args, headers);
  return res.data;
}

export async function deleteUserCompanies(args) {
  const res = await restPost("users/deleteUserCompanies/", args, headers);
  return res.data;
}

export async function updateProgressBarDismiss(args) {
  const res = await restPost("Users/UpdateProgressBarDismiss", args, headers);
  return res.data;
}

export async function getUserProfileDetailsForProgressBar(userId) {
  const res = await restGet(
    `Users/GetUserProfileDetailsForProgressBar/${userId}`,
    headers
  );
  return res;
}

// Work History Projects
export async function fetchUserWorkHistory(args) {
  const res = await restPost("users/FetchUsersWorkHistory/", args, headers);
  return res.data;
}

export async function fetchUserWorkHistory2(args) {
  const res = await restPost("users/FetchUsersWorkHistory2/", args, headers);
  return res.data;
}

export async function saveUserWorkHistory(data) {
  const res = await restPost("users/SaveUserWorkHistory/", data, headers);
  return res.data;
}

export async function saveUsersWorkHistory(data) {
  const res = await restPost("Users/SaveUsersWorkHistory", data, headers);
  return res.data;
}

export async function fetchUserSitesProjects(args) {
  const res = await restPost("sites/FetchUserSitesProjects/", args, headers);
  return res.data;
}


export async function deleteUserWorkHistories(data) {
  const res = await restPost("users/deleteUsersWorkHistory/", data, headers);
  return res.data;
}

//WorkBuddyVerify

function getDB(dbId) {
  let base_url = "";
  if (dbId == 1) {
    return (base_url = process.env.REACT_APP_BASE_URL);
  } else if (dbId == 2) {
    return (base_url = process.env.REACT_APP_BASE_URL);
  } else if (dbId == 3) {
    return (base_url = process.env.REACT_APP_BASE_URL);
  }
}

export async function fetchWorkBuddies(dbId, data) {
  try {
    const res = await axios.post(
      getDB(dbId) + "Users/FetchWorkBuddies2",
      data,
      headers_without_authorization
    );
    if (res && res.data && res.data.status && res.data.status === true)
      return res.data;
    else {
      throw res.data.message;
    }
  } catch (error) {
    throw error;
  }
}

export async function updateIsVerifiedWorkBuddy(
  dbId,
  data,
  headers_without_authorization
) {
  try {
    const res = await axios.post(
      getDB(dbId) + "Users/UpdateIsVerifiedWorkBuddy",
      data
    );
    if (res && res.data && res.data.status && res.data.status === true)
      return res.data;
    else {
      throw res.data.message;
    }
  } catch (error) {
    throw error;
  }
}

// User Phones
export async function fetchUserPhones2(data) {
  const res = await restPost("users/FetchUserPhones2/", data, headers);
  return res.data;
}

export async function saveUserPhones(data) {
  const res = await restPost("users/SaveUserPhones/", data, headers);
  return res.data;
}

export async function deleteUserPhones(data) {
  const res = await restPost("users/DeleteUserPhones", data, headers);
  return res.data;
}

// User Emails
export async function fetchUserEmails2(data) {
  const res = await restPost("users/FetchUserEmails2", data, headers);
  return res.data;
}

export async function fetchUserTempEmails(data) {
  const res = await restPost("users/FetchUserTempEmails/", data, headers);
  return res.data;
}

export async function saveUserEmails(data) {
  const res = await restPost("users/SaveUserEmails", data, headers);
  return res.data;
}

export async function deleteUserEmails(data) {
  const res = await restPost("users/DeleteUserEmails", data, headers);
  return res.data;
}

// User Address
export async function getUserAddress(userId) {
  const res = await restGet("users/GetUserAddress/" + userId, headers);
  return res;
}

export async function saveUserAddress(data) {
  const res = await restPost("users/SaveUserAddress", data, headers);
  return res.data;
}

// Ride Share
// Retrieves nearby users that are interested in ride-sharing (based off of profile address)
export async function getPeopleInterestedInRideShare(userId) {
  const res = await restGet(
    "users/GetPeopleInterestedInRideShare/" + userId,
    headers
  );
  return res;
}

// User Trades
export async function fetchUserTrades(data) {
  const res = await restPost("users/fetchUserTrades/", data, headers);
  return res.data;
}

export async function saveUserTrades(data) {
  const res = await restPost("users/saveUserTrades/", data, headers);
  return res.data;
}

export async function deleteUserTrades(data) {
  const res = await restPost("users/deleteUserTrades/", data, headers);
  return res.data;
}

export async function getNewQuestionForTheUser(userId, prevQuestionId) {
  // const res = await restGet(`users/getNewQuestionForTheUser/${userId}/${projNo}/${prevQuestionId}`, headers);
  const res = await restGet(
    `users/getNewQuestionForTheUser/${userId}/${prevQuestionId}`,
    headers
  );
  return res;
}

export async function resendUserEmailVerificationCode(userId, emailId) {
  const res = await restGet(
    "users/ResendUserEmailVerificationCode/" + userId + "/" + emailId,
    headers
  );
  return res;
}

export async function sendSMSToUserPhone(data) {
  const res = await restPost("users/SendSMSToUserPhone/", data, headers);
  return res.data;
}

export async function isValidUserEmailResetCode(userId, userEmail, resetCode) {
  const res = await restGet(
    "users/isValidUserEmailResetCode/" +
    userId +
    "/" +
    userEmail +
    "/" +
    resetCode,
    headers
  );
  return res;
}

export async function isValidUserPhoneResetCode(userId, userPhone, resetCode) {
  const res = await restGet(
    "users/isValidUserPhoneResetCode/" +
    userId +
    "/" +
    userPhone +
    "/" +
    resetCode,
    headers
  );
  return res;
}

export async function saveDataIssues(data) {
  const res = await restPost("users/saveDataIssues/", data, headers);
  return res;
}

export async function getUserDashboard(contactId) {
  const res = await restGet("users/getUserDashboard/" + contactId, headers);
  return res;
}

export async function fetchPost(params) {
  const res = await restPost("Posts/FetchPosts", params, headers);
  return res;
}


export async function getUserContactStatusName(userId, contactId) {
  const res = await restGet(
    "users/getUserContactStatusName/" + userId + "/" + contactId,
    headers
  );
  return res;
}

export async function getRefUserName(userId) {
  const res = await restGet(
    "Users/GetUserName/" + userId,
    headers_without_authorization
  );
  return res;
}

export async function saveContactEvent(data) {
  const res = await restPost("users/SaveContactEvent/", data, headers);
  return res;
}

export async function generateResume(data) {
  const res = await restPost("users/GenerateResume2/", data, headers);
  return res;
}

export async function getRecentlyInteractUsers(data) {
  const res = await restGet(
    `Users/GetRecentlyInteractUsers/${localStorage.userId}`,
    headers
  );
  return res;
}

export async function updateIsReverifiedWorkBuddy(workBuddyId, IsReverified) {
  const res = await restGet(
    `Users/UpdateIsReverifiedWorkBuddy2/${workBuddyId}/${IsReverified}`
  );
  return res;
}

export async function saveUserSitesProjects(data) {
  const res = await restPost("Sites/SaveUserSitesProjects/", data, headers);
  return res.data;
}

export async function sendResumeWithFetch(url, params) {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_BASE_URL + url, {
      method: "POST",
      headers: form_headers,
      body: params,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch(async (error) => {
        console(error);
      });
  });
}

export async function fetchUniversalSearch(params) {
  try {
    const res = await restPost(`Users/FetchUniversalSearch/`, params, headers);
    return res.data;
  } catch (error) {
    throw error;
  }
}

//user usages

export async function saveUserUsages(params) {
  try {
    const res = await restPost(`Users/Saveuserusages`, params, headers);
    if (res.status === true) return res;
    else throw res.message;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function updateIsTimeSheetsBannerFlag(data) {
  const res = await restPost(
    "users/UpdateIsTimeSheetsBannerFlag/",
    data,
    headers
  );
  return res;
}

export async function saveRecentlyInteractUsers(data) {
  const res = await restPost("Users/SaveRecentlyInteractUsers", data, headers);
  return res;
}

export async function userDeviceLogout(deviceId) {
  const res = await restGet("Users/UserDeviceLogout/" + deviceId, headers);
  return res;
}

export async function fetchProjectsPostsPictures(args) {
  const res = await restPost(
    "projects/FetchProjectsPostsPictures/",
    args,
    headers
  );
  return res.data;
}

export async function fetchProjectsFavouriteImages(args) {
  const res = await restPost(
    "projects/FetchProjectsFavouriteImages/",
    args,
    headers
  );
  return res.data;
}

export async function saveProjectsFavouriteImages(args) {
  const res = await restPost(
    "projects/SaveProjectsFavouriteImages",
    args,
    headers
  );
  return res.data;
}
export async function deleteProjectsFavouriteImages(args) {
  const res = await restPost(
    "projects/DeleteProjectsFavouriteImages",
    args,
    headers
  );
  return res.data;
}

export async function updateNotificationStatus(data) {
  const res = await restPost( "notification/UpdateNotificationStatus", data, headers );
  return res;
}

//3.9
export async function fetchUsersSiteProjects(args) {
  const res = await restPost("Sites/FetchSitesProjects", args, headers);
  return res.data;
}
export async function GetNewQuestionForTheUser2(userId) {
  const res = await restGet(
    `Users/GetNewQuestionForTheUser2/${userId}`,
    headers
  );
  return res;
}

export async function fetchQuizQuestionId( args) {
  const res = await restPost("Users/FetchUserQuizzes", args,headers);
  return res.data;
}
