import React, { useCallback } from "react";
import { useState } from "react";
import SearchField from "../../components/SearchField";
import { fetchCompanies, searchCompanies } from "../../api/CompanyApi";
import SearchListSection from "../search/SearchListSection";
import { BIBlue, BIGrey } from "../../assets/buildidColors";
import { Stack } from "@mui/material";
import AddItemModel from "../search/AddItemModal";
import { IconButton } from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export default function Companylist() {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [getUpdatedData, setGetUpdatedData] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [hasData, setHasData] = useState(true);
  const [addItemModalOpen, setAddItemModalOpen] = useState(false);

  const getCompanylist = useCallback(
    async (pageNumber, searchText) => {
      try {
        // const obj = {
        //   UserId: Number(localStorage?.userId),
        //   IncludeRecordNr: true,
        //   SearchString: searchText ?? "",
        //   PageNumber: pageNumber === "" ? 1 : pageNumber,
        //   NrRowsPerPage: 20,
        // };
        // const res = await searchCompanies(obj);

        const obj = {
          PageNr: pageNumber === "" ? 1 : pageNumber,
          NrOfRecPerPage: 10,
          IncludeRecordNr: true,
          FullSearch: searchText ?? "",
          SearchList: [{ Manufacturer: false }],
          SortList: [{ FieldName: "Name", Direction: "ASC" }],
          FetchAllowedRecordsOnly: true,
          DoNotSearchInSystemFields: true,
        };

        const res = await fetchCompanies(obj);

        setTotalRecord(res?.nrOfRecords);
        setHasData(res?.data?.length != 0 ? true : false);
        return res?.data;
      } catch (error) {
        setTotalRecord(0);
        console.log("error--", error);
      }
    },
    [page]
  );

  const increaseOffset = (callback) => {
    setPage((prevPageNr) => prevPageNr + 1);
    callback(page + 1, searchText);
  };

  const clearSearch = (value) => {
    setSearchText(value);
    if (value === "") {
      setGetUpdatedData(true);
    }
  };
  return (
    <div className="dashboard-page">
      <AddItemModel
        open={addItemModalOpen}
        onClose={() => {
          setAddItemModalOpen(false);
        }}
        type={"companies"}
      />

      <div style={{ padding: "0% 4%" }}>
        <Stack
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            marginBottom: "10px",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
              width: "35vw",
            }}
          >
            <SearchField
              value={searchText}
              onChange={(value) => clearSearch(value)}
              onSubmit={() => {
                setPage(1);
                setGetUpdatedData(true);
              }}
              placeholder={"Search Companies"}
            />
          </div>
          <p
            style={{
              backgroundColor: "white",
              padding: "12px",
              color: BIGrey,
              borderRadius: "5px",
            }}
          >
            {totalRecord} : Result
          </p>
        </Stack>
        <div
          id="scrollList"
          className="scrollablediv_manufacturer experience-form"
        >
          <SearchListSection
            getData={getCompanylist}
            increaseOffset={increaseOffset}
            page={page}
            universalsearch={false}
            searchText={searchText}
            getUpdatedData={getUpdatedData}
            setGetUpdatedData={(val) => setGetUpdatedData(val)}
            from="company"
            hasMore={hasData}
          />
        </div>
      </div>

      <div style={{ position: "absolute", bottom: "40px", right: "10px" }}>
        <IconButton
          size="small"
          style={{ color: BIBlue }}
          onClick={() => {
            setAddItemModalOpen(true);
          }}
        >
          <AddCircleIcon
            fontSize="large"
            style={{
              height: "50px",
              width: "50px",
            }}
          />
        </IconButton>
      </div>
    </div>
  );
}
