import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import ClientChangeTrackerAddModal from "./ClientChangeTrackerAddModal";
import { fetchClientTracker2 } from "../../../api/ClientApi";
import moment from "moment";
import ClientChangeTrackerViewModel from "./ClientChangeTrackerViewModel";
import Loader from "../../../components/Loader";
import { Avatar, Stack, Typography } from "@mui/material";
export default function ClientTracketCardList(props) {
  const [trackerData, setTrackerData] = useState([]);
  const [displayCount, setDisplayCount] = useState(3);
  const [viewData, setViewData] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleViewModelClose = () => setViewOpen(false);
  const handleSeeMore = () => setDisplayCount(trackerData.length);
  const handelModel = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const reloadData = () => getChangeTracker(props?.data?.jobCode);



  useEffect(() => {
    getChangeTracker(props?.data?.jobCode);
  }, []);
 
  const getChangeTracker = async (jobCode) => {
    setLoading(true);
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: Number(localStorage.getItem("userId")),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SortList: [{ FieldName: "Date", Direction: "DESC" }],
        SearchList: [
          {
            clientEmployeeJobCode: jobCode,
          },
        ],
      };
      console.log("obj", JSON.stringify(obj, null, 2));
      let res = await fetchClientTracker2(obj);
      setLoading(false);
      setDisplayCount(3);
      setTrackerData(res?.data);
    } catch (error) {
      setLoading(false);
      console.log("error ::::::", error);
    }
  };
 

  const truncateAfterCharacterLimit = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  useEffect(() => {
    reloadData();
  }, [])

  return (
    <div>
      <Accordion
      defaultExpanded
        
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              onClick={() => {
              }}
            />
          }
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon style={{ color: localStorage.clientAccentColor }} />
              <span>{`${props?.data?.jobCode} - ${props?.data?.jobName}`}</span>
            </div>
            <AddIcon
              onClick={(e) => {
                e.stopPropagation();
                handelModel();
              }}
              style={{
                marginRight: "10px",
                color: localStorage.clientAccentColor
              }}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Loader />
          ) : (
            <>
              {trackerData.length === 0 ? (
                <>
                  <span>No Request Available</span>
                </>
              ) : (
                <>
                  {trackerData.slice(0, displayCount).map((item, index) => {
                    console.log("Item : ", item)
                    return (
                      <>
                        <div
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            setViewOpen(true);
                            setViewData(item);
                          }}
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            margin: "5px",
                            borderRadius: "5px",
                            cursor: 'pointer'
                          }}
                        >
                          <Stack direction="row" spacing={2} alignItems="center">
                            {/* Avatar */}
                            <Avatar alt="User Image" src={item?.userProfilePicture} />

                            {/* Username and description */}
                            <Stack direction="column" spacing={0.5}>
                              <Typography variant="body1">{item?.userName}</Typography>
                              <Typography variant="body2" color="textSecondary">{truncateAfterCharacterLimit(item?.description,100)}</Typography>
                            </Stack>

                            {/* Date */}
                            <Typography variant="body2" color="textSecondary" style={{ marginLeft: 'auto' }}>
                              {moment(item.date).format("MMM DD, YYYY")}
                            </Typography>
                          </Stack>
                        </div>
                      </>
                    )
                  })}
                  {displayCount < trackerData.length && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        color: "#B63F3B",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSeeMore();
                        }}
                        style={{ color: localStorage.clientAccentColor }}
                      >
                        {`See ${trackerData.length - displayCount} more`}
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <ClientChangeTrackerAddModal
        open={open}
        close={handleClose}
        data={props?.data}
        updateData={reloadData}
      />
      <ClientChangeTrackerViewModel
        data={viewData}
        open={viewOpen}
        close={handleViewModelClose}
      />
    </div>
  );
}
