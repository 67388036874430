// RGB hex codes for official BuildID colors
// If you change them here, also change them in App.css
export const BIBlue = "#083DB8";
export const BIYellow = "#FFBC0A";
export const BIGrey = "#8D8D8D";
export const BILightGrey = "#CCCCCC";
export const BIWhite = "#FFFFFF";
export const BIBlack = "#000000";
export const BIRed = "#FA0000";
export const BILightRed = "#FABEBE";
export const BIGreen = "#28A745";
export const BILightYellow = "#FFEEBF";
export const BILightGreen = "#c7d9bb";
export const BILightOrange = "#fbd7bd";
export const BIBrown = "#6f360f";

