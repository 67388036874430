import React from "react";
import { Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { useState } from "react";
import { useEffect } from "react";
import { getUserContacts } from "../../api/ContactsApi";
import {
  fetchUserTempEmails,
  getRecentlyInteractUsers,
  saveRecentlyInteractUsers,
  sendResume,
} from "../../api/UserApi";
import {
  searchUsersMembers,
} from "../../api/TicketsApi";
import AlertModal from "../../components/AlertModal";

const ResumeDetailsModal = (props) => {
  const [newEmail, setNewEmail] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [toSendWith, setToSendWith] = useState([]);
  const [sentResumeAlert, setSentResumeAlert] = useState(false);
  const [recentInteractUsers, setRecentInteractUsers] = useState([]);

  async function getContacts() {
    const res = await getUserContacts(localStorage.userId);
    setSearchData(res);
  }
  useEffect(() => {
    getUserList([]);
  }, []);

  const getUserList = async (item) => {
    let getValue = await searchUsersMembers(
      Number(localStorage.getItem("userId")),
      0,
      ""
    );

    // get Stored email from database --->
    const getEmailsObject = {
      PageNr: 0,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: Number(localStorage.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ CreatedBy: Number(localStorage.userId) }],
      SortList: [{}]
    }
    const tmpMaildata = await fetchUserTempEmails(getEmailsObject);
    console.log("tmpMaildata : ", tmpMaildata)
    const uniqueTmpData = removeDuplicates(tmpMaildata, "tempEmail");

    let b = [];
    const newData = uniqueTmpData.map((item) => {
      b = item;
      b['isSelected'] = false;
      b['userEmail'] = item.tempEmail;
      b['pictureUrl'] = '';
      b['contactId'] = 0;
      b['contactName'] = item.tempEmail;
      b['firstName'] = "";
      b['lastName'] = "";
      return b;
    })

    const combinedData = getValue?.concat(newData);

    setAllUserList(combinedData);
    console.log("both arr ", combinedData)
    setUserList(combinedData);
  };

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }
  async function fetchRecentInteractUsers() {
    const res = await getRecentlyInteractUsers(localStorage?.userId);
    const addedField = res.map((data) => ({
      ...data,
      isSelected: false,
    }));
    setRecentInteractUsers(addedField);
    // setRecentInteractUsers(res);
  }
  const handleRecentInteractUserClick = (user) => {
    if (user && user.interactUserEmail) {
      // Check if the user is already in the `toSendWith` array
      if (toSendWith.some((item) => item.contactId === user.interactUserId)) {
        // If the user is already in the array, remove them
        setToSendWith((prevToSendWith) =>
          prevToSendWith.filter(
            (item) => item.contactId !== user.interactUserId
          )
        );

        // Also, update `recentInteractUsers` to set `isSelected` to false
        const updated = recentInteractUsers.map((recent) => {
          if (recent.id === user.id) {
            return {
              ...recent,
              isSelected: false, // Set `isSelected` to false
            };
          }
          return recent;
        });

        setRecentInteractUsers(updated);
        return; // Stop execution after removing the user
      }

      // If the user is not in the array, add them
      setToSendWith((prevToSendWith) => [
        ...prevToSendWith,
        {
          contactEmail: user.interactUserEmail,
          contactId: user.interactUserId,
        },
      ]);

      // Update the `recentInteractUsers` array to toggle `isSelected`
      const updated = recentInteractUsers.map((recent) => {
        if (recent.id === user.id) {
          return {
            ...recent,
            isSelected: true, // Set `isSelected` to true
          };
        }
        return recent;
      });

      setRecentInteractUsers(updated);
    }
  };

  const handleInputChange = (e) => {
    setNewEmail(e.target.value.trim());
  };

  const handleEmail = (email) => {
    let tmpArr = email.map((item) => {
      if (item.contactEmail) {
        return {
          contactEmail: item.contactEmail,
          contactId: item.contactId,
        };
      } else {
        return item;
      }
    });
    setNewEmail("");
    setToSendWith(tmpArr);
  };
  function handleChange(_, newValue) {
    handleEmail(newValue);
  }

  const handleSendResumeBuilder = async () => {
    if (toSendWith.length !== 0) {
      const selectedUsers = toSendWith
        .map((user) => {
          if (user.contactEmail) {
            return user.contactEmail;
          } else if (typeof user === "string" && user.includes("@")) {
            return user;
          }
          return null;
        })
        .filter((email) => email !== null);

      if (selectedUsers.length === 0) {
        return;
      }

      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: selectedUsers.map((email) => ({
          Id: 0,
          UserId: Number(localStorage.userId),
          SharedWithEmail: email,
          ResumePdf: props?.resumeData?.resumePdf,
          ModifiedBy: Number(localStorage.userId),
        })),
      };

      try {
        const sendResumeResponse = await sendResume(obj);

        if (sendResumeResponse.message === "OK") {
          const interactionRequests = toSendWith
            .filter((item) => item.contactId)
            .map((item) => ({
              UserId: Number(localStorage.userId),
              ReturnRecordId: true,
              ReturnRecordError: true,
              SaveList: [
                {
                  Id: 0,
                  UserId: Number(localStorage.userId),
                  InteractUserId: item.contactId,
                  ModifiedBy: Number(localStorage.userId),
                  fromScreen: "Resume",
                },
              ],
            }));

          await Promise.all(
            interactionRequests.map((interactionObj) =>
              saveRecentlyInteractUsers(interactionObj)
            )
          );

          fetchRecentInteractUsers(localStorage.userId);
          props.Close();
          setSentResumeAlert(true);
          setNewEmail("");
          setToSendWith([]);
        }
      } catch (error) {
        console.error("Error sending resume:", error);
      }
    }
  };

  useEffect(() => {
    setToSendWith(toSendWith);
  }, [toSendWith]);

  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    fetchRecentInteractUsers();
  }, []);

  const clearSelected = ()=>{
    const updated = recentInteractUsers.map((recent) => {
        return {
          ...recent,
          isSelected: false,
        };
    });
    setRecentInteractUsers(updated);
  }

  return (
    <div>
      <AlertModal
        isOpen={sentResumeAlert}
        onClose={() => {setSentResumeAlert(false);}}
        title={"Resume Sent Successfully..."}
      />

      <Modal open={props.Open} onClose={()=>{props.Close();setToSendWith([]);clearSelected()}}>
        <div className="modal-center">
          <div
            className="modal-card"
            style={{ width: "1000px", height: "85vh" }}>
            <div className="modal-card-header-buttons">
              <IconButton color="primary" onClick={()=>{props.Close();setToSendWith([]);clearSelected()}}>
                <CloseIcon />
              </IconButton>
            </div>

            <Grid container spacing={2} className="modal-card-body">
            <Grid item xs={12} md={6} lg={8} xl={7}>
                <iframe
                  width={"100%"}
                  height={"530px"}
                  src={props?.resumeData?.resumePdf}
                  allowfullscreen
                  title="Resume"></iframe>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={5}>
                <h2>Share Resume</h2>
                <div style={{ marginTop: "15px" }}>
                  <h3>Title</h3>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <h4>{props.resumeData?.title}</h4>
                </div>
                <div style={{ marginTop: "15px" }}>
                  <p>Recipients</p>
                </div>
                <div
                  className="flex-container-horizontal"
                  style={{ alignItems: "stretch", minHeight:'40px', maxHeight:'150px', overflowX:'auto' }}>
                  <Autocomplete
                    id="sendWith-autocomplete"
                    inputValue={newEmail}
                    multiple
                    freeSolo
                    filterSelectedOptions
                    options={newEmail.length >= 1 ? userList : []}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option.contactEmail) {
                        return option.contactEmail;
                      }
                      if (option.contactName) {
                        return option.contactName;
                      }
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {
                          option.pictureUrl !=="" &&(
                            <img
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                              loading="lazy"
                              width="50px"
                              height="50px"
                              src={option.pictureUrl}
                              alt=""
                            />
                          )
                        }
                        {option?.firstName} {option?.lastName} <br />
                        {option?.userEmail}
                      </Box>
                    )}
                    value={toSendWith}
                    onChange={(event, newValue, reason) => {
                      if (reason === "removeOption") {
                        // Compare the previous value with the new value to detect which option was removed
                        const removedItem = toSendWith.find(
                          (item) => !newValue.includes(item)
                        );
                        if (removedItem) {
                          // Check if the removed item is part of recentInteractUsers
                          const recentUserIndex = recentInteractUsers.findIndex(
                            (recent) =>
                              recent.interactUserId === removedItem.contactId
                          );
                          if (recentUserIndex !== -1) {
                            // Toggle `isSelected` to false for the recent user
                            const updatedRecentUsers = [...recentInteractUsers];
                            updatedRecentUsers[
                              recentUserIndex
                            ].isSelected = false;
                            setRecentInteractUsers(updatedRecentUsers);
                          }
                        }
                      }else{
                        setNewEmail("");
                      }
                      setToSendWith(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        onChange={handleInputChange}
                        onBlur={(e) => {
                          if (e.target.value !== "") {
                            const flag = toSendWith.some(
                              (item) =>
                                item.contactEmail === e.target.value ||
                                item === e.target.value
                            );
                            if (flag) {
                              setNewEmail("");
                              return;
                            }
                            setToSendWith(toSendWith.concat(e.target.value));
                            setNewEmail("");
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32 || e.key === 32) {
                            if (e.target.value !== "") {
                              setToSendWith(toSendWith.concat(e.target.value));
                              setNewEmail("");
                            }
                          }
                        }}
                        fullWidth
                        label="Add Contacts or emails"
                        value={newEmail}
                      />
                    )}
                    style={{ flex: 1 }}
                  />
                </div>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    disabled={toSendWith.length !== 0 ? false : true}
                    onClick={handleSendResumeBuilder}>
                    Send
                  </Button>
                }
                <Grid container spacing={2} className="flex-container-vertical">
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginTop: "15px" }}>
                      {recentInteractUsers.length > 0 && <b>Recent</b>}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: "block",
                    marginTop: "10px",
                  }}>
                  {recentInteractUsers.map((user) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={user.userId}>
                      <div onClick={() => handleRecentInteractUserClick(user)}>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "10px",
                          }}>
                          <div>
                            {user.isSelected ? (
                              <Badge color="primary"
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  badgeContent={<CheckIcon style={{ fontSize: 12, }}
                                />
                              }>
                                <Avatar
                                  style={{ width: "40px", height: "40px" }}
                                  alt={user.interactUserName}
                                  src={user.interactUserImageUrl}
                                />
                              </Badge>
                            ) : (
                              <Avatar
                                style={{ width: "40px", height: "40px" }}
                                alt={user.interactUserName}
                                src={user.interactUserImageUrl}
                              />
                            )}
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}>
                              <b>
                                {user.interactUserName.length > 20
                                  ? user.interactUserName.substring(0, 20) +
                                    "..."
                                  : user.interactUserName}
                              </b>
                              <br />
                              {user.interactUserTradeName.length > 20
                                ? user.interactUserTradeName.substring(0, 20) +
                                  "..."
                                : user.interactUserTradeName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ResumeDetailsModal;
