import React, { useEffect, useState } from "react";
import { Mail as MailIcon, Phone as PhoneIcon } from "@material-ui/icons";
import {
  fetchUserEmails2,
  fetchUserPhones2,
  getUserContactStatusName,
  getUserDashboard,
} from "../../../api/UserApi";
import { List, ListItem, ListItemText } from "@mui/material";

export default function UserContactInfoCard(props) {
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [isContactInList, setIsContactInList] = useState(false);
  const [contactData, setContactData] = useState([]);

  const getEmails = async () => {
    try {
      const emailObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: true,
        SearchList: [{ Id: 0, CreatedBy: Number(props.contactId) }],
      };
      const emailAddresses = await fetchUserEmails2(emailObj);
      const filterEmails = emailAddresses.filter((x) => {
        return x.isPublicEmail == true;
      });
      setEmails(filterEmails);
    } catch (error) {
      console.log("Error---", error);
    }
  };

  const getPhones = async () => {
    try {
      const phoneObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: true,
        SearchList: [{ Id: 0, CreatedBy: Number(props.contactId) }],
      };
      const phonedata = await fetchUserPhones2(phoneObj);
      const filterPhones = phonedata.filter((x) => {
        return x.isPublicPhone == true;
      });
      setPhones(filterPhones);
    } catch (error) {
      console.log("Error---", error);
    }
  };

  const getUserDashboardData = async () => {
    try {
      const userContactData = await getUserDashboard(props.contactId);
      const contactStatus = await getUserContactStatusName(
        localStorage.userId,
        props.contactId
      );
      setContactData(userContactData);
      if (contactStatus == "Yes") {
        setIsContactInList(true);
      }
    } catch (error) {
      console.log("Error---", error);
    }
  };

  useEffect(async () => {
    try {
      await getEmails();
      await getPhones();
      await getUserDashboardData();
      await checkIsDataEmpty();
    } catch (error) {
      console.log("Error while getting details :: ", error);
    }
  }, [props.contactId]);

  const checkIsDataEmpty = () => {
    if (emails?.length === 0 && phones?.length === 0) {
      props?.setIsContactListEmpty(true);
    }
  };

  useEffect(() => {
    return () => {
      resetValues();
    };
  }, []);

  const resetValues = () => {
    setEmails([]);
    setPhones([]);
    setContactData([]);
    setIsContactInList(false);
  };

  if (props?.showLoader) {
    return null;
  }

  function formatPhoneNumber(number) {
    // Convert number to string
    let numStr = String(number);

    // Extract area code (first 3 digits)
    let areaCode = numStr.substring(0, 3);

    // Extract first part of phone number (next 3 digits)
    let firstPart = numStr.substring(3, 6);

    // Extract second part of phone number (last 4 digits)
    let secondPart = numStr.substring(6);

    // Format the number
    let formattedNumber = `(${areaCode}) ${firstPart}-${secondPart}`;

    return formattedNumber;
  }

  return (
    <>
      {isContactInList &&
        (emails.length != 0 || contactData?.phones.length != 0) && (
          <>
            {(emails?.some((item) => item.isPublicEmail) ||
              contactData.isPublicUserEmail ||
              contactData.phones.some((item) => item.isPublicPhone)) && (
              <div
                className="card"
                style={{ maxHeight: "1350px", width: "380px" }}
              >
                <div
                  className="flex-container-horizontal"
                  style={{ alignItems: "center" }}
                >
                  <h3 className="flex-container-left">About</h3>
                </div>
                <div
                  className="flex-container-horizontal profile-flex"
                  style={{
                    justifyContent: "space-between",
                    maxHeight: "1250px",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    {emails.length > 0 ? (
                      <>
                        <List sx={{ padding: 0 }}>
                          <div className="flex-container-horizontal">
                            <div style={{ marginTop: "10px" }}>
                              <MailIcon color="primary" fontSize="small" />
                            </div>

                            <div
                              style={{
                                marginLeft: "15px",
                                marginTop: "5px",
                                flex: 1,
                                maxHeight: "540px",
                                overflow: "scroll",
                              }}
                            >
                              {contactData.isPublicUserEmail ? (
                                <ListItemText
                                  primary={contactData?.userEmail}
                                  secondary={"Primary"}
                                />
                              ) : null}

                              {emails?.map((email, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemText
                                    primary={email.email}
                                    secondary={
                                      <>
                                        {email.emailType === "P"
                                          ? "Personal"
                                          : ""}
                                        {email.emailType === "W" ? "Work" : ""}
                                        {email.emailType === "O" ? "Other" : ""}
                                      </>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </div>
                          </div>
                        </List>
                      </>
                    ) : (
                      <></>
                    )}
                    {phones.length > 0 ? (
                      <>
                        <List sx={{ padding: 0 }}>
                          <div className="flex-container-horizontal">
                            <div style={{ marginTop: "10px" }}>
                              <PhoneIcon color="primary" fontSize="small" />
                            </div>

                            <div
                              style={{
                                marginLeft: "15px",
                                marginTop: "5px",
                                flex: 1,
                                maxHeight: "540px",
                                overflow: "scroll",
                              }}
                            >
                              {phones?.map((num, index) => (
                                <ListItem key={index} disablePadding>
                                  <ListItemText
                                    primary={formatPhoneNumber(num.phoneNo)}
                                    secondary={
                                      <>
                                        {num.phoneType === "H" ? "Home" : ""}
                                        {num.phoneType === "M" ? "Mobile" : ""}
                                        {num.phoneType === "W" ? "Work" : ""}
                                        {num.phoneType === "O" ? "Other" : ""}
                                      </>
                                    }
                                  />
                                </ListItem>
                              ))}
                            </div>
                          </div>
                        </List>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
    </>
  );
}
