import React, { useEffect, useState } from "react";
import { Button, Modal, IconButton, Typography } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import defaultProjectProfileThumbnail from "../../../assets/images/project-profile-placeholder-thumbnail.png";
import { stringToLocale } from "../../../assets/helpers";
import {
  fetchUserWorkHistory,
  fetchUserCompanies,
  fetchUserSitesProjects,
  fetchUserWorkHistory2,
} from "../../../api/UserApi";
import { useHistory } from "react-router-dom";
import ProjectForm from "./ProjectForm";
import CompanyForm from "./CompanyForm";
import AddProjectModal from "../../../components/AddProjectModal";
import {
  List,
  Card,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Avatar,
  Tooltip,
  CardContent,
} from "@mui/material";
import { fetchSitesProjects } from "../../../api/SitesApi";
import DetailForm from "./DetailForm";
import moment from "moment";
import Loader from "../../../components/Loader";
import ExperienceProjectForm from "../../../components/ExperienceProjectForm";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ExperienceCard = (props) => {
  const [newCompanyModalOpen, setNewCompanyModalOpen] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [siteFlag, setSiteFlag] = useState(false);

  const [myExpFlag, setMyExpFlag] = useState(false);
  const [workHistoryCompanies, setWorkHistoryCompanies] = useState([]);
  const [workHistorySites, setWorkHistorySites] = useState([]);
  const [unknownCompany, setUnknownCompany] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [siteIdArray, setSiteIdArray] = useState([]);
  // Project selected from Project via history.push() redirect (selecting project on map for new Experience)
  const [selectedProject, setSelectedProject] = useState({});
  // Passed (and now returned) company for which the selectedProject is for
  const [selectedProjectCompany, setSelectedProjectCompany] = useState({});

  const location = useLocation();
  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getExperienceDetails = async () => {
    props.setLoading(true);
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 20,
        UserId: Number(localStorage.userId),
        SearchList: [{ UserId: Number(localStorage.userId) }],
        SortList: [{ FieldName: "id", Direction: "DESC" }],
      };
      const workHistory = await fetchUserWorkHistory2(workHistoryObj);
      const filteredWorkHistory = workHistory.userExperienceList.filter(
        (item) => item.projectId !== 0
      );
      const siteIdArray = filteredWorkHistory.map((work) => work.projectId);
      const userCompanies = workHistory.userCompanyHistory;
      const noCompSites = filteredWorkHistory.filter(
        (comp) => comp.companyId == 0
      );

      let a = [];
      const expData = userCompanies.map((unique) => {
        a = unique;
        a["experience"] = [];
        filteredWorkHistory.map((tempData) => {
          if (tempData.companyId === unique.companyId) {
            let obj = {
              id: tempData.id,
              companyId: tempData.companyId,
              companyName: tempData.companyName,
              endDate: tempData.endDate,
              includeInResume: tempData.includeInResume,
              present: tempData.present,
              mainPictureUrl: tempData.mainPictureUrl,
              siteId: tempData.siteId,
              siteName: tempData.siteName,
              roleName: tempData.roleName,
              roleId: tempData.roleId,
              projectCompanyRoleName: tempData.projectCompanyRoleName,
              projectCompanyRoleId: tempData.projectCompanyRoleId,
              startDate: tempData.startDate,
              tradeId: tempData.tradeId,
              tradeName: tempData.tradeName,
              address: tempData.address,
              city: tempData.city,
              province: tempData.province,
              postalCode: tempData.postalCode,
              country: tempData.country,
              latitude: tempData.latitude,
              longitude: tempData.longitude,
              description: tempData.description,
              projectName: tempData.projectName,
              projectId: tempData.projectId,
            };
            a["experience"].push(obj);
          }
        });
        return a;
      });

      const uniqueSites = [
        ...new Set(filteredWorkHistory.map((item) => item.siteId)),
      ];
      const siteData = uniqueSites
        .map((siteId) => {
          const siteExperience = filteredWorkHistory.filter(
            (item) => item.siteId === siteId
          );
          if (siteExperience.length > 0) {
            return {
              siteId,
              siteName: siteExperience[0].siteName,
              experience: siteExperience.map((item) => ({
                ...item,
              })),
            };
          }
        })
        .filter((item) => item);
      let b = [];
      const noSiteExpData = noCompSites.map((unique) => {
        b = unique;
        b["experience"] = [];
        filteredWorkHistory.map((tempData) => {
          if (tempData.companyId === 0) {
            let obj = {
              id: tempData.id,
              companyId: tempData.companyId,
              companyName: tempData.companyName,
              endDate: tempData.endDate,
              includeInResume: tempData.includeInResume,
              present: tempData.present,
              mainPictureUrl: tempData.mainPictureUrl,
              siteId: tempData.siteId,
              siteName: tempData.siteName,
              roleName: tempData.roleName,
              roleId: tempData.roleId,
              projectCompanyRoleName: tempData.projectCompanyRoleName,
              projectCompanyRoleId: tempData.projectCompanyRoleId,
              startDate: tempData.startDate,
              tradeId: tempData.tradeId,
              tradeName: tempData.tradeName,
              address: tempData.address,
              city: tempData.city,
              province: tempData.province,
              postalCode: tempData.postalCode,
              country: tempData.country,
              latitude: tempData.latitude,
              longitude: tempData.longitude,
              description: tempData.description,
              projectName: tempData.projectName,
            };
            b["experience"].push(obj);
          }
        });
        return b;
      });
      const totalExpData = [...expData, ...noSiteExpData];
      const sortedExpData = totalExpData
        .filter((op) => op.startDate && op.companyName != "")
        .sort((a, b) => {
          let dateA = new Date(a.startDate),
            dateB = new Date(b.startDate);
          return dateB - dateA;
        });
      const noDateExp = totalExpData.filter(
        (exp) =>
          !exp.startDate &&
          !exp.endDate &&
          exp.companyId != 0 &&
          exp.companyName !== ""
      );
      const unknownExpData = totalExpData.filter(
        (exp) => exp.companyName == ""
      );
      const newUnknownExpData = removeDuplicates(unknownExpData, "companyId");
      const sortedNewUnknownExpData = newUnknownExpData[0]?.experience.sort(
        (a, b) => {
          let dateA = new Date(a.startDate),
            dateB = new Date(b.startDate);
          return dateB - dateA;
        }
      );
      const finalExpData = [...sortedExpData, ...noDateExp];
      setWorkHistoryCompanies(finalExpData);
      setAllCompanies(userCompanies);
      // new code sanket 15-03-2024
      setUnknownCompany(sortedNewUnknownExpData ?? []);
      setSiteIdArray(siteIdArray);
      setWorkHistorySites(siteData);
      props.setLoading(false);
    } catch (err) {
      console.log("Error-----------", err);
      props.setLoading(false);
    }
  };

  const handleSiteList = () => {
    setSiteFlag(true);
  };

  const handleCompanyList = () => {
    setSiteFlag(false);
  };

  const handleMyExperience = () => {
    setMyExpFlag(true);
  };

  const handleBackMyExperience = () => {
    setMyExpFlag(false);
  };

  const handleEdit = () => {
    setEditFlag(true);
  };

  const handleBack = () => {
    setEditFlag(false);
  };

  useEffect(() => {
    getExperienceDetails();
  }, []);

  useEffect(() => {
    if (
      props?.siteProjectFromSite ||
      location?.state?.from === "NotificationsForProfile" ||
      location?.search == "?from=NotificationsForProfile"
    ) {
      setEditFlag(true);
    }
  }, []);

  const getUpdatedData = () => {
    getExperienceDetails();
  };

  if (props.loading) {
    return <></>;
  }

  const handleMyExperienceNew = () => {
    setEditFlag(true);
  };
  return (
    <div className="exp-card">
      <Modal
        open={newCompanyModalOpen}
        onClose={() => setNewCompanyModalOpen(true)}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "400px" }}>
            <div className="modal-card-body">
              <CompanyForm
                company={{
                  id: 0,
                  companyId: 0,
                  companyName: "",
                  roleId: 0,
                  roleName: "",
                  startDate: null,
                  endDate: null,
                  currentCompany: false,
                  includeInResume: false,
                  description: "",
                }}
                allCompanies={allCompanies}
                onReturnClick={() => {
                  getExperienceDetails();
                  setNewCompanyModalOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* New code 19-03-2024 Mahindra */}
      {
        // If user has no companies added, show message
        workHistoryCompanies?.length === 0 && workHistorySites?.length === 0 ? (
          <>
            {!myExpFlag ? (
              <>
                <div className="flex-container-horizontal">
                  <div className="flex-container-left">
                    <div className="input-container">
                      <h3>Experience</h3>
                      <div style={{ margin: "10px", padding: "0px 10px" }}>
                        <div
                          className="flex-container-horizontal"
                          style={{
                            justifyContent: "space-between",
                            color: "gray",
                          }}
                        >
                          Strengthen your resume and earn BuilderBucks by adding
                          the Companies and Projects you worked on.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => handleMyExperience()}
                >
                  Add My Experience
                </Button>
              </>
            ) : (
              <>
                <div className="flex-container-horizontal">
                  <div className="flex-container-left">
                    <div className="input-container">
                      <h3>My Experience</h3>
                    </div>
                  </div>
                  <div className="flex-container-right">
                    <div className="input-container">
                      {myExpFlag ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => handleBackMyExperience()}
                        >
                          Back
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "10px " }}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onClick={() => setNewCompanyModalOpen(true)}
                  >
                    <b> Add Company</b>
                  </Button>
                </div>
              </>
            )}
            {/* ADD SECTION HERE ---> */}
          </>
        ) : (
          <>
            <div className="flex-container-horizontal">
              <div className="flex-container-left">
                {editFlag ? <h3>My Experience</h3> : <h3>Experience</h3>}
              </div>
              {/* ADD SECTION HERE ---> */}
              <div className="flex-container-right">
                <div>
                  {editFlag ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleBack()}
                    >
                      Back
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleEdit()}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div>
              {editFlag ? (
                <>
                  <List sx={{ padding: 0, marginTop: "5px" }}>
                    {
                      // Display user's companies
                      workHistoryCompanies.map((company, index) => (
                        <div key={index}>
                          <Company
                            company={company}
                            isFormOpen={
                              selectedProjectCompany &&
                              company?.companyId ===
                                selectedProjectCompany?.companyId
                            }
                            selectedProject={selectedProject} // Used if isFormOpen is true
                            allCompanies={allCompanies}
                            siteIdArray={siteIdArray}
                            resetSelected={() => {
                              setSelectedProject({});
                              setSelectedProjectCompany({});
                            }}
                            updateWorkHistory={getExperienceDetails}
                            editFlag={editFlag}
                            siteInfo={props.siteInfo}
                            siteProjectFromSite={
                              props?.siteProjectFromSite ? true : false
                            }
                            getUpdatedData={getUpdatedData}
                          />
                        </div>
                      ))
                    }
                  </List>

                  {
                    // Display user's companies
                    <>
                      {unknownCompany?.length != 0 &&
                      unknownCompany != undefined ? (
                        <>
                          <p>
                            <b>No Company</b>
                          </p>
                          {unknownCompany?.map((company, index) => (
                            <div key={index}>
                              <Project
                                key={index}
                                project={company}
                                company={company}
                                allCompanies={allCompanies}
                                updateWorkHistory={getExperienceDetails}
                                editFlag={editFlag}
                                getUpdatedData={getUpdatedData}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  }

                  <div style={{ marginTop: "15px " }}>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={() => setNewCompanyModalOpen(true)}
                    >
                      <b> Add Company</b>
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {siteFlag ? (
                    <div>
                      <Button
                        color="primary"
                        onClick={() => handleCompanyList()}
                      >
                        View By Company
                      </Button>
                      {workHistorySites.length != 0 ? (
                        <List sx={{ padding: 0 }}>
                          {workHistorySites.map((company, index) => (
                            <div key={index}>
                              <Company
                                company={company}
                                sitesData={true}
                                editFlag={editFlag}
                                getUpdatedData={getUpdatedData}
                              />
                            </div>
                          ))}
                        </List>
                      ) : (
                        <>
                          <div className="flex-container-horizontal">
                            <div className="flex-container-left">
                              <div
                                style={{ margin: "10px", padding: "0px 10px" }}
                              >
                                <div
                                  className="flex-container-horizontal"
                                  style={{
                                    justifyContent: "space-between",
                                    color: "gray",
                                  }}
                                >
                                  Strengthen your resume and earn BuilderBucks
                                  by adding the Companies and Projects you
                                  worked on.
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleMyExperienceNew()}
                          >
                            Add My Experience
                          </Button>
                        </>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Button color="primary" onClick={() => handleSiteList()}>
                        View By Sites
                      </Button>
                      {workHistoryCompanies.length != 0 ? (
                        <List sx={{ padding: 0 }}>
                          {workHistoryCompanies.map((company, index) => (
                            <div key={index}>
                              <Company
                                company={company}
                                editFlag={editFlag}
                                sitesData={false}
                                getUpdatedData={getUpdatedData}
                              />
                            </div>
                          ))}
                        </List>
                      ) : (
                        <>
                          <div className="flex-container-horizontal">
                            <div className="flex-container-left">
                              <div
                                style={{ margin: "10px", padding: "0px 10px" }}
                              >
                                <div
                                  className="flex-container-horizontal"
                                  style={{
                                    justifyContent: "space-between",
                                    color: "gray",
                                  }}
                                >
                                  Strengthen your resume and earn BuilderBucks
                                  by adding the Companies and Projects you
                                  worked on.
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleMyExperienceNew()}
                          >
                            Add My Experience
                          </Button>
                        </>
                      )}
                      {
                        // Display user's companies
                        <>
                          {unknownCompany?.length != 0 &&
                          unknownCompany != undefined ? (
                            <>
                              <p>
                                <b>No Company</b>
                              </p>
                              {unknownCompany?.map((company, index) => (
                                <div key={index}>
                                  <Project
                                    key={index}
                                    project={company}
                                    editFlag={editFlag}
                                    getUpdatedData={getUpdatedData}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      }
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )
      }
    </div>
  );
};

function Company(props) {
  const [editCompanyModalOpen, setEditCompanyModalOpen] = useState(false);
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const history = useHistory();

  const navigateToCompanyProfile = (company) => {
    return history.push(`/company/${company?.companyId ?? 0}`);
  };

  const emptyFunction = () => {
    return;
  };

  return (
    <div>
      <Modal
        open={editCompanyModalOpen}
        onClose={() => setEditCompanyModalOpen(false)}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "400px" }}>
            <div className="modal-card-body">
              <CompanyForm
                edit={true}
                company={props.company}
                allCompanies={props.allCompanies}
                onReturnClick={() => {
                  props.updateWorkHistory();
                  setEditCompanyModalOpen(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Card sx={{ marginBottom: "10px" }}>
        <ListItem
          disablePadding
          secondaryAction={
            <>
              {props.editFlag ? (
                <Tooltip title="Edit">
                  <IconButton
                    style={{ marginRight: "15px" }}
                    color="primary"
                    onClick={() => {
                      setEditCompanyModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          }
        >
          <ListItemButton
            onClick={() => {
              props?.editFlag
                ? setEditCompanyModalOpen(true)
                : props?.sitesData
                ? emptyFunction()
                : navigateToCompanyProfile(props?.company);
            }}
            //
          >
            <ListItemText
              primary={
                props.sitesData
                  ? props.company.siteName != ""
                    ? props.company.siteName
                    : "No Site"
                  : props.company.companyName
              }
              secondary={
                <>
                  {props.sitesData ? null : (
                    <>
                      {props?.company?.experience?.length != 0 ? (
                        <span>
                          {[
                            ...new Set(
                              props?.company?.experience
                                .map((item) => item.roleName)
                                .filter((roleName) => roleName) // Filter out empty or undefined roleName
                            ),
                          ].join(", ")}
                          <br />{" "}
                        </span>
                      ) : (
                        <span style={{ display: "none" }}></span>
                      )}
                      {
                        // If startDate is null, then it's "No date"
                        props.company.startDate !== null ? (
                          <span>
                            {moment(props.company.startDate).format("MMM YYYY")}{" "}
                            -{" "}
                            {props.company.currentCompany === false
                              ? props.company.endDate == null ||
                                props.company.endDate == "Invalid date"
                                ? null
                                : moment(props.company.endDate).format(
                                    "MMM YYYY"
                                  )
                              : "Present"}{" "}
                            <br />
                          </span>
                        ) : null
                      }
                      {props.company.description ? (
                        <span style={{ wordBreak: "break-all" }}>
                          {" "}
                          {props.company.description}{" "}
                        </span>
                      ) : null}
                    </>
                  )}
                </>
              }
            />
          </ListItemButton>
        </ListItem>

        <CardContent
          className={props.editFlag ? "expCardPadding2" : "expCardPadding1"}
        >
          {props.company.experience
            .sort((a, b) => {
              let dateA = new Date(a.startDate),
                dateB = new Date(b.startDate);
              return dateB - dateA;
            })
            .map((project, index) => {
              return (
                <Project
                  key={index}
                  project={project}
                  company={props.company}
                  allCompanies={props.allCompanies}
                  updateWorkHistory={props.updateWorkHistory}
                  sitesData={props.sitesData}
                  editFlag={props.editFlag}
                  getUpdatedData={props?.getUpdatedData}
                />
              );
            })}
          {props.editFlag ? (
            <>
              {props.company.companyName !== "" ? (
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => {
                    // setNewProjectModalOpen(true)
                    history.push({
                      pathname: "/new-map",
                      state: {
                        projectSelectMode: true,
                        from: "experience",
                        projectSelectCompany: {
                          ...props?.company,
                          userCompanyId: props.company.id,
                        },
                      },
                    });
                  }}
                >
                  <b>Add Project</b>
                </Button>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
      {newProjectModalOpen && (
        <AddProjectModal
          show={newProjectModalOpen}
          onClose={() => {
            setNewProjectModalOpen(false);
          }}
          from="experience"
          siteDetails={{}}
          addProjectObj={{}}
          onDataSave={() => {}}
        />
      )}
    </div>
  );
}

function Project(props) {
  const [showEditExperienceModal, setShowEditExperienceModal] = useState(false);
  const [showExperienceDiscardModal, setShowExperienceDiscardModal] =
    useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  {
    /* new code sanket 02-04-2024 */
  }
  const [pictureUrl, setPictureurl] = useState("");

  const handleProjectClick = () => {
    setDetailModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setShowExperienceDiscardModal(true);
  };

  const onExperienceModalClose = () => {
    setShowEditExperienceModal(false);
    setShowExperienceDiscardModal(false);
  };

  const onExperienceSave = () => {
    {
      /* new code sanket 21-03-2024 */
    }
    onExperienceModalClose();
    props?.getUpdatedData();
  };

  const openExperiencemodal = () => {
    setPictureurl(props?.project?.mainPictureUrl);
    setShowEditExperienceModal(true);
  };

  return (
    <div>
      {showEditExperienceModal && (
        <Modal
          open={showEditExperienceModal}
          disableEnforceFocus
          onClose={handleCloseEditModal}
        >
          <div className="modal-center">
            <div className="modal-card" style={{ width: "800px" }}>
              <div className="modal-card-body">
                {/* new code sanket 20-03-2024  */}
                <ExperienceProjectForm
                  details={props?.project ?? {}}
                  onClose={onExperienceModalClose}
                  showExperienceDiscardModal={showExperienceDiscardModal}
                  setShowExperienceDiscardModal={(val) => {
                    setShowExperienceDiscardModal(val);
                  }}
                  onDataSave={onExperienceSave}
                  isFollowing={false}
                  onExperienceDelete={() => {
                    onExperienceModalClose();
                    props?.getUpdatedData();
                  }}
                  from={"experience"}
                  pictureUrl={pictureUrl}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}

      {detailModalOpen && (
        <DetailForm
          show={detailModalOpen}
          Close={() => setDetailModalOpen(false)}
          new={"new"}
          project={props.project}
          company={props.company}
          onExperienceDelete={() => {
            setDetailModalOpen(false);
            props?.getUpdatedData();
          }}
          getUpdatedData={() => {
            props?.getUpdatedData();
          }}
          siteData={props?.sitesData}
        />
      )}

      <Card className="expCardBgc">
        <ListItem
          secondaryAction={
            <>
              {props.editFlag ? (
                <Tooltip title="Edit">
                  <IconButton
                    color="primary"
                    onClick={() => openExperiencemodal()}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          }
          disablePadding
        >
          {/* new code Mahindra 19-03-2024 */}
          <ListItemButton onClick={handleProjectClick}>
            <Avatar
              variant="square"
              style={{
                marginRight: "15px",
                cursor: "pointer",
                width: "80px",
                height: "55px",
                borderRadius: "5px",
              }}
              src={
                props.project.mainPictureUrl
                  ? props.project.mainPictureUrl
                  : defaultProjectProfileThumbnail
              }
            ></Avatar>
            <ListItemText
              primary={props.project.projectName}
              secondary={
                <>
                  {props.sitesData ? (
                    <span>
                      {" "}
                      {props.project.companyName} <br />{" "}
                    </span>
                  ) : (
                    <span>
                      {" "}
                      {props.project.siteName} <br />{" "}
                    </span>
                  )}

                  {
                    // If startDate is null or empty, then it's "No date"
                    props.project.startDate !== null &&
                    props.project.startDate !== "" ? (
                      <span>
                        {moment(props.project.startDate).format("MMM YYYY")} -{" "}
                        {
                          // If endDate is null, then it's to "Present"
                          props.project.present === false
                            ? props.project.endDate == null ||
                              props.project.endDate == "Invalid date"
                              ? null
                              : moment(props.project.endDate).format("MMM YYYY")
                            : "Present"
                        }{" "}
                        <br />
                      </span>
                    ) : null
                  }
                  {props.project.description ? (
                    <span style={{ wordBreak: "break-all" }}>
                      <Typography variant="body2" style={{ color: "GrayText" }}>
                        {props.project.description}
                      </Typography>
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              }
            />
          </ListItemButton>
        </ListItem>
      </Card>
    </div>
  );
}

export default ExperienceCard;
