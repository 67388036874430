import React, { useState, useEffect } from "react";

import { Avatar, Button } from "@material-ui/core";

import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";

import PictureViewer from "../../components/PictureViewer";
import ProfilePicturePlaceholder from "../../assets/images/profile-picture-placeholder.png";
import Loader from "../../components/Loader";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { BIBlue, BIGrey, BIRed } from "../../assets/buildidColors";

import {
  fetchUserTrades,
  getUserAddress,
  getUserContactStatusName,
  getUserDashboard,
} from "../../api/UserApi";
import {
  getUserContacts,
  inviteFriendsByEmail,
  saveContactEvent,
} from "../../api/ContactsApi";
import PromptModal from "../../components/PromptModal";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { saveChatGroupUsers } from "../../api/ChatApi";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MemberListModal from "../../components/profile/MemberListModal";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

export default function ProfileHeaderCard(props) {
  const [profilePictureViewerOpen, setProfilePictureViewerOpen] =
    useState(false);
  const [profileData, setProfileData] = useState({});
  const [userAddress, setUserAddress] = useState(null);
  const [userTradeList, setUserTradeList] = useState([]);
  const [displayedTradeCount, setDisplayedTradeCount] = useState(15);
  const [initialViewMore, setInitialViewMore] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [isContactInList, setIsContactInList] = useState(false);
  const [contactStatusId, setContactStatusId] = useState(0);
  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(null);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [isMember, setIsMember] = useState([]);
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [showBtnLoader, setShowBtnLoader] = useState(false);

  // Employment is misspelled in the API as "employEment"
  // Code is spelled correctly (as "employment"); make note of this difference for API calls

  // Profile picture
  const [displayPicture, setDisplayPicture] = useState(null);

  const history = useHistory();

  const handleReportTest = () => {
    setMoreMenuAnchorEl(false);
    setDeleteConfirmModalOpen(true);
  };

  const handleShowMoreTrades = () => {
    setInitialViewMore(false);
    setDisplayedTradeCount(userTradeList.length);
  };

  const handleShowLessTrades = () => {
    setInitialViewMore(true);
    setDisplayedTradeCount(15);
  };

  async function userContactStatusName(contactId) {
    const contactStatus = await getUserContactStatusName(
      localStorage.userId,
      contactId
    );
    // props.setContactStatus(contactStatus);
    try {
      if (contactStatus == "Yes") {
        // 1 - in conatct "Message" and "..."
        setContactStatusId(1);
      } else if (contactStatus == "No") {
        // 2 - in conatct "Add contact" and "message"
        setContactStatusId(2);
      } else if (contactStatus == "Request Sent") {
        // 3 - in conatct "Requested" and "message"
        setContactStatusId(3);
      } else if (contactStatus == "Awaiting response") {
        // 4 - in conatct "Awaiting response contact" and "message"
        setContactStatusId(4);
      } else if (contactStatus == "Deleted") {
        // 2 - in conatct "Add contact" and "message"
        setContactStatusId(2);
      } else if (contactStatus == "Rejected") {
        // 2 - in conatct "Add contact" and "message"
        setContactStatusId(2);
      } else if (contactStatus == "me") {
        setContactStatusId(5);
        setIsContactInList(true);
        // this.setState({ contactStatusId: 5, contactStatus, isContactInList: true });
      } else {
        console.log(
          "ERROR:Contact Status Not Found",
          "Status Is :",
          contactStatus
        );
      }
    } catch (error) {
      console.log("ERROR:", error);
    }
    return contactStatus;
  }

  async function getDashboard() {
    try {
      const getAddress = await getUserAddress(props.contactId);
      const res = await getUserDashboard(props.contactId);
      const contacts = await getUserContacts(localStorage.userId);
      let checkIsContactInList = false;
      if (Number(localStorage.userId) == props.contactId) {
        checkIsContactInList = true;
      } else {
        checkIsContactInList = contacts.some(
          (cont) => cont.contactId == props.contactId
        );
      }
      setUserAddress(getAddress);
      setUserEmail(res.userEmail);
      setProfileData(res);
      setDisplayPicture(res.pictureUrl);
      setIsContactInList(checkIsContactInList);
      props?.setIsContactList(checkIsContactInList);
      props?.setIsUserProfileData(res);

      const userTradeObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [{ UserId: props.contactId }],
      };
      const userTradeRes = await fetchUserTrades(userTradeObj);
      const userTradeRes2 = userTradeRes.map((trade) => {
        trade.tradeType = trade.tradeType.toLowerCase();
        return trade;
      });
      setUserTradeList(userTradeRes2);
    } catch (err) {
      console.log("Error while receiving the data=>", err);
    }
  }

  const getCommonFriendsInContacts = async () => {
    const contactId = props.contactId;
    try {
      const userMemberRes = await getUserContacts(contactId);
      let otherUsers = userMemberRes.filter(
        (user) => user.userId != Number(localStorage.userId)
      );
      const filteredData = otherUsers.filter(
        (x) => x.contactId != localStorage.userId
      );
      const newData = [];
      for (let i = 0; i < filteredData.length; i++) {
        const contactStatus = await getUserContactStatusName(
          localStorage.userId,
          filteredData[i].contactId
        );
        const obj = {
          id: filteredData[i].contactId,
          status: contactStatus,
        };
        newData.push(obj);
      }
      const data = newData.filter((data) => data != undefined);
      let a = [];
      data.map((i) => {
        filteredData.map((x) => {
          if (i.id == x.contactId) {
            a = x;
            a["contactStatus"] = i.status;
            return a;
          }
        });
      });
      setIsMember(filteredData);
    } catch (error) {
      console.log("error---", error);
    }
  };

  useEffect(async () => {
    try {
      await getCommonFriendsInContacts();
      await getDashboard();
      await userContactStatusName(props?.contactId);
      props.setShowLoader(false);
    } catch (error) {
      props.setShowLoader(false);
      console.log("Error while getting details :: ", error);
    }
    return () => {
      setMembersModalOpen(false);
    };
  }, [props?.contactId, contactStatusId]);

  useEffect(() => {
    return () => {
      resetValues();
    };
  }, []);

  const resetValues = () => {
    setProfilePictureViewerOpen(false);
    setUserAddress({});
    setProfileData({});
    setUserTradeList([]);
    setUserEmail("");
    setIsContactInList(false);
    setContactStatusId(0);
    return setCancelConfirmModalOpen(false);
  };

  async function handleDeleteContact() {
    try {
      setShowBtnLoader(true);
      const obj = {
        Id: 0,
        UserId: Number(localStorage.userId),
        ContactEmail: userEmail,
        EventStatusId: 5,
        ModifiedBy: localStorage.userId,
      };
      await saveContactEvent(obj);
      setDeleteConfirmModalOpen(false);
      userContactStatusName(props.contactId);
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("handleDeleteContact error", error);
    }
  }

  async function handleCancelRequest() {
    try {
      const obj = {
        Id: 0,
        UserId: Number(localStorage.userId),
        ContactEmail: userEmail,
        EventStatusId: 3,
        ModifiedBy: localStorage.userId,
      };
      await saveContactEvent(obj);
      setCancelConfirmModalOpen(false);
      userContactStatusName(props.contactId);

      const contacts = await getUserContacts(localStorage.userId);
      let checkIsContactInList = contacts.some(
        (cont) => cont.contactId == props.contactId
      );
      setIsContactInList(checkIsContactInList);
    } catch (error) {
      console.log("handleCancelRequest error", error);
    }
  }

  async function handleAcceptContact() {
    try {
      const obj = {
        Id: 0,
        UserId: Number(props.contactId),
        ContactEmail: userEmail,
        EventStatusId: 2,
        ModifiedBy: localStorage.userId,
      };
      await saveContactEvent(obj);
      setCancelConfirmModalOpen(false);
      userContactStatusName(props.contactId);

      const contacts = await getUserContacts(localStorage.userId);
      let checkIsContactInList = contacts.some(
        (cont) => cont.contactId == props.contactId
      );
      setIsContactInList(checkIsContactInList);
    } catch (error) {
      console.log("handleCancelRequest error", error);
    }
  }

  const handleAddContact = async (data) => {
    try {
      setShowBtnLoader(true);
      const obj = {
        UserId: Number(localStorage.userId),
        FriendsEmails: data ? data?.contactEmail : userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${localStorage.userId}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${data?.userId}`
        },
      };
      await inviteFriendsByEmail(obj);
      if (data) getCommonFriendsInContacts();
      userContactStatusName(props.contactId);
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("handleAddContact error", error);
    }
  };

  const navigateTochat = async (data) => {
    try {
      setShowBtnLoader(true);
      const saveListArray = [
        {
          Id: 0,
          ChatGroupId: 0,
          UserId: data ? data?.contactId : profileData?.userId,
          ModifiedBy: Number(localStorage.getItem("userId")),
          Muted: false,
        },
      ];
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };
      let result = await saveChatGroupUsers(chatGroupObj);
      if (result?.data?.length > 0) {
        let chatGroupId = result.data[0]?.objData.chatGroupId;
        history.push({
          pathname: "/chat",
          state: {
            from: "New Chat",
            chatGroupId: chatGroupId,
            showInList: true,
          },
        });
      }
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("Error while sending to chat screen :: ", error);
    }
  };

  function handleMemberListPressed() {
    setMembersModalOpen(true);
  }

  if (props?.showLoader) {
    return (
      <div className="profile-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <MemberListModal
        isOpen={membersModalOpen}
        onClose={async (val) => {
          if (val) {
            await resetValues();
            // await props.resetValues();
            props.setShowLoader(true);
          }
          setMembersModalOpen(false);
        }}
        members={isMember}
        profileMemberList={true}
        handleConnectClick={handleAddContact}
        handleMessageClick={navigateTochat}
        showBtnLoader={showBtnLoader}
      />
      <PictureViewer
        isOpen={profilePictureViewerOpen}
        onClose={() => setProfilePictureViewerOpen(false)}
        src={displayPicture || ProfilePicturePlaceholder}
      />

      <PromptModal
        isOpen={cancelConfirmModalOpen}
        onClose={() => setCancelConfirmModalOpen(false)}
        title="Cancel Request"
        desc="Are you sure you want to cancel this contact request?"
        redButtonText="Yes"
        onRedButtonClick={() => handleCancelRequest()}
      />

      <PromptModal
        isOpen={deleteConfirmModalOpen}
        onClose={() => setDeleteConfirmModalOpen(false)}
        title="Are you sure you want to delete this contact?"
        desc="This action cannot be undone."
        redButtonText="Delete"
        onRedButtonClick={() => handleDeleteContact()}
      />

      <div
        className="profile-header-details"
        style={{ alignItems: "flex-start" }}
      >
        <div className="circle-picture-container" style={{ margin: "10px" }}>
          <div
            className="picture-container-overlay"
            onClick={() => setProfilePictureViewerOpen(true)}
          ></div>
          <div className="profile-pic">
            <img
              src={displayPicture || ProfilePicturePlaceholder}
              alt="User profile pic"
            />
          </div>
        </div>
        <div style={{ width: "80%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 style={{ fontSize: "35px", marginBottom: "0px" }}>
              {profileData.firstName} {profileData.lastName}
            </h1>
            {contactStatusId === 1 && (
              <div>
                <IconButton
                  onClick={(event) => setMoreMenuAnchorEl(event.currentTarget)}
                >
                  <MoreIcon />
                </IconButton>
                <Menu
                  anchorEl={moreMenuAnchorEl}
                  open={Boolean(moreMenuAnchorEl)}
                  onClose={() => setMoreMenuAnchorEl(false)}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleReportTest();
                    }}
                    style={{ color: BIRed }}
                    dense
                  >
                    Delete Contact
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
          <div
            className="flex-container-horizontal"
            style={{
              justifyContent: "space-between",
              color: "gray",
            }}
          >
            {userAddress !== null ? (
              <span style={{ fontSize: "14px" }}>
                {" "}
                {userAddress?.city} {userAddress?.city === "" ? "" : ","}{" "}
                {userAddress?.province}{" "}
                {userAddress?.province === "" ? "" : ","} {userAddress?.country}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div
            className="flex-container-horizontal"
            style={{
              marginTop: "5px",
              justifyContent: "space-between",
              color: "gray",
            }}
          >
            <span style={{ fontSize: "14px" }}>
              {" "}
              Joined {moment(profileData.joinedBuildIdDate).format("ll")}
            </span>
          </div>
          <div style={{ marginTop: "10px" }}>
            <Stack
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                alignItems: "center",
              }}
              direction="row"
              spacing={0}
            >
              {userTradeList.length > 0 ? (
                <>
                  {userTradeList
                    ?.slice(0, displayedTradeCount)
                    .sort(
                      (a, b) =>
                        a.tradeType
                          ?.toLowerCase()
                          .localeCompare(b.tradeType?.toLowerCase()) ||
                        a.tradeName
                          ?.trim()
                          .toLowerCase()
                          .localeCompare(b.tradeName?.trim().toLowerCase())
                    )
                    .map((item, index) => {
                      return (
                        <Chip
                          size="small"
                          key={index.toString()}
                          label={item.tradeName?.trim()}
                          style={{
                            backgroundColor:
                              item?.tradeType?.toLowerCase() == "main"
                                ? BIBlue
                                : "null",
                            color:
                              item?.tradeType?.toLowerCase() == "main"
                                ? "white"
                                : "null",
                          }}
                        ></Chip>
                      );
                    })}
                  {userTradeList.length > 15 && (
                    <>
                      {initialViewMore ? (
                        <Button
                          style={{ borderRadius: "50px" }}
                          color="primary"
                          size="small"
                          variant="text"
                          onClick={handleShowMoreTrades}
                        >
                          View More <KeyboardArrowDown fontSize="small" />
                        </Button>
                      ) : (
                        <Button
                          style={{ borderRadius: "50px" }}
                          color="primary"
                          size="small"
                          variant="text"
                          onClick={handleShowLessTrades}
                        >
                          View Less <KeyboardArrowUp fontSize="small" />
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </Stack>
          </div>

          <div style={{ marginTop: "10px" }}>
            <Stack direction="row" spacing={1}>
              {isContactInList ? (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    navigateTochat();
                  }}
                >
                  Message
                </Button>
              ) : (
                <div>
                  {contactStatusId == 2 ? (
                    <>
                      <Stack direction="row" spacing={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            handleAddContact();
                          }}
                        >
                          Connect
                        </Button>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={() => {
                            navigateTochat();
                          }}
                        >
                          Message
                        </Button>
                      </Stack>
                    </>
                  ) : contactStatusId == 3 ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => setCancelConfirmModalOpen(true)}
                    >
                      Requested
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleAcceptContact}
                    >
                      {" "}
                      ACCEPT CONTACT
                    </Button>
                  )}{" "}
                </div>
              )}
            </Stack>
          </div>

          {isMember.length == 0 ? null : (
            <>
              <div
                className="flex-container-horizontal"
                style={{
                  marginTop: "10px",
                  justifyContent: "space-between",
                  color: "gray",
                }}
              >
                <span style={{ fontSize: "14px" }}> Builder Network </span>
              </div>
              <Stack
                direction="row"
                justifyContent="space-between"
                style={{ padding: "10px 0px" }}
              >
                {
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Stack
                      sx={{ marginRight: "10px", cursor: "pointer" }}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      onClick={handleMemberListPressed}
                    >
                      {isMember.map((member, index) => (
                        <Avatar key={index} src={member.contactPictureUrl} />
                      ))}
                    </Stack>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <KeyboardArrowRightIcon
                        sx={{ color: BIGrey, cursor: "pointer" }}
                        onClick={handleMemberListPressed}
                      />
                    </div>
                  </div>
                }
              </Stack>
            </>
          )}
        </div>
      </div>
    </>
  );
}
