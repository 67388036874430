import React, { useState } from "react";
import { Modal, Box, Typography, Stack, IconButton, TextField, Button } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; // Generic file icon
import ImageIcon from "@mui/icons-material/Image"; // Image file icon
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary"; // Video file icon
import AudioFileIcon from "@mui/icons-material/AudioFile"; // Audio file icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // PDF icon

// Function to get the correct file type icon
const getFileTypeIcon = (file) => {
   console.log("`localStorage?.clientAccentColor`",localStorage?.clientAccentColor)
  const type = file.type.split("/")[0];
  const iconColor = localStorage?.clientAccentColor
  if (type === "image") return <ImageIcon sx={{ color: iconColor }}/>;
  if (type === "video") return <VideoLibraryIcon sx={{ color: iconColor }}/>;
  if (type === "audio") return <AudioFileIcon sx={{ color: iconColor }}/>;
  if (file.name.endsWith(".pdf")) return <PictureAsPdfIcon sx={{ color: iconColor }}/>;
  return <InsertDriveFileIcon sx={{ color: iconColor }}/>;
};

const styles = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height:600,
    overflow:"scroll",
    bgcolor: "background.paper",
    padding: "25px",
    borderRadius: "10px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  fileList: {
    marginTop: "20px",
  },
  filePreview: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
    borderBottom: "1px solid #eee",
  },
  filePreviewTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  fileName: {
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  descriptionField: {
    marginTop: "10px",
    width: "100%",
  },
  closeButton: {
    padding: 0,
  },
  addFilesButton: {
    textTransform: "none",
    backgroundColor: `localStorage?.clientAccentColor`,
    color: "white",
  },
  submitButton: {
    backgroundColor: `localStorage?.clientAccentColor`,
    color: "white",
    width: "100%",
    padding: "10px",
    border: "none",
    marginTop: "20px",
  },
};

export default function ClientSiteFilingFileUploadModal({
  open,
  handleClose,
  files,
  handleFileChange,
  handleFileRemove,
}) {
  const fileInputRef = React.useRef(null);
  const [fileDescriptions, setFileDescriptions] = useState({}); // State to store descriptions

  const handleDescriptionChange = (e, fileName) => {
    setFileDescriptions({
      ...fileDescriptions,
      [fileName]: e.target.value,
    });
  };

  // Function to render the file preview based on type
  const renderFilePreviews = (file) => {
    const fileType = file.type.split("/")[0];
    const filePreviewElement = (
        <Stack direction="row" alignItems="center" sx={styles.filePreviewTop}>
            <div style={{display:"flex"}}>
          <span style={styles.fileIcon}>{getFileTypeIcon(file)}</span>
          <Typography variant="body2" style={styles.fileName}>
            {file.name}
          </Typography>
          </div>
          <div>
          <IconButton
            sx={styles.closeButton}
            onClick={() => handleFileRemove(file)}
          >
            <Close />
          </IconButton>
          </div>
        </Stack>
      );
    
      return (
        <Box sx={styles.filePreview}>
          {filePreviewElement} {/* This will be on one line with the close button */}
          {fileType === "image" && (
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={{ width: "100%", height: "auto", marginTop: "5px" }}
            />
          )}
          {fileType === "video" && (
            <video width="100%" controls style={{ marginTop: "5px" }}>
              <source src={URL.createObjectURL(file)} type={file.type} />
              Your browser does not support the video tag.
            </video>
          )}
          {file.name.endsWith(".pdf") && (
            <embed
              src={URL.createObjectURL(file)}
              type="application/pdf"
              width="100%"
              height="100"
              style={{ marginTop: "5px" }}
            />
          )}
          {/* If it's not a recognized type, we won't show a preview here */}
        </Box>
  );
};
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styles.modalContainer}>
        {/* Header */}
        <Box sx={styles.header}>
          <Typography variant="h6">Upload Files</Typography>
          <Stack direction="row" spacing={2}>
            {/* Hidden input to add more files */}
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange} // Trigger file selection
              accept="*/*"
              multiple
            />
            <Button
              variant="contained"
              sx={styles.addFilesButton}
              onClick={() => fileInputRef.current.click()}
            >
              Add Files
            </Button>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
        </Box>

        {/* File Previews */}
        <Box sx={styles.fileList}>
          {files.length === 0 ? (
            <Typography>No files selected yet.</Typography>
          ) : (
            files.map((file, index) => (
              <Box key={index} sx={styles.filePreview}>
             
                  {/* Render file previews based on type */}
                  {renderFilePreviews(file)}
                 
             

                {/* Description TextField */}
                <TextField
                  label="Description"
                  variant="outlined"
                  size="small"
                  sx={styles.descriptionField}
                  value={fileDescriptions[file.name] || ""}
                  onChange={(e) => handleDescriptionChange(e, file.name)}
                  placeholder="Say something about this (optional)"
                />
              </Box>
            ))
          )}
        </Box>

        {/* Submit Button */}
        <Button
          variant="contained"
          disabled={files.length === 0}
          sx={styles.submitButton}
          onClick={() => console.log("Submit clicked", fileDescriptions)}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
}
