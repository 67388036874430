import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import AlertModal from "../../components/AlertModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close as CloseIcon } from "@material-ui/icons";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { BILightGrey } from "../../assets/buildidColors";
import { sendMontagesFile } from "../../api/MontagesApi";
import {
  getRecentlyInteractUsers,
  saveRecentlyInteractUsers,
} from "../../api/UserApi";
import { getUserContacts } from "../../api/ContactsApi";
import { Chip } from "@mui/material";

export default function ShareMontageModal(props) {
  const [sendMontageAlert, setSendMontageAlert] = useState(false);
  const [textCopiedAlert, setTextCopiedAlert] = useState(false);
  const [toSendWith, setToSendWith] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [recentInteractUsers, setRecentInteractUsers] = useState([]);
  const defaultLinkRef = useRef(null);

  const db = localStorage.getItem("db");

  async function getContacts() {
    const res = await getUserContacts(localStorage?.userId);
    console.log("res............",res)
    setSearchData(res);
  }

  async function fetchRecentInteractUsers() {
    const res = await getRecentlyInteractUsers(localStorage?.userId);
    let tempArr = res.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    });
    setRecentInteractUsers(tempArr);
  }

  useEffect(() => {
    getContacts();
    fetchRecentInteractUsers();
  }, []);

  const handleInputChange = (e) => {
    setNewEmail(e.target.value.trim());
  };
  function handleChange(_, newValue) {
    handleEmail(newValue);
  }
  const handleEmail = (email) => {
    let tmpArr = email.map((item) => {
      if (item.contactEmail) {
        return {
          contactEmail: item.contactEmail,
          contactId: item.contactId,
        };
      } else {
        return item;
      }
    });
    setNewEmail("");
    setToSendWith(tmpArr);
  };

  const handleRecentInteractUserClick = (user) => {
    if (user && user.interactUserEmail) {
      if (user.isSelected === true) {
        return;
      }
      setToSendWith((prevToSendWith) => [
        ...prevToSendWith,
        {
          contactEmail: user.interactUserEmail,
          contactId: user.interactUserId,
        },
      ]);
    }
  };

  async function handleShareMontage() {
    const shareData = toSendWith.map((user) => ({
      EmailId: user.contactEmail || user,
      FullName: "",
      MontageUrl: `https://bimiscwebapi-montage-viewer.azurewebsites.net/?db=${db}/&email=${
        user.contactEmail || user
      }/&montageId=${props.montId}`,
    }));

    for (const item of shareData) {
      const selectedUsers = shareData
        .map((user) => user.EmailId)
        .filter((email) => email !== null);
      if (selectedUsers.length === 0) {
        return;
      }

      const res = await sendMontagesFile(item);

      if (res.message === "OK") {
        const saveRecentChatUsers = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              UserId: Number(localStorage.userId),
              InteractUserId: item.contactId,
              ModifiedBy: Number(localStorage.userId),
              fromScreen: "montages",
            },
          ],
        };
        await saveRecentlyInteractUsers(saveRecentChatUsers);
        fetchRecentInteractUsers(localStorage?.userId);
        setSendMontageAlert(true);
        setToSendWith([]);
        props.onClose();
      }
    }
  }

  return (
    <>
      <AlertModal
        isOpen={sendMontageAlert}
        onClose={() => {
          setSendMontageAlert(false); // Close the alert modal
          setToSendWith([]);
        }}
        title={"Montage Shared Successfully..."}
      />

<AlertModal
        isOpen={textCopiedAlert}
        onClose={() => {
          setTextCopiedAlert(false); // Close the alert modal
        }}
        title={"Link Copied..."}
      />

      <Modal
        open={props.isOpen}
        onClose={() => {
          setSendMontageAlert(false);
          props.onClose();
        }}
      >
        <div className="modal-center">
          <div className="modal-card" style={{ width: "500px" }}>
            <div className="modal-card-header-buttons">
              <IconButton
                color="primary"
                onClick={() => {
                  setToSendWith([]); // Reset the new email state
                  props.onClose(); // Trigger the onClose function passed via props
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2>Share Montage</h2>
              <p>Add users, or groups to give them access to view montage</p>
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={`https://bimiscwebapi-montage-viewer.azurewebsites.net/?db=${db}/&email=unknown/&montageId=${props.montId}`}
                inputRef={defaultLinkRef}
                style={{ marginLeft: "15px", background: BILightGrey }}
              />
              <Button
                style={{ marginLeft: "10px", marginRight: "15px" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(defaultLinkRef.current.value);
                  setTextCopiedAlert(true);
                }}
              >
                <ContentCopyIcon />
              </Button>
            </div>

            <div className="modal-card-body">
              <div className="flex-container-vertical">
                <Grid container spacing={2} className="flex-container-vertical">
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      <b>Recent</b>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {recentInteractUsers.map((user, index) => (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={user.userId}>
                        <div
                          onClick={() => {
                            handleRecentInteractUserClick(user);
                            let tempArr = [...recentInteractUsers];
                            tempArr[index].isSelected = true;
                            setRecentInteractUsers(tempArr);
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <Avatar
                                style={{ width: "40px", height: "40px" }}
                                alt={user.interactUserName}
                                src={user.interactUserImageUrl}
                              />
                            </div>
                            <div style={{ marginLeft: "10px" }}>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <b>
                                  {user.interactUserName.length > 7
                                    ? user.interactUserName.substring(0, 7) +
                                      "..."
                                    : user.interactUserName}
                                </b>
                                <br />
                                {user.interactUserTradeName.length > 8
                                  ? user.interactUserTradeName.substring(0, 8) +
                                    "..."
                                  : user.interactUserTradeName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <div className="input-container" style={{ flex: 1 }}>
                  <div
                    style={{ alignItems: "center" }}
                  >
                    <Autocomplete
                      id="sendWith-autocomplete"
                      inputValue={newEmail}
                      multiple
                      freeSolo
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={key}
                              label={option.contactEmail || option}
                              {...tagProps}
                              onDelete={(e) => {
                                let tempArr = [];
                                recentInteractUsers.forEach((item) => {
                                  console.log(option);
                                  if (item.interactUserId === option.contactId) {
                                    console.log("ui", item);
                                    tempArr.push({
                                      ...item,
                                      isSelected: false,
                                    });
                                  } else {
                                    tempArr.push(item);
                                  }
                                });
                                setRecentInteractUsers(tempArr);
 
                                let temp = [];
                                toSendWith.forEach((item) => {
                                  if (item.contactId !== option.contactId) {
                                    temp.push(item);
                                  }
                                });
                                setToSendWith(temp);
                              }}
                            />
                          );
                        })
                      }
                      filterSelectedOptions
                      options={newEmail.length >= 2 ? searchData : []}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.contactEmail) {
                          return option.contactEmail;
                        }
                        if (option.contactName) {
                          return option.contactName;
                        }
                      }}
                   
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <div style={{ display: "flex" }}>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                                loading="lazy"
                                width="50px"
                                height="50px"
                                src={props.contactPictureUrl}
                                alt=""
                              />
                              <div>
                              {props.contactName} <br />
                              {props.contactEmail}
                              </div>
                            </div>
                          </Box>
                        );
                      }}
                      value={toSendWith}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          onChange={handleInputChange}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              setToSendWith(toSendWith.concat(e.target.value));
                              setNewEmail("");
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 32 || e.key === 32) {
                              if (e.target.value !== "") {
                                setToSendWith(
                                  toSendWith.concat(e.target.value)
                                );
                                setNewEmail("");
                              }
                            }
                          }}
                          fullWidth
                          label="Add Emails"
                          value={newEmail}
                        />
                      )}
                      style={{ flex: 1 }}
                    />
                    {
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "10px", width:"100%" }}
                        disabled={toSendWith.length !== 0 ? false : true}
                        onClick={handleShareMontage}
                      >
                        Share Montage
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
