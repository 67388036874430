import React, { useState, useEffect } from "react";
import { Button, IconButton, Chip, Modal, Stack, Typography } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { DeleteClientTimesheetJobImages, DeleteClientTimesheetJobs, FetchClientTimesheetJobs, FetchClientTimesheets2, SaveClientTimesheetJobImages, SaveClientTimesheets } from '../../../api/ClientApi';
import Loader from '../../../components/Loader';
import { BIGrey, BILightGrey } from '../../../assets/buildidColors';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AddClientJobModal from "./AddClientJobModal";

const AddClientTimesheetIndividualModal = ({ show, onClose, onTimesheetAdded, individualTimesheetData, individualTimesheetId, headerData }) => {


    let userId = localStorage.getItem("userId");
    let clientId = localStorage.getItem("clientId");
    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? '';


    const [showAddClientIndividualTimesheetModal, setShowAddClientIndividualTimesheetModal] = useState(show);

    const [data, setData] = useState([]);

    const [date, setDate] = useState(new Date());
    const [timesheetId, setTimesheetId] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [addedJobList, setAddedJobList] = useState([]);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [showAddJobModal, setShowAddJobModal] = useState(false);
    const [allExistingPics, setAllExistingPics] = useState([]);

    const [deleteImageIds, setDeleteImageIds] = useState([]);

    useEffect(() => {
        if (individualTimesheetData) {
            let timesheetDate = headerData?.date ?? null;
            setDataForEditTimesheet(individualTimesheetData, timesheetDate);
        }
    }, [individualTimesheetData])

    useEffect(() => {
        if (individualTimesheetId) {
            getTimeSheetData(individualTimesheetId);
        }
    }, [individualTimesheetId])


    const getTimeSheetData = async (individualTimesheetId) => {
        try {
            setIsLoading(true);
            const clientTimesheetObject = {
                PageNr: 1,
                FullSearch: '',
                UserId: userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{
                    Id: individualTimesheetId
                }]
            }
            await FetchClientTimesheets2(clientTimesheetObject).then(async (res1) => {
                let resData = res1?.data?.[0] ?? null;
                const timesheetJobObject = {
                    PageNr: 1,
                    FullSearch: '',
                    UserId: userId,
                    IncludeRecordNr: true,
                    FetchAllowedRecordsOnly: false,
                    SearchList: [{
                        ClientTimesheetId: res1?.data[0]?.id
                    }]
                };
                const res = await FetchClientTimesheetJobs(timesheetJobObject);
                let resData1 = res?.data ?? null;
                setDataForEditTimesheet(resData1, resData?.date ?? null);
            });
        } catch (error) {
            setIsLoading(false);
            console.log("Error while getting timesheet data :: ", error);
        }
    }

    const setDataForEditTimesheet = (individualTimesheetData, timesheetDate) => {
        setDate(timesheetDate ?? new Date());
        let timesheetData = individualTimesheetData ?? null;
        setData(timesheetData);
        let existing_pics = []
        setTimesheetId(timesheetData?.[0]?.clientTimesheetId);
        let allLists = [];
        timesheetData?.map((item) => {
            const jobId = item.clientJobId;
            const jobObj = {
                Id: item.id,
                ClientJobId: item.clientJobId,
                JobName: `${item.clientJobCode} - ${item.clientJobName}`,
                Notes: item.notes,
                UDFields: item.udFields,
                UDFieldsList: item.udFieldsList,
                PictureList: item.pictureList,
                Deleted: false,

            }
            const timesheetJob = { ...jobObj };

            const empObj = item.timesheetEmployeesList.find((ele, index) => ele.clientJobId === jobId);
            const timesheetEmpObj = { ...empObj };

            const timesheetEmp = {
                Id: timesheetEmpObj?.id,
                ClientJobId: timesheetEmpObj?.clientJobId,
                ClientEmployeeId: timesheetEmpObj?.clientEmployeeId,
                Mileage: timesheetEmpObj?.mileage?.toFixed(1),
                MileageUnit: timesheetEmpObj?.mileageUnit,
                UDFields: timesheetEmpObj?.udFields,
                Deleted: false,
            }

            const hourObj = item.timesheetHoursList.find((ele, index) => ele.clientJobId === jobId);
            const timesheetHoursObj = { ...hourObj }

            const timesheetHours = {
                Id: timesheetHoursObj?.id,
                ClientJobId: timesheetHoursObj?.clientJobId,
                ClientEmployeeId: timesheetHoursObj?.clientEmployeeId,
                ClientJobActivityId: timesheetHoursObj?.clientJobActivityId,
                Hours: timesheetHoursObj?.hours?.toFixed(2),
                Deleted: false,
            }

            let timesheetActivity;
            if (item.timesheetActivitiesList.length > 0) {
                const actObj = item.timesheetActivitiesList.find((ele, index) => ele.clientJobId === jobId);
                const timesheetActivityObj = { ...actObj };
                timesheetActivity = {
                    Id: timesheetActivityObj?.id,
                    ClientJobId: timesheetActivityObj?.clientJobId,
                    ClientActivityId: timesheetActivityObj?.clientActivityId,
                    JobActivityName: `${timesheetActivityObj?.clientJobActivityCode} - ${timesheetActivityObj?.clientJobActivityName}`,
                    Qty: timesheetActivityObj?.qty,
                    QtyUnit: timesheetActivityObj?.qtyUnit,
                    Notes: timesheetActivityObj?.notes,
                    UDFields: timesheetActivityObj?.udFields,
                    Deleted: false,
                }
            } else {
                timesheetActivity = {
                    Id: 0,
                    ClientJobId: 0,
                    ClientActivityId: 0,
                    JobActivityName: "",
                    Qty: 0,
                    QtyUnit: "km",
                    Notes: "",
                    UDFields: "",
                    Deleted: false,
                }
            }

            let timesheetObj = {
                id: Math.random().toString(),
                timesheetJob,
                timesheetEmp,
                timesheetHours,
                timesheetActivity
            };
            allLists.push(timesheetObj);
            existing_pics.push(...item.pictureList);
        })
        setAddedJobList(allLists);
        setAllExistingPics(existing_pics);
        setIsLoading(false);
    }

    const onJobItemClick = (item) => {
        setSelectedJobDetails(item);
        setShowAddJobModal(true);
        setShowAddClientIndividualTimesheetModal(false);
    }

    const onJobRemoveClick = (id) => {
        const filteredAddedList = addedJobList.filter((ele) => ele.id !== id);
        setAddedJobList(filteredAddedList);
    }

    const checkObject = (obj) => {
        try {
            if (obj) {
                if (typeof obj === "object") {
                    if (obj?.fileUrl) {
                        return obj?.fileUrl;
                    }
                    return URL.createObjectURL(obj);
                } else {
                    return obj?.fileUrl;
                }
            } else {
                return "";
            }
        } catch (error) {
            return "";
        }
    };

    const deleteImagesHandler = async () => {
        try {
            let allIds = deleteImageIds?.map((id) => {
                return {
                    Id: id,
                    ModifiedBy: userId,
                }
            })
            const obj = {
                UserId: 1,
                ReturnRecordId: false,
                ReturnRecordError: true,
                SoftDelete: true,
                DeleteList: allIds
            }
            await DeleteClientTimesheetJobImages(obj);
        }
        catch (err) {
            setIsLoading(false);
            console.log("ERROR ON DELETE IMAGE", err)
        }
    }

    const saveTimesheetImageHandler = async (resData, timesheetId, updatedtTimesheetJobListForImages) => {
        try {
            let updatedArr = updatedtTimesheetJobListForImages.map((addedJob, index) => {
                let updatedId = addedJob?.Id === 0 ? resData[index]?.id : addedJob?.Id;
                let imageToSave = [...addedJob.PictureList]?.filter((item) => !item.id);

                return {
                    Id: updatedId,
                    clientJobId: addedJob.ClientJobId,
                    PictureList: imageToSave
                }
            })
            //! this updated array have to loop for saving pictures 
            for (let i = 0; i < updatedArr.length; i++) {
                if (updatedArr[i].PictureList.length > 0) {
                    for (let index = 0; index < updatedArr[i].PictureList.length; index++) {
                        const picture = updatedArr[i].PictureList[index];
                        try {
                            const formData = new FormData();
                            formData.append("Id", Number.isInteger(picture?.Id || picture?.Id) ? picture?.Id || picture?.Id : 0);
                            formData.append("ClientTimesheetJobId", updatedArr?.[i].Id);
                            formData.append("ClientJobId", updatedArr?.[i].clientJobId);
                            formData.append("ClientActivityId", 0);
                            formData.append("ModifiedBy", userId);
                            formData.append("ClientTimesheetId", timesheetId);
                            formData.append("FileName", picture);
                            const response = await SaveClientTimesheetJobImages(formData);
                        } catch (error) {
                            console.error(`Error uploading image ${index}:`, error);
                        }
                    }
                }
            }
        } catch (err) {
            setIsLoading(false)
            console.log(" ERROR IN SAVING IMAGES", err)
        }
    }

    const fetchTimesheet = async (timesheetId, updatedtTimesheetJobListForImages) => {
        try {
            const timesheetJobObject = {
                PageNr: 1,
                // NrOfRecPerPage:20,
                FullSearch: '',
                UserId: userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{
                    ClientTimesheetId: timesheetId
                }]
            };
            const res = await FetchClientTimesheetJobs(timesheetJobObject);
            await saveTimesheetImageHandler(res?.data, timesheetId, updatedtTimesheetJobListForImages)
        } catch (err) {
            setIsLoading(false)
            console.log("ERROR WHILE FETCHING TIMESHEET IN INDIVIDUAL TIMESHEET SAVE PAGE", err)
        }
    }

    const onSubmitClick = async () => {
        try {
            setIsLoading(true);
            const TimeSheetJobList = [];
            const EmployeeList = [];
            const TimesheetHoursList = [];
            const ActivityList = [];

            addedJobList.map((ele, index) => {
                TimeSheetJobList.push({ ...ele.timesheetJob });
                EmployeeList.push({ ...ele.timesheetEmp });
                TimesheetHoursList.push({ ...ele.timesheetHours });
                ActivityList.push({ ...ele.timesheetActivity });
            })

            const updatedtTimesheetJobList = TimeSheetJobList.map((ele, index) => {
                const transformedData = ele.UDFieldsList
                    .filter(field => field.value)
                    .map(field => `"${field.id}":1`)
                    .join(', ');

                const udResult = `{${transformedData}}`;

                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    Notes: ele.Notes,
                    UDFields: udResult,
                    Deleted: ele.Deleted
                }
            })
            const updatedtTimesheetJobListForImages = TimeSheetJobList.map((ele, index) => {
                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    PictureList: ele.PictureList
                }
            })

            const updatedEmpList = EmployeeList.map((ele, index) => {
                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    ClientEmployeeId: ele.ClientEmployeeId,
                    Mileage: ele.Mileage ? parseFloat(ele.Mileage) : 0,
                    MileageUnit: ele.MileageUnit,
                    UDFields: ele.UDFields,
                    Deleted: ele.Deleted,
                }
            })

            const updatedActList = ActivityList.map((ele, index) => {
                return {
                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    ClientActivityId: ele.ClientActivityId,
                    Qty: ele.Qty,
                    QtyUnit: ele.QtyUnit,
                    Notes: ele.Notes,
                    UDFields: ele.UDFields,
                    Deleted: ele.Deleted,
                }
            })

            const updatedHrList = TimesheetHoursList.map((ele, index) => {
                return {

                    Id: ele.Id,
                    ClientJobId: ele.ClientJobId,
                    ClientEmployeeId: ele.ClientEmployeeId,
                    ClientJobActivityId: ele.ClientJobActivityId,
                    Hours: parseFloat(ele.Hours),
                    Deleted: ele.Deleted,
                }
            })
            const timesheetObj = {

                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: timesheetId,
                        Date: date,
                        DateSubmitted: new Date(),
                        StatusId: 1,
                        TypeId: 1,
                        ClientId: clientId,
                        Deleted: false,
                        TimeSheetJobList: updatedtTimesheetJobList,
                        EmployeeList: updatedEmpList,
                        TimesheetHoursList: updatedHrList,
                        ActivityList: updatedActList
                    }
                ]

            }

            const res = await SaveClientTimesheets(timesheetObj);

            const result = data?.filter(
                (dataItem) => !addedJobList.some((addedJob) => addedJob.timesheetJob.Id === dataItem.id)
            );
            const timesheetToBeDeleted = result.map((ele) => ({ Id: ele.id }));
            const deleteObj = {
                UserId: Number(userId),
                ReturnRecordId: false,
                ReturnRecordError: true,
                SoftDelete: true,
                DeleteList: timesheetToBeDeleted,
            }
            if (timesheetToBeDeleted.length > 0) {
                const deleteRes = await DeleteClientTimesheetJobs(deleteObj);
            }

            let resClientTimesheetId = res?.data?.[0]?.objData?.ClientTimesheetId ?? null

            if (resClientTimesheetId) {
                await fetchTimesheet(resClientTimesheetId, updatedtTimesheetJobListForImages);
            }

            //! FETCH TIMESHEET 

            if (deleteImageIds?.length > 0) {
                await deleteImagesHandler();
            }

            // if (location?.state?.from === "viewindividualtimesheet" && timesheetId !== 0) {
            //     history.push(`/client-individual-timesheets/${timesheetId}`)
            // } else {
            //     history.push(`/client-timesheets`)
            // }

            onTimesheetAdded();


            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Error while saving timesheet data :: ", error);
        }
    }

    return (
        <>
            <Modal
                open={showAddClientIndividualTimesheetModal}
                onClose={onClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        {
                            !isLoading ?
                                (
                                    <>
                                        <Stack justifyContent={'space-between'} direction={'column'}>
                                            <div style={{ height: '10%' }}>
                                                <div className="modal-card-header-buttons">
                                                    <div></div>
                                                    <IconButton
                                                        onClick={onClose}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </div>
                                            </div>

                                            <div style={{ height: '80%', overflow: 'scroll' }}>

                                                <Stack>
                                                    <div style={{ padding: '20px 0px', height: '60vh', width: '100%' }}>
                                                        <Stack direction={'column'} style={{ width: '90%', margin: '10px auto', height: '100%' }} justifyContent={'space-between'}>
                                                            <div>
                                                                <KeyboardDatePicker
                                                                    autoOk
                                                                    variant="inline"
                                                                    inputVariant="filled"
                                                                    label="Timesheet Date"
                                                                    format="YYYY-MM-DD"
                                                                    value={date}
                                                                    InputAdornmentProps={{ position: "start" }}
                                                                    onChange={(date) => {
                                                                        if (date !== null) {
                                                                            setDate(moment(date).format("YYYY-MM-DD"));
                                                                        }
                                                                    }}
                                                                    style={{ width: '100%', height: '50px' }}
                                                                />

                                                                <Stack
                                                                    direction={'row'}
                                                                    justifyContent={'space-between'}
                                                                    alignItems={'center'}
                                                                    style={{ marginTop: '20px' }}
                                                                >
                                                                    <Typography variant='h6' style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                                        Jobs
                                                                    </Typography>
                                                                    {
                                                                        addedJobList?.length !== 0 &&
                                                                        (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    setSelectedJobDetails(null);
                                                                                    setShowAddJobModal(true);
                                                                                    setShowAddClientIndividualTimesheetModal(false);
                                                                                }}
                                                                                style={{ color: clientAccentColor ?? "" }}
                                                                            >
                                                                                Add Job
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </Stack>

                                                                {
                                                                    addedJobList?.length === 0 &&
                                                                    (
                                                                        <div style={{ marginTop: '20px' }}>
                                                                            <Button
                                                                                style={{ backgroundColor: clientAccentColor ?? "", width: '100%', color: 'white' }}
                                                                                onClick={() => {
                                                                                    setSelectedJobDetails(null);
                                                                                    setShowAddJobModal(true);
                                                                                    setShowAddClientIndividualTimesheetModal(false);
                                                                                }}
                                                                                variant='contained'
                                                                            >
                                                                                Add Job
                                                                            </Button>
                                                                        </div>
                                                                    )
                                                                }

                                                                <Stack>
                                                                    {
                                                                        addedJobList?.length > 0 &&
                                                                        (
                                                                            <>
                                                                                {
                                                                                    addedJobList?.map((item, index) => (
                                                                                        <div key={index} onClick={() => { onJobItemClick(item) }} style={{ margin: '10px 0px', border: '1px solid #e4e4e4', borderRadius: '12px', padding: '10px 10px 10px' }}>
                                                                                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                                                                <Typography>{item?.timesheetJob?.JobName}</Typography>
                                                                                                <IconButton onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    onJobRemoveClick(item?.id)
                                                                                                }} >
                                                                                                    <CloseIcon style={{ fontSize: '16px' }} />
                                                                                                </IconButton>
                                                                                            </Stack>

                                                                                            {
                                                                                                item?.timesheetActivity?.JobActivityName?.length <= 3 || item?.timesheetActivity?.JobActivityName == "" ? null :
                                                                                                    <div>
                                                                                                        <Typography style={{ fontWeight: "500", color: "grey", fontSize: 14 }}>{item?.timesheetActivity?.JobActivityName}</Typography>
                                                                                                    </div>
                                                                                            }

                                                                                            <Stack direction={'row'} gap={2} style={{ marginTop: '10px', width: '50%', justifyContent: 'space-between' }}>
                                                                                                <div>
                                                                                                    <Typography style={{ color: BIGrey, fontSize: '14px' }}>
                                                                                                        Hours
                                                                                                    </Typography>
                                                                                                    <Typography>
                                                                                                        {item?.timesheetHours?.Hours}
                                                                                                    </Typography>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <Typography style={{ color: BIGrey, fontSize: '14px' }}>
                                                                                                        Mileage
                                                                                                    </Typography>
                                                                                                    <Typography>
                                                                                                        {item?.timesheetEmp?.Mileage !== "" ? Number(item?.timesheetEmp?.Mileage).toFixed(1) : (0.0).toFixed(1)} {item?.timesheetEmp?.MileageUnit}
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </Stack>

                                                                                            {
                                                                                                item?.timesheetJob?.UDFieldsList?.length > 0 && (
                                                                                                    <div style={{ marginTop: '10px' }}>
                                                                                                        <Stack key={index} direction={'row'} gap={2}>

                                                                                                            {item?.timesheetJob?.UDFieldsList?.map((udValues, index) => (
                                                                                                                (udValues?.value == 'true' || udValues?.value == true) ?
                                                                                                                    <div key={index}>
                                                                                                                        <Chip label={udValues?.label} />
                                                                                                                    </div> : null
                                                                                                            ))
                                                                                                            }
                                                                                                        </Stack>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            {
                                                                                                item?.timesheetJob?.Notes?.trim() !== "" &&
                                                                                                (
                                                                                                    <div style={{ marginTop: '5px' }}>
                                                                                                        <Typography> {item?.timesheetJob?.Notes}</Typography>
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                            {
                                                                                                item?.timesheetJob?.PictureList?.length > 0 &&
                                                                                                (
                                                                                                    <div style={{ marginTop: '20px' }}>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display: "flex",
                                                                                                                gap: "10px",
                                                                                                                overflowX: "scroll",
                                                                                                                width: '80%',
                                                                                                                height: '125px'
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                item?.timesheetJob?.PictureList.map((img, index) => (
                                                                                                                    <div style={{ width: '110px', height: '110px' }}>
                                                                                                                        <img src={checkObject(img)} style={{ height: '100%', width: '100%', borderRadius: '20px' }} />
                                                                                                                    </div>
                                                                                                                ))
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            }


                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </Stack>
                                                            </div>



                                                        </Stack>
                                                    </div>
                                                </Stack>
                                            </div>

                                            <div style={{ height: '10%', width: '90%', margin: 'auto' }}>
                                                <div>
                                                    <Button
                                                        style={{ backgroundColor: addedJobList?.length === 0 ? BILightGrey : clientAccentColor ?? "", width: '100%', color: 'white' }}
                                                        disabled={addedJobList?.length === 0}
                                                        onClick={onSubmitClick}
                                                        variant='contained'
                                                    >
                                                        Submit
                                                    </Button>
                                                </div>
                                            </div>
                                        </Stack>
                                    </>
                                )
                                :
                                (
                                    <Stack>
                                        <Loader />
                                    </Stack>
                                )
                        }
                    </div>
                </div>
            </Modal>


            {
                showAddJobModal &&
                (
                    <AddClientJobModal
                        show={showAddJobModal}
                        handleClose={() => {
                            setShowAddJobModal(false);
                            setShowAddClientIndividualTimesheetModal(true);
                        }}
                        onAddJob={(timesheetObj) => {
                            if (addedJobList?.length === 0) {
                                setAddedJobList([timesheetObj])
                            } else {
                                setAddedJobList((preValue) => ([...preValue, timesheetObj]))
                            }
                            setShowAddClientIndividualTimesheetModal(true);
                            return setShowAddJobModal(false);
                        }}
                        onJobEdit={(timesheetObj) => {
                            let cloneJobList = [...addedJobList];
                            let updatedJobList = cloneJobList?.map((item) => (item?.id === timesheetObj?.id ? timesheetObj : item));
                            setAddedJobList(updatedJobList);
                            setShowAddClientIndividualTimesheetModal(true);
                            return setShowAddJobModal(false);
                        }}
                        selectedJobDetails={selectedJobDetails}
                        deleteImageFromBackend={(newId) => {
                            setDeleteImageIds((preValue) => [...preValue, ...newId])
                        }}
                    />
                )
            }
        </>

    )
}

export default AddClientTimesheetIndividualModal