import { Button, Modal, Select, Typography } from "@material-ui/core";
import { CenterFocusStrong, UTurnLeftTwoTone } from "@mui/icons-material";
import React, { useState } from "react";
import { getSurveyDateNever } from "../../api/DashboardApi";
import { MenuItem } from "@mui/material";

export default function SurveyModal({ open, onClose, setQuizModel }) {
  const [mayBeLater, setMayBeLater] = useState(false);
  const [mayBeLaterKey, setMayBeLaterKey] = useState(1);
  const handleSureModel = () => {
    setQuizModel(true);
    onClose();
  };

  const handleNever = async () => {
    const res = await getSurveyDateNever(localStorage.userId, 5);
    console.log(res);
    onClose();
  };

  const MayBeLater = () => {
    setMayBeLater(true);
  };

  const handleSelectChange = (e) => {
    setMayBeLaterKey(e.target.value);
  };
  const handelsave = async () => {
    try {
      if (mayBeLaterKey == 1) {
        localStorage.setItem("surveyLaterToday", "true");
      }
      const res = await getSurveyDateNever(localStorage.userId, mayBeLaterKey);

      onClose();
      // this.setState({
      //   surveyDate: response[0].surveyDate,
      //   isMaybeLaterModal: false,
      // });
    } catch (error) {
      console.log("SURVEY_OPTION" + error);
    }
  };

  const nextTimeArray = [
    {
      key: 1,
      value: "Later Today",
    },
    {
      key: 2,
      value: "Tomorrow",
    },
    {
      key: 3,
      value: "Next Week",
    },
    {
      key: 4,
      value: "Next Month",
    },
    {
      key: 5,
      value: "Never",
    },
  ];
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-center">
        <div
          style={{
            padding: "20px",
          }}
          className="modal-card"
        >
          {!mayBeLater ? (
            <>
              <Typography>
                Would you be intrested in taking our one time survey
              </Typography>
              <Typography>we would love to hear your feedback</Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  gap: "20px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    width: "150px",
                    height: "40px",
                  }}
                  onClick={() => {
                    handleSureModel();
                  }}
                >
                  SURE
                </Button>
                <Button
                  onClick={handleNever}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    width: "150px",
                    height: "40px",
                  }}
                >
                  NEVER
                </Button>
                <Button
                  onClick={MayBeLater}
                  style={{
                    backgroundColor: "blue",
                    color: "white",
                    width: "150px",
                    height: "40px",
                  }}
                >
                  MAY be later
                </Button>
              </div>
            </>
          ) : (
            <>
              <Typography>
                Would you be intrested in taking our one time survey
              </Typography>
              <Typography>we would love to hear your feedback</Typography>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="outlined"
                  style={{
                    width: "200px",
                  }}
                  value={mayBeLaterKey}
                  label="Age"
                  onChange={handleSelectChange}
                >
                  {nextTimeArray.map((item) => (
                    <MenuItem value={item?.key}>{item?.value}</MenuItem>
                  ))}
                </Select>
                <Button
                  onClick={() => {
                    handelsave();
                  }}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                  Save
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
