import React, { useState } from "react";
import { Modal, Typography, Button, IconButton, Box, Stack } from "@mui/material";
import { Close as CloseIcon, Edit as EditIcon } from "@material-ui/icons";
import { BIGrey, BILightGrey } from "../../../assets/buildidColors";
import Loader from "../../../components/Loader";
import PromptModal from "../../../components/PromptModal";
import { DeleteClientTimesheetTemplates } from "../../../api/ClientApi";

const TemplateDetailsModel = ({ open, onClose, template, onTimeSheetDeleted, onEditClick }) => {

  let userId = localStorage.getItem("userId");
  let clientAccentColor = localStorage.getItem("clientAccentColor") ?? 'black';
  let clientEmployeeId = localStorage.getItem("clientEmployeeId");

  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (!template) return null;


  const onDeleteClick = () => {
    setShowDeleteModal(true);
  }

  const onCreateTimeSheetClick = () => {

  }

  const deleteTimesheet = async () => {
    try {
      setIsLoading(true);
      const delete_object = {
        "UserId": Number(userId),
        "ReturnRecordId": false,
        "ReturnRecordError": true,
        "SoftDelete": true,
        "DeleteList": [
          {
            "Id": template.id
          }
        ]
      };
      await DeleteClientTimesheetTemplates(delete_object);
      onTimeSheetDeleted();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong. Please try again later.");
      console.log("Error while deleting template ::", error)
    }
  }

  return (
    <>

      <Modal open={open} onClose={onClose}>
        <div className="modal-center">
          <div className="modal-card crew-modal" style={{ width: "400px" }}>
            {
              !isLoading ?
                (
                  <>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography variant="h6" style={{ fontSize: '20px', fontWeight: 'bold' }}>{template?.name}</Typography>

                      <Stack direction={'row'} justifyContent={'start'} alignItems={'center'}>
                        <IconButton style={{ color: clientAccentColor }} onClick={onEditClick}>
                          <EditIcon />
                        </IconButton>
                        <IconButton style={{ color: BIGrey }} onClick={onClose}>
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Box style={{ marginTop: "20px" }}>
                      <Typography variant="subtitle1" style={{ color: BIGrey }}>Job</Typography>
                      <Typography variant="subtitle1">{template?.clientJobCode} - {template?.clientJobName}</Typography>
                    </Box>
                    <Box style={{ marginTop: "20px" }}>
                      <Typography variant="subtitle1" style={{ color: BIGrey }}>Activities ({template?.noOfActivities})</Typography>
                      {
                        template?.templateActivitiesList?.map((act, index) => (
                          <Typography variant="subtitle1" key={index}>{act?.activityCode} - {act?.activityName}</Typography>
                        ))
                      }
                    </Box>
                    <Box style={{ marginTop: "20px" }}>
                      <Typography variant="subtitle1" style={{ color: BIGrey }}>Employees ({template.noOfEmployees})</Typography>
                      {
                        template?.templateEmployeesList?.map((emp, index) => (
                          <Typography variant="subtitle1" key={index}>{emp?.clientEmployeeName}</Typography>
                        ))
                      }
                    </Box>
                    <Stack style={{ marginTop: "20px" }} gap={1}>
                      <Button style={{ backgroundColor: clientAccentColor, color: 'white' }}
                        onClick={onCreateTimeSheetClick}
                      >
                        Create Timesheet
                      </Button>
                      <Button style={{ backgroundColor: BILightGrey, color: 'black' }}
                        onClick={onDeleteClick}
                      >
                        Delete Template
                      </Button>
                    </Stack>
                  </>
                )
                :
                (
                  <>
                    <Loader />
                  </>
                )
            }

          </div>
        </div>
      </Modal>
      {
        showDeleteModal &&
        <PromptModal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          title="Are you sure you want to delete this Timesheet? "
          desc="This action cannot be undone."
          clientActionButtonText="Delete"
          clientAccentColor={clientAccentColor ?? ""}
          clientActionButtonClick={() => {
            deleteTimesheet();
            setShowDeleteModal(false);
          }}
        />
      }
    </>

  );
};

export default TemplateDetailsModel;