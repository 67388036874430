import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  fetchSiteCompanyRoles,
  getProjectCompanyRoles,
} from "../../api/SitesApi";
import { useEffect } from "react";
import { fetchCompanies } from "../../api/CompanyApi";
import { useState } from "react";
import { BIBlue } from "../../assets/buildidColors";
import { TextField, Button } from "@material-ui/core";

function AddIteamModalCompanies(props) {
  const [companyName, setCompanyName] = useState("");
  const [companyInputValue, setCompanyInputValue] = useState("");
  const [getCompanyId, setGetCompanyId] = useState(0);
  const [flag, setFlag] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [roleId, setRoleId] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [otherRoleName, setOtherRoleName] = useState("");
  const [otherValFlag, setOtherValFlag] = useState(false);

  // Reset fields on close
  function onCloseClick() {
    setOtherValFlag(false);
    setCompanyName("");
    setCompanyInputValue("");
    setGetCompanyId(0);
    setRoleId(0);
    setInputValue("");
    setOtherRoleName("");
    props.onClose();
  }

  async function onSaveClick() {
    if (companyInputValue?.length > 1) {
      if (companyName !== "" && companyName !== null) {
        let newItem = {
          id: getCompanyId,
          companyName: companyName,
          roleId: roleId,
          otherRoleName: otherRoleName,
        };
        await props.saveItem(newItem);
        onCloseClick();
      } else {
        alert("Enter company name");
      }
    }
  }

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getCompanies = async () => {
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = removeDuplicates(res.data, "name");
    setCompaniesData(response);
  };

  const [filterData, setfilterData] = useState([]);

  const getFilterCompanies = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props, manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setfilterData(response);
  };

  const getRoles = async () => {
    // const response = await fetchSiteCompanyRoles({
    //   SearchList: [{ userId: Number(localStorage.userId) }],
    // });
    const response = await getProjectCompanyRoles();

    let a = [];
    const companyRoleData = response.map((value) => {
      a = value;
      a["label"] = value.name;
      return a;
    });
    setRolesData(companyRoleData);
  };

  useEffect(() => {
    getCompanies();
    getRoles();
  }, []);

  return (
    <Dialog open={props.open} onClose={onCloseClick} fullWidth maxWidth="sm">
      <DialogTitle>{props?.modalTitle}</DialogTitle>
      <DialogContent>
        <Stack style={{ paddingTop: "5px" }} spacing={2}>
          <Autocomplete
            inputValue={companyInputValue}
            onChange={(event, newValue) => {
              if (newValue && newValue.inputValue) {
                setCompanyName(newValue?.inputValue);
                setFlag(false);
              } else {
                setGetCompanyId(newValue?.id);
                setFlag(false);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setCompanyInputValue(newInputValue);
              setCompanyName(newInputValue);
              setFlag(true);
              if (newInputValue !== "") {
                getFilterCompanies(newInputValue);
              } else {
                getCompanies();
              }
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;

              const filtered = options.filter((option) =>
                option.title.toLowerCase().includes(inputValue.toLowerCase())
              );

              const isExisting = options.some(
                (option) => inputValue === option.title
              );

              if (inputValue.trim() !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            options={isSearching ? filterData : companiesData}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.title;
            }}
            renderOption={(props, option) => <li {...props}>{option.title}</li>}
            style={{ flex: 1 }}
            color={BIBlue}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label="Companies"
                color="primary"
                variant="outlined"
              />
            )}
            open={companyInputValue?.length > 1 && flag}
          />

          {!props?.hideRole ? (
            <>
              <Autocomplete
                // freeSolo
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                // label="Roles"
                id="Roles"
                options={rolesData}
                onChange={(event, newValue) => {
                  if (newValue && newValue.name == "Other") {
                    setOtherValFlag(true);
                    setRoleId(newValue?.id);
                  } else {
                    setOtherValFlag(false);
                    setRoleId(newValue?.id);
                  }
                }}
                getOptionLabel={(option) => `${option?.name}`}
                style={{ flex: 1 }}
                renderInput={(params) => {
                  return (
                    <>
                      <TextField
                        {...params}
                        label={inputValue?.length != 0 ? "Role" : ""}
                        variant="outlined"
                        placeholder="Select Role (optional)"
                      />
                    </>
                  );
                }}
              />
              {otherValFlag ? (
                <TextField
                  label="New Role"
                  variant="outlined"
                  onChange={(event) => {
                    setOtherRoleName(event.target.value);
                  }}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => onCloseClick()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => onSaveClick()}
          disabled={companyInputValue?.length < 2}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddIteamModalCompanies;
