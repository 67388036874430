import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  fetchClientEmployeesJobs,
  fetchClientTake5,
} from "../../../api/ClientApi";
import { ClientHeader } from "../ClientHeader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../../components/Loader";
import moment from "moment";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { BIGrey } from "../../../assets/buildidColors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import AudioRecorder from "./AudioRecorder";
import { Button } from "@mui/material";

export default function ClientTakeFive() {
  const [jobList, setJobsList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    setLoading(true);
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: Number(localStorage.getItem("userId")),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SearchList: [
          {
            clientEmployeeId: localStorage.getItem("clientEmployeeId"),
          },
        ],
      };
      let res = await fetchClientEmployeesJobs(obj);
      let data = res?.data.map((data) => {
        return {
          ...data,
          innerIsPlaying: false,
        };
      });

      console.log("data ::::::", data);
      setLoading(false);
      setJobsList(data);
    } catch (error) {
      console.log("error ::::::::", error);
      setLoading(false);
    }
  };

  return (
    <>
      <ClientHeader screenName={"Take 5"} />
      <div
        style={{
          paddingTop: "20px",
          minHeight: "100vh",
        }}
        className="page"
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            {jobList.map((data, index) => {
              return (
                <AccordianComponent
                  key={index}
                  jobdata={data}
                  index={index}
                  setJobsList={setJobsList}
                />
              );
            })}
          </>
        )}
      </div>
    </>
  );
}

const AccordianComponent = ({ jobdata, index, setJobsList }) => {
  const [opeAddModal, setOpeAddModal] = useState(false);
  const childRef = useRef(null);

  const fetchJobs = () => {
    if (childRef.current) {
      childRef.current.getjobListRecording();
    }
  };
  return (
    <>
      <Accordion
        key={index}
        style={{
          marginBottom: "10px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              padding: "0px 10px",
              alignItems: "center",
            }}
          >
            <Typography>
              {jobdata?.jobCode}-{jobdata?.jobName}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setOpeAddModal(true);
              }}
            >
              <AddRoundedIcon
                style={{
                  color: localStorage.clientAccentColor,
                }}
              />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "10px",
            }}
          >
            <AudioDetails
              ref={childRef}
              jobdata={jobdata}
              setJobsList={setJobsList}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <AudioRecorder
        jobData={jobdata}
        open={opeAddModal}
        onClose={() => setOpeAddModal(false)}
        reLoadData={fetchJobs}
      />
    </>
  );
};

const AudioDetails = forwardRef(({ jobdata, setJobsList }, ref) => {
  const [recordingData, setRecordingData] = useState([]);
  const [seemore, setSeemore] = useState(3);

  useEffect(() => {
    if (jobdata) getjobListRecording();
  }, []);

  useImperativeHandle(ref, () => ({
    getjobListRecording,
  }));

  const getjobListRecording = async () => {
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage.getItem("userId")),
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: true,
      SearchList: [
        {
          clientEmployeeJobId: jobdata?.id,
        },
      ],
    };
    let res = await fetchClientTake5(obj);

    if (res?.data.length > 0) {
      let temp = [];
      res?.data[0]?.recordingList.map((data) => {
        temp.push({
          ...data,
          isPlaying: false,
        });
      });
      setRecordingData(temp);
    }
  };

  return (
    <>
      {recordingData.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Typography textAlign={"center"} style={{ color: BIGrey }}>
            No Recording available
          </Typography>
        </div>
      ) : (
        <>
          {recordingData.slice(0, seemore).map((data, key) => (
            <ClientTakeFiveAudio
              setJobsList={setJobsList}
              data={data}
              jobdata={jobdata}
              key={key}
              setRecordingData={setRecordingData}
            />
          ))}
          {recordingData.length > 3 && (
            <Button
              style={{
                backgroundColor: "lightgray",
                boxShadow: "none",
                color: "black",
                textTransform: "none",
              }}
              onClick={() =>
                seemore == 3 ? setSeemore(recordingData.length) : setSeemore(3)
              }
              fullWidth
              variant="contained"
            >
              {seemore == 3
                ? `See More ${recordingData.length - 3}`
                : "See Less"}
            </Button>
          )}
        </>
      )}
    </>
  );
});

const ClientTakeFiveAudio = ({
  data,
  setRecordingData,
  setJobsList,
  jobdata,
}) => {
  const [toggle, setToggle] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  console.log("jobdata", jobdata);

  useEffect(() => {
    if (!jobdata.innerIsPlaying) {
      setToggle(false);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      setCurrentTime(0);
    }
    if (!data.isPlaying) {
      setToggle(false);
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      setCurrentTime(0);
    }
  }, [data, jobdata]);

  useEffect(() => {
    if (currentTime === duration + 1) {
      setToggle(false);
      setCurrentTime(0);
      audioRef.current.currentTime = 0;
    }
  }, [currentTime]);

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
    if (audioRef.current.currentTime === audioRef.current.duration) {
      setToggle(false);
    }
  };

  const handleLoadedMetadata = (e) => {
    setDuration(audioRef.current.duration);
  };

  const handleSeek = (event) => {
    audioRef.current.currentTime = event.target.value;
    setCurrentTime(event.target.value);
  };

  const handleTogglePlay = () => {
    if (!toggle) {
      setJobsList((prev) =>
        prev.map((item) =>
          item.id === jobdata.id
            ? {
                ...item,

                innerIsPlaying: true,
              }
            : {
                ...item,
                innerIsPlaying: false,
              }
        )
      );
    }
    if (toggle) {
      audioRef.current.pause();
      setRecordingData((prev) =>
        prev.map((item) => ({ ...item, isPlaying: false }))
      );
    } else {
      audioRef.current.play();
      setRecordingData((prev) =>
        prev.map((item) =>
          item.id === data.id
            ? { ...item, isPlaying: true }
            : { ...item, isPlaying: false }
        )
      );
    }

    setToggle(!toggle);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <div>{moment(data.dateCreated).format("MMMM DD, YYYY")}</div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                marginRight: "10px",
              }}
              onClick={() => {
                handleTogglePlay();
              }}
            >
              {!toggle ? (
                <PlayArrowRoundedIcon fontSize="large" />
              ) : (
                <PauseRoundedIcon fontSize="large" />
              )}
            </span>
            <input
              style={{
                flexGrow: 3,
                width: "100%",
              }}
              type="range"
              max={duration}
              value={currentTime}
              onChange={handleSeek}
            />
            <span
              style={{
                flexGrow: 1,
                marginLeft: "10px",
              }}
            >
              {moment.utc(duration * 1000).format("m:ss")}
            </span>
          </div>
        </div>

        <audio
          ref={audioRef}
          src={data?.fileUrl}
          type="audio/mp3"
          hidden
          controls
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          name="media"
        ></audio>
      </div>
    </>
  );
};
