import React, { useState, useEffect, useCallback } from "react";

import { Button, TextField, Modal, IconButton } from "@material-ui/core";
import {
	Add as AddIcon,
	Edit as EditIcon,
	Save as SaveIcon,
	Close as CloseIcon,
	Delete as DeleteIcon
} from "@material-ui/icons";

import {
	fetchUserTradeCertifications2,
	saveUserTradeCertifications,
	deleteUserTradeCertifications
} from "../../../api/UserApi";
import { stringToLocale } from "../../../assets/helpers";

import Loader from "../../../components/Loader";
import PromptModal from "../../../components/PromptModal";
import { Card, Checkbox, List, ListItem, ListItemButton, ListItemText, Tooltip } from "@mui/material";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

export default function CertificationsCard(props) {

	const [certifications, setCertifications] = useState([]);
	const [certificationModalOpen, setCertifcationModalOpen] = useState(false);
	const [myCertFlag, setMyCertFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);

	const updateCertifications = useCallback(
		() => {
			fetchUserTradeCertifications2({ SearchList: [{ "UserId": localStorage.userId }] })
				.then(res => {

					if (res) {
						let certificationsData = [];
						res.forEach(cert => {
							certificationsData.push({
								id: cert.id || 0,
								userId: cert.userId || 0,
								userName: cert.userName || "",
								school: cert.school || "",
								years: cert.years || 0,
								startDate: cert.startDate || null,
								endDate: cert.endDate || null,
								fieldOfStudy: cert.fieldOfStudy || "",
								isPresent: cert.isPresent || false,
								recordStatus: cert.recordStatus || "",
								recordStatusId: cert.recordStatusId || 0,
								dateCreated: cert.dateCreated || "",
								dateModified: cert.dateModified || "",
								createdBy: cert.createdBy || "",
								modifiedBy: cert.modifiedBy || "",
								createdByUserName: cert.createdByUserName || "",
								modifiedByUserName: cert.modifiedByUserName || ""
							})
						})

						certificationsData = certificationsData?.sort((a, b) => {
							if (a.endDate == '' || a.endDate == null) a.endDate = moment();
							if (b.endDate == '' || b.endDate == null) b.endDate = moment();
							let dateA = new Date(a.endDate), dateB = new Date(b.endDate);
							return dateB - dateA
						})
						setCertifications(certificationsData);
					}
				});
		}, []);

	useEffect(() => {
		updateCertifications();
	}, [updateCertifications])


	const handleMyEducation = () => {
		setMyCertFlag(true);
	}

	const handleBackMyEducation = () => {
		setMyCertFlag(false);
	}

	const handleEdit = () => {
		setEditFlag(true);
	}

	const handleBack = () => {
		setEditFlag(false);
	}

	// new code sanket 04-04-2024
	if (props.loading) {
		return (<></>)
	}

	return (
		<div className="exp-card">
			<Modal
				open={certificationModalOpen}
				onClose={() => setCertifcationModalOpen(false)}>
				<div className="modal-center">
					<div className="modal-card" style={{ width: "400px" }}>
						<div className="modal-card-body">
							<CertificationForm
								isPresent={false}
								school=""
								fieldOfStudy=""
								startDate={null}
								endDate={null}
								id={0}
								onReturnClick={() => {
									updateCertifications();
									setCertifcationModalOpen(false);
								}} />
						</div>
					</div>
				</div>
			</Modal>

			{	// If user has no companies added, show message
				(certifications.length === 0)
					? <>
						{!myCertFlag ?
							<>
								<div className="flex-container-horizontal">
									<div className="flex-container-left">
										<div className="input-container">
											<h3>Education</h3>
											<div style={{ margin: "10px", padding: '0px 10px' }}>
												<div className="flex-container-horizontal" style={{ justifyContent: "space-between", color: 'gray' }}>
													Strengthen your resume and earn BuilderBucks by adding any certifications, degrees, or training experiences you have accomplished.
												</div>
											</div>
										</div>
									</div>
								</div>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									size="small"
									onClick={() => handleMyEducation()}
								>
									Add My Education
								</Button>
							</>
							:
							<>
								<div className="flex-container-horizontal">
									<div className="flex-container-left">
										<div className="input-container">
											<h3>My Education</h3>
										</div>
									</div>
									<div className="flex-container-right">
										<div className="input-container">
											{myCertFlag ?
												<Button
													variant="contained"
													color="primary"
													size="small"
													onClick={() => handleBackMyEducation()}

												>
													Back
												</Button> :
												<></>}
										</div>
									</div>

								</div>
								<div style={{ marginTop: "10px " }}>
									<Button
										color="primary"
										variant="outlined"
										size="small"
										fullWidth
										onClick={() => setCertifcationModalOpen(true)}
									>
										<b>	Add Education</b>
									</Button>
								</div>
							</>
						}
						{/* ADD SECTION HERE ---> */}
					</>
					: (
						<>
							<div className="flex-container-horizontal">
								<div className="flex-container-left">
									<div className="input-container">
										{editFlag ? <h3>My Education</h3> : <h3>Education</h3>}
									</div>
								</div>

								{/* ADD SECTION HERE ---> */}
								<div className="flex-container-right">
									<div className="input-container">
										{editFlag ?
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={() => handleBack()}

											>
												Back
											</Button> :
											<Button
												variant="contained"
												color="primary"
												size="small"
												onClick={() => handleEdit()}
											>
												Edit
											</Button>}
									</div>
								</div>
							</div>
							<div>
								{editFlag ? <>
									<List sx={{ padding: 0 }}>
										{
											certifications.map((cert) => (
												<Certification
													key={cert.id}
													cert={cert}
													editFlag={editFlag}
													onUpdate={updateCertifications} />
											))
										}
									</List>

									<div style={{ marginTop: "15px " }}>
										<Button
											color="primary"
											variant="outlined"
											size="small"
											fullWidth
											onClick={() => setCertifcationModalOpen(true)}
										>
											<b>	Add Education</b>
										</Button>
									</div>
								</> :
									<>
										<List sx={{ padding: 0 }}>
											{
												certifications.map((cert) => (
													<Certification
														key={cert.id}
														cert={cert}
														onUpdate={updateCertifications} />
												))
											}
										</List>
									</>}
							</div>
						</>)
			}






		</div>
	)
}


export function Certification(props) {
	const [editCertificationModalOpen, setEditCertificationModalOpen] = useState(false);
	const id = props.cert.id;
	const school = props.cert.school;
	const isPresent = props.cert.isPresent;
	const fieldOfStudy = props.cert.fieldOfStudy;
	const startDate = props.cert.startDate;
	const endDate = props.cert.endDate;

	return (
		<div>
			<Modal
				open={editCertificationModalOpen}
				onClose={() => setEditCertificationModalOpen(false)}>
				<div className="modal-center">
					<div className="modal-card" style={{ width: "400px" }}>
						<div className="modal-card-body">
							<CertificationForm
								school={school}
								fieldOfStudy={fieldOfStudy}
								startDate={startDate}
								endDate={endDate}
								isPresent={isPresent}
								id={id}
								onReturnClick={() => {
									props.onUpdate();
									setEditCertificationModalOpen(false);
								}} />
						</div>
					</div>
				</div>
			</Modal>

			<Card sx={{ marginBottom: "10px" }}>
				<ListItem
					secondaryAction={
						<>{props.editFlag ?
							<Tooltip title="Edit">
								<IconButton
									color="primary"
									onClick={() => setEditCertificationModalOpen(true)}
								>
									<EditIcon />
								</IconButton>
							</Tooltip> : <></>}
						</>
					}
					disablePadding>
					<ListItemButton
					// onClick={() => { props?.editFlag ? setEditProjectModalOpen(true) : handleProjectClick() }}
					>
						<ListItemText
							primary={fieldOfStudy}
							secondary={<>
								{props.cert.school ? (<span> {school} <br /> </span>) : (<span> {null} <br /> </span>)}

								{	// If startDate is null or empty, then it's "No date"
									(startDate !== null && startDate !== "")
										? (

											// <span>{stringToLocale(startDate)} - {
											<span>{moment(startDate).format('MMM YYYY')} - {
												// If endDate is null, then it's to "Present"
												isPresent === false ? endDate == null || endDate == "Invalid date" ? null : (moment(endDate).format('MMM YYYY')) : "Present"
											} <br /> </span>
										)
										: null
								}
								{/* {props.company.description ? (<span> {props.company.description}  </span>) : null} */}
							</>}
						/>
					</ListItemButton>
				</ListItem>
			</Card>
		</div>
	)
}


export function CertificationForm(props) {
	const [id] = useState(props.id);
	const [isSaving, setIsSaving] = useState(false);
	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const [discardModalOpen, setDiscardModalOpen] = useState(false);
	const [school, setSchool] = useState(props.school);
	const [fieldOfStudy, setFieldOfStudy] = useState(props.fieldOfStudy);
	const [startDate, setStartDate] = useState(props.startDate);
	const [endDate, setEndDate] = useState(props.endDate);
	const [isPresent, setIsPresent] = useState(props.isPresent);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [openEndDatePicker, setOpenEndDatePicker] = useState(false);
	const today = moment();

	const handleStartDateChange = (date) => {
		let getDate = moment(date).startOf('month');
		const newDate = moment(getDate).format("YYYY-MM-DD");
		setStartDate(newDate);
	};

	const handleEndDateChange = (date) => {
		let getDate = moment(date).startOf('month');
		const newDate = moment(getDate).format("YYYY-MM-DD");
		setEndDate(newDate);
	};

	const handleClearStartDate = (e) => {
		e.stopPropagation();
		setStartDate(null);
		setOpenDatePicker(false); // Close the date picker when clearing the date
	};

	const handleClearEndDate = (e) => {
		e.stopPropagation();
		setEndDate(null);
		setOpenEndDatePicker(false); // Close the date picker when clearing the date
	};

	const onPresentChange = () => {
		setIsPresent(!isPresent)
		setEndDate(null);
	}

	async function handleSaveButtonClick() {
		try {
			setIsSaving(true);	// Show loading animation
			if (!isPresent && startDate && endDate && moment(startDate).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')) {
				alert("Start Date should be before the End Date");
				setIsSaving(false);
				return;
			}

			if (school !== "") {
				// Generate JSON object
				let newData = {
					Id: id,
					UserId: Number(localStorage.userId),
					School: school,
					StartDate: startDate,
					EndDate: isPresent ? null : endDate != '' ? endDate : null,
					IsPresent: isPresent,
					FieldOfStudy: fieldOfStudy,
					ModifiedBy: Number(localStorage.userId)
				}

				await saveUserTradeCertifications(newData);	// API Save the certification
				props.onReturnClick();	// Return to view mode
				setIsSaving(false);
			}
		}
		catch (error) {
			alert(error);
		}
	}


	async function handleDeleteButtonClick() {
		try {
			let newData = [{
				Id: id,
				ModifiedBy: localStorage.userId
			}]

			await deleteUserTradeCertifications(newData);
			props.onReturnClick();	// Return to view mode
		}
		catch (error) {
			alert(error);
		}
	}


	function handleCancelButtonClick() {
		// If any field has been changed, show popup to confirm discard
		if (school !== props.school
			|| fieldOfStudy !== props.fieldOfStudy
			|| startDate !== props.startDate
			|| endDate !== props.endDate
			|| isPresent !== props.isPresent
		)
			setDiscardModalOpen(true);

		// If no changes, close edit mode
		else
			props.onReturnClick();
	}


	// Updates local state on TextField change
	function handleTextFieldChange(event) {
		switch (event.target.id) {
			case "school-textfield":
				setSchool(event.target.value);
				break;
			case "trade-textfield":
				setFieldOfStudy(event.target.value);
				break;
			default:
				console.log("ERROR: Wrong TextField id");
		}
	}



	return (
		isSaving
			? <Loader />
			: (
				<>
					<PromptModal
						isOpen={confirmationModalOpen}
						onClose={() => setConfirmationModalOpen(false)}
						title="Are you sure you want to delete this eductation/certification?"
						desc="This action cannot be undone."
						redButtonText="Delete"
						blueButtonTextFirst="Go Back"
						onRedButtonClick={handleDeleteButtonClick} />

					<PromptModal
						isOpen={discardModalOpen}
						onClose={() => setDiscardModalOpen(false)}
						title="Are you sure you want to leave?"
						desc="Any unsaved changes will be discarded."
						redButtonText="Discard"
						blueButtonTextFirst="Go Back"
						onRedButtonClick={props.onReturnClick} />


					<div className="flex-container-vertical">
						<div className="flex-container-horizontal" style={{ justifyContent: 'space-between' }}>
							<div>
								<IconButton
									color="primary"
									onClick={handleCancelButtonClick}>
									<CloseIcon />
								</IconButton>
							</div>
							<div>
								<IconButton
									disabled={school !== "" ? false : true}
									color="primary"
									onClick={handleSaveButtonClick}>
									<SaveIcon />
								</IconButton>
								{(id !== 0)
									? (
										<IconButton
											color="secondary"
											onClick={() => setConfirmationModalOpen(true)}>
											<DeleteIcon />
										</IconButton>

									)
									: (<></>)
								}
							</div>
						</div>
						<div className="flex-container-vertical" style={{ flex: 1 }}>
							<h2 style={{ marginBottom: 20, paddingLeft: '5px' }}>Add Education</h2>
							<div className="input-container">
								<TextField
									id="school-textfield"
									label="School (Required)"
									required
									variant="outlined"
									fullWidth
									defaultValue={school}
									onChange={handleTextFieldChange} />
							</div>

							<div className="input-container">
								<TextField
									id="trade-textfield"
									label="Field of Study"
									variant="outlined"
									fullWidth
									defaultValue={fieldOfStudy}
									onChange={handleTextFieldChange} />
							</div>

							<div className="input-container">
								<div className="flex-container-vertical" style={{ alignItems: "stretch" }}>
									<KeyboardDatePicker
										autoOk
										open={openDatePicker} // controlled open state
										onClose={() => setOpenDatePicker(false)} // close date picker on date selection
										variant="inline"
										inputVariant="outlined"
										label="Start Date"
										placeholder="Month-Year"
										// value={startDate === null || startDate === "Invalid date" ? null : stringToLocale(startDate)}
										value={startDate === null || startDate === "Invalid date" ? null : startDate}
										views={["year", "month"]}
										InputAdornmentProps={{ position: "start" }}
										onChange={date => handleStartDateChange(date)}
										maxDate={moment(new Date()).format("YYYY-MM")}
										InputProps={{
											readOnly: true, // disable text field editing
											onClick: () => setOpenDatePicker(true), // open date picker on text field click
											endAdornment: startDate !== "Invalid date" ? (
												startDate != null ? (
													<IconButton style={{ order: 1 }} onClick={(e) => handleClearStartDate(e)}>
														<CloseIcon />
													</IconButton>
												) : null
											) : null,
										}}
									/>
								</div>
							</div>
							<div className="input-container">
								<div className="flex-container-vertical" style={{ alignItems: "stretch" }}>
									<KeyboardDatePicker
										autoOk
										open={openEndDatePicker}
										onClose={() => setOpenEndDatePicker(false)}
										variant="inline"
										inputVariant={isPresent ? "filled" : "outlined"}
										label="End Date"
										placeholder="Month-Year"
										// value={endDate === null || endDate === "Invalid date" ? null : !isPresent ? stringToLocale(endDate) : null}
										value={endDate === null || endDate === "Invalid date" ? null : !isPresent ? endDate : null}
										views={["year", "month"]}
										disabled={isPresent}
										InputAdornmentProps={{ position: "start" }}
										onChange={date => handleEndDateChange(date)}
										maxDate={today.format("YYYY-MM-DD")}
										InputProps={{	// Only show "X" button if theres a date to clear
											readOnly: true,
											onClick: () => setOpenEndDatePicker(true),
											endAdornment:
												(endDate !== "Invalid date") ?
													((!isPresent && endDate != null) ?
														<IconButton style={{ order: 1 }} onClick={(e) => handleClearEndDate(e)}>
															<CloseIcon />
														</IconButton> : null) : null
										}}
									/>
								</div>
							</div>

							<div >
								<Checkbox
									color="primary"
									checked={isPresent}
									onChange={() => onPresentChange()}
								/>
								<span style={{ color: "grey" }}>
									Present
								</span>
							</div>
						</div>
					</div>
				</>
			)
	)
}
