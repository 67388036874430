import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  IconButton,
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Avatar,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close as CloseIcon } from "@material-ui/icons";
import AlertModal from "../../components/AlertModal";
import moment from "moment";
import { BIBlue, BILightGrey } from "../../assets/buildidColors";
import {
  getRecentlyInteractUsers,
  saveRecentlyInteractUsers,
} from "../../api/UserApi";
import { getUserContacts } from "../../api/ContactsApi";
import CheckIcon from "@mui/icons-material/Check";
import { Badge } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

export default function SendExpenseModal(props) {
  const [toSendWith, setToSendWith] = useState([]);
  const [sendExpenseAlert, setSendExpenseAlert] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [recentInteractUsers, setRecentInteractUsers] = useState([]);
  const [sendBtnLoading, setSendBtnLoading] = useState(false);

  async function getContacts() {
    const res = await getUserContacts(localStorage.userId);
    setSearchData(res);
  }
  async function fetchRecentInteractUsers() {
    const res = await getRecentlyInteractUsers(localStorage?.userId);
    setRecentInteractUsers(res);
  }

  useEffect(() => {
    getContacts();
    fetchRecentInteractUsers();
  }, []);
  const handleInputChange = (e) => {
    setNewEmail(e.target.value.trim());
  };

  function handleChange(_, newValue) {
    handleEmail(newValue);
  }

  // const handleRecentInteractUserClick = (user) => {
  //   if (user && user.interactUserEmail) {
  //     setToSendWith((prevToSendWith) => [
  //       ...prevToSendWith,
  //       {
  //         contactEmail: user.interactUserEmail,
  //         contactId: user.interactUserId,
  //       },
  //     ]);
  //   }
  // };

  const handleRecentInteractUserClick = (user) => {
    if (user && user.interactUserEmail) {
      // Check if the user is already in the `toSendWith` array
      if (toSendWith.some((item) => item.contactId === user.interactUserId)) {
        // If the user is already in the array, remove them
        setToSendWith((prevToSendWith) =>
          prevToSendWith.filter(
            (item) => item.contactId !== user.interactUserId
          )
        );

        // Also, update `recentInteractUsers` to set `isSelected` to false
        const updated = recentInteractUsers.map((recent) => {
          if (recent.id === user.id) {
            return {
              ...recent,
              isSelected: false, // Set `isSelected` to false
            };
          }
          return recent;
        });

        setRecentInteractUsers(updated);
        return; // Stop execution after removing the user
      }

      // If the user is not in the array, add them
      setToSendWith((prevToSendWith) => [
        ...prevToSendWith,
        {
          contactEmail: user.interactUserEmail,
          contactId: user.interactUserId,
        },
      ]);

      // Update the `recentInteractUsers` array to toggle `isSelected`
      const updated = recentInteractUsers.map((recent) => {
        if (recent.id === user.id) {
          return {
            ...recent,
            isSelected: true, // Set `isSelected` to true
          };
        }
        return recent;
      });

      setRecentInteractUsers(updated);
    }
  };

  const handleEmail = (email) => {
    let tmpArr = email.map((item) => {
      if (item.contactEmail) {
        return {
          contactEmail: item.contactEmail,
          contactId: item.contactId,
        };
      } else {
        return item;
      }
    });
    setNewEmail("");
    setToSendWith(tmpArr);
  };

  const handleSendExpenses = () => {
    props.setloading(true);
    setSendBtnLoading(true);

    const selectedUsers = toSendWith.map((user) => {
      if (user.contactEmail) {
        return user?.contactEmail;
      } else {
        return user;
      }
    });
    props.sendExpense(selectedUsers).then((resp) => {
      if (resp.message === "OK") {
        props.closeSendExpenseModal(false);
        setSendExpenseAlert(true);
        props.setSendButtonVisible();
        if (props.activeTab === "unsent") {
          props.getUnsentExpenses();
          props.setloading(false);
        } else {
          props.getSentExpenses();
          props.setloading(false);
        }
        toSendWith.forEach(async (item) => {
          const interactionObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                InteractUserId: item.contactId,
                ModifiedBy: Number(localStorage.userId),
                fromScreen: "Expense",
              },
            ],
          };
          saveRecentlyInteractUsers(interactionObj);
        });
        fetchRecentInteractUsers(localStorage.userId);
        setToSendWith([]);
      }
    });
    props.setSendButtonVisible();
    setSendBtnLoading(false);
  };

  return (
    <>
      <AlertModal
        isOpen={sendExpenseAlert}
        onClose={() => setSendExpenseAlert(false)}
        title={"Expenses Sent Successfully..."}
      />

      <Modal open={props.isOpen} onClose={props.onClose}>
        <div className="modal-center">
          <div className="modal-card" style={{ width: "500px" }}>
            <div className="modal-card-header-buttons">
              <IconButton color="primary" onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-card-body">
              <div className="flex-container-vertical">
                <h2>Send Expense</h2>

                {/* <div className="input-container" style={{ flex: 1 }}>
                  <div
                    className="flex-container-horizontal"
                    style={{ alignItems: "stretch" }}>
                    <Autocomplete
                      id="sendWith-autocomplete"
                      inputValue={newEmail}
                      multiple
                      freeSolo
                      filterSelectedOptions
                      options={newEmail.length >= 2 ? searchData : []}
                      // getOptionLabel={(option) =>
                      //   option.contactEmail ? option.contactName : option
                      // }
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.contactEmail) {
                          return option.contactEmail;
                        }
                        if (option.contactName) {
                          return option.contactName;
                        }
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}>
                            <div style={{ display: "flex" }}>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                                loading="lazy"
                                width="50px"
                                height="50px"
                                src={props.contactPictureUrl}
                                alt=""
                              />
                              <div>
                                {props.contactName} <br />
                                {props.contactEmail}
                              </div>
                            </div>
                          </Box>
                        );
                      }}
                      value={toSendWith}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          onChange={handleInputChange}
                          onBlur={(e) => {
                            if (e.target.value !== "") {
                              setToSendWith(toSendWith.concat(e.target.value));
                              setNewEmail("");
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode === 32 || e.key === 32) {
                              if (e.target.value !== "") {
                                setToSendWith(
                                  toSendWith.concat(e.target.value)
                                );
                                setNewEmail("");
                              }
                            }
                          }}
                          fullWidth
                          label="Add Emails"
                          value={newEmail}
                        />
                      )}
                      style={{ flex: 1 }}
                    />

                    {
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={toSendWith.length !== 0 ? false : true}
                        onClick={handleSendExpenses}>
                        Send
                      </Button>
                    }
                  </div>
                </div> */}
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <div
                      className="flex-container-horizontal"
                      style={{ alignItems: "stretch" }}
                    >
                      <Autocomplete
                        id="sendWith-autocomplete"
                        inputValue={newEmail}
                        multiple
                        freeSolo
                        filterSelectedOptions
                        options={newEmail.length >= 2 ? searchData : []}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.contactEmail) {
                            return option.contactEmail;
                          }
                          if (option.contactName) {
                            return option.contactName;
                          }
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                              loading="lazy"
                              width="50px"
                              height="50px"
                              src={option.contactPictureUrl}
                              alt=""
                            />
                            {option.contactName} <br />
                            {option.contactEmail}
                          </Box>
                        )}
                        value={toSendWith}
                        onChange={(event, newValue, reason) => {
                          if (reason === "removeOption") {
                            // Compare the previous value with the new value to detect which option was removed
                            const removedItem = toSendWith.find(
                              (item) => !newValue.includes(item)
                            );
                            if (removedItem) {
                              // Check if the removed item is part of recentInteractUsers
                              const recentUserIndex =
                                recentInteractUsers.findIndex(
                                  (recent) =>
                                    recent.interactUserId ===
                                    removedItem.contactId
                                );
                              if (recentUserIndex !== -1) {
                                // Toggle `isSelected` to false for the recent user
                                const updatedRecentUsers = [
                                  ...recentInteractUsers,
                                ];
                                updatedRecentUsers[
                                  recentUserIndex
                                ].isSelected = false;
                                setRecentInteractUsers(updatedRecentUsers);
                              }
                            }
                          } else {
                            setNewEmail("");
                          }
                          setToSendWith(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            onChange={handleInputChange}
                            onBlur={(e) => {
                              if (e.target.value !== "") {
                                const flag = toSendWith.some(
                                  (item) =>
                                    item.contactEmail === e.target.value ||
                                    item === e.target.value
                                );
                                if (flag) {
                                  setNewEmail("");
                                  return;
                                }
                                setToSendWith(
                                  toSendWith.concat(e.target.value)
                                );
                                setNewEmail("");
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.keyCode === 32 || e.key === 32) {
                                if (e.target.value !== "") {
                                  setToSendWith(
                                    toSendWith.concat(e.target.value)
                                  );
                                  setNewEmail("");
                                }
                              }
                            }}
                            fullWidth
                            label="Add Contacts or emails"
                            value={newEmail}
                          />
                        )}
                        style={{ flex: 1 }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    {
                      sendBtnLoading ?
                        (<Button
                          variant="contained"
                          color="primary"
                          disabled
                        >
                          <CircularProgress style={{ color: BIBlue, height: '26px', width: '26px' }} />
                        </Button>)
                        :
                        (<Button
                          variant="contained"
                          color="primary"
                          disabled={toSendWith.length !== 0 ? false : true}
                          onClick={handleSendExpenses}
                        >
                          Send
                        </Button>)
                    }
                  </Grid>
                </Grid>
              </div>

              {/* <Grid container spacing={2} className="flex-container-vertical">
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <b>Recent</b>
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {recentInteractUsers.map((user) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={user.userId}>
                      <div onClick={() => handleRecentInteractUserClick(user)}>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            <Avatar
                              style={{ width: "40px", height: "40px" }}
                              alt={user.interactUserName}
                              src={user.interactUserImageUrl}
                            />
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <b>
                                {user.interactUserName.length > 7
                                  ? user.interactUserName.substring(0, 7) +
                                    "..."
                                  : user.interactUserName}
                              </b>
                              <br />
                              {user.interactUserTradeName.length > 8
                                ? user.interactUserTradeName.substring(0, 8) +
                                  "..."
                                : user.interactUserTradeName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid> */}
              <Grid container spacing={2} className="flex-container-vertical">
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <b>Recent</b>
                  </Typography>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {recentInteractUsers.map((user) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={user.userId}>
                      <div onClick={() => handleRecentInteractUserClick(user)}>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            {user.isSelected ? (
                              <Badge
                                badgeContent={
                                  <DoneIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "white",
                                      backgroundColor: BIBlue,
                                      borderRadius: "15px",
                                    }}
                                  />
                                }
                              >
                                <Avatar
                                  style={{ width: "40px", height: "40px" }}
                                  alt={user.interactUserName}
                                  src={user.interactUserImageUrl}
                                />
                              </Badge>
                            ) : (
                              <Avatar
                                style={{ width: "40px", height: "40px" }}
                                alt={user.interactUserName}
                                src={user.interactUserImageUrl}
                              />
                            )}
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <b>
                                {user.interactUserName.length > 7
                                  ? user.interactUserName.substring(0, 7) +
                                  "..."
                                  : user.interactUserName}
                              </b>
                              <br />
                              {user.interactUserTradeName.length > 8
                                ? user.interactUserTradeName.substring(0, 8) +
                                "..."
                                : user.interactUserTradeName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <div className="flex-container-vertical">
                <span style={{ marginTop: "10px" }}>Expenses to Send</span>
                <Divider
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    borderBottom: "2px solid #EDEDED",
                  }}
                />
                <div className="scrollable-container">
                  <div>
                    {props.selectedExpenses.map((expense, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h3>{expense.description}</h3>
                          <span>
                            {moment(expense?.dateCreated).format(
                              "MMMM DD, YYYY"
                            )}
                          </span>
                        </div>
                        <div style={{ color: BIBlue, marginRight: "20px" }}>
                          <h4>${expense.totalAmount}.00</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
