import React, { useEffect } from "react";
import { fetchGoals } from "../../api/TimesheetApi";
import { IconButton, List, ListItem, Modal } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { useState } from "react";
import "../../assets/css/Timesheets.css";

function GoalsList(props) {
  return (
    <Modal
      disableAutoFocus
      open={true}
      className="goallist-modal"
      onClose={props.onClose}
    >
      <div className="modal-center" style={{ border: "0px" }}>
        <div className="modal-card goal-card">
          <div className="header-section">
            <IconButton color="primary" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <List className="goal-list">
            {props?.goalList.map((item) => (
              <ListItem className="goal-item">
                <div className="list-item">
                  <h2
                    className="heading"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props?.onGoalPressed(item);
                    }}
                  >
                    {item?.name}
                  </h2>
                  {item?.categoryList?.map((categoryItem, categoryIndex) => (
                    <span
                      className="category-name"
                      style={{ cursor: "pointer", marginLeft: "25px" }}
                      key={categoryIndex}
                      onClick={() =>
                        props?.onCategoryPressed(categoryItem, categoryIndex)
                      }
                    >
                      {categoryItem?.categoryName}
                    </span>
                  ))}
                </div>
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </Modal>
  );
}
export default GoalsList;
