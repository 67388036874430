import React, { useEffect, useState } from "react";
import { fetchClientEmployeesJobs } from "../../../api/ClientApi";
import { Card, Stack, Typography, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ListItemButton from "@mui/material/ListItemButton";
import { ClientHeader } from "../ClientHeader";
import "../../../assets/css/ClientHome.css";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

function ClientSiteFiling() {
  const [siteFilingsList, setSiteFilingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");

  const getSiteFiling = async () => {
    setIsLoading(true);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 30,
      FullSearch: clientSearchTerm || "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          ModifiedBy: localStorage.userId,
          clientEmployeeId: localStorage.getItem("clientEmployeeId"),
        },
      ],
    };
    await fetchClientEmployeesJobs(obj)
      .then((res) => {
        const modifiedRes = res?.data;
        setSiteFilingList(modifiedRes);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getting error while getting site filing list", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSiteFiling();
  }, [clientSearchTerm]);

  return (
    <>
      <ClientHeader
        screenName={"Site Filing"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content" style={{ marginTop: "20px" }}>
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <Stack spacing={1}>
                {siteFilingsList?.map((data, index) => {
                  return <ClientSiteFilingCard data={data} />;
                })}
              </Stack>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientSiteFiling;

export const ClientSiteFilingCard = ({ data }) => {
  const history = useHistory();

  return (
    <>
      <ListItemButton
        onClick={() => {
          history.push(`/client-site-filing-detail/${data.id}`);
        }}
        style={{ padding: "0px", borderRadius: "10px" }}
      >
        <Card
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "auto",
            // maxHeight: "100px",
            padding: "10px 10px 10px 0px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: "95%", height: "50%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  display: "flex",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                gutterBottom
                component="div"
              >
                <LocationOnIcon
                  style={{
                    fontSize: 20,
                    color: localStorage?.clientAccentColor,
                  }}
                />
                {`${data.jobCode} - ${data.jobName}`}
              </Typography>
            </Stack>
          </Box>
        </Card>
      </ListItemButton>
    </>
  );
};
