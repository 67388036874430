import React, { useState, useEffect } from "react";
import { Checkbox, Chip, Divider, IconButton } from "@material-ui/core";
import {
  Save as SaveIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import {
  saveUserCompanies,
  saveUserWorkHistory,
  fetchUserCompanies,
  saveUsersWorkHistory,
  fetchUserWorkHistory2,
} from "../api/UserApi";
import { fetchCompanies, saveCompanies2 } from "../api/CompanyApi";
import Loader from "../components/Loader";
import PromptModal from "../components/PromptModal";
import AlertModal from "../components/AlertModal";
import moment from "moment";
import { Stack, TextField } from "@mui/material";
import {
  deleteUserSitesProjects,
  deleteUserWorkHistories2,
  deleteUsersExperiencesProducts,
  deleteUsersWorkHistory,
  saveProjectCompany,
  saveUsersExperiencesProducts,
} from "../api/SitesApi"; //changes-07-03-24-rahul
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import defaultProjectImage from "../assets/images/project-profile-placeholder.jpg";
import SelectProductModal from "./SelectProductModal";
import SelectTradeModal from "./SelectTradeModal";
import SelectRoleModal from "./SelectRoleModal";
import { getUserExperiencesProducts } from "../api/ProductsApi"; //changes-07-03-24-rahul
import { fetchRoles, saveRole } from "../api/RolesApi";
import { fetchTrades, requestTrade } from "../api/TradesApi";
import { fetchProjectCompanyRoles } from "../api/ProjectsApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useActions } from "./ActionsContext";

const filter = createFilterOptions();

const ExperienceProjectForm = (props) => {
  const today = moment();

  //changes-07-03-24-rahul
  const [isLoading, setIsLoading] = useState(true);

  const [projectName, setProjectName] = useState("");
  const [projectId, setProjectId] = useState(0);

  const [siteDetails, setSiteDetails] = useState(props?.siteDetails ?? {});
  const [siteAddress, setSiteAddress] = useState({});
  const [projectForCompanyId, setProjectForCompanyId] = useState(0);
  //changes-07-03-24-rahul
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isPresent, setIsPresent] = useState(false);
  const [includeInResume, setIncludeInResume] = useState(true);
  const [description, setDescription] = useState("");
  const [companyStartDateNew, setCompanyStartDateNew] = useState(null);
  const [companyEndDateNew, setCompanyEndDateNew] = useState(null);
  const [companyData, setCompanyData] = useState([]);
  const [allCompanies, seAllCompanies] = useState([]);

  const [flag, setFlag] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [companyDetail, setCompanyDetail] = useState(null);

  const [isSearching, setIsSearching] = useState(false);
  const [modalDateChecker, setModalDateChecker] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);
  const [updateModal, setUpdateModal] = useState(null);
  const [updateModalTitle, setUpdateModalTitle] = useState(null);
  const [updateModalBody, setUpdateModalBody] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openEndDatePicker, setOpenEndDatePicker] = useState(false);

  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedProductsOriginalApiCall, setSelectedProductsOriginalApiCall] =
    useState([]); //changes-07-03-24-rahul
  const [showSelectTradeModal, setShowSelectTradeModal] = useState(false);
  const [showSelectRoleModal, setShowSelectRoleModal] = useState(false);
  const [selectedTradeId, setSelectedTradeId] = useState(0);
  const [selectedTradeName, setSelectedTradeName] = useState("");
  const [selectedRoleId, setSelectedRoleId] = useState(0);
  const [selectedRoleName, setSelectedRoleName] = useState("");

  const [selectedCompanyRoleId, setSelectedCompanyRoleId] = useState(0);
  const [selectedCompanyRoleName, setSelectedCompanyRoleName] = useState("");
  const [companyRolesData, setCompanyRolesData] = useState([]);
  const [filterCompanyRolesData, setFilterCompanyRolesData] = useState([]);
  const [companyRoleFlag, setCompanyRoleFlag] = useState(false);

  const [rolesData, setRolesData] = useState([]);
  const [tradesData, setTradesData] = useState([]);
  const [filterRolesData, setFilterRolesData] = useState([]);
  const [filterTradesData, setFilterTradesData] = useState([]);
  const [roleFlag, setRoleFlag] = useState(false);
  const [tradeFlag, setTradeFlag] = useState(false);

  const [showDeleteAlertModal, setShowDeleteAlertModal] = useState(false); //changes-07-03-24-rahul
  const [unSelectedProductList, setUnSelectedProductList] = useState([]);

  const [prevCompId, setPrevCompId] = useState(0);
  const [prevRoleId, setPrevRoleId] = useState(0);
  const [prevCompRoleId, setPrevCompRoleId] = useState(0);
  const history = useHistory();
  const { getBuilderBucksCount } = useActions();
  //changes-07-03-24-rahul
  useEffect(() => {
    getFetchUserCompanies();
    getCompanies();
  }, []);

  //changes-07-03-24-rahul
  useEffect(() => {
    if (props?.siteDetails) {
      setSiteDetails(props?.siteDetails);
      let addressObj = {
        address: props?.siteDetails?.address ?? "",
        country: props?.siteDetails?.country ?? "",
        province: props?.siteDetails?.province?.toUpperCase() ?? "",
        city: props?.siteDetails?.city ?? "",
        postalCode: props?.siteDetails?.postalCode ?? "",
        latitude: props?.siteDetails?.latitude ?? 0,
        longitude: props?.siteDetails?.longitude ?? 0,
      };
      setSiteAddress(addressObj);
      setProjectName(props?.projectName ?? "");
      setProjectId(props?.projectId ?? 0);
      setCompanyDetail(props?.companyData);
      if (props?.from === "companyProfile") {
        setCompanyName(props?.companyData?.name ?? "");
        setCompanyId(props?.companyData?.id);
        setPrevCompId(props?.companyData?.id);
      } else {
        setCompanyName(props?.companyData?.companyName ?? "");
        setCompanyId(props?.companyData?.companyId);
        setPrevCompId(props?.companyData?.companyId);
      }
      // setSelectedRoleId(props?.companyData?.roleId);
      // setSelectedRoleName(props?.companyData?.roleName);
      // setPrevRoleId(props?.companyData?.roleId)
      setIsLoading(false);
    }
    if (props?.details) {
      setData(props?.details);
    }
    if (props?.from === "projectProfile" && props?.data) {
      setDataFromProjectProfile(props?.data ?? {});
    }
  }, [props]);

  //changes-07-03-24-rahul
  const setData = async (details) => {
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 20,
        UserId: Number(localStorage.userId),
        SearchList: [{ UserId: Number(localStorage.userId) }],
        SortList: [{ FieldName: "id", Direction: "DESC" }],
      };

      const workHistory = await fetchUserWorkHistory2(workHistoryObj);
      const userExperienceProductList =
        workHistory?.userExperienceProductList ?? [];

      const productsData = userExperienceProductList.filter(
        (item) => item?.userExperienceId == details?.id
      );
      const alreadySelectedProducts = productsData.map((product) => {
        let returnObject = {
          mainId: product.id,
          id: product.productId,
          name: product.productName,
        };
        return returnObject;
      });

      // if (details?.projectId && details?.companyId) {
      //     const obj = {
      //         PageNr: 1,
      //         NrOfRecPerPage: 20,
      //         FullSearch: "",
      //         UserId: 1,
      //         IncludeRecordNr: true,
      //         TypeOfObjectReturned: "",
      //         FetchAllowedRecordsOnly: false,
      //         SearchList: [
      //             {
      //                 projectId: details.projectId,
      //                 companyId: details.companyId,
      //             },
      //         ],
      //         SortList: [{}],
      //     };
      //     const res = await fetchProjectCompanies2(obj);
      //     setProjectForCompanyId(res?.data?.[0]?.id ?? 0);
      // }

      let addressObj = {
        address: details?.address ?? "",
        country: details?.country ?? "",
        province: details?.province?.toUpperCase() ?? "",
        city: details?.city ?? "",
        postalCode: details?.postalCode ?? "",
        latitude: details?.latitude ?? 0,
        longitude: details?.longitude ?? 0,
      };

      let siteInfoObj = {
        id: details?.siteId,
        name: details?.siteName,
      };

      setCompanyName(details?.companyName ?? "");
      setCompanyId(details?.companyId);
      setSelectedCompanyRoleId(details?.projectCompanyRoleId);
      setSelectedCompanyRoleName(details?.projectCompanyRoleName);
      setSelectedRoleId(details?.roleId);
      setSelectedRoleName(details?.roleName);
      setSelectedTradeId(details?.tradeId);
      setSelectedTradeName(details?.tradeName);
      setIsPresent(details?.present);
      setDescription(details?.description);
      setStartDate(details?.startDate ?? null);
      setEndDate(details?.endDate ?? null);
      setProjectName(details?.projectName);
      setProjectId(details?.projectId);
      setSiteDetails(siteInfoObj);
      setSiteAddress(addressObj);
      setSelectedProductList(alreadySelectedProducts ?? []);
      setSelectedProductsOriginalApiCall(alreadySelectedProducts ?? []);
      setCompanyDetail({ ...details, userCompanyId: details?.id });
      setIncludeInResume(details?.includeInResume ?? true);
      setPrevCompId(details?.companyId);
      setPrevRoleId(details?.roleId);
      setPrevCompRoleId(details?.projectCompanyRoleId);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while getting data :: ", error);
    }
  };
  //changes-07-03-24-rahul

  const setDataFromProjectProfile = (data) => {
    let siteDetailsObj = {
      id: data?.siteId ?? 0,
      name: data?.siteName ?? "",
    };

    let addressObj = {
      address: data?.address ?? "",
      country: data?.country ?? "",
      province: data?.province?.toUpperCase() ?? "",
      city: data?.city ?? "",
      postalCode: data?.postalCode ?? "",
      latitude: data?.latitude ?? 0,
      longitude: data?.longitude ?? 0,
    };

    setSiteDetails(siteDetailsObj);
    setSiteAddress(addressObj);
    setProjectName(data?.projectName ?? "");
    setProjectId(data?.id ?? 0);
    setIsLoading(false);
  };

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getCompanies = async () => {
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = removeDuplicates(res.data, "name");
    setCompanyData(response);
  };

  const getFilterCompanies = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props, manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setFilterData(response);
  };

  const getCompanyRoles = async () => {
    const companyRoleObj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage?.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [{}],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    };
    const res = await fetchProjectCompanyRoles(companyRoleObj);

    let a;
    res.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res, "name");
    setCompanyRolesData(response);
  };

  const getFilterCompanyRoles = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }

    const companyRoleObj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage?.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [{ Name: props }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    };
    const res = await fetchProjectCompanyRoles(companyRoleObj);
    let a;
    res.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res, "name");
    setFilterCompanyRolesData(response);
  };

  const getRoles = async () => {
    const res = await fetchRoles({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setRolesData(response);
  };

  const getFilterRoles = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchRoles({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props, manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setFilterRolesData(response);
  };

  const getTrades = async () => {
    const res = await fetchTrades({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ userId: Number(localStorage.userId) }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setTradesData(response);
  };

  const getFilterTrades = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchTrades({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setFilterTradesData(response);
  };

  const getFetchUserCompanies = async () => {
    // setIsLoading(true);
    try {
      const userCompanyObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        NrOfRecPerPage: 0,
        SearchList: [{ UserId: Number(localStorage.userId) }],
      };
      const userCompanies = await fetchUserCompanies(userCompanyObj);
      await seAllCompanies(userCompanies);
      // setIsLoading(false);
    } catch (error) {
      console.log("Error---", error);
    }
  };

  const onProductClick = (item) => {
    const objectIndex = selectedProductList.findIndex(
      (obj) => obj.id === item?.id
    );
    if (objectIndex !== -1) {
      let updatedArray = [...selectedProductList];
      updatedArray.splice(objectIndex, 1);
      setSelectedProductList(updatedArray);
      if (selectedProductsOriginalApiCall.some((obj) => obj.id === item?.id)) {
        const objectIndex = selectedProductsOriginalApiCall.findIndex(
          (obj) => obj.id === item?.id
        );
        if (objectIndex !== -1) {
          let obj = {
            ...selectedProductsOriginalApiCall[objectIndex],
            mainId: selectedProductsOriginalApiCall[objectIndex]?.mainId,
          };
          setUnSelectedProductList((preValue) => [...preValue, obj]);
        }
      }
    } else {
      let updatedArray = [...selectedProductList, item];
      setSelectedProductList(updatedArray);
      if (
        selectedProductsOriginalApiCall.some((obj) => obj.id === item?.id) &&
        unSelectedProductList.some((obj) => obj.id === item?.id)
      ) {
        setUnSelectedProductList((preValue) => [
          ...preValue.filter((obj) => obj?.id !== item?.id),
        ]);
      }
    }
  };

  function handleDescription(e) {
    setDescription(e.target.value);
  }

  //changes-07-03-24-rahul
  const handleDeleteButtonClick = async () => {
    try {
      setShowDeleteAlertModal(false);
      let deleteObj = {
        UserId: Number(localStorage?.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: props?.details?.id ?? 0,
            ModifiedBy: Number(localStorage?.userId),
          },
        ],
      };
      await deleteUsersWorkHistory(deleteObj);
      props?.onExperienceDelete();
      getBuilderBucksCount();
      /* new code sanket 20-03-2024 */
      //props.onClose();
    } catch (error) {
      props?.onExperienceDelete();
      console.log("Error while deleting experience :: ", error);
    }
  };

  const deleteProducts = async () => {
    try {
      let deletingArray = unSelectedProductList.map((item) => {
        return { Id: item?.mainId };
      });
      function removeDuplicates(arr) {
        return arr.filter((item, index) => arr.indexOf(item) === index);
      }
      let removeDuplicateArray = await removeDuplicates(deletingArray);
      if (removeDuplicateArray.length > 0) {
        const obj = {
          UserId: Number(localStorage?.userId),
          ReturnRecordError: false,
          ReturnRecordError: true,
          DeleteList: removeDuplicateArray,
        };
        await deleteUsersExperiencesProducts(obj);
      }
    } catch (error) {
      console.log("Error while deleting the products :: ", error);
    }
  };

  const onSelectClick = (selectedProductArray, unSelectedProductArray) => {
    setSelectedProductList(selectedProductArray);
    setUnSelectedProductList(unSelectedProductArray);
  };

  //changes-07-03-24-rahul

  const handleProjectSaveButton = async () => {
    try {
      let roleId = selectedRoleId ?? 0;
      let companyRoleId = selectedCompanyRoleId ?? 0;
      let tradeId = selectedTradeId ?? 0;
      if (startDate == endDate && startDate != null) {
        setModalDateChecker("startdate-enddate");
        setModalDescription("Please Change End Date or unchecked present.");
        return;
      } else if (
        !isPresent &&
        startDate &&
        endDate &&
        moment(startDate).format("YYYY-MM-DD") >
        moment(endDate).format("YYYY-MM-DD")
      ) {
        setModalDateChecker("start");
        setModalDescription(
          "Start Date should be before the End Date, or click I currently work here if you are still working on this site."
        );
        return;
      } else if (isPresent && !startDate) {
        setModalDateChecker("start-date");
        setModalDescription("Please select start date or unchecked present.");
        return;
      }

      if (selectedRoleId === 0 && selectedRoleName?.length > 1) {
        roleId = await saveSelectedItem(selectedRoleName, "role");
        setSelectedRoleId(roleId);
      }

      if (selectedTradeId === 0 && selectedTradeName?.length > 1) {
        tradeId = await saveNewTrade(selectedTradeName, "trade");
        setSelectedTradeId(tradeId);
      }

      if (selectedCompanyRoleId === 0 && selectedCompanyRoleName?.length > 1) {
        alert("This company role not present");
        return;
      }

      //changes-07-03-24-rahul
      // await deleteProducts();
      if (companyId === 0 && companyName?.trim() !== "") {
        let getCompanyId = await saveSelectedItem(companyName, "company");
        await formatTimeframe(getCompanyId, roleId, tradeId, companyRoleId);
      }
      // else if (
      //         companyId != -1 &&
      //         companyId != 0 &&
      //         !allCompanies.some((comp) => comp?.companyId == companyId)
      //     ) {
      //         console.log("33333333333")
      //         await saveCompanies(companyId, roleId, tradeId);
      //     }
      else {
        await formatTimeframe(companyId, roleId, tradeId, companyRoleId);
      }
      await props?.handleSaveFollowClick(true);
    } catch (err) {
      console.log("Error-----------", err);
    }
  };

  const formatTimeframe = async (companyId, roleId, tradeId, companyRoleId) => {
    let isProjectAdd = true;
    let isProjectOutOfFrame = false;
    const comp = allCompanies
      .filter((comp) => comp.companyId == companyDetail?.companyId)
      ?.pop();
    if (comp) {
      let companyStartDate = comp?.startDate
        ? moment(comp?.startDate).format("YYYY-MM-DD")
        : null;
      let companyEndDate = comp?.endDate
        ? moment(comp?.endDate).format("YYYY-MM-DD")
        : null;
      let newStartDate = startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : null;
      let newEndDate = endDate ? moment(endDate).format("YYYY-MM-DD") : null;
      if (newStartDate && newEndDate && companyStartDate && companyEndDate) {
        if (newStartDate >= companyStartDate && newEndDate <= companyEndDate) {
          // Add project, no company update
          isProjectAdd = true;
        } else if (
          newStartDate < companyStartDate &&
          newEndDate > companyEndDate
        ) {
          // Add project, update company, show out of frame dialog, CSD = PSD, CED = PED
          companyStartDate = newStartDate;
          companyEndDate = newEndDate;
          isProjectOutOfFrame = true;
        } else if (newEndDate < companyStartDate) {
          // no project/company update
          isProjectAdd = false;
        } else if (newStartDate > companyEndDate) {
          // no project/company update
          isProjectAdd = false;
        }
      }
      if (!companyStartDate && !companyEndDate && newStartDate) {
        companyStartDate = newStartDate;
        companyEndDate = newEndDate;
        setCompanyData(comp);
        setCompanyStartDateNew(companyStartDate);
        setCompanyEndDateNew(companyEndDate);
        let obj = {
          data: comp,
          companyStartDateNew: companyStartDate,
          companyEndDateNew: companyEndDate,
          currentCompany: isPresent,
          onUpdatePressed: "onUpdatePressed",
        };
        onUpdatePressed(obj);
        return;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate < companyStartDate &&
        !newEndDate
      ) {
        // update company, show out of frame dialog, CSD = PSD
        isProjectOutOfFrame = true;
        companyStartDate = newStartDate;
      } else if (
        newEndDate > companyEndDate &&
        newStartDate >= companyStartDate &&
        newStartDate <= companyEndDate
      ) {
        // update company, show out of frame dialog, CED = PED
        isProjectOutOfFrame = true;
        companyEndDate = newEndDate;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate < companyStartDate &&
        newEndDate
      ) {
        // update company, show out of frame dialog, CSD = PSD, CED = PED
        isProjectOutOfFrame = true;
        companyStartDate = newStartDate;
        companyEndDate = newEndDate;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate > companyStartDate &&
        !newEndDate
      ) {
        // update company, show out of frame dialog,  CED = PSD
        isProjectOutOfFrame = true;
        companyEndDate = newStartDate;
      } else if (
        companyStartDate &&
        !companyEndDate &&
        newStartDate > companyStartDate &&
        newEndDate
      ) {
        // update company, show out of frame dialog,  CED = PED
        isProjectOutOfFrame = true;
        companyEndDate = newEndDate;
      } else if (
        newStartDate < companyStartDate &&
        (!newEndDate ||
          (newEndDate >= companyStartDate && newEndDate <= companyEndDate))
      ) {
        // update company, show out of frame dialog, CSD = PSD
        isProjectOutOfFrame = true;
        companyStartDate = newStartDate;
      } else if (
        !newEndDate &&
        newStartDate >= companyStartDate &&
        newStartDate <= companyEndDate
      ) {
        // Add Project, no company update
        // isProjectAdd = true;
        isProjectOutOfFrame = true;
      } else if (!newStartDate && newEndDate) {
        setModalDateChecker("start-date");
        setModalDescription("Please select start date ");
        return;
      }
      if (isProjectOutOfFrame) {
        setCompanyData(comp);
        setCompanyStartDateNew(companyStartDate);
        setCompanyEndDateNew(companyEndDate);
        setUpdateModal("isProjectOutOfFrame");
        setUpdateModalBody(
          `This Project’s timeframe is outside of your time at ${companyName}. Would you like to update the timeframe?`
        );
        return;
      }
      if (!isProjectAdd) {
        setModalDateChecker("start-date");
        setModalDescription(
          `This Project's timeframe is outside of ${companyName}'s timeframe. Please, select correct timeframe.`
        );
        return;
      }
      await saveWorkHistory(
        companyDetail?.userCompanyId,
        companyId,
        roleId,
        tradeId,
        companyRoleId
      );
    } else {
      try {
        if (companyId > 0) {
          const companyObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                RoleId: roleId,
                CompanyId: companyId,
                StartDate: startDate
                  ? moment(startDate).format("YYYY-MM-DD")
                  : null,
                EndDate: isPresent
                  ? null
                  : endDate && endDate !== "Invalid date"
                    ? moment(endDate).format("YYYY-MM-DD")
                    : null,
                ModifiedBy: Number(localStorage.userId),
                CurrentCompany: isPresent,
                IncludeInResume: false,
              },
            ],
          };
          // const userCompaniesResponse = await saveUserCompanies(companyObj);
          // if (userCompaniesResponse[0].objData.id != "") {
          //     await saveWorkHistory(
          //         userCompaniesResponse[0].objData.id,
          //         companyId,
          //         roleId,
          //         tradeId
          //     );
          // }
          await saveWorkHistory(0, companyId, roleId, tradeId, companyRoleId);
        } else {
          await saveWorkHistory(0, companyId, roleId, tradeId, companyRoleId);
        }
      } catch (error) {
        console.log("SaveUserCompaniesError1:", error);
      }
    }
  };

  const onUpdatePressed = async (params) => {
    try {
      const companyObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: params?.data?.id,
            UserId: Number(localStorage.userId),
            RoleId: params?.data?.roleId,
            CompanyId: params?.data?.companyId,
            StartDate: companyStartDateNew
              ? moment(companyStartDateNew).format("YYYY-MM-DD")
              : null,
            endDate: isPresent
              ? null
              : companyEndDateNew && companyEndDateNew !== "Invalid date"
                ? moment(companyEndDateNew).format("YYYY-MM-DD")
                : null,
            ModifiedBy: Number(localStorage.userId),
            // CurrentCompany: isPresent
            //     ? params.currentCompany
            //     : params?.data?.currentCompany,
            CurrentCompany: params.currentCompany,

            IncludeInResume: params?.data?.includeResume
              ? params?.data?.includeResume
              : false,
            Description: params?.data?.description,
          },
        ],
      };
      const userCompaniesResponse = await saveUserCompanies(companyObj);
      if (userCompaniesResponse[0].objData.id != "") {
        await saveWorkHistory(
          userCompaniesResponse[0].objData.id,
          params?.data?.companyId,
          params?.data?.roleId ?? selectedRoleId,
          selectedTradeId,
          selectedCompanyRoleId
        );
      }
    } catch (error) {
      console.log("SaveCompaniesError2:" + error);
    }
  };
  //changes-07-03-24-rahul
  const saveWorkHistory = async (
    userCompanyId,
    selectedCompnayId,
    roleId,
    tradeId,
    companyRoleId
  ) => {
    try {
      setIsLoading(true);
      const ProductList = selectedProductList
        .map((product) => product.id)
        .join(",");

      const obj = {
        ReturnRecordId: true,
        ReturnRecordError: true,
        UserId: Number(localStorage.userId),
        SaveList: [
          {
            Id: props?.details?.id ?? 0,
            UserId: Number(localStorage.userId),
            StartDate:
              startDate && startDate != ""
                ? moment(startDate).format("YYYY-MM-DD")
                : null,
            EndDate: isPresent
              ? null
              : endDate && endDate != ""
                ? moment(endDate).format("YYYY-MM-DD")
                : null,
            Present: isPresent,
            IncludeInResume: includeInResume,
            ProjectId: props?.details?.projectId ?? projectId,
            description: description,
            ProjectCompanyRoleId: companyRoleId ?? selectedCompanyRoleId,
            CompanyId: selectedCompnayId ?? 0,
            RoleId: roleId ?? selectedRoleId,
            TradeId: tradeId ?? selectedTradeId,
            isFollowing: props?.isFollowing ?? false,
            ProductIdList: ProductList.length != 0 ? ProductList : "",
          },
        ],
      };
      await saveUsersWorkHistory(obj);
      await props?.onDataSave(props?.details?.projectId ?? projectId);
      getBuilderBucksCount();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("Error while saving user work history :: ", error);
    }
  };
  //changes-07-03-24-rahul

  const saveSelectedItem = async (name, option) => {
    try {
      if (option == "company") {
        const mainObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: true,
        };
        const saveObject = {
          ...mainObj,
          SaveList: [
            {
              Id: 0,
              Name: name,
              ModifiedBy: Number(localStorage.userId),
              RecordStatusId: 1,
              Manufacturer: false,
            },
          ],
        };
        const res = await saveCompanies2(saveObject);
        setCompanyName(name);
        return res[0]?.objData?.id;
      } else {
        const roleObj = {
          Id: 0,
          Name: name,
          CreatedBy: localStorage.userId,
          DateCreated: moment().format(),
          ModifiedBy: localStorage.userId,
          DateModified: moment().format(),
        };

        const roleId = await saveRole(roleObj);
        setSelectedRoleId(roleId);
        return roleId;
      }
    } catch (err) {
      console.log("Error while saving new data :: ", err);
    }
  };

  const saveNewTrade = async (name) => {
    try {
      const formData = new FormData();
      formData.append("Id", 0);
      formData.append("Name", name);
      formData.append("TradePictureUrl", "");
      formData.append("Description", "");
      formData.append("ModifiedBy", Number(localStorage?.userId));

      let res = await requestTrade(formData);
      let newSavedTradeId = res ? Number(res) : 0;
      setSelectedTradeId(newSavedTradeId);
      return res;
    } catch (error) {
      console.log("Error while saving trade :: ", error);
    }
  };

  const saveCompanies = async (companyId, roleId, tradeId) => {
    try {
      const companyObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            UserId: Number(localStorage.userId),
            RoleId: roleId ?? selectedRoleId,
            CompanyId: companyId,
            StartDate: startDate
              ? moment(startDate).format("YYYY-MM-DD")
              : null,
            endDate: isPresent
              ? null
              : endDate && endDate !== "Invalid date"
                ? moment(endDate).format("YYYY-MM-DD")
                : null,
            ModifiedBy: Number(localStorage.userId),
            CurrentCompany: isPresent,
            IncludeInResume: includeInResume,
          },
        ],
      };
      const userCompaniesResponse = await saveUserCompanies(companyObj);
      const companies = await getCompanies();
      seAllCompanies(companies);
      if (userCompaniesResponse[0].objData.id != "") {
        saveWorkHistory(
          userCompaniesResponse[0].objData.id,
          companyId,
          roleId,
          tradeId
        );
      }
    } catch (error) {
      console.log("SaveCompaniesError:", error);
    }
  };

  const onPresentChange = () => {
    setIsPresent(!isPresent);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    let getDate = moment(date).startOf("month");
    const newDate = moment(getDate).format("YYYY-MM-DD");
    setStartDate(newDate);
  };

  const handleEndDateChange = (date) => {
    let getDate = moment(date).startOf("month");
    const newDate = moment(getDate).format("YYYY-MM-DD");
    setEndDate(newDate);
  };

  const handleClearStartDate = (e) => {
    e.stopPropagation();
    setStartDate(null);
    setOpenDatePicker(false); // Close the date picker when clearing the date
  };

  const handleClearEndDate = (e) => {
    e.stopPropagation();
    setEndDate(null);
    setOpenEndDatePicker(false); // Close the date picker when clearing the date
  };

  const handleModalClose = () => {
    props.setShowExperienceDiscardModal(true);
  };

  const onModalClose = () => {
    setTimeout(() => {
      document.body.style.overflow = ""; // Reset overflow property after a delay
    }, 100); // Adjust the delay time as needed
    props?.onClose();
  };

  return (
    <>
      <PromptModal
        isOpen={props.showExperienceDiscardModal}
        onClose={() => {
          props?.setShowExperienceDiscardModal(false);
        }}
        title="Discard Changes"
        desc="This Site has not been saved to your Profile yet. Are you sure you want to leave this page?"
        redButtonText="Discard"
        blueButtonTextFisrt="Go Back"
        onRedButtonClick={() => onModalClose()}
      />

      <PromptModal
        isOpen={updateModal !== null}
        onClose={() => {
          setUpdateModal(null);
          setUpdateModalTitle(null);
          setUpdateModalBody(null);
        }}
        title={updateModalTitle}
        desc={updateModalBody}
        redButtonText="Cancel"
        // onRedButtonClick={props.onReturnClick}
        onRedButtonClick={() => {
          setUpdateModal(null);
          setUpdateModalTitle(null);
          setUpdateModalBody(null);
        }}
        blueButtonText="Update"
        onBlueButtonClick={async () => {
          let comp = allCompanies
            .filter((comp) => comp.companyId == companyDetail?.companyId)
            ?.pop();
          let obj = {
            data: comp,
            companyStartDateNew: companyStartDateNew,
            companyEndDateNew: companyEndDateNew,
            currentCompany: isPresent,
          };
          onUpdatePressed(obj);
          setUpdateModal(null);
          setUpdateModalTitle(null);
          setUpdateModalBody(null);
          // props.onReturnClick();
        }}
      />

      <AlertModal
        isOpen={modalDateChecker !== null}
        onClose={() => {
          setModalDateChecker(null);
          setModalDescription(null);
        }}
        body={modalDescription}
        expCloseIcon={true}
      />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
            height: "500px",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div className="flex-container-vertical">
            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "space-between", maxHeight: "500px" }}
            >
              <div>
                <IconButton color="primary" onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </div>

              <div>
                <IconButton color="primary" onClick={handleProjectSaveButton}>
                  <SaveIcon />
                </IconButton>
                {props?.details ? (
                  <IconButton
                    color="secondary"
                    onClick={() => setShowDeleteAlertModal(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <h2 style={{ paddingLeft: "10px" }}>
              {props?.details
                ? "Edit Experience on Profile"
                : "Add Experience to Profile"}
            </h2>
            <div
              style={{ height: "500px", overflowY: "auto" }}
              className="experience-form"
            >
              <div className="flex-container-vertical" style={{ flex: 1 }}>
                {/* //! for 3.9 version update */}
                <Stack direction={"column"} marginBottom={"10px"}>
                  <h5
                    style={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      marginBottom: "10px",
                    }}
                  >
                    Site/Project
                  </h5>
                  <Stack direction={"row"} gap={1}>
                    <img
                      style={{
                        height: "70px",
                        width: "70px",
                        border: "1px solid #CCCCCC",
                      }}
                      // new code sanket 02-04-2024
                      src={
                        typeof siteDetails?.mainPictureUrl === "undefined" ||
                          siteDetails?.mainPictureUrl === ""
                          ? typeof props?.pictureUrl === "undefined" ||
                            props?.pictureUrl === ""
                            ? defaultProjectImage
                            : props?.pictureUrl
                          : siteDetails?.mainPictureUrl !== "" &&
                            siteDetails?.mainPictureUrl
                            ? siteDetails?.mainPictureUrl
                            : defaultProjectImage
                      }
                    />
                    <div>
                      {projectName && (
                        <h4
                          style={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            marginBottom: "0px",
                          }}
                        >
                          {projectName}
                        </h4>
                      )}

                      <p
                        style={{
                          fontSize: "0.800rem",
                          color: "#8D8D8D",
                          margin: "0px",
                        }}
                      >
                        {siteDetails?.name ?? ""}
                      </p>
                      <p style={{ fontSize: "0.800rem", color: "#8D8D8D" }}>
                        {siteAddress
                          ? siteAddress?.address
                            ? siteAddress?.address.trim()
                            : siteAddress?.latitude !== 0 &&
                              siteAddress?.longitude !== 0 &&
                              siteAddress?.address == ""
                              ? `(${siteAddress?.latitude},${siteAddress?.longitude})`
                              : "No Location"
                          : "No Location"}
                      </p>
                    </div>
                  </Stack>
                </Stack>
                <Divider
                  style={{
                    margin: "0.5rem 0px",
                    height: "0.9px",
                    backgroundColor: "#cccccc73",
                  }}
                ></Divider>
                <p style={{ paddingLeft: "5px" }}>My Information</p>
              </div>
              <div>
                <div className="flex-container-horizontal" style={{ flex: 1 }}>
                  <div className="flex-container-vertical" style={{ flex: 1 }}>
                    <div style={{ margin: "7px" }}>
                      {/* new code sanket 20-03-2024 */}
                      <Autocomplete
                        inputValue={companyName}
                        disabled={
                          (props?.from === "experience" ||
                            props?.from === "companyProfile") &&
                            prevCompId != 0
                            ? true
                            : false
                        }
                        onChange={(event, newValue) => {
                          if (newValue && newValue?.inputValue) {
                            setCompanyName(newValue?.inputValue);
                            setCompanyId(0);
                            setCompanyDetail(null);
                            setFlag(false);
                          } else {
                            setCompanyId(newValue?.id);
                            if (
                              allCompanies?.some(
                                (comp) => comp?.companyId == newValue?.id
                              )
                            ) {
                              const comp = allCompanies.filter(
                                (c) => c?.companyId == newValue?.id
                              );
                              if (comp?.length > 0) {
                                let companyDetails = comp?.[0] ?? {};
                                setCompanyDetail({
                                  ...companyDetails,
                                  userCompanyId: companyDetails?.id,
                                });
                                setSelectedRoleId(companyDetails?.roleId ?? 0);
                                setSelectedRoleName(
                                  companyDetails?.roleName ?? ""
                                );
                              }
                            }
                            setFlag(false);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setCompanyName(newInputValue);
                          setCompanyId(0);
                          setCompanyDetail(null);
                          setFlag(true);
                          if (newInputValue !== "") {
                            getFilterCompanies(newInputValue);
                          } else {
                            getCompanies();
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;

                          const isExisting = options.some(
                            (option) => inputValue === option?.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        options={
                          props?.from == "experience" && prevCompId != 0
                            ? []
                            : isSearching
                              ? filterData
                              : companyData
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option?.inputValue) {
                            return option?.inputValue;
                          }
                          return option?.title;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option?.title}</li>
                        )}
                        style={{ flex: 1 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company"
                            // variant={"outlined"}
                            variant={
                              (props?.from === "experience" ||
                                props?.from === "companyProfile") &&
                                prevCompId != 0
                                ? "filled"
                                : "outlined"
                            }
                          />
                        )}
                        open={companyName?.length > 1 && flag}
                      />
                    </div>

                    <div style={{ margin: "7px" }}>
                      <Autocomplete
                        disabled={
                          props?.from === "experience" && prevCompRoleId != 0
                            ? true
                            : false
                        }
                        inputValue={selectedCompanyRoleName}
                        onChange={(event, newValue) => {
                          if (newValue && newValue?.inputValue) {
                            setSelectedCompanyRoleName(newValue?.inputValue);
                            setSelectedCompanyRoleId(0);
                            setCompanyRoleFlag(false);
                          } else {
                            setSelectedCompanyRoleId(newValue?.id);
                            setCompanyRoleFlag(false);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setSelectedCompanyRoleName(newInputValue);
                          setCompanyRoleFlag(true);
                          setSelectedCompanyRoleId(0);
                          if (newInputValue !== "") {
                            getFilterCompanyRoles(newInputValue);
                          } else {
                            getCompanyRoles();
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;

                          const isExisting = options?.some(
                            (option) => inputValue === option?.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        options={
                          isSearching
                            ? filterCompanyRolesData
                            : companyRolesData
                        }
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option?.inputValue) {
                            return option?.inputValue;
                          }
                          return option?.title;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option?.title}</li>
                        )}
                        style={{ flex: 1 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company Role"
                            variant={
                              props?.from === "experience" &&
                                prevCompRoleId != 0
                                ? "filled"
                                : "outlined"
                            }
                          />
                        )}
                        open={
                          selectedCompanyRoleName?.length > 1 && companyRoleFlag
                        }
                      />
                    </div>

                    <div style={{ margin: "7px" }}>
                      <Autocomplete
                        disabled={
                          props?.from === "experience" && prevRoleId != 0
                            ? true
                            : false
                        }
                        inputValue={selectedRoleName}
                        onChange={(event, newValue) => {
                          if (newValue && newValue?.inputValue) {
                            setSelectedRoleName(newValue?.inputValue);
                            setSelectedRoleId(0);
                            setRoleFlag(false);
                          } else {
                            setSelectedRoleId(newValue?.id);
                            setRoleFlag(false);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setSelectedRoleName(newInputValue);
                          setRoleFlag(true);
                          setSelectedRoleId(0);
                          if (newInputValue !== "") {
                            getFilterRoles(newInputValue);
                          } else {
                            getRoles();
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;

                          const isExisting = options.some(
                            (option) => inputValue === option?.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        options={isSearching ? filterRolesData : rolesData}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option?.inputValue) {
                            return option?.inputValue;
                          }
                          return option?.title;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option?.title}</li>
                        )}
                        style={{ flex: 1 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Title/Role"
                            // variant={"outlined"}
                            variant={
                              props?.from === "experience" && prevRoleId != 0
                                ? "filled"
                                : "outlined"
                            }
                          />
                        )}
                        open={selectedRoleName?.length > 1 && roleFlag}
                      />
                    </div>

                    <div style={{ margin: "7px" }}>
                      <Autocomplete
                        inputValue={selectedTradeName}
                        onChange={(event, newValue) => {
                          if (newValue && newValue?.inputValue) {
                            setSelectedTradeName(newValue?.inputValue);
                            setSelectedTradeId(0);
                            setTradeFlag(false);
                          } else {
                            setSelectedTradeId(newValue?.id);
                            setTradeFlag(false);
                          }
                        }}
                        onInputChange={(event, newInputValue) => {
                          setSelectedTradeName(newInputValue);
                          setTradeFlag(true);
                          setSelectedTradeId(0);
                          if (newInputValue !== "") {
                            getFilterTrades(newInputValue);
                          } else {
                            getTrades();
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;

                          const isExisting = options?.some(
                            (option) => inputValue === option?.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              title: `Add "${inputValue}"`,
                            });
                          }
                          return filtered;
                        }}
                        options={isSearching ? filterTradesData : tradesData}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option?.inputValue) {
                            return option?.inputValue;
                          }
                          return option?.title;
                        }}
                        renderOption={(props, option) => (
                          <li {...props}>{option?.title}</li>
                        )}
                        style={{ flex: 1 }}
                        freeSolo
                        renderInput={(params) => (
                          <TextField {...params} label="Trade" />
                        )}
                        open={selectedTradeName?.length > 1 && tradeFlag}
                      />
                    </div>
                  </div>
                  <div className="flex-container-vertical" style={{ flex: 1 }}>
                    <div style={{ margin: "7px" }}>
                      <div
                        className="flex-container-vertical"
                        style={{ alignItems: "stretch" }}
                      >
                        <KeyboardDatePicker
                          autoOk
                          open={openDatePicker} // controlled open state
                          onClose={() => setOpenDatePicker(false)} // close date picker on date selection
                          variant="inline"
                          inputVariant="outlined"
                          label="Start Date"
                          placeholder="Month-Year"
                          value={
                            startDate === null || startDate === "Invalid date"
                              ? null
                              : startDate
                          }
                          // value={startDate === null || startDate === "Invalid date" ? null : stringToLocale(startDate)}
                          views={["year", "month"]}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={(date) => handleStartDateChange(date)}
                          maxDate={moment(new Date()).format("YYYY-MM")}
                          InputProps={{
                            readOnly: true, // disable text field editing
                            onClick: () => setOpenDatePicker(true), // open date picker on text field click
                            endAdornment:
                              startDate !== "Invalid date" ? (
                                startDate != null ? (
                                  <IconButton
                                    style={{ order: 1 }}
                                    onClick={(e) => handleClearStartDate(e)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                ) : null
                              ) : null,
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ margin: "7px" }}>
                      <div
                        className="flex-container-vertical"
                        style={{ alignItems: "stretch" }}
                      >
                        <KeyboardDatePicker
                          autoOk
                          open={openEndDatePicker}
                          onClose={() => setOpenEndDatePicker(false)}
                          variant="inline"
                          inputVariant={isPresent ? "filled" : "outlined"}
                          label="End Date"
                          placeholder="Month-Year"
                          // format={!isPresent ? "YYYY-MM-DD" : ""}
                          value={
                            endDate === null || endDate === "Invalid date"
                              ? null
                              : !isPresent
                                ? endDate
                                : null
                          }
                          // value={endDate === null || endDate === "Invalid date" ? null : !isPresent ? stringToLocale(endDate) : null}
                          views={["year", "month"]}
                          disabled={isPresent}
                          InputAdornmentProps={{ position: "start" }}
                          onChange={(date) => handleEndDateChange(date)}
                          maxDate={today.format("YYYY-MM-DD")}
                          InputProps={{
                            // Only show "X" button if theres a date to clear
                            readOnly: true,
                            onClick: () => setOpenEndDatePicker(true),
                            endAdornment:
                              endDate !== "Invalid date" ? (
                                !isPresent && endDate != null ? (
                                  <IconButton
                                    style={{ order: 1 }}
                                    onClick={(e) => handleClearEndDate(e)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                ) : null
                              ) : null,
                          }}
                        />
                      </div>
                    </div>

                    <div style={{ margin: "7px" }}>
                      <TextField
                        fullWidth
                        minRows={4}
                        multiline
                        label="Description"
                        type="text"
                        value={description}
                        onChange={(e) => handleDescription(e)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <Checkbox
                    color="primary"
                    checked={isPresent}
                    onChange={() => onPresentChange()}
                  />
                  <span style={props?.project ? { color: "grey" } : {}}>
                    Present
                  </span>
                </div>
                <Divider
                  style={{
                    margin: "0.5rem 0px",
                    height: "0.9px",
                    backgroundColor: "#cccccc73",
                  }}
                ></Divider>
                <p style={{ paddingLeft: "5px", color: "GrayText" }}>Produts</p>
                <p style={{ color: "black", padding: "0px 5px 0px 5px" }}>
                  You can add any Materials or Tools you used while working on
                  this Project. They'll also appear on your Profile.
                </p>
                <div style={{ padding: "10px 0px" }}>
                  <TextField
                    fullWidth
                    minRows={3}
                    multiline
                    onClick={() => {
                      setShowSelectProductModal(true);
                    }}
                    onChange={() => {
                      setShowSelectProductModal(true);
                    }}
                    // 20-03-2024 chenges Yash
                    InputProps={{
                      startAdornment: (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "1050em",
                            gap: "10px",
                          }}
                        >
                          {selectedProductList.map((value) => (
                            <Chip
                              key={value.id}
                              label={value?.name}
                              onDelete={() => onProductClick(value)}
                            />
                          ))}
                        </div>
                      ),
                    }}
                  />
                  {showSelectProductModal && (
                    <SelectProductModal
                      open={showSelectProductModal}
                      onClose={() => {
                        setShowSelectProductModal(false);
                      }}
                      selectedProducts={selectedProductList}
                      // onProductClick={onProductClick}
                      onSelectClick={onSelectClick}
                      from={"experience"}
                      selectedProductsOriginalApiCall={
                        selectedProductsOriginalApiCall
                      }
                    />
                  )}
                </div>
                <p style={{ color: "GrayText", padding: "0px 5px 0px 5px" }}>
                  {props?.details
                    ? "This information can be updated at any time through your profile, or the Project page (My Experience tab)."
                    : "You can update this information from the My Sites tab or on your Profile."}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {showSelectTradeModal && (
        <SelectTradeModal
          open={showSelectTradeModal}
          onClose={() => {
            setShowSelectTradeModal(false);
          }}
          onItemClick={(item) => {
            setSelectedTradeId(item?.id ?? 0);
            setSelectedTradeName(item?.name ?? "");
            setShowSelectTradeModal(false);
          }}
        />
      )}

      {showSelectRoleModal && (
        <SelectRoleModal
          open={showSelectRoleModal}
          onClose={() => {
            setShowSelectRoleModal(false);
          }}
          onItemClick={(item) => {
            setSelectedRoleId(item?.id ?? 0);
            setSelectedRoleName(item?.name ?? "");
            setShowSelectRoleModal(false);
          }}
        />
      )}
      {showDeleteAlertModal && (
        <PromptModal
          isOpen={showDeleteAlertModal}
          onClose={() => setShowDeleteAlertModal(false)}
          title="Delete Experience"
          desc="Are you sure you want to delete this Experience from your Profile?"
          redButtonText="Yes"
          blueButtonTextFirst="No"
          onRedButtonClick={handleDeleteButtonClick}
        />
      )}
    </>
  );
};

export default ExperienceProjectForm;