import { Tab, Tabs, Typography } from "@mui/material";
import { ClientHeader } from "../ClientHeader";
import Loader from "../../../components/Loader";
import { useEffect, useState } from "react";
import { fetchClientActions } from "../../../api/ClientApi";
import { ClientActionsList } from "./ClientActionsList";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function ClientActions() {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("Received");
  const [actionsList, setActionsList] = useState([]);
  const tabsArray = ["Received", "Submitted"];
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleActionsClick = (item) => {
    history.push({
      pathname: `/client-actions-details/${item?.id}`,
      state: { activeTab }
    });
  };

  const getActionsList = async () => {
    try {
      setIsLoading(true);
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        SearchList: [
          activeTab === "Received"
            ? { EmployeeId: localStorage.clientEmployeeId }
            : { UserId: localStorage.userId },
        ],
      };
      const actionsRes = await fetchClientActions(obj);
      console.log("action Rsponse", actionsRes);
      setActionsList(actionsRes?.data);
      setIsLoading(false);
    } catch (err) {
      console.log("CLIENT ACTIONS ERROR ", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActionsList();
  }, [activeTab, clientSearchTerm]);

  return (
    <>
      <ClientHeader
        screenName={"Actions"}
        setClientSearchTerm={setClientSearchTerm}
        clientSearchTerm={clientSearchTerm}
      />
      <div className="page">
        <div className="page-content">
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            <div style={{ margin: "15px" }}>
              <div
                className="flex-container-horizontal"
                style={{ justifyContent: "space-between" }}
              >
                <Tabs
                  sx={{
                    ".Mui-selected": {
                      color: localStorage?.clientAccentColor,
                    },
                  }}
                  TabIndicatorProps={{
                    sx: { backgroundColor: localStorage?.clientAccentColor },
                  }}
                  value={activeTab}
                  onChange={handleTabChange}
                >
                  {tabsArray.map((tab, index) => (
                    <Tab
                      key={index}
                      value={tab}
                      label={tab}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        marginRight: "20px",
                      }}
                    />
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : actionsList?.length > 0 ? (
            actionsList?.map((item) => {
              return (
                <div onClick={() => handleActionsClick(item)}>
                  <ClientActionsList actionItem={item} activeTab={activeTab} />
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              {
                activeTab === 'Received'?
                (
                  <Typography variant="body">You do not have any received Actions.</Typography>
                ):(
                  <Typography variant="body">You have not submitted any Actions.</Typography>
                )
              }
            </div>
          )}
        </div>
      </div>
    </>
  );
}