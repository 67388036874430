import React, { useState, useRef, useCallback } from "react";
import {
  Card,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Checkbox,
  Typography,
  TextField,
  InputAdornment,
  Icon,
} from "@mui/material";
import { Container } from "@mui/material";
import { BIBlue, BIGrey, BILightGrey, BIRed } from "../../assets/buildidColors";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { MoreVert as MoreIcon, PinDrop } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import { useEffect } from "react";
import {
  fetchJobPosts,
  fetchUsersJobPosts,
  fetchUsersJobPostsDistance,
  getAddress,
  saveUsersJobPosts,
  sendUserResume,
  updateExpiredJobPosts,
} from "../../api/SitesApi";
import ReportModal from "../../components/ReportModal";
import { Chip, ListItemIcon, Modal } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import Loader from "../../components/Loader";
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
import { fetchTrades, fetchTradesJobPosts } from "../../api/TradesApi";
import {
  GoogleMap,
  Marker,
  Autocomplete as GooglePlacesAutocomplete,
} from "@react-google-maps/api";
import mapMarkerYellow from "../../assets/images/map-marker-yellow.png";
import NearMeIcon from "@mui/icons-material/NearMe";
import {
  BusinessCenter as BusinessCenterIcon,
  Build as BuildIcon,
} from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MyJobPosting from "./MyJobPosting";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon,
} from "@material-ui/icons";
import { FetchUsersResumes } from "../../api/ResumeBuilderApi";
import Placeholder from "../../components/Placeholder";
import UserUsages from "../../components/userUsages/UserUsages";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { LoadingButton } from "@mui/lab";
// import { ListItemButton } from '@mui/material';

export default function JobList() {
  const [activeJobArray, setActiveJobArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openTradeListModal, setOpenTradeListModal] = useState(false);
  const [tradeList, setTradeList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filteredActiveJob, setFilteredActiveJob] = useState([]);
  const [selectedTrades, setSelectedTrades] = useState([]);
  const [openChooseLocationModal, setOpenChooseLocationModal] = useState(false);
  const [locationButtonText, setLocationButtonText] = useState(
    "Using Current Location"
  );
  const [chooseLocation, setChooselocation] = useState({});
  const [showMyJob, setShowMyJob] = useState(false);
  const location = useLocation();
  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Please allow location from your browser");
        getCurrentLocation();
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        break;
    }
  };

  let lat = null;
  let lng = null;

  const currentLocation = async (position) => {
    lat = position?.coords?.latitude;
    lng = position?.coords?.longitude;
    setChooselocation({ lat: lat, lng: lng });
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(currentLocation, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  const getData = async () => {
    const { lat, lng } = chooseLocation;
    setIsLoading(true);
    await UserUsages("jobPosting");
    try {
      const obj = {
        UserId: Number(localStorage.userId),
        UserLatitude: lat,
        UserLongitude: lng,
        PageNumber: 1,
        NrRowsPerPage: 20,
      };
      const result = await fetchUsersJobPostsDistance(obj);
      const activeArray = [];
      const allData = [];
      for (const item of result.data) {
        const activeObj = {
          Id: item.id,
          ModifiedBy: Number(localStorage.userId),
        };
        const UpdateExpiredJobPosts = await updateExpiredJobPosts(activeObj);
        if (UpdateExpiredJobPosts) {
          const updatedItem = {
            ...UpdateExpiredJobPosts.data[0],
            distance: item.distance,
          };
          allData.push(updatedItem);
        }
      }

      for (const item of allData) {
        if (!item.isExpired && item.createdBy != Number(localStorage.userId)) {
          activeArray.push(item);
        }
      }
      const appliedObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ UserId: Number(localStorage.userId) }],
      };
      const appliedJobList = await fetchUsersJobPosts(appliedObj);

      let b = [];
      const appliedJobList1 = activeArray.map((temp) => {
        b = temp;
        b["isApplied"] = false;
        appliedJobList.map((applied) => {
          if (applied.jobPostId === temp.id) {
            b["isApplied"] = true;
          }
        });
        return b;
      });
      const filterList = appliedJobList1.sort((a, b) => {
        let dateA = a.distance,
          dateB = b.distance;
        return dateA - dateB;
      });
      console.log("filterList===========>",filterList)
      getDataPsoting();

      setActiveJobArray(filterList);
      setIsLoading(false);
    } catch (error) {
      console.log("ErrorFetchUsersJobPosts:", error?.message ?? error);
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    await getCurrentLocation();
    if (location?.state?.jobPostType === "jobPostExpired") {
      setShowMyJob(true);
    } else if (location?.state?.jobPostType === "jobPostActive") {
      setShowMyJob(true);
    }
    return () => {
      setChooselocation({});
    };
  }, []);

  useEffect(() => {
    getData();
  }, [chooseLocation]);

  async function getTrades() {
    const obj = {
      PageNr: 1,
      FullSearch: "",
      SearchList: [{}],
      IncludeRecordNr: false,
      TypeOfObjectReturned: "NameOnly",
      FetchAllowedRecordsOnly: true,
    };
    const trades = await fetchTrades(obj);
    const tradesData = trades?.data?.map((value) => {
      return { value: value.id, label: value.name, isChecked: false };
    });
    setTradeList(tradesData);
  }

  function handleChangeLocation() {
    setOpenChooseLocationModal(true);
  }

  const handleMyPostingsClick = () => {
    setShowMyJob(true);
  };

  useEffect(() => {
    getTrades();
  }, []);
  const [activeJobArrayMy, setActiveJobArrayMy] = useState([]);
  const [expiredJobArray, setExpiredJobArray] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  const getDataPsoting = async () => {
    setIsLoading(true);
    try {
      const obj = {
        PageNr: pageNo,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ createdBy: Number(localStorage.userId) }],
      };
      const result = await fetchJobPosts(obj);

      const updatedResult = result.map((item) => {
        const c = moment(new Date());
        const d = moment(item.dateCreated);
        const diffInDays = c.diff(d, "days");
        return { ...item, diffInDays };
      });

      const activeArray = result.filter(
        (item) =>
          moment().format("YYYY-MM-DD") <=
          moment(item.jobPostExpiryDate).format("YYYY-MM-DD")
      );
      const expiredArray = result.filter(
        (item) =>
          moment().format("YYYY-MM-DD") >
          moment(item.jobPostExpiryDate).format("YYYY-MM-DD")
      );

      setMainData(updatedResult);
      setPageNo(1);
      // setIsLoading(false);
      setActiveJobArrayMy(activeArray);
      setExpiredJobArray(expiredArray);
      setIsLoading(false);
    } catch (error) {
      console.log("ErrorFetchJobPosts:", error?.message ?? error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <TradeListModal
        openTradeListModal={openTradeListModal}
        setOpenTradeListModal={setOpenTradeListModal}
        setTradeList={setTradeList}
        tradeList={tradeList}
        activeJobArray={activeJobArray}
        setActiveJobArray={setActiveJobArray}
        setIsLoading={setIsLoading}
        setIsFilter={setIsFilter}
        setSelectedTrades={setSelectedTrades}
        setFilteredActiveJob={setFilteredActiveJob}
      />
      <ChooseLocationModal
        open={openChooseLocationModal}
        setOpenChooseLocationModal={setOpenChooseLocationModal}
        setChooselocation={setChooselocation}
        chooseLocation={chooseLocation}
        setLocationButtonText={setLocationButtonText}
        getData={getData}
      />
      {!showMyJob && (
        <>
          <div className="page rewards-page">
            <div className="header">
              <div className="page-content">
                <h1>Job Postings</h1>
              </div>
            </div>{" "}
          </div>
          <div className="page" style={{ alignItems: "center" }}>
            <Container>
              <div
                className="resp-container-horizontal"
                style={{ margin: "15px 0px 0px 0px", display: "flex" }}
              >
                <div className="flex-container-left">
                  <div className="flex-container-horizontal">
                    <div
                      className="input-container"
                      style={{ position: "relative" }}
                    >
                      {expiredJobArray.length > 0 && (
                        <FiberManualRecordIcon
                          style={{
                            position: "absolute",
                            top: "0px",
                            marginLeft: "110px",
                            height: "10px",
                            color: "red",
                            zIndex: 1,
                          }}
                        />
                      )}
                      <Button
                        variant="contained"
                        style={{
                          background: BIBlue,
                          color: "white",
                        }}
                        onClick={handleMyPostingsClick}
                      >
                        My Postings
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="flex-container-right">
                  <div className="flex-container-horizontal">
                    <div className="input-container">
                      <div
                        onClick={handleChangeLocation}
                        style={{
                          color: BIBlue,
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <RoomIcon variant="contained" fontSize="small" />
                        {locationButtonText}
                      </div>
                    </div>
                    <div
                      className={
                        selectedTrades.length > 0
                          ? "input-container"
                          : undefined
                      }
                    >
                      <IconButton
                        onClick={() => setOpenTradeListModal(true)}
                        style={
                          selectedTrades.length > 0
                            ? { color: "white", background: BIBlue }
                            : { color: BIBlue }
                        }
                      >
                        <ListOutlinedIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={'other-tab'}> */}
              {isLoading ? (
                <Loader />
              ) : (
                <List>
                  {isFilter ? (
                    filteredActiveJob.length > 0 ? (
                      filteredActiveJob.map((item) => {
                        return (
                          <ListSection
                            setOpenTradeListModal={setOpenTradeListModal}
                            item={item}
                          />
                        );
                      })
                    ) : (
                      <Placeholder
                        icon={<BusinessCenterIcon />}
                        text="There aren't any active Job Postings right now."
                      />
                    )
                  ) : (
                    activeJobArray.map((item) => {
                      return (
                        <ListSection
                          setOpenTradeListModal={setOpenTradeListModal}
                          item={item}
                          getCurrentLocation={getCurrentLocation}
                          getData={getData}
                        />
                      );
                    })
                  )}
                </List>
              )}
              {/* </div> */}
            </Container>
          </div>
        </>
      )}
      {showMyJob && (
        <MyJobPosting
          getData={getDataPsoting}
          expiredJobArray={expiredJobArray}
          activeJobArray={activeJobArrayMy}
          mainData={mainData}
          setMainData={setMainData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          jobPostType={location?.state?.jobPostType}
        />
      )}
    </>
  );
}

function ListSection(props) {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [applyJobModalOpen, setApplyJobModalOpen] = useState(false);
  const [applyJobData, setApplyJobData] = useState({});
  const [itemRecordId, setItemRecordId] = useState(0);
  const [reportModalOpen, setReportModalOpen] = useState(false);

  function handleReportClick(id) {
    setMoreMenuAnchorEl(false);
    setItemRecordId(id);
    setReportModalOpen(true);
  }

  async function handleJobItemClick(args) {
    setApplyJobData(args);
    setApplyJobModalOpen(true);
  }

  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <ReportModal
          isOpen={reportModalOpen}
          onClose={() => {
            setReportModalOpen(false);
          }}
          name={"Job Postings"}
          recordId={itemRecordId}
        />

        <ApplyJobModal
          open={applyJobModalOpen}
          setApplyJobModalOpen={setApplyJobModalOpen}
          applyJobData={applyJobData}
          getCurrentLocation={props.getCurrentLocation}
          getData={props.getData}
        />

        <Card style={{ background: props.item.isApplied && "#d8d8d8" }}>
          <ListItem
            secondaryAction={
              <>
                <IconButton
                  onClick={(event) => {
                    setMoreMenuAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreIcon />
                </IconButton>
                <Menu
                  anchorEl={moreMenuAnchorEl}
                  open={Boolean(moreMenuAnchorEl)}
                  onClose={(event) => {
                    setMoreMenuAnchorEl(false);
                  }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <MenuItem
                    onClick={() => handleReportClick(props.item?.id)}
                    style={{ color: BIRed }}
                    dense
                  >
                    Report
                  </MenuItem>
                </Menu>
              </>
            }
            disablePadding
          >
            <ListItemButton
              onClick={() => {
                handleJobItemClick(props?.item);
              }}
            >
              {/* in 3.8 primary is sitename */}
              {/* in 3.9 primary is projectname - curruntly 3.9 code*/}

              <ListItemText
                primary={
                  props.item?.projectName +
                  " " +
                  "(" +
                  props.item?.distance +
                  " km away)"
                }
                secondary={
                  <>
                    <p>{props.item?.companyName}</p>

                    <>
                      <span style={{ marginRight: "3px" }}>
                        <Chip label={props.item?.tradeName} />{" "}
                      </span>
                      <span>
                        {props.item?.jobDuration &&
                        props.item?.jobDurationType ? (
                          <Chip
                            label={
                              props.item?.jobDuration +
                              " " +
                              props.item?.jobDurationType.replace("(s)", "")
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </span>
                    </>
                  </>
                }
              />
            </ListItemButton>
          </ListItem>
        </Card>
      </div>
    </>
  );
}

function TradeListModal(props) {
  function handleTradeItemClick(index) {
    const newList = props.tradeList.map((item) => {
      if (item.value == index) {
        if (item.isChecked) return { ...item, isChecked: false };
        else return { ...item, isChecked: true };
      }
      return item;
    });
    props.setTradeList(newList);
  }

  async function closeTradesFilter() {
    props.setOpenTradeListModal(false);
    props.setIsLoading(true);
    const selectedTrades = props.tradeList
      .filter((item) => item.isChecked)
      .map((item) => item.value);
    props.setSelectedTrades(selectedTrades);
    try {
      if (selectedTrades.length != 0) {
        const tradesJobObj = {
          Trades: selectedTrades,
        };
        const result = await fetchTradesJobPosts(tradesJobObj);
        let filteredActiveJobs = [];
        filteredActiveJobs = props.activeJobArray.filter((job) => {
          return result?.data.some((result) => result.tradeId === job.tradeId);
        });
        props.setIsFilter(true);
        props.setFilteredActiveJob(filteredActiveJobs);
      } else {
        props.setIsFilter(false);
      }
      props.setIsLoading(false);
    } catch (error) {
      console.log("Error : FetchTradesJobPosts:", error);
      props.setIsLoading(false);
    }
  }
  return (
    <>
      <Modal open={props.openTradeListModal} className="add-job-modal">
        <div className="modal-center timesheet-modal">
          <div className="modal-card">
            <div className="modal-card-header-buttons">
              <IconButton
                style={{ color: BIBlue }}
                onClick={() => closeTradesFilter()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="job-list">
              {props?.tradeList.map((option, index) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.label}
                    onClick={() => handleTradeItemClick(option.value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        style={{ color: BIBlue }}
                        checked={option.isChecked}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option.label} />
                  </MenuItem>
                );
              })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ChooseLocationModal(props) {
  const [map, setMap] = useState();
  const [isCurrentLocationPressed, setIsCurrentLocationPressed] =
    useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [autocomplete, setAutocomplete] = useState(null); // Google Places Autocomplete API instance
  const [center, setCenter] = useState(null);
  const [mapTypeId, setMapTypeId] = useState(0);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [selectedMapType, setSelectedMapType] = useState("default");

  const mapTypes = [
      { id: 0, label: "Default" },
      { id: 1, label: "Hybrid" },
      { id: 2, label: "Satellite" },
      { id: 3, label: "Terrain" },
  ];

  const containerStyle = {
    width: "100%",
    height: "450px",
    borderRadius: "10px",
  };
  const mapRef = useRef(null);

  const handleMapDrag = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter(); // Get the center of the map
      const lat = center.lat();
      const lng = center.lng();
      props.setChooselocation({
        lat: Number(lat),
        lng: Number(lng),
      });
      setCenter({
        lat: Number(lat),
        lng: Number(lng),
      });
    }
    setIsCurrentLocationPressed(false);
  };

  const selectLocationcenter = (e) => {
    props.setChooselocation({
      lat: Number(e.latLng.lat()),
      lng: Number(e.latLng.lng()),
    });
  };

  const mapLoad = (map) => {
    mapRef.current = map;
    setMap(map);
  };

  const currentLocation = async (position) => {
    var lat = position?.coords?.latitude;
    var lng = position?.coords?.longitude;
    props.setChooselocation({
      lat: Number(lat),
      lng: Number(lng),
    });
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        getCurrentLocation();
        break;
      case error.POSITION_UNAVAILABLE:
        break;
      case error.TIMEOUT:
        break;
      case error.UNKNOWN_ERROR:
        break;
      default:
        break;
    }
  };

  const getCurrentLocation = () => {
    // props.setLocationButtonText("Using Current Location");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(currentLocation, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  async function handleChooseLocationClick() {
    props.setOpenChooseLocationModal(false);
    setSearchTerm("");
    const address = await getAddress({
      latitude: props.chooseLocation?.lat,
      longitude: props.chooseLocation?.lng,
    });

    if (!isCurrentLocationPressed)
      props.setLocationButtonText(address.objAddress?.postalCode);
    else props.setLocationButtonText("Using Current Location");
    // props.getData();
  }

  const onLoadAuto = (newInstance) => {
    // Access the suggestion list container and add a custom class
    setAutocomplete(newInstance);
  };

  const handleSearch = async () => {
    let place = autocomplete.getPlace();
    if (place?.geometry) {
      setSearchTerm(place.formatted_address);
      props.setChooselocation({
        lat: Number(place.geometry.location.lat()),
        lng: Number(place.geometry.location.lng()),
      });
    }
  };

  const handleBoundsChanged = () => {
    const mapCenter = mapRef.current.getCenter(); //get map center
    setCenter(mapCenter);
  };

  useEffect(() => {
    if (typeof map !== "undefined") {
      getCurrentLocation();
    }
  }, [map]);

  return (
    <>
      <Modal className="choose-opt choose-location" open={props.open}>
        <div className="modal-center">
          <div className="modal-card chooseopt-card location-card">
            <div className="header-section">
              <Typography
                className="addsite-title"
                style={{ fontSize: "16px" }}
                variant="h6"
                component="h2"
              >
                Choose Location
              </Typography>
              <IconButton
                style={{ color: BIBlue }}
                onClick={() => {
                  props.setOpenChooseLocationModal(false);
                  setAutocomplete("");
                  setIsCurrentLocationPressed(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ position: "relative",}}>
              <GoogleMap
                options={{ fullscreenControl: false, zoomControl: false, streetViewControl: false, }}
                center={props.chooseLocation}
                zoom={20}
                mapContainerStyle={containerStyle}
                onDragEnd={handleMapDrag}
                onClick={(e) => selectLocationcenter(e)}
                onLoad={mapLoad}
                ref={mapRef} // Assign the reference to the map
                onBoundsChanged={useCallback(handleBoundsChanged)}
                mapTypeId={
                  mapTypeId == 0
                    ? "roadmap"
                    : mapTypeId == 1
                        ? "hybrid"
                        : mapTypeId == 2
                            ? "satellite"
                            : mapTypeId == 3
                                ? "terrain"
                                : ""
                }>
                <div
                  style={{
                    margin: "10px",
                    position: "relative",
                    zIndex: "9999",
                    width: "780px",
                    borderRadius: "5px",
                    display:'flex',
                    justifyContent:'space-between'
                  }}
                >
                  <GooglePlacesAutocomplete
                    onLoad={(newInstance) => onLoadAuto(newInstance)} // Sets instance of Autocomplete to be referenced
                    onPlaceChanged={handleSearch}
                    options={{
                      componentRestrictions: { country: ["US", "CA", "GB"] }, // Restrict to USA, Canada, and UK
                    }}
                  >
                    <TextField
                      sx={{ backgroundColor:'white', }}
                      variant="outlined"
                      placeholder="Search map"
                      fullWidth
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                      InputProps={{
                        endAdornment: (
                          <div>
                            <InputAdornment position="start">
                              {searchTerm !== "" ? (
                                <IconButton onClick={() => setSearchTerm("")}>
                                  <CloseIcon />
                                </IconButton>
                              ) : (
                                <></>
                              )}
                            </InputAdornment>
                          </div>
                        ),
                        startAdornment: (
                          <SearchIcon
                            style={{ color: BIGrey, marginRight: "10px" }}
                          />
                        ),
                      }}
                    />
                  </GooglePlacesAutocomplete>
                  <IconButton
                      className="add-iconbtn1"
                      color="primary"
                      onClick={(event) => {
                          setMoreMenuAnchorEl(event.currentTarget);
                      }}>
                      <FilterNoneIcon sx={{ fontSize: "25px", color: BIBlue }} />
                  </IconButton>
                </div>
                <Menu
                    anchorEl={moreMenuAnchorEl}
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={(event) => {
                        setMoreMenuAnchorEl(false);
                    }}
                    sx={{ position: 'absolute', left: '-60px' }}>
                    {mapTypes.map((mapType) => (
                        <MenuItem
                            key={mapType.id}
                            onClick={() => {
                                setMapTypeId(mapType.id);
                                setSelectedMapType(mapType.label.toLowerCase());
                                setMoreMenuAnchorEl(null);
                            }}
                            style={{
                                backgroundColor:
                                    selectedMapType === mapType.label.toLowerCase()
                                        ? "#083DB8"
                                        : "inherit",
                                color:
                                    selectedMapType === mapType.label.toLowerCase()
                                        ? "#fff"
                                        : "#083DB8",
                            }}>
                            {mapType.label}
                        </MenuItem>
                    ))}
                </Menu>
                {/* <Marker
                  // position={props.chooseLocation}
                  position={center}
                  icon={{
                    url: mapMarkerYellow,
                    scaledSize: new window.google.maps.Size(30, 40),
                  }}
                /> */}
                <div className="chooseloc-footer">
                  <p
                    className="currentloc"
                    onClick={() => {
                      getCurrentLocation();
                      setIsCurrentLocationPressed(true);
                    }}
                  >
                    {!isCurrentLocationPressed && (
                      <NearMeIcon style={{ color: "#083DB8" }} />
                    )}
                    <span
                      style={{
                        fontSize: "16px",
                        color: isCurrentLocationPressed && BIGrey,
                      }}
                    >
                      {isCurrentLocationPressed
                        ? "Using Current Location"
                        : "Use Current location"}
                    </span>
                  </p>
                  <Button
                    className="chooseloc-btn"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleChooseLocationClick();
                      setIsCurrentLocationPressed(true);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </GoogleMap>
              <img
                style={{
                  position: "absolute",
                  top: "45%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  // height: "40px",
                  width: "30px",
                }}
                src={mapMarkerYellow}
                alt="this is "
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ApplyJobModal(props) {
  const [openResumeListModal, setOpenResumeListModal] = useState(false);

  const containerStyle = {
    width: "100%",
    height: "150px",
    border: "1px solid #000000d6",
    marginBottom: "14px",
  };

  return (
    <>
      <ResumeList
        open={openResumeListModal}
        setOpenResumeListModal={setOpenResumeListModal}
        applyJobData={props.applyJobData}
        closeApplyJobModal={() => props.setApplyJobModalOpen(false)}
        getCurrentLocation={props.getCurrentLocation}
        getData={props.getData}
      />

      <Modal open={props.open} className="add-job-modal">
        <div className="modal-center timesheet-modal">
          <div className="modal-card">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h3>Apply Job</h3>
              </div>
              <div className="modal-card-header-buttons">
                <IconButton
                  style={{ color: BIBlue }}
                  onClick={() => props.setApplyJobModalOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <span style={{ display: "flex" }}>
              <h3>{props.applyJobData?.projectName + " "}</h3>
              <p style={{ marginTop: "5px" }}>
                {"(" + props.applyJobData?.distance + " km away)"}
              </p>
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BusinessCenterIcon
                style={{ color: BIBlue, marginRight: "5px" }}
              />
              <span>
                <p>{props.applyJobData?.companyName}</p>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <BuildIcon style={{ color: BIBlue, marginRight: "5px" }} />
              <span>
                <p>{props.applyJobData?.tradeName}</p>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <WatchLaterIcon style={{ color: BIBlue, marginRight: "5px" }} />
              <span>
                <p>
                  Approx:{" "}
                  {props.applyJobData?.jobDuration +
                    " " +
                    props.applyJobData?.jobDurationType?.replace("(s)", "")}
                </p>
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <PinDrop style={{ color: BIBlue, marginRight: "5px" }} />
              <span>
                <p>
                  <p>
                    <b>{props.applyJobData?.siteName}</b>
                  </p>
                  {props.applyJobData && (
                    <>
                      {props.applyJobData.address && (
                        <>{props.applyJobData.address},</>
                      )}
                      {props.applyJobData.city && (
                        <>{props.applyJobData.city},</>
                      )}
                      {props.applyJobData.province && (
                        <>{props.applyJobData.province},</>
                      )}
                      {props.applyJobData.country && (
                        <>{props.applyJobData.country}</>
                      )}
                    </>
                  )}
                </p>
              </span>
            </div>
            <div style={{ marginTop: "15px", pointerEvents: "none" }}>
              <GoogleMap
                options={{ fullscreenControl: false }}
                center={{
                  lat: props.applyJobData?.latitude,
                  lng: props.applyJobData?.longitude,
                }}
                zoom={13}
                mapContainerStyle={containerStyle}
                draggable={false}
              >
                <Marker
                  position={{
                    lat: props.applyJobData?.latitude,
                    lng: props.applyJobData?.longitude,
                  }}
                  icon={{
                    url: mapMarkerYellow,
                    scaledSize: new window.google.maps.Size(30, 40),
                  }}
                />
              </GoogleMap>
            </div>
            <Button
              variant="contained"
              style={{
                marginTop: "15px",
                background: props.applyJobData?.isApplied
                  ? "lightgrey"
                  : BIBlue,
                color: "white",
              }}
              fullWidth
              disabled={props.applyJobData?.isApplied}
              onClick={() => setOpenResumeListModal(true)}
            >
              {props.applyJobData?.isApplied ? "Applied" : "Apply"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ResumeList(props) {
  const [resumes, setResumes] = useState([]);
  const [resources, setResources] = useState("");
  const [useNewResume, setUseNewResume] = useState(false);
  const [currentOpenPdf, setCurrentOpenPdf] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  async function getData() {
    const fetchResumeObj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: Number(localStorage.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ UserId: Number(localStorage.userId) }],
    };
    const result = await FetchUsersResumes(fetchResumeObj);

    if (result?.data?.length > 0) {
      setResumes(result?.data);
      setResources(result[0]?.resumePdf);
      // setIsLoading(false);
      // pdfRef.current?.reload();
    }
    // setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, [props.applyJobData]);

  const submitResume = async () => {
    setIsLoading(true);
    try {
      const obj = {
        UserId: localStorage.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            JobPostId: Number(props.applyJobData.id),
            UserId: Number(localStorage.userId),
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await saveUsersJobPosts(obj);

      const emailObj = {
        UserId: Number(localStorage.userId),
        Email: props.applyJobData.email,
        ResumePdf: resources,
        TradeId: props.applyJobData.tradeId,
        CompanyId: props.applyJobData.companyId,
      };
      const sendResumeRes = await sendUserResume(emailObj);
      props.setOpenResumeListModal(false);
      props.closeApplyJobModal();
      props.getCurrentLocation();
      // props.getData();
      setResources("");
      setResumes([]);
      setIsLoading(false);
    } catch (error) {
      console.log("SEND_EMAIL_ERROR", error);
    }
  };

  const handleCheckboxChange = () => {
    setResources(resumes[resumes?.length - 1]?.resumePdf);
    if (resumes.length > 0) {
      if (currentOpenPdf === resumes?.length - 1) {
        setUseNewResume(false);
        setCurrentOpenPdf(undefined);
      } else {
        setUseNewResume(true);
        setCurrentOpenPdf(resumes?.length - 1);
      }
    }
  };
  return (
    <>
      <Modal open={props.open} className="add-job-modal">
        <div
          className="modal-center timesheet-modal"
          style={{ width: "900px" }}
        >
          <div className="modal-card">
            <div
              className="modal-card-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="modal-card-header-buttons">
                <IconButton
                  style={{ color: BIBlue }}
                  onClick={() => props.setOpenResumeListModal(false)}
                >
                  <CloseIcon />
                </IconButton>
              </div>

              <div>
                <h2>Apply</h2>
              </div>
            </div>
            {resumes.length === 0 ? (
              <Placeholder
                icon={<FileCopyIcon />}
                text={`There aren't any saved Resumes right now,`}
                text2={"Please add new Resume before applying any job post."}
              />
            ) : (
              <div className="job-list">
                {resumes.map((item, key) => {
                  return (
                    <div>
                      <ListItem
                        onClick={() => {
                          setResources(item.resumePdf);
                          if (currentOpenPdf == key) {
                            setResources("");
                            setCurrentOpenPdf(undefined);
                          } else {
                            setResources(item.resumePdf);
                            setCurrentOpenPdf(key);
                          }
                        }}
                        secondaryAction={
                          <>
                            <IconButton
                              style={{ color: BIBlue }}
                              onClick={handleCheckboxChange}
                            >
                              {currentOpenPdf == key ? (
                                <CollapseIcon
                                  style={{ fontSize: 22, color: "grey" }}
                                />
                              ) : (
                                <ExpandIcon
                                  style={{ fontSize: 22, color: "grey" }}
                                />
                              )}
                            </IconButton>
                          </>
                        }
                        disablePadding
                        className="list-item"
                      >
                        <ListItemButton>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <ListItemText
                              primary={item?.title}
                              secondary={new Date(
                                item?.resumeDate
                              ).toLocaleDateString(undefined, {
                                month: "short",
                                day: "numeric",
                              })}
                            />
                          </div>
                        </ListItemButton>
                      </ListItem>
                      {currentOpenPdf == key && (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p>Preview</p>
                            </div>
                            <div>
                              <Checkbox
                                style={{ color: BIBlue }}
                                value={1}
                                checked={true}
                              />
                            </div>
                          </div>

                          <iframe
                            width={"100%"}
                            height={"280px"}
                            allowFullScreen
                            src={item.resumePdf}
                            title="Resume"
                            allowTransparency
                          ></iframe>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

            <Divider>Or</Divider>
            <div style={{ marginTop: "15px", bottom: "0" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  alignItems: "center",
                }}
              >
                <div>
                  <div style={{ marginLeft: "10px" }}>Use new Resume</div>
                </div>
                <div>
                  <Checkbox
                    style={{
                      color: resumes.length === 0 ? BILightGrey : BIBlue,
                    }}
                    checked={currentOpenPdf === resumes.length - 1}
                    disabled={resumes.length === 0}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <div
                className="modal-card-footer"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <LoadingButton
                  variant="contained"
                  style={{
                    background:
                      currentOpenPdf == undefined ? BILightGrey : BIBlue,
                    color: "white",
                    width: "150px",
                  }}
                  disabled={currentOpenPdf == undefined ? true : false}
                  onClick={submitResume}
                  loading={isLoading}
                  loadingPosition="start"
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
