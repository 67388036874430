import React, { useState, useEffect, useCallback, useRef } from "react";
import { Container, Stack } from "@mui/material";
import ProfileHeader from "../../components/profile/ProfileHeader";
import AboutSection from "../../components/profile/AboutSection";
import Post from "../../components/post/Post";
import {
  getProductMembers,
  fetchProducts,
  fetchProductPictures,
  fetchProductReviews,
  fetchUserProducts,
  saveUserProducts,
  deleteUserProducts,
  saveProducts,
  fetchTradeProducts,
  fetchProductsPostsPictures,
  fetchProductsFavoriteImages,
  deleteProductTrade,
} from "../../api/ProductsApi";
import {
  fetchFollowEntities,
  getSitesForProduct,
  saveFollowEntities,
  saveSitePostPicture,
  saveSitePosts,
  saveUsersExperiencesProducts,
} from "../../api/SitesApi";
import { getPosts } from "../../api/PostsApi";
import Review from "../../components/review/Review";
import PostInput from "../../components/post/PostInput";
import ReviewInput from "../../components/review/ReviewInput";
import WorkIcon from "@mui/icons-material/Work";
import ListSection from "../../components/profile/ListSection";
import { fetchSitesPictures } from "../../api/SitesApi";
import defaultProjectProfileThumbnail from "../../assets/images/project-profile-placeholder-thumbnail.png";
import PromptModal from "../../components/PromptModal";
import MemberListModal from "../../components/profile/MemberListModal";
import {
  fetchCompaniesProducts,
  saveCompanies2,
  saveCompanyProduct,
} from "../../api/CompanyApi";
import CompanyListSection from "../../components/profile/CompanyListSection";
import { useHistory } from "react-router-dom";
import { LocationOn as MapMarkerIcon } from "@material-ui/icons";
import ProfilePic from "../../components/profile/ProfilePicListSection";
import AlertModal from "../../components/AlertModal";
import Loader from "../../components/Loader";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import { saveChatGroupUsers } from "../../api/ChatApi";
import { fetchUserWorkHistory } from "../../api/UserApi";
import { fetchProjectProducts } from "../../api/ProjectsApi";
import Placeholder from "../../components/Placeholder";
import ChatIcon from "@mui/icons-material/Chat";
import ReportModal from "../../components/ReportModal";

/*
Product Profile Page
	- About
	- Posts
	- Reviews
	- Sites
	- Companies (coming soon)
	- Barcodes
*/
export default function ProductProfile(props) {
  const userId = localStorage.userId;
  const appId = 4; // 4 is the magic number for Sites
  const [productInfo, setProductInfo] = useState({});
  const [members, setMembers] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [activeTab, setActiveTab] = useState("posts");
  const [posts, setPosts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [productTrades, setProductTrades] = useState([]);
  const history = useHistory();
  const [currentProduct, setCurrentProduct] = useState([]);
  const [isFollow, setIsFollow] = useState(false);
  const [followId, setFollowId] = useState(0);
  const [isAddToProfile, setIsAddToProfile] = useState(false);
  const [productSectionAlert, setProductSectionAlert] = useState(false);
  const [removeProductSectionAlert, setRemoveProductSectionAlert] =
    useState(false);
  const [memberListModalOpen, setMemberListModalOpen] = useState(false);
  const [isSiteEmpty, setIsSiteEmpty] = useState(false);
  const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
  const [postPictures, setPostPictures] = useState([]);
  const fileInputRef = useRef(null);
  const [imageLoading, setImageLodaing] = useState(false);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [getUpdatedImages, setGetUpdatedImages] = useState(false);
  const [followLoader, setFollowLoader] = useState(false);
  const [getUpdatedData, setGetUpdatedData] = useState(false);
  const [showBtnLoader, setShowBtnLoader] = useState(false);

  const [isProjectEmpty, setIsProjectEmpty] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [postId, setPostId] = useState(0);
  const [reportPostName, setRepostPostName] = useState("");
  //update rating after one has been posted + on load

  useEffect(async () => {
    try {
      const fetchData = async () => {
        await fetchProductDetails();
        await fetchPictures();
        await fetchProductPictureList();
        await fetchUserProductList();
        await fetchProductMemberList();
        await updatePosts();
        await updateRatings();
        await fetchFollowsEntity();
        await getProductTradeList();
        await setShowLoader(false);
      };
      if (props?.productId) {
        fetchData();
      }
    } catch (err) {
      console.log("Error while getting details :: ", err);
      setShowLoader(false);
    }
  }, []);

  const updateRatings = useCallback(() => {
    fetchProductReviews({
      SearchList: [
        {
          ProductId: Number(props.productId),
          UserId: localStorage.userId,
        },
      ],
    }).then((res) => {
      if (res) {
        setReviews(res.data.reverse());
      }
    });
  }, []);

  const updatePosts = useCallback(() => {
    getPosts(appId, props.productId, 1, 25).then((res) => {
      if (res) {
        setPosts(res);
      }
    });
  }, []);

  const fetchProductDetails = async () => {
    try {
      const res = await fetchProducts({
        SearchList: [{ id: props.productId }],
      });
      setActiveTab(res?.data?.[0]?.manufacturerName == "" ? "photos" : "posts");
      return (
        res?.data?.length > 0 &&
        setProductInfo(res?.data?.[0]) &&
        setActiveTab(
          res?.data?.[0]?.manufacturerName == "" ? "photos" : "posts"
        )
      );
    } catch (err) {
      console.log("Error while getting product details :: ", err);
    }
  };

  const fetchProductPictureList = async () => {
    try {
      const res = await fetchProductPictures({
        SearchList: [{ id: props.productId }],
      });
      return res?.data && setPictures(res?.data);
    } catch (err) {
      console.log("Error while getting product picture list :: ", err);
    }
  };

  const fetchUserProductList = async () => {
    try {
      const res = await fetchUserProducts({
        SearchList: [{ UserId: localStorage.userId }],
      });
      const userProduct = res.data?.filter(
        (product) => product.productId === Number(props.productId)
      );
      setCurrentProduct(userProduct);
      if (userProduct.length != 0) {
        setIsAddToProfile(true);
      } else {
        setIsAddToProfile(false);
      }
    } catch (err) {
      console.log("Error while getting product picture list :: ", err);
    }
  };

  const fetchProductMemberList = async () => {
    try {
      const res = await getProductMembers(props.productId);
      const isProductMember = res.some(
        (member) => member.userId === Number(localStorage.userId)
      );
      setIsMember(isProductMember);
      setMembers(res);
    } catch (err) {
      console.log("Error while getting product picture list :: ", err);
    }
  };

  async function getProductSites() {
    const res = await getSitesForProduct(props.productId);
    const productArray = [];
    productArray["data"] = res;

    // Overwrite API response res.data with an updated array where the duplicate items are omitted
    let uniqueProducts = [];
    productArray.data?.forEach((project) => {
      let alreadyInArray = false;
      uniqueProducts.forEach((uniqueProduct) => {
        if (project.id === uniqueProduct.id) alreadyInArray = true;
      });
      if (!alreadyInArray) uniqueProducts.push(project);
    });

    // Retrieve Project pictures and use the first one as the list thumbnail
    // If there is no pictures for a Project, use the default image for Sites
    await Promise.all(
      uniqueProducts.map((project) =>
        fetchSitesPictures({ SearchList: [{ SiteId: project.id }] }).then(
          (res) => {
            if (res.length > 0) project.image = res[0].thumbImageUrl;
            else project.image = defaultProjectProfileThumbnail;
          }
        )
      )
    );

    res.data = uniqueProducts;
    setIsSiteEmpty(uniqueProducts?.length === 0 ? true : false);
    return res;
  }

  const fetchProjectsForAProduct = async () => {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 0,
      FullSearch: "",
      UserId: Number(localStorage?.userId),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [{ productId: Number(props.productId) }],
      // SortList: [{}],
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };
    try {
      setIsLoading(true);
      const res = await fetchProjectProducts(obj);
      setIsProjectEmpty(res?.data?.length === 0 ? true : false);
      return res;
    } catch (err) {
      setIsLoading(false);
      console.log("error while fetching projects for a company. ", err);
    }
  };

  const getProductTradeList = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [{ ProductId: Number(props.productId) }],
      };
      await fetchTradeProducts(obj)
        .then((res) => {
          setProductTrades(res?.data ?? []);
        })
        .catch((err) => {
          console.log("Error while getting the product trades list:-", err);
        });
    } catch (error) {
      console.log("Error while getting the product trades list:-", error);
    }
  };

  async function fetchFollowsEntity() {
    try {
      setFollowLoader(true);
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            EntityId: Number(props.productId),
            userId: Number(localStorage.userId),
          },
        ],
      };
      const followingData = await fetchFollowEntities(obj);
      if (followingData.length != 0) {
        setFollowId(followingData.data[0]?.id ?? 0);
        setIsFollow(followingData.data[0]?.isFollowing);
      } else {
        setFollowId(0);
        setIsFollow(false);
      }
      setFollowLoader(false);
    } catch (Error) {
      console.log("GET_Follow Entity [ERROR]::", Error);
      setFollowLoader(false);
    }
  }

  async function handleSaveFollowClick(isFollow) {
    try {
      setFollowLoader(true);
      const saveEntitiesObject = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: followId,
            EntityTypeId: 4, // 4 < -- Product
            EntityId: Number(props.productId),
            UserId: Number(localStorage.userId),
            IsFollowing: isFollow,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await saveFollowEntities(saveEntitiesObject);
      fetchProductMemberList();
      fetchFollowsEntity();
      setFollowLoader(false);
    } catch (e) {
      console.log("SAVE_FOLLOW__[ERROR]:", e);
      setFollowLoader(false);
    }
  }

  async function fetchPictures() {
    try {
      const postObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ appRecordId: Number(props?.productId ?? 0) }],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };
      const productPostPictures = await fetchProductsPostsPictures(postObj);
      const favObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            ProductId: Number(props?.productId ?? 0),
            ModifiedBy: Number(localStorage?.userId),
          },
        ],
      };
      const productFavImages = await fetchProductsFavoriteImages(favObj);
      let b = [];
      const productWithFavImages = productPostPictures?.data?.map((fav) => {
        b = fav;
        b["isFavorite"] = false;
        productFavImages?.data?.map((img) => {
          if (img.productPictureId === fav.pictureId) {
            b["isFavorite"] = true;
          }
        });

        return b;
      });
      setPostPictures(productWithFavImages);
      setImageLodaing(false);
      return productWithFavImages;
    } catch (error) {
      console.log("GET Product_Posts_Pictures [ERROR]::", error);
      setImageLodaing(false);
    }
  }

  function handleFollowPressed() {
    handleSaveFollowClick(true);
  }

  function handleFollowingPressed() {
    setProductSectionAlert(true);
  }

  async function handleAddToProfileButton() {
    try {
      setFollowLoader(true);
      const newUserProduct = {
        ReturnRecordId: false,
        ReturnRecordError: true,
        UserId: Number(localStorage.userId),
        SaveList: [
          {
            Id: 0,
            UserId: Number(localStorage.userId),
            ProductId: Number(props.productId),
            ModifiedBy: Number(localStorage.userId),
            UserExperienceId: 0,
            ProductIdList: Number(props.productId),
          },
        ],
      };
      // await saveUserProducts(newUserProduct);
      await saveUsersExperiencesProducts(newUserProduct);
      await fetchUserProductList();
      setIsAddToProfile(true);
      handleSaveFollowClick(true);
      setFollowLoader(false);
    } catch (error) {
      setFollowLoader(false);
      console.log("Save_Users_Products [ERROR]::", error);
    }
  }

  function handleRightIconPressed() {
    setRemoveProductSectionAlert(true);
  }

  async function handleDeleteFollowingClick() {
    try {
      setFollowLoader(true);
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordError: true,
        DeleteList: [
          { ProductId: currentProduct?.[0]?.productId ?? 0, userId: userId },
        ],
      };
      await deleteUserProducts(obj);
      await fetchFollowsEntity();
      await fetchUserProductList();
      setFollowLoader(false);
    } catch (error) {
      setFollowLoader(false);
      console.log("Delete Product [ERROR]::", error);
    }
  }

  function handleMemberListPressed() {
    setMemberListModalOpen(true);
  }

  function saveProductManfacturer(params) {
    if (params.manufacturerId != 0) {
      handleSaveManufacturerInProduct(params);
    } else {
      handleSaveNewManufacturer(params);
    }
  }

  async function handleSaveManufacturerInProduct(params) {
    setIsLoading(true);
    try {
      const productObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: productInfo?.id,
            Name: productInfo?.name,
            Info: productInfo?.info,
            productType: productInfo?.productType,
            RecordStatusId: 1,
            ModifiedBy: Number(localStorage.userId),
            ManufacturerId: params?.manufacturerId ?? 0,
          },
        ],
      };
      await saveProducts(productObj);
      fetchProductDetails();
      setIsLoading(false);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
      setIsLoading(false);
    }
  }

  async function handleSaveNewManufacturer(params) {
    setIsLoading(true);
    try {
      const saveObject = {
        userId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: params?.manufacturerName ?? "",
            RecordStatusId: 1,
            ManufacturerId: 0,
            Manufacturer: true,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const res = await saveCompanies2(saveObject);
      let newItem = {
        manufacturerId: res[0]?.objData?.id,
      };
      handleSaveManufacturerInProduct(newItem);
      setIsLoading(false);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
      setIsLoading(false);
    }
  }

  async function getProductCompaniesData() {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        SearchList: [{ ProductId: props.productId }],
        SortList: [{ FieldName: "CompanyName", Direction: "ASC" }],
      };
      const companyProducts = await fetchCompaniesProducts(obj);
      setIsCompanyEmpty(companyProducts?.data?.length === 0 ? true : false);
      setIsLoading(false);
      return companyProducts;
    } catch (Error) {
      console.log("GET SITE_COMPANIES-2 [ERROR]::", Error);
      setIsLoading(false);
    }
  }

  function saveCompany(params) {
    if (params.companyName === "") {
      saveCompanyInProduct(params);
    } else {
      handleSaveCompanies(params);
    }
  }

  async function handleSaveCompanies(params) {
    try {
      const saveObject = {
        userId: Number(userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: params.companyName,
            ModifiedBy: Number(userId),
            RecordStatusId: 1,
            Manufacturer: false,
          },
        ],
      };
      const siteCompaniesRes = await saveCompanies2(saveObject);
      let newItem = {
        id: siteCompaniesRes[0]?.objData?.id,
        roleId: params.roleId,
        otherRoleName: params.otherRoleName,
      };
      saveCompanyInProduct(newItem);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
    }
  }

  async function saveCompanyInProduct(params) {
    try {
      setIsLoading(true);
      const obj = {
        UserId: Number(userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            CompanyId: params.companyName == "" ? params.id : params.id,
            ProductId: props.productId,
            RecordStatusId: 1,
            ModifiedBy: Number(userId),
          },
        ],
      };
      await saveCompanyProduct(obj);
      setGetUpdatedData(true);
      setIsLoading(false);
    } catch (ERROR) {
      console.log("GET SITE_COMPANIES [ERROR]::", ERROR);
      setIsLoading(false);
    }
  }

  const onMenuOptionClick = (data) => {
    if (data === "Add Product Picture") {
      fileInputRef.current.click();
    } else if (data === "Remove from My Products") {
      setRemoveProductSectionAlert(true);
    }
  };

  const handleFileSelect = async (e) => {
    let files = e.target.files;
    if (files && files.length > 0) {
      let allFilesValid = true;

      for (let i = 0; i < files.length; i++) {
        if (!files[i].type.startsWith("image/")) {
          allFilesValid = false;
          break;
        }
      }

      if (allFilesValid) {
        setImageLodaing(true);
        const obj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: true,
          ReturnRecordError: false,
          SaveList: [
            {
              Id: 0,
              FormatTypeId: 1,
              PostedByUserId: Number(localStorage.userId),
              AppId: 4,
              AppRecordId: props?.productId,
              Message: "<<<picture>>>",
              Reported: false,
              ModifiedBy: Number(localStorage.userId),
            },
          ],
        };

        try {
          const postRes = await saveSitePosts(obj);

          for (let i = 0; i < files.length; i++) {
            const formData = new FormData();
            formData.append("Id", 0);
            formData.append("PostId", postRes?.[0]?.objData?.id);
            formData.append("ModifiedBy", localStorage.userId);
            formData.append("Pictures", files[i]);

            await saveSitePostPicture(formData).catch((err) => {
              setImageLodaing(false);
              console.log(
                "Error while calling the save post pictures API:",
                err
              );
              if (err === "Content Inappropriate") {
                setInappropriateImageAlert(true);
              }
            });
          }
          // await fetchPictures();
          if (activeTab === "photos") {
            setGetUpdatedImages(true);
          }
        } catch (err) {
          setImageLodaing(false);
          console.log("Error while calling the save site API:", err);
        }
      } else {
        alert("Please select valid image files (JPEG, PNG, GIF, etc.).");
      }
    }
  };

  const deleteTrade = async (tradeId) => {
    try {
      if (!tradeId) return;
      const deleteObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordError: true,
        SoftDelete: true,
        DeleteList: [{ TradeId: tradeId, ProductId: props?.productId }],
      };

      await deleteProductTrade(deleteObj)
        .then(async () => {
          await getProductTradeList();
        })
        .catch((err) => {
          console.log(
            "Error while deleting the trade from the company proifle ::",
            err
          );
        });
    } catch (error) {
      console.log(
        "Error while deleting the trade from the company proifle ::",
        error
      );
    }
  };

  const getSubTitle = () => {
    return productInfo?.productType || productInfo?.manufacturerName
      ? productInfo?.productType && productInfo?.manufacturerName
        ? `${productInfo?.productType} | ${productInfo?.manufacturerName}`
        : productInfo?.productType || productInfo?.manufacturerName
      : null;
  };

  if (showLoader) {
    return (
      <div className="profile-loader">
        <Loader />
      </div>
    );
  }

  const handleAddContact = async (data) => {
    try {
      setShowBtnLoader(true);
      let obj = {
        UserId: localStorage.userId,
        FriendsEmails: data?.userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(
            localStorage.userId
          )}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${contact?.userId}`
        },
      };
      await inviteFriendsByEmail(obj);
      await fetchProductMemberList();
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("handleAddContact error", error);
    }
  };

  const navigateTochat = async (data) => {
    try {
      setShowBtnLoader(true);
      const saveListArray = [
        {
          Id: 0,
          ChatGroupId: 0,
          UserId: data?.userId,
          ModifiedBy: Number(localStorage.getItem("userId")),
          Muted: false,
        },
      ];
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };
      let result = await saveChatGroupUsers(chatGroupObj);
      if (result?.data?.length > 0) {
        let chatGroupId = result.data[0]?.objData.chatGroupId;
        history.push({
          pathname: "/chat",
          state: {
            from: "New Chat",
            chatGroupId: chatGroupId,
            showInList: true,
          },
        });
      }
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("Error while sending to chat screen :: ", error);
    }
  };

  return (
    <div className="page">
      <ReportModal
        isOpen={reportModalOpen}
        onClose={() => setReportModalOpen(false)}
        name={reportPostName == "Ads" ? "Ad" : "Product Post"}
        recordId={postId}
      />
      <MemberListModal
        isOpen={memberListModalOpen}
        onClose={() => {
          setMemberListModalOpen(false);
        }}
        members={members}
        profileMemberList={false}
        handleConnectClick={handleAddContact}
        handleMessageClick={navigateTochat}
        showBtnLoader={showBtnLoader}
      />

      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />

      <PromptModal
        isOpen={productSectionAlert}
        onClose={() => {
          setProductSectionAlert(false);
        }}
        title="Are you sure you want to unfollow this Product Page "
        redButtonText="YES"
        blueButtonTextFirst="NO"
        onRedButtonClick={() => {
          handleSaveFollowClick(false);
          setProductSectionAlert(false);
        }}
      />

      <PromptModal
        isOpen={removeProductSectionAlert}
        onClose={() => {
          setRemoveProductSectionAlert(false);
        }}
        title="Remove this Product from Products you have worked with?"
        redButtonText="REMOVE"
        blueButtonTextFirst="CANCEL"
        onRedButtonClick={() => {
          handleDeleteFollowingClick();
          setRemoveProductSectionAlert(false);
        }}
      />
      <div className="page-content">
        <Stack spacing={2} style={{ margin: "16px 0px" }}>
          <ProfileHeader
            isFollow={isFollow}
            // images={[]}
            imageUrl={postPictures?.[0]?.pictureUrl ?? ""}
            title={productInfo?.name}
            subtitle={getSubTitle()}
            handleFollowClick={() => handleFollowPressed()}
            handleFollowingClick={() => handleFollowingPressed()}
            handleAddToProfileButton={() => handleAddToProfileButton()}
            handleRightIconPressed={() => handleRightIconPressed()}
            handleMemberListPressed={() => handleMemberListPressed()}
            isLoading={followLoader}
            isMember={isMember}
            isAddToProfile={isAddToProfile}
            btnTitle1={"Follow"}
            btnTitle={"I WORKED WITH THIS"}
            warningText={productInfo?.manufacturerName === "" ? true : false}
            // warningText={false}
            members={members}
            tabs={[
              productInfo?.manufacturerName === "" ? null : "posts",
              "photos",
              "reviews",
              "Projects",
              "companies",
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            menuOptions={[
              "Remove from My Products",
              "Add Product Picture",
              "Send in Chat",
              "Share",
            ]}
            onMenuOptionClick={onMenuOptionClick}
            shareDetails={{ type: "ProductInfo", id: props?.productId }}
            imageLoading={imageLoading}
            from="Product"
          />

          <Stack direction="row" spacing={2}>
            <div style={{ flex: 1 }}>
              <AboutSection
                saveItem={saveProductManfacturer}
                withCard
                content={{
                  manufacturer: productInfo?.manufacturerName ?? "",
                  website: productInfo?.productWebSite ?? "",
                  description: productInfo?.info ?? "",
                  noManufacure:
                    productInfo?.manufacturerName == "" ? "noManFact" : "",
                }}
                onDataSave={() => fetchProductDetails()}
                trades={productTrades}
                aboutSectionType={"product"}
                productId={props?.productId}
                productDetails={{
                  Id: props?.productId,
                  Name: productInfo?.name,
                  Info: productInfo?.info,
                  webSite: productInfo?.productWebSite,
                  ManufacturerId: productInfo?.manufacturerId ?? "",
                }}
                deleteTrade={deleteTrade}
              />
            </div>

            <div style={{ flex: 2 }}>
              {activeTab === "posts" && productInfo?.manufacturerName !== "" ? (
                <Stack spacing={2}>
                  <PostInput
                    pageName={"product"}
                    post={props.post}
                    appId={4}
                    appRecordId={props.productId}
                    updatePosts={updatePosts}
                    onSaveFunction={true}
                    onPostSave={async () => {
                      await fetchPictures();
                    }}
                  />
                  {posts?.length != 0 ? (
                    <>
                      {posts.map((post, index) => (
                        <Post
                          key={index}
                          post={post}
                          from={"productDetail"}
                          setReportModalOpen={(value) =>
                            setReportModalOpen(value)
                          }
                          updatePosts={updatePosts}
                          setPostId={(value) => setPostId(value)}
                          setRepostPostName={(value) =>
                            setRepostPostName(value)
                          }
                        />
                      ))}
                    </>
                  ) : (
                    <Placeholder
                      icon={<ChatIcon />}
                      text={"Share your thoughts with other BuildId Users"}
                    />
                  )}
                </Stack>
              ) : (
                <></>
              )}

              {activeTab === "reviews" ? (
                <Stack spacing={2}>
                  <ReviewInput
                    productId={props?.productId}
                    tradeName={props?.tradeName}
                    updateRatings={updateRatings}
                  />
                  {reviews.map((review, index) => (
                    <Review key={index} review={review} />
                  ))}
                </Stack>
              ) : (
                <></>
              )}

              {/* {
								(activeTab === "barcodes")
									? (
										<Stack spacing={2}>
											<BarcodePostInput
												productId={props.productId}
												tradeName={props?.tradeName}
												updateBarcodes={updateBarcodes}
											/>
											{
												barcodes.map((barcode, index) => (
													<BarcodePost key={index} barcode={barcode} />
												))
											}
										</Stack>
									)
									: <></>
							} */}

              {/* {
								(activeTab === "sites")
									? (
										<ListSection
											getData={getProductSites}
											itemIdKey="id"
											itemImgKey="image"
											itemNameKey="name"
											itemSecondaryKey="address"
											itemRecordType="site"
											searchPlaceholder="Search this Product's Sites"
											searchTopDescription='Sites that this product has been used at'
											buttonLabel="View on map"
											onButtonClick={() => {
												navigate.push('/sites');
											}}
											emptyData={isSiteEmpty}
											NOResultIcon={<MapMarkerIcon />}
											NoResultText="No information about this Product"
											NoResultText2="Know any information? Add it here for builderbucks"
										/>
									)
									: <></>
							} */}

              {activeTab === "Projects" ? (
                <ListSection
                  getData={fetchProjectsForAProduct}
                  itemIdKey="projectId"
                  itemNameKey="projectName"
                  itemRecordType="projects"
                  itemImgKey="thumbUrl"
                  itemSecondaryKey="address"
                  searchPlaceholder="Search this Product's Projects"
                  searchTopDescription="Projects that this product has been used at"
                  // onButtonClick={() => {
                  // 	navigate.push("/projects/project");
                  // }}'
                  buttonLabel="Add Project"
                  onButtonClick={() => {
                    history.push({
                      pathname: "/new-map",
                      state: {
                        from: "productProfile",
                        selectedProduct: productInfo,
                      },
                    });
                  }}
                  emptyData={isProjectEmpty}
                  NOResultIcon={<MapMarkerIcon />}
                  NoResultText="No information for this product."
                  NoResultText2="Know any information? Add it here for builderbucks"
                  productProjects={true}
                />
              ) : (
                <></>
              )}

              {activeTab === "photos" && (
                <ProfilePic
                  getData={() => fetchPictures()}
                  // getData={() => fetchSitePostPictures(userId, props.siteId)}
                  isLoading={isLoading}
                  from="product"
                  productId={props?.productId}
                  getUpdatedImages={getUpdatedImages}
                  updateValueOfGetUpdatedUmages={() => {
                    setGetUpdatedImages(false);
                  }}
                />
              )}

              {activeTab === "companies" ? (
                <CompanyListSection
                  getData={getProductCompaniesData}
                  itemIdKey="companyId"
                  itemNameKey="companyName"
                  itemRecordType="company"
                  categoryIdKey="siteCompanyRoleId"
                  categoryNameKey="siteCompanyRole"
                  otherSiteCompanyRole="otherSiteCompanyRole"
                  searchPlaceholder="Search this Product's Compaines"
                  addButtonLabel="Add Company"
                  saveItem={saveCompany}
                  isLoading={isLoading}
                  searchTopDescription={"Companies that have used this product"}
                  emptyData={isCompanyEmpty}
                  NoResultBtn="Add Company"
                  NOResultIcon={<WorkIcon />}
                  NoResultText="No information about this Product."
                  NoResultText2="Know any information? Add it here for builderbucks"
                  hideRole={false}
                  getUpdatedData={getUpdatedData}
                  changeGetUpdateDataValue={() => {
                    setGetUpdatedData(false);
                  }}
                  modalTitle="Add Company to Product"
                />
              ) : (
                <></>
              )}
            </div>
          </Stack>
        </Stack>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
        accept="image/*"
        multiple
      />
    </div>
  );
}
