import { Button, IconButton, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import BuildIDLogo from "../../assets/images/Build-ID-Transparent.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { BIBlue, BIGrey, BILightGrey } from "../../assets/buildidColors";

export default function NextModal({ open, onActionSuccesOfThermalSystems, clientDetails }) {
  const onAgreeClick = () => {
    onActionSuccesOfThermalSystems("Agree");
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-center" style={{ width: "500px" }}>
        <div
          style={{
            padding: "20px",
          }}
          className="modal-card"
        >
          <img
            src={clientDetails?.clientLogo} // Replace with your actual logo path
            alt="Thermal Systems Logo"
            className="modal-logo"
          />

          <div className="section-card">
            <div className="section-title">
              <div style={{ textAlign: "center" }}>
                <IconButton style={{ color: BIBlue }}>
                  <Visibility color={BIBlue} />
                </IconButton>
              </div>
              <Typography variant="h6">
                Data that will be shared with <b>{clientDetails?.clientName}:</b>
              </Typography>
            </div>
            <div className="data-list">
              <div className="data-item">Your first/last name</div>
              <div className="data-item">Profile Picture</div>
              <div className="data-item">Trades on your Profile</div>
              <div className="data-item">Address</div>
              <div className="data-item">Emails</div>
              <div className="data-item">Phone numbers</div>
              <div className="data-item">Tickets</div>
            </div>
          </div>

          {/* Non-Shared Data Section */}
          <div className="section-card">
            <div className="section-title">
              <div style={{ textAlign: "center" }}>
                <IconButton style={{ color: BILightGrey }}>
                  <VisibilityOff color={BIBlue} />
                </IconButton>
              </div>
              <Typography variant="h6">
                <b>{clientDetails?.clientName}</b> will not be able to see:
              </Typography>
            </div>
            <div className="data-list">
              <div className="data-item">Timesheets</div>
              <div className="data-item">Companies you worked at</div>
              <div className="data-item">Expenses</div>
              <div className="data-item">Projects you worked on</div>
              <div className="data-item">Your Builder Network</div>
              <div className="data-item">Education</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            <Button
              style={{
                backgroundColor: "blue",
                color: "white",
                width: "150px",
                height: "40px",
              }}
              onClick={onAgreeClick}
            >
              I AGREE
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
