import { Box, Card, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import AddIcon from '@mui/icons-material/Add';
import { Close as CloseIcon } from "@material-ui/icons";
import moment from "moment";

export default function Reviews(props) {

    const fileInputRef = useRef(null);

    useEffect(() => {
        console.log("props jobName --->", props.jobName);
        console.log("props selectedEmp --->", props.selectedEmp);
        console.log("props selectedjobActivities --->", props.selectedJobActivities)
    }, []);


    const onNoteschange = (e) => {
        props.setNotes(e.target.value);
    }

    const onSelectimage = () => {
        fileInputRef.current.click();
    }

    const handleImageSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            let getPictures = [...props.pictures, { id: 0, imgUrl: imageUrl, imgFile: file, isDeleted: false }];
            props.setPictures(getPictures);
        }
    };


    const renderTotalhours = () => {
        let allHours = 0;
        props?.selectedJobActivities?.map((item, index) => {
            item?.selectedEmp?.map((item) => {
                if (item?.Deleted === false)
                    allHours = item?.hours + allHours;
            })
        });
        return allHours;
    }

    const renderActivityhours = (index) => {
        let allHours = 0;
        props?.selectedJobActivities[index]?.selectedEmp?.map((item, index) => {
            if (item?.Deleted === false)
                allHours = item?.hours + allHours;
        });
        return allHours;
    }

    const showEmployeelist = () => {
        props.setCurrentstep(1);
    }

    const showActivitylist = () => {
        props.setCurrentstep(2);
    }

    const closeJobimage = (getIndex) => {
        let getImages = props?.pictures.map((item, index) => {
            if (getIndex === index) {
                return {
                    ...item,
                    isDeleted: true
                }
            } else {
                return item
            }
        })
        props.setPictures(getImages);
    }

    const empHourschange = (item) => {
        let empHours = 0;
        props?.selectedJobActivities?.map((act)=>{
              act?.selectedEmp?.map((emp)=> {
                 if(emp?.clientEmployeeId === item?.clientEmployeeId) {
                     empHours = empHours + emp?.hours
                 }
             })
        })
        return empHours;
    }

    return (
        <Box className="reviews-box">
            <Typography fontSize={19} fontWeight={600}>Reviews</Typography>
            <Stack marginTop={1}>
                <Typography fontSize={14} color={"#8d8d8d"}>Date</Typography>
                <Typography fontSize={16} fontWeight={500}>{moment(props.timesheetDate).format("MMM DD, YYYY")}</Typography>
            </Stack>
            <Stack marginTop={1}>
                <Typography fontSize={14} color={"#8d8d8d"}>Job</Typography>
                <Typography fontSize={16} fontWeight={500}>{props?.jobName}</Typography>
            </Stack>
            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={1} marginBottom={1}>
                <Typography fontSize={17} fontWeight={600}>Employees</Typography>
                <Typography fontSize={15} fontWeight={500} color={"#083db8"} style={{ cursor: "pointer" }} onClick={showEmployeelist}>Edit</Typography>
            </Stack>
            {props?.selectedEmp?.map((item) => (
                item?.Deleted === false && (
                    <Card className="emp-card" marginTop={1}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Typography className="emp-name" fontSize={16} fontWeight={500}>{item?.clientEmployeeName}</Typography>
                            <Typography fontSize={16} fontWeight={500}>{item?.mileage} km </Typography>
                            <Typography fontSize={16} fontWeight={500}>{empHourschange(item)} h</Typography>
                        </Stack>
                    </Card>
                )
            ))}
            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={1} marginBottom={1}>
                <Typography fontSize={17} fontWeight={600}>Activities</Typography>
                <Typography fontSize={15} fontWeight={500} color={"#083db8"} style={{ cursor: "pointer" }} onClick={showActivitylist}>Edit</Typography>
            </Stack>
            {props?.selectedJobActivities?.map((item, index) => (
                <Card className="activity-card" marginTop={1}>
                    <Stack>
                        <Typography fontSize={16}>{item?.activityName}</Typography>
                        <Stack flexDirection={"row"} gap={13}>
                            <Stack>
                                <Typography fontSize={15} color={"#8d8d8d"}>Hours</Typography>
                                <Typography>{renderActivityhours(index)}</Typography>
                            </Stack>
                            <Stack>
                                <Typography fontSize={15} color={"#8d8d8d"}>Quantity</Typography>
                                <Typography>{item?.quantity} sqft</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            ))
            }
            <Stack flexDirection={"row"} justifyContent={"space-between"} marginTop={1}>
                <Typography fontSize={15} fontWeight={500}>Total Hours</Typography>
                <Typography fontSize={15} fontWeight={500}>{renderTotalhours()}</Typography>
            </Stack>
            <Stack marginTop={1}>
                <Typography fontSize={15} fontWeight={500} color={"#8d8d8d"}>Notes</Typography>
                <TextField rows={2} value={props?.notes} onChange={(e) => onNoteschange(e)} multiline placeholder="Enter additional details (optional)" />
            </Stack>
            <Stack marginTop={1}>
                <Typography fontSize={15} fontWeight={500} color={"#8d8d8d"}>Pictures</Typography>
                <Grid container gap={1} overflow={"scroll"} padding={"8px 0px"} flexWrap={"nowrap"} display={"-webkit-box"}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={(e) => handleImageSelect(e)}
                    />
                    <Grid sm={2} md={3} lg={3} xl={3}>
                        <Box className="add-pic" onClick={() => onSelectimage()}>
                            <AddIcon color="#cccccc" />
                        </Box>
                    </Grid>
                    {props?.pictures?.map((item, index) => (
                        item?.isDeleted === false && (
                            <Grid sm={2} md={3} lg={3} xl={3} >
                            <Box position={"relative"}>
                                <IconButton color="primary" className="close-btn" onClick={() => closeJobimage(index)}>
                                    <CloseIcon style={{ fontSize: "1.1rem" }} />
                                </IconButton>
                                <img className="add-pic" src={item?.imgUrl}></img>
                            </Box>
                        </Grid>
                        )
                    ))}
                </Grid>
            </Stack>
        </Box>
    )
}