import React from "react";
import { Button, Container } from "@material-ui/core";
import { useState } from "react";
import AddMontageForm from "./AddMontageForm";
import MontageList from "./MontageList";
import { useEffect } from "react";
import { getMontages } from "../../api/MontagesApi";
import Placeholder from "../../components/Placeholder";
import SmartDisplayIcon from "@mui/icons-material/SmartDisplay";
import UserUsages from "../../components/userUsages/UserUsages";
import MontageTutorial from "../../components/Tutorial/MontageTutorial";
import {
  showTutorialForNewUser,
  updateTutorialForNewUser,
} from "../../assets/helpers";

export default function Montages() {
  const [editMontageData, setEditMontageData] = useState({});
  const [montageData, setMontageData] = useState([]);
  const [addMontageBtnVisible, setAddMontageBtnVisible] = useState(true);
  const [activePage, setActivePage] = useState("");
  const [fetchMontageLength, setFetchMontageLength] = useState(0);
  const [isMontageEmpty, setMontageEmpty] = useState(false);
  const [tutorialIndex, setTutorialIndex] = useState(1);

  const fetchMontage = async () => {
    try {
      const getMontageRes = await getMontages(
        Number(localStorage.getItem("userId"))
      );
      const sortArr = getMontageRes.sort((a, b) => b.id - a.id);
      setMontageData(sortArr);
      setFetchMontageLength(getMontageRes.length);
      if (getMontageRes.length === 0) setMontageEmpty(true);
      else setMontageEmpty(false);
      return getMontageRes;
    } catch (e) {
      console.log("Error :", e);
    }
  };

  useEffect(() => {
    UserUsages("Montage");
    setActivePage("montage-list");
    fetchMontage();
  }, []);

  useEffect(() => {
    setEditMontageData(editMontageData);
  }, [editMontageData]);

  const editMontage = async (data) => {
    setActivePage("new-montage");
    await setEditMontageData(data);
  };

  function getActivePage() {
    switch (activePage) {
      case "new-montage":
        return (
          <AddMontageForm
            fetchMontage={fetchMontage}
            editMontageData={editMontageData}
            setEditMontageData={setEditMontageData}
            setAddMontageBtnVisible={setAddMontageBtnVisible}
            setActivePage={() => {
              setActivePage("montage-list");
              setEditMontageData({});
            }}
          />
        );

      case "montage-list":
        return isMontageEmpty ? (
          <Placeholder
            icon={<SmartDisplayIcon />}
            text="No videos on montages."
          />
        ) : (
          <MontageList
            fetchMontageLength={fetchMontageLength}
            fetchMontage={fetchMontage}
            montageData={montageData}
            editMontage={editMontage}
            setAddMontageBtnVisible={setAddMontageBtnVisible}
            setActivePage={setActivePage}
          />
        );

      default:
        return <></>;
    }
  }

  return (
    <div>
      <div className="page rewards-page">
        <div className="header">
          <div className="page-content">
            <h1>Montages</h1>
          </div>
        </div>
      </div>
      <div className="page">
        <div className="page-content">
          <div
            className="resp-container-horizontal"
            style={{
              margin: "15px 0px 0px 0px",
              display: "flex",
              justifyContent: "flex-end",
            }}>
            <div className="flex-container-right">
              <div className="flex-container-horizontal">
                <div className="input-container">
                  {addMontageBtnVisible ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setAddMontageBtnVisible(false);
                        setActivePage("new-montage");
                      }}>
                      Add Montage
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
          {getActivePage()}
        </div>
      </div>
      {showTutorialForNewUser("montages") && (
        <MontageTutorial
          onPress={() => {
            setTutorialIndex((p) => {
              if (p >= 6) {
                if (showTutorialForNewUser("montages")) {
                  updateTutorialForNewUser("montages");
                }
                return 0;
              } else {
                return p + 1;
              }
            });
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
    </div>
  );
}
