import React, { useEffect, useState } from "react";
import { fetchClientSafetyIncidents } from "../../../api/ClientApi"
import {
  Card,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ListItemButton from "@mui/material/ListItemButton";
import ClientAddSafetyIncident from "./ClientAddSafetyIncidents"
import ClientSafetyIncidentViewModal from "./ClientSafetyIncidentViewModal";
import { ClientHeader } from "../ClientHeader";
import AddIcon from "@mui/icons-material/Add";
import "../../../assets/css/ClientHome.css"
import Loader from "../../../components/Loader";

function ClientSafetyIncidents() {
  const [safetyIncidentsList, setSafetyIncidentsList] = useState([]);
  const [addmodel, setaddmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");

  const closeModel = () => {
    setaddmodel(false);
  };

  const getSafetyIncidents = async () => {
    setIsLoading(true);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 30,
      FullSearch: clientSearchTerm || "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          ModifiedBy: localStorage.userId,
          clientEmployeeId: localStorage.getItem("clientEmployeeId"),
        },
      ],
    };
    await fetchClientSafetyIncidents(obj)
      .then((res) => {
        const modifiedRes = res?.data.sort((a, b) => new Date(b.incidentDate) - new Date(a.incidentDate));
        setSafetyIncidentsList(modifiedRes);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("getting error while getting safety incidents list", err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getSafetyIncidents();
  }, [clientSearchTerm]);

  return (
    <>
      <ClientHeader screenName={"Safety Incidents"} clientSearchTerm={clientSearchTerm} setClientSearchTerm={setClientSearchTerm} />

      <div className="page">
        <div className="page-content" style={{ marginTop: '20px' }}>
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            {isLoading ? <Loader /> : <Stack spacing={1}>
              {safetyIncidentsList?.map((data, index) => {
                return (
                  <ClientSafetyIncidentCard
                    data={data}
                    close={closeModel}
                    open={addmodel}
                  />
                );
              })}
            </Stack>}
          </div>
          <ClientAddSafetyIncident close={closeModel} open={addmodel} getSafetyIncidents={getSafetyIncidents} />
        </div>
      </div>
      <div className="fixed-button-container">
        <button className="fixed-button" style={{ background: localStorage?.clientAccentColor }} onClick={() => setaddmodel(true)}>
          <AddIcon />
        </button>
      </div>
    </>
  );
}

export default ClientSafetyIncidents;

export const ClientSafetyIncidentCard = ({ data }) => {
  const [viewModel, setViewModel] = useState(false);
  const handleModelclose = () => {
    setViewModel(false);
  };

  return (
    <>
      <ListItemButton
        onClick={() => {
          setViewModel(true);
        }}
        style={{ padding: "0px", borderRadius: "10px" }}
      >
        <Card
          style={{
            width: "100%",
            backgroundColor: "white",
            height: "auto",
            // maxHeight: "100px",
            padding: "10px 10px 10px 0px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box style={{ width: "95%", height: "50%" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                gutterBottom
                component="div"
              >
                <LocationOnIcon style={{ fontSize: 20, color: localStorage?.clientAccentColor }} />

                {`${data.clientJobCode} - ${data.clientJobName}`}
              </Typography>
              <Typography
                gutterBottom
                style={{
                  color: "gray",
                  fontSize: "12px",
                }}
              >
                {moment(data.incidentDate).format("MMMM D, YYYY")}
              </Typography>
            </Stack>
            <Typography
              color="text.secondary"
              variant="body2"
              gutterBottom
              style={{
                whiteSpace: "pre-wrap",
                overflow: "clip",
                maxHeight: "20px",
              }}
            >
              {data.description}
            </Typography>
            {data?.udFieldsList?.length > 0 &&
              data?.udFieldsList?.map((item, index) => {
                if (item?.value == "true"
                ) {
                  return <Typography style={{ backgroundColor: "lightgrey", padding: "5px", borderRadius: "5px", width: 'fit-content' }} variant="body2" color="textSecondary">
                    {item?.label}
                  </Typography>
                }
              })
            }
          </Box>
        </Card>
      </ListItemButton>
      <ClientSafetyIncidentViewModal
        open={viewModel}
        close={handleModelclose}
        data={data}
      />
    </>
  );
};
