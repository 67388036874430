import React, { useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { messaging } from "./firebase";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { updateNotificationStatus } from "./api/UserApi";

const Toastify = (props) => {
  const history = useHistory();
  const { addToast, removeToast } = useToasts();

  const handleUpdateNotificationStatus = async (msgId) => {
    try {
      let deviceId = localStorage.getItem("deviceId");
      const obj = {
        NotificationMessageId: msgId,
        ReceiverUserDevice: deviceId,
      };
      await updateNotificationStatus(obj);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const handleRemoveToast = (toastId) => {
    removeToast(toastId);
  };

  const handleNotificationClick = (item, toastId) => {
    if (item?.data !== undefined) {
      const url = item?.data["gcm.n.link"]; // No need to parse this as JSON, it's already a string.
      let screenName = "";
      let matchId = "";

      function parseURL(urlString) {
        const urlWithoutProtocol = urlString.replace("mybuilderid://", ""); // Remove protocol

        const [path] = urlWithoutProtocol.split("&"); // Split by "&" as query parameters start immediately after path

        const screenNameMatch = path.match(/screenName=([^&]+)/);
        const screenName = screenNameMatch ? screenNameMatch[1] : path;

        // If queryString is undefined, matchId won't be found
        const idMatch = urlWithoutProtocol.match(/id=([^&]+)/); // Use a regex to get the ID directly from the string
        const matchId = idMatch ? idMatch[1] : null;

        return { screenName, matchId };
      }

      const { screenName: parsedScreenName, matchId: parsedId } = parseURL(url);

      screenName = parsedScreenName || ""; // Fallback to empty string if not found
      matchId = parsedId || ""; // Fallback to empty string if not found

      if (screenName !== null) {
        switch (screenName) {
          case "CompanyInfo":
            history.push(`/company/${matchId}`);
            break;
          case "ManufacturerInfo":
            history.push(`/manufacturer/${matchId}`);
            break;
          case "ProductInfo":
            history.push(`/product/${matchId}`);
            break;
          case "TradeDetail":
            history.push(`/trade/${matchId}`);
            break;
          case "ProjectPage":
            history.push(`/projects/project/${matchId}`);
            break;
          case "ConversationScreen":
            history.push({
              pathname: "/chat",
              state: {
                from: "New Chat",
                chatGroupId: Number(matchId),
                showInList: false,
              },
            });
            break;
          case "ContactProfile":
            history.push(`/user-dashboard-profile/${matchId}`);
            break;
          case "DetailPost":
            history.push("/dashboard", {
              comment: Number(matchId),
              from: "DashboardNotifications",
              fromTo: "getNotify",
              refresh: new Date(),
            });
            break;
          case "MapForSingleSite":
            localStorage.setItem("rcx", "true");
            history.push({
              pathname: "/new-map",
              state: {
                from: "dashboard-profile-post",
                appRecordId: Number(matchId),
                refresh: new Date(),
              },
            });
            window.location.reload();
            break;
          case "Tickets":
            history.push("/tickets");
            break;
          case "ProfileBoard":
            history.push("/profile");
            break;
          case "MyExperience":
            history.push("/profile", {
              from: "NotificationsForProfile",
            });
            break;
          case "DashboardNotifications":
            history.push("/dashboard", {
              from: "DashboardNotificationsForNotify",
            });
            break;
          case "Quiz":
            history.push("/rewards/earn-rewards", {
              QuestionId: Number(matchId),
              from: "DashboardNotificationsForQuiz",
              refreshForQuiz: new Date(),
            });
            break;
          default:
            history.push("/dashboard");
            break;
        }
      }

      handleUpdateNotificationStatus(item?.data?.NotificationMessageId);
      handleRemoveToast(toastId); // Remove the toast after handling the click
    }
  };

  useEffect(() => {
    const unsubscribe = messaging.onMessage((payload) => {
      const { Title, Body } = payload.data || {};
      const toastId = addToast(
        `${Title === undefined || Title === "" ? "BuildID" : Title}: ${Body}`,
        {
          appearance: "info",
          onClick: (e) => handleToastBodyClick(e, payload, toastId),
          style: { cursor: "pointer", marginTop: "5px" },
          autoDismiss: false,
        }
      );
    });

    return () => {
      // Cleanup when the component is unmounted
      unsubscribe();
    };
  }, [addToast]);

  const handleToastBodyClick = (e, payload, toastId) => {
    if (e.target.nodeName == "DIV") {
      handleNotificationClick(payload, toastId);
    } else {
      handleRemoveToast(toastId);
    }
  };

  return <></>;
};

export default Toastify;
