import React, { useEffect, useRef, useState } from "react";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  fetchClientJobs,
  getClientUDFieldsbyClientId,
  saveClientSafetyIncidents,
} from "../../../api/ClientApi";
import { Close } from "@material-ui/icons";
import { BIGrey } from "../../../assets/buildidColors";
import { LoadingButton } from "@mui/lab";

function Addmodel({ close, open, getSafetyIncidents }) {
  const [clientUDFields, setClientUDFields] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [jobInputValue, setJobInputValue] = useState("");
  const [jobList, setJobList] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const [selectedJobValue, setSelectedJobValue] = useState({});
  const [data, setData] = useState({
    Id: "",
    ClientEmployeeId: "",
    IncidentDate: new Date(),
    IncidentTime: new Date(),
    ClientJobCodeId: "", //jobCode
    Description: "",
    ModifiedBy: "",
  });

  const jobHint = useRef("");

  useEffect(() => {
    fetchJobData();
  }, [jobInputValue]);

  const fetchJobData = async () => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: jobInputValue,
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
      };
      let getResponse = await fetchClientJobs(obj);
      setJobList(getResponse?.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSaveSafetyIncidents = async () => {
    setLoadingBtn(true);
    let ud = {};
    clientUDFields.forEach((field) => {
      ud[String(field.id)] = field.fieldDefaultValue === "true" ? 1 : 0;
    });
    const convertedJson = JSON.stringify(ud);

    const payload = {
      ReturnRecordId: 1,
      ReturnRecordError: 1,
      UserId: Number(localStorage.getItem("userId")),
      SaveList: [
        {
          Id: data.Id ? data.Id : 0,
          ClientEmployeeId: localStorage.getItem("clientEmployeeId"),
          IncidentDate: data.IncidentDate
            ? moment(data?.IncidentDate).format("YYYY-MM-DD")
            : "",
          IncidentTime: data.IncidentTime
            ? data.IncidentTime
            : moment.utc().format(),
          ClientJobId: selectedJobValue.id, //jobCode
          Description: data.Description ? data?.Description : "",
          ModifiedBy: Number(localStorage.getItem("userId")),
          UDFields: convertedJson,
        },
      ],
    };

    try {
      const res = await saveClientSafetyIncidents(payload);
      if (res.message === "OK") {
        setData({
          Id: "",
          ClientEmployeeId: "",
          IncidentDate: new Date(),
          IncidentTime: new Date(),
          ClientJobCodeId: "", //jobCode
          Description: "",
          ModifiedBy: "",
        });
        setClientUDFields([]);
        getSafetyIncidents();
        setSelectedJobValue({});
        setJobInputValue("");
      }
    } catch (error) {
      console.log("error while saving safety incidents", error);
    } finally {
      handleClose();
      setLoadingBtn(false);
    }
  };

  const getUDfields = async (e) => {
    try {
      const obj = {
        clientId: localStorage?.clientId,
        appId: 2,
        entityRecordId: Number(e),
      };

      const res = await getClientUDFieldsbyClientId(obj);
      setClientUDFields(res);
    } catch (error) {
      console.log("getClientUDFieldsbyClientId Error : ", error);
    }
  };

  const handelCheckboxChange = (e, id) => {
    const { checked } = e.target;
    setClientUDFields((prev) => {
      return prev?.map((field) => {
        if (field.id === id) {
          field.fieldDefaultValue = checked ? "true" : "false";
        }
        return field;
      });
    });
  };

  const handleClose = () => {
    setData({
      Id: "",
      ClientEmployeeId: "",
      IncidentDate: new Date(),
      IncidentTime: new Date(),
      ClientJobCodeId: "",
      Description: "",
      ModifiedBy: "",
    });
    setSelectedJobValue({});
    setClientUDFields([]);
    setJobInputValue("");
    setLoadingBtn(false);
    close();
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose} disableAutoFocus={true}>
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              minHeight: "300px",
              minWidth: "600px",
            }}
            className="modal-card">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
              }}>
              <IconButton color="primary" onClick={handleClose}>
                <Close />
              </IconButton>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
              }}>
              <div
                style={{
                  width: "64%",
                }}>
                <Typography
                  style={{
                    color: BIGrey,
                  }}>
                  Date
                </Typography>
                <KeyboardDatePicker
                  onClick={() => {
                    setDatePickerOpen(true);
                  }}
                  onClose={() => {
                    setDatePickerOpen(false);
                  }}
                  open={datePickerOpen}
                  format="MMM D, YYYY"
                  value={
                    data?.IncidentDate ? moment(data?.IncidentDate) : moment()
                  }
                  maxDate={new Date()}
                  onChange={(date) => setData({ ...data, IncidentDate: date })}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true, // Prevent manual editing
                    style: {
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      height: "40px",
                      padding: "0 10px",
                    },
                  }}
                  inputVariant={"standard"}
                  size="small"
                  className="date-picker"
                  placeholder="MM/DD/YYYY"
                  fullWidth
                  autoOk={true}
                  KeyboardButtonProps={{
                    style: { color: localStorage.clientAccentColor },
                  }}
                />
              </div>

              <div>
                <Typography
                  style={{
                    color: BIGrey,
                  }}>
                  Time
                </Typography>
                <TimePicker
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      height: "40px",
                      padding: "  0 10px",
                    },
                  }}
                  inputVariant={"standard"}
                  size="small"
                  variant="inline"
                  autoOk
                  value={data?.IncidentTime ? data?.IncidentTime : new Date()}
                  onChange={(timeValue) => {
                    setData({
                      ...data,
                      IncidentTime: moment.utc(timeValue).format(),
                    });
                  }}
                  className="date-picker"
                />
              </div>
            </div>
            <div
              style={{
                marginTop: "10px",
              }}>
              <Typography
                style={{
                  color: BIGrey,
                }}>
                Job
              </Typography>
              <Autocomplete
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                size="small"
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "10px",
                }}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === "Tab") {
                    if (jobHint.current) {
                      setJobInputValue(jobHint.current);
                      event.preventDefault();
                    }
                  }
                }}
                onBlur={() => {
                  jobHint.current = "";
                }}
                disablePortal
                inputValue={jobInputValue}
                filterOptions={(options, state) => {
                  const displayOptions = options.filter((option) =>
                    option?.name
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase().trim())
                  );

                  return displayOptions;
                }}
                id="combo-box-hint-demo"
                options={jobList}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography>{`${option?.name} `}</Typography>
                  </Box>
                )}
                onChange={(event, newValue) => {
                  getUDfields(newValue?.id);
                  setSelectedJobValue({
                    ...newValue,
                  });
                  if (newValue) {
                    setJobInputValue(
                      `${newValue?.name} - ${newValue?.jobCode} `
                    ); // Update the input value to the selected label
                  } else {
                    setJobInputValue("");
                  }
                }}
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        opacity: 0.5,
                        left: 14,
                        top: 16,
                      }}>
                      {jobHint.current}
                    </Typography>
                    <TextField
                      {...params}
                      onFocus={() => setJobInputValue("")}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setJobInputValue(newValue);
                        const matchingOption = jobList.find((option) =>
                          option?.name.startsWith(newValue)
                        );
                        if (newValue && matchingOption) {
                          jobHint.current = matchingOption.name;
                        } else {
                          jobHint.current = "";
                        }
                      }}
                    />
                  </Box>
                )}
              />
            </div>
            <div>
              {" "}
              <Typography
                style={{
                  color: BIGrey,
                }}>
                Description
              </Typography>
              <TextField
                id="outlined-multiline-static"
                className="date-picker"
                placeholder="(what happened, immediate actions, first aid administered, client action)"
                multiline
                value={data?.Description}
                size="small"
                fullWidth
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "10px",
                  padding: "5px 10px",
                }}
                rows={3}
                onChange={(e) => {
                  setData({ ...data, Description: e.target.value });
                }}
              />
            </div>
            <div className="input-container">
              {clientUDFields?.length != 0 &&
                clientUDFields?.map((field, index) => {
                  return (
                    <>
                      <Checkbox
                        color="primary"
                        checked={
                          field?.fieldDefaultValue == "true" ? true : false
                        }
                        onChange={(e) => handelCheckboxChange(e, field?.id)}
                      />
                      <span style={{ color: "grey" }}>{field?.fieldLabel}</span>
                    </>
                  );
                })}
            </div>
            <div>
              {" "}
              <LoadingButton
                onClick={handleSaveSafetyIncidents}
                loading={loadingBtn}
                loadingPosition="start"
                fullWidth
                style={{
                  background:
                    !data.Description ||
                    !data.IncidentTime ||
                    !data.IncidentDate ||
                    !selectedJobValue.id
                      ? "lightgrey"
                      : localStorage?.clientAccentColor,
                  color: "white",
                }}>
                Submit
              </LoadingButton>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Addmodel;
