import { KeyboardDatePicker } from "@material-ui/pickers";
import { Box, Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Search as SearchIcon } from "@mui/icons-material";
import { getClientUDFieldsbyClientId } from "../../../api/ClientApi";
import {
    Close as CloseIcon,
} from "@material-ui/icons";


export default function GeneralModal(props) {

    useEffect(() => {
        if (props.showEmployeelist === false) {
            getUDfields();
        }
    }, [props.showEmployeelist]);


    const getUDfields = async () => {
        try {
            const getResponse = await Promise.all(props.selectedEmp.map(async (employee, index) => {
                const obj = {
                    clientId: localStorage?.clientId,
                    appId: 11,
                    entityRecordId: employee?.clientEmployeeId,
                }
                const res = await getClientUDFieldsbyClientId(obj);
                // If Employee alredy selected Udfield 
                if (employee?.udField?.length > 0) {
                    console.log("employee-->", employee)
                    let getSelected = res.map((item, index) => {
                        let getSeleted = employee?.udField.filter((field) => field?.id === item.id);
                        if (getSeleted?.length > 0) {
                            return {
                                ...res[index],
                                checked: getSeleted[0]?.checked,
                            }
                        } else {
                            return { ...item, checked: false };
                        }
                    });
                    return {
                        ...employee,
                        udField: getSelected
                    }
                } else {
                    return {
                        ...employee,
                        udField: res.map((item) => { return { ...item, checked: JSON.parse(item?.fieldDefaultValue)} })
                    }
                }
            }));
            console.log("get Response --->",getResponse);
            props.setSelectedemp(getResponse);
        } catch (error) {

        }

    }

    const handleSearchjob = () => {
        props.setShowJobListModal(true)
    }

    const onSelectemployee = () => {
        props.setShowemployeelist(true);
    }

    const onMilagechange = (e, index) => {
        let getUpdatedlist = props.selectedEmp?.map((item, getIndex) => {
            if (index === getIndex) {
                return {
                    ...item,
                    mileage: e.target.value
                }
            } else {
                return item
            }
        });
        props.setSelectedemp(getUpdatedlist);
    }

    const onMilageUnitchange = (e, index) => {
        let getUpdatedlist = props.selectedEmp?.map((item, getIndex) => {
            if (index === getIndex) {
                return {
                    ...item,
                    mileageUnit: e.target.value
                }
            } else {
                return item
            }
        });
        props.setSelectedemp(getUpdatedlist);
    }

    const onCloseemployee = (emp, getIndex) => {
        if (emp?.id !== 0) {
            let getUpdatedemplist = props?.selectedEmp?.map((item) => {
                if (item?.id === emp?.id) {
                    return {
                        ...item,
                        Deleted: true,
                        isSelected: false,
                    }
                } else {
                    return item
                }
            })
            props.setSelectedemp(getUpdatedemplist);
        } else {
            let getList = props.selectedEmp?.filter((item, index) => index !== getIndex);
            console.log("getList----->", getList)
            props.setSelectedemp(getList);
        }

    }

    const onUdfieldchange = (e, getIndex, udFieldindex) => {
        let getList = props?.selectedEmp?.map((item, index) => {
            if (index === getIndex) {
                let getUdfield = item?.udField?.map((item, udIndex) => {
                    if (udIndex === udFieldindex) {
                        return {
                            ...item,
                            checked: e.target.checked
                        }
                    } else {
                        return item;
                    }
                })
                return {
                    ...item,
                    udField: getUdfield
                }
            } else {
                return item
            }
        });
        console.log("getlist", getList);
        props.setSelectedemp(getList);
    }

    const onTimesheetDatechange = (date) => {
        props.setTimesheetdate(date);
    }

    return (
        <Stack>
            <Typography fontSize={19} fontWeight={600}>General</Typography>
            <Stack marginTop={2} gap={1}>
                <Typography fontSize={16} fontWeight={500}>Date</Typography>
                <KeyboardDatePicker
                    className="form-field"
                    variant="inline"
                    inputVariant={"outlined"}
                    placeholder="MM/DD/YYYY"
                    format={"MMM D, YYYY"}
                    value={props.timesheetDate}
                    fullWidth
                    onChange={(date) => onTimesheetDatechange(date)}
                    size="small"
                    autoOk={true}
                />
            </Stack>
            <Stack marginTop={2} gap={1}>
                <Typography fontSize={16} fontWeight={500}>Job</Typography>
                {console.log("props?.selectedJob",props?.selectedJob)}
                <TextField
                    value={props?.selectedJob[0]?.name ?? ""}
                    placeholder={"Search for a Job"}
                    color="primary"
                    size={"small"}
                    className="user-search"
                    onClick={handleSearchjob}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton onClick={handleSearchjob}>
                                    <SearchIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack marginTop={2} gap={2} flexDirection={props?.selectedEmp?.length === 0 ? "column" : "row"} justifyContent={"space-between"}>
                <Typography fontSize={19} fontWeight={600}>Employees</Typography>
                {props?.selectedEmp?.length === 0 ? (
                        <Button color="primary" variant="contained" onClick={() => onSelectemployee()}>Select Employee</Button>
                    ) : (
                        <Typography fontSize={17} style={{ cursor: "pointer" }} fontWeight={500} color={"#083db8"} onClick={() => onSelectemployee()} >Select employees</Typography>
                 )}
            </Stack>
            {props?.selectedEmp?.length > 0 && (
                <Box className="selectedemp-box">
                    {props?.selectedEmp?.map((item, index) => (
                        item?.Deleted === false && (
                            <Card className="selected-empcard">
                                <Grid container alignItems={"center"}>
                                    <Grid item md={6} lg={6} xl={6}>
                                        <Typography>{item?.clientEmployeeName}</Typography>
                                    </Grid>
                                    <Grid item md={3} lg={3} xl={3}>
                                        <TextField
                                            placeholder={"0.0"}
                                            color="primary"
                                            size={"small"}
                                            value={item?.mileage}
                                            onChange={(e) => onMilagechange(e, index)}
                                        />
                                    </Grid>
                                    <Grid item md={3} lg={3} xl={3} display={"flex"} justifyContent={"end"} sx={{ gap: "20px" }} alignItems={"center"}>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            size="small"
                                            value={item?.mileageUnit}
                                            onChange={(e) => onMilageUnitchange(e, index)}
                                        >
                                            <MenuItem value={"km"}>km</MenuItem>
                                            <MenuItem value={"mi"}>mi</MenuItem>
                                        </Select>
                                        <CloseIcon style={{ cursor: "pointer" }} onClick={() => onCloseemployee(item, index)} />
                                    </Grid>
                                </Grid>
                                {item?.udField?.map((item, udFieldindex) => (
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={item?.checked} onChange={(e) => onUdfieldchange(e, index, udFieldindex)} />} label={item?.fieldLabel} />
                                    </FormGroup>
                                ))}
                            </Card>
                        )
                    ))
                    }
                </Box>)}
        </Stack >
    )
}