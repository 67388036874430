import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { Button, Container, Stack } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Placeholder from "../../components/Placeholder";
import ExpensesListSection from "./ExpensesListSection";
import { fetchExpenses, sendExpenses } from "../../api/ExpensesApi";
import NewExpenseModal from "./NewExpenseModal";
import SendExpenseModal from "./SendExpenseModal";
import { BIBlue } from "../../assets/buildidColors";
import Loader from "../../components/Loader";
import DescriptionIcon from "@mui/icons-material/Description";
import UserUsages from "../../components/userUsages/UserUsages";

const tabsArray = ["unsent", "sent"];

function Expenses() {
    const [activeTab, setActiveTab] = useState("unsent");
    const [isUnsentExpenseEmpty, setIsUnsentExpenseEmpty] = useState(false);
    const [isSentExpenseEmpty, setIsSentExpenseEmpty] = useState(false);
    const [pageNr, setPageNr] = useState(1);
    const [sendButtonVisible, setSendButtonVisible] = useState(true);
    const [newExpenseModalOpen, setNewExpenseModalOpen] = useState(false);
    const [sendExpenseModalOpen, setSendExpenseModalOpen] = useState(false);
    const [selectedExpenses, setSelectedExpenses] = useState([]);
    const [expenseId, setExpenseId] = useState(0);
    const [editExpensesData, setEditExpensesData] = useState([]);
    const [fetchExpensesLength, setFetchExpensesLength] = useState(0);
    const [clickMode, setClickMode] = useState("");
    const [showLoader, setShowLoader] = useState(true);

    const handleTabChange = (event, newTab) => {
        setPageNr(1);
        setActiveTab(newTab);
    };

    const getEditExpensesData = async (id) => {
        try {
            const obj = {
                PageNr: 1,
                FullSearch: '',
                UserId: Number(localStorage.userId),
                IncludeRecordNr: true,
                TypeOfObjectReturned: "",
                SearchList: [{ Id: Number(id), UserId: Number(localStorage.userId) }]
            }
            // const res = await fetchExpenses({ SearchList: [{ id: Number(id) }] });
            const res = await fetchExpenses(obj);
            setExpenseId(id);
            await setEditExpensesData(res.data[0]);
            setNewExpenseModalOpen(true);
        } catch (error) {
            console.log("error--------", error)
        }

    };

    const increaseOffset = () => {
        setPageNr(pageNr + 1);
    };

    const handleSendExpenses = () => {
        setSendExpenseModalOpen(true);
    };

    const sendExpense = async (emailList) => {
        setPageNr(1);
        const expenseArray = selectedExpenses.map((item) => item.id);

        const sendTimeSheetObj = {
            Emails: emailList,
            Expenses: expenseArray,
            UserId: Number(localStorage.getItem("userId")),
        };

        const res = await sendExpenses(sendTimeSheetObj);

        return res;
    };

    const getUnsentExpenses = useCallback(async () => {
        setNewExpenseModalOpen(false);
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: Number(localStorage.getItem("userId")),
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
            SortList: [
                {
                    FieldName: "Id",
                    Direction: "DESC",
                },
            ],
        };
        const res = await fetchExpenses(obj);

        const filteredUnsentRes = res.data.filter((item) => !item.isSent);
        // const sortedUnsentRes = filteredUnsentRes.sort((a, b) => b.id - a.id);

        setFetchExpensesLength(res.data.length);

        if (filteredUnsentRes.length === 0) {
            setIsUnsentExpenseEmpty(true);
        } else {
            setIsUnsentExpenseEmpty(false);
        }
        setShowLoader(false);
        return filteredUnsentRes;
    }, [pageNr]);

    const getSentExpenses = useCallback(async () => {
        const obj = {
            PageNr: pageNr,
            NrOfRecPerPage: 100,
            FullSearch: "",
            UserId: Number(localStorage.getItem("userId")),
            IncludeRecordNr: "true",
            TypeOfObjectReturned: "",
            SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
            SortList: [
                {
                    FieldName: "Id",
                    Direction: "DESC",
                },
            ],
        };
        const res = await fetchExpenses(obj);

        const filteredSentRes = res.data.filter((item) => item.isSent);
        // const sortedSentRes = filteredSentRes.sort((a, b) => b.id - a.id);

        setFetchExpensesLength(res.data.length);
        if (filteredSentRes.length === 0) {
            setIsSentExpenseEmpty(true);
        } else setIsSentExpenseEmpty(false);
        setShowLoader(false);
        return filteredSentRes;
    }, [pageNr]);

    useEffect(() => {
        getUnsentExpenses();
        getSentExpenses();
        // setShowLoader(true);
        setPageNr(1);
        UserUsages("Expenses");
    }, []);

    return (
        <>
            <NewExpenseModal
                isOpen={newExpenseModalOpen}
                onClose={() => {
                    setNewExpenseModalOpen(false);
                }}
                expenseId={expenseId}
                getUnsentExpenses={getUnsentExpenses}
                editExpensesData={editExpensesData}
                clickMode={clickMode}
                activeTab={activeTab}
                setPageNr={setPageNr}
                setNewExpenseModalOpen={() => setNewExpenseModalOpen(true)}
                setloading={setShowLoader}
            />

            <SendExpenseModal
                isOpen={sendExpenseModalOpen}
                onClose={() => setSendExpenseModalOpen(false)}
                setSendButtonVisible={() => setSendButtonVisible(false)}
                selectedExpenses={selectedExpenses}
                activeTab={activeTab}
                pageNr={pageNr}
                closeSendExpenseModal={setSendExpenseModalOpen}
                sendExpense={sendExpense}
                getSentExpenses={getSentExpenses}
                getUnsentExpenses={getUnsentExpenses}
                setloading={setShowLoader}
            />

            <div className="page rewards-page">
                <div
                    className="header"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "space-between",
                    }}>
                    <div className="page-content">
                        <h1>Expenses</h1>
                    </div>
                </div>{" "}
            </div>
            {isUnsentExpenseEmpty && isSentExpenseEmpty ? (
                <></>
            ) : (
                <>
                    {/* <div
            className="page-content"
            style={{ paddingLeft: "16%", paddingRight: "16%" }}>
            <Tabs
              textColor="primary"
              indicatorColor="primary"
              value={activeTab}
              onChange={handleTabChange}>
              {tabsArray.map((tab, index) => (
                <Tab key={index} value={tab} label={tab} />
              ))}
            </Tabs>
          </div> */}
                </>
            )}

            <div className="page" >
                <div className="page-content">
                    {isUnsentExpenseEmpty && isSentExpenseEmpty ? (
                        <></>
                    ) : (
                        <>
                            <div className="flex-container-vertical" style={{ flex: 2 }}>
                                <div style={{ margin: "15px" }}>
                                    <div
                                        className="flex-container-horizontal"
                                        style={{ justifyContent: "space-between" }}>
                                        <Tabs
                                            textColor="primary"
                                            indicatorColor="primary"
                                            value={activeTab}
                                            onChange={handleTabChange}>
                                            {tabsArray.map((tab, index) => (
                                                <Tab
                                                    key={index}
                                                    value={tab}
                                                    label={tab}
                                                    style={{
                                                        backgroundColor: "white",
                                                        borderRadius: "5px",
                                                        marginRight: "20px",
                                                    }}
                                                />
                                            ))}
                                        </Tabs>

                                        <div className="flex-container-horizontal resp-container-horizontal">
                                            {activeTab === "unsent" && (
                                                <div className="input-container">
                                                    <Button
                                                        variant="contained"
                                                        style={{ background: BIBlue }}
                                                        onClick={() => {
                                                            setClickMode("NewExpense");
                                                            setNewExpenseModalOpen(true);
                                                        }}>
                                                        Add Expense
                                                    </Button>
                                                </div>
                                            )}

                                            <div className="input-container">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    style={{ background: !sendButtonVisible && BIBlue }}
                                                    disabled={sendButtonVisible}
                                                    onClick={handleSendExpenses}>
                                                    Send
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <Stack spacing={2} style={{ marginBottom: "15px" }}>
                        <Stack direction="row" spacing={2}>
                            {isUnsentExpenseEmpty && isSentExpenseEmpty ? (
                                <div style={{ margin: "50px auto" }}>
                                    <Placeholder
                                        icon={<DescriptionIcon />}
                                        text="You don't have any Expenses."
                                    />
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            margin: "50px 0",
                                        }}>
                                        <Button
                                            variant="contained"
                                            style={{ background: BIBlue }}
                                            onClick={() => {
                                                setClickMode("NewExpense");
                                                setNewExpenseModalOpen(true);
                                            }}>
                                            Add Expense
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div style={{ flex: 2 }}>
                                    {activeTab === "unsent" ? (
                                        isUnsentExpenseEmpty ? (
                                            <Placeholder
                                                icon={<SearchIcon />}
                                                text="No results found."
                                            />
                                        ) : showLoader ? (
                                            <div>
                                                <Loader />
                                            </div>
                                        ) : (
                                            <ExpensesListSection
                                                fetchExpensesLength={fetchExpensesLength}
                                                increaseOffset={increaseOffset}
                                                getData={getUnsentExpenses}
                                                setClickMode={setClickMode}
                                                setSendButtonVisible={(resArr) => {
                                                    setSelectedExpenses([...resArr]);
                                                    const newCount = resArr?.length > 0 ? false : true;
                                                    setSendButtonVisible(newCount);
                                                }}
                                                activeTab={activeTab}
                                                getEditExpensesData={getEditExpensesData}
                                            />
                                        )
                                    ) : (
                                        <></>
                                    )}

                                    {activeTab === "sent" ? (
                                        isSentExpenseEmpty ? (
                                            <Placeholder
                                                icon={<SearchIcon />}
                                                text="No results found."
                                            />
                                        ) : (
                                            <ExpensesListSection
                                                increaseOffset={increaseOffset}
                                                fetchExpensesLength={fetchExpensesLength}
                                                setClickMode={setClickMode}
                                                getData={getSentExpenses}
                                                activeTab={activeTab}
                                                setSendButtonDisabled={() => setSendButtonVisible(true)}
                                                setSendButtonVisible={(resArr) => {
                                                    setSelectedExpenses([...resArr]);
                                                    const newCount = resArr?.length > 0 ? false : true;
                                                    setSendButtonVisible(newCount);
                                                }}
                                                getEditExpensesData={getEditExpensesData}
                                            />
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )}
                        </Stack>
                    </Stack>
                </div>
            </div>
        </>
    );
}

export default Expenses;
