import React, { useState, useEffect, useCallback } from "react";
import { Container, Stack } from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import BuildIcon from "@mui/icons-material/Build";
import ProfileHeader from "../../components/profile/ProfileHeader";
import AboutSection from "../../components/profile/AboutSection";
import Post from "../../components/post/Post";
import Placeholder from "../../components/Placeholder";
import {
  fetchRelatedTrades,
  fetchTradeNames,
  fetchTrades,
  getTradeMembers,
  saveTradeNames,
} from "../../api/TradesApi";
import { getPosts } from "../../api/PostsApi";
import PostInput from "../../components/post/PostInput";
import { saveUserTrades } from "../../api/TradesApi";
import { fetchUserTrades, deleteUserTrades } from "../../api/TradesApi";
import {
  fetchCompaniesTrades,
  saveCompanies2,
  saveCompanyTrades,
} from "../../api/CompanyApi";
import {
  fetchFollowEntities,
  fetchSitesPictures,
  saveFollowEntities,
} from "../../api/SitesApi";
import PromptModal from "../../components/PromptModal";
import MemberListModal from "../../components/profile/MemberListModal";
import AlertProjectModal from "../../components/AlertProjectModal";
import CompanyListSection from "../../components/profile/CompanyListSection";
import ListSection from "../../components/profile/ListSection";
import {
  fetchTradeProducts,
  saveProductTrades,
  saveProducts,
} from "../../api/ProductsApi";
import { useHistory } from "react-router-dom";
import { fetchUserWorkHistory } from "../../api/UserApi";
import defaultProjectProfileThumbnail from "../../assets/images/project-profile-placeholder-thumbnail.png";
import {
  BusinessCenter,
  LocationOn as MapMarkerIcon,
} from "@material-ui/icons";
import AddAnotherNameModal from "../../components/AddAnotherNameModal";
import Loader from "../../components/Loader";
import { inviteFriendsByEmail } from "../../api/ContactsApi";
import { saveChatGroupUsers } from "../../api/ChatApi";
import ReportModal from "../../components/ReportModal";
import { fetchProjectPictures } from "../../api/ProjectsApi";
import ChatIcon from "@mui/icons-material/Chat";

/*
Trade Profile page
  - About
  - Posts
  - Companies
  - Products
*/
export default function TradeProfile(props) {
  const appId = 7; // 2 is the magic number for Projects

  const [tradeInfo, setTradeInfo] = useState({});
  const [members, setMembers] = useState([]);
  const [activeTab, setActiveTab] = useState("posts");
  const [posts, setPosts] = useState([]);
  const [currentTrade, setCurrentTrade] = useState([]);
  const [isUserTradesEmpty, setIsUserTradesEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [followId, setFollowId] = useState(0);
  const [isAddToProfile, setIsAddToProfile] = useState(false);
  const [tradeSectionAlert, setTradeSectionAlert] = useState(false);
  const [removeTradeSectionAlert, setRemoveTradeSectionAlert] = useState(false);
  const [addedTradeSectionAlert, setAddedTradeSectionAlert] = useState(false);

  const [memberListModalOpen, setMemberListModalOpen] = useState(false);
  const [isMainTrade, setIsMainTrade] = useState([]);

  const [isProductEmpty, setIsProductEmpty] = useState(false);
  const [isSiteEmpty, setIsSiteEmpty] = useState(false);
  const [isProjectEmpty, setIsProjectEmpty] = useState(false);
  const [isCompanyEmpty, setIsCompanyEmpty] = useState(false);
  const history = useHistory();

  const [otherTradeNameList, setOtherTradeNameList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [followLoader, setFollowLoader] = useState(false);
  const [getUpdatedData, setGetUpdatedData] = useState(false);
  const [relatedTrades, setRelatedTrades] = useState([]);
  const [showBtnLoader, setShowBtnLoader] = useState(false);

  const [postId, setPostId] = useState(0);
  const [reportPostName, setRepostPostName] = useState("");
  const [reportModalOpen, setReportModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        await fetchTradeDetails();
        await fetchUserTradeDetails();
        await fetchTradeMemberList();
        await updatePosts();
        await getOtherTradeNameList();
        await fetchFollowsEntity();
        await fetchRelatedData();

        // await getTrades();
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        console.log("Error while recving the trade data :: ", err);
      }
    };
    if (props?.tradeId) {
      getData();
    }
  }, [props?.tradeId]);

  const fetchTradeDetails = async () => {
    try {
      const tradeRes = await fetchTrades({
        SearchList: [{ id: props.tradeId }],
      });
      if (tradeRes?.data?.length >= 0) setTradeInfo(tradeRes?.data[0]);
    } catch (err) {
      console.log("Error while fetching trade details :: ", err);
    }
  };

  const fetchUserTradeDetails = async () => {
    try {
      const userTrades = await fetchUserTrades({
        SearchList: [{ UserId: localStorage.userId }],
      });
      userTrades?.filter((trade) => trade.tradeId === Number(props.tradeId));
      const currentTradeRes = userTrades.filter(
        (trade) => trade.tradeId === Number(props.tradeId)
      );
      const profileTrade = userTrades.filter((tr) => tr.tradeType == "main");
      if (profileTrade.length > 0) {
        setIsMainTrade(profileTrade);
      }
      setCurrentTrade(currentTradeRes);
      if (currentTradeRes.length != 0) {
        setIsAddToProfile(true);
      } else {
        setIsAddToProfile(false);
      }

      setCurrentTrade(currentTradeRes);
      if (currentTradeRes.length != 0) {
        setIsAddToProfile(true);
      } else {
        setIsAddToProfile(false);
      }
    } catch (err) {
      console.log("Error while fetching trade details :: ", err);
    }
  };

  const fetchTradeMemberList = async () => {
    try {
      const memberRes = await getTradeMembers(props.tradeId);
      const isTradeMember = memberRes.some(
        (member) => member.userId === Number(localStorage.userId)
      );
      setIsMember(isTradeMember);
      setMembers(memberRes);
    } catch (error) {
      console.log("Error while fetching trade member list :: ", error);
    }
  };

  const updatePosts = useCallback(() => {
    getPosts(appId, props.tradeId, 1, 25).then((res) => {
      if (res) {
        setPosts(res);
      }
    });
  }, [props]);

  const getOtherTradeNameList = async () => {
    try {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        TypeOfObjectReturned: "",
        SearchList: [{ TradeId: props?.tradeId }],
      };
      await fetchTradeNames(obj)
        .then((res) => {
          setOtherTradeNameList(res?.data ?? []);
        })
        .catch((err) => {
          console.log("Error while getting trade name list:-", err);
        });
    } catch (err) {
      console.log("Error while getting site trade list:-", err);
    }
  };

  const fetchRelatedData = async () => {
    try {
      const obj = {
        PageNr: 1,
        IncludeRecordNr: true,
        SearchList: [{ tradeId: props?.tradeId }],
      };
      const trades = await fetchRelatedTrades(obj);
      setRelatedTrades(trades?.data ?? []);
    } catch (error) {
      console.error("Error fetching related trades:", error);
    }
  };

  async function fetchFollowsEntity() {
    setFollowLoader(true);
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            EntityId: Number(props.tradeId),
            userId: Number(localStorage.userId),
          },
        ],
      };
      const followingData = await fetchFollowEntities(obj);

      if (followingData.length != 0) {
        setFollowId(followingData.data[0]?.id ?? 0);
        setIsFollow(followingData.data[0]?.isFollowing);
      } else {
        setFollowId(0);
        setIsFollow(false);
      }
      setFollowLoader(false);
    } catch (error) {
      console.log("GET_Follow Entity [ERROR]::", error);
      setFollowLoader(false);
    }
  }

  async function handleSaveFollowClick(isFollow) {
    setFollowLoader(true);
    try {
      const saveEntitiesObject = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: followId,
            EntityTypeId: 7,
            EntityId: Number(props.tradeId),
            UserId: Number(localStorage.userId),
            IsFollowing: isFollow,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const followRes = await saveFollowEntities(saveEntitiesObject);
      fetchTradeMemberList();
      // getData();
      fetchUserTradeDetails();
      fetchFollowsEntity();
      setFollowLoader(false);
    } catch (e) {
      console.log("SAVE_FOLLOW__[ERROR]:", e);
      setFollowLoader(false);
    }
  }

  function handleFollowPressed() {
    handleSaveFollowClick(true);
  }

  function handleFollowingPressed() {
    setTradeSectionAlert(true);
  }

  function handleAddToProfileButton() {
    handleSaveUserTrades();
  }

  async function handleRightIconPressed() {
    setRemoveTradeSectionAlert(true);
  }

  async function handleSaveUserTrades() {
    setFollowLoader(true);
    try {
      const newUserTrade = {
        ReturnRecordId: false,
        ReturnRecordError: true,
        UserId: Number(localStorage.userId),
        SaveList: [
          {
            UserId: Number(localStorage.userId),
            TradeId: Number(props.tradeId),
            TradeType: isUserTradesEmpty ? "Main" : "Other",
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const res = await saveUserTrades(newUserTrade);
      setIsAddToProfile(true);
      handleSaveFollowClick(true);
      setFollowLoader(false);
    } catch (error) {
      setFollowLoader(false);
      console.log("Save_Users_Trades [ERROR]::", error);
    }
  }

  async function handleDeleteFollowingClick() {
    if (isMainTrade[0]?.id == currentTrade[0].id) {
      setAddedTradeSectionAlert(true);
      return;
    }
    setFollowLoader(true);
    try {
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordError: true,
        DeleteList: [
          { Id: currentTrade[0].id, ModifiedBy: Number(localStorage.userId) },
        ],
      };
      const res = await deleteUserTrades(obj);
      handleSaveFollowClick(false);
      setFollowLoader(false);
    } catch (error) {
      setFollowLoader(false);
      console.log("DELETE TRADE [ERROR]::", error);
    }
  }

  function handleMemberListPressed() {
    setMemberListModalOpen(true);
  }

  const getTradesCompaniesData = useCallback(async () => {
    try {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: false,
        TypeOfObjectReturned: "",
        NrOfRecPerPage: 10,
        SearchList: [{ TradeId: props?.tradeId }],
      };

      const res = await fetchCompaniesTrades(obj);
      const resultData = { data: res };
      setIsCompanyEmpty(res?.length === 0 ? true : false);
      return resultData;
    } catch (Error) {
      console.log("GET SITE_COMPANIES-2 [ERROR]::", Error);
      // setIsLoading(false);
    }
  }, [props]);

  function saveCompany(params) {
    if (params.companyName === "") {
      getTradeCompany(params);
    } else {
      handleSaveCompanies(params);
    }
  }

  async function handleSaveCompanies(params) {
    try {
      const saveObject = {
        userId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: params.companyName,
            ModifiedBy: Number(localStorage.userId),
            RecordStatusId: 1,
            Manufacturer: false,
          },
        ],
      };
      const siteCompaniesRes = await saveCompanies2(saveObject);
      let newItem = {
        id: siteCompaniesRes[0]?.objData?.id,
        roleId: params.roleId,
        otherRoleName: params.otherRoleName,
      };
      getTradeCompany(newItem);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
    }
  }

  async function getTradeCompany(params) {
    try {
      setIsLoading(true);
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            CompanyId: params.companyName == "" ? params.id : params.id,
            TradeId: Number(props.tradeId),
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await saveCompanyTrades(obj);
      setGetUpdatedData(true);
      setIsLoading(false);
    } catch (ERROR) {
      console.log("GET SITE_COMPANIES [ERROR]::", ERROR);
      setIsLoading(false);
    }
  }

  // Products
  async function getTradesProductsData() {
    try {
      const obj = {
        FullSearch: "",
        SearchList: [{ TradeId: props?.tradeId }],
        userId: Number(localStorage.userId),
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: true,
      };
      const data = await fetchTradeProducts(obj);
      setIsProductEmpty(data?.data?.length === 0 ? true : false);
      return data;
    } catch (Error) {
      console.log("GET SITE_COMPANIES-2 [ERROR]::", Error);
      setIsLoading(false);
    }
  }

  function saveProduct(params) {
    if (params.productId != 0) {
      addProductToTrade(params);
    } else {
      handleSaveProduct(params);
    }
  }

  function handleSaveProduct(params) {
    if (params.manufacturerName !== "" && params.productName === "") {
      handleSaveManufaturer(params);
    } else if (params.manufacturerName !== "" && params.productName !== "") {
      handleSaveManufaturer(params);
    } else {
      handleSaveNewProduct(params);
    }
  }

  async function handleSaveManufaturer(params) {
    try {
      const saveObject = {
        userId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: params.manufacturerName,
            RecordStatusId: 1,
            ManufacturerId:
              params.getManufacturerId != 0
                ? params.getManufacturerId
                : params.newManufacturerId,
            Manufacturer: true,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const siteCompaniesRes = await saveCompanies2(saveObject);
      let newItem = {
        newManufacturerId: siteCompaniesRes[0]?.objData?.id,
        newProductName: params.productName,
        productType: params?.productType ?? "",
      };
      handleSaveNewProduct(newItem);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
    }
  }

  async function handleSaveNewProduct(params) {
    try {
      const saveObject = {
        userId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: params.productName
              ? params.productName
              : params.newProductName,
            RecordStatusId: 1,
            ManufacturerId: params.getManufacturerId
              ? params.getManufacturerId
              : params.newManufacturerId,
            ModifiedBy: Number(localStorage.userId),
            productType: params?.productType ?? "",
          },
        ],
      };
      const productRes = await saveProducts(saveObject);
      let newItem = {
        newProductId: productRes?.data[0]?.objData?.id,
        nullManufactureId: productRes?.data[0]?.objData?.manufacturerId,
      };
      addProductToTrade(newItem);
    } catch (error) {
      console.log("SAVE SITE_COMPANIES [ERROR]::", error);
    }
  }

  async function addProductToTrade(params) {
    try {
      setIsLoading(true);
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            ProductId: params.productId
              ? params.productId
              : params.newProductId,
            TradeId: props?.tradeId,
            ModifiedBy: Number(localStorage.userId),
            RecordStatusId: 1,
          },
        ],
      };
      const res = await saveProductTrades(obj);
      setIsProductEmpty(false);
      if (params.nullManufactureId == 2) {
        history.push(
          `/product/${
            params.productId ? params.productId : params.newProductId
          }`
        );
      }
      setGetUpdatedData(true);
      setIsLoading(false);
    } catch (ERROR) {
      console.log("GET SITE_Products [ERROR]::", ERROR);
      setIsLoading(false);
    }
  }

  // Sites
  async function getTradeSites() {
    try {
      const res = await fetchUserWorkHistory({
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        SearchList: [{ TradeId: props?.tradeId ?? 0 }],
      });
      // Overwrite API response res.data with an updated array where the duplicate items are omitted
      let uniqueSites = [];
      res?.forEach((project) => {
        let alreadyInArray = false;
        uniqueSites.forEach((uniqueSite) => {
          if (project.siteId === uniqueSite.siteId) alreadyInArray = true;
        });

        if (!alreadyInArray) uniqueSites.push(project);
      });

      // Retrieve Site pictures and use the first one as the list thumbnail
      // If there is no pictures for a Site, use the default image for Sites
      await Promise.all(
        uniqueSites.map((project) =>
          fetchSitesPictures({ SearchList: [{ SiteId: project.siteId }] }).then(
            (res) => {
              if (res.length > 0) project.image = res[0].thumbImageUrl;
              else project.image = defaultProjectProfileThumbnail;
            }
          )
        )
      );
      res.data = uniqueSites;
      setIsSiteEmpty(uniqueSites?.length === 0 ? true : false);
      return res;
    } catch (err) {
      console.log("Error while getting site data list in trades:--", err);
    }
  }

  // Sites
  async function getTradeProjects() {
    try {
      const res = await fetchUserWorkHistory({
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        SearchList: [{ TradeId: props?.tradeId ?? 0 }],
      });

      // Overwrite API response res.data with an updated array where the duplicate items are omitted
      let uniqueProjects = [];
      res?.forEach((project) => {
        let alreadyInArray = false;

        uniqueProjects.forEach((uniqueProject) => {
          if (project.projectId === uniqueProject.projectId)
            alreadyInArray = true;
        });

        if (!alreadyInArray) uniqueProjects.push(project);
      });

      // Retrieve Site pictures and use the first one as the list thumbnail
      // If there is no pictures for a Site, use the default image for Sites
      await Promise.all(
        uniqueProjects.map((project) =>
          fetchProjectPictures({
            SearchList: [{ projectId: project.projectId }],
          }).then((res) => {
            if (res.length > 0) project.image = res[0].thumbImageUrl;
            else project.image = defaultProjectProfileThumbnail;
          })
        )
      );
      res.data = uniqueProjects;
      setIsProjectEmpty(uniqueProjects?.length === 0 ? true : false);
      return res;
    } catch (err) {
      console.log("Error while getting site data list in projects:--", err);
    }
  }

  const onMenuOptionClick = (data) => {
    if (data === "Add another Trade name") {
      setShowModal(true);
    } else {
      console.log("No Such Options");
    }
  };

  const onAotherNameSave = async (name) => {
    try {
      const obj = {
        UserId: Number(localStorage?.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            TradeId: props?.tradeId,
            Name: name,
            Current: false,
            RecordStatusId: 1,
            ModifiedBy: Number(localStorage?.userId),
          },
        ],
      };
      await saveTradeNames(obj)
        .then((res) => {
          setOtherTradeNameList([...otherTradeNameList, { name: name }]);
          setShowModal(false);
        })
        .catch((err) => {
          setShowModal(false);
          console.log("Error while saving another trade name:-", err);
        });
    } catch (err) {
      setShowModal(false);
      console.log("Error while saving another trade name:-", err);
    }
  };

  if (showLoader) {
    return (
      <div className="profile-loader">
        <Loader />
      </div>
    );
  }

  async function onProductSave(productId, goToProductPage) {
    try {
      setIsLoading(true);
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            ProductId: productId,
            TradeId: props?.tradeId,
            ModifiedBy: Number(localStorage.userId),
            RecordStatusId: 1,
          },
        ],
      };
      saveProductTrades(obj);
      setIsProductEmpty(false);
      setGetUpdatedData(true);
      setIsLoading(false);
      if (goToProductPage) {
        history.push(`/product/${productId}`);
        return;
      }
    } catch (ERROR) {
      console.log("GET SITE_Products [ERROR]::", ERROR);
      setIsLoading(false);
    }
  }

  const handleAddContact = async (data) => {
    try {
      setShowBtnLoader(true);
      let obj = {
        UserId: localStorage.userId,
        FriendsEmails: data?.userEmail,
        Notification: {
          ActionUrl: `${process.env.REACT_APP_CONTACTPROFILE}${Number(
            localStorage.userId
          )}`,
          title: "Connect Request",
          Body: "has requested to Connect you.",
          //NotifyUserList: `${contact?.userId}`
        },
      };
      await inviteFriendsByEmail(obj);
      await fetchTradeMemberList();
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("handleAddContact error", error);
    }
  };

  const navigateTochat = async (data) => {
    try {
      setShowBtnLoader(true);
      const saveListArray = [
        {
          Id: 0,
          ChatGroupId: 0,
          UserId: data?.userId,
          ModifiedBy: Number(localStorage.getItem("userId")),
          Muted: false,
        },
      ];
      const chatGroupObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: saveListArray,
      };
      let result = await saveChatGroupUsers(chatGroupObj);
      if (result?.data?.length > 0) {
        let chatGroupId = result.data[0]?.objData.chatGroupId;
        history.push({
          pathname: "/chat",
          state: {
            from: "New Chat",
            chatGroupId: chatGroupId,
            showInList: true,
          },
        });
      }
      setShowBtnLoader(false);
    } catch (error) {
      setShowBtnLoader(false);
      console.log("Error while sending to chat screen :: ", error);
    }
  };

  return (
    <div className="page">
      <ReportModal
        isOpen={reportModalOpen}
        onClose={() => setReportModalOpen(false)}
        name={reportPostName == "Trade Ads" ? "Trade Ad" : "Trade Post"}
        recordId={postId}
      />
      <MemberListModal
        isOpen={memberListModalOpen}
        onClose={() => {
          setMemberListModalOpen(false);
        }}
        members={members}
        profileMemberList={false}
        handleConnectClick={handleAddContact}
        handleMessageClick={navigateTochat}
        showBtnLoader={showBtnLoader}
      />

      <PromptModal
        isOpen={tradeSectionAlert}
        onClose={() => {
          setTradeSectionAlert(false);
        }}
        title="Are you sure you want to unfollow this Trade Page"
        redButtonText="YES"
        blueButtonTextFirst="No"
        onRedButtonClick={() => {
          handleSaveFollowClick(false);
          setTradeSectionAlert(false);
        }}
      />

      <PromptModal
        isOpen={removeTradeSectionAlert}
        onClose={() => {
          setRemoveTradeSectionAlert(false);
        }}
        title="Remove from your Profile"
        redButtonText="REMOVE"
        blueButtonTextFirst="CANCEL"
        onRedButtonClick={() => {
          handleDeleteFollowingClick();
          setRemoveTradeSectionAlert(false);
        }}
      />

      <AlertProjectModal
        isOpen={addedTradeSectionAlert}
        onClose={() => setAddedTradeSectionAlert(false)}
        content={
          "This trade cannot be deleted because it is used with one or more of the projects in your timeline."
        }
      />

      <div className="page-content">
        <Stack spacing={2} style={{ margin: "16px 0px" }}>
          <ProfileHeader
            isFollow={isFollow}
            images={[]}
            title={tradeInfo.name}
            subtitle="Trade"
            imageUrl={tradeInfo?.tradePictureUrl ?? ""}
            members={members}
            handleFollowClick={() => handleFollowPressed()}
            handleFollowingClick={() => handleFollowingPressed()}
            handleAddToProfileButton={() => handleAddToProfileButton()}
            handleRightIconPressed={() => handleRightIconPressed()}
            handleMemberListPressed={() => handleMemberListPressed()}
            isLoading={followLoader}
            isMember={isMember}
            isAddToProfile={isAddToProfile}
            btnTitle1={"Follow"}
            btnTitle={"ADD TO PROFILE"}
            tabs={["posts", "companies", "products", "projects"]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            menuOptions={["Send in Chat", "Share"]}
            onMenuOptionClick={onMenuOptionClick}
            shareDetails={{
              type: "TradeDetail",
              id: props?.tradeId,
              thirdParam: tradeInfo?.tradePictureUrl
                ? tradeInfo?.tradePictureUrl.replace(/^https:\/\//i, "")
                : "image_url",
            }}
            from="Trade"
          />

          <Stack direction="row" spacing={2}>
            <div style={{ flex: 1 }}>
              {tradeInfo?.description ||
              otherTradeNameList.length > 0 ||
              otherTradeNameList.length > 0 ? (
                <AboutSection
                  withCard
                  content={{}}
                  tradeDescription={tradeInfo?.description ?? ""}
                  otherNameList={otherTradeNameList}
                  tradeAboutSection
                  relatedTrades={relatedTrades}
                />
              ) : (
                <Placeholder
                  icon={
                    <BuildIcon style={{ height: "100px", width: "100px" }} />
                  }
                  text="No information about this Trade."
                  text2="Know any information? Add it here for builderbucks"
                />
              )}
            </div>

            <div style={{ flex: 2 }}>
              {activeTab === "posts" ? (
                <Stack spacing={2}>
                  <PostInput
                    pageName={"Trade Network"}
                    post={props.post}
                    appId={7}
                    appRecordId={props.tradeId}
                    updatePosts={updatePosts}
                  />
                  {posts?.length != 0 ? (
                    <>
                      {posts.map((post, index) => (
                        <Post
                          key={index}
                          post={post}
                          from={"tradeDetail"}
                          updatePosts={updatePosts}
                          setPostId={(value) => setPostId(value)}
                          setReportModalOpen={(value) =>
                            setReportModalOpen(value)
                          }
                          setRepostPostName={(value) =>
                            setRepostPostName(value)
                          }
                        />
                      ))}
                    </>
                  ) : (
                    <Placeholder
                      icon={<ChatIcon />}
                      text={"Share your thoughts with other BuildId Users"}
                      text2={props?.NoResultText2 ?? ""}
                    />
                  )}
                </Stack>
              ) : (
                <></>
              )}

              {activeTab === "companies" ? (
                <CompanyListSection
                  getData={getTradesCompaniesData}
                  itemIdKey="companyId"
                  itemNameKey="companyName"
                  itemRecordType="company"
                  categoryIdKey="siteCompanyRoleId"
                  categoryNameKey="siteCompanyRole"
                  otherSiteCompanyRole="otherSiteCompanyRole"
                  searchPlaceholder="Search this Trade's Companies"
                  addButtonLabel="Add Company"
                  saveItem={saveCompany}
                  isLoading={isLoading}
                  searchTopDescription={"Companies that use this Trade"}
                  emptyData={isCompanyEmpty}
                  NoResultBtn="Add Company"
                  NOResultIcon={<WorkIcon />}
                  NoResultText="No information about this Trade."
                  NoResultText2="Know any information? Add it here for builderbucks"
                  hideRole={true}
                  getUpdatedData={getUpdatedData}
                  changeGetUpdateDataValue={() => {
                    setGetUpdatedData(false);
                  }}
                  from={"Trade"}
                  modalTitle="Add Company to Trade"
                />
              ) : (
                <></>
              )}

              {activeTab === "products" ? (
                <ListSection
                  getData={getTradesProductsData}
                  itemIdKey="productId"
                  itemNameKey="productName"
                  categoryIdKey="manufacturerId"
                  categoryNameKey="manufacturerName"
                  itemRecordType="product"
                  categoryRecordType="manufacturer"
                  searchPlaceholder="Search this Trade's Products"
                  addButtonLabel="Add Product"
                  onProductSave={onProductSave}
                  isLoading={isLoading}
                  searchTopDescription={
                    "Products that are useful to this trade"
                  }
                  emptyData={isProductEmpty}
                  NoResultBtn="Add Product"
                  NOResultIcon={<BuildIcon />}
                  NoResultText="No information for this Trade."
                  NoResultText2="Know any information? Add it here for builderbucks"
                  getUpdatedData={getUpdatedData}
                  changeGetUpdateDataValue={() => {
                    setGetUpdatedData(false);
                  }}
                  showManufactureNameInNewAddProduct={true}
                  modalTitle="Add Product to Trade"
                />
              ) : (
                <></>
              )}

              {activeTab === "sites" ? (
                <ListSection
                  getData={getTradeSites}
                  itemIdKey="siteId"
                  itemImgKey="image"
                  itemNameKey="siteName"
                  itemSecondaryKey="address"
                  itemRecordType="site"
                  searchPlaceholder="Search this Trade's Sites"
                  searchTopDescription="Sites that this Trade has worked on"
                  buttonLabel="View on map"
                  onButtonClick={() => {
                    history.push("/sites");
                  }}
                  emptyData={isSiteEmpty}
                  NOResultIcon={<MapMarkerIcon />}
                  NoResultText="Know any Sites that have used this trade?"
                  NoResultText2="Let us know for builderbucks!"
                />
              ) : (
                <></>
              )}

              {activeTab === "projects" ? (
                <ListSection
                  getData={getTradeProjects}
                  itemIdKey="projectId"
                  itemImgKey="image"
                  itemNameKey="projectName"
                  itemRecordType="projects"
                  searchPlaceholder="Search this Trade's Projects"
                  searchTopDescription="Projects that this Trade has worked on"
                  emptyData={isProjectEmpty}
                  NOResultIcon={<BusinessCenter />}
                  NoResultText="No information for this Trade."
                  NoResultText2="Know any information? Add it here for builderbucks!"
                />
              ) : (
                <></>
              )}
            </div>
          </Stack>
        </Stack>
      </div>
      <AddAnotherNameModal
        onClose={() => {
          setShowModal(false);
        }}
        open={showModal}
        label="Another Trade Name"
        onAotherNameSave={onAotherNameSave}
      />
    </div>
  );
}
