import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar, Chip, IconButton, Stack } from '@mui/material';
import AvtarImage from "../../../assets/images/AvatarImage.jpeg";
import { BIGrey, BILightGrey } from '../../../assets/buildidColors';
import moment from 'moment';
import DoneIcon from '@mui/icons-material/Done';
import { FetchClientCommunicator2, saveClientCommunicatorAcknowledgements } from '../../../api/ClientApi';
import Loader from '../../../components/Loader';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const CommunicatorModal = ({ open, handleClose, selectedCommunicatorDetails, fetchClientCommunicator }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [communicatorDetails, setCommunicatorDetails] = useState(selectedCommunicatorDetails ?? null)

    useEffect(()=>{
            console.log("CommunicatorDetails Modal : ",communicatorDetails)
    },[communicatorDetails])


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        height: "60%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        outline: 'none',
    };

    // useEffect(() => {
    //     FetchClientCommunicator2();
    // }, [])

    const fetchCommunicatorDetails = async () => {
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: localStorage?.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{ Id: communicatorDetails?.id }],
            };
            let res = await FetchClientCommunicator2(obj);
            console.log("res ===>", res)
        } catch (error) {
            console.log("Error while getting communicator details :: ", error);
        }
    }

    const onAcknowledgePressed = async () => {
        try {
            setIsLoading(true);
            const clientEmployeeId = localStorage.getItem("clientEmployeeId");
            const obj = {
                UserId: localStorage?.userId,
                ReturnRecordId: true,
                ReturnRecordError: true,
                SaveList: [
                    {
                        Id: 0,
                        acknowledged: 0,
                        ClientEmployeeId: clientEmployeeId,
                        ClientCommunicatorId: communicatorDetails?.id,
                        ModifiedBy: localStorage?.userId
                    }
                ]
            }
            const res = await saveClientCommunicatorAcknowledgements(obj);
            if (res.errorMessage === "" || res.status !== false) {
                setCommunicatorDetails({
                    ...communicatorDetails,
                    acknowledged: 1,
                })
                fetchClientCommunicator()
            }
            // fetchCommunicatorDetails();
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            console.log("Error while acknowledge the communicator details :: ", error);
            alert("Something went wrong! please try again later")
        }
    }

    if (!open) {
        return null;
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        isLoading ?
                            (
                                <Stack justifyContent={"center"} alignItems={"center"} sx={{ height: '100%' }}>
                                    <Loader />
                                </Stack>
                            )
                            :
                            (
                                <Stack direction={'column'} justifyContent={'space-between'} sx={{ height: '100%' }}>
                                    <div>
                                        <Typography id="modal-modal-title" variant="h4" sx={{ fontWeight: 600 }}>
                                            {communicatorDetails?.subject ?? ""}
                                            <IconButton style={{ float: 'right' }} onClick={handleClose} >
                                                <CloseIcon />
                                            </IconButton>
                                        </Typography>
                                        <div>
                                            <Chip label={communicatorDetails?.clientCommunicatorCategory ?? ""} />
                                        </div>
                                        <Stack direction={'row'} sx={{
                                            justifyContent: "flex-start",
                                            alignItems: "center",
                                            marginTop: '20px'
                                        }} spacing={1}>
                                            <Avatar alt="" src={communicatorDetails?.profilePictureUrl != '' ? communicatorDetails?.profilePictureUrl : AvtarImage} />

                                            <Stack direction={'row'} spacing={2}
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                                style={{ width: '100%' }}
                                            >
                                                <Typography >Mihai Velicu</Typography>
                                                <Typography style={{ color: BIGrey }}>
                                                    {moment(communicatorDetails?.date).format("MMMM D, YYYY")}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                            {communicatorDetails?.body}
                                        </Typography>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        {
                                            communicatorDetails?.acknowledged === 1 ?
                                                (
                                                    <Button
                                                        component="label"
                                                        variant="filled"
                                                        tabIndex={-1}
                                                        disabled
                                                        sx={{ backgroundColor: BILightGrey, width: '50%', textTransform: 'none' }}
                                                        // startIcon={<DoneIcon />}
                                                    >
                                                        Acknowledged
                                                    </Button>
                                                )
                                                :
                                                (
                                                    <Button
                                                        component="label"
                                                        tabIndex={-1}
                                                        sx={{ width: '50%', textTransform: 'none' }}
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={onAcknowledgePressed}
                                                        startIcon={<DoneIcon />}
                                                    >
                                                        Acknowledge
                                                    </Button>
                                                )
                                        }
                                    </div>
                                </Stack>
                            )
                    }

                </Box>
            </Modal>
        </>
    )
}

export default CommunicatorModal