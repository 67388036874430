import React, { useEffect, useRef, useState } from "react";

import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { LoadingButton } from "@mui/lab";
import { Close } from "@material-ui/icons";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import {
  fetchSupportCategoryList,
  fetchSupportUrgencyList,
  saveClientSupport,
  saveClientSupportPictures,
} from "../../../api/ClientApi";

// code by Vaibhav mahajan 0n 9/10/2024
export default function ClientSupportAddModal({ open, onClose, reloadData }) {
  const userId = localStorage.userId;
  const clientId = localStorage.clientId;

  const inputRef = useRef();
  const [date, setDate] = useState();
  const [images, setImage] = useState([]);
  const [urgencyId, setUrgencyId] = useState("0");
  const [categoryId, setCategoryId] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [UrgencyList, setUrgencyList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);

  useEffect(() => {
    getUrgencyList();
    getCategoryList();
  }, []);

  const getUrgencyList = async () => {
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SortList: [
        {
          FieldName: "id",
          Direction: "ASC",
        },
      ],
    };
    const res = await fetchSupportUrgencyList(obj);
    setUrgencyList(res?.data);
  };
  const getCategoryList = async () => {
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          clientId: clientId,
        },
      ],
      SortList: [
        {
          FieldName: "id",
          Direction: "ASC",
        },
      ],
    };

    const res = await fetchSupportCategoryList(obj);
    setCategoryList(res?.data);
  };

  const handelSubmit = async () => {
    setIsLoading(true);
    try {
      const support_request_Object = {
        ReturnRecordId: true,
        ReturnRecordError: true,
        UserId: Number(userId),
        SaveList: [
          {
            Id: 0,
            //  // ClientId: clientsApps[0].ClientId,
            ClientEmployeeId: localStorage?.clientEmployeeId,
            Description: description,
            UrgencyId: urgencyId,
            ECDate:
              date == "Invalid date" || date == ""
                ? ""
                : moment(date).format("YYYY-MM-DD"),
            // // CategoryId: categoryValue.id,
            // UDFields: categoryValue.id != undefined ? `{"${allUdFields[0].id}":${categoryValue.id}}` : '',
            SupportCategoryId: categoryId,
            Status: "Open",
            // IsPublic: true,
            ModifiedBy: Number(userId),
          },
        ],
      };
      const res = await saveClientSupport(support_request_Object);
      if (res?.data[0]?.message == "OK") {
        if (images.length != 0) {
          let formDataArray = images.map((img) => {
            let formData = new FormData();
            formData.append("Id", 0);
            formData.append("SupportId", res?.data[0]?.objData.id);
            formData.append("FileName", img);
            formData.append("ModifiedBy", Number(userId));
            return formData;
          });
          let promises = formDataArray.map((formData) =>
            saveClientSupportPictures(formData)
          );
          let a = await Promise.all(promises);
        }
      }
      setIsLoading(false);
      reloadData();
      handelClose();
    } catch (e) {
      console.log("ERROR : [onSubmit] : >> ", e);
      setIsLoading(false);
    }
  };

  const handleImageChange = (event) => {
    let temp = [];
    let file = event.target.files;
    for (let i = 0; i < event.target.files.length; i++) {
      temp.push(file[i]);
    }
    setImage((prev) => [...prev, ...temp]);
    event.target.value = null;
  };

  const handelImageDelete = (index) => {
    let temp = [...images];
    temp.splice(index, 1);
    setImage(temp);
  };

  const handleCategoryChange = (event) => {
    setCategoryId(event.target.value);
  };

  const handleDateChange = (e) => {
    let date = moment(e).format("YYYY-MM-DD");
    setDate(date);
  };

  const resetState = () => {
    setDescription("");
    setUrgencyId("0");
    setCategoryId("0");
    setDate("");
    setImage([]);
  };

  const handelClose = () => {
    resetState();
    onClose();
  };

  const imgUrl = (file) => {
    try {
      let url = URL.createObjectURL(file);
      return url;
    } catch (e) {
      return file;
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              width: "600px",
            }}
            className="modal-card"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                color="primary"
                onClick={handelClose}
                style={{ padding: "0px" }}
              >
                <Close />
              </IconButton>
            </div>

            <Grid container sx={12} md={12} xl={12} lg={12} sm={12}>
              <Grid item marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
                <Typography>Description</Typography>
                <TextField
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                    marginTop: "5px",
                  }}
                  fullWidth
                  size="small"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  placeholder="Describe the problem and what needs to be done"
                  multiline
                  rows={4}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
                <Typography>Urgency</Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={urgencyId}
                  fullWidth
                  label="Age"
                  onChange={(event) => {
                    setUrgencyId(event.target.value);
                  }}
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderRadius: "10px",
                    height: "40px",
                  }}
                  size="small"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                >
                  <MenuItem disabled value="0">
                    <Typography
                      style={{
                        color: "gray",
                      }}
                    >
                      Select option
                    </Typography>
                  </MenuItem>
                  {UrgencyList.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
                <Typography>Expected Completion Date</Typography>
                <KeyboardDatePicker
                  inputVariant={"standard"}
                  size="small"
                  autoOk
                  open={openDatePicker} // controlled open state
                  onClose={() => setOpenDatePicker(false)} // close date picker on date selection
                  fullWidth
                  variant="outlined"
                  placeholder="select date (optional)"
                  error={false}
                  helperText={null}
                  value={date === null || date === "Invalid date" ? null : date}
                  // value={startDate === null || startDate === "Invalid date" ? null : stringToLocale(startDate)}
                  InputAdornmentProps={{
                    position: "end",
                  }}
                  keyboardIcon={
                    <CalendarTodayIcon
                      style={{
                        color: localStorage.clientAccentColor,
                      }}
                    />
                  }
                  onChange={(date) => handleDateChange(date)}
                  minDate={new Date()}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      height: "40px",
                      padding: "  0 10px",
                    },
                    readOnly: true, // disable text field editing
                    onClick: () => setOpenDatePicker(true), // open date picker on text field click
                  }}
                />
              </Grid>
              <Grid item marginTop={1} sx={12} md={12} xl={12} lg={12} sm={12}>
                <Typography>Category</Typography>
                <FormControl fullWidth variant="outlined">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={categoryId}
                    style={{
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      height: "40px",
                    }}
                    size="small"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    onChange={handleCategoryChange}
                  >
                    <MenuItem disabled value="0">
                      <Typography
                        style={{
                          color: "gray",
                        }}
                      >
                        Select Category (optional)
                      </Typography>
                    </MenuItem>
                    {categoryList.map((item) => (
                      <MenuItem value={item?.id}>
                        {item?.supportCategoryName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item marginTop={2} sx={12} md={12} xl={12} lg={12} sm={12}>
                <input
                  hidden
                  multiple
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={inputRef}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    onClick={() => inputRef.current.click()}
                    style={{
                      minHeight: "100px",
                      minWidth: "100px",
                      border: "1px solid lightgray",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "10px",
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "lightgray",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      maxWidth: "100%",
                      overflow: "auto",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    {images?.map((item, index) => (
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          src={imgUrl(item)}
                          alt="no image"
                          style={{
                            maxHeight: "100px",
                            minWidth: "100px",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <IconButton
                          onClick={() => handelImageDelete(index)}
                          style={{
                            position: "absolute",
                            backgroundColor: "white",
                            height: "25px",
                            width: "25px",
                            top: "0px",
                            right: "0px",
                          }}
                        >
                          <Close
                            style={{
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item marginTop={2} sx={12} md={12} xl={12} lg={12} sm={12}>
                <LoadingButton
                  onClick={handelSubmit}
                  fullWidth
                  style={{
                    backgroundColor:
                      description == "" || urgencyId == "0"
                        ? "lightgray"
                        : localStorage.clientAccentColor,
                    color: "white",
                    borderRadius: "10px",
                  }}
                  loadingPosition="start"
                  disabled={description == "" || urgencyId == "0"}
                  loading={isLoading}
                >
                  Submit
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
}
