import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import { requestTrade } from "../api/TradesApi";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, TextField } from "@material-ui/core";
import { ToastProvider, useToasts } from "react-toast-notifications";

const RequestTradeModal = (props) => {
  const [name, setName] = useState("");
  const [submit, setSubmit] = useState(false);
  const { addToast } = useToasts();
  const handleClose = () => {
    setName("");
    setSubmit(false);
    props.onClose();
  };

  const onRequestSubmit = async () => {
    // demo-web

    try {
      setSubmit(true);
      const formData = new FormData();
      formData.append("Id", 0);
      formData.append("Name", name);
      formData.append("TradePictureUrl", null);
      formData.append("Description", "");
      formData.append("ModifiedBy", 1);
      await requestTrade(formData)
        .then((res) => {
          if (res.message === "OK") {
            addToast(
              "Thanks for requesting a Trade! We will review to be added to BuildId.",
              {
                style: {
                  cursor: "pointer",
                  marginTop: "50px",
                  background: "#fce7b0",
                  color: "orange",
                },
                appearance: "success",
                autoDismiss: true,
              }
            );
            handleClose();
          }
        })
        .catch((err) => {
          console.log("Error while requesting the new trade::", err);
          handleClose();
        });
    } catch (error) {
      handleClose();
      console.log("Error while requesting the new trade::", error);
    }
  };
  return (
    <>
      <ToastProvider>
        <Dialog onClose={handleClose} open={props.open} fullWidth maxWidth="sm">
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Request a Trade
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <div className="input-container">
              <TextField
                autoFocus
                variant="outlined"
                label={"Trade"}
                fullWidth
                defaultValue={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter" && name.trim()) {
                    !submit && onRequestSubmit();
                  }
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                !submit && onRequestSubmit();
              }}
              disabled={!name.trim()}
              color="primary"
            >
              {!submit ? "Submit" : <CircularProgress size={24} />}
            </Button>
          </DialogActions>
        </Dialog>
      </ToastProvider>
    </>
  );
};

export default RequestTradeModal;
