import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { fetchUserProducts } from "../../../api/ProductsApi";
import SelectProductModal from "../../../components/SelectProductModal";
import { Card, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import moment from "moment";

export default function ProductCard(props) {
  const [showSelectProductModal, setShowSelectProductModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const [noProduct, setNoproduct] = useState(false);
  const history = useHistory();

  useEffect(() => {
    handleFetchProducts();
  }, []);

  async function handleFetchProducts() {
    try {
      const obj = {
        PageNr: 1,
        FullSearch: "",
        NrOfRecPerPage: 0,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        UserId: Number(localStorage.userId),
        SearchList: [{ UserId: Number(localStorage.userId) }],
        FetchAllowedRecordsOnly: true,
      }
      const res = await fetchUserProducts(obj);
      if (res?.data?.length === 0) {
        setNoproduct(true);
        setProductData([]);
      } else {
        setNoproduct(false);
        setProductData(res?.data?.sort((a, b) => {
          if (a.endDate == '' || a.endDate == null) a.endDate = moment();
          if (b.endDate == '' || b.endDate == null) b.endDate = moment();
          let dateA = new Date(a.endDate), dateB = new Date(b.endDate);
          return dateB - dateA
        }));
      }
    } catch (error) {
      console.log("fetchUserProducts--Error", error);
    }
  }

  const handleMyProducts = () => {
    setShowSelectProductModal(true);
  }


  if (props?.loading) {
    return (<></>)
  }

  const getProductDescriptionText = (item) => {
    if (item.productType && item.manufacturerName) {
      return `${item.productType} | ${item.manufacturerName}`;
    } else if (item.productType) {
      return `${item.productType} `;
    } else if (item.manufacturerName) {
      return `${item.manufacturerName} `;
    } else {
      return 'Unknown';
    }
  }

  return (
    <div className="exp-card">
      < div className="flex-container-horizontal" >
        <div className="flex-container-left">
          <div className="input-container">
            <h3>Products</h3>
            {noProduct === true && (
              <div style={{ margin: "10px", padding: '0px 10px' }}>
                <div className="flex-container-horizontal" style={{ justifyContent: "space-between", color: 'gray' }}>
                  List any Materials or Tools you have used to complete your Profile and earn BuilderBucks
                </div>
              </div>
            )
            }
          </div>
          {noProduct === true && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleMyProducts()}
            >
              Add My Products
            </Button>
          )}        </div>
        <div className="flex-container-right">
          {noProduct === false && (
            <div className="flex-container-right">
              <div className="input-container">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => setShowSelectProductModal(true)}
                >
                  Edit
                </Button>
              </div>
            </div>
          )
          }
        </div>
      </div>
      <List sx={{ padding: 0 }}>
        {
          productData?.slice(0, 20)?.map((item, index) => (
            <div key={index}>
              <Card sx={{ marginBottom: '10px' }}>
                <ListItem
                  key={index}
                  disablePadding>
                  <ListItemButton
                    onClick={() => history.push(`/product/${item?.productId}`)}
                  >
                    <ListItemText
                      primary={item.productName}
                      secondary={<>
                        {getProductDescriptionText(item)}
                      </>}
                    />
                  </ListItemButton>
                </ListItem>
              </Card>
            </div>
          ))
        }
      </List>

      {
        showSelectProductModal && (
          <SelectProductModal
            open={showSelectProductModal}
            onClose={() => {
              setShowSelectProductModal(false);
            }}
            selectedProducts={productData}
            from={"profile"}
            handleFetchProducts={handleFetchProducts}
          />
        )
      }
    </div>
  );
}
