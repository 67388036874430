import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, IconButton, Stack, Grid, Card, Menu, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { DeleteClientTimesheetTemplates, fetchClientTimesheetTemplates, SaveClientTimesheetTemplates } from '../../../api/ClientApi';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NewTemplateModel from './NewTemplateModel';
import TemplateDetailsModel from './TemplateDetailsModel';
import { Close as CloseIcon, Edit as EditIcon } from "@material-ui/icons";
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Loader from '../../../components/Loader';
import { BIGrey, BILightGrey } from '../../../assets/buildidColors';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    height: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '12px'
};

const AddClientTimesheetModal = ({ show, handleClose, onAddIndividualTimesheet, onAddCrewTimesheet }) => {

    let history = useHistory();
    let userId = localStorage.getItem("userId");
    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? 'black';
    let clientEmployeeId = localStorage.getItem("clientEmployeeId");

    const [showAddTimeSheetModal, setShowAddTimesheetModal] = useState(show);
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
    const [templateLists, setTemplateLists] = useState([]);
    const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showTemplateDetailsModal, setShowTemplateDetailsModal] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        getTimesheetTemplate();
    }, [])

    const getTimesheetTemplate = async () => {
        setIsLoadingTemplates(true);
        try {
            const fetch_Object = {
                "PageNr": 1,
                "NrOfRecPerPage": 0,
                "FullSearch": "",
                "UserId": Number(userId),
                "IncludeRecordNr": false,
                "FetchAllowedRecordsOnly": true,
                "SearchList": [{
                    'ClientEmployeeId': Number(clientEmployeeId)
                }]
            };
            const res = await fetchClientTimesheetTemplates(fetch_Object);
            let resData = res?.data ?? [];
            setTemplateLists(resData);
            setIsLoadingTemplates(false);
        } catch (e) {
            console.log("ERROR:[getTimesheetTemplate] : > ", e);
            setIsLoadingTemplates(false);
        }
    };

    const handleMoreIconClick = async (event, template) => {
        let currentTarget = event?.currentTarget;
        event.stopPropagation();
        await setSelectedTemplate(template);
        setAnchorEl(currentTarget);
    };

    const handleCardClick = (template) => {
        setSelectedTemplate(template);
        setShowTemplateDetailsModal(true);
    };


    const navigateToPage = (url) => {
        history.push(url)
    }

    const handleCloseMenu = () => {
        setSelectedTemplate(null);
        setAnchorEl(null);
    }


    const handleEditTemplate = () => {
        setShowAddTimesheetModal(false);
        setShowAddTemplateModal(true);
    };


    const onTemplateDuplicateClick = async () => {

        try {
            if (!selectedTemplate?.id) {
                return null;
            }
            setIsLoadingTemplates(true);
            const save_Duplicate_Template_Object = {
                "UserId": Number(userId),
                "ReturnRecordId": true,
                "ReturnRecordError": true,
                "SaveList": [
                    {
                        "Id": 0,
                        "Name": `${selectedTemplate.name} - Copy`,
                        "Type": selectedTemplate?.type,
                        "ClientEmployeeId": clientEmployeeId,
                        "ClientJobId": Number(selectedTemplate?.clientJobId),
                        "EmployeeIdList": `${selectedTemplate?.templateEmployeesList?.map(emp => emp.clientEmployeeId).join(',')}`,
                        "ActivityIdList": `${selectedTemplate?.templateActivitiesList?.map(act => act.activityId).join(',')}`,
                        "ModifiedBy": Number(userId)
                    }
                ]
            };

            await SaveClientTimesheetTemplates(save_Duplicate_Template_Object);
            getTimesheetTemplate();
        } catch (error) {
            setIsLoadingTemplates(false);
            alert("Something went wrong. Please try again later.");
            console.log("Error while duplicating template ::", error)
        }
    }

    const onTemplateDeleteClick = async () => {
        try {
            if (!selectedTemplate?.id) {
                return null;
            }
            setIsLoadingTemplates(true);
            const delete_object = {
                "UserId": Number(userId),
                "ReturnRecordId": false,
                "ReturnRecordError": true,
                "SoftDelete": true,
                "DeleteList": [
                    {
                        "Id": selectedTemplate?.id
                    }
                ]
            };
            await DeleteClientTimesheetTemplates(delete_object);
            getTimesheetTemplate();
        } catch (error) {
            setIsLoadingTemplates(false);
            alert("Something went wrong. Please try again later.");
            console.log("Error while deleting template ::", error)
        }
    }

    return (
        <>

            {
                showAddTimeSheetModal && (
                    <Modal
                        open={showAddTimeSheetModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Stack sx={{ height: '100%' }}>
                                <Stack flexDirection={'row'} justifyContent={"end"} alignItems={"center"} style={{ marginLeft: '-10px' }}>
                                    <IconButton onClick={handleClose} >
                                        <CloseIcon />
                                    </IconButton>
                                </Stack>

                                <Stack style={{ marginTop: '20px' }}>
                                    <Stack direction={'row'} justifyContent={"space-between"} alignItems={"center"}>
                                        <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>My Templates</Typography>
                                        <Button style={{ color: clientAccentColor ?? '' }}
                                            onClick={() => {
                                                setSelectedTemplate(null);
                                                setShowAddTimesheetModal(false);
                                                setShowAddTemplateModal(true);
                                            }}
                                        >New Template</Button>
                                    </Stack>

                                    {
                                        isLoadingTemplates ? (
                                            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                                <Loader />
                                            </Stack>
                                        )
                                            :
                                            (
                                                <Stack direction={'row'} gap={1} style={{ overflowX: 'scroll' }}>
                                                    {
                                                        templateLists?.length > 0 ?
                                                            (
                                                                <>
                                                                    {templateLists?.map((template, index) => (
                                                                        <Grid item xs={4} key={index}>
                                                                            <Card
                                                                                variant="outlined"
                                                                                style={{
                                                                                    padding: "10px",
                                                                                    cursor: "pointer",
                                                                                    width: '200px',
                                                                                    display: 'flex',
                                                                                    flexDirection: 'column',
                                                                                    height: '100%', // Ensure card stretches to fill the container
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                >
                                                                                    <div onClick={() => handleCardClick(template)}>
                                                                                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} style={{ color: 'black' }}>
                                                                                            <Typography variant="subtitle1">
                                                                                                {template?.name}
                                                                                            </Typography>
                                                                                            <IconButton
                                                                                                onClick={(e) => handleMoreIconClick(e, template)}
                                                                                            >
                                                                                                <MoreHorizIcon />
                                                                                            </IconButton>
                                                                                        </Stack>

                                                                                        <Typography>
                                                                                            {template?.clientJobCode} - {template?.clientJobName}
                                                                                        </Typography>
                                                                                        <Typography style={{ color: BIGrey }} variant='caption'>
                                                                                            {template?.templateActivitiesList?.length ?? 0} Activities
                                                                                        </Typography>
                                                                                        <br />
                                                                                        <Typography style={{ color: BIGrey }} variant='caption'>
                                                                                            {template?.templateEmployeesList?.length ?? 0} Employees
                                                                                        </Typography>
                                                                                    </div>

                                                                                </Box>
                                                                                {/* {anchorEl && selectedTemplate?.id === template?.id && ( */}
                                                                                <Menu
                                                                                    anchorEl={anchorEl}
                                                                                    open={Boolean(anchorEl)}
                                                                                    onClose={handleCloseMenu}
                                                                                >
                                                                                    <MenuItem onClick={() => {
                                                                                        setAnchorEl(null);
                                                                                        handleEditTemplate();
                                                                                    }}>
                                                                                        <div>
                                                                                            <IconButton style={{ color: clientAccentColor }}>
                                                                                                <EditIcon />
                                                                                            </IconButton>
                                                                                            Edit
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={() => {
                                                                                        setAnchorEl(null);
                                                                                        onTemplateDuplicateClick();
                                                                                    }}>
                                                                                        <div>
                                                                                            <IconButton style={{ color: clientAccentColor }}>
                                                                                                <FileCopyIcon />
                                                                                            </IconButton>
                                                                                            Duplicate Template
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                    <MenuItem onClick={() => {
                                                                                        setAnchorEl(null);
                                                                                        onTemplateDeleteClick();
                                                                                    }}>
                                                                                        <div>
                                                                                            <IconButton style={{ color: clientAccentColor }}>
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                            Delete
                                                                                        </div>
                                                                                    </MenuItem>
                                                                                </Menu>
                                                                                {/* )} */}
                                                                            </Card>
                                                                        </Grid>
                                                                    ))}
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <Typography style={{ marginTop: '10px', textAlign: 'center', color: BIGrey }}>
                                                                    Use templates to quickly create new Crew Timesheets with the same Job, Activities, and Employees.
                                                                </Typography>
                                                            )
                                                    }

                                                </Stack>
                                            )
                                    }





                                    <Stack direction={'row'} justifyContent={'space-between'} style={{ width: '100%', margin: '10px 0px' }} alignItems={"center"} >
                                        <hr style={{ width: '45%', height: '1px' }} />
                                        <span>or</span>
                                        <hr style={{ width: '45%', height: '1px' }} />
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        Create a new Timesheet
                                    </Typography>
                                    <Stack direction={"row"} justifyContent={'center'} alignItems={'center'} gap={10}
                                        style={{ width: '100%', margin: '20px auto' }}
                                    >
                                        <Stack
                                            direction={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            style={{
                                                border: `1px solid ${clientAccentColor}`,
                                                borderRadius: '5px',
                                                padding: '20px 40px',
                                                width: '40%',
                                                height: '150px',
                                                cursor: 'pointer',
                                                textAlign: 'center'

                                            }}
                                            onClick={onAddIndividualTimesheet}
                                        >
                                            <div
                                            >
                                                <PersonIcon style={{ color: clientAccentColor, fontSize: '50px' }} />
                                            </div>
                                            <Typography style={{ fontWeight: 'bold' }}>
                                                Individual
                                            </Typography>
                                            <Typography>
                                                For myself
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            direction={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            style={{
                                                border: `1px solid ${clientAccentColor}`,
                                                borderRadius: '5px',
                                                padding: '20px 40px',
                                                width: '40%',
                                                height: '150px',
                                                textAlign: 'center',
                                                cursor: "pointer"
                                            }}
                                            onClick={onAddCrewTimesheet}

                                        >
                                            <div
                                            >
                                                <PeopleIcon style={{ color: clientAccentColor, fontSize: '50px' }} />
                                            </div>
                                            <Typography style={{ fontWeight: 'bold' }}>
                                                Crew
                                            </Typography>
                                            <Typography>
                                                For multiple employees
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>

                            </Stack>

                        </Box>
                    </Modal>
                )
            }


            {
                showAddTemplateModal && (
                    <NewTemplateModel
                        open={showAddTemplateModal}
                        onClose={() => {
                            setSelectedTemplate(null);
                            setShowAddTimesheetModal(true);
                            setShowAddTemplateModal(false);
                        }}
                        onTemplateAdded={() => {
                            setSelectedTemplate(null);
                            getTimesheetTemplate();
                            setShowAddTimesheetModal(true);
                            setShowAddTemplateModal(false);
                        }}
                        selectedTemplate={selectedTemplate}
                    />
                )
            }



            {
                showTemplateDetailsModal &&
                (
                    <TemplateDetailsModel
                        open={showTemplateDetailsModal}
                        onClose={() => {
                            setShowTemplateDetailsModal(false);
                            setShowAddTimesheetModal(true);
                        }}
                        template={selectedTemplate}
                        onTimeSheetDeleted={() => {
                            setSelectedTemplate(null);
                            getTimesheetTemplate();
                            setShowTemplateDetailsModal(false);
                            setShowAddTimesheetModal(true);
                        }}
                        onEditClick={() => {
                            setShowTemplateDetailsModal(false);
                            setShowAddTemplateModal(true)
                        }}
                    />
                )
            }

        </>
    )
}

export default AddClientTimesheetModal