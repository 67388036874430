import React, { useRef, useState } from "react";
import { Grid, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { ClientHeader } from "../ClientHeader";
import { BIBlack, BIGrey } from "../../../assets/buildidColors";
import { FileUpload } from "@mui/icons-material";
import ClientSiteFilingFileUploadModal from "./ClientSiteFilingUploadFileModal";


export default function ClientSiteFilingGallery(props) {
  const folderData = props.location.state?.folderData;
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortOptionClick = (option) => {
    console.log(`Selected sort option: ${option}`);
    handleClose();
  };
  const handleFileUploadClick = () => {
    fileInputRef.current.click(); // This will open the file picker
  };

  // Handle file selection and update the files list
  const handleFileChange = (event) => {
    const uploadedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
    setIsModalOpen(true); // Open the modal after files are selected
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ClientHeader
        screenName={"Site Filing"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content">
          <div className="expense-detail">
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography style={{ fontWeight: "600", marginTop: "10px" }}>
                {folderData.title}
              </Typography>
            </Stack>

            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              style={{ margin: "15px 0px" }}
            >
              <Stack>
                <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                  {folderData.images.length} items
                </Typography>
              </Stack>

              <Stack alignItems={"center"}>
                <Typography style={{ color: BIBlack, fontSize: "14px" }}>
                  Sort by{" "}
                  <span
                    style={{
                      color: localStorage.getItem("clientAccentColor"),
                      fontWeight: "600",
                      cursor: "pointer",
                      position: "relative",
                      zIndex: "999",
                    }}
                    onClick={handleClick}
                    onMouseEnter={handleClick}
                  >
                    Upload Date
                  </span>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    onMouseLeave={handleClose}
                    slotProps={{
                      paper: {
                        sx: {
                          marginTop: "10px",
                          marginLeft: "-40px",
                        },
                      },
                    }}
                  >
                    {["Type", "Name", "Upload Date"].map((option) => (
                      <MenuItem
                        key={option}
                        onClick={() => handleSortOptionClick(option)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </Typography>
              </Stack>
            </Stack>

            <Grid container spacing={2}>
              {folderData.images.map((img, imgIndex) => (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    lg={2}
                    textAlign={"center"}
                    key={imgIndex}
                    marginBottom={4}
                    marginTop={0}
                  >
                    <img
                      src={img.imgSrc}
                      alt={img.imageTitle}
                      style={{
                        height: "auto",
                        width: "100%",
                        aspectRatio: "1",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                    <Typography variant="h6">{img.imageTitle}</Typography>
                    <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                      2024-10-21
                    </Typography>
                  </Grid>
                </>
              ))}
            </Grid>
          </div>
        </div>
      </div>
      <div className="fixed-button-container">
        <button className="fixed-button" style={{ background: localStorage?.clientAccentColor }}  onClick={handleFileUploadClick}>
          <FileUpload />
        </button>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange} // Trigger file selection
        accept="*/*" // Allow all file types
        multiple
      />
      <ClientSiteFilingFileUploadModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        files={files}
        handleFileChange={handleFileChange} // Pass the file change handler
        handleFileRemove={handleFileRemove}
      />
    </>
  );
}
