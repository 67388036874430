import React, { useState, useEffect } from "react";
import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  Typography,
  Avatar,
  Chip,
  Divider,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import InfoIcon from "@mui/icons-material/Info";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import {
  Edit as EditIcon,
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon,
} from "@material-ui/icons";
import { BIBlue, BIGrey, BILightGrey } from "../../assets/buildidColors";
import "../../assets/css/Timesheets.css";
import {
  fetchTimeSheetsEntries,
  fetchTimeSheetsGoals,
} from "../../api/TimesheetApi";
import PictureViewer from "../../components/PictureViewer";
import Loader from "../../components/Loader";
import { Button } from "@material-ui/core";
import { formatNumericToTime } from "../../assets/helpers";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg";

export default function TimeSheetListSection(props) {
  const [response, setResponse] = useState([]);
  const [nrOfRecords, setNrOfRecords] = useState(0);

  useEffect(() => {
    props.activeTab === "sent" && props.setSendButtonDisabled();
    setNrOfRecords(0);
    updateList();
    window.scrollTo(0, 0);
    return () => {
      props.setResponse([]);
      setNrOfRecords(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(async () => {
    if (props?.callUpdatedData) {
      await updateList();
    }
  }, [props?.callUpdatedData]);

  const updateList = async () => {
    // await props.increaseOffset();
    props.getData().then((res) => {
      setNrOfRecords(res.nrOfRecords);
      let getUpdatedres = res.map((item) => {
        return {
          ...item,
          isTimesheetChecked: false,
          isExpand: false,
        };
      });
      props.setResponse(getUpdatedres);
      if (props?.callUpdatedData) {
        props?.setCallUpdatedData(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleToggle = (index) => {
    props.response[index].isTimesheetChecked = props.response[index]
      .isTimesheetChecked
      ? false
      : true;
    props.setResponse([...props.response]);
    props.setSendButtonVisible(
      props.response.filter((res) => res.isTimesheetChecked)
    );
  };

  const callNextlist = async () => {
    if (props?.infiniteScroll === true) {
      await props.increaseOffset();
      props.getData().then((res) => {
        const newResponse = res.map((item) => {
          return {
            ...item,
            isTimesheetChecked: false,
            isExpand: false,
          };
        });
        props.setResponse(newResponse);
      });
    }
  };

  return (
    <div className="all-tab">
      {props.response.length > 0 ? (
        <InfiniteScroll
          dataLength={props.response.length}
          next={callNextlist}
          hasMore={true}
        >
          <Stack spacing={0}>
            {console.log("props.response", props.response)}
            {props.response.map((item, itemIndex) => {
              return (
                <div key={itemIndex}>
                  <ListSectionItem
                    data={item}
                    handleToggle={() => handleToggle(itemIndex)}
                    itemTimeSheetDate={item.timesheetDate}
                    itemNoOfJobs={item.noOfJobs}
                    itemTotalHours={item.totalHours}
                    itemId={item.id}
                    setClickFrom={props.setClickFrom}
                    itemActiveTab={props.activeTab}
                    itemResponse={props.response}
                    itemSetResponse={props.setResponse}
                    itemSetSendButtonVisible={props.setSendButtonVisible}
                    itemGetEditTimeSheetData={props.getEditTimeSheetData}
                    infiniteScroll={props?.infiniteScroll}
                  />
                </div>
              );
            })}
          </Stack>
        </InfiniteScroll>
      ) : (
        <>
          {nrOfRecords !== 0 ? (
            <CircularProgress
              style={{
                textAlign: "center",
                marginTop: "50%",
                marginLeft: "50%",
              }}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

function ListSectionItem(props) {
  const [projectInfo, setProjectinfo] = useState([]);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const expandCurrentTimesheet = async (e, getId) => {
    e.preventDefault();
    let getUpdatedresponse = props.itemResponse.map((item) => {
      if (item.id === getId) {
        return {
          ...item,
          isExpand: item.isExpand === true ? false : true,
        };
      } else {
        return {
          ...item,
          isExpand: false,
        };
      }
    });
    props.itemSetResponse(getUpdatedresponse);
    try {
      setIsLoading(true);
      const sendObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: true,
        SearchList: [{ TimeSheetId: getId }],
      };
      const getTimesheetGoals = await fetchTimeSheetsGoals(sendObj);

      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [{ TimesheetId: getId }],
      };
      const timesheetsEntries = await fetchTimeSheetsEntries(obj);
      const totalDistance = timesheetsEntries?.data?.reduce(
        (total, item) => total + Number(item.distance),
        0
      );
      const unitOfDistance = timesheetsEntries?.data?.[0]?.unitOfDistance;

      let a = [];
      const newTimeSheetData = timesheetsEntries?.data?.map((unique) => {
        a = unique;
        a["timesheetGoalsArray"] = [];
        getTimesheetGoals?.data.map((item) => {
          if (item.timeSheetEntryId == unique.id) {
            let obj = {
              id: item.id,
              key: item.goalId,
              value: item.goalName,
              goalName: item.goalName,
              categoryName: item.goalCategoryName,
              categoryId: item.goalCategoryId,
              time: item.hours,
              goalId: item.goalId,
              siteId: item.siteId,
              siteName: item.siteName,
              timeSheetEntryId: item.timeSheetEntryId,
              timeSheetId: item.timeSheetId,
            };
            a["timesheetGoalsArray"].push(obj);
          }
        });
        return a;
      });
      setProjectinfo(newTimeSheetData ?? []);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const openCurrentImg = (picList, index) => {
    setPreviewImages(picList ?? []);
    setPictureViewIndex(index);
    setPictureViewerOpen(true);
  };

  return (
    <>
      <ListItem
        key={props.itemId}
        secondaryAction={
          <>
            {props?.data?.isExpand === true ? (
              <IconButton
                style={{ color: BIBlue }}
                onClick={(e) => expandCurrentTimesheet(e, props.itemId)}
              >
                <CollapseIcon />
              </IconButton>
            ) : (
              <IconButton
                style={{ color: BIBlue }}
                onClick={(e) => expandCurrentTimesheet(e, props.itemId)}
              >
                <ExpandIcon />
              </IconButton>
            )}
          </>
        }
        disablePadding
        className="list-item"
      >
        <ListItemButton onClick={props.handleToggle}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <>
              {
                <ListItemIcon>
                  <Checkbox
                    style={{ color: BIBlue }}
                    value={JSON.stringify(props.itemId)}
                    checked={props.data?.isTimesheetChecked}
                  />
                </ListItemIcon>
              }
            </>
            <ListItemText
              primary={moment(props.itemTimeSheetDate).format("MMMM DD, YYYY")}
              secondary={`${props.itemNoOfJobs} Jobs, ${formatNumericToTime(
                props.itemTotalHours
              )} Hours`}
            />
          </div>
        </ListItemButton>
      </ListItem>
      {/* Expand Time Sheet  */}

      {props.data.isExpand === true && (
        <>
          {isLoading ? (
            <Loader />
          ) : projectInfo.length > 0 ? (
            <>
              {projectInfo?.map((item, index) => (
                <Card className="timesheetinfo-card" key={index}>
                  <CardContent className="sheet-conent">
                    <div>
                      <p style={{ color: BIGrey }}>
                        {item.companyId == 0 || item.companyName == ""
                          ? "No Company"
                          : item.companyName}
                      </p>
                      <Stack
                        direction={"row"}
                        gap={1}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Stack
                          direction={"row"}
                          gap={1}
                          style={{ width: "80%" }}
                        >
                          <Avatar
                            variant="rounded"
                            style={{
                              marginRight: "10px",
                              width: "52px",
                              height: "52px",
                            }}
                            src={
                              item?.sitePictures && item?.sitePictures !== ""
                                ? item?.sitePictures
                                : defaultProjectImage
                            }
                          ></Avatar>
                          <Stack>
                            <Typography>{item?.projectName}</Typography>
                            <Typography style={{ color: BIGrey }}>
                              {`${item?.siteAddress}, ${item?.siteCity}, ${item?.siteProvince}, ${item?.sitePostalCode}, ${item?.siteCountry}`}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack>
                          <Typography style={{ fontWeight: "800" }}>
                            {`${formatNumericToTime(item?.hours)}`}
                          </Typography>
                          <Typography style={{ fontWeight: "500" }}>
                            {`${item?.distance} km`}
                          </Typography>
                        </Stack>
                      </Stack>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {item?.timesheetGoalsArray?.map((value, index) => {
                        return (
                          <Stack
                            key={index}
                            direction={"row"}
                            gap={1}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            style={{ marginBottom: "10px" }}
                          >
                            <Chip
                              label={
                                value.categoryName === ""
                                  ? value.goalName
                                  : value.categoryName
                              }
                            />
                            <Typography>
                              {formatNumericToTime(value.time)}
                            </Typography>
                          </Stack>
                        );
                      })}
                    </div>
                    <p>{item?.comments}</p>
                    <p>
                      {item?.pictureList?.length > 0 && (
                        <ImageList
                          className="project-img"
                          sx={{ width: 500, height: 150 }}
                          cols={3}
                          rowHeight={164}
                        >
                          {item?.pictureList?.map((pic, i) => (
                            <ImageListItem key={i}>
                              <img
                                onClick={() => {
                                  openCurrentImg(item?.pictureList ?? [], i);
                                }}
                                srcSet={pic?.pictureUrl}
                                src={pic?.pictureUrl}
                                loading="lazy"
                                style={{ cursor: "pointer" }}
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      )}
                    </p>
                  </CardContent>
                </Card>
              ))}
              <Divider />
              <div style={{ marginTop: "10px" }}>
                {props.itemActiveTab === "sent" ? (
                  <p
                    style={{
                      backgroundColor: "lightyellow",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <InfoIcon fontSize="small" /> This Timesheet has been sent.
                    Remember to resend if you edit!
                  </p>
                ) : (
                  <></>
                )}

                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignContent={"center"}
                  style={{ margin: "10px" }}
                >
                  <Stack direction={"row"} gap={4}>
                    <Typography style={{ fontWeight: 400 }}>Total</Typography>
                    <Typography style={{ fontWeight: 800 }}>
                      {formatNumericToTime(props?.data?.totalHours)}
                    </Typography>
                    <Typography style={{ fontWeight: 800 }}>
                      {props?.data?.totalDistance} {props?.data?.unitOfDistance}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Button
                      variant="contained"
                      style={{
                        background: BIBlue,
                        color: "#fff",
                        width: "15%",
                        textAlign: "end",
                        marginLeft: "auto",
                      }}
                      onClick={() => {
                        props.itemGetEditTimeSheetData(
                          props.data,
                          props.itemId
                        );
                        props.setClickFrom("EditTimesheet");
                      }}
                    >
                      EDIT
                    </Button>
                    <p
                      style={{
                        fontSize: "small",
                        marginTop: "5px",
                        color: BIGrey,
                      }}
                    >
                      Last Modified :
                      {moment(props?.data?.dateModified).format(
                        "YYYY-MM-DD h:mm a"
                      )}{" "}
                    </p>
                  </Stack>
                </Stack>
              </div>
            </>
          ) : null}
          {previewImages?.length > 0 && (
            <PictureViewer
              isOpen={pictureViewerOpen}
              onClose={() => {
                setPreviewImages([]);
                setPictureViewIndex(0);
                setPictureViewerOpen(false);
              }}
              pictures={previewImages?.map((pic) => ({
                src: pic.pictureUrl,
                alt: "",
              }))}
              viewerIndex={pictureViewIndex}
            />
          )}
        </>
      )}
    </>
  );
}
