import React, { useEffect, useState } from "react";
import { BIBlue, BIRed } from "../../assets/buildidColors";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon } from "@material-ui/icons";
import ErrorIcon from "@mui/icons-material/Error";
import WorkIcon from "@mui/icons-material/Work";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import JobList from "./JobList";
import NewJobPostingModal from "./NewJobPostingModal";
import Loader from "../../components/Loader";
import AlertModal from "../../components/AlertModal";
import Placeholder from "../../components/Placeholder";
import PromptModal from "../../components/PromptModal";
import {
  deleteJobPosts,
  fetchJobPosts,
  saveJobPosts,
} from "../../api/SitesApi";

const MyJobPosting = (props) => {

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [expirJobAlertModalOpen, setExpireJobAlertModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [activeJobArray, setActiveJobArray] = useState([]);
  const [expiredJobArray, setExpiredJobArray] = useState([]);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [showJobList, setShowJobList] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    try {
      const obj = {
        PageNr: pageNo,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ createdBy: Number(localStorage.userId) }],
      };
      const result = await fetchJobPosts(obj);

      const updatedResult = result.map((item) => {
        const c = moment(new Date());
        const d = moment(item.dateCreated);
        const diffInDays = c.diff(d, "days");
        return { ...item, diffInDays };
      });

      const activeArray = result.filter(
        (item) =>
          moment().format("YYYY-MM-DD") <=
          moment(item.jobPostExpiryDate).format("YYYY-MM-DD")
      );
      const expiredArray = result.filter(
        (item) =>
          moment().format("YYYY-MM-DD") >
          moment(item.jobPostExpiryDate).format("YYYY-MM-DD")
      );

      setMainData(updatedResult);
      setPageNo(1);
      setIsLoading(false);
      setActiveJobArray(activeArray);
      setExpiredJobArray(expiredArray);
      setIsLoading(false);
    } catch (error) {
      console.log("ErrorFetchJobPosts:", error?.message ?? error);
      setIsLoading(false);
    }
  };
  
  const onJobRePost = async (item) => {
    try {
      const obj = {
        UserId: localStorage.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: item.id,
            SiteId: item.siteId,
            TradeId: item.tradeId,
            CompanyId: item.companyId,
            JobDuration: item.jobDuration,
            JobDurationType: item.jobDurationType,
            Email: item.email,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await saveJobPosts(obj);

      const updatedActiveJobs = await getData();
      activeJobArray(updatedActiveJobs);
    } catch (error) {
      console.log("Error : [onJobRePost] :", error);
    }
  };
  const handleDelete = async (itemId) => {
    const deleteObj = {
      UserId: localStorage.userId,
      ReturnRecordId: false,
      ReturnRecordError: true,
      DeleteList: [
        {
          Id: itemId,
          ModifiedBy: localStorage.userId,
        },
      ],
    };

    try {
      await deleteJobPosts(deleteObj);
      const updatedMainData = mainData.filter((item) => item.id !== itemId);
      setMainData(updatedMainData);
      setDeleteAlert(false);
      getData();
    } catch (error) {
      console.error("Error deleting job post:", error);
    }
  };
  const toggleComponents = () => {
    setShowJobList(!showJobList);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {" "}
      {showJobList ? (
        <JobList hasExpiredJobs={expiredJobArray.length > 0} />
      ) : (
        <Box>
          <div className="page rewards-page">
            <Box className="header">
              <Box className="page-content">
                <h1>My Job Postings</h1>
              </Box>
            </Box>
          </div>
          <Box className="page">
            <div className="page-content">
              <Grid
                className="input-container"
                sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    component="label"
                    startIcon={<CancelOutlinedIcon />}
                    style={{ background: BIBlue, color: "white" }}
                    onClick={toggleComponents}>
                    Back
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    style={{ background: BIBlue, color: "white" }}
                    onClick={() => {
                      setDeleteModalOpen(true);
                    }}>
                    Create A Job Posting
                  </Button>
                </Box>
              </Grid>
              {isLoading ? (
                <Loader color="primary" />
              ) : expiredJobArray.length === 0 &&
                activeJobArray.length === 0 ? (
                <Placeholder
                  icon={<WorkIcon />}
                  text="Looking for someone to help on Site you're working on?"
                />
              ) : (
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginBottom: "20px",
                    }}>
                    {expiredJobArray.length > 0 && <h3>Expired</h3>}
                    {expiredJobArray.map((job) => (
                      <Box
                        sx={{
                          width: "100%",
                          marginBottom: "20px",
                        }}>
                        <Card
                          sx={{
                            height: "150px",
                            padding: "0px",
                          }}>
                          <CardContent>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                              <Box sx={{ cursor: "pointer" }}>
                                <Typography component="div" variant="h5">
                                  {job.projectName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div">
                                  {job.companyName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div">
                                  {job.email}
                                </Typography>
                                <Box style={{ display: "flex", gap: "8px" }}>
                                  <Chip
                                    label={job.tradeName}
                                    variant="outlined"
                                  />
                                  {job.jobDuration > 0 && (
                                    <Chip
                                      label={`${job.jobDuration
                                        } ${job.jobDurationType.replace(
                                          "(s)",
                                          ""
                                        )}`}
                                      variant="outlined"
                                    />
                                  )}
                                </Box>
                              </Box>
                              <Box>
                                <Box marginLeft={25}>
                                  <IconButton
                                    sx={{ color: BIRed }}
                                    onClick={() => {
                                      setExpireJobAlertModalOpen(true);
                                    }}>
                                    <ErrorIcon />
                                  </IconButton>
                                </Box>
                                <Box>
                                  {moment(job.jobPostExpiryDate).diff(
                                    new Date(),
                                    "days"
                                  ) > 3 ? (
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      style={{ marginTop: "15px" }}>
                                      <Button
                                        variant="outlined"
                                        onClick={() => handleDelete(job.id)}>
                                        DELETE
                                      </Button>
                                    </Stack>
                                  ) : (
                                    <Stack
                                      direction="row"
                                      spacing={2}
                                      style={{ marginTop: "15px" }}>
                                      <Button
                                        variant="outlined"
                                        startIcon={<DeleteIcon />}
                                        style={{ color: "red" }}
                                        onClick={() => handleDelete(job.id)}>
                                        DELETE
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        startIcon={<AutorenewIcon />}
                                        style={{ color: "blue" }}
                                        onClick={() => onJobRePost(job)}>
                                        REPOST
                                      </Button>
                                    </Stack>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      marginBottom: "20px",
                    }}>
                    {activeJobArray.length > 0 && <h3>Active</h3>}
                    {activeJobArray.map((job) => (
                      <Box
                        key={job.id}
                        sx={{
                          width: "100%",
                          marginBottom: "20px",
                        }}>
                        <Card
                          sx={{
                            height: "170px",
                            padding: "0px",
                          }}>
                          <CardContent>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}>
                              <Box sx={{ cursor: "pointer" }}>
                                <Typography component="div" variant="h5">
                                  {job.projectName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div">
                                  {job.companyName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div">
                                  {job.email}
                                </Typography>
                                <div style={{ display: "flex", gap: "8px" }}>
                                  <Chip
                                    label={job.tradeName}
                                    variant="outlined"
                                  />
                                  {job.jobDuration != "" && (
                                    <Chip
                                      label={`${job.jobDuration
                                        } ${job.jobDurationType.replace(
                                          "(s)",
                                          ""
                                        )}`}
                                      variant="outlined"
                                    />
                                  )}
                                </div>
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div">
                                  {job.description}
                                </Typography>
                              </Box>
                              <Box>
                                <Box>
                                  Expires in{" "}
                                  {job.jobPostExpiryDate
                                    ? moment(job.jobPostExpiryDate).diff(
                                      new Date(),
                                      "days"
                                    ) <= 1
                                      ? `${moment(job.jobPostExpiryDate).diff(
                                        new Date(),
                                        "days"
                                      )} day`
                                      : `${moment(job.jobPostExpiryDate).diff(
                                        new Date(),
                                        "days"
                                      )} days`
                                    : "N/A"}
                                </Box>
                                <Button
                                  variant="outlined"
                                  startIcon={<DeleteIcon />}
                                  style={{ color: "red" }}
                                  onClick={() => setDeleteAlert(true)}>
                                  DELETE
                                </Button>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                        <PromptModal
                          isOpen={deleteAlert}
                          onClose={() => {
                            setDeleteAlert(false);
                          }}
                          title="Delete Job Posting"
                          desc="Are you sure you want to Delete this job Posting?"
                          redButtonText="Delete"
                          onRedButtonClick={() => handleDelete(job.id)}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </div>
          </Box>
        </Box>
      )}
      <NewJobPostingModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        getData={getData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />
      <AlertModal
        isOpen={expirJobAlertModalOpen}
        onClose={() => setExpireJobAlertModalOpen(false)}
        body={
          "This Job Posting has expired and is no longer Visible to others."
        }
      />
    </>
  );
};

export default MyJobPosting;
