import React from 'react'
import { Close as CloseIcon } from "@material-ui/icons";
import {
    Dialog, DialogTitle, DialogContent, IconButton, ListItemText, MenuItem, ListItemIcon, Checkbox
} from '@mui/material'
import { BIBlue } from '../../assets/buildidColors';


const FilterOptions = (props) => {
    return (
        <>
            <Dialog
                open={props?.open}
                onClose={props?.onClose}
                fullWidth
                maxWidth="sm"
                scroll={"paper"}
            >
                <DialogTitle>
                    <div className="modal-card-header-buttons" style={{ alignItems: 'center' }}>
                        <h2>Filter</h2>
                        <IconButton
                            style={{ color: BIBlue }}
                            onClick={() => props?.onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ paddingTop: 0 }}>
                    <MenuItem
                        key={0}
                        value={"For Sale"}
                        onClick={() => props?.setForSaleCheckBox(!(props.forSaleCheckBox))}>
                        <ListItemIcon>
                            <Checkbox
                                style={{ color: BIBlue }}
                                checked={props?.forSaleCheckBox}
                            />
                        </ListItemIcon>
                        <ListItemText primary={"For Sale"} />
                    </MenuItem>
                    <MenuItem
                        key={1}
                        value={"For Trade"}
                        onClick={() => props?.setForTradeCheckBox(!(props.forTradeCheckBox))}>
                        <ListItemIcon>
                            <Checkbox
                                style={{ color: BIBlue }}
                                checked={props?.forTradeCheckBox}
                            />
                        </ListItemIcon>
                        <ListItemText primary={"For Trade"} />
                    </MenuItem>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default FilterOptions