import React from "react";

import { Modal, IconButton, Button } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { BIBlue } from "../assets/buildidColors";

/*
Message modal that acts like alert(), but styled like other BuildID modals
AlertModal code in parent must be under any other modals in order to appear on top
Props (* required):
  *isOpen				- boolean; modal visibility variable
  *onClose			- function; to modify visibility state variable
  title				- string; alert message title
  body					- string; alert message body

Usage:
  <AlertModal
    isOpen={}
    onClose={() => }
    title=""
    body=""/>
*/
function AlertModal(props) {
  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <div className="modal-center">
        <div
          className="modal-card"
          style={{ width: props.inappropriateImage ? "500px" : "450px" }}
        >
          {/* <div className="modal-card-header-buttons"> */}
          {props.expCloseIcon ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                padding: "0px 10px",
              }}
            >
              <IconButton color="primary" onClick={props?.onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          )}

          <div className="modal-card-body">
            <div style={{ paddingBottom: "20px", textAlign: "center" }}>
              <h3>{props.title}</h3>
              <p>{props.body}</p>
              {props.inappropriateImage && (
                <>
                  <p>{props?.text1}</p>
                </>
              )}
            </div>

            <div
              className="flex-container-horizontal"
              style={{ justifyContent: "center" }}
            >
              {
                props?.clientAccentColor ? (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: props?.clientAccentColor, color: 'white', width: '50%' }}
                    onClick={props?.onClose}

                  >
                    <b>OK</b>
                  </Button>
                )
                  :
                  (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={props?.onClose}
                    >
                      <b>OK</b>
                    </Button>
                  )
              }

            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AlertModal;