import React, { useState, useEffect, useCallback } from "react";
import InfoIcon from "@mui/icons-material/Info";
import {
  Tabs,
  Tab,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  Modal,
  Grid,
  Divider,
} from "@material-ui/core";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import defaultProjectImage from "../../assets/images/project-profile-placeholder.jpg";
import SendIcon from "@mui/icons-material/Send";
import {
  Button,
  Container,
  Stack,
  Card,
  Typography,
  IconButton,
  Chip,
  ListItemButton,
  Checkbox,
  Alert,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import Placeholder from "../../components/Placeholder";
import TimeSheetListSection from "./TimeSheetListSection";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  fetchGoals,
  fetchTimesheet,
  fetchTimeSheetsEntries,
  fetchTimeSheetsGoals,
  getTimeSheetsGoalsDetailsForProgressBar,
  sendUnsentTimesheets,
  updateIsGoalFlag,
} from "../../api/TimesheetApi";
import SendTimesheet from "./SendTimesheetModal";
import NewTimesheetModals from "./NewTimesheetModals";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import ReportModal from "../../components/ReportModal";
import LinearProgress from "@mui/material/LinearProgress";
import { BIBlue, BIGrey, BILightGrey } from "../../assets/buildidColors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Loader from "../../components/Loader";
import {
  KeyboardReturnRounded,
  MoreVert as MoreIcon,
} from "@material-ui/icons";
import { BIRed } from "../../assets/buildidColors";
import AddGoalModal from "./AddGoalModal";
import { Close as CloseIcon } from "@material-ui/icons";
import { Edit as EditIcon } from "@mui/icons-material";
import AddTimeSheetModal from "./AddTimeSheetModal";
import ExportGoalsSummaryModal from "../buysell/ExportGoalsSummaryModal";
import {
  getUserDashboard,
  updateIsTimeSheetsBannerFlag,
} from "../../api/UserApi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import UserUsages from "../../components/userUsages/UserUsages";
import moment from "moment";
const _ = require("lodash");
const tabsArray = ["unsent", "sent"];

function TimeSheets() {
  const [activeTab, setActiveTab] = useState("unsent");
  const [isUnsentTimeSheetEmpty, setIsUnsentTimeSheetEmpty] = useState(false);
  const [isSentTimeSheetEmpty, setIsSentTimeSheetEmpty] = useState(false);
  const [pageNr, setPageNr] = useState(1);
  const [sendButtonVisible, setSendButtonVisible] = useState(true);
  const [newTimesheetModalOpen, setnewTimesheetModalOpen] = useState(false);
  const [sendTimesheetModalOpen, setSendTimesheetModalOpen] = useState(false);
  const [selectedTimeSheets, setSelectedTimeSheets] = useState([]);
  const [timeSheetId, setTimeSheetId] = useState(0);
  const [editTimeSheetData, setEditTimeSheetData] = useState([]);
  const [timesheetEntriesData, setTimesheetEntriesData] = useState([]);
  const [fetchTimesheetsLength, setFetchTimesheetsLength] = useState(0);
  const [loadingGoal, setLoadingGoal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickFrom, setClickFrom] = useState("");
  const [response, setResponse] = useState([]);
  const [infiniteScroll, setInfinitescroll] = useState(false);
  const [isProgressBarExpanded, setIsProgressBarExpanded] = useState(false);
  const [editGoalModal, setEditGoalModal] = useState(false);

  const [moreMenuAnchor, setMoreMenuAnchor] = useState(null);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [addGoalModalOpen, setAddGoalModalOpen] = useState(false);

  const [editGoalDetails, setEditGoalDetails] = useState(null);
  const [TimesheetList, setTimesheetList] = useState([]);
  const [userGoalData, setUserGoalData] = useState([]);
  const [isHideCompletedGoals, setIsHideCompletedGoals] = useState(false);
  const [timesheetGoalData, setTimesheetGoalData] = useState([]);
  const [callUpdatedData, setCallUpdatedData] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [isTimeSheetsBannerFlag, setIsTimeSheetsBannerFlag] = useState(false);
  const [timeSheetListdata, setTimesheetListdata] = useState([]);
  const [list, setList] = useState("0");

  const [timeSheetSendData, setTimeSheetSendData] = useState([]);
  const [timeUnsentData, setTimeUnsentData] = useState([]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    timeUnsentData.some((item) => item.isChecked)
      ? setSendButtonVisible(false)
      : setSendButtonVisible(true);
  }, [timeUnsentData]);

  useEffect(async () => {
    const userData = await getUserDashboard(localStorage?.userId);
    setIsTimeSheetsBannerFlag(userData?.isTimeSheetsBannerFlag);
  }, []);

  useEffect(() => {
    setTimesheetEntriesData(timesheetEntriesData);
  }, [timesheetEntriesData]);

  const handleTabChange = (event, newTab) => {
    setSendButtonVisible(true);
    setPageNr(1);
    setActiveTab(newTab);
  };

  const handelClick = (list) => {
    setList(list);
  };

  useEffect(() => {
    getTimeSheetData();
  }, [list]);

  const getDataForEditTimeSheet = async (id) => {
    try {
      const sendObj = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: true,
        SearchList: [{ TimeSheetId: id }],
      };
      const getTimesheetGoals = await fetchTimeSheetsGoals(sendObj);
      await setTimesheetGoalData(getTimesheetGoals?.data);
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        UserId: Number(localStorage.userId),
        SearchList: [{ TimesheetId: id }],
      };

      let timesheetEntries = await fetchTimeSheetsEntries(obj);
      const newTimeSheetData = timesheetEntries?.data?.map((unique) => {
        const entryWithGoals = { ...unique, timesheetGoalsArray: [] };
        getTimesheetGoals?.data.forEach((item) => {
          if (item.timeSheetEntryId === unique.id) {
            let obj = {
              id: item.id,
              key: item.goalId,
              value: item.goalName,
              goalName: item.goalName,
              categoryName: item.goalCategoryName,
              categoryId: item.goalCategoryId,
              time: item.hours,
              goalId: item.goalId,
              siteId: item.siteId,
              siteName: item.siteName,
              timeSheetEntryId: item.timeSheetEntryId,
              timeSheetId: item.timeSheetId,
            };
            entryWithGoals.timesheetGoalsArray.push(obj);
          }
        });
        return entryWithGoals;
      });
      setTimesheetEntriesData(newTimeSheetData ?? []);
    } catch (err) {
      console.log(
        "Error while getting data for editing the timesheet :: ",
        err
      );
    }
  };

  const getEditTimeSheetData = async (data, id) => {
    try {
      await getDataForEditTimeSheet(id);
      setTimeSheetId(id);
      await setEditTimeSheetData(data);
      setnewTimesheetModalOpen(true);
    } catch (error) {
      console.log("fetchTimesheetEntriesError:", error);
    }
  };

  const increaseOffset = () => {
    setPageNr(pageNr + 1);
  };

  const handleSendTimeSheet = () => {
    setSendTimesheetModalOpen(true);
  };

  function clearCheckBox() {
    response.map((item) => (item.isTimesheetChecked = false));
    setResponse(response);
  }

  const sendUnsentTimesheet = async (emailList) => {
    setPageNr(1);
    const timeSheetArray = [];
    timeUnsentData.map((item) => {
      if (item.isChecked) {
        timeSheetArray.push(item.id);
      }
    });

    const sendTimeSheetObj = {
      Emails: emailList,
      TimeSheets: timeSheetArray,
      UserId: Number(localStorage.getItem("userId")),
    };
    console.log("asfghkasoifihu; time sheet obj", sendTimeSheetObj);

    const res = await sendUnsentTimesheets(sendTimeSheetObj);
    return res;
  };

  const getTimeSheetData = async () => {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 100,
      FullSearch: "",
      UserId: Number(localStorage.getItem("userId")),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
      SortList: [
        {
          FieldName: "timesheetDate",
          Direction: "DESC",
        },
      ],
    };
    const res = await fetchTimesheet(obj);
    let data = res.data.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });

    console.log("data", count);
    setCount((count) => count + 1);

    let SendData = [];
    let UnsentData = [];
    let allData = [];
    data.map((item) => {
      allData.push(item);
      if (item.isSent) {
        SendData.push(item);
      } else {
        UnsentData.push(item);
      }
    });

    setTimesheetListdata(allData);
    setTimeUnsentData(UnsentData);
    setTimeSheetSendData(SendData);
    // const filteredUnsent = res.data.filter((timesheet) => !timesheet.isSent);
    // if (filteredUnsent.length === 0) setIsUnsentTimeSheetEmpty(true);
    // else setIsUnsentTimeSheetEmpty(false);
    // return filteredUnsent;
  };
  // const getAllTimesheet = useCallback(async () => {
  //   const obj = {
  //     PageNr: 1,
  //     NrOfRecPerPage: 100,
  //     FullSearch: "",
  //     UserId: Number(localStorage.getItem("userId")),
  //     IncludeRecordNr: true,
  //     TypeOfObjectReturned: "",
  //     SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
  //     SortList: [
  //       {
  //         FieldName: "Id",
  //         Direction: "DESC",
  //       },
  //     ],
  //   };
  //   const res = await fetchTimesheet(obj);
  //   const filteredSent = res.data;
  //   if (res.nrOfRecords === res.data.length) {
  //     setInfinitescroll(false);
  //   } else {
  //     setInfinitescroll(true);
  //   }
  //   setFetchTimesheetsLength(res.data.length);
  //   if (filteredSent.length === 0) setIsSentTimeSheetEmpty(true);
  //   else setIsSentTimeSheetEmpty(false);
  //   return filteredSent;
  // }, [pageNr]);
  // const getSentTimesheet = useCallback(async () => {
  //   const obj = {
  //     PageNr: 1,
  //     NrOfRecPerPage: 100,
  //     FullSearch: "",
  //     UserId: Number(localStorage.getItem("userId")),
  //     IncludeRecordNr: true,
  //     TypeOfObjectReturned: "",
  //     SearchList: [{ UserId: Number(localStorage.getItem("userId")) }],
  //     SortList: [
  //       {
  //         FieldName: "Id",
  //         Direction: "DESC",
  //       },
  //     ],
  //   };
  //   const res = await fetchTimesheet(obj);
  //   const filteredSent = res.data.filter((timesheet) => timesheet.isSent);
  //   if (res.nrOfRecords === res.data.length) {
  //     setInfinitescroll(false);
  //   } else {
  //     setInfinitescroll(true);
  //   }
  //   setFetchTimesheetsLength(res.data.length);
  //   if (filteredSent.length === 0) setIsSentTimeSheetEmpty(true);
  //   else setIsSentTimeSheetEmpty(false);
  //   return filteredSent;
  // }, [pageNr]);

  useEffect(() => {
    getTimeSheetGoal();
    getUsersGoal();
    setPageNr(1);
    UserUsages("Timesheets");
  }, []);

  const getTimeSheetGoal = async () => {
    try {
      const goals = await getTimeSheetsGoalsDetailsForProgressBar(
        localStorage.userId
      );
      setTimesheetList(goals);
    } catch (error) {
      console.log("getTimeSheetGoal:", error);
    }
  };

  const getUsersGoal = async () => {
    try {
      const obj = {
        PageNr: 1,
        // NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        SearchList: [{ createdBy: Number(localStorage.userId) }],
      };
      const goals = await fetchGoals(obj);
      let a = [];
      goals.data?.map((item) => {
        a = item;
        a["key"] = item.id;
        a["value"] = item.name;
        return a;
      });
      const sum = goals.data?.reduce((result, item) => {
        return result + item.hours;
      }, 0);
      setUserGoalData(goals?.data);
    } catch (error) {
      console.log("ERROR:[getData]:", error);
    }
  };

  const progressData = (item, index) => {
    const shouldHideItem = isHideCompletedGoals && item.isGoalFlag;
    if (shouldHideItem) {
      return null; // Return null to hide the item
    }
    var progressValue = (
      Number(item.goalProgressTotalHours / item.goalHours) * 100
    ).toFixed(0);
    if (progressValue > 100) {
      var progressValue = 100;
    }
    return (
      <>
        <Stack>
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            sx={{ padding: "10px 0px" }}
          >
            <Stack>
              <Typography sx={{ fontWeight: "bold" }}>
                {item.goalName}
              </Typography>
            </Stack>
            <Stack direction={"row"} gap={1}>
              <Typography
                style={{
                  color: BIBlue,
                  fontWeight: "bold",
                }}
              >
                {item.goalProgressTotalHours}
              </Typography>
              <Typography>/ {item.goalHours} hrs</Typography>
            </Stack>
          </Stack>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              backgroundColor: BILightGrey,
              borderRadius: "10px",
              height: "10px",
              ".MuiLinearProgress-bar": {
                backgroundColor: BIBlue,
              },
            }}
          />

          {isProgressBarExpanded &&
            item.categoryList.map((category) => (
              <Stack sx={{ paddingTop: "5px" }}>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Stack>
                    <Typography>{category.categoryName}</Typography>
                  </Stack>
                  <Stack direction={"row"}>
                    <Typography>
                      {category.categoryProgressTotalHours} hrs
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))}
        </Stack>
      </>
    );
  };

  const onCloseBannerPressed = async () => {
    try {
      const obj = {
        Id: Number(localStorage?.userId),
        IsTimeSheetsBannerFlag: false,
      };
      await updateIsTimeSheetsBannerFlag(obj);
      setIsTimeSheetsBannerFlag(false);
    } catch (error) {
      console.log("Error while closing banner :: ", error);
    }
  };

  const handleAddGoal = () => {
    setMoreMenuAnchor(false);
    setAddGoalModalOpen(true);
    setEditGoalDetails(null);
  };

  const handleReport = () => {
    setMoreMenuAnchor(false);
    setReportModalOpen(true);
  };
  const onEditGoalClickModal = () => {
    setEditGoalModal(true);
  };

  const onEditGoalClick = (item) => {
    setEditGoalDetails(item);
    setAddGoalModalOpen(true);
  };

  const onToggleSwitch = async (value) => {
    setIsHideCompletedGoals(value);
    const updateFlagobj = {
      CreatedBy: Number(localStorage.userId),
    };
    try {
      setLoadingGoal(true);
      await updateIsGoalFlag(updateFlagobj);
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        SearchList: [
          {
            createdBy: Number(localStorage.userId),
          },
        ],
      };
      const goals = await fetchGoals(obj);
      console.log("goals", goals);
      setUserGoalData(goals?.data ?? []);
      setLoadingGoal(false);
    } catch (error) {
      console.log("ERROR:[updateIsGoalFlag/fetchGoals]:", error);
      setLoadingGoal(false);
    }
  };

  const onDataSave = () => {
    setIsTimeSheetsBannerFlag(false);
    getTimeSheetGoal();
    getUsersGoal();
  };

  const getUpdatedData = () => {
    // new code sanket 02-04-2024
    getTimeSheetData();
    setIsUnsentTimeSheetEmpty(false);
    getTimeSheetGoal();
    getUsersGoal();
    setCallUpdatedData(true);
  };

  const renderPlaceholder = () => {
    return (
      <div style={{ marginTop: "90px" }}>
        <Placeholder
          icon={<CalendarMonthIcon />}
          text="You don't have any Timesheets."
        />
      </div>
    );
  };

  const getTimeSheetDivStyle = () => {
    if (isTimeSheetsBannerFlag) {
      return { flex: 1 };
    } else if (TimesheetList.length === 0) {
      return {};
    } else {
      return { flex: 1 };
    }
  };

  const reloadData = () => {
    getTimeSheetData();
  };

  return (
    <>
      {newTimesheetModalOpen && (
        <AddTimeSheetModal
          isOpen={newTimesheetModalOpen}
          onClose={() => {
            setLoading(false);
            setnewTimesheetModalOpen(false);
          }}
          clickFrom={clickFrom}
          editTimeSheetData={editTimeSheetData}
          timesheetEntriesData={timesheetEntriesData}
          getUpdatedData={getUpdatedData}
        />
      )}

      <SendTimesheet
        isOpen={sendTimesheetModalOpen}
        onClose={() => setSendTimesheetModalOpen(false)}
        setSendButtonVisible={() => setSendButtonVisible(true)}
        selectedTimeSheet={
          timeUnsentData.filter((item) => item.isChecked) ?? []
        }
        getSentTimesheet={() => {
          if (sendTimesheetModalOpen) getTimeSheetData();
        }}
        closeTimeSheetModal={setSendTimesheetModalOpen}
        handleSendUnsentTimeSheet={sendUnsentTimesheet}
        setLoading={setLoading}
        activeTab={activeTab}
        clearCheckBox={clearCheckBox}
      />

      <ReportModal
        isOpen={reportModalOpen}
        onClose={() => setReportModalOpen(false)}
        name={"Timesheet"}
        recordId={0}
      />
      <AddGoalModal
        isOpen={addGoalModalOpen}
        onClose={() => {
          setEditGoalDetails(null);
          setAddGoalModalOpen(false);
        }}
        editGoalDetails={editGoalDetails}
        onDataSave={onDataSave}
      />

      <div className="page">
        <div className="page rewards-page">
          <div
            className="header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "space-between",
            }}
          >
            <div className="page-content">
              <h1>Timesheets</h1>
            </div>
            <div className="page-content">
              <MoreIcon
                className="link"
                color="action"
                onClick={(event) => setMoreMenuAnchor(event.currentTarget)}
              />
              <Menu
                open={Boolean(moreMenuAnchor)}
                onClose={() => setMoreMenuAnchor(null)}
                anchorEl={moreMenuAnchor}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <MenuItem onClick={() => handleAddGoal()}>Add Goal</MenuItem>
                <MenuItem
                  onClick={() => {
                    setMoreMenuAnchor(null);
                    setShowExportModal(true);
                  }}
                >
                  Export Goals Summary
                </MenuItem>
                <MenuItem
                  onClick={() => handleReport()}
                  style={{ color: BIRed }}
                >
                  Report
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>

        <div className="page-content" style={{ paddingTop: "20px" }}>
          <div className="resp-container-horizontal">
            <div
              className="flex-container-vertical"
              style={getTimeSheetDivStyle()}
            >
              <div style={{ margin: "15px" }}>
                {isTimeSheetsBannerFlag && (
                  <>
                    <Stack style={{ backgroundColor: "#FFEEBF" }}>
                      <Stack
                        direction={"row"}
                        gap={1}
                        justifyContent={"start"}
                        style={{ padding: "20px" }}
                      >
                        <Typography>
                          Working towards an apprenticeship? Set up a Goal to
                          track the hours from your Timesheets.
                        </Typography>
                        <IconButton
                          style={{
                            padding: "0px",
                            width: "21px",
                            height: "24px",
                          }}
                          onClick={onCloseBannerPressed}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                      <Button
                        style={{ marginBottom: "20px" }}
                        onClick={() => {
                          handleAddGoal();
                        }}
                      >
                        New Goal
                      </Button>
                    </Stack>
                  </>
                )}
                {TimesheetList && TimesheetList.length > 0 && (
                  <>
                    <Card style={{ padding: "15px", paddingBottom: "0px" }}>
                      <div
                        className="flex-container-horizontal"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <Button
                          variant="contained"
                          style={{ background: BIBlue }}
                          onClick={() => onEditGoalClickModal()}
                        >
                          Edit
                        </Button>
                      </div>
                      {!editGoalModal ? (
                        <List style={{ padding: 0 }}>
                          {TimesheetList.slice(0, 7).map((item, index) => {
                            return progressData(item, index);
                          })}
                          {isProgressBarExpanded &&
                            TimesheetList.slice(7).map((item, index) => {
                              return progressData(item, index);
                            })}
                          <Stack direction="row" justifyContent="flex-end">
                            <IconButton
                              onClick={() => {
                                setIsProgressBarExpanded(
                                  !isProgressBarExpanded
                                );
                              }}
                            >
                              {isProgressBarExpanded ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </Stack>
                        </List>
                      ) : (
                        <>
                          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Edit Goals
                          </Typography>
                          <Button
                            onClick={(e) => {
                              onToggleSwitch(e.target.checked);
                            }}
                            size="small"
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  color="primary"
                                  checked={isHideCompletedGoals ? true : false}
                                />
                              }
                            />
                            <Typography
                              style={{
                                color: "black",
                                textTransform: "capitalize",
                              }}
                            >
                              Hide Completed Goals
                            </Typography>
                          </Button>
                          {loadingGoal ? (
                            <div>
                              <Loader />
                            </div>
                          ) : (
                            <List style={{ padding: 0 }}>
                              {userGoalData.map((item, index) => (
                                <Stack>
                                  <Stack
                                    direction="row"
                                    width="100%"
                                    justifyContent="space-between"
                                    sx={{ padding: "10px 0px" }}
                                  >
                                    <Stack>
                                      <Typography>{item.name}</Typography>
                                    </Stack>
                                    <Stack>
                                      <IconButton
                                        onClick={() => {
                                          onEditGoalClick(item);
                                        }}
                                      >
                                        <EditIcon
                                          sx={{
                                            fontSize: "20px",
                                            color: BIBlue,
                                            cursor: "pointer",
                                          }}
                                        />
                                      </IconButton>
                                    </Stack>
                                  </Stack>
                                </Stack>
                              ))}
                            </List>
                          )}
                          <div
                            className="flex-container-horizontal"
                            style={{ justifyContent: "center" }}
                          >
                            <Button
                              style={{ color: BIBlue }}
                              onClick={() => handleAddGoal()}
                            >
                              Add Goal
                            </Button>
                          </div>

                          <div
                            className="flex-container-horizontal"
                            style={{ justifyContent: "flex-end" }}
                          >
                            <Button
                              style={{ color: BIBlue }}
                              onClick={() => setEditGoalModal(false)}
                            >
                              Close
                            </Button>
                          </div>
                        </>
                      )}
                    </Card>
                  </>
                )}
              </div>
            </div>

            <div className="flex-container-vertical" style={{ flex: 2 }}>
              <div style={{ margin: "15px" }}>
                <div
                  className="flex-container-horizontal"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      style={{
                        padding: "10px",
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: list == "1" ? BIBlue : "",
                        color: list == "1" ? "white" : "black",
                      }}
                      label="UNSENT"
                      onClick={() => {
                        list == "1" ? handelClick("0") : handelClick("1");
                      }}
                      position="left"
                      variant={list == "1" ? "" : "outlined"}
                      icon={list == "1" && <CloseIcon color="white" />}
                    />
                    <Chip
                      style={{
                        padding: "10px",
                        height: "40px",
                        borderRadius: "20px",
                        backgroundColor: list == "2" ? BIBlue : "",
                        color: list == "2" ? "white" : "black",
                      }}
                      label="SENT"
                      onClick={() => {
                        list == "2" ? handelClick("0") : handelClick("2");
                      }}
                      icon={list == "2" && <CloseIcon color="white" />}
                      variant={list == "2" ? "" : "outlined"}
                    />
                  </div>
                  {/* <Tabs
                    textColor="primary"
                    indicatorColor="primary"
                    value={activeTab}
                    onChange={handleTabChange}
                  >
                    {tabsArray.map((tab, index) => (
                      <Tab
                        key={index}
                        value={tab}
                        label={tab}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                          marginRight: "20px",
                        }}
                      />
                    ))}
                  </Tabs> */}

                  <div className="flex-container-horizontal resp-container-horizontal">
                    {activeTab === "unsent" && (
                      <div className="input-container">
                        <Button
                          variant="contained"
                          style={{ background: BIBlue }}
                          onClick={() => {
                            setnewTimesheetModalOpen(true);
                            setClickFrom("AddTimesheet");
                          }}
                        >
                          Add Timesheet
                        </Button>
                      </div>
                    )}

                    <div className="input-container">
                      <Button
                        variant="contained"
                        style={{ background: !sendButtonVisible && BIBlue }}
                        disabled={sendButtonVisible}
                        onClick={handleSendTimeSheet}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  {/* {activeTab === "unsent" ? ( */}
                  {list === "0" ? (
                    timeSheetListdata.length == 0 ? (
                      renderPlaceholder()
                    ) : loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      timeSheetListdata.map((item, index) => (
                        <TimeSheetList
                          reloadData={reloadData}
                          data={item}
                          isSent={false}
                          setList={setTimesheetListdata}
                        />
                      ))
                      // <TimeSheetListSection
                      //   increaseOffset={increaseOffset}
                      //   setClickFrom={setClickFrom}
                      //   fetchTimesheetsLength={fetchTimesheetsLength}
                      //   getData={getUnsentTimesheet}
                      //   setLoading={setLoading}
                      //   setSendButtonVisible={(resArr) => {
                      //     setSelectedTimeSheets([...resArr]);
                      //     const newCount = resArr?.length > 0 ? false : true;
                      //     setSendButtonVisible(newCount);
                      //   }}
                      //   activeTab={activeTab}
                      //   setResponse={setResponse}
                      //   response={response}
                      //   getEditTimeSheetData={getEditTimeSheetData}
                      //   infiniteScroll={infiniteScroll}
                      //   callUpdatedData={callUpdatedData}
                      //   setCallUpdatedData={setCallUpdatedData}
                      // />
                    )
                  ) : (
                    <></>
                  )}

                  {list === "1" ? (
                    timeUnsentData.length == 0 ? (
                      renderPlaceholder()
                    ) : loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      timeUnsentData.map((item, index) => (
                        <TimeSheetList
                          reloadData={reloadData}
                          data={item}
                          isSent={true}
                          setList={setTimeUnsentData}
                        />
                      ))
                      // <TimeSheetListSection
                      //   increaseOffset={increaseOffset}
                      //   setClickFrom={setClickFrom}
                      //   fetchTimesheetsLength={fetchTimesheetsLength}
                      //   setLoading={setLoading}
                      //   getData={getSentTimesheet}
                      //   activeTab={activeTab}
                      //   setSendButtonDisabled={() => setSendButtonVisible(true)}
                      //   getEditTimeSheetData={getEditTimeSheetData}
                      //   setSendButtonVisible={(resArr) => {
                      //     setSelectedTimeSheets([...resArr]);
                      //     const newCount = resArr?.length > 0 ? false : true;
                      //     setSendButtonVisible(newCount);
                      //   }}
                      //   setResponse={setResponse}
                      //   response={response}
                      //   infiniteScroll={infiniteScroll}
                      //   callUpdatedData={callUpdatedData}
                      //   setCallUpdatedData={setCallUpdatedData}
                      // />
                    )
                  ) : (
                    <></>
                  )}
                  {list === "2" ? (
                    timeSheetSendData.length == 0 ? (
                      renderPlaceholder()
                    ) : loading ? (
                      <div>
                        <Loader />
                      </div>
                    ) : (
                      timeSheetSendData.map((item, index) => (
                        <TimeSheetList
                          reloadData={reloadData}
                          data={item}
                          isSent={!item.isSent}
                          setList={setTimesheetListdata}
                        />
                      ))

                      // <TimeSheetListSection
                      //   increaseOffset={increaseOffset}
                      //   setClickFrom={setClickFrom}
                      //   fetchTimesheetsLength={fetchTimesheetsLength}
                      //   setLoading={setLoading}
                      //   getData={getAllTimesheet}
                      //   activeTab={activeTab}
                      //   setSendButtonDisabled={() => setSendButtonVisible(true)}
                      //   getEditTimeSheetData={getEditTimeSheetData}
                      //   setSendButtonVisible={(resArr) => {
                      //     setSelectedTimeSheets([...resArr]);
                      //     const newCount = resArr?.length > 0 ? false : true;
                      //     setSendButtonVisible(newCount);
                      //   }}
                      //   setResponse={setResponse}
                      //   response={response}
                      //   infiniteScroll={infiniteScroll}
                      //   callUpdatedData={callUpdatedData}
                      //   setCallUpdatedData={setCallUpdatedData}
                      // />
                    )
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showExportModal && (
        <ExportGoalsSummaryModal
          open={showExportModal}
          onClose={() => {
            setShowExportModal(false);
          }}
          userGoalData={userGoalData ?? []}
        />
      )}
    </>
  );
}

export default TimeSheets;

const TimeSheetList = ({ data, isSent, setList, reloadData }) => {
  const [viewModel, setViewModel] = useState(false);
  function convertToTime(decimalHours) {
    let hours = Math.floor(decimalHours);
    let minutes = Math.round((decimalHours - hours) * 60);

    let hoursFormatted = String(hours).padStart(2, "0");
    let minutesFormatted = String(minutes).padStart(2, "0");

    return `${hoursFormatted}:${minutesFormatted}`;
  }
  return (
    <>
      <div
        style={{
          backgroundColor: "#ffffff",
          borderRadius: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isSent && (
          <Checkbox
            style={{
              marginLeft: "10px",
              width: "50px",
              height: "50px",
            }}
            checked={data?.isChecked}
            onChange={(e) => {
              e.stopPropagation();
              data.isChecked = !data.isChecked;
              setList((prev) => {
                return prev.map((item) => {
                  if (item.id === data.id) {
                    return data;
                  }
                  return item;
                });
              });
            }}
          />
        )}

        <ListItemButton
          onClick={() => {
            setViewModel(true);
          }}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <div>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
              >
                {moment(data?.timesheetDate).format("MMMM DD, YYYY")}
              </Typography>
              <Typography>
                {data?.noOfJobs} Jobs, {convertToTime(data?.totalHours)}
              </Typography>
            </div>
          </div>

          {data?.isSent && (
            <SendIcon
              style={{
                color: BIGrey,
              }}
            />
          )}
        </ListItemButton>
      </div>
      <ViewTimeSheet
        reloadDatamain={reloadData}
        id={data.id}
        open={viewModel}
        onClose={() => setViewModel(false)}
      />
    </>
  );
};

const ViewTimeSheet = ({ open, onClose, id, reloadDatamain }) => {
  const [data, setData] = useState({});
  const [timeSheetEnteries, setTimeSheetEntries] = useState([]);
  const [goalsList, setGoalsList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      getTimeSheetEntries(id);
      getTimeSheetData();
    }
  }, [open]);
  const reloadData = () => {
    getTimeSheetEntries(id);
  };
  function convertToTime(decimalHours) {
    let hours = Math.floor(decimalHours);
    let minutes = Math.round((decimalHours - hours) * 60);

    let hoursFormatted = String(hours).padStart(2, "0");
    let minutesFormatted = String(minutes).padStart(2, "0");

    return `${hoursFormatted}:${minutesFormatted}`;
  }

  const getTimeSheetData = async () => {
    setLoading(true);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 100,
      FullSearch: "",
      UserId: Number(localStorage.getItem("userId")),
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ id: id }],
    };
    const res = await fetchTimesheet(obj);
    console.log("res", res);
    setData(res?.data[0]);
  };

  const getTimeSheetEntries = async (getId) => {
    const sendObj = {
      PageNr: 1,
      NrOfRecPerPage: 10,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      SearchList: [{ TimeSheetId: getId }],
    };
    const goals = await fetchTimeSheetsGoals(sendObj);
    setGoalsList(goals?.data);
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 10,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      SearchList: [{ TimesheetId: getId }],
    };
    const timesheetsEntries = await fetchTimeSheetsEntries(obj);
    console.log("timesheetsEntried", timesheetsEntries);
    timesheetsEntries.data.forEach((entry) => {
      entry.timesheetGoalsArray = []; // Adding an empty array to each entry
    });

    timesheetsEntries?.data.map((item) => {
      goals?.data.map((dataX) => {
        if (item.id == dataX?.timeSheetEntryId) {
          item.timesheetGoalsArray.push(dataX);
        }
      });
    });

    setTimeSheetEntries(timesheetsEntries?.data);
    setLoading(false);
  };

  const sendUnsentTimesheet = async (emailList) => {
    const timeSheetArray = [id];

    const sendTimeSheetObj = {
      Emails: emailList,
      TimeSheets: timeSheetArray,
      UserId: Number(localStorage.getItem("userId")),
    };
    console.log("asfghkasoifihu; time sheet obj", sendTimeSheetObj);

    const res = await sendUnsentTimesheets(sendTimeSheetObj);
    return res;
  };
  const [sendTimesheetModalOpen, setSendTimesheetModalOpen] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          reloadDatamain();
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            className="modal-card"
            style={{
              padding: "20px",
              width: "600px",
              minHeight: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={() => {
                  reloadDatamain();
                  onClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Grid Container display="flex" justifyContent="space-between">
                  <Grid item>
                    <Typography
                      style={{
                        color: "grey",
                      }}
                    >
                      {moment(data?.timesheetDate).format("MMMM DD, YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <div
                  style={{
                    maxHeight: "500px",
                    overflow: "auto",
                  }}
                >
                  {timeSheetEnteries.map((item) => {
                    return <ModalList data={item} />;
                  })}
                  {data?.isSent && (
                    <Alert
                      style={{
                        marginTop: "10px",
                      }}
                      icon={<InfoIcon />}
                      severity="warning"
                    >
                      This timesheet has been sent. Remember to resend if you
                      edit!
                    </Alert>
                  )}

                  {data.totalHours && (
                    <>
                      <Typography
                        variant="p"
                        style={{
                          fontWeight: "bold",
                          marginTop: "10px",
                        }}
                      >
                        TOTAL{"  "} {convertToTime(data?.totalHours)}
                      </Typography>
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    fullWidth
                    style={{ borderColor: BIBlue }}
                    variant="outlined"
                    onClick={() => setOpenEditModel(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ background: BIBlue }}
                    fullWidth
                    variant="contained"
                    onClick={() => setSendTimesheetModalOpen(true)}
                  >
                    SEND
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    style={{
                      color: BIGrey,
                    }}
                  >
                    Last modified{" "}
                    {moment(data?.dateModified).format("YYYY-DD-MM hh:mm a")}
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <AddTimeSheetModal
        isOpen={openEditModel}
        onClose={() => {
          setOpenEditModel(false);
        }}
        clickFrom={"EditTimesheet"}
        editTimeSheetData={data}
        timesheetEntriesData={timeSheetEnteries}
        getUpdatedData={reloadData}
      />
      <SendTimesheet
        isOpen={sendTimesheetModalOpen}
        onClose={() => {
          setSendTimesheetModalOpen(false);
          setOpenEditModel(false);
        }}
        selectedTimeSheet={[{ ...data }]}
        getSentTimesheet={reloadData}
        closeTimeSheetModal={setSendTimesheetModalOpen}
        handleSendUnsentTimeSheet={sendUnsentTimesheet}
        setSendButtonVisible={() => { }}
        setLoading={() => { }}
        clearCheckBox={() => { }}
      />
    </>
  );
};

const ModalList = ({ data }) => {
  function convertToTime(decimalHours) {
    let hours = Math.floor(decimalHours);
    let minutes = Math.round((decimalHours - hours) * 60);

    let hoursFormatted = String(hours).padStart(2, "0");
    let minutesFormatted = String(minutes).padStart(2, "0");

    return `${hoursFormatted}:${minutesFormatted}`;
  }
  return (
    <>
      <Divider
        style={{
          margin: "10px 0px",
        }}
      />
      <Grid container display="flex" justifyContent="space-between">
        <Typography
          style={{
            color: "grey",
          }}
        >
          {data?.companyName}
        </Typography>
      </Grid>
      <Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
            }}
          >
            <img
              style={{
                width: "50px",
                height: "50px",
                border: "1px solid grey",
                borderRadius: "10px",
              }}
              src={defaultProjectImage}
              alt=""
            />
            <div>
              <Typography
                style={{
                  color: "grey",
                }}
              >
                {`,${data.siteCity}, ${data.siteProvince}, `}
                <br />
                {`${data?.sitePostalCode}, ${data?.siteCountry}`}
              </Typography>
            </div>
          </div>
          <div>
            <Typography
              style={{
                fontWeight: "bold",
              }}
              marginRight={2}
            >
              {convertToTime(data?.hours)}
            </Typography>
            <Typography
              style={{
                fontWeight: "bold",
              }}
              marginRight={2}
            >
              {`${data?.distance} ${data?.unitOfDistance} `}
            </Typography>
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            gap: "10px",
            overflow: "auto",
            width: "100%",
          }}
        >
          {data?.pictureList?.map((item) => {
            return (
              <img
                style={{
                  minHeight: "100px",
                  minWidth: "100px",
                  borderRadius: "10px",
                }}
                src={item.pictureUrl}
              />
            );
          })}
        </div>
        {data.timesheetGoalsArray.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "5px",
                alignItems: "center",
              }}
            >
              <Chip label={item.goalName} />

              <Typography
                style={{
                  color: "grey",
                }}
              >
                {convertToTime(item.hours)}
              </Typography>
            </div>
          );
        })}

        <Typography>
          {data?.comments}
        </Typography>
      </Grid>
    </>
  );
};
