import { Button, Card, Checkbox, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import { fetchClientEmployee } from "../../../api/ClientApi";
import Loader from "../../../components/Loader";

export default function Employeeslist(props) {

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (props.showEmployeelist === true) {
            getEmployeelist();
        }
    }, [props.showEmployeelist])

    const getEmployeelist = async () => {
        setLoader(true);
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: "",
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{ ClientId: localStorage.clientId }],
            };
            const res = await fetchClientEmployee(obj);
            if (res.data?.length > 0) {
                let getList = res?.data?.map((emp, index) => {
                    // If employee already selected 
                    if (props?.selectedEmp?.length > 0) {
                        let getEmp = props?.selectedEmp.filter((item) => item?.clientEmployeeId === emp?.id);
                        if (getEmp?.length > 0) {
                            if (getEmp[0]?.Deleted === true) {
                                return {
                                    ...getEmp[0],
                                    udField: []
                                }
                            } else {
                                return getEmp[0]
                            }
                        }
                        else {
                            return {
                                ...emp,
                                isSelected: false,
                                mileage: 0.0,
                                mileageUnit: "km",
                                hours: 0,
                                clientEmployeeName: emp?.firstName + " " + emp?.lastName,
                                clientEmployeeId: emp?.id,
                                id: 0,
                                Deleted: false,
                                userProfilePicture: emp?.userProfilePicture
                            }
                        }
                    } else {
                        return {
                            ...emp,
                            id: 0,
                            isSelected: false,
                            mileage: 0.0,
                            mileageUnit: "km",
                            hours: 0,
                            clientEmployeeName: emp?.firstName + " " + emp?.lastName,
                            clientEmployeeId: emp?.id,
                            Deleted: false,
                            userProfilePicture: emp?.userProfilePicture
                        }
                    }
                })
                props.setEmplist(getList);
            }
        } catch (error) {

        }
        setLoader(false);
    }

    const selectEmployee = (item) => {
        console.log("itemm-->", item)
        let getList = props.emplist?.map((emp) => {
            if (emp?.clientEmployeeId === item?.clientEmployeeId) {
                return {
                    ...emp,
                    isSelected: item?.isSelected === true ? false : true,
                    Deleted: false,
                    id: 0
                }
            } else {
                return {
                    ...emp
                }
            }
        })
        console.log("getList-->", getList)
        props.setEmplist(getList);
    }

    const onSelectemp = () => {
        let getSelectedemp = props.emplist.filter((item) => item.isSelected === true);
        let getDeletedemp = props.selectedEmp.filter((item) => item.Deleted === true);
        getSelectedemp = [...getSelectedemp, ...getDeletedemp];
        if (getSelectedemp?.length > 0) {
            props.setSelectedemp(getSelectedemp);
        }
        props.handleClose();
    }

    if (loader) {
        return (
            <Loader />
        )
    }

    return (
        <>
            <Modal
                open={props.showEmployeelist}
                onClose={props.handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Select Employees</Typography>
                            <TextField
                                value={""}
                                placeholder={"Search for an employee"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px" }}>
                            {props.emplist.map((item) => (
                                <Stack flexDirection={"row"} gap={1} margin={"10px 0px"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => selectEmployee(item)}>
                                    <Checkbox checked={item?.isSelected}></Checkbox>
                                    <img className="profile-img" src={item?.userProfilePicture}></img>
                                    <Typography>{item?.clientEmployeeName}</Typography>
                                </Stack>
                            ))}
                        </Card>
                        <Button onClick={onSelectemp} color="primary" fullWidth variant="contained" sx={{ marginTop: 2 }} disabled={props.emplist?.filter((item) => item?.isSelected === true)?.length !== 0 ? false : true}>Select {props.emplist?.filter((item) => item?.isSelected === true)?.length !== 0 ? props.emplist?.filter((item) => item?.isSelected === true)?.length : ""}</Button>
                    </div>
                </div>
            </Modal>
        </>
    )

}