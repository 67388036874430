import React, { useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal';
import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Close as CloseIcon } from "@material-ui/icons";

const ActivityHours = (props) => {

    const [list, setList] = useState(props.selectedJobActivities);
    const activityHours = useRef(0);

    useEffect(() => {
        if (props.showActivityhours === true) {
            setList(props.selectedJobActivities);
        }
    }, [props.showActivityhours])

    // On Hours change
    const onHourschange = (e, getIndex) => {
        let getList = props.selectedJobActivities.map((item, index) => {
            if (index === props.parentIndex) {
                let getList = item?.selectedEmp?.map((item, index) => {
                    if (index === getIndex) {
                        return {
                            ...item,
                            hours: e.target.value === "" ? "" : Number(e.target.value),
                        }
                    } else {
                        return item
                    }
                })
                return {
                    ...item,
                    selectedEmp: getList,
                    hoursChange: true     // We need to update hoursChange: true here
                }
            } else {
                return item
            }
        })
        setList(getList);
        props.setSelectJobActivities(getList);
    }


    const onSavejobActivity = () => {
        let getList = props.selectedJobActivities.map((item, index) => {
            if (index === props.parentIndex) {
                return {
                    ...item,
                    hours: activityHours.current,
                }
            } else {
                return item
            }
        });
        props.setSelectJobActivities(getList);
        props.onClose(false);
    }

    
    const renderTotalhours = () => {
        let allHours = 0;
        list[props.parentIndex]?.selectedEmp?.map((item) => {
            if(item?.Deleted === false) {
                if (item?.hours !== "") {
                    return allHours = item?.hours + allHours;
                }
            }
        });
        activityHours.current = allHours;
        return allHours;
    }

    return (
        <>

            <Modal
                open={props.showActivityhours}
                onClose={props.handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "400px" }}>
                        <div className="modal-card-header-buttons">
                            <IconButton
                                color="primary"
                                onClick={props.onClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>{props?.select?.label}</Typography>
                            <Stack gap={1}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                    <Typography>
                                        Employee
                                    </Typography>
                                    <Typography>
                                        Hours
                                    </Typography>
                                </Stack>
                                {console.log("")}
                                {list[props.parentIndex]?.selectedEmp?.map((item, index) => (
                                    (item.Deleted === false && (
                                        <Stack alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"}>
                                            <Typography>
                                                {item?.clientEmployeeName}
                                            </Typography>
                                            <TextField
                                                placeholder={"00.00"}
                                                onChange={(e) => onHourschange(e, index)}
                                                value={item?.hours}
                                                size='small'
                                                style={{ width: "20%" }}
                                            />
                                        </Stack>
                                    ))
                                ))}
                            </Stack>
                            <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography style={{ fontSize: '16px', fontWeight: '600' }}>
                                    Total Activity Hours
                                </Typography>
                                <Typography style={{ fontSize: '18px', fontWeight: '600' }}>
                                    {renderTotalhours()}
                                </Typography>
                            </Stack>
                            <Button
                                variant='contained'
                                color="primary"
                                onClick={onSavejobActivity}
                            >
                                Save
                            </Button>
                        </Stack>
                    </div>
                </div>
            </Modal>
        </>

    )
}

export default ActivityHours