import React, { useEffect, useState } from "react";
import {
  Modal,
  IconButton,
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Avatar,
  Chip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Close as CloseIcon } from "@material-ui/icons";
import AlertModal from "../../components/AlertModal";
import moment from "moment";
import {
  getRecentlyInteractUsers,
  saveRecentlyInteractUsers,
} from "../../api/UserApi";
import { getUserContacts } from "../../api/ContactsApi";
import { Badge, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import { BIBlue } from "../../assets/buildidColors";

export default function SendTimesheet(props) {
  const {
    isOpen,
    onClose,
    setSendButtonVisible,
    selectedTimeSheet,
    getSentTimesheet,
    closeTimeSheetModal,
    handleSendUnsentTimeSheet,
    setLoading,
    contactPictureUrl,
    contactName,
    contactEmail,
    activeTab,
    clearCheckBox,
  } = props;

  const [toSendWith, setToSendWith] = useState([]);
  const [sendTimesheetAlert, setSendTimeSheetAlert] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [recentInteractUsers, setRecentInteractUsers] = useState([]);

  const [sendTimeSheetLoading, setSendTimeSheetLoading] = useState(false);
  async function getContacts() {
    const res = await getUserContacts(localStorage.userId);
    setSearchData(res);
  }

  async function fetchRecentInteractUsers() {
    const res = await getRecentlyInteractUsers(localStorage?.userId);
    let tempArr = res.map((item) => {
      return {
        ...item,
        isSelected: false,
      };
    });
    setRecentInteractUsers(tempArr);
  }

  useEffect(() => {
    if (isOpen) {
      getContacts();
      fetchRecentInteractUsers();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    setNewEmail(e.target.value.trim());
  };
  function handleChange(_, newValue) {
    handleEmail(newValue);
  }
  const handleEmail = (email) => {
    let tmpArr = email.map((item) => {
      if (item.contactEmail) {
        return {
          contactEmail: item.contactEmail,
          contactId: item.contactId,
        };
      } else {
        return item;
      }
    });
    setNewEmail("");
    setToSendWith(tmpArr);
  };
  const handleRecentInteractUserClick = (user) => {
    if (user && user.interactUserEmail) {
      if (user.isSelected == true) {
        return;
      }
      setToSendWith((prevToSendWith) => [
        ...prevToSendWith,
        {
          contactEmail: user.interactUserEmail,
          contactId: user.interactUserId,
        },
      ]);
    }
  };

  const handleSendTimeSheet = async () => {
    const selectedUsers = toSendWith.map((user) => {
      if (user.contactEmail) {
        return user?.contactEmail;
      } else {
        return user;
      }
    });

    console.log("selectedUsers,", selectedUsers);

    handleSendUnsentTimeSheet(selectedUsers).then(async (resp) => {
      setSendTimeSheetLoading(true);
      if (resp.message === "OK") {
        closeTimeSheetModal(false);
        toSendWith.map(async (item) => {
          const interactionObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                InteractUserId: item.contactId,
                ModifiedBy: Number(localStorage.userId),
                fromScreen: "TimeSheet",
              },
            ],
          };
          await saveRecentlyInteractUsers(interactionObj);
        });
        await fetchRecentInteractUsers(localStorage?.userId);
        setSendTimeSheetLoading(false);
        setToSendWith([]);
        setLoading(true);
        setSendTimeSheetAlert(true);
        clearCheckBox();
        setLoading(false);
        setSendButtonVisible();
        getSentTimesheet();
      }
    });
    setSendButtonVisible();
  };

  function convertToTime(decimalHours) {
    let hours = Math.floor(decimalHours);
    let minutes = Math.round((decimalHours - hours) * 60);

    let hoursFormatted = String(hours).padStart(2, "0");
    let minutesFormatted = String(minutes).padStart(2, "0");

    return `${hoursFormatted}:${minutesFormatted}`;
  }

  const handelRemove = () => {
    return;
    const recent = recentInteractUsers.map((a) => ({
      ...a,
      isSelected: toSendWith.includes(a.interactUserEmail),
    }));

    setRecentInteractUsers(recent);
  };

  const handleClose = () => {
    setToSendWith([]);
    clearCheckBox();
    setLoading(false);
    onClose();
  };

  return (
    <>
      <AlertModal
        isOpen={sendTimesheetAlert}
        onClose={() => setSendTimeSheetAlert(false)}
        title={"Timesheet Sent Successfully..."}
      />

      <Modal open={isOpen} onClose={() => handleClose}>
        <div className="modal-center">
          <div className="modal-card" style={{ width: "500px" }}>
            <div className="modal-card-header-buttons">
              <IconButton color="primary" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className="modal-card-body">
              <div className="flex-container-vertical">
                <h2>Send Timesheet</h2>

                <div className="input-container" style={{ flex: 1 }}>
                  <div
                    className="flex-container-horizontal"
                    style={{ alignItems: "stretch" }}
                  >
                    <Autocomplete
                      id="sendWith-autocomplete"
                      inputValue={newEmail}
                      multiple
                      freeSolo
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => {
                          const { key, ...tagProps } = getTagProps({ index });
                          return (
                            <Chip
                              key={key}
                              label={option.contactEmail || option}
                              {...tagProps}
                              onDelete={(e) => {
                                let tempArr = [];
                                recentInteractUsers.map((item) => {
                                  if (
                                    item.interactUserEmail ===
                                      option.contactEmail ||
                                    item.interactUserEmail === option
                                  ) {
                                    tempArr.push({
                                      ...item,
                                      isSelected: false,
                                    });
                                  } else {
                                    tempArr.push(item);
                                  }
                                });
                                setRecentInteractUsers(tempArr);

                                let temp = [];
                                toSendWith.map((item) => {
                                  if (item.contactId !== option.contactId) {
                                    temp.push(item);
                                  }
                                });
                                setToSendWith(temp);
                              }}
                            />
                          );
                        })
                      }
                      filterSelectedOptions
                      options={newEmail.length > 0 ? searchData : []}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.contactEmail) {
                          return option.contactEmail;
                        }
                        if (option.contactName) {
                          return option.contactName;
                        }
                      }}
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <div style={{ display: "flex" }}>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                                loading="lazy"
                                width="50px"
                                height="50px"
                                src={props.contactPictureUrl}
                                alt=""
                              />
                              <div>
                                {props.contactName} <br />
                                {props.contactEmail}
                              </div>
                            </div>
                          </Box>
                        );
                      }}
                      value={toSendWith}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          onChange={handleInputChange}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            if (value !== "" && !toSendWith.includes(value)) {
                              setToSendWith([...toSendWith, value]);
                              setNewEmail("");
                            }
                          }}
                          onKeyDown={(e) => {
                            const value = e.target.value.trim();

                            // Handle the 'Enter' key or 'Space' to add the email
                            if (
                              (e.keyCode === 32 || e.key === "Enter") &&
                              value !== ""
                            ) {
                              if (!toSendWith.includes(value)) {
                                setToSendWith([...toSendWith, value]);
                                setNewEmail("");
                              }
                            }

                            // Handle the 'Backspace' key to remove the last item when input is empty
                            if (
                              (e.keyCode === 8 || e.key === "Backspace") &&
                              newEmail === ""
                            ) {
                              const newToSendWith = [...toSendWith];
                              const removedItem = newToSendWith.pop(); // Remove the last item

                              setToSendWith(newToSendWith);
                              setNewEmail(""); // Clear the input if needed

                              // Optionally handle `recentInteractUsers` array
                              let updatedRecent = recentInteractUsers.map(
                                (item) => {
                                  if (
                                    item.interactUserEmail ===
                                    removedItem?.contactEmail
                                  ) {
                                    return { ...item, isSelected: false };
                                  }
                                  return item;
                                }
                              );

                              setRecentInteractUsers(updatedRecent);
                            }

                            // Clear input on 'Space'
                            if (e.keyCode === 32) {
                              e.target.value = "";
                            }
                          }}
                          fullWidth
                          label="Add Emails"
                          value={newEmail}
                        />
                      )}
                      style={{
                        flex: 1,
                        maxHeight: "100px",
                        overflow: "auto",
                        padding: "10px 0px",
                      }}
                    />

                    {
                      <LoadingButton
                        loading={sendTimeSheetLoading}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        style={{ marginLeft: "10px", maxHeight: "40px" }}
                        disabled={toSendWith.length !== 0 ? false : true}
                        onClick={handleSendTimeSheet}
                      >
                        {!sendTimeSheetLoading ? "Send" : " "}
                      </LoadingButton>
                      // <Button
                      //   variant="contained"
                      //   color="primary"
                      //   style={{ marginLeft: "10px" }}
                      //   disabled={toSendWith.length !== 0 ? false : true}
                      //   onClick={handleSendTimeSheet}
                      // >
                      //   Send
                      // </Button>
                    }
                  </div>
                </div>
              </div>

              <Grid container spacing={2} className="flex-container-vertical">
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <b>Recent</b>
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  {recentInteractUsers.map((user, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={user.userId}>
                      <div
                        onClick={() => {
                          handleRecentInteractUserClick(user);
                          let tempArr = [...recentInteractUsers];
                          tempArr[index].isSelected = true;
                          setRecentInteractUsers(tempArr);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            {user.isSelected ? (
                              <Badge
                                badgeContent={
                                  <DoneIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "white",
                                      backgroundColor: BIBlue,
                                      borderRadius: "5px",
                                    }}
                                  />
                                }
                              >
                                <Avatar
                                  style={{ width: "40px", height: "40px" }}
                                  alt={user.interactUserName}
                                  src={user.interactUserImageUrl}
                                />
                              </Badge>
                            ) : (
                              <Avatar
                                style={{ width: "40px", height: "40px" }}
                                alt={user.interactUserName}
                                src={user.interactUserImageUrl}
                              />
                            )}
                          </div>
                          <div style={{ marginLeft: "10px" }}>
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <b>
                                {user.interactUserName.length > 7
                                  ? user.interactUserName.substring(0, 7) +
                                    "..."
                                  : user.interactUserName}
                              </b>
                              <br />
                              {user.interactUserTradeName.length > 8
                                ? user.interactUserTradeName.substring(0, 8) +
                                  "..."
                                : user.interactUserTradeName}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <div className="flex-container-vertical">
                <span style={{ marginTop: "10px" }}>TimeSheets to Send</span>
                <Divider
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                    borderBottom: "2px solid #EDEDED",
                  }}
                />
                {selectedTimeSheet.map((timesheets, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h4>
                          {moment(timesheets?.timesheetDate).format(
                            "MMMM DD, YYYY"
                          )}
                        </h4>
                        <span>{`${timesheets?.noOfJobs} jobs,  `}</span>
                        <span>{timesheets?.totalHours} hrs</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
