import React, { useState, useEffect } from "react";
import { fetchClientSafetyObservations } from "../../../api/ClientApi";
import { ClientSafetyObservationCard } from "./ClientSafetyObservationCard";
import { Tab, Tabs, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../../components/Loader";
import "../../../assets/css/ClientHome.css";
import { ViewObservationModal } from "./ViewObservationModal";
import { AddSafetyObservationModal } from "./AddSafetyObservationModal";
import { ClientHeader } from "../ClientHeader";

export const ClientSafetyObservations = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [safetyObservationsList, setSafetyObservationsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewObservationModalOpen, setViewObservationModalOpen] =
    useState(false);
  const [individualSafetyObservation, setIndividualSafetyObservation] =
    useState({});
  const [openAddSafetyObservationModal, setOpenAddSafetyObservationModal] =
    useState(false);
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const tabsArray = ["All", "My Observations"];

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const handleSafetyObservationClick = (item) => {
    setViewObservationModalOpen(true);
    setIndividualSafetyObservation(item);
  };

  const handleAddSafetyObservationClick = () => {
    setOpenAddSafetyObservationModal(true);
  };

  const getSafetyIncidentsList = async () => {
    try {
      setIsLoading(true);
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: clientSearchTerm || "",
        UserId: localStorage.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: true,
        SortList: [
          {
            FieldName: "Id",
            Direction: "DESC",
          },
        ],
        SearchList: [
          activeTab === "My Observations"
            ? { UserId: localStorage.userId }
            : {},
        ],
      };
      const safetyObservationsRes = await fetchClientSafetyObservations(obj);
      setSafetyObservationsList(safetyObservationsRes?.data);
      setIsLoading(false);
    } catch (err) {
      console.log("CLIENT SAFETY OBSERVATION ERROR ", err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSafetyIncidentsList();
  }, [activeTab, clientSearchTerm]);

  return (
    <>
      <ClientHeader
        screenName={"Safety Observations"}
        setClientSearchTerm={setClientSearchTerm}
        clientSearchTerm={clientSearchTerm}
      />

      <div className="page">
        <div className="page-content">
          <div className="flex-container-vertical" style={{ flex: 2 }}>
            <div style={{ margin: "15px" }}>
              <div
                className="flex-container-horizontal"
                style={{ justifyContent: "space-between" }}
              >
                <Tabs
                  sx={{
                    ".Mui-selected": {
                      color: localStorage?.clientAccentColor,
                    },
                  }}
                  TabIndicatorProps={{
                    sx: { backgroundColor: localStorage?.clientAccentColor },
                  }}
                  value={activeTab}
                  onChange={handleTabChange}
                >
                  {tabsArray.map((tab, index) => (
                    <Tab
                      key={index}
                      value={tab}
                      label={tab}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        marginRight: "20px",
                      }}
                    />
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Loader />
          ) : safetyObservationsList?.length > 0 ? (
            safetyObservationsList?.map((item) => {
              return (
                <div onClick={() => handleSafetyObservationClick(item)}>
                  <ClientSafetyObservationCard
                    safetyObservationItem={item}
                    isMyObservation={activeTab == "My Observations"}
                  />
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <Typography variant="body">No observations yet.</Typography>
            </div>
          )}
        </div>
      </div>
      <div className="fixed-button-container">
        <button
          className="fixed-button"
          style={{ background: localStorage?.clientAccentColor }}
          onClick={handleAddSafetyObservationClick}
        >
          <AddIcon />
        </button>
      </div>
      {viewObservationModalOpen && (
        <ViewObservationModal
          isOpen={viewObservationModalOpen}
          onClose={() => setViewObservationModalOpen(false)}
          individualSafetyObservation={individualSafetyObservation}
          getSafetyIncidentsList={getSafetyIncidentsList}
          activeTab={activeTab}
        />
      )}
      {openAddSafetyObservationModal && (
        <AddSafetyObservationModal
          from={"OBSERVATION"}
          isOpen={openAddSafetyObservationModal}
          onClose={() => setOpenAddSafetyObservationModal(false)}
          closeViewObservationModal={() => setViewObservationModalOpen(false)}
          getSafetyIncidentsList={getSafetyIncidentsList}
        />
      )}
    </>
  );
};
