import React, { useEffect, useState } from 'react'
import { Card, Chip, Stack, Typography } from '@mui/material';
import "../../../assets/css/ClientHome.css"
import AddIcon from '@mui/icons-material/Add';
import { ClientHeader } from '../ClientHeader';
import { Edit as EditIcon } from "@material-ui/icons";
import { Delete as DeleteIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import CrewModal from './CrewModal';
import { DeleteClientTimesheets, GetClientTimesheetDashboard } from '../../../api/ClientApi';
import moment from 'moment';
import HelpIcon from "@mui/icons-material/Help";
import { BILightGrey, BIBlack } from '../../../assets/buildidColors';
import AddClientTimesheetModal from './AddClientTimesheetModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AlertModal from '../../../components/AlertModal';
import Loader from '../../../components/Loader';
import AddClientTimesheetIndividualModal from './AddClientTimesheetIndividualModal';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import NewTemplateModel from './NewTemplateModel';


const Colors = {
    Green_Light: '#c6d9bb',
    Red_Light: '#f5a9a9',
    LightYellow: '#FFEEBF',
    Green_Dark_Text: '#215112',
    Yellow_Dark_Text: '#684f0a',
    Red_Dark_Text: '#9b2020',
}


const ClientTimesheets = () => {

    let history = useHistory();

    let clientAccentColor = localStorage.getItem("clientAccentColor") ?? '';

    const [isLoading, setIsLoading] = useState(true);

    const [openCrew, setOpencrew] = useState(false);
    const [timesheetlist, setTimesheetlist] = useState([]);

    const [timesheetId, setTimesheetid] = useState(null);
    const [showAddClientTimesheetModal, setShowAddClientTimesheetModal] = useState(false);
    const [showAddClientIndividualTimesheetModal, setShowAddClientIndividualTimesheetModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);

    const [clientSearchTerm,setClientSearchTerm] = useState("");

    useEffect(() => {
        fetchClienttimesheet();
    }, [])

    const fetchClienttimesheet = async () => {
        try {
            const getAllTimeSheetResponse = await GetClientTimesheetDashboard(Number(localStorage?.clientEmployeeId));
            let resData = getAllTimeSheetResponse ?? [];
            setTimesheetlist(resData);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("error", error)
        }
    }

    const deleteTimesheet = async (e, id) => {
        e.stopPropagation();
        try {
            setIsLoading(true);
            const obj = {
                userId: Number(localStorage?.userId),
                returnRecordError: true,
                softDelete: false,
                deleteList: [{ id: Number(id) }]
            };
            await DeleteClientTimesheets(obj);
            await fetchClienttimesheet();
        } catch (e) {
            setIsLoading(false);
            alert("Something went wrong. Please try again later.");
        }
    };

    const onSaveAsTemplateClick = (e, item) => {
        e.stopPropagation();
        setTimesheetid(item?.id);
        setShowAddTemplateModal(true);
    }

    const onEditClientTimesheet = (e, item) => {
        e.stopPropagation();
        if (item?.type === "Individual") {
            setShowAddClientIndividualTimesheetModal(true);
        } else if (item?.type === "Crew") {
            setOpencrew(true);
        } else {
          
        }
        setTimesheetid(item?.id);
       
    }

    const onTimeSheetClick = (item) => {
        if (item.type === 'Crew' && item.noOfActivities === 0) {
            history.push(`/client-crew-timesheets/${item?.id}`)
        } else if (item.type === 'Crew' && item.noOfActivities > 0) {
        } else {
            history.push(`/client-individual-timesheets/${item?.id}`)
        }
    }

    return (
        <>
            <ClientHeader screenName={"Timesheets"} clientSearchTerm={clientSearchTerm} setClientSearchTerm={setClientSearchTerm}/>
            <div className="page rewards-page" style={{ backgroundColor: "#FFFFFF", paddingTop: "2rem" }}>
                {
                    !isLoading ?
                        (
                            <>
                                {
                                    timesheetlist?.length > 0 ?
                                        (
                                            timesheetlist.map((item) => (
                                                <Card className='timelist-card'
                                                    style={{
                                                        cursor: 'pointer',
                                                        background: item?.type === 'Crew' && item.noOfActivities > 0 ? BILightGrey : "#fff"
                                                    }}
                                                    onClick={() => {
                                                        onTimeSheetClick(item);
                                                    }}
                                                >
                                                    <Stack display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                                                        <Typography fontWeight={"600"} fontSize={"17px"} className="timeitem-date">{moment(item?.date).format("MMM DD, YYYY")}</Typography>
                                                        <Stack flexDirection={"row"} gap={1}>
                                                            {(item.type === 'Crew' && item.noOfActivities === 0) || item.type === 'Individual' ? (
                                                                <>
                                                                    {
                                                                        item?.type === "Crew" && item?.noOfActivities === 0 && (
                                                                            <FileCopyIcon style={{ color: "blue", cursor: "pointer" }} onClick={(e) => onSaveAsTemplateClick(e, item)} />
                                                                        )
                                                                    }
                                                                    <EditIcon style={{ color: "blue", cursor: "pointer" }} onClick={(e) => onEditClientTimesheet(e, item)} />
                                                                    <DeleteIcon style={{ color: "red", cursor: "pointer" }} onClick={(e) => deleteTimesheet(e, item?.id)} />
                                                                </>
                                                            ) : (
                                                                <HelpIcon
                                                                    onClick={() => {
                                                                        setShowHelpModal(true);
                                                                    }}
                                                                    style={{
                                                                        fontSize: "25px",
                                                                        color: "#000000",
                                                                    }}
                                                                />
                                                            )}
                                                        </Stack>
                                                    </Stack>
                                                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                                                        <Stack flexDirection={"row"} gap={2} position={"relative"}>
                                                            <Stack className="jobs-count">
                                                                <Typography>{item?.type === "Crew" && item?.noOfActivities === 0 ? "Employees" : item.type === "Crew" && item.noOfActivities > 0 ? "Activities" : "Jobs"}</Typography>
                                                                <Typography>{item?.type === "Crew" && item?.noOfActivities === 0 ? item?.noOfEmployees : item.type === "Crew" && item.noOfActivities > 0 ? item.noOfActivities : item?.noOfJobs}</Typography>
                                                            </Stack>
                                                            <Stack justifyContent={'center'} alignItems={'center'}>
                                                                <div style={{ width: '1px', backgroundColor: "#cccccc", height: '70%' }}></div>
                                                            </Stack>
                                                            <Stack className='hours-count'>
                                                                <Typography>Hours</Typography>
                                                                <Typography>{item?.totalHours}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                        <Chip
                                                            label={item?.status}
                                                            style={{
                                                                background: item.status === "Approved"
                                                                    ? Colors.Green_Light
                                                                    : item.status === "Rejected"
                                                                        ? Colors.Red_Light
                                                                        : Colors.LightYellow,
                                                                color:
                                                                    item.status === "Approved"
                                                                        ? Colors.Green_Dark_Text
                                                                        : item.status === "Rejected"
                                                                            ? Colors.Red_Dark_Text
                                                                            : Colors.Yellow_Dark_Text,
                                                            }}

                                                        />
                                                    </Stack>
                                                </Card>
                                            ))

                                        )
                                        :
                                        (
                                            <Stack justifyContent={'center'} alignItems={'center'}
                                                style={{ color: '#8d8d8d', marginTop: '100px' }}
                                            >
                                                You have not submitted any Timesheets yet.
                                            </Stack>
                                        )
                                }
                            </>
                        )
                        :
                        (
                            <Stack justifyContent={'center'} alignItems={'center'}>
                                <Loader />
                            </Stack>
                        )
                }

                
                <div className="fixed-button-container">
                    <button className="fixed-button" style={{ background: localStorage?.clientAccentColor }} onClick={() => {
                        setShowAddClientTimesheetModal(true);
                        setTimesheetid(null);
                    }}>
                        <AddIcon />
                    </button>
                </div>

                 <CrewModal  fetchClienttimesheet={fetchClienttimesheet} setTimesheetid={setTimesheetid} timesheetId={timesheetId} setOpencrew={setOpencrew} openCrew={openCrew} />
  
                {
                    showAddClientTimesheetModal &&
                    (
                        <AddClientTimesheetModal
                            show={showAddClientTimesheetModal}
                            handleClose={() => {
                                setShowAddClientTimesheetModal(false);
                            }}
                            onAddIndividualTimesheet={() => {
                                setShowAddClientTimesheetModal(false);
                                setShowAddClientIndividualTimesheetModal(true);
                            }}
                            onAddCrewTimesheet={()=>{
                                setShowAddClientTimesheetModal(false);
                                setOpencrew(true);
                            }}
                        />
                    )
                }

                {
                    showAddClientIndividualTimesheetModal && (
                        <AddClientTimesheetIndividualModal
                            show={showAddClientIndividualTimesheetModal}
                            onClose={() => {
                                setTimesheetid(null);
                                setShowAddClientIndividualTimesheetModal(false);
                            }}
                            onTimesheetAdded={() => {
                                setIsLoading(true);
                                setTimesheetid(null);
                                fetchClienttimesheet();
                                setShowAddClientIndividualTimesheetModal(false);
                            }}
                            individualTimesheetId={timesheetId ?? null}
                        />
                    )
                }

                {
                    showHelpModal &&
                    (
                        <AlertModal
                            isOpen={showHelpModal}
                            onClose={() => {
                                setShowHelpModal(false);
                            }}
                            title="Your foreman created the timesheet for your crew."
                            clientAccentColor={clientAccentColor}
                        />
                    )
                }


                {
                    showAddTemplateModal && (
                        <NewTemplateModel
                            open={showAddTemplateModal}
                            onClose={() => {
                                setShowAddTemplateModal(false);
                                setTimesheetid(null);
                            }}
                            onTemplateAdded={() => {
                                setShowAddTemplateModal(false);
                                setTimesheetid(null);
                            }}
                            timesheetId = {timesheetId ?? null}

                        />
                    )
                }

            </div>
        </>
    )
}

export default ClientTimesheets