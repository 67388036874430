import {
  Container,
  Grid,
  ListItem,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../assets/css/Search.css";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchUniversalSearch } from "../../api/UserApi";
import SearchListSection from "./SearchListSection";
import { useLocation } from "react-router-dom";
import { fetchTrades } from "../../api/TradesApi";
import Loader from "../../components/Loader";
import BuildIcon from "@mui/icons-material/Build";
import { Autocomplete as GooglePlacesAutocomplete } from "@react-google-maps/api";
import { Close as CloseIcon } from "@material-ui/icons";
import { getAddress } from "../../api/SitesApi";
import {
  showTutorialForNewUser,
  updateTutorialForNewUser,
} from "../../assets/helpers";
import ContentSearchTutorial from "../../components/Tutorial/ContentSearchTutorial";

function Universalsearch() {
  const [typelist, setTypelist] = useState([
    { text: "Companies", checked: false, itemRecordType: "company" },
    { text: "Manufactures", checked: false, itemRecordType: "manufacturer" },
    { text: "Products", checked: false, itemRecordType: "product" },
    { text: "Trades", checked: false, itemRecordType: "trade" },
  ]);

  const [companiesData, setCompaniesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [tradesData, setTradesData] = useState([]);
  const [tradeList, setTradelist] = useState([]);
  const [allTrade, setAllTrade] = useState(false);
  const [isLoad, setIsload] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [address, setAddress] = useState("");
  const [selectCity, setSelectcity] = useState([]);
  const location = useLocation();
  const [tutorialIndex, setTutorialIndex] = useState(1);

  useEffect(() => {
    callInitialData();
  }, [location?.state]);

  const callInitialData = async () => {
    setIsload(true);
    await callUniversalsearch({
      UserId: Number(localStorage.userId),
      SearchString: location?.state?.search,
      SearchType: "",
      SearchLocations: "",
      SearchTrades: "",
      PageNumber: 1,
      NrRowsPerPage: 20,
    });
    let tradeObj = {
      PageNr: 1,
      FullSearch: "",
      IncludeRecordNr: true,
      TypeOfObjectReturned: "NameOnly",
      SearchList: [{}],
      NrOfRecPerPage: 12,
    };
    await getAlltrades(tradeObj);
    setIsload(false);
    getCurrentLocation();
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(currentLocation, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("Please allow location from your browser");
        getCurrentLocation();
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        break;
    }
  };

  const currentLocation = async (position) => {
    var lat = position?.coords?.latitude;
    var lng = position?.coords?.longitude;
    getSitesofCurrentlocation(lat, lng);
  };

  const getSitesofCurrentlocation = async (lat, lng) => {
    let addressData = await getAddress({ latitude: lat, longitude: lng });
    let cityObj = {
      city: addressData?.objAddress?.city,
      checked: false,
    };
    setSelectcity([cityObj]);
  };

  const callUniversalsearch = async (searchObj) => {
    let result = await fetchUniversalSearch(searchObj);
    const dataByType = {};
    result.forEach((item) => {
      if (!dataByType[item.type]) {
        dataByType[item.type] = [];
      }
      dataByType[item.type].push(item);
    });
    const companiesData = dataByType["Companies"] || [];
    const productsData = dataByType["Products"] || [];
    const manufacturersData = dataByType["Manufacturers"] || [];
    const tradesData = dataByType["Trades"] || [];
    console.log("companiesData", companiesData);
    console.log("productsData", productsData);
    console.log("manufacturersData", manufacturersData);
    console.log("tradesData", tradesData);
    setCompaniesData(companiesData);
    setProductsData(productsData);
    setManufacturersData(manufacturersData);
    setTradesData(tradesData);
  };

  const getAlltrades = async (tradeObj) => {
    let res = await fetchTrades(tradeObj);
    console.log("trade response", res);
    if (res?.data?.length > 0) {
      let getList = res.data.map((item) => {
        return {
          ...item,
          checked: false,
        };
      });
      setTradelist(getList);
    }
  };

  const showMoreTrade = async () => {
    if (allTrade) {
      let tradeObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: true,
        TypeOfObjectReturned: "NameOnly",
        SearchList: [{}],
        NrOfRecPerPage: 12,
      };
      getAlltrades(tradeObj);
      setAllTrade(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      let tradeObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: true,
        TypeOfObjectReturned: "NameOnly",
        SearchList: [{}],
      };
      getAlltrades(tradeObj);
      setAllTrade(true);
    }
  };

  const onSeeMoreClick = () => {};

  const selectType = async (e, item, index) => {
    let getList = [...typelist];
    if (item?.checked) {
      getList[index].checked = false;
    } else {
      getList[index].checked = true;
    }
    setTypelist(getList);
    let commaSep = getList
      .filter((item) => item.checked)
      .map((item) => item.itemRecordType)
      .join(", ");
    let getTrade = tradeList
      .filter((item) => item?.checked === true)
      .map((item) => item?.id)
      .join(",");
    let getCity = selectCity
      .filter((item) => item?.checked === true)
      .map((item) => item?.city)
      .join(",");
    setIsload(true);
    let searchObj = {
      UserId: Number(localStorage.userId),
      SearchString: location.state.search,
      SearchType: commaSep,
      SearchLocations: getCity,
      SearchTrades: getTrade,
      PageNumber: 1,
      NrRowsPerPage: 20,
    };
    console.log("searchObj", searchObj);
    await callUniversalsearch(searchObj);
    setIsload(false);
  };

  // Select Trade
  const selectTrade = async (e, item, index) => {
    let getList = [...tradeList];
    if (item?.checked) {
      getList[index].checked = false;
    } else {
      getList[index].checked = true;
    }
    setTradelist(getList);
    let getTrade = getList
      .filter((item) => item?.checked === true)
      .map((item) => item?.id)
      .join(",");
    let commaSep = typelist
      .filter((item) => item.checked)
      .map((item) => item.itemRecordType)
      .join(", ");
    let getCity = selectCity
      .filter((item) => item?.checked === true)
      .map((item) => item?.city)
      .join(",");
    setIsload(true);
    let searchObj = {
      UserId: Number(localStorage.userId),
      SearchString: location.state.search,
      SearchType: commaSep,
      SearchLocations: getCity,
      SearchTrades: getTrade,
      PageNumber: 1,
      NrRowsPerPage: 20,
    };
    await callUniversalsearch(searchObj);
    setIsload(false);
  };

  const handleSearch = () => {
    let place = autocomplete.getPlace();
    if (place?.address_components) {
      let getCity = place?.address_components.find((address) =>
        address.types.includes("locality")
      )?.long_name;
      if (selectCity.length === 3) {
        selectCity[0].city = getCity;
        setSelectcity(selectCity);
      } else {
        let cityObj = { city: getCity, checked: false };
        setSelectcity([...selectCity, cityObj]);
      }
      setAddress(place.formatted_address);
    }
  };

  const setSearchTerm = (getAddress) => {
    setAddress(getAddress);
  };

  const clearLocation = () => {
    setAddress("");
  };

  // Select Location
  const selectQuicklocation = async (item, index) => {
    let getList = [...selectCity];
    setAddress("");
    if (item?.checked) {
      getList[index].checked = false;
    } else {
      getList[index].checked = true;
    }
    setSelectcity(getList);
    let getCity = getList
      .filter((item) => item?.checked === true)
      .map((item) => item?.city)
      .join(",");
    let getTrade = tradeList
      .filter((item) => item?.checked === true)
      .map((item) => item?.id)
      .join(",");
    let commaSep = typelist
      .filter((item) => item.checked)
      .map((item) => item.itemRecordType)
      .join(", ");
    setIsload(true);
    let searchObj = {
      UserId: Number(localStorage.userId),
      SearchString: location.state.search,
      SearchType: commaSep,
      SearchLocations: getCity,
      SearchTrades: getTrade,
      PageNumber: 1,
      NrRowsPerPage: 20,
    };
    await callUniversalsearch(searchObj);
    setIsload(false);
  };

  return (
    <div className="page" style={{ alignItems: "center" }}>
      <Container>
        <div className="universal-search">
          <span className="search-type">Type</span>
          <Grid xs={12} container className="type-list" columnGap={1}>
            {typelist.map((item, index) => (
              <Grid xs={2}>
                <ListItem
                  onClick={(e) => selectType(e, item, index)}
                  className={`list-item ${item?.checked ? "active" : ""}`}>
                  {item.itemRecordType == "product" ||
                  item.itemRecordType == "trade" ? (
                    <BuildIcon />
                  ) : (
                    <BusinessCenterIcon />
                  )}
                  <span>{item?.text}</span>
                </ListItem>
              </Grid>
            ))}
          </Grid>
          <Accordion className="advanced-accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ margin: "0px" }}
              className="advanced-summary">
              <Typography>Advanced</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <style>
                {`.pac-container {
                                             width: 260px !important;
                                   }`}
              </style>
              <GooglePlacesAutocomplete
                onPlaceChanged={handleSearch}
                onLoad={(newInstance) => {
                  setAutocomplete(newInstance);
                }}
                types={["(cities)"]}
                options={{
                  componentRestrictions: { country: ["US", "CA", "GB"] }, // Restrict to USA, Canada, and UK
                }}
                className="location-search">
                <TextField
                  variant="outlined"
                  placeholder="Location - Type-Here"
                  size="small"
                  className="search-place"
                  style={{ marginBottom: "14px" }}
                  value={address}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  InputProps={{
                    style: {
                      border: "none",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        {address !== "" && (
                          <IconButton onClick={clearLocation}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </GooglePlacesAutocomplete>
              <div className="quick-location">
                <span className="quick-text">Quick Location</span>
                <Grid xs={12} container columnGap={2} className="quickloc-grid">
                  {selectCity.map((item, index) => (
                    <Grid xs={2}>
                      <div
                        className={`firstQuick ${
                          item?.checked ? "active" : ""
                        }`}
                        onClick={() => selectQuicklocation(item, index)}>
                        <span>{item?.city}</span>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div className="trade-list">
                {tradeList.map((item, index) => (
                  <div
                    className={`list-item ${item.checked ? "active" : ""}`}
                    onClick={(e) => selectTrade(e, item, index)}>
                    <span className="trade-name">{item?.name}</span>
                  </div>
                ))}
              </div>
              <span className="show-more" onClick={showMoreTrade}>
                {allTrade ? "Less More Trades" : "Show More Trades"}
              </span>
            </AccordionDetails>
          </Accordion>
          {isLoad ? (
            <Loader />
          ) : (
            <>
              {productsData?.length > 0 && (
                <SearchListSection
                  getData={productsData}
                  universalsearch={true}
                  from={"product"}
                />
              )}
              <br></br>
              {manufacturersData?.length > 0 && (
                <SearchListSection
                  getData={manufacturersData}
                  universalsearch={true}
                  itemNameKey="description1"
                  itemSecondaryKey="address"
                  from={"manufacturer"}
                />
              )}
              <br></br>
              {companiesData?.length > 0 && (
                <SearchListSection
                  getData={companiesData}
                  universalsearch={true}
                  itemNameKey="description1"
                  itemSecondaryKey="address"
                  from="company"
                />
              )}
              <br></br>
              {tradesData?.length > 0 && (
                <SearchListSection
                  getData={tradesData}
                  universalsearch={true}
                  itemNameKey="description1"
                  itemSecondaryKey="address"
                  from="trade"
                />
              )}
            </>
          )}
        </div>
      </Container>
      {showTutorialForNewUser("contentSearch") && (
        <ContentSearchTutorial
          onPress={() => {
            setTutorialIndex((p) => {
              if (p >= 3) {
                if (showTutorialForNewUser("contentSearch")) {
                  updateTutorialForNewUser("contentSearch");
                }
                return 0;
              } else {
                return p + 1;
              }
            });
          }}
          tutorialIndex={tutorialIndex}
        />
      )}
    </div>
  );
}
export default Universalsearch;
