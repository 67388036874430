import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MenuList from "./MenuList";
import { BrowserRouter, Link, useLocation } from "react-router-dom";
import { makeStyles, IconButton } from "@material-ui/core";
import MapLogo from "../assets/images/map-marker-yellow.png";
import { useState } from "react";
import { version } from "../../package.json";
import TopAppBar from "./TopAppBar";
import BuilderBucksBar from "./BuilderBucksBar";
import Toastify from "../Toastify";
import { Notifications } from "react-push-notification";
import { ToastProvider } from "react-toast-notifications";
import { Avatar, Drawer, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getChatGroupDashboard } from "../api/ConnectApi";
import "../assets/css/Menubar.css";
import {
  fetchClientEmployee,
  updateClientEmployeeInfo,
} from "../api/ClientApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PlaceHolderImage from "../assets/images/AvatarImage.jpeg";

const MiniDrawer = (props) => {
  const drawerWidthLarge = 235; // Large drawer width
  const drawerWidthSmall = 185; // Small drawer width

  const isLargeScreen = useMediaQuery("(min-width:1920px)");
  const drawerWidth = isLargeScreen ? drawerWidthLarge : drawerWidthSmall;
  const history = useHistory();

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [chatNotification, setChatnotification] = useState(false);
  const [chatGroupData, setChatGroupData] = useState(null);
  const [preClient, setPrevClient] = useState("");
  const [clientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [loadingClient, setLoadingClient] = useState(null); // Store currently loading client

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const fetchData = async () => {
    try {
      const data = await getChatGroupDashboard(
        Number(localStorage?.getItem("userId"))
      );
      let getChatGroupData = data?.sort((a, b) => {
        let dateA = new Date(a?.dateSent),
          dateB = new Date(b?.dateSent);
        return dateB - dateA;
      });
      let getValue = getChatGroupData
        ?.filter((group) => group?.nrOfUsersInGroup > 3)
        ?.slice(0, 3);
      setChatGroupData(getValue);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateEmployee = async (ClientInfo) => {
    const obj = { Id: ClientInfo.ClientEmployeeId };
    let res = updateClientEmployeeInfo(obj);
  };
  const handleClientClick = (client) => {
    setLoading(true);
    setLoadingClient(client);
    updateEmployee(client);
    let currentDate = new Date().toISOString().slice(0, -1);
    let temp = { ...client, LastDateUsedByEmployee: currentDate };

    let updated = clientList.map((item) =>
      temp.ClientId === item.ClientId ? temp : item
    );

    let UpdateLocalStorage = JSON.stringify(updated);
    localStorage.setItem("clientList", UpdateLocalStorage);
    setClientList(updated);
    sortClient();

    localStorage.setItem("clientName", client?.ClientName);
    localStorage.setItem("OrganizationName", client?.OrganizationName);
    localStorage.setItem("ClientLogo", client?.ClientLogo);
    localStorage.setItem("clientAccentColor", client?.AccentColor || "white");
    localStorage.setItem("clientId", client?.ClientId);
    localStorage.setItem("clientEmployeeId", client?.ClientEmployeeId);

    setTimeout(() => {
      setLoading(false);
      setLoadingClient(null);
      history.push("/client");
    }, 2000);
  };
  React.useEffect(() => {
    fetchData();
    if (JSON.parse(localStorage.getItem("clientList"))) {
      getClientEmployees();
    }
  }, []);

  const drawer = (
    <div>
      <MenuList
        setChatnotification={setChatnotification}
        // value={open}
        chatGroupData={chatGroupData}
        fetchData={fetchData}
        isShowChatInClient={props?.isShowChatInClient}
        isClientApplicationsLoading={props?.isClientApplicationsLoading}
      />
    </div>
  );
  const sortClient = () => {
    let data = JSON.parse(localStorage.getItem("clientList")).sort(
      (a, b) =>
        new Date(b.LastDateUsedByEmployee) - new Date(a.LastDateUsedByEmployee)
    );
    setClientList(data);
    if (data?.length > 0) {
      setPrevClient(data[0]?.ClientId);
    }
  };
  const getClientEmployees = async () => {
    const payload = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: localStorage.primaryEmail,
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [{}],
    };

    let res = await fetchClientEmployee(payload);
    let data = res.data;
    let temp = [];
    JSON.parse(localStorage.getItem("clientList")).map((item) => {
      for (let i in data) {
        if (data[i].clientId == item.ClientId) {
          temp.push({ ...item, LastDateUsedByEmployee: data[i]?.lastDateUsed });
        }
      }
    });

    let convertData = JSON.stringify(temp);
    localStorage.setItem("clientList", convertData);
    sortClient();
  };
  // Remove this const when copying and pasting into your project.
  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                borderRight: "none",
                backgroundColor: "#f2f2f2",
              },
            }}
          // style={{ width: window.innerWidth < 1024 ? "auto" : "0px" }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                border: "none",
                borderRight: "none",
                backgroundColor: "#f2f2f2",
                // width: window.innerWidth < 1024 ? "0px" : drawerWidth,
                overflow: 'hidden'
              },
            }}
            // open
            // open={window.innerWidth > 1024 ? true : false}
            className={"main-drawer"}>
            {loading && loadingClient ? (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgb(33, 150, 243)",
                    zIndex: 1000,
                  }}
                >
                  <Avatar
                    sx={{
                      width: 100,
                      height: 100,
                    }}
                    src={loadingClient?.ClientLogo || PlaceHolderImage}
                    style={{ animation: "fadeInOut 2s ease-in-out infinite" }}
                  />
                  <p style={{ marginTop: "10px", color: "#fff", fontSize:'30px', animation: "fadeInOut 2s ease-in-out infinite" }}>
                    {loadingClient?.OrganizationName || loadingClient?.ClientName}
                  </p>

                  {/* Add keyframes for zoom-in effect */}
                  <style>
                    {`
                      @keyframes fadeInOut {
                        0% {
                          opacity: 0;
                        }
                        50% {
                          opacity: 1;
                        }
                        100% {
                          opacity: 0;
                        }
                      }
                    `}
                  </style>
                </div>

              </>
            ) : (
              <>{drawer}</>
            )}
          </Drawer>
        </Box>

        <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
          <div
            className={
              navigator.userAgent.indexOf("Chrome") !== -1
                ? "topbar-div-width-chrome"
                : "topbar-div-width"
            }>
            <TopAppBar
              setChatnotification={setChatnotification}
              chatNotification={chatNotification}
              // handleDrawerOpen={handleDrawerOpen}
              profilePictureUrl={props.profilePictureUrl}
              firstName={props.firstName}
              lastName={props.lastName}
              onLogOut={props.onLogOut}
              setUserId={props.setUserId}
              tradeName={props.tradeName}
              drawerWidth={drawerWidth}
              preClient={preClient}
              handleClientClick={handleClientClick}
              handleDrawerToggle={handleDrawerToggle}
            />
          </div>
          <div className="topbar-body">
            <Toastify />
            {props.children}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default MiniDrawer;
