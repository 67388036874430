import React, { useState } from "react";

import { IconButton, Tooltip, Button } from "@material-ui/core";
import {
  Mail as MailIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandIcon,
  ExpandLess as CollapseIcon,
} from "@material-ui/icons";

import PromptModal from "../../components/PromptModal";
import PictureViewer from "../../components/PictureViewer";
import ProfilePicturePlaceholder from "../../assets/images/profile-picture-placeholder.png";
import { useHistory } from "react-router";
import { saveContactEvent } from "../../api/ContactsApi";
import { isoToMonthDateYear } from "../../assets/helpers";
import { saveChatGroupUsers } from "../../api/ChatApi";
import { Card, Stack } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Avatar,
} from "@mui/material";

function ContactCard(props) {
  const history = useHistory();
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [blockConfirmModalOpen, setBlockConfirmModalOpen] = useState(false);

  // Handle block and delete button clicks
  function handleButtonClick(config) {
    try {
      // Use appropriate eventStatusId
      let eventStatusId = 0;
      if (config === "block") eventStatusId = 4; // 4 = block
      else if (config === "delete") eventStatusId = 5; // 5 = delete
      // Error if config is neither block or delete
      else if (eventStatusId === 0) throw new Error();

      saveContactEvent({
        Id: 0,
        UserId: localStorage.userId,
        ContactEmail: props.contact.data.userEmail,
        EventStatusId: eventStatusId,
        ModifiedBy: localStorage.userId,
      }).then((res) => {
        props.updateContacts();
        setDeleteConfirmModalOpen(false);
      });
    } catch (error) {
      console.log(error);
      if (config === "block") alert("Error blocking contact!");
      else if (config === "delete") alert("Error deleting contact!");
    }
  }
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const navigateTochat = async (e, userId) => {
    e.preventDefault();
    console.log("fff", userId);
    const saveListArray = [
      {
        Id: 0,
        ChatGroupId: 0,
        UserId: userId,
        ModifiedBy: Number(localStorage.getItem("userId")),
        Muted: false,
      },
    ];
    const chatGroupObj = {
      UserId: Number(localStorage.getItem("userId")),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: saveListArray,
    };
    let result = await saveChatGroupUsers(chatGroupObj);
    if (result.data.length > 0) {
      let chatGroupId = result.data[0]?.objData.chatGroupId;
      history.push({
        pathname: "/chat",
        state: {
          from: "New Chat",
          chatGroupId: chatGroupId,
          showInList: true,
        },
      });
    }
  };

  return (
    <>
      <PictureViewer
        isOpen={pictureViewerOpen}
        onClose={() => setPictureViewerOpen(false)}
        src={
          props.selfInfo == "selfInfo"
            ? props?.contact1[0]?.pictureUrl || ProfilePicturePlaceholder
            : props.contact.data.pictureUrl || ProfilePicturePlaceholder
        }
      />

      <PromptModal
        isOpen={deleteConfirmModalOpen}
        onClose={() => setDeleteConfirmModalOpen(false)}
        title="Are you sure you want to delete this contact?"
        desc="This action cannot be undone."
        redButtonText="Delete"
        onRedButtonClick={() => handleButtonClick("delete")}
      />

      <PromptModal
        isOpen={blockConfirmModalOpen}
        onClose={() => setBlockConfirmModalOpen(false)}
        title="Are you sure you want to block this contact?"
        desc="This action cannot be undone."
        redButtonText="Block"
        onRedButtonClick={() => handleButtonClick("block")}
      />

      {props.selfInfo == "selfInfo" && (
        <Card>
          <ListItem
            sx={{ padding: "10px 0px" }}
            // secondaryAction={
            // 	<>
            // 		<Tooltip title="Send Message">
            // 			<IconButton
            // 				style={{ marginRight: '50px' }}
            // 				color="primary"
            // 				onClick={(e) => navigateTochat(e, Number(props?.contact1[0].userId))}
            // 			>
            // 				<QuestionAnswerIcon />
            // 			</IconButton>
            // 		</Tooltip>
            // 	</>
            // }
            disablePadding
          >
            <Avatar
              onClick={() => setPictureViewerOpen(true)}
              variant="circular"
              style={{ marginLeft: "15px", cursor: "pointer" }}
              src={
                props.selfInfo == "selfInfo" ? (
                  props?.contact1[0]?.pictureUrl || ProfilePicturePlaceholder
                ) : (
                  <></>
                )
              }
            ></Avatar>
            <ListItemButton
              onClick={() => {
                Number(props?.contact1[0]?.userId) ==
                  Number(localStorage.userId) && history.push(`/profile`);
              }}
            >
              <ListItemText
                primary={
                  <>
                    <b>
                      {props?.contact1[0]?.firstName +
                        " " +
                        props?.contact1[0]?.lastName}
                    </b>{" "}
                    <span style={{ color: "GrayText" }}>(me)</span>
                  </>
                }
                secondary={props?.contact1[0]?.tradeName}
              />
            </ListItemButton>
          </ListItem>
        </Card>
      )}
      <div style={{ marginBottom: "10px" }}>
        {props.selfInfo != "selfInfo" && (
          <Card>
            <ListItem
              secondaryAction={
                <>
                  <Tooltip title="Send Message">
                    <IconButton
                      color="primary"
                      onClick={(e) =>
                        navigateTochat(e, Number(props.contact.data.userId))
                      }
                    >
                      <QuestionAnswerIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Contact">
                    <IconButton
                      color="secondary"
                      onClick={() => setDeleteConfirmModalOpen(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
              disablePadding
            >
              <Avatar
                onClick={() => setPictureViewerOpen(true)}
                variant="circular"
                style={{ marginLeft: "15px", cursor: "pointer" }}
                src={
                  props.selfInfo !== "selfInfo" ? (
                    props.contact.data?.pictureUrl || ProfilePicturePlaceholder
                  ) : (
                    <></>
                  )
                }
              ></Avatar>
              <ListItemButton
                onClick={() => {
                  Number(props.contact.data.userId) !==
                    Number(localStorage.userId) &&
                    history.push(
                      `/user-dashboard-profile/${props.contact.data.userId}`
                    );
                }}
              >
                <ListItemText
                  primary={
                    <b>
                      {props.contact.data?.firstName +
                        " " +
                        props.contact.data?.lastName}
                    </b>
                  }
                  secondary={props.contact.data?.tradeName}
                />
              </ListItemButton>
            </ListItem>
          </Card>
        )}
      </div>
    </>
  );
}

export default ContactCard;
