import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Chip,
} from "@material-ui/core";
import {
  Visibility as ShowIcon,
  VisibilityOff as HideIcon,
} from "@material-ui/icons";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../components/Loader";
import AlertModal from "../../components/AlertModal";
import {
  signIn,
  userLogin,
  createAccount,
  savePersonalInfo,
  saveUserAddress,
  generateResetCode,
  isValidResetCode,
  saveNewPassword,
  getUserDashboard,
  getRefUserName,
  updateVerifiedDetails,
  fetchUserCompanies,
  saveUserCompanies,
} from "../../api/UserApi";
import "../../assets/css/Login.css";
import BuildIDLogo from "../../assets/images/Build-ID-Transparent.png";
import AppleStoreLogo from "../../assets/images/appleStore.png";
import PlayStoreLogo from "../../assets/images/playStore.png";
import {
  BIGrey,
  BIGreen,
  BIRed,
  BIYellow,
  BIBlue,
  BILightGrey,
} from "../../assets/buildidColors";
import { FaCheck, FaTimes } from "react-icons/fa";
import { fetchUserTrades } from "../../api/TradesApi";
import OtpInput from "react-otp-input";
import brokenLink from "../../assets/images/broken-link.png";
import Bowser from "bowser";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";
import "../../assets/css/referral.css";
import { getToken } from "../../firebase";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import packageInfo from "../../../package.json";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import { firebase_app } from "../../firebase.js";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import googleLogo from "./../../assets/images/google-logo.png";
import fbLogo from "./../../assets/images/fb_logo.png";
import AppleIcon from "@mui/icons-material/Apple";
import { sha256 } from "crypto-hash";
import { Modal, Stack } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { fetchCompanies, saveCompanies2 } from "../../api/CompanyApi";
import TradeCardModal from "../profile/sections/TradeCardModal";
import MapScreen from "../newsite/MapScreen.jsx";
import {
  fetchNearestSitesList,
  saveOnBoardingUserExperience,
} from "../../api/SitesApi";
import TakeTour from "../login/takeatour/TakeTour";

const filter = createFilterOptions();

let firstName = "";
let lastName = "";
const auth = getAuth(firebase_app);
// let emailRegexp = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
let emailRegexp = new RegExp(
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
);

// var isAndroid = /Android/i.test(navigator.userAgent);
// var isIphone = /iPhone/i.test(navigator.userAgent);
// var isIpad = /iPad/i.test(navigator.userAgent);
const userAgent = navigator.userAgent || navigator.vendor || window.opera;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? BIBlue : "#308fe8",
  },
}));

function ReferralSignUp(props) {
  const history = useHistory();
  const [alertModalTitle, setAlertModalTitle] = useState(null);
  const [alertModalBody, setAlertModalBody] = useState(null);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isEmailPrivate, setIsEmailPrivate] = useState(false);
  const [emailFlag, setEmailFlag] = useState(false);
  // const [activeForm, setActiveForm] = useState("create-account");
  const [activeForm, setActiveForm] = useState("");

  const [refPersonName, setRefPersonName] = useState("");

  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      const url = `https://mybuilderid.onelink.me/hhPN?af_web_dp=https%3A%2F%2Fbimiscwebapi-web-user-production.azurewebsites.net%2Fcreate-account&af_xp=custom&pid=BI_user&af_dp=mybuilderid%3A%2F%2F&deep_link_value=${props?.referId}`
      window.location.href = url;
      return;
    } else {
      // getReferrelName();
      // setActiveForm("FinishPage");
      setRefPersonName("true");
      setActiveForm("create-account");
    }

    return;
    // let url = ''
    // if (/android/i.test(userAgent)) {
    //   // const url = `https://play.google.com/store/apps/details?id=com.mybuilderid&referrer=utm_source=${props?.referId}%26adin=BI_user`;
    //   // const url = `https://mybuilderid.onelink.me/hhPN?af_xp=custom&pid=MAIN&is_retargeting=true&af_reengagement_window=30d&af_inactivity_window=7d&deep_link_value=BI_user&af_dp=mybuilderid%3A%2F%2F&deep_link_sub1=${props?.referId}&af_force_deeplink=true`
    //   url = `https://mybuilderid.onelink.me/hhPN?af_xp=custom&pid=MAIN&is_retargeting=true&af_reengagement_window=30d&af_inactivity_window=7d&deep_link_value=BI_user&af_dp=mybuilderid%3A%2F%2F&deep_link_sub1=${props?.referId}&af_force_deeplink=true`
    //   window.location.href = url;
    // } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    //   url = `https://mybuilderid.onelink.me/hhPN?af_xp=custom&pid=MAIN&is_retargeting=true&af_reengagement_window=30d&af_inactivity_window=7d&deep_link_value=BI_user&af_dp=mybuilderid%3A%2F%2F&deep_link_sub1=${props?.referId}&af_force_deeplink=true`
    //   window.location.href = url;
    // }

    //   // const url = "mybuilderid://"; // Custom URL scheme for your app
    //   // const appStoreURL =
    //   //   "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL
    // }
    // async function getReferrelName() {
    //   try {
    //     const getRefUserNameRes = await getRefUserName(Number(props.referId));
    //     setRefPersonName(getRefUserNameRes[0]?.userName);
    //     setActiveForm("create-account");
    //   } catch (error) {
    //     console.log("getReferrelName Error : ", error);
    //   }
    // }
    console.log("current path : ", currentPath);
    if (currentPath.includes("/refer")) {
      // getReferrelName();
      // setActiveForm("FinishPage");
      setRefPersonName("true");
      setActiveForm("create-account");
    } else {
      setActiveForm("create-account");
    }
  }, []);

  console.log("Email ==>", email);

  function getActiveForm() {
    switch (activeForm) {
      case "login":
        return (
          <LoginForm
            history={history}
            email={email}
            handleEmailTextfieldChange={(event) => setEmail(event.target.value)}
            onLogIn={props.onLogIn}
            onForgotClick={() => setActiveForm("forgot-password")}
            onCreateClick={() => setActiveForm("create-account")}
          />
        );
      case "reset-password":
        return (
          <ResetPasswordForm
            email={email}
            handleEmailTextfieldChange={(event) => setEmail(event.target.value)}
            onBackClick={() => setActiveForm("forgot-password")}
            goToLoginForm={() => setActiveForm("login")}
          />
        );
      case "create-account":
        return (
          <>
            {refPersonName ? (
              <CreateAccountForm
                refPersonName={refPersonName}
                referId={props.referId}
                style={{ autoCapitalize: "none" }}
                history={history}
                email={email}
                handleEmailTextfieldChange={(event) => {
                  let value = event.target.value?.trim();
                  if (value.length > 0) {
                    value = value[0].toLowerCase() + value.slice(1);
                  }
                  setEmail(value);
                }}
                setAlertModalTitle={setAlertModalTitle}
                setAlertModalBody={setAlertModalBody}
                onLogIn={props.onLogIn}
                setActiveForm={setActiveForm}
                firstName={firstName}
                lastName={lastName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setIsEmailPrivate={setIsEmailPrivate}
                emailFlag={emailFlag}
                setEmailFlag={setEmailFlag}
                onBackClick={() => setActiveForm("login")}
                goToVerifyForm={() => setActiveForm("verify-email")}
              />
            ) : currentPath == "/create-account" ? (
              <CreateAccountForm
                refPersonName={refPersonName}
                referId={props.referId}
                history={history}
                email={email}
                handleEmailTextfieldChange={(event) => {
                  let value = event.target.value?.trim();
                  if (value.length > 0) {
                    value = value[0].toLowerCase() + value.slice(1);
                  }
                  setEmail(value);
                }}
                setAlertModalTitle={setAlertModalTitle}
                setAlertModalBody={setAlertModalBody}
                onLogIn={props.onLogIn}
                setActiveForm={setActiveForm}
                firstName={firstName}
                lastName={lastName}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setEmail={setEmail}
                setIsEmailPrivate={setIsEmailPrivate}
                emailFlag={emailFlag}
                setEmailFlag={setEmailFlag}
                onBackClick={() => setActiveForm("login")}
                goToVerifyForm={() => setActiveForm("verify-email")}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100vh",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center",
                }}>
                <img src={brokenLink} height={"80px"} width={"80px"} />
                <h2>
                  Oops...!
                  <br />
                  This referral link is invalid
                </h2>
              </div>
            )}
          </>
        );
      case "verify-email":
        return (
          <VerifyEmailForm
            email={email}
            handleEmailTextfieldChange={(event) => setEmail(event.target.value)}
            setAlertModalTitle={setAlertModalTitle}
            setAlertModalBody={setAlertModalBody}
            onBackClick={() => setActiveForm("create-account")}
            goToLoginForm={() => setActiveForm("DownloadApk")}
          />
        );
      case "DownloadApk":
        return <DownloadApkForm />;
      case "ProfilePage":
        return (
          <ProfilePage
            email={email}
            firstName={firstName}
            lastName={lastName}
            setFirstName={setFirstName}
            setLastName={setLastName}
            handleEmailTextfieldChange={(event) => {
              // setIsEmailPrivate(false);
              let value = event.target.value?.trim();
              if (value.length > 0) {
                value = value[0].toLowerCase() + value.slice(1);
              }
              setEmail(value);
            }}
            setEmail={setEmail}
            isEmailPrivate={isEmailPrivate}
            emailFlag={emailFlag}
            setEmailFlag={setEmailFlag}
            onNextClick={() => setActiveForm("TradeAndCompanyPage")}
          />
        );
      case "TradeAndCompanyPage":
        return (
          <TradeAndCompanyPage
            onNextClick={() => setActiveForm("ExperiencePage")}
          />
        );
      case "ExperiencePage":
        return (
          <ExperiencePage onNextClick={() => setActiveForm("FinishPage")} />
        );

      case "FinishPage":
        return <FinishPage />;

      default:
        return <></>;
    }
  }

  return (
    <div className="login" style={{ justifyContent: "center" }}>
      <AlertModal
        isOpen={alertModalTitle !== null || alertModalBody !== null}
        onClose={() => {
          setAlertModalTitle(null);
          setAlertModalBody(null);
        }}
        title={alertModalTitle}
        body={alertModalBody}
      />
      {getActiveForm()}
    </div>
  );
}

export default ReferralSignUp;

function LoginForm(props) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isIncorrectPassword, setIsIncorrectPassword] = useState(false);

  const hash = (str) => {
    return sha256(str);
  };

  async function handleLoginClick(event) {
    event.preventDefault();

    try {
      if (props.email === "") {
        setIsEmptyEmail(true);
        setIsValid(false);
        if (password === "") {
          setIsEmptyPassword(true);
          return false;
        } else setIsEmptyPassword(false);
        return false;
      } else {
        setIsEmptyEmail(false);
        const email = props.email;
        if (emailRegexp.test(email)) {
          setIsValid(false);
          setIsEmptyEmail(false);
        } else {
          setIsValid(true);
          if (password === "") {
            setIsEmptyPassword(true);
            return false;
          } else setIsEmptyPassword(false);
          return false;
        }
      }

      if (password === "") {
        setIsEmptyPassword(true);
        return false;
      } else setIsEmptyPassword(false);

      const pwd = await hash(password);
      const loginObj = {
        User: props.email,
        Password: pwd,
      };
      let loginResult = await userLogin(loginObj);

      await localStorage.setItem(
        "token",
        loginResult?.data?.accessToken ?? null
      );

      if (loginResult?.data?.accessToken) {
        let loginData = {
          Email: props.email,
          Password: pwd,
        };

        let res = await signIn(loginData);

        if (res.message === "OK" || res.message === "PROFILE") {
          if (res.data?.newUser) {
            const newPersonalInfo = new FormData();
            newPersonalInfo.append("Id", 0);
            newPersonalInfo.append("UserId", res.data.userId);
            newPersonalInfo.append("FirstName", firstName);
            newPersonalInfo.append("UserEmail", props?.email);
            newPersonalInfo.append("LastName", lastName);
            newPersonalInfo.append("RideShareInterested", false);
            newPersonalInfo.append("BackupEmail", "");
            newPersonalInfo.append("NickName", "");
            newPersonalInfo.append("EmploymentStatusId", 0);
            newPersonalInfo.append("ModifiedBy", res.data.userId);
            newPersonalInfo.append("Birthday", "");
            newPersonalInfo.append("PictureUrl", "");
            console.log(
              "Populating new account with blank info...",
              JSON.stringify(newPersonalInfo),
              props?.email
            );
            await savePersonalInfo(newPersonalInfo);

            let newUserAddress = {
              Id: 0,
              UserId: res.data.userId,
              Address1: "",
              Address2: "",
              City: "",
              Province: "",
              PostalCode: "",
              Country: "",
              ModifiedBy: res.data.userId,
            };

            console.log("Populating new account with blank info...");

            await Promise.all([
              savePersonalInfo(newPersonalInfo),
              saveUserAddress(newUserAddress),
            ]);
          }

          // props.onLogIn(res.data, props.email);

          props.history.push("/dashboard");
          localStorage.setItem("userId", res.data?.userId);
          localStorage.setItem("token", loginResult.data?.accessToken);
        } // Redirect on login
      } else setIsIncorrectPassword(true);

      // try{
      const obj = {
        SearchList: [
          { userId: Number(localStorage.userId), tradeType: "Main" },
        ],
      };

      let header = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      fetchUserTrades(obj, header).then((resTrade) => {
        localStorage.setItem("tradeName", resTrade[0].tradeName || null);
      });
    } catch (error) {
      setIsIncorrectPassword(true);
    }
  }

  useEffect(() => {
    setIsEmptyEmail(false);
    setIsEmptyPassword(false);
    firstName = localStorage.getItem("firstName");
    lastName = localStorage.getItem("lastName");
  }, []);

  return (
    <div>
      <AlertModal
        isOpen={isIncorrectPassword}
        onClose={() => setIsIncorrectPassword(false)}
        title={"Login Error"}
        body={"Invalid email and/or password."}
      />

      <div className="login-form-content">
        <h3>Login</h3>
        <form>
          <div className="input-container">
            <TextField
              variant="outlined"
              label="Email"
              fullWidth
              // defaultValue={props.email}
              onChange={props.handleEmailTextfieldChange}
              error={isEmptyEmail || isValid}
              helperText={
                isEmptyEmail
                  ? "This field is required."
                  : isValid
                    ? "Please enter a valid email address"
                    : ""
              }
            />
          </div>

          <div className="input-container">
            <TextField
              id="password-textfield"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              label="Password"
              fullWidth
              // defaultValue={password}
              onChange={(event) => setPassword(event.target.value)}
              error={isEmptyPassword}
              helperText={isEmptyPassword ? "This field is required." : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex="-1" // Unfocusable button
                      onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <HideIcon /> : <ShowIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") handleLoginClick(event);
              }}
            />
          </div>
        </form>

        <div className="flex-container-horizontal">
          <div style={{ width: "50%" }}>
            <div className="input-container">
              <Button
                size="small"
                color="primary"
                onClick={props.onForgotClick}>
                Forgot Password
              </Button>
            </div>
          </div>
          <div style={{ width: "50%", float: "right", textAlign: "right" }}>
            <div className="input-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleLoginClick}>
                Login
              </Button>
            </div>
          </div>
        </div>

        <div className="divider">
          <p>
            <span>or</span>
          </p>
        </div>

        <div style={{ textAlign: "center" }}>
          <Button color="primary" onClick={props.onCreateClick}>
            Create New Account
          </Button>
        </div>
      </div>
    </div>
  );
}

function ForgotPasswordForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  async function handlePasswordRecoveryClick() {
    try {
      if (props.email === "") {
        setIsEmptyEmail(true);
        return false;
      } else {
        setIsEmptyEmail(false);

        const email = props.email;
        if (emailRegexp.test(email)) {
          setIsInvalidEmail(false);
          setIsEmptyEmail(false);
        } else {
          setIsInvalidEmail(true);
          return false;
        }
      }
      setIsLoading(true); // Show loading animation
      const res = await generateResetCode(props.email);

      if (res === true) {
        setEmailSent(true);
      } else {
        setIsInvalidEmail(true);
      }

      setIsLoading(false);
    } catch (error) {
      // TODO Catches undefined error when invalid email is submitted?
      setIsLoading(false); // Clear loading animation
    }
  }

  return (
    <div className="login-form-content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertModal
            isOpen={emailSent}
            onClose={() => {
              setEmailSent(false);
              props.onResetClick();
            }}
            title={"Reset Code"}
            body={
              "If there is an account associated with this email, you will receive a reset code."
            }
          />

          <h3>Forgot your password?</h3>
          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                // defaultValue={props.email}
                onChange={props.handleEmailTextfieldChange}
                error={isEmptyEmail || isInvalidEmail}
                helperText={
                  isEmptyEmail
                    ? "This field is required."
                    : isInvalidEmail
                      ? "Please enter a valid email address."
                      : ""
                }
              />
            </div>
          </form>

          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <Button
                  size="small"
                  color="primary"
                  onClick={props.onBackClick}>
                  Back
                </Button>
              </div>
            </div>

            <div className="flex-container-right">
              <div className="input-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePasswordRecoveryClick}>
                  Submit
                </Button>
              </div>
            </div>
          </div>

          <div className="input-container">
            <Button size="small" color="primary" onClick={props.onResetClick}>
              I have a reset code
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

function ResetPasswordForm(props) {
  const [isLoading, setIsLoading] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyResetCode, setIsEmptyResetCode] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [isEmptyRetypePassword, setIsEmptyRetypePassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alertModalOpen_ResetCodeError, setAlertModalOpen_ResetCodeError] =
    useState(false);
  const [alertModalOpen_EmailError, setAlertModalOpen_EmailError] =
    useState(false);
  const [alertModalOpen_PasswordChange, setAlertModalOpen_PasswordChange] =
    useState(false);
  const [passwordStrengthCount, setPasswordStrengthCount] = React.useState(0);

  const hash = (str) => {
    return sha256(str);
  };

  async function handleResetPasswordClick() {
    try {
      if (props.email === "") {
        setIsEmptyEmail(true);
        if (resetCode === "") {
          setIsEmptyResetCode(true);
          return false;
        } else setIsEmptyResetCode(false);
        return false;
      } else {
        setIsEmptyEmail(false);

        const email = props.email;
        if (emailRegexp.test(email)) {
          setIsValid(false);
          setIsEmptyEmail(false);
        } else {
          setIsValid(true);

          if (resetCode === "") {
            setIsEmptyResetCode(true);
            return false;
          } else setIsEmptyResetCode(false);

          return false;
        }
      }

      if (resetCode === "") {
        setIsEmptyResetCode(true);
        return false;
      } else setIsEmptyResetCode(false);

      if (password === "") setIsEmptyPassword(true);
      else setIsEmptyPassword(false);

      // if (retypePassword === "") setIsEmptyRetypePassword(true);
      // else setIsEmptyRetypePassword(false);

      // Check if passwords match
      // if (password !== retypePassword)
      //     throw new Error("Passwords do not match!");

      setIsLoading(true); // Show loading animation
      const resValid = await isValidResetCode(props.email, resetCode);

      await localStorage.setItem("token", resValid ?? null);

      if (resValid) {
        const pwd = await hash(password);

        const newPasswordData = {
          Email: props.email,
          Password: pwd,
        };

        // Email was already checked in isValidResetCode
        const passResult = await saveNewPassword(newPasswordData);
        if (passResult?.status) {
          setAlertModalOpen_PasswordChange(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false); // Clear loading animation
      if (error === "Verification code is invalid!")
        setAlertModalOpen_ResetCodeError(true);
      else if (error === "Invalid email !") setAlertModalOpen_EmailError(true);
    }
  }

  const getPasswordStrength = () => {
    let strength = "";
    if (passwordStrengthCount === 0) strength = "";
    else if (passwordStrengthCount === 1) strength = "Weak";
    else if (passwordStrengthCount > 1 && passwordStrengthCount < 4)
      strength = "Medium";
    else if (passwordStrengthCount === 4) strength = "Strong";
    return strength;
  };

  return (
    <div className="login-form-content">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertModal
            isOpen={alertModalOpen_ResetCodeError}
            onClose={() => setAlertModalOpen_ResetCodeError(false)}
            body="Invalid reset code."
          />

          <AlertModal
            isOpen={alertModalOpen_EmailError}
            onClose={() => setAlertModalOpen_EmailError(false)}
            body="No account is associated with that email address."
          />

          <AlertModal
            isOpen={alertModalOpen_PasswordChange}
            onClose={() => {
              setAlertModalOpen_PasswordChange(false);
              props.goToLoginForm();
            }}
            title="Success"
            body="Password successfully changed!"
          />

          <h3>Reset Password</h3>
          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                // defaultValue={props.email}
                onChange={props.handleEmailTextfieldChange}
                error={isEmptyEmail || isValid}
                helperText={
                  isEmptyEmail
                    ? "This field is required."
                    : isValid
                      ? "Please enter a valid email address."
                      : ""
                }
              />
            </div>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Reset Code"
                fullWidth
                // defaultValue={resetCode}
                onChange={(event) => setResetCode(event.target.value)}
                error={isEmptyResetCode}
                helperText={isEmptyResetCode ? "This field is required." : ""}
              />
            </div>
            <div className="input-container">
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                label="New Password"
                fullWidth
                // defaultValue={password}
                error={isEmptyPassword}
                helperText={isEmptyPassword ? "This field is required." : ""}
                onChange={(e) => {
                  let count = 0;
                  if (e.target.value.length >= 6) count += 1;
                  if (e.target.value.match(/(?=.*\d)/)) count += 1;
                  if (e.target.value.match(/(?=.*[A-Z])/)) count += 1;
                  if (e.target.value.match(/(?=.*[a-z])/)) count += 1;
                  setPassword(e.target.value);
                  setPasswordStrengthCount(count);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <HideIcon /> : <ShowIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="flex-container-horizontal">
              <div className="flex-container-left">
                <div className="input-container">
                  <span>Password strength</span>
                </div>
              </div>
              <div className="flex-container-right">
                <div className="input-container">
                  <span
                    className="right"
                    style={{
                      color:
                        passwordStrengthCount === 1
                          ? BIRed
                          : passwordStrengthCount > 1 &&
                            passwordStrengthCount < 4
                            ? BIYellow
                            : passwordStrengthCount === 4
                              ? BIGreen
                              : BIGrey,
                    }}>
                    {getPasswordStrength()}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "5px",
                borderRadius: "4px",
                backgroundColor: BIGrey,
              }}>
              <div
                style={{
                  borderRadius: "4px",
                  height: "5px",
                  width:
                    passwordStrengthCount === 1
                      ? "33%"
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? "66%"
                        : passwordStrengthCount === 4
                          ? "100%"
                          : 0,
                  backgroundColor:
                    passwordStrengthCount === 1
                      ? BIRed
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? BIYellow
                        : passwordStrengthCount === 4
                          ? BIGreen
                          : BIGrey,
                }}></div>
            </div>

            <div className="input-container">
              <p
                style={{
                  color:
                    password !== ""
                      ? password.length >= 6
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.length >= 6 ? (
                  <FaCheck
                    className="icon-position"
                    style={{ color: password.length >= 6 ? BIGreen : BIGrey }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 6 characters
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*\d)/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.match(/(?=.*\d)/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*\d)/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 number
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[A-Z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.match(/(?=.*[A-Z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[A-Z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 capital letter
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[a-z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.match(/(?=.*[a-z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[a-z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 lowercase letter
              </p>
            </div>

            {/* <div className="input-container">
                                    <TextField
                                        type={showRetypePassword ? "text" : "password"}
                                        variant="outlined"
                                        label="Retype New Password"
                                        fullWidth
                                        defaultValue={retypePassword}
                                        onChange={event => setRetypePassword(event.target.value)}
                                        error={isEmptyRetypePassword}
                                        helperText={
                                            isEmptyRetypePassword ? "This field is required." : ""
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        tabIndex="-1"	// Unfocusable button
                                                        onClick={() => setShowRetypePassword(!showRetypePassword)}>
                                                        {showRetypePassword ? <HideIcon /> : <ShowIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </div> */}

            {/* <div className="input-container">
                                    {retypePassword === '' || password === retypePassword ?
                                        <p style={{ color: password === retypePassword && retypePassword !== '' ? BIGreen : BIGrey }}>
                                            <FaCheck className="icon-position" style={{ color: password === retypePassword && retypePassword !== '' ? BIGreen : BIGrey }} /> Password match
                                        </p> :
                                        <p style={{ color: BIRed }}>
                                            <FaTimes className="icon-position" style={{ color: BIRed }} /> Password match
                                        </p>
                                    }
                                </div> */}
          </form>

          <div className="flex-container-horizontal">
            <div className="flex-container-left">
              <div className="input-container">
                <Button
                  size="small"
                  color="primary"
                  onClick={props.onBackClick}>
                  Back
                </Button>
              </div>
            </div>

            <div className="flex-container-right">
              <div className="input-container">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    passwordStrengthCount === 4 && password === retypePassword
                      ? false
                      : true
                  }
                  onClick={handleResetPasswordClick}>
                  Reset
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function CreateAccountForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [passwordStrengthCount, setPasswordStrengthCount] = React.useState(0);
  const [isValid, setIsValid] = useState(false);
  const [alertModalOpen_AccountExist, setAlertModalOpen_AccountExist] =
    useState(false);
  const [notifyToken, setNotifytoken] = useState("");
  const [passwordStrengthStatus, setPasswordStrengthStatus] = useState("");
  const [applePopupBlockFlag, setApplePopupBlockFlag] = useState(false);
  const [facebookPopupBlockFlag, setFacebookPopupBlockFlag] = useState(false);
  const [popUpAlertOpen, setPopUpAlertOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { pathname } = useLocation();
  let bowserRes = Bowser.getParser(window.navigator.userAgent);

  const hash = (str) => {
    return sha256(str);
  };

  const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      let getNotificationtoken = await getToken();
      return getNotificationtoken;
    }
  };

  const handleFacebookRedirectSignIn = async () => {
    const result = await getRedirectResult(auth);
    try {
      if (result) {
        try {
          // First time login fb
          const deviceId = await getDeviceId();
          setIsLoading(true);

          const fbAuthObj = {
            Email: result?.user?.email,
            NotifyToken: notifyToken ?? null,
            AppName: "BuilID-Web", //<--- Changes for Mobile development
            AppVersion: packageInfo?.version,
            Browser: bowserRes.parsedResult.browser?.name,
            BrowserVersion: bowserRes.parsedResult.browser?.version,
            Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
            PlatformVersion: "NA",
            DeviceName: "Browser",
            DeviceId: deviceId,
            IdentityToken: result._tokenResponse.oauthAccessToken,
            UserID: result?.user?.providerData?.[0]?.uid ?? 0,
            LoginWith: "Facebook",
          };

          const loginResult = await userLogin(fbAuthObj); //! re-rendering

          const { isFirstTimeLoggedIn, UserId, FirstName, LastName, email } =
            loginResult?.data?.data?.userInformation;
          if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
            // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
            // loginResult?.data?.data.userInformation.ClientsList?.forEach(
            //   (obj) => {
            //     if (obj.Licences) {
            //       obj.noOfLicense = obj.Licences.length;
            //     } else {
            //       obj.noOfLicense = 0;
            //     }
            //   }
            // );
            // let filteredData =
            //   loginResult?.data?.data.userInformation.ClientsList?.filter(
            //     (obj) => obj.noOfLicense !== 0
            //   );

            let filteredData =
              loginResult?.data?.data.userInformation.ClientsList?.filter(
                (obj) => obj.ClientName !== "BI Free"
              );

            if (filteredData.length > 0)
              await localStorage.setItem(
                "clientList",
                JSON.stringify(filteredData)
              );
          }

          await localStorage.setItem(
            "token",
            loginResult?.data?.data?.accessToken ?? null
          );
          await localStorage.setItem(
            "deviceId",
            loginResult?.data?.data?.deviceId ?? null
          );
          await localStorage.setItem("userId", UserId);

          if (loginResult?.data?.data?.accessToken) {
            localStorage.setItem("email", email || result._tokenResponse.email);
            props?.setEmail(email || result._tokenResponse.email || "");
            localStorage.setItem(
              "primaryEmail",
              email || result._tokenResponse.email
            );
            localStorage.setItem("firstName", FirstName || "");
            localStorage.setItem("lastName", LastName || "");
            props?.setFirstName(FirstName || "");
            props?.setLastName(LastName || "");
            localStorage.setItem("loginWith", "Facebook");
            localStorage.setItem("referPageFlag", "true");
            localStorage.setItem(
              "pictureUrl",
              loginResult?.data?.data?.pictureUrl
            );
            // localStorage.setItem("IsPrivateEmail", false);
            // props?.setIsEmailPrivate(true);
            props?.setEmailFlag(true);
            const tutorial = {
              dashboard: "true",
              sites: "true",
              montages: "true",
              ticket: "true",
              jobPostings: "true",
              carpool: "true",
              resumeBuilder: "true",
              contentSearch: "true",
            };
            localStorage.setItem("tutorial", JSON.stringify(tutorial));

            if (loginResult.data?.message === "OK") {
              if (isFirstTimeLoggedIn) {
                localStorage.setItem("newUser", "true");
                props?.setActiveForm("ProfilePage");
                return;
              } else {
                if (/android/i.test(userAgent)) {
                  const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
                  window.location.href = url;
                } else if (
                  /iPad|iPhone|iPod/.test(userAgent) &&
                  !window.MSStream
                ) {
                  const url = "mybuilderid://"; // Custom URL scheme for your app
                  const appStoreURL =
                    "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

                  // Start timer
                  const startTime = Date.now();
                  let didOpenApp = false;

                  // Attempt to open the app
                  window.location.href = url;

                  // Check if the app opened successfully
                  const checkAppOpened = setTimeout(() => {
                    const endTime = Date.now();

                    // If the time difference is small, it means the app did not open
                    if (!didOpenApp && endTime - startTime < 2000) {
                      window.location.href = appStoreURL;
                    }
                  }, 1500);

                  // Listen for visibility change
                  document.addEventListener("visibilitychange", () => {
                    if (document.visibilityState === "hidden") {
                      didOpenApp = true;
                      clearTimeout(checkAppOpened);
                    }
                  });
                } else {
                  window.location.replace("/dashboard");
                }
              }
              if (props?.screenName) {
                switch (props?.screenName) {
                  case "TradeDetail":
                    window.location.href = "trade/" + props?.itemId;
                    break;
                  case "ProdcutInfo":
                    window.location.href = "product/" + props?.itemId;
                    break;
                  case "CompanyInfo":
                    window.location.href = "company/" + props?.itemId;
                    break;
                  case "SiteDetail":
                    window.location.href = "sites/site/" + props?.itemId;
                    break;
                  case "ManufacturerInfo":
                    window.location.href = "manufacturer/" + props?.itemId;
                    break;
                  default:
                    // window.location.href = "/dashboard";
                    window.location.replace("/dashboard");
                }
              } else {
                if (pathname === "/")
                  // props.history.push("/dashboard");
                  window.location.replace("/dashboard");
              }
            }
          } else {
            console.log("LOGIN RESPONSE IS NOT OK!");
          }
        } catch (err) {
          console.log("Error faceboo : ", err);
        }
      }
    } catch (err) {
      if (err.code === "auth/account-exists-with-different-credential") {
        // Get the pending credential (Facebook credential)
        const deviceId = await getDeviceId();
        const result = err.customData;
        console.log("result", result);
        const userId = JSON.parse(err.customData._tokenResponse.rawUserInfo);
        console.log("err.customData--->", userId);
        const fbAuthObj = {
          Email: "",
          NotifyToken: notifyToken ?? null,
          AppName: "BuilID-Web", //<--- Changes for Mobile development
          AppVersion: packageInfo?.version,
          Browser: bowserRes.parsedResult.browser?.name,
          BrowserVersion: bowserRes.parsedResult.browser?.version,
          Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
          PlatformVersion: "NA",
          DeviceName: "Browser",
          DeviceId: deviceId,
          IdentityToken: result._tokenResponse.oauthAccessToken,
          UserID: userId.id ?? 0,
          LoginWith: "Facebook",
        };
        const loginResult = await userLogin(fbAuthObj);
        console.log("loginResult--->", loginResult, fbAuthObj);
        const { isFirstTimeLoggedIn, UserId, FirstName, LastName, email } =
          loginResult?.data?.data?.userInformation;
        if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
          // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
          // loginResult?.data?.data.userInformation.ClientsList?.forEach(
          //   (obj) => {
          //     if (obj.Licences) {
          //       obj.noOfLicense = obj.Licences.length;
          //     } else {
          //       obj.noOfLicense = 0;
          //     }
          //   }
          // );
          // let filteredData =
          //   loginResult?.data?.data.userInformation.ClientsList?.filter(
          //     (obj) => obj.noOfLicense !== 0
          //   );

          let filteredData =
            loginResult?.data?.data.userInformation.ClientsList?.filter(
              (obj) => obj.ClientName !== "BI Free"
            );

          if (filteredData.length > 0)
            await localStorage.setItem(
              "clientList",
              JSON.stringify(filteredData)
            );
        }
        await localStorage.setItem(
          "token",
          loginResult?.data?.data?.accessToken ?? null
        );
        await localStorage.setItem(
          "deviceId",
          loginResult?.data?.data?.deviceId ?? null
        );
        await localStorage.setItem("userId", UserId);

        if (loginResult?.data?.data?.accessToken) {
          localStorage.setItem("email", email || result._tokenResponse.email);
          props?.setEmail(email || result._tokenResponse.email || "");
          localStorage.setItem(
            "primaryEmail",
            email || result._tokenResponse.email
          );
          localStorage.setItem("firstName", FirstName || "");
          localStorage.setItem("lastName", LastName || "");
          props?.setFirstName(FirstName || "");
          props?.setLastName(LastName || "");
          localStorage.setItem("loginWith", "Facebook");
          localStorage.setItem(
            "pictureUrl",
            loginResult?.data?.data?.pictureUrl
          );
          // localStorage.setItem("IsPrivateEmail", false);
          // props?.setIsEmailPrivate(true);
          props?.setEmailFlag(true);
          const tutorial = {
            dashboard: "true",
            sites: "true",
            montages: "true",
            ticket: "true",
            jobPostings: "true",
            carpool: "true",
            resumeBuilder: "true",
            contentSearch: "true",
          };
          localStorage.setItem("tutorial", JSON.stringify(tutorial));

          if (loginResult.data?.message === "OK") {
            if (isFirstTimeLoggedIn) {
              localStorage.setItem("newUser", "true");
              props?.setActiveForm("ProfilePage");
              return;
            } else {
              // window.location.href = "/dashboard"
              if (/android/i.test(userAgent)) {
                const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
                window.location.href = url;
              } else if (
                /iPad|iPhone|iPod/.test(userAgent) &&
                !window.MSStream
              ) {
                const url = "mybuilderid://"; // Custom URL scheme for your app
                const appStoreURL =
                  "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

                // Start timer
                const startTime = Date.now();
                let didOpenApp = false;

                // Attempt to open the app
                window.location.href = url;

                // Check if the app opened successfully
                const checkAppOpened = setTimeout(() => {
                  const endTime = Date.now();

                  // If the time difference is small, it means the app did not open
                  if (!didOpenApp && endTime - startTime < 2000) {
                    window.location.href = appStoreURL;
                  }
                }, 1500);

                // Listen for visibility change
                document.addEventListener("visibilitychange", () => {
                  if (document.visibilityState === "hidden") {
                    didOpenApp = true;
                    clearTimeout(checkAppOpened);
                  }
                });
              } else {
                window.location.replace("/dashboard");
              }
            }
            if (props?.screenName) {
              switch (props?.screenName) {
                case "TradeDetail":
                  window.location.href = "trade/" + props?.itemId;
                  break;
                case "ProdcutInfo":
                  window.location.href = "product/" + props?.itemId;
                  break;
                case "CompanyInfo":
                  window.location.href = "company/" + props?.itemId;
                  break;
                case "SiteDetail":
                  window.location.href = "sites/site/" + props?.itemId;
                  break;
                case "ManufacturerInfo":
                  window.location.href = "manufacturer/" + props?.itemId;
                  break;
                default:
                  // window.location.href = "/dashboard";
                  window.location.replace("/dashboard");
              }
            } else {
              if (pathname === "/")
                // props.history.push("/dashboard");
                window.location.replace("/dashboard");
            }
          }
        } else {
          console.log("LOGIN RESPONSE IS NOT OK!");
        }
      }
    }
  };

  const handleAppleRedirectSignIn = async () => {
    alert("try");
    try {
      alert("handleAppleRedirectSignIn");
      const result = await getRedirectResult(auth);
      if (result) {
        alert("result");
        const deviceId = await getDeviceId();
        setIsLoading(true);

        const appleAuthObj = {
          Email: result?.user?.email,
          Password: "",
          NotifyToken: notifyToken ?? null,
          AppName: "BuilID-Web", //<--- Changes for Mobile development
          AppVersion: packageInfo?.version,
          Browser: bowserRes.parsedResult.browser?.name,
          BrowserVersion: bowserRes.parsedResult.browser?.version,
          // MobileDeviceModel: DeviceInfo.getModel(),
          Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
          PlatformVersion: "NA",
          // IsDeviceNotify: "true",
          DeviceName: "Browser",
          DeviceId: deviceId,
          IdentityToken: result._tokenResponse.oauthIdToken,
          UserID: result?.user?.providerData?.[0]?.uid ?? 0,
          LoginWith: "Apple",
          // FirstName: "Apple",
          // LastName: "Users"
        };

        const loginResult = await userLogin(appleAuthObj); //! re-rendering

        const {
          isFirstTimeLoggedIn,
          UserId,
          FirstName,
          LastName,
          Email,
          IsPrivateEmail,
          DeviceId,
        } = loginResult?.data?.data?.userInformation;
        if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
          // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
          // loginResult?.data?.data.userInformation.ClientsList?.forEach(
          //   (obj) => {
          //     if (obj.Licences) {
          //       obj.noOfLicense = obj.Licences.length;
          //     } else {
          //       obj.noOfLicense = 0;
          //     }
          //   }
          // );
          // let filteredData =
          //   loginResult?.data?.data.userInformation.ClientsList?.filter(
          //     (obj) => obj.noOfLicense !== 0
          //   );
          let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.ClientName !== "BI Free"
        );
          if (filteredData.length > 0)
            await localStorage.setItem(
              "clientList",
              JSON.stringify(filteredData)
            );
        }
        await localStorage.setItem(
          "token",
          loginResult?.data?.data?.accessToken ?? null
        );
        await localStorage.setItem("deviceId", DeviceId ?? null);
        await localStorage.setItem("userId", UserId);

        if (loginResult?.data?.data?.accessToken) {
          localStorage.setItem("email", result?.user?.email || "");
          if (!IsPrivateEmail) {
            props?.setEmail(result?.user?.email || "");
          }
          localStorage.setItem("primaryEmail", result?.user?.email);
          localStorage.setItem("firstName", FirstName || "");
          localStorage.setItem("lastName", LastName || "");
          props?.setFirstName(FirstName || "");
          props?.setLastName(LastName || "");
          localStorage.setItem("loginWith", "Apple");
          localStorage.setItem("referPageFlag", "true");
          // localStorage.setItem("IsPrivateEmail", IsPrivateEmail);
          props?.setIsEmailPrivate(IsPrivateEmail);
          localStorage.setItem(
            "pictureUrl",
            loginResult?.data?.data?.pictureUrl
          );

          const tutorial = {
            dashboard: "true",
            sites: "true",
            montages: "true",
            ticket: "true",
            jobPostings: "true",
            carpool: "true",
            resumeBuilder: "true",
            contentSearch: "true",
          };
          localStorage.setItem("tutorial", JSON.stringify(tutorial));

          if (loginResult.data?.message === "OK") {
            if (isFirstTimeLoggedIn) {
              localStorage.setItem("newUser", "true");
              props?.setActiveForm("ProfilePage");
            } else {
              // window.location.href = "/dashboard";
              if (/android/i.test(userAgent)) {
                const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
                window.location.href = url;
              } else if (
                /iPad|iPhone|iPod/.test(userAgent) &&
                !window.MSStream
              ) {
                const url = "mybuilderid://"; // Custom URL scheme for your app
                const appStoreURL =
                  "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

                // Start timer
                const startTime = Date.now();
                let didOpenApp = false;

                // Attempt to open the app
                window.location.href = url;

                // Check if the app opened successfully
                const checkAppOpened = setTimeout(() => {
                  const endTime = Date.now();

                  // If the time difference is small, it means the app did not open
                  if (!didOpenApp && endTime - startTime < 2000) {
                    window.location.href = appStoreURL;
                  }
                }, 1500);

                // Listen for visibility change
                document.addEventListener("visibilitychange", () => {
                  if (document.visibilityState === "hidden") {
                    didOpenApp = true;
                    clearTimeout(checkAppOpened);
                  }
                });
              }
            }
            if (props?.screenName) {
              switch (props?.screenName) {
                case "TradeDetail":
                  window.location.href = "trade/" + props?.itemId;
                  break;
                case "ProdcutInfo":
                  window.location.href = "product/" + props?.itemId;
                  break;
                case "CompanyInfo":
                  window.location.href = "company/" + props?.itemId;
                  break;
                case "SiteDetail":
                  window.location.href = "sites/site/" + props?.itemId;
                  break;
                case "ManufacturerInfo":
                  window.location.href = "manufacturer/" + props?.itemId;
                  break;
                default:
                  // window.location.href = "/dashboard";
                  window.location.replace("/dashboard");
              }
            }
          }
        } else {
          console.log("LOGIN RESPONSE IS NOT OK!");
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error getting redirect result:", error);
      // Handle errors related to redirect sign-in
    }
  };

  useEffect(async () => {
    const tok = await requestNotificationPermission();
    setNotifytoken(tok);
  }, []);

  // useEffect(() => {
  //   if (applePopupBlockFlag) {
  //     handleAppleRedirectSignIn();
  //   }
  // }, [applePopupBlockFlag])

  // useEffect(() => {
  //   handleFacebookRedirectSignIn();
  // }, [facebookPopupBlockFlag])

  async function getDeviceId() {
    // const fp = await FingerprintJS.load();
    // const { visitorId } = await fp.get();
    // return visitorId;
    const visitorId = await getCurrentBrowserFingerPrint();
    return visitorId;
  }

  async function handleSignUpClick() {
    try {
      setIsLoading(true);

      if (props.email === "") {
        setIsValid(false);
      } else {
        const email = props.email;
        if (emailRegexp.test(email)) {
          setIsValid(false);
        } else {
          setIsValid(true);
          setIsLoading(false);
          return false;
        }
      }

      // API create a new account
      const pass = await hash(password);

      let newUserId = -1;
      let newAccountData = {
        UserEmail: props.email,
        Password: pass,
        ReferredBy: pathname.includes("/refer") ? String(props?.referId) : "",
        ReferredSource: pathname.includes("/refer") ? "BI_user" : "",
      };
      await createAccount(newAccountData).then((res) => {
        newUserId = res?.id;
        localStorage?.setItem("userId", res?.id);
      });

      if (newUserId > 0) {
        try {
          const tutorial = {
            dashboard: "true",
            sites: "true",
            montages: "true",
            ticket: "true",
            jobPostings: "true",
            carpool: "true",
            resumeBuilder: "true",
            contentSearch: "true",
          };
          localStorage.setItem("tutorial", JSON.stringify(tutorial));

          const loginObj = {
            Email: props.email,
            Password: pass,
          };
          let loginResult = await userLogin(loginObj);
          await localStorage.setItem(
            "token",
            loginResult?.data?.data?.accessToken ?? null
          );
          localStorage.setItem("email", props?.email || "");
          localStorage.setItem("primaryEmail", props?.email || "");
          localStorage.setItem("loginWith", "Email");
          localStorage.setItem("referPageFlag", "true");
          props?.setEmailFlag(true);
          props?.setActiveForm("ProfilePage");
          localStorage.setItem("newUser", "true");
        } catch (error) {
          console.log("LOGINERROR : ", error);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setPassword("");
      setPasswordStrengthCount(0);
      console.log("Create Account Error : ", error);
      if (error.response.status == 406) {
        setErrorMsg(error.response.data.errorMessage);
        setPassword("");
        setPasswordStrengthCount(0);
        setIsLoading(false);
      } else {
        setAlertModalOpen_AccountExist(true);
      }
      // await handleLoginClick();
    }
  }

  async function handleLoginClick(event) {
    try {
      // if (props.email === "") {
      //   setIsEmptyEmail(true);
      //   setIsValid(false);
      //   if (password === "") {
      //     setIsEmptyPassword(true);
      //     return false;
      //   } else setIsEmptyPassword(false);
      //   return false;
      // } else {
      //   setIsEmptyEmail(false);
      //   const email = props.email;
      //   if (isValidEmail(email)) {
      //     setIsValid(false);
      //     setIsEmptyEmail(false);
      //   } else {
      //     setIsValid(true);
      //     if (password === "") {
      //       setIsEmptyPassword(true);
      //       return false;
      //     } else setIsEmptyPassword(false);
      //     return false;
      //   }
      // }

      // if (password === "") {
      //   setIsEmptyPassword(true);
      //   return false;
      // } else setIsEmptyPassword(false);

      const pwd = await hash(password);

      const deviceId = await getDeviceId();

      const loginObj = {
        DeviceId: deviceId,
        Email: props?.email,
        Password: pwd,
        AppName: "BuilID-Web",
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name,
        PlatformVersion: "NA",
        DeviceName: "Browser",
        NotifyToken: notifyToken ?? null,
        LoginWith: "Password",
      };

      let loginResult = await userLogin(loginObj);
      const {
        isFirstTimeLoggedIn,
        UserId,
        DeviceId,
        FirstName,
        LastName,
        Email,
      } = loginResult?.data?.data?.userInformation;

      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );

      localStorage?.setItem("loginWith", "Email");
      await localStorage.setItem("deviceId", DeviceId ?? null);
      await localStorage.setItem("firstName", FirstName ?? null);
      await localStorage.setItem("lastName", LastName ?? null);
      await localStorage.setItem("email", Email ?? null);
      await localStorage.setItem("primaryEmail", Email ?? null);
      localStorage.setItem(
        "pictureUrl",
        loginResult?.data?.data?.pictureUrl || ""
      );
      await localStorage.setItem("userId", UserId);

      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
        // loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
        //   if (obj.Licences) {
        //     obj.noOfLicense = obj.Licences.length;
        //   } else {
        //     obj.noOfLicense = 0;
        //   }
        // });
        // let filteredData =
        //   loginResult?.data?.data.userInformation.ClientsList?.filter(
        //     (obj) => obj.noOfLicense !== 0
        //   );
        let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.ClientName !== "BI Free"
        );
        if (filteredData.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );
      }
      if (loginResult?.data?.data?.accessToken) {
        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        const obj = {
          SearchList: [{ userId: Number(UserId), tradeType: "Main" }],
        };

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        fetchUserTrades(obj, header).then((resTrade) => {
          localStorage.setItem("tradeName", resTrade[0]?.tradeName || null);
          localStorage.setItem("tradeId", resTrade[0]?.tradeId || null);
        });

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");

            // console.log("Populating new account with blank info...");
          } else {
            window.location.pathname = "/dashboard";
          }

          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                window.location.href = "/dashboard";
            }
          } else {
            if (pathname === "/") props.history.push("/dashboard");
          }
        } // Redirect on login
      } else {
        setPassword("");
        return;
      }

      // try{
    } catch (error) {
      console.log("ERROR:::", error);
      setPassword("");
    }
  }

  useEffect(() => {
    getPasswordStrength();
  }, [passwordStrengthCount]);

  const getPasswordStrength = (props) => {
    let strength = "";
    if (passwordStrengthCount === 0) strength = "";
    else if (passwordStrengthCount === 1) strength = "Weak";
    else if (passwordStrengthCount > 1 && passwordStrengthCount < 4)
      strength = "Medium";
    else if (passwordStrengthCount === 4) strength = "Strong";
    setPasswordStrengthStatus(strength);
    // return strength;
  };

  const googleAuthHandler = async () => {
    const auth = getAuth(firebase_app);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setIsLoading(true);
      const deviceId = await getDeviceId();

      const googleLoginObj = {
        DeviceId: deviceId, // Send whenever available at ur end
        Email: result._tokenResponse?.email,
        AppName: "BuilID-Web",
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name, // ios/android/windows/macos
        PlatformVersion: "NA",
        DeviceName: "Browser",
        NotifyToken: notifyToken ?? null,
        LoginWith: "Google", // Facebook,Google,Password,
        IdentityToken: result._tokenResponse.oauthIdToken, // Google , Facebook Tokens
      };

      const uName = result?._tokenResponse.displayName.split(" ");

      let loginResult = await userLogin(googleLoginObj);
      const { DeviceId, UserId, isFirstTimeLoggedIn } =
        loginResult?.data?.data?.userInformation;
      loginResult.data.data.userInformation.FirstName = String(uName[0]) || "";
      loginResult.data.data.userInformation.LastName =
        String(uName[uName.length - 1]) || "";
      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
        // loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
        //   if (obj.Licences) {
        //     obj.noOfLicense = obj.Licences.length;
        //   } else {
        //     obj.noOfLicense = 0;
        //   }
        // });
        // let filteredData =
        //   loginResult?.data?.data.userInformation.ClientsList?.filter(
        //     (obj) => obj.noOfLicense !== 0
        //   );
        let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.ClientName !== "BI Free"
        );
        if (filteredData.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );
      }

      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );
      await localStorage.setItem("deviceId", DeviceId ?? null);
      await localStorage.setItem("userId", UserId);

      if (loginResult?.data.data.accessToken) {
        localStorage.setItem("email", result._tokenResponse.email || "");
        props?.setEmail(result._tokenResponse.email || "");
        localStorage.setItem("primaryEmail", result._tokenResponse.email || "");
        localStorage.setItem(
          "firstName",
          result._tokenResponse.firstName || ""
        );
        localStorage.setItem("lastName", result._tokenResponse.lastName || "");
        props?.setFirstName(result._tokenResponse.firstName || "");
        props?.setLastName(result._tokenResponse.lastName || "");
        localStorage.setItem("pictureUrl", loginResult?.data?.data?.pictureUrl);
        localStorage.setItem("loginWith", "Google");
        localStorage.setItem("referPageFlag", "true");
        // localStorage.setItem("IsPrivateEmail", false);
        // props?.setIsEmailPrivate(true);
        props?.setEmailFlag(true);

        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        const obj = {
          SearchList: [
            {
              userId: Number(UserId),
              tradeType: "Main",
            },
          ],
        };

        let header = {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        };

        fetchUserTrades(obj, header).then((resTrade) => {
          localStorage.setItem("tradeName", resTrade[0]?.tradeName || null);
          localStorage.setItem("tradeId", resTrade[0]?.tradeId || null);
        });

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            props?.setActiveForm("ProfilePage");
            return;
          } else {
            // window.location.href = "/dashboard";
            if (/android/i.test(userAgent)) {
              const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
              window.location.href = url;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              const url = "mybuilderid://"; // Custom URL scheme for your app
              const appStoreURL =
                "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

              // Start timer
              const startTime = Date.now();
              let didOpenApp = false;

              // Attempt to open the app
              window.location.href = url;

              // Check if the app opened successfully
              const checkAppOpened = setTimeout(() => {
                const endTime = Date.now();

                // If the time difference is small, it means the app did not open
                if (!didOpenApp && endTime - startTime < 2000) {
                  window.location.href = appStoreURL;
                }
              }, 1500);

              // Listen for visibility change
              document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === "hidden") {
                  didOpenApp = true;
                  clearTimeout(checkAppOpened);
                }
              });
            } else {
              window.location.replace("/dashboard");
            }
          }

          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                // window.location.href = "/dashboard";
                window.location.replace("/dashboard");
            }
          } else {
            if (pathname === "/")
              // props.history.push("/dashboard");
              window.location.replace("/dashboard");
          }
        }
      } else {
        console.log("LOGIN RESPONSE IS NOT OK!");
      }
    } catch (err) {
      console.log("GOOGLE AUTH ERROR IS >>>", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fbAuthHandler = async () => {
    const auth = getAuth(firebase_app);
    console.log("auth", auth);
    const provider = new FacebookAuthProvider();
    console.log("provider", provider);
    const deviceId = await getDeviceId();
    try {
      provider.setCustomParameters({ display: "popup" });
      const result = await signInWithPopup(auth, provider);
      console.log("try result : ", result);

      const user = result.user;
      const jwtToken = await user.getIdToken();
      console.log("jwtToken try ", jwtToken);

      // First time login fb

      const fbToken = result._tokenResponse.oauthAccessToken;
      const fbEmail = result._tokenResponse.email;
      setIsLoading(true);

      const fbAuthObj = {
        Email: fbEmail || result?.user?.email,
        NotifyToken: notifyToken ?? null,
        AppName: "BuilID-Web", //<--- Changes for Mobile development
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
        PlatformVersion: "NA",
        DeviceName: "Browser",
        DeviceId: deviceId,
        IdentityToken: fbToken,
        UserID: result?.user?.providerData?.[0]?.uid ?? 0,
        LoginWith: "Facebook",
      };

      const loginResult = await userLogin(fbAuthObj); //! re-rendering
      const { isFirstTimeLoggedIn, UserId, FirstName, LastName, email } =
        loginResult?.data?.data?.userInformation;
      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
        // loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
        //   if (obj.Licences) {
        //     obj.noOfLicense = obj.Licences.length;
        //   } else {
        //     obj.noOfLicense = 0;
        //   }
        // });
        // let filteredData =
        //   loginResult?.data?.data.userInformation.ClientsList?.filter(
        //     (obj) => obj.noOfLicense !== 0
        //   );
        let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.ClientName !== "BI Free"
        );
        if (filteredData.length > 0)
          await localStorage.setItem(
            "clientList",
            JSON.stringify(filteredData)
          );
      }

      await localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );
      await localStorage.setItem(
        "deviceId",
        loginResult?.data?.data?.deviceId ?? null
      );
      await localStorage.setItem("userId", UserId);

      if (loginResult?.data?.data?.accessToken) {
        localStorage.setItem("email", email || result._tokenResponse.email);
        props?.setEmail(email || result._tokenResponse.email || "");
        localStorage.setItem(
          "primaryEmail",
          email || result._tokenResponse.email
        );
        localStorage.setItem("firstName", FirstName || "");
        localStorage.setItem("lastName", LastName || "");
        props?.setFirstName(FirstName || "");
        props?.setLastName(LastName || "");
        localStorage.setItem("loginWith", "Facebook");
        localStorage.setItem("referPageFlag", "true");
        localStorage.setItem("pictureUrl", loginResult?.data?.data?.pictureUrl);
        // localStorage.setItem("IsPrivateEmail", false);
        // props?.setIsEmailPrivate(true);
        props?.setEmailFlag(true);
        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            props?.setActiveForm("ProfilePage");
            return;
          } else {
            if (/android/i.test(userAgent)) {
              const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
              window.location.href = url;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              const url = "mybuilderid://"; // Custom URL scheme for your app
              const appStoreURL =
                "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

              // Start timer
              const startTime = Date.now();
              let didOpenApp = false;

              // Attempt to open the app
              window.location.href = url;

              // Check if the app opened successfully
              const checkAppOpened = setTimeout(() => {
                const endTime = Date.now();

                // If the time difference is small, it means the app did not open
                if (!didOpenApp && endTime - startTime < 2000) {
                  window.location.href = appStoreURL;
                }
              }, 1500);

              // Listen for visibility change
              document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === "hidden") {
                  didOpenApp = true;
                  clearTimeout(checkAppOpened);
                }
              });
            } else {
              window.location.replace("/dashboard");
            }
          }
          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                // window.location.href = "/dashboard";
                window.location.replace("/dashboard");
            }
          } else {
            if (pathname === "/")
              // props.history.push("/dashboard");
              window.location.replace("/dashboard");
          }
        }
      } else {
        console.log("LOGIN RESPONSE IS NOT OK!");
      }
    } catch (err) {
      console.log("Error Facebook : ", err);

      if (err.code === "auth/account-exists-with-different-credential") {
        // Get the pending credential (Facebook credential)
        const result = err.customData;
        console.log("result", result);
        const user = result.customData.user;
        const jwtToken = await user.getIdToken();
        const fbToken = result._tokenResponse.oauthAccessToken;
        const userId = JSON.parse(err.customData._tokenResponse.rawUserInfo);
        console.log("err.customData--->", userId);
        const fbAuthObj = {
          Email: result._tokenResponse.email || result?.user?.email,
          NotifyToken: notifyToken ?? null,
          AppName: "BuilID-Web", //<--- Changes for Mobile development
          AppVersion: packageInfo?.version,
          Browser: bowserRes.parsedResult.browser?.name,
          BrowserVersion: bowserRes.parsedResult.browser?.version,
          Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
          PlatformVersion: "NA",
          DeviceName: "Browser",
          DeviceId: deviceId,
          IdentityToken: fbToken,
          UserID: userId.id ?? 0,
          LoginWith: "Facebook",
        };

        const loginResult = await userLogin(fbAuthObj);
        console.log("loginResult--->", loginResult, fbAuthObj);
        const jwtT = await result.user.getIdToken();
        console.log("jwtT", jwtT);

        const { isFirstTimeLoggedIn, UserId, FirstName, LastName, email } =
          loginResult?.data?.data?.userInformation;
        if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
          // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
          // loginResult?.data?.data.userInformation.ClientsList?.forEach(
          //   (obj) => {
          //     if (obj.Licences) {
          //       obj.noOfLicense = obj.Licences.length;
          //     } else {
          //       obj.noOfLicense = 0;
          //     }
          //   }
          // );
          // let filteredData =
          //   loginResult?.data?.data.userInformation.ClientsList?.filter(
          //     (obj) => obj.noOfLicense !== 0
          //   );
          let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.ClientName !== "BI Free"
        );
          if (filteredData.length > 0)
            await localStorage.setItem(
              "clientList",
              JSON.stringify(filteredData)
            );
        }
        await localStorage.setItem(
          "token",
          loginResult?.data?.data?.accessToken ?? null
        );
        await localStorage.setItem(
          "deviceId",
          loginResult?.data?.data?.deviceId ?? null
        );
        await localStorage.setItem("userId", UserId);

        if (loginResult?.data?.data?.accessToken) {
          localStorage.setItem("email", email || result._tokenResponse.email);
          props?.setEmail(email || result._tokenResponse.email || "");
          localStorage.setItem(
            "primaryEmail",
            email || result._tokenResponse.email
          );
          localStorage.setItem("firstName", FirstName || "");
          localStorage.setItem("lastName", LastName || "");
          props?.setFirstName(FirstName || "");
          props?.setLastName(LastName || "");
          localStorage.setItem("loginWith", "Facebook");
          localStorage.setItem(
            "pictureUrl",
            loginResult?.data?.data?.pictureUrl
          );
          // localStorage.setItem("IsPrivateEmail", false);
          // props?.setIsEmailPrivate(true);
          props?.setEmailFlag(true);
          const tutorial = {
            dashboard: "true",
            sites: "true",
            montages: "true",
            ticket: "true",
            jobPostings: "true",
            carpool: "true",
            resumeBuilder: "true",
            contentSearch: "true",
          };
          localStorage.setItem("tutorial", JSON.stringify(tutorial));

          if (loginResult.data?.message === "OK") {
            if (isFirstTimeLoggedIn) {
              localStorage.setItem("newUser", "true");
              props?.setActiveForm("ProfilePage");
              return;
            } else {
              // window.location.href = "/dashboard"
              if (/android/i.test(userAgent)) {
                const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
                window.location.href = url;
              } else if (
                /iPad|iPhone|iPod/.test(userAgent) &&
                !window.MSStream
              ) {
                const url = "mybuilderid://"; // Custom URL scheme for your app
                const appStoreURL =
                  "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

                // Start timer
                const startTime = Date.now();
                let didOpenApp = false;

                // Attempt to open the app
                window.location.href = url;

                // Check if the app opened successfully
                const checkAppOpened = setTimeout(() => {
                  const endTime = Date.now();

                  // If the time difference is small, it means the app did not open
                  if (!didOpenApp && endTime - startTime < 2000) {
                    window.location.href = appStoreURL;
                  }
                }, 1500);

                // Listen for visibility change
                document.addEventListener("visibilitychange", () => {
                  if (document.visibilityState === "hidden") {
                    didOpenApp = true;
                    clearTimeout(checkAppOpened);
                  }
                });
              } else {
                window.location.replace("/dashboard");
              }
            }
            if (props?.screenName) {
              switch (props?.screenName) {
                case "TradeDetail":
                  window.location.href = "trade/" + props?.itemId;
                  break;
                case "ProdcutInfo":
                  window.location.href = "product/" + props?.itemId;
                  break;
                case "CompanyInfo":
                  window.location.href = "company/" + props?.itemId;
                  break;
                case "SiteDetail":
                  window.location.href = "sites/site/" + props?.itemId;
                  break;
                case "ManufacturerInfo":
                  window.location.href = "manufacturer/" + props?.itemId;
                  break;
                default:
                  // window.location.href = "/dashboard";
                  window.location.replace("/dashboard");
              }
            } else {
              if (pathname === "/")
                // props.history.push("/dashboard");
                window.location.replace("/dashboard");
            }
          }
        } else {
          console.log("LOGIN RESPONSE IS NOT OK!");
        }
      }

      if (err.code === "auth/popup-blocked") {
        // Fallback to redirect if popup is blocked
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
          setPopUpAlertOpen(true);
        return;
        // await signInWithRedirect(auth, provider);
        // setFacebookPopupBlockFlag(true);
      } else {
        console.error("Error during sign-in:", err);
      }

      if (err.code === "auth/popup-closed-by-user") {
        alert("Pop up closed by user");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirectCase = async (auth) => {
    const res = await getRedirectResult(auth);
    console.log("Redirect : ", res);
  };

  const appleLogin = async () => {
    const auth = getAuth(firebase_app);
    const provider = new OAuthProvider("apple.com");
    const deviceId = await getDeviceId();
    try {
      //  provider.setCustomParameters({ display: 'popup' });
      provider.addScope("email");
      provider.addScope("name");
      const result = await signInWithPopup(auth, provider);

      console.log("Auth result ", result);

      setIsLoading(true);

      const appleAuthObj = {
        Email: result?.user?.email,
        Password: "",
        NotifyToken: notifyToken ?? null,
        AppName: "BuilID-Web", //<--- Changes for Mobile development
        AppVersion: packageInfo?.version,
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        // MobileDeviceModel: DeviceInfo.getModel(),
        Platform: bowserRes.parsedResult.os.name, // <--- can be ios,android,windows,macos
        PlatformVersion: "NA",
        // IsDeviceNotify: "true",
        DeviceName: "Browser",
        DeviceId: deviceId,
        IdentityToken: result._tokenResponse.oauthIdToken,
        UserID: result?.user?.providerData?.[0]?.uid ?? 0,
        LoginWith: "Apple",
        // FirstName: "Apple",
        // LastName: "Users"
      };

      const loginResult = await userLogin(appleAuthObj); //! re-rendering

      console.log("loginResult : ", loginResult);
      // return;

      const {
        isFirstTimeLoggedIn,
        UserId,
        FirstName,
        LastName,
        Email,
        IsPrivateEmail,
        DeviceId,
      } = loginResult?.data?.data?.userInformation;
      if (loginResult?.data?.data.userInformation.ClientsList.length > 0) {
        // const clientsWithLicense = loginResult?.data?.data?.userInformation?.ClientsList?.filter(client => Array.isArray(client.Licences)) || [];
        // loginResult?.data?.data.userInformation.ClientsList?.forEach((obj) => {
        //   if (obj.Licences) {
        //     obj.noOfLicense = obj.Licences.length;
        //   } else {
        //     obj.noOfLicense = 0;
        //   }
        // });
        // let filteredData =
        //   loginResult?.data?.data.userInformation.ClientsList?.filter(
        //     (obj) => obj.noOfLicense !== 0
        //   );
        let filteredData =
        loginResult?.data?.data.userInformation.ClientsList?.filter(
          (obj) => obj.ClientName !== "BI Free"
        );
        if (filteredData.length > 0)
          localStorage.setItem("clientList", JSON.stringify(filteredData));
      }
      localStorage.setItem(
        "token",
        loginResult?.data?.data?.accessToken ?? null
      );
      localStorage.setItem("deviceId", DeviceId ?? null);
      localStorage.setItem("userId", UserId);

      if (loginResult?.data?.data?.accessToken) {
        localStorage.setItem("email", result?.user?.email || "");
        props?.setIsEmailPrivate(IsPrivateEmail);
        if (IsPrivateEmail) {
          props?.setEmail("");
        } else {
          props?.setEmail(result?.user?.email);
        }
        localStorage.setItem("primaryEmail", result?.user?.email);
        localStorage.setItem("firstName", FirstName || "");
        localStorage.setItem("lastName", LastName || "");
        props?.setFirstName(FirstName || "");
        props?.setLastName(LastName || "");
        localStorage.setItem("loginWith", "Apple");
        localStorage.setItem("referPageFlag", "true");
        localStorage.setItem("IsPrivateEmail", IsPrivateEmail);

        // localStorage.setItem("IsPrivateEmail", IsPrivateEmail);

        localStorage.setItem("pictureUrl", loginResult?.data?.data?.pictureUrl);

        const tutorial = {
          dashboard: "true",
          sites: "true",
          montages: "true",
          ticket: "true",
          jobPostings: "true",
          carpool: "true",
          resumeBuilder: "true",
          contentSearch: "true",
        };
        localStorage.setItem("tutorial", JSON.stringify(tutorial));

        if (loginResult.data?.message === "OK") {
          if (isFirstTimeLoggedIn) {
            localStorage.setItem("newUser", "true");
            props?.setActiveForm("ProfilePage");
          } else {
            // window.location.href = "/dashboard";
            if (/android/i.test(userAgent)) {
              const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
              window.location.href = url;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              const url = "mybuilderid://"; // Custom URL scheme for your app
              const appStoreURL =
                "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

              // Start timer
              const startTime = Date.now();
              let didOpenApp = false;

              // Attempt to open the app
              window.location.href = url;

              // Check if the app opened successfully
              const checkAppOpened = setTimeout(() => {
                const endTime = Date.now();

                // If the time difference is small, it means the app did not open
                if (!didOpenApp && endTime - startTime < 2000) {
                  window.location.href = appStoreURL;
                }
              }, 1500);

              // Listen for visibility change
              document.addEventListener("visibilitychange", () => {
                if (document.visibilityState === "hidden") {
                  didOpenApp = true;
                  clearTimeout(checkAppOpened);
                }
              });
            } else {
              window.location.replace("/dashboard");
            }
          }
          if (props?.screenName) {
            switch (props?.screenName) {
              case "TradeDetail":
                window.location.href = "trade/" + props?.itemId;
                break;
              case "ProdcutInfo":
                window.location.href = "product/" + props?.itemId;
                break;
              case "CompanyInfo":
                window.location.href = "company/" + props?.itemId;
                break;
              case "SiteDetail":
                window.location.href = "sites/site/" + props?.itemId;
                break;
              case "ManufacturerInfo":
                window.location.href = "manufacturer/" + props?.itemId;
                break;
              default:
                // window.location.href = "/dashboard";
                window.location.replace("/dashboard");
            }
          }
        }
      } else {
        console.log("LOGIN RESPONSE IS NOT OK!");
      }
    } catch (error) {
      console.log("err : ", error);
      // if (error.code === 'auth/popup-blocked') {
      if (
        error.code === "auth/popup-blocked" ||
        error.code === "auth/popup-closed-by-user"
      ) {
        // Fallback to redirect if popup is blocked
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
          setPopUpAlertOpen(true);
        return;
        // await signInWithRedirect(auth, provider)
        // setApplePopupBlockFlag(true);
      } else {
        console.error("Error during sign-in:", error);
      }

      console.error("Error during sign in with Apple: ", error);
    }
  };

  return (
    <div className="login-form-content-width with-referral">
      {errorMsg != "" && (
        <AlertModal
          isOpen={errorMsg != ""}
          onClose={() => setErrorMsg("")}
          body={errorMsg}
        />
      )}
      {popUpAlertOpen && (
        <AlertModal
          inappropriateImage
          isOpen={popUpAlertOpen}
          onClose={() => setPopUpAlertOpen(false)}
          title={"Enable Pop-ups for a Better Experience"}
          body={
            <>
              It looks like <strong>Safari's pop-up blocker</strong> is
              currently enabled, which may prevent you from signing in. To
              continue, please disable the 'Block Pop-ups' setting for this
              site.
            </>
          }
          // text1={"Open the Settings app on your device. Scroll down and tap Safari."}
          text1={
            <>
              Open the <strong>Settings</strong> app on your device {">"} <br />
              Scroll down and tap <strong>Safari</strong> {">"} <br />
              Scroll down and Toggle off <strong>Block Pop-ups</strong>.
            </>
          }
        />
      )}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}>
          <Loader />
        </div>
      ) : (
        <>
          <AlertModal
            isOpen={alertModalOpen_AccountExist}
            onClose={() => setAlertModalOpen_AccountExist(false)}
            title="User already exists!"
            body="User already exists! Use 'Forgot password' option to reset your password!"
          />
          <div
            className="flex-container-horizontal"
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "45px 0px",
            }}>
            <span
              style={{
                maxWidth: "90%",
                display: "flex",
                justifyContent: "center",
              }}>
              <img
                src={BuildIDLogo}
                alt=""
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  width: "200px",
                  height: "auto",
                }}
              />
            </span>
          </div>
          <div
            className="flex-container-horizontal"
            style={{
              width: "100%",
              justifyContent: "center",
              padding: "8px 0px",
              flexDirection: "column",
              fontSize: "15px ",
              lineHeight: "20px",
            }}>
            <p>
              {" "}
              Sign up for Build Id, a free construction app that empowers and
              rewards tradespeople and other working in the industry.{" "}
            </p>
            <br />{" "}
            <p>
              {" "}
              Organize your safety tickets, generate <br />
              professional-looking resumes, and connect with the construction
              industry.
            </p>
          </div>
          <form>
            <div className="input-container">
              <TextField
                variant="outlined"
                label="Email"
                fullWidth
                // defaultValue={props.email}
                onChange={props.handleEmailTextfieldChange}
                error={isValid}
                helperText={
                  isValid ? "Please enter a valid email address." : ""
                }
                value={props?.email ?? ""}
              />
            </div>
            {/* <div className="input-container">
                                    <TextField
                                        variant="outlined"
                                        label="First Name"
                                        fullWidth
                                        value={firstName}
                                        onChange={event => {
                                            setFirstName(event.target.value);
                                            localStorage.setItem("firstName", event.target.value);
                                        }}
                                    />
                                </div>
                                <div className="input-container">
                                    <TextField
                                        variant="outlined"
                                        label="Last Name"
                                        fullWidth
                                        value={lastName}
                                        onChange={event => {
                                            setLastName(event.target.value);
                                            localStorage.setItem("lastName", event.target.value);
                                        }}
                                    />
                                </div> */}
            <div className="input-container">
              <TextField
                type={showPassword ? "text" : "password"}
                variant="outlined"
                label="Password"
                fullWidth
                // defaultValue={password}
                onChange={(e) => {
                  let count = 0;
                  if (e.target.value.length >= 6) count += 1;
                  if (e.target.value.match(/(?=.*\d)/)) count += 1;
                  if (e.target.value.match(/(?=.*[A-Z])/)) count += 1;
                  if (e.target.value.match(/(?=.*[a-z])/)) count += 1;
                  setPassword(e.target.value);
                  setPasswordStrengthCount(count);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex="-1" // Unfocusable button
                        onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <HideIcon /> : <ShowIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="flex-container-horizontal">
              <div className="flex-container-left">
                <div className="input-container">
                  <span>Password strength</span>
                </div>
              </div>

              <div className="flex-container-right">
                <div className="input-container">
                  <span
                    className="right"
                    style={{
                      color:
                        passwordStrengthCount === 1
                          ? BIRed
                          : passwordStrengthCount > 1 &&
                            passwordStrengthCount < 4
                            ? BIYellow
                            : passwordStrengthCount === 4
                              ? BIGreen
                              : BIGrey,
                    }}>
                    {passwordStrengthStatus}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "5px",
                borderRadius: "4px",
                backgroundColor: BIGrey,
              }}>
              <div
                style={{
                  borderRadius: "4px",
                  height: "5px",
                  width:
                    passwordStrengthCount === 1
                      ? "33%"
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? "66%"
                        : passwordStrengthCount === 4
                          ? "100%"
                          : 0,
                  backgroundColor:
                    passwordStrengthCount === 1
                      ? BIRed
                      : passwordStrengthCount > 1 && passwordStrengthCount < 4
                        ? BIYellow
                        : passwordStrengthCount === 4
                          ? BIGreen
                          : BIGrey,
                }}></div>
            </div>

            <div className="input-container">
              <p
                style={{
                  color:
                    password !== ""
                      ? password.length >= 6
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.length >= 6 ? (
                  <FaCheck
                    className="icon-position"
                    style={{ color: password.length >= 6 ? BIGreen : BIGrey }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 6 characters
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*\d)/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.match(/(?=.*\d)/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*\d)/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 number
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[A-Z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.match(/(?=.*[A-Z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[A-Z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 capital letter
              </p>

              <p
                style={{
                  color:
                    password !== ""
                      ? password.match(/(?=.*[a-z])/)
                        ? BIGreen
                        : BIRed
                      : BIGrey,
                }}>
                {password === "" || password.match(/(?=.*[a-z])/) ? (
                  <FaCheck
                    className="icon-position"
                    style={{
                      color: password.match(/(?=.*[a-z])/) ? BIGreen : BIGrey,
                    }}
                  />
                ) : (
                  <FaTimes className="icon-position" style={{ color: BIRed }} />
                )}{" "}
                At least 1 lowercase letter
              </p>
            </div>
          </form>

          <div
            className="input-container"
            style={{ paddingBottom: "40px", paddingTop: "2px" }}>
            <div className="input-container">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  props.email == "" ||
                  password == "" ||
                  passwordStrengthCount !== 4
                }
                onClick={handleSignUpClick}>
                create Account
              </Button>
            </div>

            {/* <div className="divider">
              <p>
                <span>or</span>
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: "pointer",
                gap: "10px",
              }}
              onClick={() => {
                googleAuthHandler();
              }}
            >
              <img src={googleLogo} width={24} height={24} alt="Loading.." />
              <p>Continue with Google</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: "pointer",
                gap: "10px",
                marginTop: "10px",
              }}
              onClick={() => {
                fbAuthHandler();
              }}
            >
              <img src={fbLogo} width={24} height={24} alt="Loading.." />
              <p>Continue with Facebook</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgray",
                paddingTop: 7,
                paddingBottom: 7,
                borderRadius: 8,
                cursor: "pointer",
                gap: "10px",
                marginTop: "10px",
              }}
              onClick={() => {
                appleLogin();
              }}
            >
              <AppleIcon />
              <p>Continue with Apple</p>
            </div>
          </div> */}
          </div>
        </>
      )}
    </div>
  );
}

function VerifyEmailForm(props) {
  const [verificationCode, setVerificationCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [isEmptyVerificationCode, setIsEmptyVerificationCode] = useState(false);

  let bowserRes = Bowser.getParser(window.navigator.userAgent);

  function handleVerifyClick() {
    if (props.email === "") {
      setIsEmptyEmail(true);
      setIsValid(false);
      verificationCode === ""
        ? setIsEmptyVerificationCode(true)
        : setIsEmptyVerificationCode(false);
      return false;
    } else {
      setIsEmptyEmail(false);
      verificationCode === ""
        ? setIsEmptyVerificationCode(true)
        : setIsEmptyVerificationCode(false);

      const email = props.email;
      if (emailRegexp.test(email)) {
        setIsValid(false);
        setIsEmptyEmail(false);
      } else {
        setIsValid(true);
        return false;
      }
    }

    if (verificationCode === "") {
      setIsEmptyVerificationCode(true);
    } else {
      setIsEmptyVerificationCode(false);
      // It's not a "reset" code, but this is the API for it
      const obj = {
        email: props.email,
        resetCode: verificationCode,
        NotifyToken: "",
        AppName: "BuilID-Web",
        AppVersion: "3.8",
        Browser: bowserRes.parsedResult.browser?.name,
        BrowserVersion: bowserRes.parsedResult.browser?.version,
        MobileDeviceModel: "",
        Platform: bowserRes.parsedResult.os.name, // ios/android/windows/macos
        PlatformVersion: "NA",
        DeviceName: "",
        SendWelcomeEmail: true, // if want to send email
      };

      isValidResetCode(obj)
        .then(async (res) => {
          if (
            res === "Verification code is invalid!" ||
            res === "Not a valid Email address!" ||
            res === "Invalid email !"
          )
            props.setAlertModalTitle("Incorrect code!");
          else {
            await localStorage.setItem("token", res);
            props.setAlertModalTitle("Your account has been verified!");
            props.setAlertModalBody("Please log in.");
            props.goToLoginForm();
          }
        })
        .catch((err) =>
          err === "Invalid email !"
            ? props.setAlertModalTitle("Invalid email !")
            : props.setAlertModalTitle("Incorrect code!")
        );
    }
  }

  function handleOtpChange(otp) {
    setVerificationCode(otp);
  }

  return (
    <div
      className="login-form-content-width thankyou-screen"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
      <h3>Verify Email</h3>
      <form>
        <div style={{ margin: "20px 0px" }}>
          <TextField
            variant="outlined"
            label="Email"
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            // defaultValue={props.email}
            onChange={props.handleEmailTextfieldChange}
            error={isEmptyEmail || isValid}
            helperText={
              isEmptyEmail
                ? "This field is required."
                : isValid
                  ? "Please enter a valid email address."
                  : ""
            }
          />
        </div>
        <div>
          <OtpInput
            value={verificationCode}
            isInputNum={true}
            onChange={(otp) => handleOtpChange(otp)}
            numInputs={6}
            inputStyle={{
              width: "45px",
              height: "45px",
              margin: "5px",
              fontSize: "1rem",
              borderRadius: 4,
              marginBottom: "20px",
              border: "1px solid #484848",
            }}
          />
        </div>
      </form>

      <div
        className="flex-container-horizontal"
        style={{ justifyContent: "center" }}>
        <div className="input-container">
          <Button color="primary" onClick={props.onBackClick}>
            Back
          </Button>
        </div>
        <div className="input-container">
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyClick}>
            Verify
          </Button>
        </div>
      </div>
    </div>
  );
}

function DownloadApkForm(props) {
  return (
    <div className="login-form-content-width thankyou-screen">
      <div
        className="flex-container-horizontal"
        style={{ width: "100%", justifyContent: "center", paddingTop: "50px" }}>
        <span
          style={{
            maxWidth: "90%",
            display: "flex",
            justifyContent: "center",
          }}>
          <img
            src={BuildIDLogo}
            alt=""
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "200px",
              height: "auto",
            }}
          />
        </span>
      </div>
      <div
        className="flex-container-horizontal"
        style={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          fontSize: "15px ",
          paddingTop: "45px",
          lineHeight: "10px",
        }}>
        <p>
          {" "}
          <span style={{ color: "black" }}>
            <b>Thanks for verifying your email!</b>
          </span>{" "}
        </p>
        <br /> <p> Download the app to continue</p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingTop: "65px",
        }}>
        <a
          rel="noopener noreferrer"
          href="https://apps.apple.com/us/app/build-id/id1490164104"
          target="_blank"
          style={{
            maxWidth: "80%",
            maxHeight: "44px",
            display: "flex",
            justifyContent: "center",
          }}>
          <img
            src={AppleStoreLogo}
            alt=""
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "auto",
              height: "auto",
            }}
          />
        </a>

        <a
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.mybuilderid&pli=1"
          target="_blank"
          style={{
            maxWidth: "80%",
            maxHeight: "44px",
            display: "flex",
            justifyContent: "center",
          }}>
          <img
            src={PlayStoreLogo}
            alt=""
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "auto",
              height: "auto",
            }}
          />
        </a>
      </div>
    </div>
  );
}

export function ProfilePage(props) {
  // const [firstName, setFirstName] = useState(localStorage?.firstName ?? "");
  // const [lastName, setLastName] = useState(localStorage?.lastName ?? "");
  // const [email, setEmail] = useState(props?.email);
  const [searchTerm, setSearchTerm] = useState("");
  const [locationDetails, setLocationDetails] = useState({
    city: "",
    country: "",
    latitude: "",
    longitude: "",
  });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const lettersOnly = /^[a-zA-Z]*$/;

  const handleSelect = async (place) => {
    setSelectedPlace(place);

    try {
      const placeId = place.value.place_id;
      const results = await geocodeByPlaceId(placeId);
      const { lat, lng } = await getLatLng(results[0]);

      const addressComponents = results[0].address_components;

      const city =
        addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name ||
        addressComponents.find((component) =>
          component.types.includes("sublocality")
        )?.long_name ||
        addressComponents.find((component) =>
          component.types.includes("administrative_area_level_1")
        )?.long_name ||
        addressComponents.find((component) =>
          component.types.includes("administrative_area_level_2")
        )?.long_name ||
        "";

      const country =
        addressComponents.find((component) =>
          component.types.includes("country")
        )?.long_name || "";

      // Log or store the city, country, latitude, and longitude
      // console.log({ city, country, lat, lng });
      setLocationDetails({
        city: city,
        country: country,
        latitude: lat,
        longitude: lng,
      });
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const handleClear = () => {
    setSearchTerm("");
    setSelectedPlace(null);
  };

  const handleProfilePageNext = async () => {
    try {
      const obj = {
        UserId: localStorage?.userId,
        FirstName: props?.firstName ?? "",
        LastName: props?.lastName ?? "",
        Email: "",
        City: locationDetails.city ?? "",
        Country: locationDetails.country ?? "",
        Latitude: locationDetails.latitude ?? 0.0,
        Longitude: locationDetails.longitude ?? 0.0,
      };

      const res = await updateVerifiedDetails(obj);

      localStorage?.setItem("email", props?.email);
      localStorage?.setItem("primaryEmail", props?.email);
      if (locationDetails?.latitude || locationDetails?.longitude) {
        localStorage.setItem("latitude", locationDetails?.latitude);
        localStorage.setItem("longitude", locationDetails?.longitude);
      }
      localStorage.setItem("firstName", props?.firstName || "");
      localStorage.setItem("lastName", props?.lastName || "");
      props?.onNextClick();
    } catch (err) {
      console.log("UpdateVerifiedDetails ERROR : ", err);
      if (err.response?.status == 406) {
        setErrorMsg(err.response?.data?.errorMessage);
      }
    }
  };

  return (
    <div
      className="login-form-content-width with-referral"
      style={{ padding: "45px 0px" }}>
      <div
        className="flex-container-horizontal"
        style={{ width: "100%", paddingTop: "50px" }}>
        <span
          style={{
            maxWidth: "90%",
            display: "flex",
            justifyContent: "center",
          }}>
          <img
            src={BuildIDLogo}
            alt=""
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "150px",
              height: "auto",
            }}
          />
        </span>
      </div>
      <div className="input-container">
        <Stack justifyContent={"center"} width={"100%"}>
          <BorderLinearProgress variant="determinate" value={33.33} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Typography variant="caption" sx={{ flexGrow: 1, textAlign: "left" }}>
            Profile
          </Typography>
          <Typography variant="caption" sx={{ textAlign: "right" }}>
            1 of 3
          </Typography>
        </Stack>
      </div>
      <form>
        <div className="input-container">
          <TextField
            variant="outlined"
            label="First Name"
            fullWidth
            value={props?.firstName}
            onChange={(event) => {
              const newValue = event.target.value;
              if (lettersOnly.test(newValue)) {
                props?.setFirstName(newValue);
              }
              // localStorage.setItem("firstName", event.target.value);
            }}
          />
        </div>
        <div className="input-container">
          <TextField
            variant="outlined"
            label="Last Name"
            fullWidth
            value={props?.lastName}
            onChange={(event) => {
              const newValue = event.target.value;
              if (lettersOnly.test(newValue)) {
                props?.setLastName(newValue);
              }
              // localStorage.setItem("lastName", event.target.value);
            }}
          />
        </div>
        <div className="input-container">
          <TextField
            variant="outlined"
            label="Email"
            fullWidth
            defaultValue={props?.email}
            value={props?.email}
            // disabled={props?.isEmailPrivate ? false : props?.emailFlag && true}
            disabled={
              props?.isEmailPrivate === false
                ? true
                : props?.isEmailPrivate === true
                  ? false
                  : props?.emailFlag && true
            }
            onChange={props?.handleEmailTextfieldChange}
          // error={isValid}
          // helperText={
          //     isValid ? "Please enter a valid email address." : ""
          // }
          />
        </div>

        <div className="input-container">
          <GooglePlacesAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            selectProps={{
              value: selectedPlace,
              onChange: handleSelect,
              inputValue: searchTerm,
              onInputChange: (value) => setSearchTerm(value),
              isClearable: true, // This allows clearing the selection
              placeholder: "City",
              styles: {
                input: (provided) => ({
                  ...provided,
                  height: "45px", // Adjust the height as needed
                  padding: "12px",
                }),
                control: (provided) => ({
                  ...provided,
                  minHeight: "45px", // Adjust the minimum height to match the input
                }),
              },
            }}
            autocompletionRequest={{
              componentRestrictions: { country: ["US", "CA", "GB"] }, // Restrict to USA, Canada, and UK
            }}>
            <TextField
              variant="outlined"
              fullWidth
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {searchTerm !== "" && (
                      <IconButton onClick={handleClear}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                startAdornment: (
                  <SearchIcon style={{ color: "grey", marginRight: "10px" }} />
                ),
              }}
            />
          </GooglePlacesAutocomplete>
        </div>
      </form>

      <div
        className="input-container"
        style={{ paddingBottom: "40px", paddingTop: "2px" }}>
        <div className="input-container">
          <Button
            variant="contained"
            // color="primary"
            style={{
              background:
                props?.firstName && props?.lastName && props?.email
                  ? BIBlue
                  : BILightGrey,
              color:
                props?.firstName && props?.lastName && props?.email
                  ? "white"
                  : BIGrey,
            }}
            fullWidth
            disabled={
              props?.firstName == "" ||
              props?.lastName == "" ||
              props?.email == ""
            }
            onClick={handleProfilePageNext}>
            Next
          </Button>
        </div>
      </div>

      {errorMsg != "" && (
        <AlertModal
          isOpen={errorMsg != ""}
          onClose={() => setErrorMsg("")}
          body={errorMsg}
        />
      )}
    </div>
  );
}

export function TradeAndCompanyPage(props) {
  const [tradeCardOpen, setTradeCardOpen] = useState(false);
  const [trades, setTrades] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyDetail, setCompanyDetail] = useState({});
  const [companyId, setCompanyId] = useState(0);
  const [flag, setFlag] = useState(false);
  const [allCompanies, seAllCompanies] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [tradeLoading, setTradeLoading] = useState(false);

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  function sortTradesByTradeType(trades) {
    return trades.sort((a, b) => {
      if (
        a.tradeType.toLowerCase() === "main" &&
        b.tradeType.toLowerCase() !== "main"
      ) {
        return -1; // a comes before b
      } else if (
        a.tradeType.toLowerCase() !== "main" &&
        b.tradeType.toLowerCase() === "main"
      ) {
        return 1; // b comes before a
      }
      return 0; // no change in order
    });
  }

  async function getUserTradeList() {
    // setTradeLoading(true);
    const userTradeObj = {
      PageNr: 1,
      FullSearch: "",
      IncludeRecordNr: false,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [{ UserId: localStorage.userId }],
    };
    const userTradeRes = await fetchUserTrades(userTradeObj);
    const userTradeRes2 = userTradeRes.map((trade) => {
      trade.tradeType = trade.tradeType.toLowerCase();
      return trade;
    });
    setTrades(userTradeRes2);
    // setTradeLoading(false);
  }

  const onDataSave = () => {
    setTradeLoading(true);
    fetchUserTrades({ SearchList: [{ UserId: localStorage.userId }] })
      .then((manufacturerRes) => {
        const userTradeRes2 = manufacturerRes.map((trade) => {
          trade.tradeType = trade.tradeType.toLowerCase();
          return trade;
        });
        const sortedTrades = sortTradesByTradeType(userTradeRes2);
        setTrades(sortedTrades);
        setTradeLoading(false);
        // getUserTradeList();
      })
      .catch((err) => {
        console.log("Error while getting info after saving the data", err);
      });
  };

  const getCompanies = async () => {
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = removeDuplicates(res.data, "name");
    setCompanyData(response);
  };

  const getAllCompanies = async () => {
    setIsLoading(true);
    try {
      const userCompanyObj = {
        IncludeRecordNr: true,
        NrOfRecPerPage: 0,
        SearchList: [{ manufacturer: false }],
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
      };
      const userCompanies = await fetchCompanies(userCompanyObj);
      seAllCompanies(userCompanies);
      setIsLoading(false);
    } catch (error) {
      console.log("Error---", error);
    }
  };

  const getFilterCompanies = async (props) => {
    if (props !== "") {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
    const res = await fetchCompanies({
      IncludeRecordNr: true,
      NrOfRecPerPage: 0,
      SearchList: [{ name: props, manufacturer: false }],
      SortList: [{ FieldName: "Name", Direction: "ASC" }],
    });
    let a;
    res.data.map((x) => {
      a = x;
      a["title"] = x.name;
      return a;
    });
    const response = await removeDuplicates(res.data, "name");
    setFilterData(response);
  };

  useEffect(() => {
    getAllCompanies();
    getUserTradeList();
  }, []);

  const nextBtnClick = async () => {
    if (companyId == 0 && companyName !== "") {
      const savecompanyObj = {
        userId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            Name: companyName,
            ModifiedBy: localStorage?.userId,
            RecordStatusId: 1,
            Manufacturer: false,
          },
        ],
      };

      const saveCompanyRes = await saveCompanies2(savecompanyObj);

      console.log("saveCompanyRes ", saveCompanyRes);

      if (saveCompanyRes[0]?.message === "OK") {
        const obj = {
          UserId: localStorage?.userId,
          ReturnRecordId: true,
          ReturnRecordError: true,
          SaveList: [
            {
              Id: 0,
              UserId: localStorage?.userId,
              RoleId: 0,
              CompanyId: saveCompanyRes[0]?.objData?.id,
              StartDate: null,
              EndDate: null,
              ModifiedBy: localStorage?.userId,
              IncludeInResume: false,
              CurrentCompany: false,
              Description: "",
            },
          ],
        };

        await saveUserCompanies(obj);
        localStorage.setItem("companyId", saveCompanyRes[0]?.objData?.id);
      }
    } else if (companyId > 0) {
      const obj = {
        UserId: localStorage?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            UserId: localStorage?.userId,
            RoleId: 0,
            CompanyId: localStorage?.companyId || 0,
            StartDate: null,
            EndDate: null,
            ModifiedBy: localStorage?.userId,
            IncludeInResume: false,
            CurrentCompany: false,
            Description: "",
          },
        ],
      };
      await saveUserCompanies(obj);
    }
    props?.onNextClick();
  };

  return (
    <>
      <div
        className="login-form-content-width with-referral"
        style={{ padding: "45px 0px" }}>
        <div
          className="flex-container-horizontal"
          style={{ width: "100%", paddingTop: "50px" }}>
          <span
            style={{
              maxWidth: "90%",
              display: "flex",
              justifyContent: "center",
            }}>
            <img
              src={BuildIDLogo}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "150px",
                height: "auto",
              }}
            />
          </span>
        </div>
        <div className="input-container">
          <Stack justifyContent={"center"} width={"100%"}>
            <BorderLinearProgress variant="determinate" value={66.66} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="caption"
              sx={{ flexGrow: 1, textAlign: "left" }}>
              Trade and Profile
            </Typography>
            <Typography variant="caption" sx={{ textAlign: "right" }}>
              2 of 3
            </Typography>
          </Stack>
        </div>
        <div className="input-container">
          <Typography variant="h5">What do you do for work?</Typography>
        </div>
        <div className="input-container">
          <Typography variant="body2">
            Your trade and company will help you connect with similar BuildID
            users
          </Typography>
        </div>

        {tradeLoading ? (
          <Loader />
        ) : trades.length > 0 ? (
          <div
            className="input-container"
            style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
            {trades.map((trade, index) => (
              <span key={index} style={{ display: "flex", marginRight: "5px" }}>
                <Chip
                  style={{ background: trade?.tradeType == "main" && BIYellow }}
                  label={trade?.tradeName}
                />
              </span>
            ))}
            <div
              onClick={() => {
                setTradeCardOpen(true);
              }}
              style={{
                color: BIBlue,
                cursor: "pointer",
                padding: "5px",
              }}>
              <EditIcon />
            </div>
          </div>
        ) : (
          <div className="input-container">
            <Button
              onClick={() => setTradeCardOpen(true)}
              fullWidth
              variant="contained"
              style={{ background: BIBlue, color: "white" }}>
              Select my Trade(s)
            </Button>
          </div>
        )}
        <div className="input-container">
          {/* new code sanket 20-03-2024 */}
          <Autocomplete
            inputValue={companyName}
            // disabled={(props?.from === "experience" || props?.from === "companyProfile") && prevCompId != 0 ? true : false}
            onChange={(event, newValue) => {
              if (newValue && newValue?.inputValue) {
                setCompanyName(newValue?.inputValue);
                setCompanyId(0);
                setCompanyDetail(null);
                setFlag(false);
              } else {
                setCompanyId(newValue?.id);
                localStorage.setItem("companyId", newValue?.id);
                setFlag(false);
                //     if (allCompanies?.some((comp) => comp?.companyId == newValue?.id)) {
                //         const comp = allCompanies.filter((c) => c?.companyId == newValue?.id);
                //         if (comp?.length > 0) {
                //             let companyDetails = comp?.[0] ?? {};
                //             setCompanyDetail({ ...companyDetails, userCompanyId: companyDetails?.id });
                //             setSelectedRoleId(companyDetails?.roleId ?? 0);
                //             setSelectedRoleName(companyDetails?.roleName ?? "");
                //         }
                //     // }
                //     setFlag(false);
                // }
              }
            }}
            onInputChange={(event, newInputValue) => {
              setCompanyName(newInputValue);
              setCompanyId(0);
              setCompanyDetail(null);
              setFlag(true);
              if (newInputValue !== "") {
                getFilterCompanies(newInputValue);
              } else {
                getCompanies();
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;

              const isExisting = options.some(
                (option) => inputValue === option?.title
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  title: `Add "${inputValue}"`,
                });
              }
              return filtered;
            }}
            options={isSearching ? filterData : companyData}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              if (option?.inputValue) {
                return option?.inputValue;
              }
              return option?.title;
            }}
            renderOption={(props, option) => (
              <li {...props}>{option?.title}</li>
            )}
            style={{ flex: 1 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <div style={{ display: "flex" }}>
                    <SearchIcon style={{ marginRight: 8 }} />
                    Search for a Company
                  </div>
                }
                variant="outlined"
              />
            )}
            open={companyName?.length > 1 && flag}
          />
        </div>
        <div className="input-container">
          <Typography variant="subtitle1">
            If you are currently unemployed, leave this blank.
          </Typography>
        </div>
        <div className="input-container" style={{ marginTop: "40%" }}>
          <Button
            fullWidth
            variant="contained"
            // style={{ background: BIBlue, color: "white" }}
            style={{
              background: trades.length > 0 ? BIBlue : BILightGrey,
              color: trades.length > 0 ? "white" : BIGrey,
            }}
            disabled={trades.length == 0}
            onClick={() => {
              nextBtnClick();
            }}>
            Next
          </Button>
        </div>
      </div>
      <TradeCardModal
        isOpen={tradeCardOpen}
        onClose={() => {
          setTradeCardOpen(false);
        }}
        userTradeList={trades}
        onDataSave={onDataSave}
      />
    </>
  );
}

export function ExperiencePage(props) {
  const [showAddSiteModal, setShowAddSiteModal] = useState(false);
  const [signUpSiteInfo, setSignUpSiteInfo] = useState({});
  const [nearestSiteInfo, setNearestSiteInfo] = useState([]);

  const handleSiteClick = (site) => {
    setSignUpSiteInfo(site);
  };

  const onChooseSitePressed = () => {
    setShowAddSiteModal(true);
  };

  const handleDifferentSite = () => {
    setShowAddSiteModal(true);
  };

  const fetchNearestSiteList = async () => {
    const obj = {
      UserId: Number(localStorage?.userId),
      UserLatitude: localStorage?.latitude || 51.04483,
      UserLongitude: localStorage?.longitude || -114.071518,
      PageNumber: 1,
      NrRowsPerPage: 5,
    };
    const res = await fetchNearestSitesList(obj);
    setNearestSiteInfo(res);
  };

  const handleExperienceNext = async () => {
    const obj = {
      UserId: Number(localStorage?.userId),
      SiteId: signUpSiteInfo?.id || 0,
      CompanyId: localStorage?.companyId || 0,
    };
    const res = await saveOnBoardingUserExperience(obj);
    props?.onNextClick();
  };

  useEffect(() => {
    fetchNearestSiteList();
  }, []);

  // console.log("signUpSiteInfo=-----", signUpSiteInfo);
  return (
    <>
      {showAddSiteModal && (
        <Modal
          className="choose-opt choose-location"
          open={showAddSiteModal}
          onClose={() => setShowAddSiteModal(false)}>
          <div className="modal-center" style={{ outline: "none" }}>
            <div
              className="modal-card chooseopt-card location-card"
              style={{ width: "1000px" }}>
              <div className="header-section">
                <Typography
                  className="addsite-title"
                  style={{ fontSize: "16px" }}
                  variant="h6"
                  component="h2">
                  Choose Sites
                </Typography>
                <IconButton
                  sx={{ color: "#083DB8" }}
                  onClick={() => setShowAddSiteModal(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <MapScreen
                from={"signUpPage"}
                setSignUpSiteInfo={setSignUpSiteInfo}
                setAddSiteModalForSignUp={setShowAddSiteModal}
              />
            </div>
          </div>
        </Modal>
      )}
      <div
        className="login-form-content-width with-referral"
        style={{ padding: "45px 0px" }}>
        <div
          className="flex-container-horizontal"
          style={{
            width: "100%",
            paddingTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <span
            style={{
              maxWidth: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img
              src={BuildIDLogo}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "150px",
                height: "auto",
              }}
            />
          </span>
        </div>
        <div className="input-container">
          <Stack justifyContent={"center"} width={"100%"}>
            <BorderLinearProgress variant="determinate" value={99.99} />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Typography
              variant="caption"
              sx={{ flexGrow: 1, textAlign: "left" }}>
              Experience
            </Typography>
            <Typography variant="caption" sx={{ textAlign: "right" }}>
              3 of 3
            </Typography>
          </Stack>
        </div>
        <div className="input-container">
          <Typography variant="h6">
            What site are you currently working on?
          </Typography>
        </div>
        {Object.keys(signUpSiteInfo).length == 0 && (
          <div className="input-container">
            <Button
              fullWidth
              variant="contained"
              style={{ background: BIBlue, color: "white" }}
              onClick={() => onChooseSitePressed()}>
              Select a Site
            </Button>
          </div>
        )}

        {Object.keys(signUpSiteInfo).length > 0 ? (
          <div
            className="input-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid grey",
              borderRadius: "10px",
              padding: "12px",
            }}>
            <div>
              <Typography variant="h6">{signUpSiteInfo?.name}</Typography>
              <Typography variant="body2">
                {signUpSiteInfo?.address ||
                  (signUpSiteInfo?.latitude, signUpSiteInfo?.longitude)}
              </Typography>
            </div>
            <IconButton onClick={() => setSignUpSiteInfo({})}>
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          nearestSiteInfo.length > 0 && (
            <div className="input-container">
              <Typography variant="body2">
                Suggested from your location
              </Typography>
              {nearestSiteInfo.map((site, index) => (
                <Typography
                  key={index}
                  onClick={() => handleSiteClick(site)}
                  variant="body2"
                  style={{ color: BIBlue, cursor: "pointer" }}>
                  {site.name} ({site.address})
                </Typography>
              ))}
            </div>
          )
        )}

        {Object.keys(signUpSiteInfo).length > 0 && (
          <Stack
            direction={"row"}
            justifyContent={"center"}
            onClick={handleDifferentSite}>
            <Typography
              style={{ color: BIBlue, cursor: "pointer", fontWeight: "bold" }}
              variant="body2">
              Select a different Site
            </Typography>
          </Stack>
        )}

        <div
          className="input-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}>
          <Button onClick={() => props?.onNextClick()}>Skip</Button>
        </div>
        <div className="input-container">
          <Button
            fullWidth
            onClick={handleExperienceNext}
            variant="contained"
            disabled={Object.keys(signUpSiteInfo).length == 0}
            style={{
              background:
                Object.keys(signUpSiteInfo).length > 0 ? BIBlue : BILightGrey,
              color: Object.keys(signUpSiteInfo).length > 0 ? "white" : BIGrey,
            }}>
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export function FinishPage(props) {
  const [openTour, setOpenTour] = useState(false);

  const handleExploreClick = () => {
    setOpenTour(true);
  };

  const handleGoToAppClick = () => {
    // window.location.href = "/dashboard";
    if (/android/i.test(userAgent)) {
      const url = `intent://#Intent;scheme=mybuilderid;package=com.mybuilderid;end`;
      window.location.href = url;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      const url = "mybuilderid://"; // Custom URL scheme for your app
      const appStoreURL =
        "https://apps.apple.com/in/app/buildid-connect-share-build/id1490164104"; // Replace with your App Store URL

      // Start timer
      const startTime = Date.now();
      let didOpenApp = false;

      // Attempt to open the app
      window.location.href = url;

      // Check if the app opened successfully
      const checkAppOpened = setTimeout(() => {
        const endTime = Date.now();

        // If the time difference is small, it means the app did not open
        if (!didOpenApp && endTime - startTime < 2000) {
          window.location.href = appStoreURL;
        }
      }, 1500);

      // Listen for visibility change
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          didOpenApp = true;
          clearTimeout(checkAppOpened);
        }
      });
    } else {
      window.location.replace("/dashboard");
    }
  };

  return (
    <>
      <div
        className="login-form-content-width with-referral"
        style={{ padding: "45px 0px" }}>
        <div
          className="flex-container-horizontal"
          style={{
            width: "100%",
            marginTop: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <span
            style={{
              maxWidth: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img
              src={BuildIDLogo}
              alt=""
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                width: "150px",
                height: "auto",
              }}
            />
          </span>
        </div>
        <div
          className="input-container"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Typography variant="h4">You're all done!</Typography>
          <Typography variant="subtitle1">
            Welcome to our construction community!
          </Typography>
        </div>

        <div
          className="input-container"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "100px",
          }}>
          <Button
            style={{ background: BIBlue, color: "white" }}
            variant="contained"
            onClick={handleExploreClick}>
            Explore BuildID Features
          </Button>
        </div>
        <div className="input-container">
          <Button
            fullWidth
            onClick={handleGoToAppClick}
            style={{ color: BIBlue }}>
            Go to App
          </Button>
        </div>
      </div>

      {openTour && (
        <TakeTour
          refSignUpPage
          closeOpenTourFromSignUp={() => setOpenTour(false)}
        />
      )}
    </>
  );
}
