import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Tabs,
  Tab,
  Modal,
} from "@mui/material";
import AlertModal from "../../components/AlertModal";
import "../../assets/css/ClientHome.css";
import PictureViewer from "../../components/PictureViewer";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchClientBoardAlbums,
  fetchClientPicturesBoard,
  fetchClientPicturesBoard2,
  saveClientPicturesBoard,
} from "../../api/ClientApi";
import Loader from "../../components/Loader";
import { BIBlue, BIGrey, BILightGrey } from "../../assets/buildidColors";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClientHeader } from "./ClientHeader";
import { LoadingButton } from "@mui/lab";
import { Add, Close } from "@material-ui/icons";
import { useLocation } from "react-router-dom";

export default function PictureBoard() {
  const [albums, setAlbums] = useState([]);
  const [pictureViewerOpen, setPictureViewerOpen] = useState(false);
  const [albumPictureViewerOpen, setAlbumPictureViewerOpen] = useState(false);
  const [pictureViewIndex, setPictureViewIndex] = useState(0);
  const [showAlbumPictures, setShowAlbumPictures] = useState(false);
  const [albumPictures, setAlbumPictures] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [isImageListLoading, setIsImageListLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [submitPicture, setSubmitPicture] = useState(false);
  const [pictureDesc, setPictureDec] = useState("");
  const [uploadedImageURL, setUploadedImageURL] = useState([]);
  const [uploadedImageList, setUploadedImageLIst] = useState([]);
  const [alertModalOpen, setAlertModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const tabsArray = ["All", "My Submissions"];
  const [loading, setLoading] = useState(false);
  const [viewAlbumModal, setViewAlbumModal] = useState(false);
  const [uploadedAlbumImageURL, setUploadedAlbumImageURL] = useState([]);
  const [uploadedAlbumImageLIst, setUploadedAlbumImageLIst] = useState([]);
  const [submitAlbumPicture, setSubmitAlbumPicture] = useState(false);
  const [albumPictureDec, setAlbumPictureDec] = useState("");
  const [albumId, setAlbumId] = useState(null);
  const [btnDisable, setBtnDisable] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (uploadedImageURL.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [uploadedImageURL]);

  useEffect(() => {
    if (uploadedAlbumImageURL.length > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [uploadedAlbumImageURL]);

  const inputFile = useRef(null);
  const inputAlbumFile = useRef(null);

  const getAlbumList = async () => {
    try {
      const obj = {
        UserId: localStorage.userId,
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ ClientId: localStorage?.clientId }],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };
      const albumListRes = await fetchClientBoardAlbums(obj);
      const sortedPictureList = albumListRes.data?.map((item) => {
        // Sort the pictureList array in descending order based on id
        const sortedPictures = item.pictureList.sort((a, b) => b.id - a.id);
        // Return the item with the sorted pictureList
        return {
          ...item,
          pictureList: sortedPictures,
        };
      });
      setAlbums(sortedPictureList || []);
    } catch (err) {
      console.log("Error while fetching album list ", err);
    }
  };

  const loadMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    getAlbumList();
  }, []);

  useEffect(() => {
    if (activeTab === "All") getAllImages(page);
    else getMySubmissionsImages(page);
    setIsImageListLoading(false);
  }, [page, activeTab]);

  const handleAlbumClick = (album) => {
    setAlbumPictures(album?.pictureList);
    setViewAlbumModal(true);
    // setAlbumPictureViewerOpen(true);
  };

  const handleAddImageClick = () => {
    inputFile.current.click();
  };

  const handleAddAlbumImageClick = () => {
    inputAlbumFile.current.click();
  };

  const handlePictureSelect = (pictureIndex) => {
    setPictureViewIndex(pictureIndex);
    setPictureViewerOpen(true);
  };

  const handleAlbumPictureSelect = (index) => {
    setPictureViewIndex(index);
    setAlbumPictureViewerOpen(true);
  }

  const handleImageChange = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      let temp = [];
      for (let i = 0; i < files.length; i++) {
        temp.push(files[i]);
      }

      setUploadedImageLIst(temp);
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setUploadedImageURL((prevImages) => prevImages.concat(fileArray));
      setSubmitPicture(true);

      // Clear the file input value to allow the same file to be selected again
      event.target.value = "";
    }
  };

  const handleAlbumImageChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      let temp = [];
      for (let i = 0; i < files.length; i++) {
        temp.push(files[i]);
      }

      setUploadedAlbumImageLIst(temp);
      const fileArray = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );
      setUploadedAlbumImageURL((prevImages) => prevImages.concat(fileArray));
      setSubmitAlbumPicture(true);

      // Clear the file input value to allow the same file to be selected again
      event.target.value = "";
    }
  }

  const savePictures = async (clientEmployeeId, pictureObj, description) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("Id", 0);
    formData.append("ClientEmployeeId", clientEmployeeId);
    formData.append("PictureUrl", pictureObj);
    formData.append("PictureDescription", description);
    formData.append("AlbumId", 1);
    formData.append("ModifiedBy", localStorage.userId);
    try {
      await saveClientPicturesBoard(formData);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const saveAlbumPictures = async (clientEmployeeId, pictureObj, description, albumId) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("Id", 0);
    formData.append("ClientEmployeeId", clientEmployeeId);
    formData.append("PictureUrl", pictureObj);
    formData.append("PictureDescription", description);
    formData.append("AlbumId", albumId);
    formData.append("ModifiedBy", localStorage.userId);
    try {
      await saveClientPicturesBoard(formData);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleSubmitPicture = async () => {
    for (let i = 0; i < uploadedImageList.length; i++) {
      try {
        await savePictures(
          localStorage.getItem("clientEmployeeId"),
          uploadedImageList[i],
          pictureDesc || ""
        );
      } catch (err) {
        console.log("err : ", err);
        return;
      }
    }
    setSubmitPicture(false);
    setUploadedImageLIst([]);
    setUploadedImageURL([]);
    await getMySubmissionsImages(1);
    setLoading(false);
  };

  const handleSubmitAlbumPicture = async () => {

    for (let i = 0; i < uploadedAlbumImageLIst.length; i++) {
      try {
        await saveAlbumPictures(
          localStorage.getItem("clientEmployeeId"),
          uploadedAlbumImageLIst[i],
          albumPictureDec || "",
          albumPictures[0]?.albumId
        );
      } catch (err) {
        console.log("err : ", err);
        return;
      }
    }
    setSubmitAlbumPicture(false);
    setUploadedAlbumImageLIst([]);
    setAlbumPictureDec("");
    setUploadedAlbumImageURL([]);
    setAlertModalOpen(true);
    await getMySubmissionsImages(1);
    setLoading(false);

  }

  const getMySubmissionsImages = async (pageNumber) => {
    setIsImageListLoading(true);
    const obj = {
      PageNr: pageNumber,
      NrOfRecPerPage: 50,
      FullSearch: "",
      UserId: localStorage?.userId,
      IncludeRecordNr: true,
      SearchList: [
        {
          ClientId: localStorage?.clientId,
          ClientEmployeeId: localStorage?.clientEmployeeId,
        },
      ],
      FetchAllowedRecordsOnly: false,
      SortList: [{ FieldName: "Id", Direction: "DESC" }],
    };
    const imgList = await fetchClientPicturesBoard(obj);
    if (imgList.data.length > 0) {
      setImageList((prevImageList) => [...prevImageList, ...imgList.data]);
    } else {
      setHasMore(false);
    }
    setIsImageListLoading(false);
  };

  const getAllImages = async (pageNumber) => {
    try {
      setIsImageListLoading(true);
      const obj = {
        PageNr: pageNumber,
        NrOfRecPerPage: 50,
        FullSearch: "",
        UserId: localStorage?.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
        SearchList: [{ ClientId: localStorage?.clientId }],
      };
      const imgList = await fetchClientPicturesBoard2(obj);

      if (imgList.data.length > 0) {
        setImageList((prevImageList) => [...prevImageList, ...imgList.data]);
      } else {
        setHasMore(false);
      }
      setIsImageListLoading(false);
    } catch (err) {
      console.log("FetchClientPicturesBoard Error : ", err);
      setIsImageListLoading(false);
    }
  };

  async function openAlbumFromNotification() {
    try {
      const obj = {
        UserId: localStorage.userId,
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ ClientId: localStorage?.clientId, id: location.state.item?.custom3 }],
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
      };
      const albumListRes = await fetchClientBoardAlbums(obj);

      const sortedPictureList = albumListRes.data?.map((item) => {
        // Sort the pictureList array in descending order based on id
        const sortedPictures = item.pictureList.sort((a, b) => b.id - a.id);
        // Return the item with the sorted pictureList
        return {
          ...item,
          pictureList: sortedPictures,
        };
      });
      setAlbumPictures(sortedPictureList[0]?.pictureList);
      setViewAlbumModal(true);
    } catch (err) {
      console.log("Error while fetching album list ", err);
    }
  }

  useEffect(() => {
    if (location.state) {
      if (location.state.item?.custom3 > 0)
        openAlbumFromNotification();
    }
  }, [location.state])

  return (
    <>
      <ClientHeader screenName={"Picture Board"} />
      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={inputFile}
          style={{ display: "none" }}
          multiple
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleAlbumImageChange}
          ref={inputAlbumFile}
          style={{ display: "none" }}
          multiple
        />

        {albums.length > 0 && (
          <>
            <Typography variant="h6" gutterBottom>
              Albums
            </Typography>
            <Grid container spacing={2} style={{ overflowX: "auto" }}>
              {albums?.map((item) => {
                return (
                  <Grid item key={item.id}>
                    <Card
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleAlbumClick(item)}
                    >
                      <img
                        src={item?.pictureList[0]?.thumbFileUrl}
                        alt={item.albumName}
                        style={{
                          width: "150px",
                          height: "150px",
                          borderRadius: "20px",
                          marginBottom: "10px",
                        }}
                      />
                      <Typography variant="body1" component="div">
                        {item.albumName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {item.nrofPictures} Pictures
                      </Typography>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        <Divider sx={{ marginY: "20px" }} />
        <div style={{ margin: "15px" }}>
          <div
            className="flex-container-horizontal"
            style={{ justifyContent: "space-between" }}
          >
            <Tabs
              sx={{
                ".Mui-selected": {
                  color: localStorage?.clientAccentColor,
                },
              }}
              TabIndicatorProps={{
                sx: { backgroundColor: localStorage?.clientAccentColor },
              }}
              value={activeTab}
              onChange={(_, tab) => {
                setPage(1);
                setImageList([]);
                setActiveTab(tab);
              }}
            >
              {tabsArray.map((tab, index) => (
                <Tab
                  key={index}
                  value={tab}
                  label={tab}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    marginRight: "20px",
                  }}
                />
              ))}
            </Tabs>
          </div>
        </div>

        {isImageListLoading ? (
          <Loader />
        ) : (
          <>
            {imageList ? (
              <InfiniteScroll
                dataLength={imageList?.length}
                next={loadMoreData}
                hasMore={hasMore}
                loader={<Loader />}
              >
                <Grid container spacing={2}>
                  {imageList?.map((picture, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        key={`${index}`}
                        style={{
                          position: "relative", // Add relative positioning to the parent container
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          padding: "10px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={picture?.thumbFileUrl}
                          loading="lazy"
                          alt={`Picture ${index}`}
                          style={{
                            width: "430px",
                            height: "250px",
                            marginBottom: "10px",
                            filter: !picture?.approved && "brightness(30%)", // Reduce the brightness of the image
                          }}
                          onClick={() => handlePictureSelect(index)}
                        />
                        {!picture?.approved && (
                          <InfoIcon
                            style={{
                              position: "absolute", // Absolute positioning for the icon
                              bottom: 30, // Position it at the bottom
                              right: 15, // Position it at the right
                              backgroundColor: "rgba(0, 0, 0, 0.7)", // Dark semi-transparent background
                              color: "white", // White color for the icon
                              borderRadius: "50%", // Circular icon
                              fontSize: "24px", // Increase icon size
                              cursor: "pointer",
                              zIndex: 1, // Ensure the icon stays on top
                            }}
                            onClick={() => setAlertModalOpen(true)} // Handle the info click event
                          />
                        )}
                      </Grid>
                    );
                  })}

                  <div className="fixed-button-container">
                    <button
                      className="fixed-button"
                      style={{ background: localStorage?.clientAccentColor }}
                      onClick={handleAddImageClick}
                    >
                      <AddIcon />
                    </button>
                  </div>
                </Grid>
              </InfiniteScroll>
            ) : (
              <Typography
                variant="body1"
                component="div"
                style={{ padding: "20px" }}
              >
                No Pictures yet. Submit a Picture now to share it with your
                Company!!
              </Typography>
            )}
          </>
        )}
        <Dialog
          open={showAlbumPictures}
          onClose={() => setShowAlbumPictures(false)}
          maxWidth="md"
          fullWidth
        >
          {/* <DialogTitle>Create New Album</DialogTitle> */}
          <DialogContent>
            <Grid container spacing={2}>
              {albumPictures?.map((picture, index) => (
                <Grid item key={index} xs={6} sm={4} md={3}>
                  <Card
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={picture?.fileUrl}
                      alt={`Selected Picture ${index}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "20px",
                        marginBottom: "10px",
                      }}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowAlbumPictures(false)}
              style={{ background: BIBlue, color: "white" }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={submitPicture}
          onClose={() => {
            setUploadedImageLIst([]);
            setUploadedImageURL([]);
            setSubmitPicture(false);
          }}
          maxWidth="md"
          fullWidth
        >
          {/* <DialogTitle>Create New Album</DialogTitle> */}
          <DialogContent>
            <Typography variant="h6" gutterBottom sx={{ marginBottom: "20px" }}>
              Selected Pictures
            </Typography>
            <Grid container alignItems="center">
              {uploadedImageURL?.map((picture, index) => (
                <Grid item key={index} xs={6} sm={4} md={3}>
                  <Card
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={picture}
                      alt={`Selected Picture ${index}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => {
                        const updatedImageURL = [...uploadedImageURL];
                        updatedImageURL.splice(index, 1);
                        setUploadedImageURL(updatedImageURL);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
              <button
                className="plus-button"
                style={{ width: "150px", height: "150px", padding: "10px" }}
                onClick={() => inputFile.current.click()}
              >
                <AddIcon />
              </button>
            </Grid>
            <TextField
              label="Description"
              value={pictureDesc}
              onChange={(e) => setPictureDec(e.target.value)}
              fullWidth
              style={{ marginBottom: "10px", marginTop: "15px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => {
                setUploadedImageLIst([]);
                setUploadedImageURL([]);
                setSubmitPicture(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              style={{
                background: btnDisable ? BIGrey : BIBlue,
                color: "white",
              }}
              onClick={handleSubmitPicture}
              loading={loading}
              loadingPosition="start"
              disabled={btnDisable}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={submitAlbumPicture}
          onClose={() => {
            setUploadedAlbumImageLIst([]);
            setAlbumPictureDec("");
            setUploadedAlbumImageURL([]);
            setSubmitAlbumPicture(false);
          }}
          maxWidth="md"
          fullWidth
        >
          {/* <DialogTitle>Create New Album</DialogTitle> */}
          <DialogContent>
            {/* <Typography variant="h6" gutterBottom>
              Selected Pictures
            </Typography> */}
            <Grid container spacing={2} alignItems="center">
              {uploadedAlbumImageURL?.map((picture, index) => (
                <Grid item key={index} xs={6} sm={4} md={3}>
                  <Card
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={picture}
                      alt={`Selected Picture ${index}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "20px",
                        marginBottom: "10px",
                      }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        background: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => {
                        const updatedImageURL = [...uploadedAlbumImageURL];
                        updatedImageURL.splice(index, 1);
                        setUploadedAlbumImageURL(updatedImageURL);
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
              <button
                className="plus-button"
                style={{ width: "150px", height: "150px", padding: "10px" }}
                onClick={() => inputAlbumFile.current.click()}
              >
                <AddIcon />
              </button>
            </Grid>
            <TextField
              label="Description"
              value={albumPictureDec}
              onChange={(e) => setAlbumPictureDec(e.target.value)}
              fullWidth
              style={{ marginBottom: "10px", marginTop: "15px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => {
                setUploadedAlbumImageLIst([]);
                setAlbumPictureDec("");
                setUploadedAlbumImageURL([]);
                setSubmitAlbumPicture(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              fullWidth
              style={{
                background: btnDisable ? BIGrey : BIBlue,
                color: "white",
              }}
              onClick={handleSubmitAlbumPicture}
              loading={loading}
              loadingPosition="start"
              disabled={btnDisable}
            >
              Submit
            </LoadingButton>
          </DialogActions>
        </Dialog>

        {pictureViewerOpen && <PictureViewer
          isOpen={pictureViewerOpen}
          onClose={() => setPictureViewerOpen(false)}
          pictures={imageList?.map((pic) => ({
            src: pic?.fileUrl,
            alt: pic?.pictureDescription,
          }))}
          viewerIndex={pictureViewIndex}
        />}
        {albumPictureViewerOpen && <PictureViewer
          isOpen={albumPictureViewerOpen}
          onClose={() => {
            setAlbumPictureViewerOpen(false);
            // setAlbumPictures([]);
          }}
          pictures={albumPictures?.map((pic) => ({
            src: pic?.fileUrl || "",
            alt: pic?.pictureDescription || "",
          }))}
          viewerIndex={pictureViewIndex}
        />}
        {alertModalOpen && <AlertModal
          isOpen={alertModalOpen}
          onClose={() => setAlertModalOpen(false)}
          body={`This image will not be visible to others until ${localStorage?.clientName} approves it`}
        />}

        {viewAlbumModal && <Modal open={viewAlbumModal} onClose={() => setViewAlbumModal(false)}>
          <div className="modal-center timesheet-modal" style={{ width: 'auto' }}>
            <div className="modal-card" style={{ padding: '0px' }}>
              <div className="modal-card-body">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6">{albumPictures[0]?.albumName}</Typography>
                  <IconButton onClick={() => { setViewAlbumModal(false); setAlbumPictures([]); }}>
                    <Close />
                  </IconButton>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {albumPictures.map((image, index) => (
                    <img
                      key={index}
                      src={image?.thumbFileUrl}
                      alt={image?.pictureDescription}
                      height="100px"
                      width="100px"
                      style={{ marginRight: '5px', marginBottom: '5px' }} // Added margin bottom for wrapping effect
                      onClick={() => handleAlbumPictureSelect(index)}
                    />
                  ))}
                </div>
              </div>
              <div style={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: '#fff', // Background to avoid content overlap
                padding: '10px',
                borderTop: '1px solid #ddd',
                marginRight: '15px',
                display: 'flex',
                justifyContent: 'flex-end' // Align button to the right side
              }}>
                <IconButton
                  onClick={() => {
                    // Handle icon button action
                    // setSubmitAlbumPicture(true);
                    handleAddAlbumImageClick();
                  }}
                  style={{
                    backgroundColor: localStorage.clientAccentColor, // Button background color
                    color: '#fff',              // Icon color
                    borderRadius: '50%',         // Make it round
                    padding: '10px'              // Adjust padding for larger clickable area
                  }}
                >
                  <Add /> {/* Replace 'Add' with any MUI icon you prefer */}
                </IconButton>
              </div>
            </div>
          </div>
        </Modal>}

      </div>
    </>
  );
}
