import React, { useState, useEffect } from "react";
import { Container, Stack } from "@mui/material";
import ProfileHeader from "../../components/profile/ProfileHeader";
import AboutSection from "../../components/profile/AboutSection";
import {
  deleteCompanyTrades,
  fetchCompanies,
  fetchCompaniesProducts,
  fetchCompaniesTrades,
  fetchCompanyAddress,
  saveCompanies2,
  saveCompanyProduct,
} from "../../api/CompanyApi";
import ListSection from "../../components/profile/ListSection";
import { fetchSitesPictures, fetchSiteCompanies2 } from "../../api/SitesApi";
import defaultProjectProfileThumbnail from "../../assets/images/project-profile-placeholder-thumbnail.png";
import WorkIcon from "@mui/icons-material/Work";
import BuildIcon from "@mui/icons-material/Build";
import Placeholder from "../../components/Placeholder";
import { useHistory } from "react-router-dom";
import {
  deleteProductTrade,
  fetchProducts,
  fetchProjectsForCompany,
  saveProducts,
} from "../../api/ProductsApi";
import { Button } from "@material-ui/core";
import { LocationOn as MapMarkerIcon } from "@material-ui/icons";
import { fetchUserWorkHistory, fetchUserWorkHistory2 } from "../../api/UserApi";
import Loader from "../../components/Loader";
/*
Company Profile Page
	- About
	- Sites
	- Manufacturers (coming soon)
	- Products (coming soon)
*/
export default function CompanyProfile(props) {
  const [companyInfo, setCompanyInfo] = useState({});
  const [activeTab, setActiveTab] = useState("Projects");
  const [companyTrades, setCompanyTrades] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProductEmpty, setIsProductEmpty] = useState(false);
  const [isSiteEmpty, setIsSiteEmpty] = useState(false);
  const [companyAddress, setCompanyAddress] = useState([]);
  const [workingHistory, setWorkingHistory] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [getUpdatedData, setGetUpdatedData] = useState(false);
  const [isProjectEmpty, setIsProjectEmpty] = useState(false);
  // On load, retrieve company info from API
  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      try {
        setShowLoader(true);
        await fetchCompaniesDetails();
        await getCompanyAddressList();
        await getCompanyTradeList();
        await fetchUserWorkHistoryDetails();
        setShowLoader(false);
      } catch (err) {
        setShowLoader(false);
        console.log("Error while recving the compaines data ::", err);
      }
    };
    if (props?.companyId) {
      getData();
    }
  }, [props?.companyId]);

  const fetchCompaniesDetails = () => {
    try {
      fetchCompanies({ SearchList: [{ id: props?.companyId }] }).then(
        (companyRes) => {
          // if (companyRes?.data?.length > 0)
          setCompanyInfo(companyRes?.data[0]);
        }
      );
    } catch (err) {
      console.log("Error while fetching compaines details :: ", err);
    }
  };

  const fetchUserWorkHistoryDetails = async () => {
    try {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        SearchList: [{ Id: 0, UserId: Number(localStorage.userId) }],
      };
      const res = await fetchUserWorkHistory(workHistoryObj);
      setWorkingHistory(res);
    } catch (err) {
      console.log("Error while fetching user work history details :: ", err);
    }
  };
  const fetchProjectsForACompany = async () => {
    const obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: 1,
      IncludeRecordNr: true,
      TypeOfObjectReturned: "",
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          companyId: props?.companyId,
        },
      ],
      SortList: [{}],
    };
    try {
      // setShowLoader(true);
      const res = await fetchProjectsForCompany(obj);
      setIsProjectEmpty(res?.data?.length === 0 ? true : false);
      setShowLoader(false);
      return res;
    } catch (err) {
      setShowLoader(false);
      console.log("error while fetching projects for a company. ", err);
    }
  };
  const getCompanyAddressList = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [{ companyId: props?.companyId }],
      };
      await fetchCompanyAddress(obj)
        .then((res) => {
          setCompanyAddress(res ? res : []);
        })
        .catch((err) => {
          console.log("Error while getting comapny address list:-", err);
        });
    } catch (err) {
      console.log("Error while getting comapny address list:-", err);
    }
  };

  const getCompanyTradeList = async () => {
    try {
      const reqObj = {
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        SearchList: [{ CompanyId: props?.companyId }],
      };
      fetchCompaniesTrades(reqObj)
        .then((companyTrades) => {
          setCompanyTrades(companyTrades);
        })
        .catch((err) => {
          console.log("Error while getting trade list ::", err);
        });
    } catch (error) {
      console.log("Error while getting trade list ::", error);
    }
  };

  async function getCompanySites() {
    const res = await fetchSiteCompanies2({
      FullSearch: "",
      SearchList: [{ CompanyId: props?.companyId }],
      userId: localStorage.userId,
      FetchAllowedRecordsOnly: true,
    });

    // Overwrite API response res.data with an updated array where the duplicate items are omitted
    let uniqueSites = [];
    res.data.forEach((project) => {
      let alreadyInArray = false;
      uniqueSites.forEach((uniqueSite) => {
        if (project.siteId === uniqueSite.siteId) alreadyInArray = true;
      });

      if (!alreadyInArray) uniqueSites.push(project);
    });

    // Retrieve Site pictures and use the first one as the list thumbnail
    // If there is no pictures for a Site, use the default image for Sites
    await Promise.all(
      uniqueSites.map((project) =>
        fetchSitesPictures({ SearchList: [{ SiteId: project.siteId }] }).then(
          (res) => {
            if (res.length > 0) project.image = res[0].thumbImageUrl;
            else project.image = defaultProjectProfileThumbnail;
          }
        )
      )
    );

    res.data = uniqueSites;
    setIsSiteEmpty(uniqueSites?.length === 0 ? true : false);
    return res;
  }

  const onDataSave = (data) => {
    if (data?.addressSave) {
      getCompanyAddressList();
    } else {
      fetchCompaniesDetails();
    }
  };

  async function getCompanyProductsData() {
    try {
      const obj = {
        FullSearch: "",
        SearchList: [{ CompanyId: props?.companyId }],
        userId: Number(localStorage.userId),
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: true,
      };
      const companyProducts = await fetchCompaniesProducts(obj);
      setIsProductEmpty(companyProducts?.data?.length === 0 ? true : false);

      if (companyProducts?.data?.length === 0) {
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
      return companyProducts;
    } catch (Error) {
      console.log("GET SITE_COMPANIES-2 [ERROR]::", Error);
    }
  }

  async function onProductSave(productId, goToProductPage) {
    try {
      setIsLoading(true);
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            ProductId: productId,
            CompanyId: props?.companyId,
            ModifiedBy: Number(localStorage.userId),
            RecordStatusId: 1,
          },
        ],
      };
      await saveCompanyProduct(obj);
      setIsProductEmpty(false);
      setGetUpdatedData(true);
      setIsLoading(false);
      if (goToProductPage) {
        history.push(`/product/${productId}`);
        return;
      }
    } catch (ERROR) {
      console.log("GET SITE_Products [ERROR]::", ERROR);
      setIsLoading(false);
    }
  }

  const onMenuOptionClick = (data) => {
    console.log(":DDD", data);
  };

  const deleteTrade = async (tradeId) => {
    try {
      if (!tradeId) return;
      const deleteObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordError: true,
        SoftDelete: true,
        DeleteList: [{ TradeId: tradeId, CompanyId: props?.companyId }],
      };
      await deleteCompanyTrades(deleteObj)
        .then(async () => {
          await getCompanyTradeList();
        })
        .catch((err) => {
          console.log(
            "Error while deleting the trade from the company proifle ::",
            err
          );
        });
    } catch (error) {
      console.log(
        "Error while deleting the trade from the company proifle ::",
        error
      );
    }
  };

  if (showLoader) {
    return (
      <div className="profile-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="page">
      <div className="page-content">
        <Stack spacing={2} style={{ margin: "16px 0px" }}>
          <ProfileHeader
            title={companyInfo?.name}
            subtitle="Company"
            tabs={[
              // "sites",
              "Projects",
              "Products",
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            menuOptions={["Send in Chat", "Share"]}
            onMenuOptionClick={onMenuOptionClick}
            shareDetails={{ type: "CompanyInfo", id: props?.companyId }}
            from="Company"
          />

          <Stack direction="row" spacing={2}>
            <div style={{ flex: 1 }}>
              <AboutSection
                withCard
                content={{
                  website: companyInfo?.webSite,
                  phone: companyInfo?.phone,
                  formattedAddress: {
                    address: companyInfo?.address,
                    city: companyInfo?.city,
                    province: companyInfo?.province,
                    postalCode: companyInfo?.postalCode,
                    country: companyInfo?.country,
                  },
                }}
                multipleAddress={companyAddress}
                Id={companyInfo?.id}
                name={companyInfo?.name}
                trades={companyTrades}
                manufacturer_and_company_id={props?.companyId ?? ""}
                onDataSave={onDataSave}
                deleteTrade={deleteTrade}
                aboutSectionType={"company"}
              />
            </div>

            <div style={{ flex: 2 }}>
              {/* {activeTab === "sites" ? (
                <ListSection
                  getData={getCompanySites}
                  itemIdKey="siteId"
                  itemImgKey="sitePicture"
                  itemNameKey="siteName"
                  itemSecondaryKey="siteAddress"
                  itemRecordType="site"
                  searchPlaceholder="Search this Company's Sites"
                  searchTopDescription="The Sites that this Company has worked at"
                  buttonLabel="View on map"
                  companySites
                  workingHistory={workingHistory}
                  onButtonClick={() => {
                    navigate.push("/sites");
                  }}
                  emptyData={isSiteEmpty}
                  NOResultIcon={<MapMarkerIcon />}
                  NoResultText="No information for this company."
                  NoResultText2="Know any information? Add it here for builderbucks"
                />
              ) : (
                <></>
              )} */}
              {activeTab === "Projects" ? (
                <ListSection
                  getData={fetchProjectsForACompany}
                  itemIdKey="projectId"
                  itemImgKey="thumbUrl"
                  itemNameKey="projectName"
                  itemSecondaryKey="projectAddress"
                  itemRecordType="projects"
                  searchPlaceholder="Search this Company's Projects"
                  searchTopDescription="The Projects that this Company has worked at"
                  buttonLabel="Add Project"
                  companySites
                  workingHistory={workingHistory}
                  onButtonClick={() => {
                    history.push({
                      pathname: "/new-map",
                      state: {
                        from: "companyProfile",
                        selectedCompany: companyInfo,
                      },
                    });
                  }}
                  emptyData={isProjectEmpty}
                  NOResultIcon={<MapMarkerIcon />}
                  NoResultText="No information for this company."
                  NoResultText2="Know any information? Add it here for builderbucks"
                />
              ) : (
                <></>
              )}

              {/* {
								(activeTab === "manufacturers")
									? <Placeholder icon={<WorkIcon />} text="Coming soon!" />
									: <></>
							}*/}

              {activeTab === "Products" ? (
                <ListSection
                  getData={getCompanyProductsData}
                  itemIdKey="productId"
                  itemNameKey="productName"
                  categoryIdKey="manufacturerId"
                  categoryNameKey="manufacturerName"
                  itemRecordType="product"
                  categoryRecordType="manufacturer"
                  searchPlaceholder="Search this  Company's  Products"
                  addButtonLabel="Add Product"
                  modalTitle="Add Product to Company"
                  onProductSave={onProductSave}
                  isLoading={showLoader}
                  searchTopDescription={
                    "The Products that this Company has used"
                  }
                  emptyData={isProductEmpty}
                  NoResultBtn="Add Product"
                  NOResultIcon={<BuildIcon />}
                  NoResultText="No information for this company."
                  NoResultText2="Know any information? Add it here for builderbucks"
                  getUpdatedData={getUpdatedData}
                  changeGetUpdateDataValue={() => {
                    setGetUpdatedData(false);
                  }}
                  showManufactureNameInNewAddProduct={true}
                />
              ) : (
                <></>
              )}
            </div>
          </Stack>
        </Stack>
      </div>
    </div>
  );
}
