import React, { useState, useCallback, useEffect } from "react";
import "../../assets/css/Profile.css";
import UserCertificationsCard from "./sections/UserCertificationCard";
import ProfileHeaderCard from "./ProfileHeaderCard";
import UserExperienceCard from "./sections/UserExperienceCard";
import UserContactInfoCard from "./sections/UserContactInfoCard";
import UserProductCard from "./sections/UserProductCard";

const UserProfile = (props) => {
  const [showLoader, setShowLoader] = useState(true);
  const [isContactList, setIsContactList] = useState(false);
  const [isContactListEmpty, setIsContactListEmpty] = useState(false);
  const [isUserExperienceListEmpty, setIsUserExperienceListEmpty] =
    useState(false);
  const [isUserCertificationsEmpty, setIsUserCertificationsEmpty] =
    useState(false);
  const [isUserProductEmpty, setIsUserProductEmpty] = useState(false);
  const [isUserProfileData, setIsUserProfileData] = useState({});

  return (
    <>
      <div className="page">
        <ProfileHeaderCard
          contactId={Number(props.contactId)}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          setIsContactList={(value) => setIsContactList(value)}
          setIsUserProfileData={(value) => setIsUserProfileData(value)}
        />
        {isContactList ? (
          <>
            {isUserExperienceListEmpty &&
            isUserCertificationsEmpty &&
            isUserProductEmpty ? (
              <div
                className="page-content"
                style={{
                  paddingTop: "50px",
                  alignItems: "center",
                  color: "GrayText",
                }}
              >
                <div className="resp-container-horizontal">
                  {isUserProfileData.firstName} {isUserProfileData.lastName} has
                  not added any information to their Profile yet
                </div>
              </div>
            ) : (
              <div className="page-content" style={{ paddingTop: "0px" }}>
                <div className="resp-container-horizontal">
                  <div
                    className={
                      !isContactListEmpty ? "flex-container-vertical" : ""
                    }
                    style={!isContactListEmpty ? { flex: 1 } : {}}
                  >
                    <UserContactInfoCard
                      contactId={Number(props.contactId)}
                      showLoader={showLoader}
                      setIsContactListEmpty={(value) =>
                        setIsContactListEmpty(value)
                      }
                    />
                  </div>

                  <div className="flex-container-vertical" style={{ flex: 2 }}>
                    <UserExperienceCard
                      contactId={Number(props.contactId)}
                      showLoader={showLoader}
                      setIsUserExperienceListEmpty={(value) =>
                        setIsUserExperienceListEmpty(value)
                      }
                    />
                    <UserCertificationsCard
                      contactId={props.contactId}
                      showLoader={showLoader}
                      setIsUserCertificationsEmpty={(value) =>
                        setIsUserCertificationsEmpty(value)
                      }
                    />
                    <UserProductCard
                      contactId={props.contactId}
                      showLoader={showLoader}
                      setIsUserProductEmpty={(value) =>
                        setIsUserProductEmpty(value)
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default UserProfile;
