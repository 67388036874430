import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Checkbox, FormControlLabel, IconButton, InputAdornment, Modal, Stack, TextField, Typography } from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import "./ClientTimesheets.css"
import { Search as SearchIcon } from "@mui/icons-material";
import { fetchClientEmployee } from "../../../api/ClientApi";
import Loader from "../../../components/Loader";
import { BIGrey } from "../../../assets/buildidColors";

export default function IndividualEmployeeslist({ show, handleClose, setSelectedemp, selectedEmployees }) {

    const [emplist, setEmplist] = useState([]);
    const debounceTimeout = useRef(null);

    const [searchTerm, setSearchTerm] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [selectedEmployeeList, setSelectedEmployeeList] = useState(selectedEmployees ?? []);

    useEffect(() => {
        getEmployeeList(searchTerm);
    }, [])


    const getEmployeeList = async (searchTerm) => {
        try {
            const obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: searchTerm,
                UserId: localStorage.userId,
                IncludeRecordNr: true,
                FetchAllowedRecordsOnly: false,
                SearchList: [{ ClientId: localStorage.clientId }],
            };
            const res = await fetchClientEmployee(obj);
            let resData = res?.data ?? [];
            let getList = resData?.map((item) => {
                return {
                    ...item,
                    employeefullName: item?.firstName + " " + item?.lastName,
                    employeeId: item?.id,
                    isSelected: selectedEmployeeList.find(obj => obj.employeeId === item?.id) ? true : false
                }
            })
            setEmplist(getList);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Error while getting employee list :: ", error);
        }
    }

    const selectEmployee = (item) => {
        let getList = emplist?.map((emp) => {
            if (emp?.employeeId === item?.employeeId) {
                return {
                    ...emp,
                    isSelected: !item?.isSelected
                }
            } else {
                return {
                    ...emp
                }
            }
        })
        setEmplist(getList);

        if (item?.isSelected) {
            return setSelectedEmployeeList((preValue) => preValue?.filter((emp) => emp?.employeeId !== item?.employeeId));
        } else {
            return setSelectedEmployeeList((preValue) => [...preValue, { ...item, isSelected: true }]);
        }

    }


    const onSelectemp = () => {
        let getSelectedemp = selectedEmployeeList.filter((item) => item.isSelected);
        setSelectedemp(getSelectedemp ?? []);
        handleClose();
    }

    const getSelectedEmployeeCount = () => {
        return selectedEmployeeList?.length === 0 ? null : selectedEmployeeList?.length;
    }

    if (!show) {
        return null;
    }


    return (
        <>
            <Modal
                open={show}
                onClose={handleClose}>
                <div className="modal-center">
                    <div className="modal-card crew-modal" style={{ width: "600px" }}>
                        <div className="modal-card-header-buttons">
                            <Typography></Typography>
                            <IconButton
                                onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack gap={1}>
                            <Typography fontSize={19} fontWeight={600}>Select Employees</Typography>
                            <TextField
                                value={searchTerm}
                                onChange={async (event) => {
                                    setIsLoading(true);
                                    const searchValue = event.target.value;
                                    setSearchTerm(searchValue)
                                    if (debounceTimeout.current) {
                                        clearTimeout(debounceTimeout.current);
                                    }
                                    debounceTimeout.current = setTimeout(() => {
                                        getEmployeeList(searchValue);
                                    }, 1000);
                                }}
                                placeholder={"Search for an employee"}
                                color="primary"
                                size={"small"}
                                className="user-search"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Card style={{ boxShadow: "none", border: "1px solid #cccccc", marginTop: "15px" }}>
                            {
                                !isLoading ?
                                    (
                                        <>
                                            {
                                                emplist?.length > 0 ?
                                                    emplist?.map((item) => (
                                                        <Stack flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }} onClick={() => selectEmployee(item)} justifyContent={'start'} gap={0}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={item?.isSelected}
                                                                        onChange={() => selectEmployee(item)}
                                                                    // name="Offensive_Inappropriate"
                                                                    />
                                                                }
                                                                style={{ margin: '0px' }}
                                                            />
                                                            <Typography>{item?.employeefullName}</Typography>
                                                        </Stack>
                                                    ))
                                                    :
                                                    (
                                                        <div style={{ padding: '10px', textAlign: 'center', color: BIGrey }}>
                                                            No such employee found.
                                                        </div>
                                                    )
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <div style={{ textAlign: 'center' }}>
                                                <Loader />
                                            </div>
                                        </>
                                    )
                            }

                        </Card>
                        <Button onClick={onSelectemp} color="primary" fullWidth variant="contained" sx={{ marginTop: 2 }} disabled={!getSelectedEmployeeCount()}>
                            Select {getSelectedEmployeeCount() ?? ""}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )

}