import React from "react";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import { Close } from "@material-ui/icons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  bgcolor: "background.paper",
  padding: "25px",
  borderRadius: "10px",
};

const tableStyle = {
  width: "100%",
  borderCollapse: "collapse",
};

const headerCellStyle = {
  borderBottom: "1px solid #ccc",
  padding: "8px",
  textAlign: "left",
  fontWeight: "bold",
};

const cellStyle = {
  borderBottom: "1px solid #ccc",
  padding: "8px",
  textAlign: "left",
};

const subtotalStyle = {
  borderBottom: "1px solid #ccc",
  padding: "8px",
  textAlign: "left",
  color: localStorage?.clientAccentColor,
};

const ClientSiteEstimateViewModal = ({ data, close, open }) => {
  if (!data || !data.siteEstimationList) return null;

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "5px",
          }}
        >
          <IconButton
            color="primary"
            onClick={close}
            style={{ padding: "0px" }}
          >
            <Close />
          </IconButton>
        </div>

        <Typography id="modal-modal-title" variant="h6" component="h2">
          Job
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {data.clientJobCode} {data.clientJobName}
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 3 }}
        >
          Area/Item
        </Typography>
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th
                  style={{
                    ...headerCellStyle,
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    ...headerCellStyle,
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  Days
                </th>
                <th
                  style={{
                    ...headerCellStyle,
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  Employees
                </th>
                <th
                  style={{
                    ...headerCellStyle,
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                  }}
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {data.siteEstimationList.map((item, index) => (
                <tr key={index}>
                  <td style={cellStyle}>{item.description}</td>
                  <td style={cellStyle}>{item.days.toFixed(2)}</td>
                  <td style={cellStyle}>{item.employees}</td>
                  <td style={subtotalStyle}>
                    {parseInt(item.subTotal).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
    </Modal>
  );
};

export default ClientSiteEstimateViewModal;
