import { Autocomplete, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BIGrey, BILightGrey } from "../../../assets/buildidColors";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import Close from "@material-ui/icons/Close";
import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  fetchClientEmployees,
  saveClientSARActions,
  saveClientSARActionsPictures,
} from "../../../api/ClientApi";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton } from "@material-ui/core";

function AddSARActionsModal(props) {
  const { open, onClose, data, edit, clientJobsData, reLoadData } = props;

  const [buttonDisable, setButtonDisable] = useState(true);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [uploadedImageURL, setUploadedImageURL] = useState([]);
  const [uploadedImageList, setUploadedImageList] = useState([]);
  const [clientJobList, setClientJobList] = useState([]);
  const [description, setDescription] = useState("");
  const [deleteImageList, setDeleteImageList] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const hint = useRef("");
  const imgRef = useRef(null);

  useEffect(() => {
    if (description == "" || title == "" || inputValue == "") {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [description, title, selectedValue]);

  useEffect(() => {
    fetchEmployee();
  }, [inputValue]);

  const handleAddImage = () => {
    imgRef.current.click();
  };

  const resetData = () => {
    setDescription("");
    setTitle("");
  };

  const fetchEmployee = async () => {
    const payload = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: inputValue,
      UserId: localStorage?.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          ClientId: localStorage?.clientId,
        },
      ],
    };

    const clientJobRes = await fetchClientEmployees(payload);
    let temp = [];
    clientJobRes?.data?.map((item) => {
      if (
        item.clientId != localStorage.clientId ||
        item.id != localStorage.clientId
      ) {
        temp.push(item);
      }
    });
    setClientJobList(temp);
  };

  const handleCreateReport = async () => {
    setLoading(true);
    try {
      const payload = {
        ReturnRecordId: true,
        ReturnRecordError: true,
        UserId: localStorage?.userId,
        SaveList: [
          {
            Id: 0,
            SARId: data?.id,
            EmployeeId: selectedValue.emplaoyeeId,
            Title: title,
            Description: description,
            DueDate: moment(date).format("YYYY-MM-DD"),
            ModifiedBy: localStorage?.userId,
          },
        ],
      };

      const res = await saveClientSARActions(payload);

      if (res.message === "OK") {
        if (uploadedImageList.length > 0) {
          const uploadPromises = Array.from(uploadedImageList)?.map(
            async (picture) => {
              const formData = new FormData();
              formData.append("Id", picture.id ?? 0);
              formData.append("SARActionId", res.data[0]?.objData?.id);
              formData.append("ModifiedBy", localStorage.userId);
              formData.append("FileName", picture);
              await saveClientSARActionsPictures(formData);
            }
          );
          await Promise.all(uploadPromises);
          reLoadData();
        } else {
          reLoadData();
        }
        resetData();
        onClose();
      }
    } catch (err) {
      console.error("Error:", err);
      alert(`An error occurred: ${err.message || "Unable to save data"}`);
    }
    setLoading(false);
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    setUploadedImageList(files);
    const fileArray = Array.from(files)?.map((file) =>
      URL.createObjectURL(file)
    );
    setUploadedImageURL((prevImages) => prevImages.concat(fileArray));
    event.target.value = null;
  };

  const handleRemoveImage = (picture, index) => {
    const deleteList = deleteImageList;
    if (picture.id) {
      deleteList.push({ id: picture?.id });
    }
    setDeleteImageList(deleteList);
    setUploadedImageURL(uploadedImageURL.filter((_, i) => i !== index));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          resetData();
          onClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              minHeight: "400px",
              width: "500px",
            }}
            className="modal-card">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}>
              <IconButton
                onClick={() => {
                  resetData();
                  onClose();
                }}
                style={{ padding: "5px" }}>
                <Close
                  style={{
                    color: localStorage.clientAccentColor,
                  }}
                />
              </IconButton>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={imgRef}
              style={{ display: "none" }}
              multiple
            />

            <div
              style={{
                marginTop: "10px",
              }}>
              <Typography
                style={{
                  color: BIGrey,
                }}>
                Employee
              </Typography>
              <Autocomplete
                sx={{
                  // border: "1px solid blue",
                  "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                size="small"
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "10px",
                }}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === "Tab") {
                    if (hint.current) {
                      setInputValue(hint.current);
                      event.preventDefault();
                    }
                  }
                }}
                onBlur={() => {
                  hint.current = "";
                }}
                disablePortal
                inputValue={inputValue}
                filterOptions={(options, state) => {
                  const displayOptions = options.filter(
                    (option) =>
                      option.firstName
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase().trim()) ||
                      (option.lastName &&
                        option.lastName
                          .toLowerCase()
                          .includes(state.inputValue.toLowerCase().trim()))
                  );

                  return displayOptions;
                }}
                id="combo-box-hint-demo"
                options={clientJobList}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography>{`${option?.firstName} ${option?.lastName}`}</Typography>
                  </Box>
                )}
                onChange={(event, newValue) => {
                  console.log("newValue", newValue);
                  setSelectedValue({
                    emplayeeName: `${newValue?.firstName} ${newValue?.lastName}`,
                    emplaoyeeId: newValue?.id,
                  });
                  if (newValue) {
                    setInputValue(
                      `${newValue?.firstName} ${newValue?.lastName}`
                    ); // Update the input value to the selected label
                  } else {
                    setInputValue("");
                  }
                }}
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        opacity: 0.5,
                        left: 14,
                        top: 16,
                      }}>
                      {hint.current}
                    </Typography>
                    <TextField
                      {...params}
                      onFocus={() => setInputValue("")}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setInputValue(newValue);
                        const matchingOption = clientJobList.find((option) =>
                          option?.firstName.startsWith(newValue)
                        );

                        if (newValue && matchingOption) {
                          hint.current = matchingOption.firstName;
                        } else {
                          hint.current = "";
                        }
                      }}
                    />
                  </Box>
                )}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
              }}>
              <div
                style={{
                  marginTop: "10px",
                }}>
                <Typography
                  style={{
                    color: BIGrey,
                  }}>
                  Title
                </Typography>
                <TextField
                  className="date-picker"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  size="small"
                  fullWidth
                  inputVariant={"outlined"}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}>
                <Typography
                  style={{
                    color: BIGrey,
                  }}>
                  Description
                </Typography>
                <TextField
                  className="date-picker"
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  size="small"
                  fullWidth
                  inputVariant={"outlined"}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}>
                <Typography
                  style={{
                    color: BIGrey,
                  }}>
                  Date
                </Typography>
                <KeyboardDatePicker
                  onChange={(e) => {
                    console.log(moment(e).format());
                    setDate(moment(e).format());
                  }}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                    style: {
                      backgroundColor: "#f2f2f2",
                      borderRadius: "10px",
                      height: "40px",
                      padding: "  0 10px",
                    },
                  }}
                  onClick={() => {
                    setDatePickerOpen(true);
                  }}
                  onClose={() => {
                    setDatePickerOpen(false);
                  }}
                  open={datePickerOpen}
                  value={date}
                  className="date-picker"
                  inputVariant={"outlined"}
                  placeholder="MM/DD/YYYY"
                  format="MM/DD/YYYY"
                  fullWidth
                  size="small"
                  autoOk={true}
                  KeyboardButtonProps={{
                    style: { color: localStorage.clientAccentColor }, // Change icon color here
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  gap: "10px",
                }}>
                <div
                  onClick={handleAddImage}
                  style={{
                    cursor: "pointer",
                    height: "100px",
                    width: "100px",
                    border: "2px solid lightgrey",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <AddIcon />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "400px",
                    overflow: "scroll",
                  }}>
                  {uploadedImageURL?.map((picture, index) => (
                    <div
                      style={{
                        position: "relative",
                      }}>
                      <img
                        src={picture?.fileUrl || picture}
                        alt={"NO data"}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                        }}
                      />
                      <IconButton
                        onClick={() => handleRemoveImage(picture, index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "5px",
                          backgroundColor: BILightGrey,
                          color: "white",
                        }}>
                        <CloseIcon
                          style={{
                            color: localStorage?.clientAccentColor,
                            fontSize: "16px",
                          }}
                        />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}>
                <LoadingButton
                  style={{
                    textTransform: "none",
                    backgroundColor: !buttonDisable
                      ? localStorage.clientAccentColor
                      : BILightGrey,
                    boxShadow: "none",
                    color: "white",
                  }}
                  onClick={() => {
                    handleCreateReport();
                  }}
                  loading={loading}
                  disabled={buttonDisable}
                  fullWidth
                  loadingPosition="start">
                  save
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddSARActionsModal;
