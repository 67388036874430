import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { Avatar, IconButton, InputAdornment, TextField } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';

export const ClientHeader = ({ screenName, setClientSearchTerm, clientSearchTerm }) => {

    const { pathname } = useLocation();
    const textFieldRef = useRef();

    const handleSearchTxt = (e) => {
        setClientSearchTerm(e.target.value);
    }

    const searchContent = (e) => {
        if (e.key === "Enter") {
            setClientSearchTerm(clientSearchTerm);
        }
    };

    const handleClearText = () => {
        setClientSearchTerm("");
        if (textFieldRef.current) {
            textFieldRef.current.blur();
        }
    };
    return (
        <div style={{ marginTop: '-48px' }} className={navigator.userAgent.indexOf("Chrome") !== -1 ? 'topbar-div-width-chrome' : 'topbar-div-width'}>
            <section className="client-header">
                <div style={{ background: localStorage.getItem("clientAccentColor"), paddingLeft: '10px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        { pathname === "/client" && <Avatar alt={screenName} src={localStorage?.ClientLogo} style={{ height: "32px", width: "32px" }} />}
                        <h1 className="client-heading">{screenName}</h1>
                    </div>
                    {pathname === "/client" || pathname === "/client-take-5" || pathname==="/client-picture-board" || pathname === "/client-actions" || pathname === "/client-expenses" || pathname === "/client-notifications" ? <></> : <div style={{ marginLeft: 'auto', marginRight: '60px' }}>
                        <TextField
                            ref={textFieldRef}
                            style={{ color: 'white', marginBottom: '8px' }}
                            variant="standard"
                            label="Search..."
                            sx={{
                                '& .MuiInputLabel-root': { color: 'white' }, // Default label color
                                '& .MuiInputLabel-root.Mui-focused': { color: 'white' }, // Label color when focused
                                '& .MuiInputBase-input': { color: 'white' }, // Input text color
                                '& .MuiInput-underline:before': { borderBottomColor: 'white' }, // Underline color when not focused
                                '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'white' }, // Underline color on hover
                                '& .MuiInput-underline:after': { borderBottomColor: 'white' } // Underline color when focused
                            }}
                            value={clientSearchTerm}
                            onChange={handleSearchTxt}
                            onKeyDown={(e) => searchContent(e)}

                            InputProps={clientSearchTerm && {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClearText}
                                            edge="end"
                                            style={{ color: 'white' }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>}
                </div>
            </section>
        </div>
    )
}
