import React, { useState } from "react";
import { Modal, IconButton, Button } from "@material-ui/core";
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import InfoIcon from "@mui/icons-material/Info";
import TimesheetCard from "../../components/TimesheetCard";
import "../../assets/css/Timesheets.css";
import moment from "moment/moment";
import {
  deleteTimeSheets,
  deleteTimeSheetsEntries,
  deleteTimeSheetsPictures,
  deleteTimesheeetGoals,
  fetchGoals,
  saveTimeSheet,
  saveTimeSheetsEntries,
  saveTimeSheetsGoals,
  saveTimeSheetsPictures,
} from "../../api/TimesheetApi";
import AlertModal from "../../components/AlertModal";
import PromptModal from "../../components/PromptModal";
import Loader from "../../components/Loader";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fetchUserWorkHistory } from "../../api/UserApi";
import { useEffect } from "react";
import { BIRed } from "../../assets/buildidColors";
import JoblistModal from "./JoblistModal";
import {
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import GoalsList from "./GoalsList";
import { set } from "lodash";

const labelArray = [
  {
    key: 1,
    value: "km",
  },
  {
    key: 2,
    value: "mi",
  },
];

const parseMyDate = (date) => {
  if (date == "") date = new Date();
  if (!(date instanceof Date)) date = new Date(date);
  return date;
};
function AddTimeSheetModal(props) {
  const today = moment();
  const [details, setDetails] = useState(null);
  const [entriesData, setEntriesData] = useState([]);
  const isHoursEmpty = entriesData.filter((en) => en.hours == 0);
  const [timesheetDate, setTimeSheetDate] = useState(
    details ? parseMyDate(details.timesheetDate) : new Date()
  );
  const [userSites, setUserSites] = useState([]);
  const [showJoblist, setShowjoblist] = useState(false);
  const [deletedEntries, setDeletedEntries] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [modified, setModified] = useState(false);
  const [roleIndex, setRoleIndex] = useState(1);
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [goalList, setGoalList] = useState([]);

  const [entiresDataIndex, setEntiresDataIndex] = useState("");
  const [deletedGoalsCategories, setDeletedGoalsCategories] = useState([]);
  const [goalsDeleted, setGoalsDeleted] = useState(false);
  const [getError, setGetError] = useState(false);
  const [isGoalHoursExceed, setIsGoalHoursExceed] = useState(false);
  const [deletedImages, setDeletedImages] = useState([]);
  const [isTimesheetHoursValid, setIsTimesheetHoursValid] = useState(false);
  const [goalIndex, setGoalIndex] = useState(0);
  const [selectedRowGoal, setSelectedRowGoal] = useState("");
  const [jobItemIndex, setJobItemIndex] = useState(0);
  const [loadingBtn, setloadingBtn] = useState(false);
  const [temporaryEntriesData, setTemporaryEntriesData] = useState([]);
  const [deleteTimeSheetPrompt, setDeleteTimeSheetPrompt] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    if (props?.isOpen) {
      getUserList();
      getGoallist();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props?.clickFrom === "EditTimesheet") {
      if (props.isOpen) {
        setDetails(props?.editTimeSheetData);
        setTotalHours(props?.editTimeSheetData?.totalHours ?? 0);
        setTotalDistance(props?.editTimeSheetData?.totalDistance ?? 0);
        const timeshetEntries1 = props?.timesheetEntriesData;
        setEntriesData(timeshetEntries1 ?? []);
        const tempUnit = timeshetEntries1
          ? timeshetEntries1[0]?.unitOfDistance
          : "";
        setRoleIndex(tempUnit != "" ? (tempUnit == "mi" ? 2 : 1) : 1);
        setTimeSheetDate(
          props?.editTimeSheetData
            ? parseMyDate(props?.editTimeSheetData?.timesheetDate)
            : new Date()
        );
        setTemporaryEntriesData(timeshetEntries1 ?? []);
        setIsTimesheetHoursValid(
          props?.editTimeSheetData?.totalHours ? true : false
        );
      }
    }
  }, [props?.clickFrom, props.isOpen]);

  const removeDuplicates = (originalArray, prop) => {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };

  const getUserList = async () => {
    try {
      const workHistoryObj = {
        // PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        SearchList: [{ Id: 0, UserId: Number(localStorage.userId) }],
        NrOfRecPerPage: 0,
      };
      let userSites = await fetchUserWorkHistory(workHistoryObj);
      userSites = removeDuplicates(userSites, "projectId");
      let temp = userSites;
      const updatedUserSites = temp.map((element) => {
        element["siteAddress"] = element["address"];
        element["siteCity"] = element["city"];
        element["siteProvince"] = element["province"];
        element["sitePostalCode"] = element["postalCode"];
        element["siteCountry"] = element["country"];
        delete element["address"];
        delete element["city"];
        delete element["province"];
        delete element["postalCode"];
        delete element["country"];
        return element;
      });

      const noCompanyUserSites = updatedUserSites.filter(
        (comp) => comp.companyId == 0 || comp.companyName == ""
      );
      const finalCompSites = updatedUserSites.filter(
        (comp) => comp.companyId != 0 || comp.companyName != ""
      );
      setUserSites(finalCompSites ?? []);
    } catch (error) {
      console.log("WorkHistoryError:", error);
    }
  };

  const getGoallist = async () => {
    try {
      const obj = {
        PageNr: 1,
        NrOfRecPerPage: 10,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        SearchList: [{ createdBy: Number(localStorage?.userId) }],
      };
      let res = await fetchGoals(obj);
      setGoalList(res?.data ?? []);
    } catch (error) {
      console.log("Error while fetching user goals list :: ", error);
    }
  };

  const subtractTime = (time1, time2) => {
    const [hours1, minutes1] = time1.split(":").map((s) => parseInt(s, 10));
    const [hours2, minutes2] = time2.split(":").map((s) => parseInt(s, 10));
    let hours = hours1 - hours2;
    let minutes = minutes1 - minutes2;
    if (minutes < 0) {
      minutes += 60;
      hours -= 1;
    }
    if (hours < 0) {
      hours += 24;
    }
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  const formatNumber = (value) => {
    if (Math.floor(value) !== value) {
      let parts = value.toString().split(".");
      let decimalPlaces = parts[1].length || 0;
      let zerosToAdd = 5 - decimalPlaces;
      if (zerosToAdd > 0) {
        parts[1] += "0".repeat(zerosToAdd);
      }
      return parts.join(".");
    } else {
      return value.toString();
    }
  };

  const formatNumericToTime = (numericValue) => {
    let formattedHours = "00";
    let formattedMinutes = "00";
    let finaltime = "00";
    if (numericValue.toString().includes(".")) {
      // Split the numeric value into hours and minutes
      let [hoursPart, minutesPart] = numericValue.toString().split(".");
      if (hoursPart != 0) {
        const hours = parseInt(hoursPart) || 0;
        const minutes = minutesPart;
        formattedHours = hours.toString().padStart(2, "0");
        formattedMinutes =
          minutes.toString().length == 1
            ? minutes.toString().padEnd(2, "0")
            : minutes;
        finaltime = `${formattedHours}:${formattedMinutes}`;
      } else {
        formattedMinutes =
          minutesPart.toString().length == 1
            ? `00:${minutesPart.toString().padEnd(2, "0")}`
            : `00:${minutesPart} `;
        finaltime = `${formattedMinutes} `;
      }
    } else {
      let hoursPart = numericValue.toString();
      // formattedHours = hoursPart.length == 1 ? `${hoursPart.toString().padStart(2, "0")}:00` : `${hoursPart}:00`;
      formattedHours =
        hoursPart.length == 1
          ? `${hoursPart.toString().padStart(2, "0")}:00`
          : hoursPart.length == 2
          ? `${hoursPart}:00`
          : hoursPart;
      finaltime = `${formattedHours} `;

      // finaltime = numericValue;
    }
    return `${finaltime} `;
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const onDeleteEntryPressed = (item, index) => {
    setModified(true);
    if (!item.isDeleted && !item.isNew) {
      setDeletedEntries([...deletedEntries, item]);
    }
    let tempEntriesData = [...entriesData];
    let updatedTotalHours;
    let updatedTotalDistance;
    tempEntriesData.map((item, j) => {
      if (j == index) {
        if (totalHours != 0) {
          if (entriesData[index].hours != 0) {
            if (totalHours.toString().includes(":")) {
              updatedTotalHours = subtractTime(
                formatNumericToTime(totalHours),
                formatNumericToTime(entriesData[index].hours)
              );
            } else
              updatedTotalHours = subtractTime(
                formatNumericToTime(totalHours),
                formatNumericToTime(entriesData[index].hours)
              );
          } else {
            updatedTotalHours = totalHours;
          }
          updatedTotalDistance = totalDistance - entriesData[index].distance;
        }
      }
    });
    const newEntriesData = entriesData.filter((entry, i) => i != index);
    setEntriesData(newEntriesData);
    setTotalHours(updatedTotalHours ?? 0);
    setTotalDistance(updatedTotalDistance ?? 0);
  };

  const onExandClick = async (index) => {
    let cloneEntriesData = [...entriesData];
    const updateEntriesArray = () => {
      return (cloneEntriesData[index].isJobItemExpanded =
        !cloneEntriesData[index].isJobItemExpanded);
    };
    await updateEntriesArray();
    setEntriesData(cloneEntriesData);
  };

  const onCategoryPressed = async (item, categoryIndex) => {
    let data = [...entriesData];
    const updateEntriesArray = () => {
      if (data[entiresDataIndex]?.isJobItemAddGoalClicked) {
        data[entiresDataIndex].timesheetGoalsArray.push({
          goalName: "",
          time: "00:00",
          goalId: item.goalId,
          categoryName: item.categoryName,
          categoryId: item.id,
        });
      } else {
        data[jobItemIndex].timesheetGoalsArray[goalIndex].goalName =
          item.goalName;
        data[jobItemIndex].timesheetGoalsArray[goalIndex].goalId = item.goalId;
        data[jobItemIndex].timesheetGoalsArray[goalIndex].time = 0;
        data[jobItemIndex].timesheetGoalsArray[goalIndex].categoryName =
          item.categoryName;
        data[jobItemIndex].timesheetGoalsArray[goalIndex].categoryId = item.id;
      }
    };
    updateEntriesArray();
    setEntriesData(data);
    setShowGoalModal(false);
  };

  const onGoalPressed = (item) => {
    const data = [...entriesData];
    if (data[entiresDataIndex].isJobItemAddGoalClicked) {
      data[entiresDataIndex].timesheetGoalsArray.push({
        goalName: item.name,
        time: 0,
        goalId: item.id,
        categoryName: "",
        categoryId: "",
        goalDateTimeModal: false,
      });
    } else {
      data[jobItemIndex].timesheetGoalsArray[goalIndex].goalName = item.name;
      data[jobItemIndex].timesheetGoalsArray[goalIndex].time = 0;
      data[jobItemIndex].timesheetGoalsArray[goalIndex].goalId = item.id;
      data[jobItemIndex].timesheetGoalsArray[goalIndex].categoryName = "";
      data[jobItemIndex].timesheetGoalsArray[goalIndex].categoryId = 0;
      data[jobItemIndex].timesheetGoalsArray[
        goalIndex
      ].goalDateTimeModal = false;
    }
    setEntriesData(data);
    setShowGoalModal(false);
  };

  const onAddGoalClick = async (item, entiresDataIndex) => {
    let data = [...entriesData];
    data[entiresDataIndex].isJobItemAddGoalClicked = true;
    await setEntiresDataIndex(entiresDataIndex);
    await setEntriesData(data);
    setShowGoalModal(true);
    return;
  };

  const deleteTimeSheetGoal = (entiresDataIndex, goalIndex) => {
    const deletedGoal = entriesData[
      entiresDataIndex
    ]?.timesheetGoalsArray.filter((v, i) => i === goalIndex);
    setDeletedGoalsCategories([...deletedGoalsCategories, deletedGoal[0]]);
    setGoalsDeleted(true);
    setModified(true);
    const remaningGoalData = entriesData[
      entiresDataIndex
    ]?.timesheetGoalsArray.filter((v, i) => i !== goalIndex);
    let data = [...entriesData];
    data[entiresDataIndex].timesheetGoalsArray = remaningGoalData ?? [];
    setEntriesData(data);
    updateGoalsCategoriesHours(entiresDataIndex, 0, goalIndex);
  };

  const onPictureSelect = (pictures, entiresDataIndex) => {
    let data = [...entriesData];
    data[entiresDataIndex].pictureList = [
      ...data[entiresDataIndex].pictureList,
      ...pictures,
    ];
    setEntriesData(data);
  };

  const onDeletePicturePressed = (picIndex, item, entiresDataIndex) => {
    if (item?.id) {
      setDeletedImages([...deletedImages, item]);
    }
    const remaningPictureList = entriesData[
      entiresDataIndex
    ]?.pictureList?.filter((pic, i) => i !== picIndex);
    let data = [...entriesData];
    data[entiresDataIndex].pictureList = remaningPictureList ?? [];
    setEntriesData(data ?? []);
  };

  const updateComment = (text, rootIndex) => {
    let data = [...entriesData];
    data[rootIndex].comments = text;
    setModified(true);
    setEntriesData(data);
  };

  const handleAddTimeValue = (index, value) => {
    let data = [...entriesData];
    const [hoursStr, minutesStr] = value.split(":");
    const validHours = parseInt(hoursStr, 10);
    const validMinutes = parseInt(minutesStr, 10);

    if (validHours <= 24 && validMinutes <= 59) {
      data[index].hours = value;
      // Calculate total hours and minutes
      let totalHours = 0;
      let totalMinutes = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].hours != 0) {
          const [hours, minutes] = formatNumericToTime(data[i]?.hours)
            .split(":")
            ?.map((s) => parseInt(s, 10));
          totalHours += hours;
          totalMinutes += minutes;
        }
      }
      // Adjust hours and minutes
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
      // Format the total time
      const formattedTotalHours = totalHours.toString().padStart(2, "0");
      const formattedTotalMinutes = totalMinutes.toString().padStart(2, "0");
      const formattedTotalTime = `${formattedTotalHours}:${formattedTotalMinutes}`;

      setEntriesData(data);
      setTotalHours(formattedTotalTime);
      setIsTimesheetHoursValid(true);
    } else {
      setIsTimesheetHoursValid(false);
    }
  };

  const updateGoalsCategoriesHours = (rootIndex, text, goalIndex) => {
    let cloneData = [...entriesData];
    if (cloneData && cloneData[rootIndex].timesheetGoalsArray) {
      if (
        goalIndex >= 0 &&
        goalIndex < cloneData[rootIndex].timesheetGoalsArray?.length
      ) {
        cloneData[rootIndex].timesheetGoalsArray[goalIndex].time =
          text === 0
            ? cloneData[rootIndex].timesheetGoalsArray[goalIndex].time
            : text;
        const totalSelectedGoalsTime = cloneData[
          rootIndex
        ].timesheetGoalsArray.reduce(
          (total, goal) =>
            total + timeToMinutes(formatNumericToTime(goal.time)),
          0
        );
        const totalHoursMinutes = timeToMinutes(
          formatNumericToTime(cloneData[rootIndex].hours)
        );
        if (totalSelectedGoalsTime > totalHoursMinutes) {
          cloneData[rootIndex].isError = true;
          setEntriesData(cloneData);
          setGetError(true);
        } else {
          cloneData[rootIndex].isError = false;
          setEntriesData(cloneData);
          setGetError(false);
          setIsGoalHoursExceed(false);
        }
      } else {
        cloneData[rootIndex].isError = false;
        setEntriesData(cloneData);
        setGetError(false);
        setIsGoalHoursExceed(false);
      }
    }
  };

  const handleTimeChangeForJob = (timeValue, entiresDataIndex) => {
    let cloneEntriesData = [...entriesData];
    cloneEntriesData[entiresDataIndex].hours = timeValue;
    handleAddTimeValue(entiresDataIndex, timeValue);
    let goalIndex = 0;
    updateGoalsCategoriesHours(entiresDataIndex, 0, goalIndex);
  };

  const handleTimeChangeForGoal = (timeValue, entiresDataIndex, goalIndex) => {
    let cloneEntriesData = [...entriesData];
    cloneEntriesData[entiresDataIndex].timesheetGoalsArray[goalIndex].time =
      timeValue;
    setEntriesData(cloneEntriesData);
    updateGoalsCategoriesHours(entiresDataIndex, timeValue, goalIndex);
  };

  const updateDistance = (text, entiresDataIndex) => {
    const data = [...entriesData];
    setModified(true);
    data[entiresDataIndex].distance = text;
    const totalDistance = data.reduce(
      (total, item) => total + Number(item.distance),
      0
    );
    setEntriesData(data);
    setTotalDistance(totalDistance);
  };

  const handleRowClick = (value) => {
    let data = [...entriesData];
    data[value.itemIndex].isJobItemAddGoalClicked = false;
    setSelectedRowGoal(value?.goalItem);
    setGoalIndex(value?.goalIndex);
    setJobItemIndex(value?.itemIndex);
    setEntriesData(data);
    setShowGoalModal(true);
  };

  const formatDateString2 = (date) => {
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    if (month?.length == 1) month = "0" + month;
    if (day?.length == 1) day = "0" + day;
    const newDate = date.getFullYear() + "-" + month + "-" + day;
    return newDate;
  };

  const clearEditData = () => {
    setTimeSheetDate(today.format("YYYY-MM-DD"));
    setEntriesData([]);
    setUserSites([]);
    setShowjoblist(false);
    setDeletedEntries([]);
    setTotalHours(0);
    setTotalDistance(0);
    setModified(false);
    setRoleIndex(1);
    setShowGoalModal(false);
    setGoalList([]);
    setEntiresDataIndex("");
    setDeletedGoalsCategories([]);
    setGoalsDeleted(false);
    setGetError(false);
    setIsGoalHoursExceed(false);
    setDeletedImages([]);
    setIsTimesheetHoursValid(false);
    setGoalIndex(0);
    setSelectedRowGoal("");
    setJobItemIndex(0);
    setDetails(null);
    setloadingBtn(false);
  };

  const deleteData = async () => {
    try {
      if (deletedGoalsCategories?.length > 0) {
        deletedGoalsCategories.map(async (item) => {
          const deleteObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: false,
            ReturnRecordError: true,
            DeleteList: [
              {
                Id: item.id,
                ModifiedBy: Number(localStorage.userId),
              },
            ],
          };
          await deleteTimesheeetGoals(deleteObj);
        });
      }

      if (deletedImages?.length != 0) {
        const deleteArray = deletedImages.map((deletePic) => {
          return { Id: deletePic.id };
        });
        const deleteObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: false,
          ReturnRecordError: true,
          DeleteList: deleteArray,
        };
        await deleteTimeSheetsPictures(deleteObj);
      }

      if (deletedEntries?.length != 0) {
        let tempDeletePictures = [];
        deletedEntries.map((en) =>
          en.pictureList.map((el) => tempDeletePictures.push(el))
        );
        const deletePictureArray = tempDeletePictures.map((deletePic) => {
          return { Id: deletePic.id };
        });
        if (deletePictureArray?.length > 0) {
          const deletePictureObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: false,
            ReturnRecordError: true,
            DeleteList: deletePictureArray,
          };
          await deleteTimeSheetsPictures(deletePictureObj);
        }

        const deleteArray = deletedEntries.map((deleteEntry) => {
          return { Id: deleteEntry.id };
        });
        const deleteObj = {
          UserId: Number(localStorage.userId),
          ReturnRecordId: false,
          ReturnRecordError: true,
          DeleteList: deleteArray,
        };
        await deleteTimeSheetsEntries(deleteObj);
      }
    } catch (err) {
      console.log("Error while deleting data :: ", err);
    }
  };

  const handleSaveTimeSheet = async () => {
    try {
      setloadingBtn(true);

      if (details?.id) {
        deleteData();
      }

      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: details ? details?.id : 0,
            TimesheetDate: formatDateString2(new Date(timesheetDate)),
            TotalHours: totalHours.toString().replace(":", "."),
            TotalDistance: totalDistance,
            UnitOfDistance: roleIndex == 1 ? "km" : "mi",
            IsSent: details ? details?.isSent : false,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const timesheetData = await saveTimeSheet(obj);

      let finalEntriesData = [];
      for (let i = 0; i < entriesData?.length; i++) {
        const entryObj = {
          Id: entriesData[i].isNew ? 0 : entriesData[i].id,
          TimesheetId: timesheetData?.data?.[0].objData.id,
          SiteId: entriesData[i].siteId,
          Hours:
            entriesData[i].hours != ""
              ? entriesData[i].hours.toString().replace(":", ".")
              : 0,
          Distance: entriesData[i].distance != "" ? entriesData[i].distance : 0,
          UnitOfDistance: entriesData[i].unitOfDistance == "km" ? "km" : "mi",
          Comments: entriesData[i].comments,
          CompanyId: entriesData[i].companyId,
          ModifiedBy: Number(localStorage.userId),
          ProjectId: entriesData[i].projectId,
        };
        finalEntriesData.push(entryObj);
      }
      const finalEntriesObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: finalEntriesData,
      };

      const savedEntry = await saveTimeSheetsEntries(finalEntriesObj);
      for (let i = 0; i < entriesData?.length; i++) {
        for (let j = 0; j < entriesData[i].timesheetGoalsArray?.length; j++) {
          const saveGoalsDetailsObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: entriesData[i].timesheetGoalsArray[j].id
                  ? entriesData[i].timesheetGoalsArray[j].id
                  : 0,
                TimeSheetId: timesheetData?.data?.[0]?.objData?.id,
                TimeSheetEntryId: savedEntry?.data?.[i]?.objData?.id,
                SiteId: entriesData[i].siteId,
                GoalId: entriesData[i].timesheetGoalsArray[j].goalId,
                goalCategoryId:
                  entriesData[i].timesheetGoalsArray[j].categoryId == ""
                    ? 0
                    : entriesData[i].timesheetGoalsArray[j].categoryId,
                Hours:
                  entriesData[i].timesheetGoalsArray[j].time != ""
                    ? entriesData[i].timesheetGoalsArray[j].time
                        .toString()
                        .replace(":", ".")
                    : 0,
                ModifiedBy: Number(localStorage.userId),
                ProjectId: entriesData[i].projectId,
              },
            ],
          };
          console.log(
            "entriesData[i].userSiteProjectId======",
            entriesData[i].userSiteProjectId
          );
          await saveTimeSheetsGoals(saveGoalsDetailsObj);
        }
      }

      for (let j = 0; j < entriesData?.length; j++) {
        const newPictureArray = entriesData[j].pictureList.filter(
          (pic) => !pic.id
        );
        let timesheetEntry = {};
        let timesheetEntry2 = {};
        if (newPictureArray?.length != 0) {
          if (entriesData[j].isNew) {
            timesheetEntry = savedEntry?.data
              ?.filter(
                (ent) =>
                  ent.objData?.comments.toLowerCase() ==
                    entriesData[j].comments.toLowerCase() &&
                  // && this.formatNumericToTime(ent.objData?.hours) == entriesData[j].hours
                  formatNumericToTime(ent.objData?.hours) != "" &&
                  ent.objData?.distance == entriesData[j].distance &&
                  ent.objData?.siteId == entriesData[j].siteId
              )
              .pop();
          } else {
            timesheetEntry = entriesData[j];
          }
          for (let i = 0; i < newPictureArray?.length; i++) {
            const formData1 = new FormData();
            formData1.append("Id", 0);
            formData1.append(
              "TimeSheetId",
              timesheetData?.data?.[0]?.objData?.id
            );
            formData1.append(
              "TimeSheetEntryId",
              entriesData[j].isNew
                ? timesheetEntry?.objData?.id
                : timesheetEntry?.id
            );
            formData1.append("SiteId", entriesData[j].siteId);
            formData1.append(
              "ProjectId",
              entriesData[j]?.userSiteProjectId ?? entriesData[j].projectId
            );
            formData1.append("ModifiedBy", Number(localStorage.userId));
            formData1.append("PictureUrl", newPictureArray[i]);
            await saveTimeSheetsPictures(formData1);
          }
        }
      }
      setloadingBtn(false);
      props.getUpdatedData();
      clearEditData();
      props.onClose();
    } catch (error) {
      setloadingBtn(false);
      console.log("Errro while saving the timesheet :: ", error);
      // alert("Error while saving data. Please try again later")
    }
  };

  const onDeleteButtonPressed = async () => {
    try {
      const deleteObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: details?.id,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      await deleteTimeSheets(deleteObj);
      props.getUpdatedData();
      props.onClose();
      await clearEditData();
    } catch (err) {
      console.log("Error while deleting timesheet :: ", err);
    }
  };

  // console.log('entried data  =================',entriesData)
  return (
    <>
      {/* <AlertModal
				isOpen={deleteTimeSheetAlert}
				onClose={() => setDeleteTimeSheetAlert(false)}
				title={"Timesheet Deleted Successfully..."}
			/> */}

      {/* <AlertModal
				isOpen={inappropriateImageAlert}
				onClose={() => setInappropriateImageAlert(false)}
				title={"Content Rejected"}
				body={'The text picture you entered has been automatically identified as inappropriate.'}
				inappropriateImage
				text1={'Please remove the content and try again.'}
			/> */}

      {/* <PromptModal
				isOpen={discardModalOpen}
				onClose={() => setDiscardModalOpen(false)}
				title="Are you sure you want to leave?"
				desc="Any unsaved changes will be discarded."
				redButtonText="Discard"
				onRedButtonClick={() => {
					props.onClose(); clearEditData(); setDiscardModalOpen(false)
				}} /> */}
      <PromptModal
        isOpen={discardModalOpen}
        title="Discard Changes"
        desc=" Are you sure you want to discard changes? you Made?"
        redButtonText="Discard"
        onRedButtonClick={() => {
          setEdited(false);
          setDiscardModalOpen(false);
          props.onClose();
        }}
        blueButtonText="No thanks"
        onBlueButtonClick={() => {
          setDiscardModalOpen(false);
        }}
      />

      <PromptModal
        isOpen={deleteTimeSheetPrompt}
        onClose={() => {
          setDeleteTimeSheetPrompt(false);
        }}
        title="Are you sure you want to Delete?"
        redButtonText="Delete"
        onRedButtonClick={() =>
          onDeleteButtonPressed(props.editTimeSheetData?.id)
        }
      />
      {showJoblist ? (
        <JoblistModal
          open={showJoblist}
          options={userSites}
          onClose={() => {
            setShowjoblist(false);
          }}
          entriesDataLength={entriesData?.length}
          addNewSite={(data) => {
            setModified(true);
            setEntriesData([...entriesData, data]);
            setShowjoblist(false);
          }}
        />
      ) : showGoalModal ? (
        <>
          <GoalsList
            goalList={goalList}
            onClose={() => {
              setShowGoalModal(false);
            }}
            onCategoryPressed={onCategoryPressed}
            onGoalPressed={onGoalPressed}
          />
        </>
      ) : (
        <Modal
          open={props.isOpen}
          onClose={() => {
            if (edited) {
              setDiscardModalOpen(true);
            } else {
              props.onClose();
              clearEditData();
            }
          }}
        >
          <div className="modal-center timesheet-modal" style={{ top: "45%" }}>
            <div className="modal-card">
              <div className="modal-card-header">
                <div className="modal-card-header-buttons">
                  <IconButton
                    color="primary"
                    onClick={async () => {
                      if (edited) {
                        setDiscardModalOpen(true);
                      } else {
                        props.onClose();
                        clearEditData();
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <h3>
                    Total :{" "}
                    {totalHours === 0
                      ? "00:00"
                      : formatNumericToTime(totalHours)}{" "}
                    &nbsp; &nbsp; {totalDistance}
                    &nbsp;
                    <FormControl size="small" style={{ marginLeft: "10px" }}>
                      <Select
                        value={roleIndex}
                        onChange={(e) => {
                          const value = e.target.value;
                          setModified(true);
                          const data = entriesData;
                          for (let i = 0; i < data.length; i++) {
                            data[i].unitOfDistance = value === 1 ? "km" : "mi";
                          }
                          setEntriesData(data);
                          setRoleIndex(value);
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {labelArray.map((v, i) => (
                          <MenuItem value={v.key}>{v.value}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </h3>
                </div>
              </div>
              <div
                className="modal-card-body"
                style={{
                  padding: "10px 15px 0px 0px",
                  overflowY: "auto",
                  maxHeight: "70vh",
                }}
              >
                <h2>
                  {props.clickFrom === "EditTimesheet"
                    ? `Edit Timesheet`
                    : `Add Timesheet`}
                </h2>
                <div className="input-container">
                  <div
                    className="flex-container-vertical"
                    style={{ alignItems: "stretch" }}
                  >
                    <KeyboardDatePicker
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label="Timesheet Date"
                      format="YYYY-MM-DD"
                      value={timesheetDate}
                      InputAdornmentProps={{ position: "start" }}
                      onChange={(date) => {
                        setEdited(true);
                        setTimeSheetDate(moment(date).format("YYYY-MM-DD"));
                      }}
                    />
                  </div>
                </div>
                {entriesData?.length > 0 &&
                  entriesData?.map((item, index) => (
                    <div key={index}>
                      <TimesheetCard
                        setEdited={() => setEdited(true)}
                        index={index}
                        item={item}
                        onDeleteEntryPressed={onDeleteEntryPressed}
                        onExandClick={onExandClick}
                        goalList={goalList}
                        onAddGoalClick={onAddGoalClick}
                        deleteTimeSheetGoal={deleteTimeSheetGoal}
                        onPictureSelect={onPictureSelect}
                        onDeletePicturePressed={onDeletePicturePressed}
                        updateComment={updateComment}
                        handleTimeChangeForJob={handleTimeChangeForJob}
                        handleTimeChangeForGoal={handleTimeChangeForGoal}
                        updateDistance={updateDistance}
                        handleRowClick={handleRowClick}
                      />
                    </div>
                  ))}
                {props.clickFrom === "EditTimesheet" &&
                  props.activeTab === "sent" && (
                    <div className="input-container">
                      <p
                        style={{
                          backgroundColor: "lightyellow",
                          textAlign: "center",
                        }}
                      >
                        <InfoIcon fontSize="small" /> This Timesheet has been
                        sent. Remember to resend if you edit!
                      </p>
                    </div>
                  )}
                {props.clickFrom === "AddTimesheet" &&
                  entriesData?.length === 0 && (
                    <div className="add-job-modal-body">
                      <BusinessCenterIcon className="add-job-modal-body-icon" />
                      <p style={{ color: "gray", textAlign: "center" }}>
                        Add a Job to get started with this Timesheet
                      </p>
                    </div>
                  )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  component="label"
                  style={{ margin: "12px 0px" }}
                  onClick={() => {
                    setEdited(true);
                    setShowjoblist(true);
                  }}
                >
                  ADD JOB
                </Button>
                <div
                  className="input-container"
                  style={{ display: "flex", margin: "0px 0px" }}
                >
                  {props.clickFrom === "EditTimesheet" && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      fullWidth
                      size="medium"
                      component="label"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        setDeleteTimeSheetPrompt(true);
                        setEdited(true);
                      }}
                      style={{
                        marginRight: "10px",
                        color: BIRed,
                        borderColor: BIRed,
                      }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="medium"
                    component="label"
                    disabled={
                      entriesData?.length > 0 &&
                      isHoursEmpty?.length == 0 &&
                      !isGoalHoursExceed &&
                      isTimesheetHoursValid &&
                      !getError
                        ? false
                        : true
                    }
                    startIcon={!loadingBtn && <SaveIcon />}
                    onClick={handleSaveTimeSheet}
                  >
                    {loadingBtn ? <CircularProgress /> : "Save"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default AddTimeSheetModal;
