import { Autocomplete, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BIGrey, BILightGrey } from "../../../assets/buildidColors";
import Close from "@material-ui/icons/Close";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import {
  fetchClientSARNotesTypes,
  saveClientSARNotes,
  saveClientSARnotesPictures,
} from "../../../api/ClientApi";
import { LoadingButton } from "@mui/lab";
import { Box, IconButton } from "@material-ui/core";

function AddSARNotes(props) {
  const { open, onClose, data, edit, clientJobsData, reLoadData } = props;
  const [buttonDisable, setButtonDisable] = useState(true);
  const [loading, setLoading] = useState(false);

  const hint = useRef("");
  const imgRef = useRef(null);

  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [uploadedImageURL, setUploadedImageURL] = useState([]);
  const [uploadedImageList, setUploadedImageList] = useState([]);
  const [notesType, setNotesType] = useState([]);
  const [description, setDescription] = useState("");
  const [deleteImageList, setDeleteImageList] = useState([]);

  const handleAddImage = () => {
    imgRef.current.click();
  };

  const resetData = () => {
    setInputValue("");
    setSelectedValue(null);
    setDescription("");
    setUploadedImageURL([]);
    setUploadedImageList([]);
    setDeleteImageList([]);
  };

  useEffect(() => {
    if (selectedValue == null || description == "") {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [description, selectedValue]);

  useEffect(() => {
    fetchEmployee();
  }, [inputValue]);

  const fetchEmployee = async () => {
    const payload = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: inputValue,
      // UserId: userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [
        {
          ClientId: localStorage?.clientId,
        },
      ],
    };
    const clientJobRes = await fetchClientSARNotesTypes(payload);
    let temp = [];
    clientJobRes?.data?.map((item) => {
      temp.push(item);
    });
    setNotesType(temp);
  };

  const handleCreateReport = async () => {
    setLoading(true);
    try {
      const payload = {
        ReturnRecordId: true,
        ReturnRecordError: true,
        UserId: localStorage?.userId,
        SaveList: [
          {
            Id: 0,
            SARId: clientJobsData?.id,
            NoteTypeId: selectedValue.noteTypeId,
            Description: description,
            ModifiedBy: localStorage?.userId,
          },
        ],
      };
      const res = await saveClientSARNotes(payload);

      if (res.message === "OK") {
        if (uploadedImageList.length > 0) {
          const uploadPromises = Array.from(uploadedImageList)?.map(
            async (picture) => {
              const formData = new FormData();
              formData.append("Id", picture.id ?? 0);
              formData.append("SARNoteId", res.data[0]?.objData?.id);
              formData.append("ModifiedBy", localStorage.userId);
              formData.append("FileName", picture);
              await saveClientSARnotesPictures(formData);
            }
          );
          try {
            await Promise.all(uploadPromises);
            reLoadData();
          } catch (error) {
            console.error("Error uploading picture URLs:", error);
          }
        }
      }
      reLoadData();
    } catch (err) {
      console.log("ERRR :::: ", err);
    }
    setLoading(false);
    resetData();
    onClose();
  };

  const handleImageChange = async (event) => {
    const files = event.target.files;
    setUploadedImageList(files);
    const fileArray = Array.from(files)?.map((file) =>
      URL.createObjectURL(file)
    );
    setUploadedImageURL((prevImages) => prevImages.concat(fileArray));
  };

  const handleRemoveImage = (picture, index) => {
    const deleteList = deleteImageList;
    if (picture.id) {
      deleteList.push({ id: picture?.id });
    }
    setDeleteImageList(deleteList);
    setUploadedImageURL(uploadedImageURL.filter((_, i) => i !== index));
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-center">
          <div
            style={{
              padding: "20px",
              minHeight: "400px",
              width: "500px",
            }}
            className="modal-card"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={() => {
                  onClose();
                  resetData();
                }}
                style={{ padding: "0px" }}
              >
                <Close
                  style={{
                    color: localStorage.clientAccentColor,
                  }}
                />
              </IconButton>
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={imgRef}
              style={{ display: "none" }}
              multiple
            />

            <div
              style={{
                marginTop: "10px",
              }}
            >
              <Typography
                style={{
                  color: BIGrey,
                }}
              >
                Type
              </Typography>
              <Autocomplete
                sx={{
                  // border: "1px solid blue",
                  "& .MuiOutlinedInput-root": {
                    // border: "1px solid yellow",
                    borderRadius: "0",
                    padding: "0",
                  },
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                size="small"
                style={{
                  backgroundColor: "#f2f2f2",
                  borderRadius: "10px",
                }}
                fullWidth
                onKeyDown={(event) => {
                  if (event.key === "Tab") {
                    if (hint.current) {
                      setInputValue(hint.current);
                      event.preventDefault();
                    }
                  }
                }}
                onBlur={() => {
                  hint.current = "";
                }}
                disablePortal
                inputValue={inputValue}
                filterOptions={(options, state) => {
                  const displayOptions = options.filter((option) =>
                    option.noteTypeName
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase().trim())
                  );

                  return displayOptions;
                }}
                id="combo-box-hint-demo"
                options={notesType}
                renderOption={(props, option) => (
                  <Box {...props}>
                    <Typography>{option?.noteTypeName} </Typography>
                  </Box>
                )}
                onChange={(event, newValue) => {
                  console.log("newValue", newValue);
                  setSelectedValue({
                    noteTypeName: newValue?.noteTypeName,
                    shortDescription: newValue?.shortDescription,
                    noteTypeId: newValue?.id,
                  });
                  if (newValue) {
                    setInputValue(newValue?.noteTypeName); // Update the input value to the selected label
                  } else {
                    setInputValue("");
                  }
                }}
                renderInput={(params) => (
                  <Box sx={{ position: "relative" }}>
                    <Typography
                      sx={{
                        position: "absolute",
                        opacity: 0.5,
                        left: 14,
                        top: 16,
                      }}
                    >
                      {hint.current}
                    </Typography>
                    <TextField
                      {...params}
                      onFocus={() => setInputValue("")}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setInputValue(newValue);
                        const matchingOption = notesType.find((option) =>
                          option?.noteTypeName.startsWith(newValue)
                        );

                        if (newValue && matchingOption) {
                          hint.current = matchingOption.noteTypeName;
                        } else {
                          hint.current = "";
                        }
                      }}
                    />
                  </Box>
                )}
              />
            </div>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  style={{
                    color: BIGrey,
                  }}
                >
                  {selectedValue?.shortDescription}
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  style={{
                    color: BIGrey,
                  }}
                >
                  Description
                </Typography>
                <TextField
                  className="date-picker"
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  size="small"
                  fullWidth
                  inputVariant={"outlined"}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  gap: "10px",
                }}
              >
                <div
                  onClick={handleAddImage}
                  style={{
                    cursor: "pointer",
                    height: "100px",
                    width: "100px",
                    border: "2px solid lightgrey",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddIcon />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "400px",
                    overflow: "scroll",
                  }}
                >
                  {uploadedImageURL?.map((picture, index) => (
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <img
                        src={picture?.fileUrl || picture}
                        alt={"NO data"}
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "10px",
                          marginBottom: "10px",
                        }}
                      />
                      <IconButton
                        onClick={() => handleRemoveImage(picture, index)}
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          padding: "5px",
                          backgroundColor: BILightGrey,
                          color: "white",
                        }}
                      >
                        <CloseIcon
                          style={{
                            color: localStorage?.clientAccentColor,
                            fontSize: "16px",
                          }}
                        />
                      </IconButton>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <LoadingButton
                  style={{
                    textTransform: "none",
                    backgroundColor: !buttonDisable
                      ? localStorage.clientAccentColor
                      : BILightGrey,
                    boxShadow: "none",
                    color: "white",
                  }}
                  onClick={() => {
                    handleCreateReport();
                  }}
                  loading={loading}
                  disabled={buttonDisable}
                  fullWidth
                  loadingPosition="start"
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AddSARNotes;
