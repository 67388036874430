import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Save as SaveIcon, Close as CloseIcon } from "@material-ui/icons";
import { Modal, TextField, CircularProgress } from "@mui/material";
import { BIGrey } from "../assets/buildidColors";
import {
  SaveSites,
  fetchSiteTypes,
  saveProjectCompany,
  saveSitePostPicture,
  saveSitePosts,
  saveUserSitesProjects,
} from "../api/SitesApi";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ExperienceProjectForm from "./ExperienceProjectForm";
import { fetchProjectsPostsPictures } from "../api/UserApi";
import { saveProjectProducts } from "../api/ProjectsApi";
import ImageIcon from "@mui/icons-material/Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxWidth: ITEM_HEIGHT + ITEM_PADDING_TOP,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: "none",
    borderRadius: 8,
  },
}));

const AddProjectModal = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const appLocation = useLocation();

  const [loading, setLoading] = useState(false);
  const [siteDetails, setSiteDetails] = useState({});
  const [siteId, setSiteId] = useState(0);
  //changes-07-03-24-rahul
  const [savedProjectDetails, setSavedProjectDetails] = useState({});
  //changes-07-03-24-rahul
  const [address, setAddress] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [website, setWebsite] = useState("");
  const [selectedType, setSelectedType] = useState(0);
  const [description, setDescription] = useState("");
  const [isActiveProject, setIsActiveProject] = useState(false);
  const [isAddToMyProfile, setIsAddToMyProfile] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [siteNameText, setSiteNameText] = useState("");
  const [showUpdateSiteNameModal, setShowUpdateSiteNameModal] = useState(false);
  const [showAddExperienceModal, setShowAddExperienceModal] = useState(false);
  const [showExperienceDiscardModal, setShowExperienceDiscardModal] =
    useState(false);
  const [pictureUrl, setPictureurl] = useState("");
  const [updateName, setUpdateName] = useState(false);

  useEffect(() => {
    getTypeData();
  }, []);

  useEffect(() => {
    if (props?.siteDetails) {
      setSiteDetails(props?.siteDetails ?? {});
      setSiteId(props?.siteDetails?.id ?? 0);
      setSiteNameText(props?.siteDetails?.name ?? "");
      let addressObj = {
        address: props?.siteDetails?.address ?? "",
        country: props?.siteDetails?.country ?? "",
        province: props?.siteDetails?.province?.toUpperCase() ?? "",
        city: props?.siteDetails?.city ?? "",
        postalCode: props?.siteDetails?.postalCode ?? "",
        latitude: props?.siteDetails?.latitude ?? 0,
        longitude: props?.siteDetails?.longitude ?? 0,
      };
      setAddress(addressObj);
    }
    let previousDataObj = props?.addProjectObj ?? {};
    if (Object.keys(previousDataObj).length !== 0) {
      setProjectName(previousDataObj?.prevFormValues?.projectName ?? "");
      setSelectedType(previousDataObj?.prevFormValues?.selectedType ?? 0);
      setWebsite(previousDataObj?.prevFormValues?.website ?? "");
      setDescription(previousDataObj?.prevFormValues?.description ?? "");
      setIsActiveProject(
        previousDataObj?.prevFormValues?.isActiveProject ?? false
      );
      setIsAddToMyProfile(
        previousDataObj?.prevFormValues?.isAddToMyProfile ?? false
      );
      setPictures(previousDataObj?.prevFormValues?.pictures ?? []);
    }
  }, [props]);

  const onClose = () => {
    if (showAddExperienceModal) {
      return setShowExperienceDiscardModal(true);
    } else {
      if (appLocation?.state?.from === "quiz") {
        let obj = {
          from: "map",
          question: appLocation?.state?.obj?.questionData,
          project: "",
        };

        // history.push({
        //   pathname: "/rewards/earn-rewards",
        //   state: { obj },
        // });

        if (appLocation?.state?.fromQuestion == "dashboard-profile") {
          history.push({
            pathname: "/dashboard",
            state: { obj },
          });
        } else {
          history.push({
            pathname: "/rewards/earn-rewards",
            state: { obj },
          });
        }
        return;
      } else {
        setTimeout(() => {
          document.body.style.overflow = ""; // Reset overflow property after a delay
        }, 100); // Adjust the delay time as needed
        setIsAddToMyProfile(false);
        return props.onClose();
      }
    }
  };

  const onExperienceModalClose = () => {
    if (appLocation?.state?.from === "experience") {
      history.push("/profile");
    } else {
      setIsAddToMyProfile(false);
      return props?.onClose();
    }
  };

  const getTypeData = async () => {
    try {
      const siteTypeObj = {
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: Number(localStorage.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{}],
        SortList: [{ FieldName: "Name", Direction: "ASC" }],
      };
      const sitesTypesRes = await fetchSiteTypes(siteTypeObj);
      const sitesTypeData = sitesTypesRes?.data ?? [];
      const typeData = sitesTypeData.map((value) => {
        let a;
        a = value;
        a["value"] = value.id;
        a["label"] = value.name;
        return a;
      });
      setTypeOptions(typeData);

      /* new code sanket 20-03-2024 */
      // show Add to my profile default selected

      if (props.from === "experience") {
        setIsAddToMyProfile(true);
      }
    } catch (error) {
      console.log("Error while getting sites types list :: ", error);
    }
  };

  const onDeletePicturePressed = (item, i) => {
    let updatePicturesList = pictures.filter((pic, index) => index !== i);
    setPictures(updatePicturesList);
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    let allFilesValid = true;
    if (files?.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (!files[i].type.startsWith("image/")) {
          allFilesValid = false;
          break;
        }
      }
    }
    if (!allFilesValid)
      return alert("Please select valid image files (JPEG, PNG, GIF, etc.).");
    let images = [];
    for (let i = 0; i < files.length; i++) {
      images.push(files[i]);
    }
    setPictures([...pictures, ...images]);
    event.target.value = null;
  };

  const validateURL = (url) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    const wwwUrlRegex = /^(www)\.[^ "]+$/;
    if (
      !urlRegex.test(url.trim()) &&
      !wwwUrlRegex.test(url.trim()) &&
      url !== ""
    ) {
      return false;
    }
    return true;
  };

  const saveProjectPictures = async (productId) => {
    try {
      const obj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: false,
        SaveList: [
          {
            Id: 0,
            FormatTypeId: 1,
            PostedByUserId: Number(localStorage.userId),
            AppId: 24,
            AppRecordId: productId,
            Message: "<<<picture>>>",
            Reported: false,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const savePostResult = await saveSitePosts(obj);
      for (let i = 0; i < pictures?.length; i++) {
        let pictureDate = "";
        let fileDate = pictures[i]?.lastModifiedDate ?? null;
        if (fileDate) {
          pictureDate = moment(fileDate, "YYYY:MM:DD HH:mm:ss").format(
            "YYYY-MM-DD hh:mm A"
          );
        }
        const formData = new FormData();
        formData.append("Id", 0);
        formData.append("PostId", savePostResult?.[0]?.objData?.id ?? 0);
        formData.append("ModifiedBy", localStorage.userId);
        formData.append("Pictures", pictures[i]);
        formData.append("PictureDateTime", pictureDate);
        formData.append("GPSLatitude", 0);
        formData.append("GPSLongitude", 0);
        formData.append("GPSAltitude", 0);
        await saveSitePostPicture(formData);
      }
      // new code sanket 28-03-2024
      const picObj = {
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: 1,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{ AppRecordId: productId }],
      };
      const fetchProjectsPostsPicturesResponse =
        await fetchProjectsPostsPictures(picObj);
      setPictureurl(fetchProjectsPostsPicturesResponse[0]?.thumbUrl);
    } catch (error) {
      console.log("Error while saving project pictures :: ", error);
    }
  };

  const saveSitesAndProjects = async (
    siteId,
    projectName,
    siteName,
    isAddSite
  ) => {
    try {
      let projectId;
      // Call "saveSites" API only when site id is zero or siteName is empty;  --->
      if (siteId == 0 || siteName == "") {
        try {
          const siteObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: isAddSite ? 0 : siteId,
                Name: siteNameText != "" ? siteNameText : projectName,
                Address: isAddSite && !address ? "" : address.address,
                City: isAddSite && !address ? "" : address.city,
                Province: isAddSite && !address ? "" : address.province,
                PostalCode: isAddSite && !address ? "" : address.postalCode,
                Country: isAddSite && !address ? "" : address.country,
                Latitude:
                  isAddSite && !address ? 0 : address ? address?.latitude : 0,
                Longitude:
                  isAddSite && !address ? 0 : address ? address?.longitude : 0,
                RecordStatusId: 1,
                ModifiedBy: Number(localStorage.userId),
              },
            ],
          };
          const saveSiteResult = await SaveSites(siteObj);
          await setSiteDetails(saveSiteResult?.data?.[0]?.objData ?? {});
          const projObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                SiteId:
                  siteId == 0 ? saveSiteResult?.data?.[0]?.objData?.id : siteId,
                projectName: projectName,
                ProjectType: selectedType,
                ProjectWebsite: website,
                Description: description,
                IsActive: isActiveProject,
                RecordStatusId: 1,
                ModifiedBy: Number(localStorage.userId),
              },
            ],
          };
          const saveUserSitesProjectsResponses = await saveUserSitesProjects(
            projObj
          );
          // new code sanket 28-03-2024
          projectId = saveUserSitesProjectsResponses?.data?.[0]?.objData?.id;
          //changes-07-03-24-rahul
          await setSavedProjectDetails(
            saveUserSitesProjectsResponses?.data?.[0]?.objData ?? {}
          );
          if (appLocation?.state?.from === "quiz") {
            let obj = {
              from: "map",
              question: appLocation?.state?.obj?.questionData,
              project: saveUserSitesProjectsResponses?.data?.[0]?.objData,
            };

            // history.push({
            //   pathname: "/rewards/earn-rewards",
            //   state: { obj },
            // });

            if (appLocation?.state?.fromQuestion == "dashboard-profile") {
              history.push({
                pathname: "/dashboard",
                state: { obj },
              });
            } else {
              history.push({
                pathname: "/rewards/earn-rewards",
                state: { obj },
              });
            }

            return;
          }
          //changes-07-03-24-rahul
          await onAddToProfile(projectId);
        } catch (error) {
          setLoading(false);
          console.log("Error while saving project  ::", error);
          alert(
            error?.response?.data?.message ??
              "Something went wrong. Please try again later."
          );
          onClose();
        }
      } else {
        // <--- Comes in if when site id is not zero and site name is not empty;
        try {
          const projObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: 0,
                UserId: Number(localStorage.userId),
                SiteId: siteId,
                projectName: projectName,
                ProjectType: selectedType,
                ProjectWebsite: website,
                Description: description,
                IsActive: isActiveProject,
                RecordStatusId: 1,
                ModifiedBy: Number(localStorage.userId),
              },
            ],
          };
          const saveUserSitesProjectsResponses = await saveUserSitesProjects(
            projObj
          );
          // new code sanket 28-03-2024
          projectId = saveUserSitesProjectsResponses?.data?.[0]?.objData?.id;
          //changes-07-03-24-rahul
          await setSavedProjectDetails(
            saveUserSitesProjectsResponses?.data?.[0]?.objData ?? {}
          );
          //changes-07-03-24-rahul

          if (appLocation?.state?.from === "quiz") {
            let obj = {
              from: "map",
              question: appLocation?.state?.obj?.questionData,
              project: saveUserSitesProjectsResponses?.data?.[0]?.objData,
            };

            // history.push({
            //   pathname: "/rewards/earn-rewards",
            //   state: { obj },
            // });

            if (appLocation?.state?.fromQuestion == "dashboard-profile") {
              history.push({
                pathname: "/dashboard",
                state: { obj },
              });
            } else {
              history.push({
                pathname: "/rewards/earn-rewards",
                state: { obj },
              });
            }

            return;
          }
          await onAddToProfile(projectId);
        } catch (error) {
          setLoading(false);
          console.log("Error while saving project  ::", error);
          alert(
            error?.response?.data?.message ??
              "Something went wrong. Please try again later."
          );
          onClose();
        }
      }
      if (pictures?.length > 0) {
        //changes-07-03-24-rahul
        await saveProjectPictures(projectId);
      }

      if (isAddToMyProfile) {
        setLoading(false);
        return setShowAddExperienceModal(true);
      }
      if (siteId == 0) {
        let callSitesApi = siteId == 0 || siteName == "";
        await props?.onDataSave(callSitesApi);
      } else if (siteId !== 0 && siteName == "") {
        if (
          props?.from === "companyProfile" ||
          props?.from === "manufactureProfile" ||
          props?.from === "productProfile"
        ) {
          return;
        }
        await props?.onDataSave();
        if (props?.mapScreenFlag) {
          await props?.onDataSave(projectId);
        }
      } else {
        if (
          props?.from === "companyProfile" ||
          props?.from === "manufactureProfile" ||
          props?.from === "productProfile"
        ) {
          return;
        } else {
          await props?.onDataSave();
        }
      }
      onClose();
    } catch (error) {
      setLoading(false);
      alert(
        error?.response?.data?.message ??
          "Something went wrong. Please try again later."
      );
      console.log("Error while saving project  ::", error);
      onClose();
    }
  };

  const onAddToProfile = async (item) => {
    if (props?.from == "companyProfile") {
      let companyId = props?.companyData?.id;
      await saveProjectForCompany(item, companyId);
    } else if (props?.from == "manufactureProfile") {
      let manufactureId = props?.manufacturerData?.id;
      await saveProjectForCompany(item, manufactureId);
    } else if (props?.from == "productProfile") {
      let productId = props?.productData?.id;
      await saveProjectForProduct(item, productId);
    }
  };

  const saveProjectForCompany = async (item, companyId) => {
    const saveObj = [
      {
        Id: 0,
        ProjectId: item,
        CompanyId: companyId,
        ModifiedBy: Number(localStorage?.userId),
      },
    ];
    try {
      await saveProjectCompany(saveObj);
      if (props?.from == "companyProfile" && !isAddToMyProfile) {
        let companyId = props?.companyData?.id;
        history.push(`/company/${companyId}`);
        return;
      } else if (props?.from == "manufactureProfile" && !isAddToMyProfile) {
        let manufacturerId = props?.manufacturerData?.id;
        history.push(`/manufacturer/${manufacturerId}`);
        return;
      }
    } catch (error) {
      console.log("saveProjectCompany_ERROR: ", error);
    }
  };

  const saveProjectForProduct = async (item, productId) => {
    const saveObj = {
      UserId: Number(localStorage?.userId),
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: 0,
          ProjectId: item,
          ProductId: productId,
          ModifiedBy: Number(localStorage?.userId),
        },
      ],
    };
    try {
      await saveProjectProducts(saveObj);
      if (props?.from == "productProfile" && !isAddToMyProfile) {
        let productId = props?.productData?.id;
        history.push(`/product/${productId}`);
        return;
      }
    } catch (error) {
      console.log("saveProjectproduct_ERROR: ", error);
    }
  };

  const handleProjectSaveButton = async () => {
    try {
      if (updateName === false && siteNameText === "") {
        setShowUpdateSiteNameModal(true);
        return;
      }
      if (siteNameText !== "") {
        setLoading(true);
      }
      if (website != "") {
        const isValidUrl1 = validateURL(website);
        if (!isValidUrl1) {
          alert("Please enter a vaild URL format");
          setLoading(false);
          return; // Stop  if the website is not valid
        }
      }
      if (siteId === 0) {
        // first add site then project
        await saveSitesAndProjects(siteId, projectName, siteNameText, true);
      }
      // else {
      //     if (siteNameText === '') {       // selected site is Unnamed And user has not added siteName --->
      //         // alert('Update site name as project name');
      //         setShowUpdateSiteNameModal(true);
      //         // await this.saveSitesAndProjects(siteId, projectNameText, siteNameText, false)
      //     }
      else {
        if (props?.siteDetails?.name?.trim() === "") {
          const siteObj = {
            UserId: Number(localStorage.userId),
            ReturnRecordId: true,
            ReturnRecordError: true,
            SaveList: [
              {
                Id: siteId,
                Name: siteNameText,
                Address: !address ? "" : address.address,
                City: !address ? "" : address.city,
                Province: !address ? "" : address.province,
                PostalCode: !address ? "" : address.postalCode,
                Country: !address ? "" : address.country,
                Latitude: !address ? 0 : address ? address?.latitude : 0,
                Longitude: !address ? 0 : address ? address?.longitude : 0,
                RecordStatusId: 1,
                ModifiedBy: Number(localStorage.userId),
              },
            ],
          };

          let saveSiteResult = await SaveSites(siteObj);
          await setSiteDetails(saveSiteResult?.data?.[0]?.objData ?? {});
        }
        await saveSitesAndProjects(siteId, projectName, siteNameText, false);
      }
    } catch (error) {
      setLoading(false);
      console.log("Error while saving project :: ", error);
    }
  };

  const onUpdateSiteName = async () => {
    try {
      setUpdateName(true);
      setLoading(true);
      setShowUpdateSiteNameModal(false);
      const siteObj = {
        UserId: Number(localStorage.userId),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: siteId,
            Name: projectName,
            Address: !address ? "" : address.address,
            City: !address ? "" : address.city,
            Province: !address ? "" : address.province,
            PostalCode: !address ? "" : address.postalCode,
            Country: !address ? "" : address.country,
            Latitude: !address ? 0 : address ? address?.latitude : 0,
            Longitude: !address ? 0 : address ? address?.longitude : 0,
            RecordStatusId: 1,
            ModifiedBy: Number(localStorage.userId),
          },
        ],
      };
      const saveSiteResult = await SaveSites(siteObj);
      await setSiteDetails(saveSiteResult?.data?.[0]?.objData ?? {});
      await setSiteId(saveSiteResult?.data?.[0]?.objData?.id ?? 0);
      await saveSitesAndProjects(
        saveSiteResult?.data?.[0]?.objData?.id ?? 0,
        projectName,
        siteNameText,
        false
      );
      setLoading(false);
    } catch (error) {
      console.log("Error while saving site name :: ", error);
      setLoading(false);
    }
  };

  const onChangeSite = () => {
    let prevFormValues = {
      projectName,
      selectedType,
      website,
      description,
      isActiveProject,
      isAddToMyProfile,
      pictures,
    };
    let obj = {
      previousSiteSelected: props?.siteDetails ?? {},
      prevFormValues,
    };
    props.onChangeSite(obj);
  };

  const onExperienceSave = (projectId) => {
    if (props?.from == "companyProfile") {
      let companyId = props?.companyData?.id;
      history.push(`/company/${companyId}`);
    } else if (props?.from == "manufactureProfile") {
      let manufacturerId = props?.manufacturerData?.id;
      history.push(`/manufacturer/${manufacturerId}`);
    } else if (props?.from == "productProfile") {
      let productId = props?.productData?.id;
      history.push(`/product/${productId}`);
    } else if (projectId) {
      history.push(`/projects/project/${projectId}`);
    } else {
      props.onClose();
    }
  };

  const nameChange = (e) => {
    e.preventDefault();
    setProjectName(e.target.value);
  };

  const renderAddProjectForm = () => {
    return (
      <>
        <div className="flex-container-vertical">
          <div
            className="flex-container-horizontal"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <IconButton color="primary" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <div>
              <IconButton
                color="primary"
                onClick={handleProjectSaveButton}
                disabled={projectName?.trim() === ""}
              >
                <SaveIcon />
              </IconButton>
            </div>
          </div>
          <div className="flex-container-vertical" style={{ flex: 1 }}>
            <h2 style={{ paddingLeft: "10px" }}>Add Project</h2>

            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12} md={6} lg={12}>
                <Grid container className="job-grid">
                  <Grid item xs={12} md={8} sm={12} lg={12}>
                    {pictures.length == 0 ? (
                      <div
                        className="upload-image"
                        style={{
                          width: "100%",
                          marginRight: "0px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <Button
                          style={{ width: "100%", padding: 0, color: "gray" }}
                          variant="text"
                          size="large"
                          fontWeight="bold"
                          component="label"
                        >
                          <ImageIcon style={{ cursor: "pointer" }} />
                          Add Pictures
                          <input
                            id="timeSheetPicture-upload"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleFileSelect}
                            hidden
                          />
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div
                            className="upload-image"
                            style={{ backgroundColor: "lightgray" }}
                          >
                            <Button
                              style={{
                                width: "100%",
                                padding: 0,
                                color: "gray",
                              }}
                              variant="text"
                              size="small"
                              component="label"
                            >
                              <ImageIcon style={{ cursor: "pointer" }} />
                              Add Pictures
                              <input
                                id="timeSheetPicture-upload"
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleFileSelect}
                                hidden
                              />
                            </Button>
                          </div>
                          <div className="upload-image-div">
                            {pictures.map((item, index) => {
                              return (
                                <div key={index} className="store-card-image1">
                                  <div className="store-card-colse-icon1">
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        onDeletePicturePressed(item, index)
                                      }
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </div>
                                  <img
                                    src={
                                      item.pictureUrl
                                        ? item.pictureUrl
                                        : URL.createObjectURL(item)
                                    }
                                    alt="Expenses"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <TextField
              fullWidth
              label="Site Name"
              type="text"
              disabled={props?.siteDetails?.name ? true : false}
              value={siteNameText}
              onChange={(e) => setSiteNameText(e.target.value)}
            />
            {siteId !== 0 ||
            (address &&
              (address?.address || address?.latitude || address?.longitude)) ? (
              <div>
                <div>
                  <p style={{ color: BIGrey }}>
                    {address
                      ? address?.address
                        ? address?.address.trim()
                        : address?.latitude !== 0 &&
                          address?.longitude !== 0 &&
                          address?.address === ""
                        ? `(${address?.latitude},${address?.longitude})`
                        : "No Location"
                      : "No Location"}
                  </p>
                </div>
                <div style={{ marginVertical: 10 }}>
                  <Button variant="text" color="primary" onClick={onChangeSite}>
                    {" "}
                    Change SITE{" "}
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ marginVertical: 10 }}>
                <Button variant="text" color="primary" onClick={onChangeSite}>
                  {" "}
                  Choose Site{" "}
                </Button>
              </div>
            )}
          </div>
          <div className="flex-container-horizontal" style={{ flex: 1 }}>
            <div className="flex-container-vertical" style={{ flex: 1 }}>
              <div className="input-container">
                <TextField
                  id="project-name-textfield"
                  label="Project Name * (required)"
                  value={projectName}
                  fullWidth
                  onChange={(e) => nameChange(e)}
                />
              </div>

              <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                <TextField
                  id="project-name-textfield"
                  label="Website"
                  value={website}
                  fullWidth
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </div>

              <div>
                <Checkbox
                  color="primary"
                  checked={isActiveProject}
                  onChange={() => setIsActiveProject((preValue) => !preValue)}
                />
                <span>Active Project</span>
              </div>
              {props?.from == "quiz" ? null : (
                <div>
                  <Checkbox
                    disabled={props?.from === "experience" ? true : false}
                    color="primary"
                    checked={isAddToMyProfile}
                    onChange={() =>
                      setIsAddToMyProfile((preValue) => !preValue)
                    }
                  />
                  <span>Add to my Profile</span>
                </div>
              )}
            </div>
            <div className="flex-container-vertical" style={{ flex: 1 }}>
              <div className="input-container">
                <FormControl fullWidth>
                  <InputLabel
                    id="mutiple-select-label"
                    className="timesheet-label"
                  >
                    Type
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    variant="outlined"
                    value={selectedType}
                    label="type"
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                    MenuProps={MenuProps}
                    style={{ width: "100%" }}
                  >
                    {typeOptions.map((option) => {
                      return (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          onClick={() => {}}
                          style={{
                            width: "450px",
                          }}
                        >
                          <ListItemText primary={option.name} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                <TextField
                  fullWidth
                  minRows={4}
                  multiline
                  label="Description"
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Modal open={props.show} onClose={onClose}>
        <div className="modal-center">
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress size={100} />
            </div>
          ) : (
            <div className="modal-card" style={{ width: "800px" }}>
              <div className="modal-card-body">
                {!showAddExperienceModal ? (
                  renderAddProjectForm()
                ) : (
                  <ExperienceProjectForm
                    siteDetails={siteDetails}
                    projectId={savedProjectDetails?.id ?? 0} //changes-07-03-24-rahul
                    projectName={savedProjectDetails?.projectName ?? ""} //changes-07-03-24-rahul
                    onClose={onExperienceModalClose}
                    showExperienceDiscardModal={showExperienceDiscardModal}
                    setShowExperienceDiscardModal={(val) => {
                      setShowExperienceDiscardModal(val);
                    }}
                    onDataSave={onExperienceSave}
                    isFollowing={true} //changes-07-03-24-rahul
                    pictureUrl={pictureUrl}
                    companyData={props?.companyData ?? {}}
                    from={props?.from}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        open={showUpdateSiteNameModal}
        onClose={() => {
          setShowUpdateSiteNameModal(false);
        }}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <p id="simple-modal-description">
            The Project Name will also be used as the Site Name.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Button
              onClick={() => {
                setShowUpdateSiteNameModal(false);
              }}
              color="primary"
              style={{ marginRight: "8px" }}
            >
              Go Back
            </Button>
            <Button
              onClick={onUpdateSiteName}
              color="primary"
              variant="contained"
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddProjectModal;
