import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  IconButton,
  ListItemText,
  ListItemButton,
  Typography,
  List,
  ListItem,
  Stack,
} from "@mui/material";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { BIBlue, BIRed } from "../../assets/buildidColors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ReportModal from "../../components/ReportModal";
import ErrorIcon from "@mui/icons-material/Error";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NoLocationSitesModel from "../sites/dilogs/NoLocationSitesModel";
import AlertModal from "../../components/AlertModal";
import "../../assets/css/Sites.css";
import { MoreVert as MoreIcon } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import blueMarker from "../../assets/images/map-marker-blue.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const SiteIssuesList = (props) => {
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState(false);
  const [noLocationModalOpen, setNoLocationModalOpen] = useState(false);
  const [siteDetailsToAddProduct, setSiteSiteDetailsToAddProduct] =
    useState(null);
  const history = useHistory();

  const handleNoLocationModalOpen = () => {
    props?.setNoLoactionSiteModalFlag(true);
  };

  const handleNoLocationModalClose = () => {
    setNoLocationModalOpen(false);
  };

  {
    /* Mahindra Code 28-mar-2024 */
  }
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + " ...";
  }

  return (
    <>
      {/* Mahindra Code 28-mar-2024 */}
      <div>
        {props?.noLocationSiteList && props?.noLocationSiteList.length > 0 && (
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            sx={{ cursor: "pointer" }}
            backgroundColor={"#f8bdbd"}
            padding={"5px 10px"}
            onClick={handleNoLocationModalOpen}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ErrorIcon fontSize="small" sx={{ color: BIRed }} />
              {props?.showChooseOption ||
              props?.from == "companyProfile" ||
              props?.from == "manufactureProfile" ||
              props?.from == "productProfile" ? (
                <Typography
                  variant="body2"
                  className="no-location-message"
                  sx={{ marginLeft: "5px" }}
                >
                  {props?.noLocationSiteList.length} Sites without a location
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  className="no-location-message"
                  sx={{ marginLeft: "5px" }}
                >
                  There are {props?.noLocationSiteList.length} item(s) you added
                  that need action from you.
                </Typography>
              )}
            </div>
            <NavigateNextIcon sx={{ color: "GrayText" }} />
          </Stack>
        )}
        {/* Mahindra Code 28-mar-2024 */}
        {props?.siteList && props?.siteList?.length > 0 ? (
          <div
            className={
              props?.noLocationSiteList && props?.noLocationSiteList.length > 0
                ? "mysite-list"
                : props?.fromSignUpPage == "signUpPage"
                ? "sighup-mysite-list"
                : "nolocation-mysite-list"
            }
          >
            <List sx={{ padding: 0 }}>
              {props?.siteList?.map((item, index) => {
                return (
                  <ListItem
                    sx={{ padding: moreMenuAnchorEl ? "0px" : "0px" }}
                    key={index}
                    secondaryAction={
                      <>
                        {item?.isMySites ? (
                          <IconButton disabled>
                            <img src={blueMarker} height={"25px"} />
                          </IconButton>
                        ) : item?.name == "" ? (
                          <IconButton disabled>
                            <WarningIcon style={{ color: "#FFBC0A" }} />
                          </IconButton>
                        ) : (
                          <></>
                        )}
                        {/* Mahindra Code 28-mar-2024 */}
                        {props?.from === "experience" ||
                        props?.from == "companyProfile" ||
                        props?.from == "manufactureProfile" ||
                        props?.from == "productProfile" ||
                        props?.from == "quiz" ? (
                          <></>
                        ) : (
                          <>
                            {!props?.showChooseOption ? (
                              <IconButton
                                onClick={(event) => {
                                  setSiteSiteDetailsToAddProduct({
                                    id: item?.id,
                                    name: item?.name ?? "",
                                    address: item?.address ?? "",
                                    city: item?.city ?? "",
                                    province: item?.province ?? "",
                                    postalCode: item?.postalCode ?? "",
                                    country: item?.country ?? "",
                                    latitude: item?.latitude ?? 0,
                                    longitude: item?.longitude ?? 0,
                                  });
                                  setMoreMenuAnchorEl(event.currentTarget);
                                }}
                              >
                                <MoreHorizIcon fontSize="small" />
                              </IconButton>
                            ) : null}
                          </>
                        )}
                      </>
                    }
                    disablePadding
                  >
                    {/* Mahindra Code 28-mar-2024 */}
                    <ListItemButton
                      onClick={() => {
                        props?.setSelectedSiteDetails(item);
                        props?.setShowSiteCardModal(true);
                      }}
                    >
                      <ListItemText
                        primary={
                          <span
                            style={{
                              color:
                                item?.name?.trim() !== ""
                                  ? "black"
                                  : "GrayText",
                            }}
                          >
                            {item?.name || "Unnamed Site"}{" "}
                          </span>
                        }
                        secondary={
                          truncateText(item?.address, 50) ||
                          `(${item?.latitude?.toFixed(
                            5
                          )}, ${item?.longitude?.toFixed(5)})`
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </div>
        ) : (
          <div
            className="mysite-list"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "15px",
            }}
          >
            <LocationOnIcon
              sx={{ width: "300px", height: "300px" }}
              color="disabled"
            />
            <Typography variant="body2" color={"GrayText"}>
              {" "}
              You haven't added any Project yet.
            </Typography>
          </div>
        )}
        {/* Mahindra Code 28-mar-2024 */}
        {noLocationModalOpen && (
          <NoLocationSitesModel
            handleNoLocationModalOpen={noLocationModalOpen}
            handleNoLocationModalClose={handleNoLocationModalClose}
            noLocationSiteList={props?.noLocationSiteList}
            setSelectedSiteDetails={props?.setSelectedSiteDetails}
            setShowSiteCardModal={props?.setShowSiteCardModal}
            siteInfo={props?.siteInfo}
            setSiteInfo={props?.setSiteInfo}
            getNoLocationSiteList={props?.getNoLocationSiteList}
            addProjectToSite={props?.addProjectToSite}
            handleReportModal={props?.handleReportModal}
            showChooseOption={props?.showChooseOption ?? false}
            currentLocation={props?.currentLocation}
            getSiteInfo={props?.getSiteInfo}
            from={props?.from}
          />
        )}
      </div>
      {/* Mahindra Code 28-mar-2024 */}
      <Menu
        anchorEl={moreMenuAnchorEl}
        open={Boolean(moreMenuAnchorEl)}
        onClose={(event) => {
          setMoreMenuAnchorEl(false);
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        style={{ bottom: "10px", left: "50px" }}
      >
        {siteDetailsToAddProduct?.name == "" ? (
          <MenuItem
            onClick={() => {
              setMoreMenuAnchorEl(false);
              props?.handleAddSiteName(siteDetailsToAddProduct);
            }}
            style={{ color: BIBlue }}
          >
            Add Site Name
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            setMoreMenuAnchorEl(false);
            props?.addProjectToSite(siteDetailsToAddProduct);
          }}
          style={{ color: BIBlue }}
        >
          Add To Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMoreMenuAnchorEl(false);
            props?.handleReportModal(siteDetailsToAddProduct);
          }}
          style={{ color: BIRed }}
        >
          Report
        </MenuItem>
      </Menu>
    </>
  );
};

export default SiteIssuesList;
