import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Grid,
  TextField,
} from "@mui/material";
import { Divider, IconButton, Typography } from "@material-ui/core";
import {
  BIBlack,
  BIBrown,
  BIGreen,
  BIGrey,
  BILightGreen,
  BILightGrey,
  BILightOrange,
  BILightRed,
  BIRed,
} from "../../../assets/buildidColors";
import "../../../assets/css/ClientHome.css";
import { useParams } from "react-router-dom";
import {
  fetchClientActions,
  FetchClientSARActionsHistory2,
  saverOverDueCLientSARActionHistory,
  saveSARActions,
} from "../../../api/ClientApi";
import { ClientHeader } from "../ClientHeader";
import moment from "moment";
import SendIcon from "@mui/icons-material/Send";
import ClientChangeStatusModal from "./ClientChangeStatusModal";
import PictureViewer from "../../../components/PictureViewer";
import { useLocation } from "react-router-dom";

export default function ClientActionsDetails() {
  const { id } = useParams();

  const location = useLocation();

  const activeTab = location.state?.activeTab;

  const [actionDetails, setActionDetails] = useState();
  const [clientSearchTerm, setClientSearchTerm] = useState("");
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [commentText, setComment] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const [openPictureViewer, setOpenPictureViewer] = useState(false);
  const [pictureIndex, setPictureindex] = useState(0);

  const [SARHistory, setSARHistory] = useState([]);

  useEffect(() => {
    fetchActionsdata();
  }, []);

  const fetchActionsdata = async () => {
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 100,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SortList: [
        {
          FieldName: "Id",
          Direction: "DESC",
        },
      ],
      SearchList: [{ id: id }],
    };
    let getActions = await fetchClientActions(obj);
    console.log("fetchActionsdata", getActions);
    setActionDetails(getActions?.data[0]);

    await getApiCAll(getActions.data[0].id, localStorage.userId);
  };

  // !this api should be compulsary called after  the above the api

  const getApiCAll = async (clientSARActionId, userId) => {
    const res = await saverOverDueCLientSARActionHistory(
      clientSARActionId,
      userId
    );

    fetchSARHistory();
  };

  const fetchSARHistory = async (filter) => {
    let obj = {
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: localStorage.userId,
      IncludeRecordNr: true,
      FetchAllowedRecordsOnly: false,
      SearchList: [{ sarActionId: id }],
    };

    const res = await FetchClientSARActionsHistory2(obj);
    console.log("FetchClientSARActionsHistory2 ::", res);

    setSARHistory(res.data);
  };

  // !for adding comment to the action
  const saveAction = async (comment) => {
    setButtonLoading(true);
    try {
      let obj = {
        UserId: localStorage.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            SARActionId: actionDetails.id,
            Status: actionDetails.status,
            StatusChangedBy: localStorage.userId,
            StatusChangedDate: comment
              ? moment(SARHistory[SARHistory.length - 1]).format(`YYYY-MM-DD`)
              : moment().format("YYYY-MM-DD"),
            Comment: comment ? commentText : "",
            ModifiedBy: localStorage.userId,
          },
        ],
      };

      const res = saveSARActions(obj);
      fetchActionsdata();

      setButtonLoading(false);
      setComment("");
    } catch (error) {
      setButtonLoading(false);
      console.log("error", error);
    }
  };

  // ! for updating the status of the action
  const upadteActionStatus = async (key) => {
    try {
      let obj = {
        UserId: localStorage.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: 0,
            SARActionId: actionDetails.id,
            Status: key,
            StatusChangedBy: localStorage.userId,
            StatusChangedDate: moment().format("YYYY-MM-DD"),
            Comment: "",
            ModifiedBy: localStorage.userId,
          },
        ],
      };
      setOpenStatusModal(false);
      const res = saveSARActions(obj);
      fetchActionsdata();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleStatusModal = () => {
    setOpenStatusModal(true);
    console.log("openstatusmodal", openStatusModal);
  };
  console.log("SARHistory :::", SARHistory);

  return (
    <>
      <ClientHeader
        screenName={"Actions"}
        clientSearchTerm={clientSearchTerm}
        setClientSearchTerm={setClientSearchTerm}
      />
      <div className="page">
        <div className="page-content">
          <Grid
            style={{ cursor: "pointer" }}
            marginBottom={1}
            container
            alignContent={"center"}
            md={12}
            lg={12}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Card>
                <Grid
                  gap={1}
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                  paddingInline={2}
                  paddingTop={2}>
                  <Grid item>
                    <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                      Created by
                    </Typography>
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={actionDetails?.userProfilePicture}
                        alt={actionDetails?.userName}
                        style={{ width: 40, height: 40 }} // Adjust size as needed
                      />
                      <Typography
                        style={{
                          color: BIGrey,
                          fontSize: "14px",
                          marginLeft: "8px",
                        }}>
                        {actionDetails?.userName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginLeft: "auto" }}>
                    <Typography style={{ color: BIGrey, fontSize: "14px" }}>
                      Due
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  gap={1}
                  container
                  justifyContent="space-between"
                  alignItems={"center"}
                  paddingInline={2}>
                  {console.log("%%%%%%%%%%%%%", activeTab)}
                  {activeTab === "Received" ? (
                    <Grid item>
                      <Avatar
                        alt={actionDetails?.userProfilePicture}
                        src={actionDetails?.userProfilePicture}
                      />
                    </Grid>
                  ) : activeTab === "Submitted" ? (
                    <Grid item>
                      <Avatar
                        alt={actionDetails?.employeeProfilePicture}
                        src={actionDetails?.employeeProfilePicture}
                      />
                    </Grid>
                  ) : null}
                  {activeTab === "Received" ? (
                    <Grid item>
                      <Typography variant="p">
                        {actionDetails?.userName}
                      </Typography>
                    </Grid>
                  ) : activeTab === "Submitted" ? (
                    <Grid item>
                      <Typography variant="p">
                        {actionDetails?.employeeName}
                      </Typography>
                    </Grid>
                  ) : null}
                  <Grid item sx={{ marginLeft: "auto" }}>
                    <Typography variant="p">
                      {moment(actionDetails?.dueDate).format("MMMM D, YYYY")}
                    </Typography>
                  </Grid>
                </Grid>
                <CardContent>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Typography variant="body1">
                        {actionDetails?.title}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body2"
                    style={{ marginTop: "10px" }}
                    color="textSecondary">
                    {actionDetails?.description}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      overflow: "auto",
                    }}>
                    {actionDetails?.pictureList != null &&
                      actionDetails?.pictureList.length > 0 &&
                      actionDetails?.pictureList.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              gap: "10px",
                              marginBottom: "10px",
                            }}>
                            <img
                              onClick={() => {
                                setPictureindex(index);
                                setOpenPictureViewer(true);
                              }}
                              src={item.fileUrl}
                              alt="img"
                              style={{
                                width: "100px",
                                height: "100px",
                                borderRadius: "px",
                              }}
                            />
                          </div>
                        );
                      })}
                    <PictureViewer
                      isOpen={openPictureViewer}
                      onClose={() => setOpenPictureViewer(false)}
                      pictures={actionDetails?.pictureList.map((item) => ({
                        src: item.fileUrl,
                        alt: "",
                      }))}
                      viewerIndex={pictureIndex}
                    />
                  </div>
                  <p style={{ color: BIGrey }}>Status</p>
                  <div
                    style={{
                      width: "fit-content",
                      marginTop: "2px",
                      display: "flex",
                      gap: "10px",
                    }}>
                    <Chip
                      label={actionDetails?.status}
                      style={
                        actionDetails?.status === "In progress"
                          ? { backgroundColor: "lightgrey", color: BIBlack }
                          : actionDetails?.status === "Overdue"
                          ? { backgroundColor: BILightRed, color: BIRed }
                          : actionDetails?.status === "Not Started"
                          ? { backgroundColor: BILightGrey, color: BIGrey }
                          : actionDetails?.status === "On Hold"
                          ? { backgroundColor: BILightOrange, color: BIBrown }
                          : { backgroundColor: BILightGreen, color: BIGreen }
                      }
                    />
                    {activeTab === "Received" && (
                      <p
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          color: localStorage?.clientAccentColor,
                        }}
                        onClick={() => handleStatusModal()}>
                        Update
                      </p>
                    )}
                  </div>

                  <Divider style={{ marginTop: "10px" }} />

                  <Typography
                    align="center"
                    style={{
                      width: "100%",
                    }}>
                    Created on{" "}
                    {moment(actionDetails?.dateCreated).format(
                      "MMMM D, YYYY, h:mm a"
                    )}
                  </Typography>

                  {SARHistory.map((item, index) => {
                    // if (item.status !== "Not Started") {
                    return (
                      <>
                        <Grid
                          gap={1}
                          container
                          justifyContent="space-between"
                          alignItems={"center"}
                          marginTop={2}>
                          {item.statusChangedByUser !== "" && (
                            <Grid item>
                              <Avatar
                                alt={item?.userProfilePicture}
                                src={item?.userProfilePicture}
                              />
                            </Grid>
                          )}
                          {item.statusChangedByUser !== "" && (
                            <Grid item>
                              <Typography variant="p">
                                {item?.statusChangedByUser}
                              </Typography>
                            </Grid>
                          )}

                          {item.statusChangedByUser !== "" && (
                            <Grid item sx={{ marginLeft: "auto" }}>
                              <Typography
                                variant="p"
                                style={{
                                  color: BIGrey,
                                }}>
                                {moment(item?.statusChangedDate).format(
                                  "MMMM DD, YYYY"
                                )}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                        <Typography variant="p">{item?.comment} </Typography>
                        {/* <Typography
                          align="center"
                          style={{
                            width: "100%",
                            color: BIGrey,
                          }}>
                          {item.status}
                          {" on "}
                          {moment(
                            item?.statusChangedDate !== null
                              ? item?.statusChangedDate
                              : actionDetails?.dueDate
                          ).format("MMMM DD, YYYY")}
                        </Typography> */}
                      </>
                    );
                    // }
                  })}
                  <div
                    style={{
                      marginTop: "10px",
                      display:
                        actionDetails?.status === "Completed" ||
                        activeTab === "Submitted"
                          ? "none"
                          : "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}>
                    <TextField
                      variant="standard"
                      value={commentText}
                      onChange={(e) => setComment(e.target.value)}
                      InputProps={{
                        disableUnderline: true,
                      }}
                      fullWidth
                      style={{
                        padding: "5px",
                        borderRadius: "10px",
                        backgroundColor: "lightgray",
                        size: "small",
                      }}
                      placeholder="Write a comment"
                    />

                    <IconButton
                      disabled={commentText.length === 0}
                      onClick={() => {
                        saveAction("comment");
                      }}>
                      <SendIcon
                        style={{
                          color:
                            commentText.length != 0
                              ? localStorage?.clientAccentColor
                              : "",
                        }}
                      />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      {openStatusModal && (
        <ClientChangeStatusModal
          saveStatus={(key) => upadteActionStatus(key)}
          selectedStatus={actionDetails?.status}
          open={openStatusModal}
          close={() => setOpenStatusModal(false)}
        />
      )}
    </>
  );
}
