import { useState, useEffect } from "react";
import {
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Card,
  Stack
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import ClientJobListModal from "./ClientJobListModal";
import { DeleteClientTimesheetTemplateActivities, DeleteClientTimesheetTemplateEmployees, FetchClientJobActivities2, FetchClientTimesheetJobs, SaveClientTimesheetTemplates } from "../../../api/ClientApi";
import ClientJobActivities from "./ClientJobActivities";
import Employeeslist from "./IndividualEmployeeslist";
import { BIGrey } from "../../../assets/buildidColors";
import Loader from "../../../components/Loader";
import IndividualEmployeeslist from "./IndividualEmployeeslist";
import TimeplateJobActivities from "./TemplateJobActivitiesModal";

export default function NewTemplateModel({
  open,
  onClose,
  onTemplateAdded,
  selectedTemplate,
  timesheetId
}) {


  let userId = localStorage.getItem("userId");
  let clientAccentColor = localStorage.getItem("clientAccentColor") ?? 'black';
  let clientEmployeeId = localStorage.getItem("clientEmployeeId");

  const [isLoading, setIsLoading] = useState(false);

  const [showAddTemplateModal, setShowAddTemplateModal] = useState(open);
  const [templateName, setTemplateName] = useState("");


  const [jobName, setJobName] = useState("");
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [showJobListModal, setShowJobListModal] = useState(false);


  const [showJobActivityList, setShowJobActivityList] = useState(false);
  const [showActivityListModal, setShowActivityListModal] = useState(false);
  const [selectedJobActivities, setSelectJobActivities] = useState([]);


  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [showEmployeeListModal, setShowEmployeeListModal] = useState(false);
  const [deletedEmployees, setDeletedEmployees] = useState([]);

  const [previousJobActivities, setPreviousJobActivities] = useState([]);

  console.log("previousJobActivities ==>", previousJobActivities)

  useEffect(() => {
    if (selectedTemplate?.id) {
      setTemplateName(selectedTemplate?.name ?? "");
      setJobName(selectedTemplate?.clientJobCode + " - " + selectedTemplate?.clientJobName);
      setSelectedJobId(selectedTemplate?.clientJobId);

      let selectedEmployeeList = selectedTemplate?.templateEmployeesList?.map((emp) => (
        { ...emp, employeefullName: emp?.clientEmployeeName, employeeId: emp?.clientEmployeeId, alreadySelected: true }
      )) ?? [];

      setSelectedEmployees(selectedEmployeeList);

      if (selectedTemplate?.templateActivitiesList?.length > 0) {
        let selectedJobActivitiesList = selectedTemplate?.templateActivitiesList?.map((act) => (
          { ...act, label: act?.activityCode + " - " + act?.activityName }
        )) ?? [];

        setShowJobActivityList(true);
        setSelectJobActivities(selectedJobActivitiesList);
        setPreviousJobActivities(selectedJobActivitiesList);
      } else {
        fetchClientJobActivity(selectedTemplate?.clientJobId)
      }
    }
  }, [selectedTemplate])


  useEffect(async () => {
    console.log("selectedTimeSheetDetails ====>", timesheetId)
    if (timesheetId) {
      const timesheetJobObject = {
        PageNr: 1,
        // NrOfRecPerPage:20,
        FullSearch: '',
        UserId: userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [{
          ClientTimesheetId: timesheetId
        }]
      };
      const res = await FetchClientTimesheetJobs(timesheetJobObject);
      let resData = res?.data?.[0] ?? {};
      console.log("RES ===>", resData);
      setSelectedJobId(resData?.clientJobId ?? 0);
      setJobName(resData?.clientJobCode + " - " + resData?.clientJobName);

      let selectedEmployeeList = resData?.timesheetEmployeesList?.map((emp) => (
        { ...emp, employeefullName: emp?.clientEmployeeName, employeeId: emp?.clientEmployeeId, alreadySelected: true }
      )) ?? [];

      setSelectedEmployees(selectedEmployeeList);

      if (resData?.timesheetActivitiesList?.length > 0) {
        let selectedJobActivitiesList = resData?.timesheetActivitiesList?.map((act) => (
          { ...act, label: act?.clientJobActivityCode + " - " + act?.clientJobActivityName, activityId: act?.clientActivityId }
        )) ?? [];

        setShowJobActivityList(true);
        setSelectJobActivities(selectedJobActivitiesList);
        setPreviousJobActivities(selectedJobActivitiesList);
      } else {
        fetchClientJobActivity(resData?.clientJobId)
      }

    }
  }, [timesheetId])

  const fetchClientJobActivity = async (clientJobId) => {
    try {
      const obj = {
        PageNr: 1,
        // NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: Number(localStorage?.userId),
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        SearchList: [
          {
            clientJobId
          }
        ]
      }
      const res = await FetchClientJobActivities2(obj);
      let resData = res?.data ?? [];
      setShowJobActivityList(resData?.length !== 0);
    } catch (err) {
      setShowJobActivityList(false);
      console.log("ERROR WHILE FETCHING JOB ACTIVITY", err)
    }
  }

  const onSaveTemplate = async () => {
    try {
      setIsLoading(true);


      const deletedJobActivities = previousJobActivities.filter(firstItem =>
        !selectedJobActivities.some(secondItem => secondItem.activityId === firstItem.activityId)
      );

      if (deletedJobActivities.length > 0) {
        deletedJobActivities.map(async (act) => {
          const deleteJobActivities_Object = {
            "UserId": Number(userId),
            "ReturnRecordId": false,
            "ReturnRecordError": true,
            "SoftDelete": true,
            "DeleteList": [
              {
                "Id": act.id
              }
            ]
          };
          await DeleteClientTimesheetTemplateActivities(deleteJobActivities_Object);
        });
      };

      if (deletedEmployees.length > 0) {
        deletedEmployees.map(async (emp) => {
          const deleteEmployees_Object = {
            "UserId": Number(userId),
            "ReturnRecordId": false,
            "ReturnRecordError": true,
            "SoftDelete": true,
            "DeleteList": [
              {
                "Id": emp.id
              }
            ]
          };
          await DeleteClientTimesheetTemplateEmployees(deleteEmployees_Object);
        });
        setDeletedEmployees([]);
      };

      const obj = {
        "UserId": Number(userId),
        "ReturnRecordId": true,
        "ReturnRecordError": true,
        "SaveList": [
          {
            "Id": selectedTemplate?.id ?? 0,
            "Name": templateName,
            "Type": "Crew",
            "ClientEmployeeId": clientEmployeeId,
            "ClientJobId": Number(selectedJobId),
            "EmployeeIdList": `${selectedEmployees.map(emp => emp.employeeId).join(',')}`,
            "ActivityIdList": `${selectedJobActivities.map(act => act.activityId ?? act.id).join(',')}`,
            "ModifiedBy": Number(userId)
          }
        ]
      };

      await SaveClientTimesheetTemplates(obj);
      onTemplateAdded();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong. Please try again later.");
      console.log("Error while saving template :: ", error)
    }
  }

  const onRemoveEmployee = (emp) => {
    setSelectedEmployees((preValue) => ([...preValue]?.filter((e) => e.employeeId !== emp.employeeId)));
    if (emp?.alreadySelected) {
      setDeletedEmployees((preValue) => ([...preValue, emp]))
    }
  }

  const onRemoveJobActivities = (act) => {
    setSelectJobActivities((preValue) => ([...preValue]?.filter((a) => a.activityId !== act.activityId)));
    // if (act?.alreadySelected) {
    //   setDeletedJobActivities((preValue) => ([...preValue, act]))
    // }
  }


  // const checkPreviousJobActivityExits = async (id) => {
  //   let checkToDeleteActivites = [...selectedJobActivities].filter((a) => a.alreadySelected);
  //   setDeletedJobActivities((preValue) => [...preValue, ...checkToDeleteActivites]);

  // }


  return (

    <>
      {
        showAddTemplateModal &&
        (
          <Modal open={open} onClose={onClose}>
            <div className="modal-center">
              <div className="modal-card crew-modal" style={{ width: "600px", height: '600px', overflow: 'scroll' }}>

                {
                  !isLoading ? (
                    <>
                      <Stack justifyContent={'space-between'} direction={'column'} style={{ height: '100%' }}>
                        <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} style={{ height: '10%' }}>
                          <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}> {!selectedTemplate && "Add New Template"}</Typography>
                          <IconButton onClick={onClose}>
                            <CloseIcon />
                          </IconButton>
                        </Stack>

                        <div style={{ height: '80%', overflow: 'scroll' }}>

                          <Typography style={{ marginBottom: '10px' }}>Template Name</Typography>
                          <TextField
                            fullWidth
                            label="Template Name"
                            size={"medium"}
                            className="user-search"
                            variant="outlined"
                            placeholder="Enter a name (required)"
                            required
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                          />

                          <Stack direction={'column'} style={{ marginTop: '20px' }}>
                            <Typography style={{ marginBottom: '10px' }}>Job</Typography>
                            <Stack
                              direction={'row'}
                              onClick={() => {
                                setShowAddTemplateModal(false);
                                setShowJobListModal(true);
                              }}
                              justifyContent={'start'}
                              alignItems={'center'}
                              style={{ border: "1px solid #e2e2e2", borderRadius: '5px', width: '100%', height: "55px" }}
                            >
                              <IconButton>
                                <SearchIcon sx={{ color: 'black' }} />
                              </IconButton>
                              <Typography style={{ color: jobName === "" ? "#666666" : "black" }}>
                                {jobName !== '' ? jobName : `Search for a Job`}
                              </Typography>
                            </Stack>
                          </Stack>

                          {
                            showJobActivityList &&
                            (
                              <Stack style={{ marginTop: '20px' }}>
                                <Stack
                                  direction={'row'}
                                  justifyContent={'space-between'}
                                  alignItems={'center'}
                                >
                                  <Typography>
                                    Activities
                                  </Typography>
                                  <Button style={{ color: clientAccentColor }}
                                    onClick={() => {
                                      setShowAddTemplateModal(false);
                                      setShowActivityListModal(true);
                                    }}
                                  >
                                    Select activities
                                  </Button>
                                </Stack>

                                <div style={{ marginTop: '5px' }}>
                                  {
                                    selectedJobActivities?.map((emp, index) => (
                                      <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} key={index}
                                        style={{ border: '1px solid #e2e2e2', margin: '5px 0px', padding: '0px 5px', borderRadius: '5px' }}
                                      >
                                        <Typography>
                                          {
                                            emp?.label ?? ""
                                          }

                                        </Typography>
                                        <IconButton style={{ color: BIGrey }} onClick={() => { onRemoveJobActivities(emp) }}>
                                          <CloseIcon />
                                        </IconButton>
                                      </Stack>
                                    ))
                                  }
                                </div>
                              </Stack>
                            )
                          }

                          <Stack style={{ marginTop: '20px' }}>
                            <Stack
                              direction={'row'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                            >
                              <Typography>
                                Employees
                              </Typography>
                              <Button style={{ color: clientAccentColor }}
                                onClick={() => {
                                  setShowAddTemplateModal(false);
                                  setShowEmployeeListModal(true);
                                }}
                              >
                                Select employees
                              </Button>
                            </Stack>
                            <div id="template_employee_list">
                              {
                                selectedEmployees?.length > 0 ? (
                                  <div style={{ marginTop: '5px' }}>
                                    {
                                      selectedEmployees?.map((emp, index) => (
                                        <Stack direction={"row"} justifyContent={'space-between'} alignItems={'center'} key={index}
                                          style={{ border: '1px solid #e2e2e2', margin: '5px 0px', padding: '0px 5px', borderRadius: '5px' }}
                                        >
                                          <Typography>
                                            {
                                              emp?.employeefullName ?? ""
                                            }

                                          </Typography>
                                          <IconButton style={{ color: BIGrey }} onClick={() => { onRemoveEmployee(emp) }}>
                                            <CloseIcon />
                                          </IconButton>
                                        </Stack>
                                      ))
                                    }
                                  </div>
                                )
                                  :
                                  (
                                    <>
                                      <div style={{ textAlign: 'center', color: BIGrey }}>No employees</div>
                                    </>
                                  )
                              }
                            </div>

                          </Stack>
                        </div>

                        <Stack style={{ marginTop: '30px', height: '10%' }}>
                          <Button style={{ color: "white", backgroundColor: templateName?.trim() === "" ? BIGrey : clientAccentColor }}
                            onClick={onSaveTemplate}
                            disabled={templateName?.trim() === ""}
                          >
                            Save
                          </Button>
                        </Stack>

                      </Stack>


                    </>
                  )
                    :
                    (
                      <>
                        <Stack justifyContent={"center"} alignItems={'center'} style={{ height: '100%' }}>
                          <Loader />
                        </Stack>
                      </>
                    )
                }

              </div>
            </div>
          </Modal>
        )
      }

      {
        showJobListModal && (
          <ClientJobListModal
            show={showJobListModal}
            handleClose={() => {
              setShowAddTemplateModal(true);
              setShowJobListModal(false)
            }}
            onJobSelect={async (item) => {
              if (selectedJobId !== item?.id) {
                setSelectedJobId(item?.id ?? 0);
                setJobName(item?.value ?? "");
                setSelectJobActivities([]);
                setShowJobActivityList(false);
                await fetchClientJobActivity(item?.id);
              }

              setShowJobListModal(false);
              setShowAddTemplateModal(true);
              // checkPreviousJobActivityExits(item?.id);
            }}
            selectedJobId={selectedJobId}
          />
        )
      }

      {
        showActivityListModal &&
        (
          <TimeplateJobActivities
            show={showActivityListModal}
            selectedJobId={selectedJobId}
            onClose={() => {
              setShowActivityListModal(false);
              setShowAddTemplateModal(true);
            }}
            selectedJobActivities={selectedJobActivities}
            setSelectJobActivities={(data) => {
              setSelectJobActivities(data)
            }}
          />
        )
      }

      {
        showEmployeeListModal &&
        (
          <IndividualEmployeeslist
            show={showEmployeeListModal}
            handleClose={() => {
              setShowEmployeeListModal(false);
              setShowAddTemplateModal(true);
            }}
            setSelectedemp={(data) => {
              const element = document.getElementById('template_employee_list');
              console.log("OKOKO ", element)
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
              setSelectedEmployees(data)
            }}
            selectedEmployees={selectedEmployees}
          />
        )
      }

    </>
  )
}