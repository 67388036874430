import React, { useState } from "react";
import { Modal, IconButton, Button, TextField } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Close as CloseIcon,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Publish as UploadIcon,
} from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import InfoIcon from "@mui/icons-material/Info";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "../../assets/css/Timesheets.css";
import moment from "moment/moment";
import {
  deleteExpenses,
  deleteExpensesPictures,
  saveExpenses,
  saveExpensesPictures,
} from "../../api/ExpensesApi";
import AlertModal from "../../components/AlertModal";
import PromptModal from "../../components/PromptModal";
import { Checkbox, Grid } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { fetchUserWorkHistory } from "../../api/UserApi";
import { useEffect } from "react";
import { BIRed } from "../../assets/buildidColors";
import { fontSize } from "@mui/system";
import { isEqual } from "lodash";

function NewExpenseModal(props) {
  const today = moment();
  const [selectedDate, setSelectedDate] = useState(today.format("YYYY-MM-DD"));
  const [selected, setSelected] = useState("");
  const [options, setOptions] = useState([]);
  const [deletedImages, setDeletedImages] = useState([]);
  const [saveExpensesAlert, setSaveExpensesAlert] = useState(false);
  const [deleteExpensesAlert, setDeleteExpensesAlert] = useState(false);
  const [amount, setAmount] = useState(null);
  const [description, setDescription] = useState("");
  const [comments, setComments] = useState("");
  const [siteId, setSiteId] = useState(0);
  const [projectId, setProjectId] = useState(0);
  const [pictureList, setPictureList] = useState([]);
  const [lastModified, setLastModified] = useState(null);
  const [expenseId, setExpenseId] = useState(0);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [inappropriateImageAlert, setInappropriateImageAlert] = useState(false);

  const [isFocused, setIsFocused] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  let form_headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  function removeDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }
    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  useEffect(() => {
    const getUserWorkHistory = async () => {
      const workHistoryObj = {
        PageNr: 1,
        FullSearch: "",
        IncludeRecordNr: false,
        SearchList: [{ Id: 0, UserId: Number(localStorage.userId) }],
      };
      const res = await fetchUserWorkHistory(workHistoryObj);
      const filteredOptions = res.filter((option) => option.projectName !== "");

      setOptions(filteredOptions);
    };
    getUserWorkHistory();
  }, []);

  function handleChooseFile(event) {
    const files = event.target.files;
    const pictures = [];
    Array.from(files).forEach((file) => pictures.push(file));
    setPictureList([...pictureList, ...pictures]);
    if (files) {
      setIsChanged(true);
    }
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAmount = (event) => {
    // if (event.target.value?.length <= 4) {
    setAmount(event.target.value);
    setIsChanged(true);
    // }
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
    setIsChanged(true);
  };

  const handleComments = (event) => {
    setComments(event.target.value);
    setIsChanged(true);
  };

  const clearEditData = async () => {
    setSelectedDate(moment().format());
    props.setPageNr(1);
    setIsChanged(false);
    // setSelected("");
    setAmount(null);
    setComments("");
    setSelected("");
    setDescription("");
    setExpenseId(0);
    setPictureList([]);
    setDeletedImages([]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxWidth: ITEM_HEIGHT + ITEM_PADDING_TOP,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const newArr = options.filter((item) => item.projectName === value);
    setSiteId(newArr[0]?.siteId);
    setProjectId(newArr[0]?.userSiteProjectId);
    setSelected(value);
    setIsChanged(true);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const onDeleteButtonPressed = async (expenseId) => {
    try {
      const deleteExpense = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: expenseId,
            ModifiedBy: Number(localStorage.getItem("userId")),
          },
        ],
      };
      const deleteExpenseRes = await deleteExpenses(deleteExpense);
      props.onClose();
      if (deleteExpenseRes.message === "OK") {
        props.setloading(true);
        setDeleteExpensesAlert(false);
        await clearEditData();
        props.getUnsentExpenses();
        props.setloading(false);
      } else {
        await clearEditData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeletePicturePressed = (item, index) => {
    if (item.pictureUrl) deletedImages.push(item);
    const pictures = pictureList.filter((pic, i) => i !== index);
    setDeletedImages([...deletedImages]);
    setPictureList([...pictures]);
  };

  const handleSaveExpenses = async () => {
    try {
      props.setloading(true);
      if (deletedImages.length !== 0) {
        const deleteArray = deletedImages.map((deletePic) => {
          return { Id: deletePic.id };
        });

        const deleteObj = {
          UserId: Number(localStorage.getItem("userId")),
          ReturnRecordId: false,
          ReturnRecordError: true,
          DeleteList: deleteArray,
        };
        await deleteExpensesPictures(deleteObj);
      }

      const saveExpensObj = {
        UserId: Number(localStorage.getItem("userId")),
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: expenseId,
            ExpenseDate: selectedDate,
            TotalAmount: amount,
            Description: description,
            SiteId: siteId || 0,
            ProjectId: projectId || 0,
            Comments: comments,
            IsSent: false,
            ModifiedBy: Number(localStorage.getItem("userId")),
          },
        ],
      };

      const saveExpensesRes = await saveExpenses(saveExpensObj);

      const newPictureList = pictureList?.filter((pic) => !pic.pictureUrl);
      if (newPictureList && newPictureList.length > 0) {
        newPictureList.map(async (img, index) => {
          const formData1 = new FormData();
          formData1.append("Id", 0);
          formData1.append(
            "ExpenseId",
            saveExpensesRes.data[0].objData?.id ?? props.editExpensesData?.id
          );
          formData1.append("PictureUrl", img);
          formData1.append(
            "ModifiedBy",
            Number(localStorage.getItem("userId"))
          );

          await saveExpensesPictures(formData1, form_headers).catch((err) => {
            if (err === "Content Inappropriate") {
              setInappropriateImageAlert(true);
              clearEditData();
            }
          });
        });
      }
      props.getUnsentExpenses();
      props.onClose();
      props.setloading(false);
      await clearEditData();
    } catch (error) {
      if (error.response.status == 400) {
        setErrorMsg(error.response.data.message);
      }
      console.log("SaveExpenseError:", error);
      props.setloading(false);
      clearEditData();
    }
  };

  function handleDiscard() {
    if (isChanged) {
      setDiscardModalOpen(true);
    } else {
      props.onClose();
      clearEditData();
      setDiscardModalOpen(false);
    }
  }

  useEffect(() => {
    const fetchEditExpenses = () => {
      if (!props.editExpensesData.id) {
        props.setNewExpenseModalOpen();
      } else {
        setProjectId(props.editExpensesData?.projectId);
        setAmount(props.editExpensesData?.totalAmount);
        setDescription(props.editExpensesData?.description);
        setExpenseId(props.editExpensesData?.id);
        setComments(props.editExpensesData?.comments);
        setSelectedDate(
          moment(props.editExpensesData?.expenseDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        setSelected(props.editExpensesData?.projectName);
        setPictureList(props.editExpensesData?.pictureList);
        setLastModified(
          moment
            .parseZone(props.editExpensesData?.dateModified)
            .local()
            .format("YYYY-DD-MM h:ssa")
        );
      }
    };
    fetchEditExpenses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editExpensesData]);

  useEffect(() => {
    return () => {
      setIsChanged(false);
    };
  }, []);

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <>
      {errorMsg != "" && (
        <AlertModal
          isOpen={errorMsg != ""}
          onClose={() => setErrorMsg("")}
          body={errorMsg}
        />
      )}
      <AlertModal
        isOpen={saveExpensesAlert}
        onClose={() => setSaveExpensesAlert(false)}
        title={"Expense Saved Successfully..."}
      />

      <AlertModal
        isOpen={inappropriateImageAlert}
        onClose={() => setInappropriateImageAlert(false)}
        title={"Content Rejected"}
        body={
          "The text picture you entered has been automatically identified as inappropriate."
        }
        inappropriateImage
        text1={"Please remove the content and try again."}
      />

      <PromptModal
        isOpen={discardModalOpen}
        onClose={() => setDiscardModalOpen(false)}
        title="Are you sure you want to leave?"
        desc="Any unsaved changes will be discarded."
        redButtonText="Discard"
        onRedButtonClick={() => {
          props.onClose();
          clearEditData();
          setDiscardModalOpen(false);
        }}
      />

      <PromptModal
        isOpen={deleteExpensesAlert}
        onClose={() => {
          setDeleteExpensesAlert(false);
        }}
        title="Are you sure you want to Delete?"
        redButtonText="Delete"
        onRedButtonClick={() =>
          onDeleteButtonPressed(props.editExpensesData?.id)
        }
      />

      <Modal
        open={props.isOpen}
        onClose={async () => {
          props.onClose();
          await clearEditData();
        }}
      >
        <div className="modal-center timesheet-modal">
          <div className="modal-card">
            <div
              className="modal-card-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="modal-card-header-buttons">
                <IconButton
                  color="primary"
                  onClick={async () => {
                    handleDiscard();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div>
                <h2>
                  {props.clickMode === "EditExpense"
                    ? "Edit Expense"
                    : "Add Expense"}
                </h2>
              </div>
            </div>
            {/* new changes 27-03 Yash  */}

            <Grid container alignItems={"center"}>
              <Grid item xs={12} sm={12} md={6} lg={12}>
                <Grid container className="job-grid">
                  <Grid item xs={12} md={8} sm={12} lg={12}>
                    {pictureList.length == 0 ? (
                      <div
                        className="upload-image"
                        style={{
                          width: "100%",
                          marginRight: "0px",
                          backgroundColor: "lightgray",
                        }}
                      >
                        <Button
                          style={{ width: "100%", padding: 0 }}
                          variant="text"
                          color="primary"
                          size="large"
                          fontWeight="bold"
                          component="label"
                        >
                          Add Pictures
                          <input
                            id="timeSheetPicture-upload"
                            type="file"
                            multiple
                            accept="image/*"
                            onChange={handleChooseFile}
                            hidden
                          />
                        </Button>
                      </div>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div className="upload-image">
                            <Button
                              style={{ width: "100%", padding: 0 }}
                              variant="text"
                              color="primary"
                              size="small"
                              component="label"
                            >
                              Add Pictures
                              <input
                                id="timeSheetPicture-upload"
                                type="file"
                                multiple
                                accept="image/*"
                                onChange={handleChooseFile}
                                hidden
                              />
                            </Button>
                          </div>
                          <div className="upload-image-div">
                            {pictureList.map((item, index) => {
                              return (
                                <div key={index} className="store-card-image1">
                                  <span className="store-card-colse-icon1">
                                    <CloseIcon
                                      className="store-card-colse-icon1-color"
                                      onClick={() =>
                                        onDeletePicturePressed(item, index)
                                      }
                                    />
                                  </span>
                                  <img
                                    src={
                                      item.pictureUrl
                                        ? item.pictureUrl
                                        : URL.createObjectURL(item)
                                    }
                                    alt="Expenses"
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* new changes 27-03 Yash  */}

            <div
              className="modal-card-body"
              style={{
                padding: "10px 15px 0px 0px",
                overflowY: "auto",
                maxHeight: "70vh",
              }}
            >
              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="Expense Date"
                    format="YYYY-MM-DD"
                    value={selectedDate}
                    InputAdornmentProps={{ position: "start" }}
                    onChange={(date) => {
                      handleDateChange(date);
                      setIsChanged(true);
                    }}
                  />
                </div>
              </div>
              {/* new changes 27-03 Yash  */}

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <FormControl>
                    <InputLabel
                      id="mutiple-select-label"
                      className="timesheet-label"
                    >
                      Project (Optional)
                    </InputLabel>

                    <Select
                      labelId="mutiple-select-label"
                      label="Project (Optional)"
                      variant="outlined"
                      value={selected}
                      onChange={handleChange}
                      MenuProps={MenuProps}
                      style={{ width: "100%" }}
                      renderValue={(selected) => selected}
                    >
                      {options.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.projectName}>
                            <ListItemText
                              primary={option.projectName}
                              secondary={
                                <>
                                  <h4>{option.siteName}</h4>
                                  <h4>{option.address}</h4>
                                </>
                              }
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              {/* new changes 27-03 Yash  */}

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <TextField
                    type="number"
                    variant="outlined"
                    placeholder="0.00"
                    label="Amount"
                    value={amount}
                    onChange={handleAmount}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Description"
                    value={description}
                    onChange={handleDescription}
                  />
                </div>
              </div>

              <div className="input-container">
                <div
                  className="flex-container-vertical"
                  style={{ alignItems: "stretch" }}
                >
                  <TextField
                    type="text"
                    variant="outlined"
                    label="Comments (Optional)"
                    value={comments}
                    onChange={handleComments}
                  />
                </div>
              </div>

              {/* <div className="input-container">
                {pictureList?.length > 0 ? (
                  pictureList?.map((item, picIndex) => {
                    return (
                      <div key={picIndex} style={{ position: "relative" }}>
                        <IconButton
                          aria-label="Close"
                          onClick={() => onDeletePicturePressed(item, picIndex)}
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "190px",
                          }}>
                          <CloseIcon />
                        </IconButton>
                        <img
                          src={
                            item.pictureUrl
                              ? item.pictureUrl
                              : URL.createObjectURL(item)
                          }
                          alt="Expenses"
                          style={{
                            height: "200px",
                            width: "200px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <img style={{ display: "none" }} alt="" />
                )}
              </div>

              <div className="input-container">
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  component="label"
                  startIcon={<UploadIcon />}>
                  Upload Image
                  <input
                    id="timeSheetPicture-upload"
                    type="file"
                    multiple
                    accept="image/*"
                    onChange={handleChooseFile}
                    hidden
                  />
                </Button>
              </div> */}

              {props.clickMode == "EditExpense" &&
              props.activeTab === "sent" ? (
                <p
                  style={{
                    backgroundColor: "lightyellow",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  <InfoIcon fontSize="small" /> This Expense has been sent.
                  Remember to resend if you edit!
                </p>
              ) : (
                <></>
              )}

              <div
                className="input-container"
                style={{ padding: "20px 0px", display: "flex" }}
              >
                {props.clickMode == "EditExpense" ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    size="medium"
                    component="label"
                    startIcon={<DeleteIcon />}
                    onClick={() => setDeleteExpensesAlert(true)}
                    style={{
                      marginRight: "10px",
                      color: BIRed,
                      borderColor: BIRed,
                    }}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="medium"
                  component="label"
                  disabled={amount > 0 && description !== "" ? false : true}
                  startIcon={<SaveIcon />}
                  onClick={handleSaveExpenses}
                >
                  Save
                </Button>
              </div>
              {props.clickMode == "EditExpense" && lastModified && (
                <p style={{ fontSize: "medium", textAlign: "right" }}>
                  Last modified: {lastModified}
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default NewExpenseModal;
